import * as components from "../react_components";
import {PositionProperty, VisibilityProperty} from "csstype";
import {Avatar, FormHelperText, GridSize, IconButton, makeStyles, Tooltip} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircle";
import MomentUtils from "@date-io/moment";
import * as React from "react";
import {createContext, Ref, useContext, useEffect, useState} from "react";
import {
    FieldValidationCallbacks,
    FormReference,
    GenericFieldParams
} from "sancus-client-common/dist/common/generic_component_base";

import {AnyCheckType, ICheck} from "sancus-client-common/dist/common/FormContainerBase";
import JsonValueEditor from "./JsonValueEditor";
import {
    AnnotationContext,
    GenericFieldComponentSimpleBase,
    GenericFieldRenderers,
    Validation
} from "sancus-client-common/dist/common/GenericFieldComponentSimpleBase";
import LayoutContext, {Layout} from "sancus-client-common/dist/common/LayoutContext";
import {CustomFileField, LabelWithTooltip} from "../generic_component";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Error';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";
import {MomentInput} from "moment";

import {IndexDecoratorFormValidationUtilFactory} from "sancus-client-common/dist/utils/formValidation.util";
import {useFormContext} from "react-hook-form";
import {withLang} from "sancus-client-common/dist/common/LanguageContext";
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import {useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import {useAnnotatorActions} from "sancus-client-common/dist/store/actions/annotator.actions";
import {element, WithDrag} from "../../designer_utils";
import DeleteIcon from "@material-ui/icons/Delete";
import {CustomTextfield} from "../customTextfield.component";
import {CustomCheckbox} from "../customCheckbox.component";
import {CustomDate} from "../customDate.component";
import {FormField} from "sancus-client-common/dist/common/camunda_helper";
import EditIcon from "@material-ui/icons/Edit";
import {getStore} from "sancus-client-common/dist/store";
import AddIcon from "@material-ui/icons/Add";
import {useEditField} from "sancus-client-common/dist/common/EditFieldContext";
import {useIntl} from "react-intl";

export const readOnlyStyleFactory = (theme) => ({
    readOnlyLabel: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    readOnlyValue: {},
    designerFieldSelected: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px'
    },
    designerIsDragOverIndicateTop: {
        borderTop: `3px solid ${theme.palette.primary.main}`
    },
    designerIsDragOverIndicateBottom: {
        borderBottom: `3px solid ${theme.palette.primary.main}`
    },
    designerIsDragOverGroup: {
        borderColor: `${theme.palette.primary.main}`
    },
})

const annotatorStyles = (theme) => ({
    annotatedElement: {
        position: 'relative' as PositionProperty,
        // display: 'contents',
        '&:hover': {
            backgroundColor: 'lightgrey',
            '& $annotationButton': {
                visibility: 'visible' as VisibilityProperty
            }
        }
    },

    annotationButtonContainer: {
        position: 'absolute' as PositionProperty,
        right: '60px',
        top: '0px',
    },

    annotationButton: {
        '@media (hover: hover)': {
            visibility: 'hidden' as VisibilityProperty
        },

        '@media (hover: none)': {
            opacity: 0.3,
        },


        '&.active': {
            visibility: 'visible' as VisibilityProperty
        }
    }
});

const useStyles = makeStyles(readOnlyStyleFactory);
const useAnnotatorStyles = makeStyles(annotatorStyles)
const momentUtils = new MomentUtils();

const hasPendingConstraint = (constraints: any): boolean => {
    return Array.isArray(constraints) && !!constraints.find(item => item.name === 'pending') || false;
};

const ReadOnlyPendingField = (props: { label: string }) => {
    const readOnlyClasses = useStyles();

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
              style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}><Typography variant="body2"
                                          className={`${readOnlyClasses.readOnlyLabel}`}>{props.label}:</Typography></Grid>
            <Grid item xs={6}><Typography variant="body2">Pending</Typography></Grid>
        </Grid>
    );
}

export function GenericFieldComponentSimple<T>(fv_value: GenericFieldParams) {
    const layout = useContext(LayoutContext);
    const intl = useIntl();

    class WebRenderers implements GenericFieldRenderers {

        handleChange = (fv_key, setValue) => async (event) => {
            event.preventDefault();
            if (event.target.readOnly) {
                return false;
            }

            let newValue;
            if (event.target.type == 'checkbox') {
                newValue = event.target.checked
            } else if (event.target.type == 'file' && event.target.files) {
                const scope = event.target.scope;
                const files = Array.from<File>(event.target.files);

                newValue = files.map((f, i) => {
                    const {name, type, size} = f;

                    // it can be already modified file
                    // @ts-ignore
                    if (f.data) {
                        return f;
                    }

                    return {
                        name, type: 'file', mimeType: type, size, scope, data: async () => {

                            // ie11 compatibility, arrayBuffer not supported
                            const reader = new FileReader();
                            reader.readAsDataURL(f);

                            const dataUrl: string = await new Promise<string>((resolve, reject) => {
                                reader.onloadend = function () {
                                    const base64data = reader.result as string;
                                    resolve(base64data);
                                };
                                reader.onerror = function () {
                                    reject(reader.error);
                                };
                            });

                            return dataUrl.split(',')[1];

                            /*
                            const buffer = await f.arrayBuffer();
                            const bytes = new Uint8Array(buffer);
                            const encoded = Base64.fromUint8Array(bytes);
                            return encoded;
                            */
                        }
                    };
                })
                fv_value.fileChangeCbk(fv_key, newValue)


            } else {
                newValue = event.target.value;
            }
            setValue(newValue);
            fv_value.valueChangeCbk(fv_key, newValue)

        }
        //TODO remove this when file validation is implemented.
        handleInputRef = (validation, setError) => {
            return el => {
                if (el) {
                    Object.assign(el, validation)
                    el.addEventListener('invalid', invEvent => {
                        invEvent.preventDefault();
                        setError(invEvent.target.validationMessage)
                    })
                    el.addEventListener('blur', blurEvent => {
                        blurEvent.preventDefault();
                        const valid = blurEvent.target.reportValidity();
                        if (valid) {
                            setError(null);
                        }
                    })
                }
            }
        }

        renderCustom<V, E>(elemRef: Ref<HTMLInputElement>, customComponent, fv_key, label, value: V, setValue: (v: V) => void, extra_props: {
            [key: string]: string
        }, validation: Validation, checkMessages: AnyCheckType | undefined, formReference: FormReference, fieldValidationCallbacks: FieldValidationCallbacks): JSX.Element {
            const ReactComponent = components[customComponent]
            if (ReactComponent) {
                const methods = useFormContext();
                console.debug(`Rendering CustomComponent ${customComponent}`)
                const required = validation && validation.required;



                const LCReactComponent = withLang(ReactComponent)
                return <LCReactComponent
                    key={fv_key}
                    id={fv_key}
                    name={fv_key}
                    path = {fv_value.path}
                    label={<LabelWithTooltip label={label} tooltip={extra_props.tooltip}/>}
                    value={value}
                    checkMessages={checkMessages}
                    cam-variable-name={fv_key}
                    cam-variable-type={fv_value.typeName}
                    {...extra_props}
                    index={fv_value.index}

                    validation={validation}
                    valueChangeCbk={fv_value.valueChangeCbk}
                    fileChangeCbk={fv_value.fileChangeCbk}
                    contextEvaluator={fv_value.contextEvaluator}
                    formReference={formReference}
                    fieldValidationCallbacks={fieldValidationCallbacks}

                    required={required}
                    getNotificationTemplate={this.getNotificationTemplate}

                    elemRef={elemRef}
                    methods={methods}

                    fv_value={fv_value}

                />
            } else {
                throw new Error(`Custom component ${customComponent} not Found`)

            }
        }

        renderCheckBox(elemRef: Ref<HTMLInputElement>, fv_key, _value, setValue, error, setError, validation, readOnly: boolean,extra_props: any, label: string, formReference: FormReference, fieldValidationCallbacks) {
            fv_value = {
                ...fv_value,
                label
            }

            const {onChange} = fieldValidationCallbacks;
            const readOnlyClasses = useStyles();

            return (
                <CustomCheckbox
                    elemRef={elemRef}
                    fv_value={fv_value}
                    _value={_value}
                    readOnlyClasses={readOnlyClasses}
                    getNotificationTemplate={this.getNotificationTemplate}
                    validation={validation}
                    onValueChange={(() => {
                        const cbk = this.handleChange(fv_key, setValue);
                        return (event) => {
                            onChange(event.target.checked);
                            return cbk(event);
                        };
                    })()}
                    {...extra_props}
                />
            );
        }

        renderDate<V extends MomentInput, E = string>(elemRef: Ref<HTMLInputElement>, fv_key, _value: V, setValue: (v: V) => void, error: E, setError: (e: E) => void, validation, readOnly, extra_props: {
            [key: string]: string
        } | undefined, label: string, formReference: FormReference, fieldValidationCallbacks: FieldValidationCallbacks) {
            let format = extra_props?.format || "DD/MM/yyyy";

            const {onBlur, onChange} = fieldValidationCallbacks;

            fv_value = {
                ...fv_value,
                label
            }

            return (
                <CustomDate

                    elemRef={elemRef}
                    fv_value={fv_value}
                    _value={_value}
                    readOnlyClasses={readOnlyClasses}
                    getNotificationTemplate={this.getNotificationTemplate}
                    validation={validation}
                    onValueChange={(() => {
                        const cbk = dateAsString => {
                            setValue(dateAsString);
                            fv_value.valueChangeCbk(fv_key, dateAsString);
                        };
                        return (date) => {
                            const dateAsString = !!date && typeof date.format === 'function'
                                ? date!.format(format)
                                : null;
                            onChange(dateAsString);
                            return cbk(dateAsString);
                        };
                    })()}
                    onBlur={onBlur}
                    extra_props={extra_props}
                />
            );
        }

        renderFile(elemRef: Ref<HTMLInputElement>, fv_key, _value, setValue, error, setError, validation, label: string, type, readOnly, extra_props, formReference: FormReference, fieldValidationCallbacks: FieldValidationCallbacks) {
            fv_value = {
                ...fv_value,
                label
            }
            const required = validation && validation.required;

            return <CustomFileField key={fv_value.id}
                                    id={fv_key}
                                    name={fv_key}
                                    path = {fv_value.path}
                                    label={<LabelWithTooltip label={label} tooltip={extra_props.tooltip}/>}
                                    defaultValue={null}
                                    type={type}
                                    fieldValidationCallbacks={fieldValidationCallbacks}
                                    onClick={event => {
                                        if (event.target.readOnly) {
                                            event.preventDefault();
                                            return false;
                                        }
                                    }}
                                    data-readonly={readOnly}
                                    onChange={this.handleChange(fv_key, setValue)}
                                    inputProps={{
                                        'data-section': fv_value.properties?.section,
                                        'cam-variable-name': fv_key,
                                        'cam-variable-type': fv_value.typeName
                                    }}
                                    variant="filled"
                                    fullWidth
                                    margin="normal"
                                    error={!!error}
                                    helperText={error}
                                    readOnly={readOnly}
                                    inputRef={this.handleInputRef(validation, setError)}
                                    filesValue={_value}
                                    required={required}
                                    {...extra_props}
                                    index={fv_value.index}

                                    getNotificationTemplate={this.getNotificationTemplate}
                                    checkMessages={fv_value.checkMessages}

                                    elemRef={elemRef}

                                    fv_value={fv_value}
                                    focused={fv_value.focused}

            />
        }

        renderText(elemRef: Ref<HTMLInputElement>, fv_key, _value, setValue: (v) => void, error, setError: (e) => void, validation, type, readOnly: boolean, extra_props: any, formReference: FormReference, label: string, fieldValidationCallbacks: FieldValidationCallbacks) {
            fv_value = {
                ...fv_value,
                label
            }
            const {onBlur, onChange} = fieldValidationCallbacks;
            const methods = useFormContext();
            const {errors} = methods;
            const readOnlyClasses = useStyles();
            const parentPath = fv_value.path && fv_value.path.lastIndexOf('.') !== -1 ? fv_value.path.substring(0, fv_value.path.lastIndexOf('.')) : undefined
            const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(fv_value.index,parentPath,intl);

            const required = validation && validation.required;

            return (
                <CustomTextfield
                    textFieldProps={{
                        autoComplete: fv_value.properties?.autocomplete || fv_value.id,
                        inputRef: elemRef,
                        key: fv_value.id,
                        id: fv_key,
                        name: fv_key,

                        label: <LabelWithTooltip label={fv_value.label || fv_key} tooltip={extra_props?.tooltip}/>,
                        value: _value,
                        type: type,
                        onChange: (() => {
                            const cbk = this.handleChange(fv_key, setValue);
                            return (val) => {
                                onChange(val);
                                return cbk(val);
                            };
                        })(),
                        onBlur: onBlur,
                        inputProps: {
                            'data-section': fv_value.properties?.section,
                            'cam-variable-name': fv_key,
                            'cam-variable-type': fv_value.typeName,
                        },
                        'data-readonly': readOnly,
                        variant: 'filled',
                        fullWidth: true,
                        margin: "normal",
                        error: formValidationUtil.hasFieldError(fv_key, errors),
                        errorText: formValidationUtil.getFieldErrorMessage(fv_key, errors),

                        required: required,
                        ...extra_props
                    }}
                    readonlyTextFieldProps={{
                        fv_value: fv_value,
                        _value: _value,
                        readOnlyClasses: readOnlyClasses,
                        getNotificationTemplate: this.getNotificationTemplate,
                    }}
                    validation={validation}
                />
            );
        }

        renderOnfido() {
            return <span></span>

        }

        renderPep() {
            return <span></span>
        }

        renderJSON(elemRef: Ref<HTMLTextAreaElement>, fv_key, label, readOnly, formReference) {
            return <JsonValueEditor name={fv_key}
                                    label={label}
                                    value={fv_value.value?.value}
                                    readOnly={readOnly}
                                    elemRef={elemRef}
                                    valueChangeCbk={
                                        newValue => fv_value.valueChangeCbk(fv_key, newValue)
                                    }/>
        }

        private getNotificationTemplate(props) {
            return getNotificationTemplate(props);
        }

        renderToolTip(title, tgt) {
            return tgt;
        }

        renderAnnotated(fv_key, fv_value, tgt, readonly, modes, xs,dataItems) {
            if (readonly) {
                return <AnnotatorBarRO fv_key={fv_key} tgt={tgt} modes={modes} xs={xs} dataItems={dataItems}/>
            } else {
                return <AnnotatorBar fv_key={fv_key} tgt={tgt} modes={modes} xs={xs} dataItems={dataItems}/>
            }
        }
    }

    const designMode = useContext(DesignModeContext);
    const dispatch = useDispatch();
    const designerActions = useDesignerActions(dispatch);


    const [deleteIsShown, setDeleteIsShown] = React.useState(false);
    const [isDragOver, setIsDragOver] = React.useState(false);
    const selectedField = useSelector<RootState, RootState['designer']['selectedField']>(state => state.designer?.selectedField);

    const readOnlyClasses = useStyles();

    const renderers = new WebRenderers();
    if (designMode) {
        const hiddenEl = fv_value.properties && fv_value.properties['visibility'] && fv_value.properties['visibility'] === 'hidden';

        return <WithDrag dragProps={fv_value} type={element}>
            {(drag, isDragging) => <div
                ref={drag}
                className={(isDragOver && readOnlyClasses.designerIsDragOverIndicateTop || " ") + " " + (!isDragOver && selectedField && selectedField.id === fv_value.id && readOnlyClasses.designerFieldSelected || " ")}
                onFocusCapture={(evt) => {
                    if (evt.target && evt.target['dataset'] && evt.target['dataset'].button) {
                        // skip next actions as manage(delete) button clicked
                    } else {
                        evt.preventDefault();
                        evt.stopPropagation();
                        designerActions.selectField({fieldId: fv_value.id})
                    }
                }}
                onClick={(evt) => {
                    if (evt.target && evt.target['dataset'] && evt.target['dataset'].button) {
                        // skip next actions as manage(delete) button clicked
                    } else {
                        evt.preventDefault();
                        evt.stopPropagation();
                        designerActions.selectField({fieldId: fv_value.id});
                    }
                }}
                onDragOver={(e) => {
                    setIsDragOver(true);

                    e.stopPropagation();
                    e.preventDefault();
                }}
                onDragLeave={(e) => {
                    setIsDragOver(false);

                    e.stopPropagation();
                    e.preventDefault();
                }}
                onDrop={
                    hiddenEl ? undefined :
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            designerActions.onDropInPlace({type: 'field', fieldId: fv_value.id});
                        }}
                onDragStart={
                    hiddenEl ? undefined :
                        (e) => {
                            e.stopPropagation();
                            designerActions.onDragStart({type: 'field', fieldId: fv_value.id})
                        }
                }
                onMouseEnter={() => setDeleteIsShown(true)}
                onMouseLeave={() => setDeleteIsShown(false)}

                style={{position: 'relative', ...(fv_value.properties && fv_value.properties['visibility'] ? {visibility: 'hidden'} : {})}}
            >

                <GenericFieldComponentSimpleBase {...fv_value}
                                                 renderers={renderers}

                />

                {deleteIsShown && <div style={{position: 'absolute', bottom: '0px', right: '0px'}}>
                    <IconButton data-button="delete" onClick={(e) => {
                        e.stopPropagation();
                        designerActions.removeField({fieldId: fv_value.id})
                    }}>
                        <DeleteIcon fontSize="large" color="primary"/>
                    </IconButton>
                </div>}

            </div>}
        </WithDrag>
    } else {

        return <GenericFieldComponentSimpleBase {...fv_value}
                                                renderers={renderers}


        />
    }
}

/**
 * render form field selected check notification if field is matched.
 * @param props the form field
 */
export const getNotificationTemplate =
    (formField) => renderFieldLinkedCheckNotification(formField as FormField & { checkMessages?: ICheck[] });

/** render form field selected check notification matching the selected check's linked checks
 * if field is matched.
 * @param formField
 */
const renderFieldLinkedCheckNotification = (formField: FormField & { checkMessages?: ICheck[] }) => {
    const {selectedCheck} = useSelector((state: RootState) => {
        return {
            selectedCheck: state.selectedCheck
        };
    });

    const foundCheckOrLinkedCheck = [
        selectedCheck,
        ...selectedCheck && Array.isArray(selectedCheck.linked)
            ? selectedCheck.linked
            : []
    ]
        .filter(check => !!check)
        .find((check) => showFieldCheckNotification(formField, check))


    return renderFieldCheckNotification(formField, foundCheckOrLinkedCheck ? foundCheckOrLinkedCheck : selectedCheck);
};

/**
 * @param check
 * @param field
 * @return true if form field is related to check notification else false.
 */
const showFieldCheckNotification = (field: FormField & { checkMessages?: ICheck[] }, check: ICheck | null): boolean => {
    let showNotifications = false;
    if (check) {
        if (field.checkMessages && !!field.checkMessages.find((fieldCheck) => {
            return fieldCheck.uuid === check.uuid
        })) {
            showNotifications = true
        }
    }
    if (!showNotifications || !field.checkMessages || field.checkMessages.length <= 0) {
        return false;
    } else {
        return true;
    }
};

/**
 * render form field check notification if field is matched.
 * @param props
 */
function renderFieldCheckNotification(props, selectedCheck): JSX.Element {
    if (!showFieldCheckNotification(props, selectedCheck)) {
        return <></>
    }
    return props.checkMessages.filter((check: ICheck) => {
        return selectedCheck && selectedCheck.uuid === check.uuid;
    }).map((check: ICheck) => {
        const fieldId = props.id
        const fieldsMeta = check.fieldMetadata && check.fieldMetadata[fieldId];

        const color = check.status === 2 ?
            (check.service === 'manual' || check.service === 'dilligence') ? '#039be5'
                : '#ffcc00'
            : '#99cc33'
        let checkIcon;
        if (fieldsMeta?.avatar) {
            checkIcon =
                <Avatar src={fieldsMeta.avatar} style={{width: 16, height: 16, boxShadow: `0 0 0 2px ${color}`}}/>
        } else if (fieldsMeta?.useAvatar && check.avatar) {
            checkIcon = <Avatar src={check.avatar} style={{width: 16, height: 16, boxShadow: `0 0 0 2px ${color}`}}/>
        } else {
            checkIcon = check.status === 2 ?
                <WarningIcon style={{color: color}}/> :
                <CheckIcon style={{color: color}}/>
        }

        let text = fieldsMeta?.text || check.message;
        //add old value text
        if (check.status === 2 && props.id && check.fieldMetadata && check.fieldMetadata[props.id]) {
            if (typeof text === 'string') {
                if (fieldsMeta && fieldsMeta.oldValue !== undefined) {
                    const oldValue = fieldsMeta.oldValue
                    text = text.concat(` ${oldValue !== null ? `Previous value: <b>${oldValue}</b>` : 'Previous value was empty'}`);
                }

            }
        }
        const text_2 = <div dangerouslySetInnerHTML={{__html: text}}/>

        const iconWithTip = text && <Tooltip title={text_2}>{checkIcon}</Tooltip> || checkIcon


        return <span key={"span_" + check.uuid}>{iconWithTip}</span>;
    })
}

const AnnotatorBar = (props: { fv_key: string, tgt: JSX.Element, modes: "" | string[], xs: undefined | GridSize,dataItems:{[key:string]:string} }) => {
    const dispatch = useDispatch();
    const annotatorActions = useAnnotatorActions(dispatch);

    const handleAnnotate = (key, mode) => {
        annotatorActions.showAnnotatorPopup({fieldId: key, mode});
    }

    const {fv_key, tgt, modes,dataItems} = props;
    const annotatorClasses = useAnnotatorStyles();

    const store = getStore();
    const state = store.getState();
    const hasAnnotation = state.annotator?.annotations[fv_key];
    const hasEdit = state.annotator?.edits[fv_key];
    const isTouched = !!hasAnnotation || !!hasEdit;
    const [touched, setTouched] = useState({hasAnnotation, hasEdit})

    useEffect(() => {
        return store.subscribe(() => {
            const state = store.getState();
            const hasAnnotation = state.annotator?.annotations[fv_key];
            setTouched(hasAnnotation);
        })
    }, [])

    return <Grid item xs={12} className={annotatorClasses.annotatedElement+ ' generic-field-annotator'} {...dataItems}>
        {tgt}
        {modes &&
            <div className={`${annotatorClasses.annotationButtonContainer} ${isTouched ? 'active' : ''}`}>
                <Grid container direction={"row"}

                >
                    {modes.indexOf('edit') != -1 && <Tooltip title={hasAnnotation?.value ?? "Edit Value"}>
                        <Grid item className={`${annotatorClasses.annotationButton} ${hasEdit ? 'active' : ''}`}>
                            <EditIcon
                                onClick={evt => handleAnnotate(fv_key, 'edit')}/>
                        </Grid>
                    </Tooltip> || null}
                    {modes.indexOf('annotate') != -1 && <Tooltip title={hasEdit?.value ?? "Annotate Value"}>
                        <Grid item
                              className={`${annotatorClasses.annotationButton} ${hasAnnotation ? 'active' : ''}`}><AddIcon
                            onClick={evt => handleAnnotate(fv_key, 'annotate')}/></Grid>
                    </Tooltip> || null}
                </Grid>
            </div> || null}
    </Grid>

}

const AnnotatorBarRO = (props: { fv_key: string, tgt: JSX.Element, modes: string, xs: undefined | GridSize,dataItems:{[key:string]:string} }) => {
    const {fv_key, tgt, modes, xs,dataItems} = props;
    const store = getStore();
    const editField = useEditField()

    const getHasAnnotations = () => {
        const state = store.getState();
        const hasAnnotation = state.annotator?.annotations && state.annotator?.annotations[fv_key];
        return hasAnnotation;
    }
    const [hasAnnotation, setHasAnnotation] = useState(getHasAnnotations)
    useEffect(() => {
        return store.subscribe(() => {
            const _hasAnnotation = getHasAnnotations();
            setHasAnnotation(_hasAnnotation)
        });
    }, [])


    // const xs_tgt = xs ? <Grid item md={xs} style={{
    //     paddingLeft: "16px",
    //     paddingRight: "16px",
    // }}>{tgt}</Grid> : tgt;

    const xs_tgt = editField ? tgt : <Grid
        item
        className={`generic-field-annotator-ro ${hasAnnotation ? 'annotated' : ''}`}
        xs={12}
        sm={12}
        md={xs ?? 12}
        style={{
            paddingLeft: "16px",
            paddingRight: "16px",
        }}
        {...dataItems}
    >{tgt}</Grid>;

    if (hasAnnotation) {
        return <AnnotationContext.Provider value={hasAnnotation}>
            {xs_tgt}
        </AnnotationContext.Provider>
    } else {
        return xs_tgt;
    }
}


