import axios from 'axios';
import {contextPath} from "../contextPath";

export const passwordPolicyApiServiceFactory = () => {
    const client = axios.create({
        baseURL: `${contextPath}api/password-policy`,
    });

    return {
        /**
         * @returns {Promise<{
                enabled: boolean,
                minLength: number,
                minDigits: number,
                minUppercase: number,
                minLowerCase: number,
                minSymbols: number,
                regex: string,
                invalidateAfterDays: number,
                passwordHistory: number,
                maxFailedAttempts: number,
                temporaryLockDelayBase: number,
                temporaryLockDelayFactor: number,
                temporaryLockMaxDelay: number,
            }>}
         */
        fetchConfig: async () => {
            const result = await client.get('/');
            return result.data;
        },
        fetchDefaultConfig: async () => {
            const result = await client.get('/default');
            return result.data;
        },
        /**
         *
         * @param props {enabled: boolean, config?: ConfigDTO}
         * @returns {Promise<ConfigDTO>}
         */
        updateConfig: async (props) => {
            const result = await client.put('/', props);
            return result.data;
        },
    };
};
