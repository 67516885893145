import { handleActions } from 'redux-actions';
import {FormDialogActions} from "../actions/formDialog.actions";

export const formDialogReducer = handleActions<any[], any>(
    {
        [FormDialogActions.Type.ACTIVE_DIALOG]: (state, action) => {
            return action.payload;
        }
    },
    {} as any
);

