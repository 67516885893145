import *  as React from 'react';
import { useContext } from 'react';
import {TextField, makeStyles, TextareaAutosize} from '@material-ui/core';
import { CustomComponentsBaseParams } from 'sancus-client-common/dist/common/generic_component_base';
import LayoutContext from 'sancus-client-common/dist/common/LayoutContext';
import { IndexDecoratorFormValidationUtilFactory } from 'sancus-client-common/dist/utils/formValidation.util';
import { useFormContext } from 'react-hook-form';
import {ReadonlyTextField} from "./generic_component";
import {readOnlyStyleFactory} from "./generic_component/GenericFieldComponentSimple";
import {useIntl} from "react-intl";
import {styled} from "@material-ui/core/styles";




/**
 * editable component view.
 * @param props
 * @constructor
 */
const EditableTextareaFC = (TC)=>(props: CustomComponentsBaseParams) => {
    const layout = useContext(LayoutContext);
    const intl = useIntl();
    const methods = useFormContext();
    const { errors } = methods;
    const parentPath = props.path && props.path.lastIndexOf('.') !== -1 ? props.path.substring(0,props.path.lastIndexOf('.')) : undefined
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.index,parentPath,intl);

    return (
        <TC  key={props.id}
                    id={props.id}
                    name={props.id}
                    autoComplete={props.autocomplete || props.id}
                    variant={'filled'}
                    fullWidth
                    minRows={5}
                    maxRows={20}
                    multiline
                    InputProps={{
                        disableUnderline: true,
                    }}
                    inputProps={{
                        'data-section': (props as CustomComponentsBaseParams & {section?: string})?.section,
                        'cam-variable-name': props['cam-variable-name'],
                        'cam-variable-type': props['cam-variable-type'],

                        'autoComplete': props.autocomplete || props.id,

                    }}
                    label={props.label}
            //data-readonly={readOnly}
                    margin="normal"
                    required={props.required}
                    error={formValidationUtil.hasFieldError(props.id, errors)}
                    helperText={formValidationUtil.getFieldErrorMessage(props.id, errors)}
                    value={props.value || ''}
                    onChange={(evt) => {
                        if(evt && evt.target) {
                            const val = evt.target.value;
                            props.fieldValidationCallbacks.onChange(val);
                            props.valueChangeCbk(props.id, val, false);
                        }
                    }}
                    onBlur={props.fieldValidationCallbacks.onBlur}
        />
    );
};

const NoPaddingTextareaAutosize = styled(TextareaAutosize)(st =>({
    paddingRight: 0,
    paddingLeft: 0,
    '& > div':{
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
    }

}),{name:'NoPaddingTextareaAutosize',classNamePrefix:'no-padding-textarea-autosize'});

const NoPaddingTextField = styled(TextField)(st =>({
    paddingRight: 0,
    paddingLeft: 0,

    '& > div':{
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
    }


}),{name:'NoPaddingTextField',classNamePrefix:'no-padding-text-field'});


const EditableTextarea = EditableTextareaFC(NoPaddingTextField)
const EditableTextArea = EditableTextareaFC(NoPaddingTextareaAutosize)

/**
 * readonly and facing layout component view.
 * @param props
 * @constructor
 */
const ReadonlyTextarea = (props: CustomComponentsBaseParams & {getNotificationTemplate: any}) => {
    const useStyles = makeStyles(readOnlyStyleFactory);
    const readOnlyClasses = useStyles();

    return (
        <ReadonlyTextField
            fv_value={props}
            _value={props.value}
            readOnlyClasses={readOnlyClasses}
            getNotificationTemplate={props.getNotificationTemplate}
        />
    );
};
/**
 * component wrapper.
 * @param props
 * @constructor
 */
const TextareaFC =(ETC,RTC) => (props: CustomComponentsBaseParams & {getNotificationTemplate: any}) => {
    const isReadOnly = props.validation.readOnly;
    const layout = useContext(LayoutContext);

    if (isReadOnly && layout === 'review'){
        return <ReadonlyTextarea {...props}/>
    }else{
        return <EditableTextarea {...props}/>
    }
};

export const Textarea = TextareaFC(EditableTextarea,ReadonlyTextarea)
export const TextArea = TextareaFC(EditableTextArea,ReadonlyTextarea)

