import * as React from "react";
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from "@material-ui/core/Typography";

function children(src:object | any[],path = [] as string[]){
    const ctv = src && Object.keys(src).map(key=>{
        const newPath = [...path,key]
        const value = src[key];
        let tv;
        if (value == null) {
            tv = null;
        }else if(Array.isArray(value) || typeof value === 'object'){
           tv = children(value,newPath);
        }else{
            tv = value;
        }
        return tv
    })
    if (ctv) {
        return <TreeItem nodeId={path.join(".")} label={path.join(".")}>{ctv}</TreeItem>
    }else{
        return <Typography variant="body2" color="textSecondary">Nothing to display</Typography>
    }
}

export default function JsonTreeViewer <T=object | any[]>(props:{
    name:string,
    label:string,
    value:T
    readOnly?:boolean
    valueChangeCbk:(value:T)=>void
}){
    return <div>
        {props.label}
        {children(props.value as any)}
        </div>
}

