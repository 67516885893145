export default {
    "name": "Signicat Login",
    "id": "com.norbloc.sancus.integrations.SignicatLogin",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://developer.signicat.com/enterprise/css/signicat-css/dist/logo/favicon/favicon-16x16.png"
    },
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "signicat_login",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "First Name",
            "type": "String",
            "value": "firstName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "firstName"
            }
        },
        {
            "label": "Last Name",
            "type": "String",
            "value": "lastName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "lastName"
            }
        },
        {
            "label": "Personal Number",
            "type": "String",
            "value": "personalNumber",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "personalNumber"
            }
        },
        {
            "label": "Logged in User Id",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "userId"
            }
        },{
            "label": "Method of authentication",
            "type": "String",
            "value": "sbid-remote",
            "editable": true,
            "choices":[
                {"name":"Bank ID","value":"sbid"},
                {"name":"Bank ID Mobile","value":"sbid-remote"},
                {"name":"Bank ID QR Code","value":"sbid-qr-remote"},

                {"name":"SMS","value":"scid-sms"},
                {"name":"Email OTP","value":"scid-email"},

                {"name":"Social","value":"social"},
            ],
            "binding": {
                "type": "camunda:inputParameter",
                "name": "method"
            }
        },{
            "label": "Method (Mapping)",
            "type": "Hidden",
            "value": "method",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "method"
            }
        }
    ]
}