import ScopedEval from "scoped-eval";

/**
 * Evaluator service.
 */
export interface SancusConditionEvaluator {
    id: number;
    /**
     * Evaluate condition.
     * - 'true' -> true
     * - 'false' -> false
     * - <string> -> script evaluation
     * - false
     * @param condition
     */
    eval(condition: string): any;

    /**
     * Update the current context.
     * @param currentContext
     */
    updateContext(currentContext?: { variables: ContextVariable[] }): void;

    /**
     * Subscribe to context updates.
     * @param notifyCallback
     */
    subscribe(notifyCallback): void;

    /**
     * Unsubscribe callback from updates.
     * @param notifyCallback
     */
    unsubscribe(notifyCallback): boolean;


}

/**
 * The context variable.
 */
export interface ContextVariable {
    variableName: string;
    variableValue: any;
}

/**
 * prepare stringified context for evaluation.
 * @param currentContext
 */
const stringifyContext = (currentContext?: { variables: ContextVariable[] }): string => {
    /**
     * The context variable with normalized value.
     */
    interface NormalizedContextVariable {
        variableName: string;
        variableValue: string;
    }

    /**
     * Normalize context variable.
     * @param field
     */
    const toContextVariable = (variable: ContextVariable): NormalizedContextVariable => {
        let { variableValue } = variable;

        if(typeof variable.variableValue === 'object') {
            variableValue = JSON.stringify(variable.variableValue);
        }
        else if(typeof variable.variableValue === 'string') {
            //DO NOT REMOVE JSON.stringify AS IT IS USED TO ESCAPE THE STRING'S CHARACTERS.
            variableValue = `'${JSON.stringify(variable.variableValue)}'`;
            variableValue = variableValue.slice(1, variableValue.length - 1);
        }
        else {
            variableValue = `${JSON.stringify(variable.variableValue)}`;
        }

        return {
            ...variable,
            variableValue,
        };
    };

    /**
     * Transform the context variables to the eval argument string script.
     * @param acc
     * @param variable
     */
    const contextVariableScriptReducer = (acc: string, variable: NormalizedContextVariable): string => {
        return acc.concat(`var ${variable.variableName}=${variable.variableValue};`);
    };

    /**
     * The current eval context script.
     */
    let context = ';';
    if(currentContext) {
        context = currentContext.variables
            .filter(variable => !variable.variableName.startsWith("#"))
            .map(toContextVariable)
            .reduce(contextVariableScriptReducer, '');
    }
    return context;
}

let counter = 0;

class ContextEvaluator implements SancusConditionEvaluator {
    private subscriptionCallbacks: any[] = [];
    constructor(public id:number, private ctxv: {[key: string]: any},private parent?: SancusConditionEvaluator) {
    }

    /**
     * Evaluate condition on given context.
     * - 'true' -> true
     * - 'false' -> false
     * - <string> -> script evaluation
     * - false
     * @param condition
     */

    private evaluateConditionWithContext = (condition: string) => {
        if (condition === 'true') {
            return true;
        }else if (condition === 'false') {
            return false;
        }else{
            // console.debug(`evaluating: ${context + condition}`);
            // const result = eval(context + condition);
            return this.scopedEval(condition);
        }
    };

    eval(condition: string): boolean | undefined {

        if (typeof condition !== "string"){
            console.log("Condition must be a string");
            return false;
        }
        try{
            return this.evaluateConditionWithContext(condition);
        }catch (e){
            if (this.parent){
                return this.parent.eval(condition);
            }
            return undefined;
        }


    }
    updateContext(currentContext?: { variables: ContextVariable[] }): void {
        // ctx = stringifyContext(currentContext);
        this.ctxv = currentContext?.variables
            .filter(variable => !variable.variableName.startsWith("#"))
            .reduce((acc, variable) => {acc[variable.variableName] = variable.variableValue; return acc;}, {}) || {};
        this.subscriptionCallbacks.forEach(callback => callback());
    }

    subscribe(notifyCallback): void {
        this.subscriptionCallbacks.push(notifyCallback);
    }
    unsubscribe(notifyCallback): boolean {
        return !!this.subscriptionCallbacks.splice(this.subscriptionCallbacks.indexOf(notifyCallback), 1);
    }

    private scopedEval(expr:string) {
        const topLevel = this.parent == null;
        const proxy = new Proxy(this.ctxv, {
            get: function(target, prop) {
                if (prop === '&') return target;
                const ret_val = target[prop as string];
                const has_key = target.hasOwnProperty(prop as string);


                if (!has_key){
                    if (topLevel) {
                        return undefined;
                    }else{
                        throw new Error(`Variable ${prop as string} is not defined in the context`);
                    }
                }else{
                    if (ret_val?.postProcessGet) {
                        return ret_val.postProcessGet();
                    }else {
                        return ret_val;
                    }
                }
            },has(target: { [p: string]: any }, p: string | symbol): boolean {
                return target[p as string] !=null
            }
        })

        const scopedEval = new ScopedEval();
        if (expr.indexOf('${') > -1) {
            const v = scopedEval.eval('`'+expr+'`',proxy)
            //FIXME: MUst do this inside the context
            if (v && typeof(v) == 'string'){
                return v.replaceAll('undefined','')
            }else{
                return v;
            }

        }else {
            return scopedEval.eval(expr, proxy);
        }
        // const evaluator = new Function(...Object.keys(this.ctxv),`return ${expr};`);
        // return evaluator.apply(null, Object.values(ctxv));


    }




}
/**
 * Context evaluator factory.
 * @param currentContext
 * @param parent
 */
export const makeContextEvaluator = (currentContext?: { variables: ContextVariable[] },parent?:SancusConditionEvaluator): SancusConditionEvaluator => {
    // let ctx = stringifyContext(currentContext);
    let ctxv = currentContext?.variables
        .filter(variable => !variable.variableName.startsWith("#"))
        .reduce((acc, variable) => {acc[variable.variableName] = variable.variableValue; return acc;}, {}) || {};


    return new ContextEvaluator(counter++, ctxv,parent);
}
