import { handleActions } from 'redux-actions';
import {FormContainerActions} from "../actions/formContainer.actions";

export const formContainerValidationErrorReducer = handleActions<any, any>(
    {

        [FormContainerActions.Type.SET_VALIDATION_ERROR]: (state, action) => {
            return action.payload;
        }
    },
    {} as any
);