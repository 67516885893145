import 'proxy-polyfill' //Required for react-forms-hook to prevent (near) infinite loop in IE11

import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate,
    useLocation,
    useResolvedPath,
    useParams,
    useSearchParams, matchRoutes, UNSAFE_RouteContext, useNavigation, createBrowserRouter, RouterProvider, Outlet
} from 'react-router-dom';
import {Dashboard} from './pages/Dashboard';
import {Tasks} from './pages/Tasks';
import {RunJourneys} from "./pages/RunJourneys";
import SignIn from './pages/SignIn';
import {LoggedUserContext} from "./context/loggedUser/loggedUserContext";
import CustomizedAlert from "./components/CustomizedAlert";
import GlobalLoader from "./components/GlobalLoader";
import {contextPath, contextBaseUri,publicURL} from "./contextPath";
import {makeStyles} from "@material-ui/core/styles";
import {Header} from "./components/Header";

import {AlertContext} from "./context/alert/alertContext";
import {UsersContext} from "./context/users/usersContext";
import {groupsUrl, serverConfigUrl, usersUrl} from "./variables";
import {GroupsContext} from "./context/groups/GroupsContext";
import {ServerConfigContext} from "./context/serverConfig/serverConfigContext";
import Grid from "@material-ui/core/Grid";
import ForgotPassword from "./pages/forgotPassword";
import {SetupTFA, SetupTFAState} from "./pages/setupTFA";
import { ForcePasswordPolicyReset } from "./pages/forcePasswordPolicyReset.component";
import ForgotPasswordEnterOtp from "./pages/forgotPasswordEnterOtp";
import ResetPassword from "./pages/resetPassword";
import {ProvideForgotPasswordService} from "./context/users/forgotPassword.service";
import {getStore} from "sancus-client-common/dist/store";
import {Private} from "./components/privateRoute";
import GroupManagement from "./pages/GroupManagement.component";
import WorkflowManagement from "./pages/WorkflowManagement.component";
import UserManagement from "./pages/UserManagement";
import { PasswordPolicy } from "./pages/passwordPolicy.component";
import {ConfirurationManagement, ListConfigurationProperties} from "./pages/ConfigurationManagement";
import {Journey} from "./pages/Journey";
import {TFALogin} from "./pages/TFALogin";


const useStyles = makeStyles((theme) => ({
    screen: {
        minHeight: '100vh',
    },
    layoutContainer: {
        backgroundColor: theme.palette.secondary.main,
        overflow: "auto"
    }
}));

function StoreLocation() {

    const location = useLocation();
    const routeContext= useContext(UNSAFE_RouteContext);
    const matches = routeContext.matches;

    useEffect(() => {
        // console.log({matches})
    }, [matches]);

    useEffect(() => {
        if ('/' !== location.pathname && (location.pathname.includes("/dashboard") || location.pathname.includes("/tasks") || location.pathname.includes("/run_journeys"))) {
            console.debug('useEffect last known location for storing', location.pathname);
            sessionStorage.setItem('lats_location',  location.pathname);
        }
    }, [location]);
    return <></>
}

function RootSimple(props){
    const location = useLocation();

    return <ProvideForgotPasswordService>
        <StoreLocation/>
        <Outlet/>
    </ProvideForgotPasswordService>
}

function Root(props){
    const classes = useStyles();
    return <Grid className={classes.screen} item xs container direction="row" justifyContent="flex-start" alignItems="stretch">
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" style={{  minHeight: '100vh'}}>
            <Header/>
            <RootSimple/>
        </Grid>
    </Grid>
}




function App() {
    console.debug("PUBLIC_URL",publicURL)

    const loginUrl = `${contextPath}api/users/login`;
    const {LoggedUserState, setUserLoggedIn, setUserLoggedOut} = useContext(LoggedUserContext);
    // const {storeFetchedUsers} = useContext(UsersContext);
    // const {storeFetchedGroups} = useContext(GroupsContext);
    const {showAlert} = useContext(AlertContext);
    const {ServerConfigState, storeFetchedServerConfig} = useContext(ServerConfigContext);
    const [isLoading, setLoading] = useState(false);


    // here is a code for support/show GlobalLoader on each axios call
    useEffect(() => {
        // declare a request interceptor for better loading indication
        axios.interceptors.request.use(config => {
            // perform a task before the request is sent

            if (config.showLoading !== false) {
                setLoading(true);
            }
            return config;
        }, error => {
            const config = error.config;
            if (config?.showLoading !== false) {
                setLoading(false);
            }
            // handle the error
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // do something with the response data
            const config = response.config;
            if (config?.showLoading !== false) {
                setLoading(false);
            }
            return response;
        }, error => {
            const config = error.config;
            if (config?.showLoading !== false) {
                setLoading(false);
            }
            // handle the response error
            return Promise.reject(error);
        });
    }, []);

    // check if user logged in and get user info
    // also if user logged In we fetch all Camunda users
    useEffect(() => {
        console.debug(`SignIn:  check loggedIn request request ${loginUrl}`);

        /*
        function getAllCamundaUsers() {
            axios({
                method: 'get',
                url: usersUrl,
            })
                .then((response) => {
                        console.debug('App.js: getAllCamundaUsers response:', response);
                        //, add imageURL link
                        storeFetchedUsers({
                            all: response.data.map((user) => ({
                                ...user, imageURL: user.imageURL || `${usersUrl}/${user.id}/image`,
                            }))
                        });
                    },
                    error => showAlert(error, 'error'));
        }

        function getAllCamundaGroups() {
            axios({
                method: 'get',
                url: groupsUrl,
            })
                .then((response) => {
                        console.debug('App.js: getAllCamundaGroups response:', response);
                        //, add imageURL link
                        storeFetchedGroups(response.data);
                    },
                    error => showAlert(error, 'error'));
        }
        */

        axios({
            method: 'get',
            url: serverConfigUrl,
        })
            .then((response) => {
                    console.debug('App.js: getServerConfig response:', response);
                    storeFetchedServerConfig(response.data);
                },
                error => showAlert(error, 'error'));


        axios({
            method: 'get',
            url: loginUrl,
        })
            .then((response) => {
                    const {data} = response;
                    console.debug('SignIn: response:', response);

                    //TODO FIX IT After backend fix
                    data.imageURL = data.imageURL || `${usersUrl}/${data.id}/image`;
                    setUserLoggedIn({user: data});

/*                    getAllCamundaUsers();
                    getAllCamundaGroups();*/
                },
                error => {
                    if ((error.status && error.status !== 401) || (error.response && error.response.status !== 401)) {
                        showAlert(error, 'error');
                    } else {
                        setUserLoggedOut({user: {}});
                        console.debug(`SignIn: user doesn't loggedIn or expired`);
                    }
                }
            )
    }, []);

    useEffect(() => {
        const onReduxStoreDataChange = () => {
            const inProgress = getStore().getState().inProgress;
            setLoading(inProgress);
        };

        // Redux store
        const store = getStore();
        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);

        // code here work like in componentWillUnmount()
        return () => unsubscribeStore();
    }, []);

    console.debug('App.js: LoggedUserState: ', LoggedUserState);

    let view;
    if (LoggedUserState.isLoggedIn) {
        const {su,admin,officer,manager} = LoggedUserState.user;

        const defaultRoute = (su || officer || manager) ? '/dashboard' : '/run_journeys';

        const routes = [
            <Route key={"root"} exact path='/' element={<Navigate to={defaultRoute} replace/>}/>,
            <Route key={"user_management"} exact path={'/dashboard/user_management'} element={
                <Private>
                    <UserManagement/>
                </Private>}/>,
            <Route key={"password_policy"} exact path={'/dashboard/password_policy'} element={
                <Private>
                    <PasswordPolicy/>
                </Private>
            }/>,
            <Route key={"group_management"} exact path={'/dashboard/group_management'} element={
                <Private>
                    <GroupManagement/>
                </Private>
            }/>,
            (su && <Route key={"workflow_management"} exact path={'/dashboard/workflow_management/:workflowId?'} element={
                <Private>
                    <WorkflowManagement/>
                </Private>
            }/> || null),
            <Route key={"forcePasswordPolicyReset"} exact path='/dashboard/forcePasswordPolicyReset' element={
                <Private>
                    <ForcePasswordPolicyReset/>
                </Private>
            }/>,

            (su && <Route key={"configuration"} exact path={'/dashboard/configuration'} element={<ListConfigurationProperties/>}/> || null),
            (su && <Route key={"configuration_category"} exact path={'/dashboard/configuration/:category/:name'} element={<ConfirurationManagement/>}/> || null),

            (su || officer || manager) && <Route key={"dashboard"} path={'/dashboard'} exact element={<Dashboard/>}>
                <Route exact path={'setup_tfa/:userId?'} element={
                    <Private>
                        <SetupTFAState/>
                    </Private>
                }>

                </Route>
            </Route>,
            (su || officer || manager) && <Route key={"tasks"} path={'/tasks/:task_id?'} element={<Tasks/>}/>,
            <Route key={"run_journeys"} path={'/run_journeys'} element={<RunJourneys/>} >

            </Route>,
            <Route key={"resume_journey"}  path={'/run_journeys/:journeyName/:processInstanceId?/:taskId?'} element={<Journey/>} />,


            /*redirect any not know route to dashboard*/
            <Route key={"default_route"} path="*" element={<Navigate to={defaultRoute} replace/>}/>
        ]

        const topLevel = <Route path={"/"} element={<Root/>}>{routes}</Route>

        view = <BrowserRouter basename={contextBaseUri}>
                <Routes>
                    {topLevel}
                </Routes>
            </BrowserRouter>

    } else if (LoggedUserState.isLoggedIn === false) {
        const routes = [
            <Route exact path='/' element={<SignIn/>} />
            ];
        if (!ServerConfigState.emailIndependent){
            const extraRoutes = [

                <Route exact path='/forgotPassword' element={<ForgotPassword/>}/>,
                <Route exact path='/forcePasswordPolicyReset' element={<ForcePasswordPolicyReset/>}/>,
                <Route exact path='/activateAccount' element={<ForgotPassword flow="activateAccount"/>}/>,
                <Route exact path='/forgotPasswordEnterOtp' element={<ForgotPasswordEnterOtp/>}/>,
                <Route exact path='/resetPassword' element={<ResetPassword/>}/>,
                <Route exact path='/setupTFA' element={<SetupTFA/>}/>,
            ]
            routes.push(...extraRoutes);
        }else if (ServerConfigState.emailIndependent){
            routes.push(<Route exact path='/forcePasswordPolicyReset' element={<ForcePasswordPolicyReset/>}/>,)
        }
        routes.push(
            <Route exact path='/setupTFA' element={<SetupTFA/>}/>,
            <Route exact path='/verifyTFA' element={<TFALogin/>}/>,
            <Route path="*" element={<Navigate to='/' replace/>}/>
        );

        const topLevel = <Route path={"/"} element={<RootSimple/>}>{routes}</Route>



        view = <BrowserRouter basename={contextBaseUri}>
                        <Routes>
                            {topLevel}
                        </Routes>
                    </BrowserRouter>



    } else {
        view = <>Loading</>
    }


    return (
        <>
            <CustomizedAlert/>
            {isLoading ? <GlobalLoader progressData={isLoading}/> : ''}
            {view}
        </>
    );
}

export default App;
