import * as React from 'react';
import { useLayout } from 'sancus-client-common/dist/common/LayoutContext';
import {
    ReadonlyTextField,
    CustomTextField,
    FacingTextField,
    helperTextFromValidationAndAnnotation
} from "./generic_component";
import { TextFieldProps } from '@material-ui/core';


type CustomTextFieldProps = {
    textFieldProps: TextFieldProps & {errorText?:TextFieldProps['helperText']},
    readonlyTextFieldProps: {fv_value,_value,getNotificationTemplate,readOnlyClasses},
    validation
}
/**
 * the custom textfield wrapper view.
 * @param props
 * @constructor
 */
export const CustomTextfield = (props: CustomTextFieldProps) => {
    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly ? true : false;
    const layout = useLayout();

    if(layout === 'default' || !isReadOnly) {

        const required = props.validation && props.validation.required;

        const helperTextFormValidator = props.textFieldProps?.errorText
        const helperText = helperTextFromValidationAndAnnotation(null,props.textFieldProps);


        return (

            <CustomTextField
                {...props.textFieldProps}
                required={required}
                errorText={helperTextFormValidator}
                helperText={helperText}
                disabled={isReadOnly}
            />
        );
    }
    else if(layout === 'review') {
        return (
            <ReadonlyTextField { ...props.readonlyTextFieldProps }/>
        );
    }
    else if (layout === 'facing') {
        return (
            <FacingTextField  { ...props.readonlyTextFieldProps }/>
        );
    }
    else {
        return (
            <div>could not render textfield</div>
        );
    }

};
