import {ICheck} from "./FormContainerBase";
import contextPath from "./contextPath";
import React from "react";
import {getStore} from "../store";

type Color = 'red' | 'green' | 'yellow' | 'blue'
declare global{
    interface Window {
        sancus?:{
            theme:string,
            overrides:Partial<{
                avatar:string,
                avatar_alt:string,
                service_name:string,
                color:Color

            }>
        }
    }
}

export function getAvatarDetails(check: ICheck):{
    avatar:string,
    avatar_alt:string,
    service_message:string,
    color:Color
} {
    const overrides = window.sancus && window.sancus.overrides && check.service && window.sancus.overrides[check.service]
    let avatar = check.avatar || overrides?.avatar;
    const serviceName = check?.resolveInfo?.resolverInfo?.firstName || overrides?.service_name || check.service;

    let avatar_alt = overrides?.avatar_alt || serviceName;
    let service_message:string = serviceName && overrides?.service_message || `Verified by ${serviceName || check.service}`

    if (avatar == null) {
        switch (check.service) {
            case 'aade':
                avatar = "https://www.aade.gr/sites/default/files/aade.gr-favicon.png"
                break;
            case 'onfido':
                avatar = `https://storage.googleapis.com/norbloc_public/images/onfido.png`
                break;
            case 'jumio':
            case 'jumio-kyx':
                avatar = "https://www.jumio.com/app/uploads/2020/03/jumio-favicon-192x198-1.png"
                break;
            case 'pep':
                avatar = `https://storage.googleapis.com/norbloc_public/images/rdc.png`
                break;
            case 'gemi':
            case 'gemi-api':
            case 'gemi-web':
            case 'gemi-web-base':
                avatar = `https://publicity.businessportal.gr/static/gemiFavicon.ico`
                break;
            case 'bolagsverket':
                avatar = `${contextPath}img/bolagsverket.png`
                break;
            case "companies_house":
                // avatar = `https://developer-specs.company-information.service.gov.uk/images/royal-coat-of-arms-white.png`
                avatar = `https://www.gov.uk/assets/static/favicon-f54816fc15997bd42cd90e4c50b896a1fc098c0c32957d4e5effbfa9f9b35e53.ico`
                break;
            case 'refinitiv':
                avatar = `https://www.refinitiv.com/favicon.ico`
                break;
            case 'risk_calculator':
                avatar = `https://raw.githubusercontent.com/twbs/icons/main/icons/calculator-fill.svg`
                break;
            case 'fides':
                avatar =`https://storage.googleapis.com/norbloc_public/images/fides.png`
                break;
            case 'orbis':
                avatar = `https://login.bvdinfo.com/favicon.ico`
                break;
            case 'asia_verify':
                avatar= 'https://asiaverify.com/app/uploads/2022/04/favicon.png'
                break;
            case 'manual':
            case 'dilligence':
                avatar_alt = undefined
                break;
            default:

        }

    }


    if (!service_message) {
        switch (check.service) {
            case 'aade':
                service_message = 'Confirmed by AADE'
                break;
            case 'onfido':
                service_message = 'Confirmed by ONFIDO'
                break;
            case 'jumio':
                service_message = 'Confirmed by JUMIO'
                break;
            case 'pep':
                service_message = 'Confirmed by RDC'
                break;
            case 'gemi':
                service_message = 'Confirmed by GEMI'
                break;
            case 'gemi-web':
            case 'gemi-web-base':
                service_message = 'Confirmed by GEMI (WEB)'
                break;
            case 'gemi-api':
                service_message = 'Confirmed by GEMI (API)'
                break;
            case 'bolagsverket':
                service_message = 'Confirmed by Bolagsverket'
                break;
            case 'refinitiv':
                service_message = 'Confirmed by Refinitiv'
                break;
            case 'fides':
                service_message = 'Verified by FIDES'
                break;
            case 'orbis':
                service_message = 'Verified by Orbis'
                break;
            case 'asia_verify':
                service_message = 'Verified by Asia Verify'
                break;
            case 'manual':
            case 'dilligence':
                service_message = 'Manual check'
                break;
            default:
                service_message = `Verified by ${check.service}`

        }

    }

    let color:Color = overrides?.color;
    if (!color) {
        if (check.service == 'manual' || check.service == 'dilligence') {
            color = 'blue'
        } else if (check.status === 2) {
            color = 'yellow'
        } else if (check.status === 1) {
            color = 'green'
        } else {
            color = 'blue';
        }
    }

    return {avatar,avatar_alt,service_message,color}
}

export const FieldCheckAvatarFactory = (props:{Avatar,Tooltip,check: ICheck,doScroll:(checkUUID:string)=>void}) => {

    /**
     * todo fix re-use
     * Used to scroll to checks component
     * @param check
     */
    const scrollToServiceCheckView = () => {
        const store = getStore();
        const setSelected = (c) => { return {type: 'SET_SELECTED_CHECK', payload: c}}
        store.dispatch(setSelected(props.check))
        props.doScroll(props.check.uuid)
    }


    const colors = {
        red:'#ff0000',
        yellow:'#ffcc00',
        green:'#a6ce39',
        blue:'#039be5'
    }

    const {avatar,avatar_alt,service_message,color} = getAvatarDetails(props.check);
    const bColor = colors[color];
    let checkIcon;

    const message = props.check.message || service_message
    checkIcon =  <props.Avatar id={`${props.check.uuid}_view`}
                            key={`${props.check.uuid}_view`}
                         src={avatar && avatar.length>0?avatar:'/not_found.png'}
                         alt={avatar_alt}
                         style={{width: 24, height: 24, boxShadow: `0 0 0 2px ${bColor}` }}
                         onClick={()=>scrollToServiceCheckView()}
    ></props.Avatar>
    if (message){
        return <props.Tooltip title={message}  key={`${props.check.uuid}_tooltip`}>{checkIcon}</props.Tooltip>
    }else{
        return checkIcon;
    }
}
