import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid/index";

import Chart from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

export default function Doughnut({config, labelsColor, onClickCallback, changeIndicator,onHoverCallback}) {
    const canvasRef = useRef(null);
    const [currentChart, setCurrentChart] = useState(null);

    console.debug(`Doughnut data:`, config);
    console.debug(`onClickCallback is present:`, !!onClickCallback);

    useEffect(() => {
        let newArr;

        if (currentChart) {
            currentChart.destroy();
        }

        const myChartConfig = {
            type: 'doughnut',
            plugins: [ChartDataLabels],
            options: {
                //maintainAspectRatio: false,
                ...(config.title ? {
                    title: {
                        display: true,
                        text: config.title,
                    }
                } : {}),
                responsive: true,
                ...(onClickCallback ? {onClick: chartClickEvent} : {}),

                ...(labelsColor ? {
                    legend: {
                        labels: {
                            fontColor: labelsColor
                            //fontSize: 18
                        }
                    }
                } : {
                    legend: {
                        labels: {
                            padding: 30,
                            usePointStyle: true
                        },
                        position: 'bottom'
                    }
                }),
                plugins: {
                    datalabels: {
                        backgroundColor: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        borderColor: 'white',
                        borderRadius: 25,
                        borderWidth: 2,
                        color: 'white',
                        display: function (context) {
                            var dataset = context.dataset;
                            var count = dataset.data.length;
                            var value = dataset.data[context.dataIndex];

                            // TODO for small values 1,2 this returned false
                            //return value > count * 1.5;
                            return value;
                        },
                        font: {
                            weight: 'bold',
                            size: 16,
                        },
                        formatter: Math.round
                    }
                },
                hover: {
                    onHover: function(e) {
                        document.getElementById("doughnut").style.cursor = "pointer";
                        if (onHoverCallback){
                            const activeElement = getActiveElement(e);
                            if (activeElement) {
                                onHoverCallback(e);
                            }
                        }
                    }
                }
            },
            data: {
                labels: config.data.map(d => d.label),
                filterNames: config.data.map(d => d.filterName),
                datasets: [{
                    data: config.data.map(d => d.value),
                    backgroundColor: config.colors,
                    datalabels: {
                        anchor: 'center',
                        backgroundColor: null,
                        borderWidth: 0
                    }
                }]
            },
        };

        // Check if data is all 0s; if it is, add dummy data to end with empty label
        myChartConfig.data.datasets.forEach(dataset => {
            if (dataset.data.every(el => el === 0)) {
                dataset.backgroundColor.push('rgba(0, 0, 0, 0.1)');
                dataset.data.push(1);
                dataset.datalabels.display = false;
            }
        })

        const myChart = new Chart(canvasRef.current, myChartConfig);
        function getActiveElement(event){
            const active = myChart.getElementsAtEvent(event);
            if (active === 'undefined' || active == null || active.length === 0) {
                return;
            }

            const elementIndex = active[0]._datasetIndex;
            console.debug("elementIndex: " + elementIndex + "; array length: " + newArr.length);

            if (newArr[elementIndex] === 'undefined' || newArr[elementIndex] == null) {
                return;
            }

            const chartData = newArr[elementIndex]['_chart'].config.data;

            const idx = newArr[elementIndex]['_index'];

            const label = chartData.labels[idx];
            const filterName = chartData.filterNames[idx];
            const value = chartData.datasets[elementIndex].data[idx];
            return {label, value, filterName};
        }
        function chartClickEvent(event, array) {

            newArr = array;

            if (event === 'undefined' || event == null) {
                return;
            }
            if (newArr === 'undefined' || newArr == null) {
                return;
            }
            if (newArr.length <= 0) {
                return;
            }

            const activeElement = getActiveElement(event);
            if (!activeElement){
                return;
            }else {
                onClickCallback(activeElement);
            }
        }

        setCurrentChart(myChart);
    }, [changeIndicator]);

    return <Grid><Grid item xs={12} style={{padding: 30}}>
        <canvas id="doughnut" ref={canvasRef}/>
    </Grid></Grid>;
}
