export default {
    "name": "BankID Login",
    "id": "com.norbloc.sancus.integrations.BankIDLogin",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://www.bankid.com/assets/logo-bank-id.svg"
    },
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "bankid_login",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "First Name",
            "type": "String",
            "value": "firstName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "firstName"
            }
        },
        {
            "label": "Last Name",
            "type": "String",
            "value": "lastName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "lastName"
            }
        },
        {
            "label": "Personal Number",
            "type": "String",
            "value": "personalNumber",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "personalNumber"
            }
        },
        {
            "label": "Logged in User Id",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "userId"
            }
        }
    ]
}