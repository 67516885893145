import * as React from "react";
import {Repeatable} from "./Repeatable";
import {RepeatableGroupComponentBase} from "sancus-client-common/dist/common/RepeatableGroupBase";


export class RepeatableGroupComponent extends RepeatableGroupComponentBase {
    constructor(props) {
        super(props,{Repeatable});
    }

}