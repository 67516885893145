import _, {keyBy} from "lodash";

export interface ILoggedUserState {
    isLoggedIn: null,
    user: {
        id:string,
        email: string,
        firstName: string,
        lastName: string,
        imageURL,
        ROLES: string[],
    }
}
export interface INote {
    user?: ILoggedUserState['user'];
    text: string;
    date:Date;
}

export interface IResolveInfo {
    resolver: "user" | "service";
    resolverInfo: IResolverInfo;
}

export interface IResolverInfo {
    id:string
    firstName: string;
    lastName: string;
    date: Date;
    avatar?: string;
}

export interface ICheck {
    uuid: string;
    fields: string[];
    sections?: string[];
    service: string;
    message: string;
    metaData?: any;
    status?: any // resolve or not
    uiConfiguration: any // meta data for ui
    sectionuuid?: string // meta data for ui
    resolveInfo: IResolveInfo | null;
    notes: INote[],

    showCheck?:boolean,
    showAnnotation?:boolean,
    avatar?:string,

    fieldMetadata?:{[fieldName:string]:{
            text:string,
            avatar?:string,
            useAvatar?:boolean, //If true, check should use the check's avatar instead of the check / warning symbols
            oldValue?:any //If there was an old value for the field - Check text will be modified accordingly
        }};

    /**
     * property used to indicate relations between checks.
     */
    linked?: ICheck[];
}


export type FieldCheck = ICheck[]
export type GroupCheck = {[fieldId:string]:FieldCheck}
export type FieldOrGroupCheck = FieldCheck | GroupCheck
export type RepeatableGroupCheck = GroupCheck[]
export type RepeatableFieldCheck = FieldCheck[]
export type RepeatableCheck = RepeatableGroupCheck | RepeatableFieldCheck
export type AnyCheckType = FieldOrGroupCheck | RepeatableCheck

function idForMerge(check:ICheck){
    const service = check.service;
    switch (service){
        case "fides":
        case "fides_verifier":
            const metadata= check.metaData;
            const verifier = metadata?.verifierOrganizationName
            return `fides:${verifier}`
        default:
            return service
    }
}
export function mergeServices(services:ICheck[]){
    let groups = _.groupBy(services,idForMerge)
    groups = _.mapValues(groups,gps=>{
        return _.orderBy(gps,g=>g.status,'desc')
    })

    const merged = _.map(groups,gps=>{
        const [pivot,...linked] = gps;
        pivot.linked = linked
        return pivot
    })
    return merged
}
export function mergeServiceGroups(serviceGroups:({[key: string]: ICheck})){
    const checks =  _.values(serviceGroups)
    const merged = mergeServices(checks)
    return _.keyBy(merged, g => g.uuid)

}