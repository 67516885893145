import { createContext, useContext } from "react";
export type Layout = "default" | "facing"
    /**
     * visual representation of the review forms.
     */
    | 'review';

const LayoutContext = createContext<Layout | undefined>("default")

export default LayoutContext;

export const useLayout = () => {
    return useContext(LayoutContext);
};

export const isValidLayout = (layout: string): boolean => {
    return ['default', 'facing', 'review'].indexOf(layout) >= 0;
}