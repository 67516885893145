import React, {useState, useEffect, useContext} from 'react';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import {makeStyles} from '@material-ui/core/styles';
import {passwordPolicyApiServiceFactory} from "../services/passwordPolicyApi.service";
import {Controller, useForm} from 'react-hook-form';
import {AlertContext} from "../context/alert/alertContext";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    '@global': {
        '.passwordPolicyFormContainer': {
            padding: '16px',
        },
        '.passwordPolicyFormContainer.officerApp-MuiGrid-container .officerApp-MuiGrid-item': {
            margin: '16px 0px 8px',
        },
        '.passwordPolicyFormContainer.officerApp-MuiGrid-container .officerApp-MuiTextField-root': {
            minWidth: 450,
        },
    },
}));

const apiService = passwordPolicyApiServiceFactory();

export const PasswordPolicy = () => {
    const {showAlert} = useContext(AlertContext);
    const classes = useStyles();
    const {control, handleSubmit, errors} = useForm({
        mode: 'all',
    });
    const [config, setConfig] = useState(null);
    const [defaultConfig, setDefaultConfig] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const labels = {
        "Maximum failed attempts before lock": "Maximum failed attempts before lock",
        "Temporary Lock Delay Base": "Temporary Lock Delay Base",
        "Temporary Lock Delay Factor": "Temporary Lock Delay Factor",
        "Temporary Lock Max Delay (sec)": "Temporary Lock Max Delay (sec)"
    };

    const fetchConfiguration = async () => {
        try {
            const result = await apiService.fetchConfig();
            setConfig(result);
            setEnabled(result.enabled);
        } catch (e) {
            console.error(e);
            showAlert(`unexpected error: could not fetch configuration`, 'error');
        }
    };

    const fetchDefaultConfiguration = async () => {
        try {
            const result = await apiService.fetchDefaultConfig();
            setDefaultConfig(result);
        } catch (e) {
            console.error(e);
            showAlert(`unexpected error: could not fetch default configuration`, 'error');
        }
    };

    const handleUpdateConfig = async (formData) => {
        try {
            console.log(formData);
            const updateProps = {
                enabled: formData.enabled,
                ...formData.enabled ? {config: {...formData}} : {}
            };
            if (updateProps.config) {
                delete updateProps.config.enabled;
            }

            const result = await apiService.updateConfig(updateProps);
            showAlert(`updated successfully`, 'success');
        } catch (e) {
            console.error(e);
            showAlert(`unexpected error: could not update configuration`, 'error');
        }
    }

    const errorMessageElementFactory = (fieldError, rules = {}) => {
        const ruleMessages = [
            {type: 'required', messageProvider: (rules) => 'This field is required'},
            {type: 'min', messageProvider: (rules) => `Minimum value ${rules.hasOwnProperty('min') ? rules.min : ''}`},
        ]
        if (fieldError) {
            for (let index = 0; index < ruleMessages.length; index++) {
                const ruleMessage = ruleMessages[index];
                if (ruleMessage) {
                    if (fieldError.type === ruleMessage.type) {
                        return (
                            <Typography color={'error'}
                                        variant={'subtitle2'}>{ruleMessage.messageProvider(rules)}</Typography>
                        );
                    }
                }
            }
        }
        return null;
    }

    useEffect(async () => {
        await fetchDefaultConfiguration();
        await fetchConfiguration();
    }, []);

    return (
        <Box p={4}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Box pb={3} pl={2}>
                        <Typography variant={'h6'}>Password policy</Typography>
                    </Box>
                </Grid>
            </Grid>
            {
                (config && defaultConfig)
                    ? <Paper elevation={3}>
                        <Grid container direction={'column'} className={'passwordPolicyFormContainer'}>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name={'enabled'}
                                    rules={{}}
                                    defaultValue={config.enabled}
                                    render={({onChange, onBlur, value}) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Switch name="enabled"
                                                            color={'primary'}
                                                            checked={value}
                                                            onBlur={onBlur}
                                                            onChange={(evt) => {
                                                                setEnabled(evt.target.checked);
                                                                onChange(evt.target.checked);
                                                            }}
                                                    />
                                                }
                                                label={value ? 'Enabled' : 'Disabled'}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Grow in={enabled} unmountOnExit timeout={300}>
                                    <Grid container direction={'row'}>
                                        <Grid md={6} sm={12} item container direction={'column'}>
                                            <Grid item>
                                                <Typography>Password configuration</Typography>
                                                <Divider/>
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'minLength'}
                                                    rules={{
                                                        required: true,
                                                        min: 1,
                                                    }}
                                                    defaultValue={config.minLength}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Minimum length"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.minLength, {required: true, min: 1,})
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'minDigits'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.minDigits}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Minimum digits"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.minDigits, {required: true, min: 0,})
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'minUppercase'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.minUppercase}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Minimum uppercase"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.minUppercase, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'minLowercase'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.minLowercase}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Minimum lowercase"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.minLowercase, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'minSymbols'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.minSymbols}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Minimum symbols"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.minSymbols, {required: true, min: 0,})
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'maxConsecutive'}
                                                    rules={{}}
                                                    defaultValue={config.maxConsecutive}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                type={'number'}
                                                                label="Maximum Consecutive digits allowed (0 to disable)"
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid md={6} sm={12} item container direction={'column'}>
                                            <Grid item>
                                                <Typography>Lock configuration</Typography>
                                                <Divider/>
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'invalidateAfterDays'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.invalidateAfterDays}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Invalidate password after (days)"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.invalidateAfterDays, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'passwordHistory'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.passwordHistory}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label="Do not allow password to match previous"
                                                                type={'number'}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(evt) => {
                                                                    onChange(evt.target.value);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.passwordHistory, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'maxFailedAttempts'}
                                                    rules={{
                                                        required: true,
                                                        min: 1,
                                                    }}
                                                    defaultValue={config.maxFailedAttempts}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <Tooltip
                                                                title="Defines the maximum number of attempts a user can try to login or reset password before this user is locked">
                                                                <TextField
                                                                    required
                                                                    label={labels["Maximum failed attempts before lock"]}
                                                                    type={'number'}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(evt) => {
                                                                        onChange(evt.target.value);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.maxFailedAttempts, {
                                                        required: true,
                                                        min: 1,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'temporaryLockDelayBase'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.temporaryLockDelayBase}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <Tooltip
                                                                title="Defines the base by which the delay is calculated after an unsuccessful login attempt">
                                                                <TextField
                                                                    required
                                                                    label={labels["Temporary Lock Delay Base"]}
                                                                    type={'number'}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(evt) => {
                                                                        onChange(evt.target.value);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.temporaryLockDelayBase, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'temporaryLockDelayFactor'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.temporaryLockDelayFactor}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <Tooltip
                                                                title="Defines the factor by which the delay is calculated after an unsuccessful login attempt">
                                                                <TextField
                                                                    required
                                                                    label={labels["Temporary Lock Delay Factor"]}
                                                                    type={'number'}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(evt) => {
                                                                        onChange(evt.target.value);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.temporaryLockDelayFactor, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={'temporaryLockMaxDelay'}
                                                    rules={{
                                                        required: true,
                                                        min: 0,
                                                    }}
                                                    defaultValue={config.temporaryLockMaxDelay}
                                                    render={({onChange, onBlur, value}) => {
                                                        return (
                                                            <Tooltip
                                                                title="Defines the maximum amount of time (in seconds) for which a user must wait until they are able to try to login again">
                                                                <TextField
                                                                    required
                                                                    label={labels["Temporary Lock Max Delay (sec)"]}
                                                                    type={'number'}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(evt) => {
                                                                        onChange(evt.target.value);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    }}
                                                />
                                                {
                                                    errorMessageElementFactory(errors.temporaryLockMaxDelay, {
                                                        required: true,
                                                        min: 0,
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grow>
                            </Grid>
                            <Grid item>
                                <Grow in={!enabled} unmountOnExit timeout={200}>
                                    <>
                                        <Typography>{`Default Camunda server's configuration is used!`}</Typography>
                                        <Typography variant={"body2"}>
                                            {labels["Maximum failed attempts before lock"]}: <strong>{defaultConfig.maxFailedAttempts}</strong>.
                                            {labels["Temporary Lock Delay Base"]}: <strong>{defaultConfig.temporaryLockDelayBase}</strong>.
                                            {labels["Temporary Lock Delay Factor"]}: <strong>{defaultConfig.temporaryLockDelayFactor}</strong>.
                                            {labels["Temporary Lock Max Delay (sec)"]}: <strong>{defaultConfig.temporaryLockMaxDelay}</strong>.
                                        </Typography>
                                    </>
                                </Grow>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary"
                                        onClick={handleSubmit(handleUpdateConfig)}>Update</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    : null
            }

        </Box>
    );
};