import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import axios from "axios";
import {contextPath} from "../contextPath";
import Logoipsum from "./../assets/logoipsum.svg";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {AlertContext} from "../context/alert/alertContext";
import Grid from "@material-ui/core/Grid";
import NavTabs from "./NavTabs";
import {usersUrl} from "../variables";
import {Box} from "@material-ui/core";
import {Notifications} from "./Notifications";
import {MenuOpenSharp, Menu as MenuIcon} from "@material-ui/icons";

const logoutURL = `${contextPath}api/users/logout`;

export const Header = () => {
    const {setUserLoggedOut, LoggedUserState, setUserLoggedIn} = useContext(LoggedUserContext);
    const {showAlert} = useContext(AlertContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useNavigate();

    const [drag, setDrag] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        setUserLoggedOut();
        sessionStorage.removeItem('lats_location')
        axios({
            method: 'post',
            url: logoutURL
        }).then(
            () => {
                history('/');
            },
            error => showAlert(error, 'error'));
    };
    const theme = useTheme();
    const showNotifications = true;


    const {su,admin,officer,manager} = LoggedUserState?.user ?? {};

    return (
        <Grid item container direction="row" style={{boxShadow: '0 1px 1px 0 #e6e6e6', marginBottom: '1px'}}>

            <Grid item container direction="row" alignItems="flex-end" xs>
                <div className={"logContainer"} style={{
                    height:60,
                    backgroundColor: theme.customProps && theme.customProps.logoContainerColor,
                }}>
                    <img height={40} src={theme.customProps && theme.customProps.logoUrl}
                         style={{margin: '10px 40px 10px 40px'}}/>
                </div>
                <NavTabs/>
            </Grid>

            <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {(showNotifications) ?
                        (<Box m={1}>
                            <Notifications/>
                        </Box>) : <></>
                    }

                    <Box m={1}>
                        <Avatar src={LoggedUserState.user.imageURL && LoggedUserState.user.imageURL || ''}
                                style={drag ? {backgroundColor: "black"} : {}}
                            // onClick={handleClick}

                                onDrop={event => {
                                    event.preventDefault();
                                    setDrag(false);

                                    const imageURL = LoggedUserState.user.imageURL ? LoggedUserState.user.imageURL + '?key=' + Math.random() :
                                        `${usersUrl}/${LoggedUserState.user.id}/image?key=` + Math.random();

                                    if (event.dataTransfer.items[0]?.type?.indexOf("image/") === 0) {
                                        const formData = new FormData();
                                        const file = event.dataTransfer.files[0]
                                        formData.append('photo', file)
                                        axios.post(`${contextPath}api/users/image`, formData).then(() => {
                                            setUserLoggedIn({user: {...LoggedUserState.user, imageURL}})
                                        }).catch(error => showAlert(error, 'error'));

                                    } else {
                                        axios.delete(`${contextPath}api/users/image`).then(() => {
                                            setUserLoggedIn({user: {...LoggedUserState.user, imageURL}})
                                        }).catch(error => showAlert(error, 'error'));
                                    }
                                }}

                                onDragOver={event => {
                                    event.preventDefault();
                                    console.log("onDragOver", event)
                                }}

                                onDragEnter={event => {
                                    setDrag(true)
                                }}


                                onDragExit={event => {
                                    setDrag(false)
                                }}

                        />
                    </Box>

                    <Box m={1} mr={5}>

                        <Typography color="textSecondary" variant="body1">
                            {(LoggedUserState.user.firstName || '')} {(LoggedUserState.user.lastName || '')}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                            {(LoggedUserState.user.roles && LoggedUserState.user.roles.join(', ') || 'officer').toUpperCase()}
                        </Typography>

                    </Box>
                    <Box m={1} mr={5} onClick={handleClick}>
                        <MenuIcon/>
                    </Box>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {
                            LoggedUserState.user.roles && LoggedUserState.user.roles.indexOf('ADMIN') > -1
                                ? (
                                    [
                                        <MenuItem key={'user_management'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/user_management`);
                                        }}>
                                            User Management
                                        </MenuItem>,
                                        <MenuItem key={'group_management'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/group_management`);
                                        }}>
                                            Group Management
                                        </MenuItem>,
                                        (su && <MenuItem key={'workflow_management'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/workflow_management`);
                                        }}>
                                            Workflow Management
                                        </MenuItem>) || null ,

                                        (su && <MenuItem key={'configuration_management'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/configuration`);
                                        }}>
                                            Configuration Management
                                        </MenuItem>) || null,

                                        <MenuItem key={'force_reset_password'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/forcePasswordPolicyReset`,
                                                {state:{userNameOrEmail: LoggedUserState.user.id}});
                                        }}>
                                            Reset password
                                        </MenuItem>,

                                        (!LoggedUserState.user.hasTFA && <MenuItem key={'setup_tfa'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/setup_tfa`,
                                                {state:{userId: LoggedUserState.user.id}});
                                        }}>
                                            Setup TFA
                                        </MenuItem>) || null,

                                        <MenuItem key={'password_policy'} onClick={() => {
                                            handleClose();
                                            history(`/dashboard/password_policy`);
                                        }}>
                                            Password Policy
                                        </MenuItem>
                                    ]
                                ) : [
                                    <MenuItem key={'force_reset_password'} onClick={() => {
                                        handleClose();
                                        history(`/dashboard/forcePasswordPolicyReset`,
                                            {state:{userNameOrEmail: LoggedUserState.user.id}});
                                    }}>
                                        Reset password
                                    </MenuItem>,

                                     (!LoggedUserState.user.hasTFA && <MenuItem key={'setup_tfa'} onClick={() => {
                                        handleClose();
                                        history(`/dashboard/setup_tfa`,
                                            {state:{userId: LoggedUserState.user.id}});
                                    }}>
                                        Setup TFA
                                    </MenuItem>) || null
                                ]
                        }
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </Grid>
    );
};
