import React, {useState, useCallback, useRef, useEffect, useContext} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import JourneyCard from "../components/runJourneyComponents/JourneyCard";
import {Journey} from "./Journey";
import axios from "axios";
import {contextPath} from "../contextPath";
import {AlertContext} from "../context/alert/alertContext";
import SearchBar from "material-ui-search-bar";
import _ from "lodash";
import {Box, Divider, Typography} from "@material-ui/core";



export const RunJourneys = (props) => {
    const {showAlert} = useContext(AlertContext);
    const [journeys,setJourneys] = useState([]);
    const history = useNavigate();

    const [searched, setSearched] = useState("");

    useEffect(()=>{
        axios.get(`${contextPath}api/processmgmt?category=journey&adminSeesAll=false`).then(rsp=>{
            const processDefinitions = rsp.data;
            const journeys=  processDefinitions.map(pd=>{
                return {
                    name:pd.key,
                    title:pd.name,
                    image:pd.extensions && pd.extensions['sancus.def.image'],
                    groupId:pd.extensions && pd.extensions['sancus.journey.groupId'],
                    groupName:pd.extensions && pd.extensions['sancus.journey.groupName'],
                }
            })
            setJourneys(journeys)
        },error => showAlert(error, 'error'))
    },[]);

    const searchFilter = (journey, index) => {
        if (!searched) {
            return true;
        }

        const s = searched.toLowerCase();
        return journey.name?.toLowerCase().includes(s) || journey.title?.toLowerCase().includes(s);
    }

    const journeyGroups = _.groupBy(journeys.filter(searchFilter), j=>j.groupId || '');
    const groupNames = _.mapValues(journeyGroups, (groupJourneys, groupId)=>{
        return _.maxBy(groupJourneys, j=>j.groupName)?.groupName || groupId;
    });
    //Ensure default group comes last
    const allGroupIds = _.keys(journeyGroups).filter(g=>g!=='');
    allGroupIds.push('');

    return (
        <Grid id="services" style={{padding: 40}}>
            <SearchBar
                value={searched}
                onChange={(searchVal) => {
                    setSearched(searchVal);
                }}
                onCancelSearch={() => setSearched("")}
                style={{marginBottom: 15}}
            />


            <Grid container spacing={5}
                  // style={{maxHeight: '100vh', overflow: 'auto'}}
            >
                {_.map(allGroupIds, (groupId) => {
                    const groupJourneys = journeyGroups[groupId];
                    if (!groupJourneys || groupJourneys.length === 0) {
                        return null;
                    }
                    const groupSlice = <Grid item container spacing={2} >
                        {groupJourneys.map((j, index) => {
                            let img;
                            try{
                                img = require(`../assets/${j.name}.svg`);
                            }catch (e){
                                img = null;
                            }
                            return <Grid item xs={12} sm={6} md={3} key={j.name + index}>
                                <JourneyCard
                                    cardData={{title: j.title, img: img, name: j.name}}
                                    onCardClick={() => history(`/run_journeys/${j.name}`,{
                                        state:{title:j.title}
                                    })}/>
                            </Grid>
                        })}
                    </Grid>

                    const groupName = groupNames[groupId]
                    const groupHeader = <Grid item style={{width:'100%'}}>
                        <Box id={groupId} mt={2} mb={2} >
                            {groupName && <Box mt={2} mb={2}><Typography variant="body1">{groupName}</Typography></Box>}
                            <Divider />
                        </Box>
                    </Grid>

                    return <Grid item container direction={"row"} key={groupName}>
                        {groupHeader}
                        {groupSlice}
                    </Grid>
                }).filter(g=>g!=null)}
            </Grid>
        </Grid>
    );
};