import ElementBuilder from './utils'

const elementBuilder = ElementBuilder
    .forService('Audit Record',ElementBuilder.idPrefix+'audit','#{createAuditRecord}')
    .addIcon("https://raw.githubusercontent.com/twbs/icons/main/icons/receipt.svg")
    .addInputParam("audit_parent_id","Id of Parent Record","Set the Id of the parent message if this is a sequence")
    .addInputParam("audit_level","Audit Level","error,warn,info,debug","info")
    .addInputParam("audit_message","Audit Message","What to log")

    .addInputParam("expose_id_as","Expose Id as","Define variable for audit Id(to be used as parent subsequently)","audit_parent_id")

    .addScriptInputParam("audit_tags","Tags","Add tags to the audit record for better searching","[]")
    .addScriptInputParam("audit_attributes","Attributes","Add attributes to the audit record for better searching","[:]")
    .exposeVariableByName("audit_dto_id","expose_id_as")


export default elementBuilder.element