export default {
    "name": "PEP Screening",
    "id": "com.norbloc.sancus.integrations.RDC",
    "appliesTo": [
        "bpmn:ServiceTask"
    ],
    "icon":{
        "contents":"https://storage.googleapis.com/norbloc_public/images/rdc.png"
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "#{simplePepScreen}",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "camunda:delegateExpression"
            }
        },
        {
            "label": "PEP Provider",
            "type": "Dropdown",
            "choices": [
                {"name": "rdc", "value": "rdc"},
                {"name": "Refinitiv", "value": "refinitiv"},
            ],
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "pep_provider_name"
            }
        },

        {
            "label": "Object type",
            "type": "Dropdown",
            "choices": [
                {"name": "user", "value": "user"},
                {"name": "org", "value": "org"},
            ],
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_type"
            }
        },


        {
            "label": "Nested variable",
            "type": "String",
            "description": "In case firstName & lastName exist inside a variable",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_nested"
            }
        }, {
            "label": "First Name variable",
            "type": "String",
            "value": "firstName",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_firstName"
            }
        },
        {
            "label": "Last Name variable",
            "type": "String",
            "value": "lastName",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_lastName"
            }
        },

        {
            "label": "Org Name variable",
            "type": "String",
            "value": "orgName",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_orgName"
            }
        },


        {
            "label": "Export PEP result to variable",
            "type": "String",
            "description": "Set the name of the variable that will hold the PEP result",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "pep_export_var"
            }
        },

        {
            "label": "Store PEP flag to variable",
            "type": "String",
            "value":"pep_flag",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_store_flag"
            }
        },

        {
            "label": "Store PEP score to variable",
            "type": "String",
            "editable": true,
            "value":"pep_score",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_store_score"
            }
        },
        {
            "label": "Store PEP metadata to variable",
            "type": "String",
            "value":"pep_meta",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "unwrap_store_meta"
            }
        },



        {
            label: "Export metadata",
            "type":"Hidden",
            value:"if (execution.getVariableLocal('pep_export_var') != null) {" +
                "execution.setVariable(" +
                    "execution.getVariableLocal('pep_export_var')," +
                    "execution.getVariableLocal('pepScreenRealtime'))}",
            editable: false,
            binding: {
                type:"camunda:executionListener",
                event:"end",
                scriptFormat:"groovy"

            }


        }
    ]
}