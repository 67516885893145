import *  as React from 'react';
import {useState, useEffect, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from "@material-ui/core/Avatar";
import {getStore} from "sancus-client-common/dist/store";
import contextPath from "sancus-client-common/dist/common/contextPath";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    mainBloc: {
        position: "absolute",
        right: "64px",
        top: "32px",
        border: "solid 1px rgba(24, 60, 103, 0.16)",
        borderRadius: "50%",
        '& svg': {
            color: "rgba(24, 60, 103, 0.26)"
        },
        '&:hover': {
            borderColor: "rgba(24, 60, 103)"
        },
        '&:hover svg': {
            color: "rgba(24, 60, 103)"
        }
    }
}));

export default function UserSessionWidget(props: { logoutUserSession: () => Promise<void> }) {
    const [loggedInUser, SetLoggedInUser] = useState<any>(null);

    useEffect(() => {
        // Redux store
        const store = getStore();
        console.log('UserSessionWidget use effect triggered');

        const onReduxStoreDataChange = () => {
            const userSessionInfo = getStore().getState().userSessionInfo;

            if (userSessionInfo && userSessionInfo.id !== (loggedInUser && loggedInUser!.id)) {
                SetLoggedInUser(userSessionInfo);
            }

            if (!userSessionInfo && loggedInUser) {
                SetLoggedInUser(null);
            }
        };

        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);

        // code here work like in componentWillUnmount()
        return () => {
            console.log('UserSessionWidget use effect unsubscribeStore');
            unsubscribeStore();
        }
    });

    const classes = useStyles();

    return (
        <Grid container direction="row" justifyContent="flex-end" key={"UserSessionWidget"}>
            {loggedInUser ? (
                    <Grid item className={classes.mainBloc}>
                        <Grid container direction="column" alignItems="center" xs>
                              {/*onMouseEnter={e => {*/}
                              {/*    SetDisplayMode({display: 'block'});*/}
                              {/*}}*/}
                              {/*onMouseLeave={e => {*/}
                              {/*    SetDisplayMode({display: 'none'})*/}
                              {/*}}>*/}
                            {/*<Avatar src={ loggedInUser.hasImageURL || `${contextPath}api/users/${loggedInUser.id}/image`}/>*/}

                            {/*<Typography variant="caption" color="textSecondary">*/}
                            {/*    {(loggedInUser.firstName || '').toUpperCase()} {(loggedInUser.lastName || '').toUpperCase()}*/}
                            {/*</Typography>*/}

                            <IconButton onClick={
                                () => {
                                    props.logoutUserSession()
                                        .then(() => {
                                            window.location.reload();
                                        })
                                        .catch((err) => console.error('UserSessionWidget logoutUserSession error', err))
                                }}>
                                <ExitToAppIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
                : <></>
            }
        </Grid>
    )
}
