import React, {createRef, useEffect, useRef, useState} from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";

import propertiesPanelModule from 'bpmn-js-properties-panel';
// providing camunda executable properties, too
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda.json';
import customControlsModule from 'sancus-modeler-plugin/src/custom';

import sancusModdle from 'sancus-modeler-plugin/src/custom/SancusModdle.json'


import {makeStyles, useTheme} from "@material-ui/core/styles";

import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css'


import {FormBuilderApp} from "sancus-html-client/dist/FormBuilder";

import cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';

import {useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import {getStore} from "sancus-client-common/dist/store";

import dragger from "sancus-modeler-plugin/src/custom/dragger";
import {
    getAllProcessVariables,
    hideDescription,
    validateSancusElementAndShowDescription,
    validateSancusElements
} from "sancus-modeler-plugin/src/custom/utils";
import ReactDmnEditor from "../dmn/ReactDmn";

export const DEFAULT_LAYOUT = {
    width: 300
};
export const MIN_WIDTH = 150;
export const MAX_WIDTH = 800;

function getLayout(dx, initialWidth) {
    let width = Math.min(initialWidth - dx, MAX_WIDTH);

    const open = width >= MIN_WIDTH;

    if (!open) {
        width = DEFAULT_LAYOUT.width;
    }

    return {
        open,
        width
    };
}

const newDiagramXML=`<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
  <bpmn2:process id="new_workflow_id" name="New workflow name" isExecutable="true">
    <bpmn2:startEvent id="StartEvent_1" />
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="new_workflow_id">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="412" y="240" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>
`

const useStyles = makeStyles((theme) => ({
    "reactBpmnDiagramContainer": {
        //fix  Palette in two columns  for Chrome
        "& [class*=\"djs-palette-entries\"]": {
            width: 94,
            "& [class*=\"hide\"]": {
                display: 'none'
            }
        },
    },
    "actionButtons": {
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        width: '50%',
        zIndex: 100,
    },
    "bpmnContent": {
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex',
        backgroundColor:'white'
    },
    "toggle": {
        position: "absolute",
        left: -30,
        top: "50%",
        backgroundColor: "#f8f8f8",
        padding: "7px 10px",
        transform: "rotate(-90deg)",
        whiteSpace: "nowrap",
        fontSize: 13,
        border: "solid 1px #cccccc",
        borderBottom: "none",
        borderRadius: "2px 2px 0 0",
        transformOrigin: "top left",
        zIndex: "10",
        cursor: "default",
        userSelect: 'none'
    },
    "resize-handle": {
        marginLeft: '-5px',
        cursor: 'ew-resize',
        zIndex: 11,
        width: 5,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        userSelect: 'none'
    },
    "properties-panel-parent": {
        backgroundColor: "#f8f8f8",
        borderLeft: "1px solid #ccc",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'auto',
        "&:empty": {
            display: 'none'
        },
        '& > .djs-properties-panel': {
            paddingBottom: '70px',
            minHeight: '100%'
        }
    }
}));

function createInputParameterModdle({name, value, bpmnFactory, type }) {
    let parameterValue;
    let parameterDefinition;
    console.debug(' createInputParameterModdle name', name, ' value', value);

   /*

   if (type === 'camunda:Script') {
        parameterDefinition = bpmnFactory.create('camunda:Script', {
            scriptFormat: type,
            value: value
        });
    } else {
        parameterValue = value;
    }

    */

    if (Array.isArray(value) && value.every(el=> el.length < 30 || typeof el === 'string')) {
        // create InputParameter as List
        const inValues = value.map(value => bpmnFactory.create("camunda:Value", {value}));
        parameterDefinition = bpmnFactory.create("camunda:List", {items: inValues});

    } else {
        //create InputParameter as String
        if (typeof value === 'string') {
            parameterValue = value;
        } else {
            parameterValue = JSON.stringify(value);
        }
    }

    return bpmnFactory.create('camunda:InputParameter', {
        name: name,
        value: parameterValue,
        definition: parameterDefinition
    });
}

function parseActionListFromExtensionElements(extensionElements) {
    let inputOutputModdleElement = (extensionElements?.values || []).find(el => el['$type'] === "camunda:InputOutput");
    let actionListModdle = (inputOutputModdleElement?.inputParameters || []).find(el => el.name === "actionsList");
    let actionListValue;

    if (actionListModdle){
        if (actionListModdle.definition && actionListModdle.definition["$type"] ==="camunda:List" && actionListModdle.definition.items){
            actionListValue = actionListModdle.definition.items.map(i => i.value);
        } else {
            actionListValue = actionListModdle.value;
        }
    }

    return actionListValue;
}


const convertFormDataToModdleExtensionElements = ({name, description, formFields}, bpmnViewer, businessObject) => {
    let inputOutput;
    const bpmnFactory = bpmnViewer.get('bpmnFactory');
    const documentation = [bpmnFactory.create("bpmn:Documentation",{text:description})];

    const formActionsListIdx = formFields.findIndex(f=>f.id === 'actionsList');
    let formActionsList = null;
    let formActionListValue = false;
    let inputOutputModdleElement;
    let needUpdateInputOutputTab;

    if (formActionsListIdx !== -1) {
        formActionsList = formFields[formActionsListIdx];
        formActionListValue = formActionsList.value?.value;
        formFields.splice(formActionsListIdx, 1);
    }

    const inputOutputIndex = (businessObject.extensionElements?.values || []).findIndex(moddleElement => {
        if (moddleElement['$type'] === "camunda:InputOutput") {
            inputOutputModdleElement = moddleElement;
            return true;
        }
    });

    if (!inputOutputModdleElement && !formActionListValue) {
        // do nothing with inputOutput
    } else if (!inputOutputModdleElement && formActionListValue) {
        // create inputOutput
        const inputParameterModdle = createInputParameterModdle({name:'actionsList', value: formActionListValue, bpmnFactory});
        inputOutput = bpmnFactory.create("camunda:InputOutput", {inputParameters: [inputParameterModdle]});

    } else if (inputOutputModdleElement) {
        // handle existing inputOutput
        const inputParameterModdle = createInputParameterModdle({name:'actionsList', value: formActionListValue, bpmnFactory});
        const actionListIndex = (inputOutputModdleElement.inputParameters || []).findIndex(moddleElement => moddleElement['name'] === "actionsList");

        inputOutput = bpmnFactory.create("camunda:InputOutput", {
            inputParameters: inputOutputModdleElement.inputParameters && [...inputOutputModdleElement.inputParameters] || undefined,
            outputParameters: inputOutputModdleElement.outputParameters && [...inputOutputModdleElement.outputParameters] || undefined,
        });

        if (!formActionListValue && actionListIndex > -1) {
            inputOutput.inputParameters.splice(actionListIndex, 1);
        }

        if (formActionListValue && actionListIndex < 0) {
            inputOutput.inputParameters = inputOutput.inputParameters || [];
            inputOutput.inputParameters = inputOutput.inputParameters.concat([inputParameterModdle]);
        }

        if (formActionListValue && actionListIndex > -1) {
            inputOutput.inputParameters.splice(actionListIndex, 1);
            inputOutput.inputParameters = inputOutput.inputParameters.concat([inputParameterModdle]);
            needUpdateInputOutputTab = true;
        }
    }

    const formData = bpmnFactory.create("camunda:FormData", {
        fields: formFields.map(f => convertFormFieldToModdle(bpmnViewer)(f))
    })

    //extensionElements can contain different values we need to Update / Insert FormData
    let extensionElements = businessObject.extensionElements;
    const formIndex = (extensionElements?.values || []).findIndex(moddleElement => moddleElement.$type === "camunda:FormData");

    if (formIndex < 0) {
        if (extensionElements && extensionElements.values) {
            extensionElements.values.push(formData)
        } else {
            extensionElements = bpmnFactory.create("bpmn:ExtensionElements", {values: [formData]});
        }

    } else {
        extensionElements.values[formIndex] = formData;
    }

    // On this code line we expect existing 'extensionElements.values'
    if(inputOutput) {
        if (inputOutputIndex < 0) {
            extensionElements.values.push(inputOutput);
        } else {
            extensionElements.values[inputOutputIndex] = inputOutput;
        }
    }

    return {
        formAndInputOutputModdleProperties: {name, documentation, extensionElements},
        needUpdateInputOutputTab
    }
}

const convertFormFieldToModdle = (bpmnViewer)=>(formField)=>{
    const {validationConstraints,properties} = formField;


    const fieldModdleProperties = {
        id:formField.id,
        label: formField.label,
        defaultValue: formField.value?.value && formField.typeName === 'json' ? JSON.stringify(formField.value?.value) : formField.value?.value,
        type:formField.typeName
    }
    const bpmnFactory = bpmnViewer.get('bpmnFactory')

    if (validationConstraints && validationConstraints.length >0){
        const constraints = validationConstraints.map(c=>{
            const {name,configuration} = c;
            return bpmnFactory.create("camunda:Constraint",{name,config:configuration})
        });

        const validationModdle = bpmnFactory.create("camunda:Validation",{constraints});
        fieldModdleProperties.validation = validationModdle;
    }

    if (properties && Object.keys(properties).length >0){
        const props = Object.keys(properties).map(k=>{
            return bpmnFactory.create("camunda:Property",{id:k,value:properties[k]});

        })
        const propsModdle = bpmnFactory.create("camunda:Properties",{values:props})
        fieldModdleProperties.properties = propsModdle;
    }

    const fieldModdle = bpmnFactory.create("camunda:FormField",fieldModdleProperties)
    return fieldModdle;
}

const convertModdleToFormField = (fieldModdle)=>{
    const {id,label,type,defaultValue,validation,properties} = fieldModdle;

    const _properties = properties?.values?.reduce((acc,p)=>{
        acc[p.id] = p.value;
        return acc;
    },{}) || {}

    const _validationConstraints = validation?.constraints?.map(v=>{
        const {name,config} = v;
        return {name,config}
    }) || []

    return {
        id,
        label,
        value:{
            type,
            value:defaultValue
        },
        type:{
            name:type
        },
        typeName:type,
        properties:_properties,
        validationConstraints:_validationConstraints
    }
}

const CamundaFormBuilder = (props)=>{
    const theme = useTheme();
    const {element,onUpdate,bpmnViewer} = props;
    const {businessObject} = element;
    const {name} = businessObject;
    const description = businessObject.documentation[0]?.text

    //extensionElements can contain different values we need to find object with fields
    let fieldsModdles = [];

    (businessObject.extensionElements?.values || []).some(moddleElement => {
        if ( moddleElement.$type === "camunda:FormData" && moddleElement.fields) {
            fieldsModdles = moddleElement.fields;
            return true;
        }
    });

    const formFields = fieldsModdles.map(f => convertModdleToFormField(f))
    const actionList = parseActionListFromExtensionElements(businessObject.extensionElements);

    if (actionList){
        const actionListFormField = {
            id: `actionsList`,
            value: {
                value: actionList,
                type:{
                    name:'json'
                },
                transient:true
            },
            type:{
                name:'json'
            },
            typeName:'json',
            validationConstraints:[{name:'readonly'}],
            properties: {},
        };

        const actionsListIndex = formFields && formFields.findIndex(f => f.id === 'actionsList');
        if (actionsListIndex < 0) {
            formFields.push(actionListFormField);
        } else {
            formFields.splice(actionsListIndex,1);
            formFields.push(actionListFormField);
        }
    }

    const allProcessVariablesNames = getAllProcessVariables().map(item => item.name);
    const designerActions = useDesignerActions(getStore().dispatch)

    useEffect(()=>{
        designerActions.reset({name,description,formFields})
    },[])
    return <FormBuilderApp
        onUpdate={new_model=>{
            onUpdate(new_model)
        }}
        name={name}
        description={description}
        formFields={formFields}
        theme_data={theme}
        allProcessVariablesNames={allProcessVariablesNames}
    />
}

const CamundaDMNEditor = (props) => {
    const {element, onUpdate, bpmnViewer} = props;

    if (!element){
        return <></>;
    }

    const {businessObject} = element;
    const decisionRef = businessObject.decisionRef;
    const cmdStack = bpmnViewer.get('commandStack');

    console.debug('businessObject: ', businessObject);

    return <ReactDmnEditor
        diagramXML={null}
        decisionReferenceId={decisionRef}
        onSave={async (options) => {
            // update decisionRef
            if (options.dmnId !== businessObject.decisionRef) {
                const {cmd, context} = cmdHelper.updateBusinessObject(element, businessObject, {
                    decisionRef: options.dmnId
                })

                cmdStack.execute(cmd, context);
            }
        }}
        onClose={() => props.onClose && props.onClose()}
    />
}

export default function ReactBpmn(props) {
    const classes = useStyles();
    const containerRef = createRef();
    const propertiesContainerRef = createRef();
    const [propertiesPanelProps, setPropertiesPanelProps] = useState({width: DEFAULT_LAYOUT.width});
    const [bpmnViewer, setBpmnViewer] = useState(null);
    const [showFormBuilder,setShowFormBuilder] = useState(null);
    const [showDMNEditor,setShowDMNEditor] = useState(null);

    const [diagramXML,setDiagramXML] = useState(props.diagramXML || newDiagramXML)


    const changes = useRef([]);

    useEffect(() => {
        const interval = setInterval(() => {
            const _changes = changes.current.splice(0);
            props.onHeartbeat(_changes);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const container = containerRef.current;


        console.log('camundaModdleDescriptor',camundaModdleDescriptor)
        console.log('sancusModdle',sancusModdle)

        const _bpmnViewer = new BpmnModeler({
            container,
            keyboard: {
                bindTo: document
            },
            propertiesPanel: {
                parent: '#js-properties-panel'
            },
            additionalModules: [
                propertiesPanelModule,
                propertiesProviderModule,
                customControlsModule
            ],
            // needed if you'd like to maintain camunda:XXX properties in the properties panel
            moddleExtensions: {
                camunda: camundaModdleDescriptor,
                sancus:sancusModdle
            }
        })

        _bpmnViewer.importXML(diagramXML)
            .then(() => {
                window.my_bpmnViewer = _bpmnViewer;
                const groupName = 'integrations'
                //collapse pallet djs-palette-entries
                const palletEl = document.querySelector(".djs-palette-entries");


                const groupEl = palletEl.querySelector(`[data-group='${groupName}']`);

                if (groupEl){
                    groupEl.classList.add("hide");
                }
            });

        const eventBus = _bpmnViewer.get('eventBus');

        const _fire = eventBus.fire;
        eventBus.fire = (...args)=>{
            console.debug("eventBus::fire",args)
            const ret = _fire.apply(eventBus,args);
            return ret;
        }

        eventBus.on(`formbuilder.open`, evt => {
            if (!showFormBuilder) {
                const element = evt.data;

                setShowFormBuilder(element);
            }
        });

        eventBus.on(`DMN_editor.open`, evt => {
            if (!showDMNEditor) {
                const element = evt.data;

                setShowDMNEditor(element);
            }
        });

        eventBus.on('selection.changed', function(context) {
            const {oldSelection,newSelection} = context;
            console.log('selection.changed',oldSelection,newSelection)

            //SRD-296 on selection Sancus
            hideDescription(oldSelection.length && oldSelection[0]);
            validateSancusElementAndShowDescription(newSelection.length && newSelection[0]);

            // go crazy
        });

        eventBus.on('elements.changed', function(context) {
            const {elements} = context;
            console.log('elements.changed',elements);

            //SRD-296 on any BPMN changes we validate Sancus elements

            validateSancusElements();
            // go crazy
            changes.current.push(context)
        });

        setBpmnViewer(_bpmnViewer);

        return function cleanup() {
            console.log('ReactBpmn module unmount')
            _bpmnViewer.destroy();
        }
    }, [diagramXML]);

    const handleToggle = () => {
        const currentWidth = parseInt(propertiesPanelProps.width);
        let prevWidth = parseInt(propertiesPanelProps.prevWidth || 0);

        if (currentWidth) {
            propertiesContainerRef.current.classList.toggle('open', false);
            propertiesContainerRef.current.style["min-width"] = `0px`;
            propertiesContainerRef.current.style["width"] = `0px`;
            setPropertiesPanelProps({width: `0px`, prevWidth: currentWidth});
        } else {
            // if prevWidth not exist we set default width
            prevWidth = prevWidth || DEFAULT_LAYOUT.width;
            propertiesContainerRef.current.classList.toggle('open', true);
            propertiesContainerRef.current.style["min-width"] = `${prevWidth}px`;
            propertiesContainerRef.current.style["width"] = `${prevWidth}px`;
            setPropertiesPanelProps({width: prevWidth, prevWidth: currentWidth});
        }
    }

    const handleResizeStart = event => {
        const onDragStart = dragger(handleResize);
        onDragStart(event);
    }

    const handleResize = (_, delta) => {
        const { x: dx } = delta;
        if (dx === 0) {
            return;
        }

        // here we can have  numbers or CSS string like '150px'
        const startWidth = parseInt(propertiesPanelProps.width);

        const {open, width} = getLayout(dx, startWidth);

        if (propertiesContainerRef.current) {
            propertiesContainerRef.current.classList.toggle('open', open);
            propertiesContainerRef.current.style["min-width"] = `${ open ? width : 0 }px`;
            propertiesContainerRef.current.style["width"] = `${ open ? width : 0 }px`;
        }
    }

    const handleResizeEnd = () => {
        setPropertiesPanelProps({width: propertiesContainerRef.current.style["min-width"]});
    }

    const [newModel,setNewModel] = useState({});



    const onSaveCloseFormBuilder = evt => {
        const cmdStack = bpmnViewer.get('commandStack');
        const modeling = bpmnViewer.get('modeling');
        const propertiesPanel = bpmnViewer.get('propertiesPanel');
        const element = showFormBuilder;
        const {businessObject} = element;

        // filter “hidden" fields used for support group's handling
        newModel.formFields = newModel.formFields.filter(f => !f.id.includes('__hidden_field_of_'));

        const {
            formAndInputOutputModdleProperties,
            needUpdateInputOutputTab
        } = convertFormDataToModdleExtensionElements(newModel, bpmnViewer, businessObject);
        const {
            cmd,
            context
        } = cmdHelper.updateBusinessObject(element, businessObject, formAndInputOutputModdleProperties)

        cmdStack.execute(cmd, context);
        modeling.updateLabel(element, newModel.name)
        setShowFormBuilder(null);

        if (needUpdateInputOutputTab) {
            // here we use some hack for re-render propertiesPanel
            // to switch of to main root element
            propertiesPanel.update();
            // to switch just edited element
            propertiesPanel.update(element);
            // return to froms tab
            propertiesPanel.activateTab('forms');
        }
    };

    const onCloseFormBuilder = evt => {
        setShowFormBuilder(null);
    };

    return (<>
        <Grid className={classes.bpmnContent} style={!!showDMNEditor || !!showFormBuilder ? {display: "none"} : {display: "flex"}}
              onDrop={async evt => {
                  const dt = evt.dataTransfer;
                  const item = dt.items[0];
                  if (item && item.type === 'application/bpmn' && item.kind === 'file') {
                      evt.preventDefault();
                      evt.stopPropagation();

                      const file = dt.files[0];
                      const text = await file.text();
                      setDiagramXML(text);
                  }
              }}
              onDragOver={evt=>{
                  const dt = evt.dataTransfer;
                  const item = dt.items[0];
                  if (item && item.type === 'application/bpmn' && item.kind === 'file') {
                      evt.preventDefault();
                      evt.stopPropagation();
                  }
              }}
        >
            <Grid className={"react-bpmn-diagram-container " + classes['reactBpmnDiagramContainer']} id={"js-canvas"} ref={containerRef} style={{width: '100%'}}/>

            <Grid style={{position: 'relative',  backgroundColor: "#f8f8f8", minWidth: propertiesPanelProps.width}} ref={propertiesContainerRef}>
                <div
                    className={classes['toggle']}
                    onClick={handleToggle}
                    draggable
                    onDragStart={handleResizeStart}
                    onDragEnd={handleResizeEnd}
                >Properties Panel
                </div>

                {
                    !!parseInt(propertiesPanelProps.width) &&
                    <Grid className={classes['resize-handle']}
                          draggable
                          onDragStart={handleResizeStart}
                          onDragEnd={handleResizeEnd}>
                    </Grid>
                }

                <Grid className={'properties-panel-parent ' + classes['properties-panel-parent']}
                      id="js-properties-panel"/>
            </Grid>

            <Grid className={classes.actionButtons} container direction="row" alignItems="center">
                <Box pr={1}>
                    <Button onClick={() => {
                        props.onClose && props.onClose();
                    }} color="primary">
                        Close
                    </Button>
                </Box>

                <Box pr={1}>
                    <Button onClick={async () => {
                        const {xml} = await bpmnViewer.saveXML({format: true});

                        const xmlDoc = new DOMParser().parseFromString(xml, "text/xml");
                        const process = xmlDoc.getElementsByTagName("bpmn2:process")[0] || xmlDoc.getElementsByTagName("bpmn:process")[0]
                        const processId = process?.getAttribute("id")?? 'process_id';

                        const blob = new Blob([xml], {type: "text/xml"});

                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(blob);
                        a.download = `${processId}.bpmn`
                        return new Promise(resolve => {
                            a.click()

                            setTimeout(()=>{
                                URL.revokeObjectURL(a.href);
                                resolve();
                            });
                        })

                    }} color="primary">
                        Download
                    </Button>
                </Box>

                <Box p={2}>
                    <Button onClick={async () => {
                        const {xml} = await bpmnViewer.saveXML({format: true});
                        props.onSave && props.onSave(xml);
                    }} variant="contained" color="primary" autoFocus>
                        Save Changes
                    </Button>
                </Box>


            </Grid>
        </Grid>

        <Grid style={!!showFormBuilder ? {display: "flex"} : {display: "none"}}>
            <div id="modalContainer" style={{backgroundColor: 'white', height: '100vh', width: '100%'}}>

                <Grid className={classes.actionButtons} container direction="row" alignItems="center">
                    <Box pr={1}>
                        <Button onClick={onCloseFormBuilder} size="large" color="primary"> Cancel </Button>
                    </Box>
                    <Box p={2}>
                        <Button onClick={onSaveCloseFormBuilder} variant="contained" size="large"
                                color="primary"> Save and close </Button>
                    </Box>
                </Grid>

                {!!showFormBuilder && <CamundaFormBuilder bpmnViewer={bpmnViewer} element={showFormBuilder}
                                                          onUpdate={new_model => setNewModel(new_model)}/>}
            </div>
        </Grid>

        <Grid style={!!showDMNEditor ? {display: "flex"} : {display: "none"}}>
            <div id="modalContainer2" style={{backgroundColor: 'white', height: '100vh', width: '100%'}}>
                <CamundaDMNEditor bpmnViewer={bpmnViewer} element={showDMNEditor}
                                  onUpdate={new_model => setNewModel(new_model)} onClose={() => {
                    setShowDMNEditor(null)
                }}/>
            </div>
        </Grid>
    </>)
}




