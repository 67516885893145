import React, {useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid/index";

import Chart from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"


export default function DoughnutSimple({config,clickHandler,hoverHandler,customTooltip}) {
    const canvasRef = useRef(null);

    const myChartConfig = {
        type: 'doughnut',
        plugins: [ChartDataLabels],
        options: {
            maintainAspectRatio: true,
            aspectRatio: 2.5,
            responsive: true,
            onClick: clickHandler,
            onHover: hoverHandler,
            legend: {
                display: false,
                labels: {
                    padding: 30,
                    usePointStyle: true
                },
                position: 'bottom'
            },

            tooltips:{
                enabled: ! customTooltip?.custom,
                custom: customTooltip?.custom && customTooltip.custom(()=>canvasRef.current,this),
                callbacks: {
                    ...(customTooltip ?? {} )
                    // title:customTooltip?.title,
                    // label:customTooltip?.label,
                    // labelColor:customTooltip?.labelColor,
                    // footer:customTooltip?.footer
                }
            },

            plugins: {
                datalabels: {
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderColor: 'white',
                    borderRadius: 25,
                    borderWidth: 2,
                    color: 'white',
                    display: function (context) {
                        var dataset = context.dataset;
                        var count = dataset.data.length;
                        var value = dataset.data[context.dataIndex];

                        // TODO for small values 1,2 this returned false
                        //return value > count * 1.5;
                        return value;
                    },
                    font: {
                        weight: 'bold',
                        size: 16,
                    },
                    formatter: Math.round,
                },

            }
        },
        data: {
            labels: config.labels,
            filterNames: config.filterNames,
            datasets: config.datasets,
        },

    };

    // Check if data is all 0s; if it is, add dummy data to end with empty label
    myChartConfig.data.datasets.forEach(dataset => {
        if (dataset.data.every(el => el === 0)) {
            dataset.backgroundColor.push('rgba(0, 0, 0, 0.1)');
            dataset.data.push(1);
            dataset.datalabels = {
                display: false,

            };
        }
    });

    useEffect(() => {
        const myChart = new Chart(canvasRef.current, myChartConfig);
    });

    return <Grid><Grid item xs={12} style={{padding: 30}}>
        <canvas ref={canvasRef} id={"chart"}/>
    </Grid></Grid>;
}
