import CustomContextPad from './CustomContextPad';
import CustomRendeder from "./CustomRenderer";
import LaunchFormBuilderPropertiesProvider from "./LaunchFormBuilderPropertiesProvider";
import LaunchDmnBuilderPropertiesProvider from "./LaunchDmnBuilderPropertiesProvider";
import CustomElementFactory from "./CustomElementFactory";
import CustomUpdater from "./CustomUpdater";
import SancusTemplatesRegistrar from "./SancusTemplatesRegistrar";

/**
 * Purpose of the custom package is to override bpmn-js defaults in order to accommodate Sancus Workflow Designer needs.
 * Sancus Integrations:
 *  * Sancus integrations are modeled as element templates (See SancusElementTemplates.json).
 *    This way it is possible to specify what is required for each integration to work
 *  * In order to achieve better UI & possibly different server side handling, every sancus integrations contains the
 *    sancus:sancusIntegration attribute in the bpmn entry (See SancusModdle.json)
 *  * CustomContextPad & CustomPalette create ui links for the various integrations - they create elements that have the sancusService attribute set
 *  * CustomRenderer renders the img of the sancusService inside the shape
 *  * CustomElementFactory takes care that sancusService etc. are propagated inside the shape's businessObject.
 *    This works also in reverse as the businessObject is populated when the bpmn file is loaded
 *  * CustomUpdater applies the configured template to the element.
 */

export default {
  __init__: [ 'customContextPad',
    'customRenderer',
    'elementFactory',
    'customUpdater',
    'launchFormBuilderPropertiesProvider',
    'launchDmnBuilderPropertiesProvider',
    'sancusTemplateRegistrar'
  ],
  customContextPad: [ 'type', CustomContextPad ],
  // customPalette: [ 'type', CustomPalette ],
  customRenderer:['type',CustomRendeder],
  elementFactory: [ 'type', CustomElementFactory ],
  customUpdater:['type',CustomUpdater],

  launchFormBuilderPropertiesProvider: [ 'type', LaunchFormBuilderPropertiesProvider ],
  launchDmnBuilderPropertiesProvider: [ 'type', LaunchDmnBuilderPropertiesProvider ],

  sancusTemplateRegistrar:['type',SancusTemplatesRegistrar]
};
