import { handleActions } from 'redux-actions';
import {UserSessionInfoActions} from '../actions/userSessionInfo.actions';

/**
 * auditTasks related actions
 */
export const userSessionInfoReducer = handleActions<any[], any>(
	{
		[UserSessionInfoActions.Type.SET_USER_SESSION_INFO]: (state, action) => {
			return action.payload;
		}
	},
	{} as any
);
