import * as React from "react";
import {lazy, Suspense, useContext, useEffect, useState} from "react";
import {omit} from 'lodash'
import {CustomComponentsBaseParams} from "sancus-client-common/dist/common/generic_component_base";
import {CustomFileFieldWithUrl} from "./generic_component";
import cpp from "sancus-client-common/dist/common/contextPath";
import {Box, Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import {getNotificationTemplate} from "./generic_component/GenericFieldComponentSimple";
import {useFormContext} from "react-hook-form";
import {FormContainerContext} from "sancus-client-common/dist/common/FormContainerContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {FormValues} from "sancus-client-common/dist";

//JumioValue is passed along after the init of Jumio WF
export type JumioInitResponse = {
    redirectUrl: string;
    transactionReference: string;
}

const GroupComponent = lazy(async () =>  {
    const mod = await import('./generic_component/Group');
    const {GroupComponent} = mod;
    console.log ("GroupComponent",GroupComponent,'of mod',mod)
    return {default:GroupComponent};
});


export type JumioPreIntiateOptions = {
    locale?: string;
    workflowId?: number;
    userRef?: string;
    customerInternalRef?: string;

}



export type JumioKYXData = {
    workflow:{
        id:string,
        status:string,
        definitionKey:string,
        customerInternalReference:string,
    },
    account:{
        id:string,
    },
    decision:{
        type: string,
        risk:{
            score: number,
        },
        details:{
            label:string
        }

    },
    capabilities:{
        [key:string]:[{
            decision:JumioKYXData['decision']
        }]
    },
    credentials:[{
        id:string,
        category:string,
        parts:[{
            classifier:string,
            href:string,
        }]
    }]


}

//JumiData are returned after the webhook
export type JumioData  = {
    "callbackType":string;
    "jumioIdScanReference":string;
    "verificationStatus":string;
    "idScanStatus":string;
    "idScanSource":string;
    "idCheckDataPositions":string;
    "idCheckDocumentValidation":string;
    "idCheckHologram":string;
    "idCheckMRZCode":string;
    "idCheckMicroprint":string;
    "idCheckSecurityFeatures":string;
    "idCheckSignature":string;
    "transactionDate":string;
    "callbackDate":string;
    "identityVerification":string // in the form of {similarity:"MATCH" | "NO_MATCH" | "NOT_POSSIBLE", validity: "TRUE" | "FALSE", reason: string};
    "idType":string;
    "idSubtype":string;
    "idCountry":string;
    "rejectReason":string;
    "idScanImage":string;
    "idScanImageFace":string;
    "idScanImageBackside":string;
    "idNumber":string;
    "idFirstName":string;
    "idLastName":string;
    "idDob":string;
    "idExpiry":string;
    "idUsState":string;
    "personalNumber":string;
    "idAddress":string;
    "merchantIdScanReference":string;
    "merchantReportingCriteria":string;
    "customerId":string;
    "clientIp":string;
    "firstAttempt":string;
    "presetCountry":string;
    "presetIdType":string;
    "issuingDate":string;
    "livenessImages":string;
    "facemap":string;
    "curp":string;
    "cpf":string;
    "registrationNumber":string;
    "personalIdentificationNumber":string;
    "rgNumber":string;
}

export type JumioComponentProps = CustomComponentsBaseParams<JumioInitResponse | JumioData | JumioKYXData |JumioPreIntiateOptions> &{
    modal?:'true' | 'false',
    skipable?:'true' | 'false',
    landingText?:string
    preventFullScreen?:string,

    mode: 'iframe' | 'doc_file' | 'doc_and_selfie_file' | 'websdk'
    workflow_key?:number
};

export type JumioIFrameMessageData = {
    "authorizationToken": string,
    "transactionReference": string,
    "eventType": number,
    "dateTime": string,
    "payload": {
        "value": string,
        "metainfo": {
            "mode": string
        }
    },
    "customerInternalReference":string
}

export type JumioSancusIFrameMessageData = {
    name: 'jumio-result-event',
    data:{
        status: 'error' | 'success',
        errorCode: number,
        allParams:{[key:string]:any}
    }
}

const JumioComponent = (jumioProps: JumioComponentProps) => {
    const readOnly = !!jumioProps.validation?.readOnly
    if (readOnly){
        return <JumioReadOnly {...jumioProps}/>
    }else{
        const mode = jumioProps.mode ?? 'iframe';
        const workflow_key = jumioProps.workflow_key;
        if (mode == 'iframe') {
            return <JumioComponentIFrame {...jumioProps}/>
        }else if (mode == 'doc_file'){
            return <JumioComponentDocFile {...jumioProps}/>
        }

    }
}

const JumioComponentDocFile = (jumioProps: JumioComponentProps) => {
    const workflow_key = jumioProps.workflow_key;
    const fv_value = jumioProps;
    const _value = jumioProps._value as any as FormValues
    const value = jumioProps.value as any as FormValues ?? {};

    value.workflow_key = workflow_key;

    const methods = useFormContext();

    return <Suspense fallback={"Loading"}>
        <GroupComponent
            index={-1}//TODO?
            // id={jumioProps.id}
            name={jumioProps.name}
            // key={jumioProps.key}
            path={jumioProps.path}
            // data-section={section}
            template={{
                'front':{
                    type:{
                        name: "file"
                    },
                    typeName:"file",
                    defaultValue:undefined,
                    value:_value?.front,
                    _value:_value?.front,
                    validationConstraints:[{name:'required'}],
                    properties:{},

                    path:fv_value.id+'.front',
                    contextEvaluator:jumioProps.contextEvaluator,
                    valueChangeCbk:jumioProps.valueChangeCbk,
                    fileChangeCbk:jumioProps.fileChangeCbk,
                    formReference:jumioProps.formReference,
                    id:'front',
                    label:"Document Front Side"
                },
                'back':{
                    type:{
                        name: "file"
                    },
                    typeName:"file",
                    defaultValue:undefined,
                    value:_value?.back,
                    _value:_value?.back,
                    validationConstraints:[],
                    properties:{},

                    path:fv_value.id+'.back',
                    contextEvaluator:jumioProps.contextEvaluator,
                    valueChangeCbk:jumioProps.valueChangeCbk,
                    fileChangeCbk:jumioProps.fileChangeCbk,
                    formReference:jumioProps.formReference,
                    id:'back',
                    label:"Document back side (if applicable)"
                }

            }}
            checkMessages={jumioProps.checkMessages as any}
            value={value}

            contextEvaluator={jumioProps.contextEvaluator}
            fileChangeCbk={jumioProps.fileChangeCbk}
            valueChangeCbk={jumioProps.valueChangeCbk}
            formReference={jumioProps.formReference}
            methods = {methods}
            designMode={false}


        />
    </Suspense>


    /*
            <GenericFieldComponent
                type={{
                    name: "file"
                }}
                typeName={"file"}

                defaultValue={undefined}

                validationConstraints={[{name:'required'}]}
                properties={{}}
                key={fv_value.id}

                path={fv_value.id}
                contextEvaluator={jumioProps.contextEvaluator}
                _value={fv_value._value}

                // checkMessages={checkMessages}
                valueChangeCbk={jumioProps.valueChangeCbk}
                fileChangeCbk={jumioProps.fileChangeCbk}
                formReference={jumioProps.formReference}
                id={jumioProps.id+'_front'}
                label={"Document Front Side"}
                value={null}/>

            <GenericFieldComponent
                type={{
                    name: "file"
                }}
                typeName={"file"}

                defaultValue={undefined}

                validationConstraints={[]}
                properties={{}}
                key={fv_value.id}

                path={fv_value.id}
                contextEvaluator={jumioProps.contextEvaluator}
                _value={fv_value._value}

                // checkMessages={checkMessages}
                valueChangeCbk={jumioProps.valueChangeCbk}
                fileChangeCbk={jumioProps.fileChangeCbk}
                formReference={jumioProps.formReference}
                id={jumioProps.id+'_back'}
                label={"Document back side (if applicable)"}
                value={null}/>
    */

}

const JumioReadOnly = (jumioProps: JumioComponentProps) => {
    const checks = <Box ml={2} key={"check_box"}>
        <Grid item>
            {getNotificationTemplate(jumioProps)}
        </Grid>
    </Box>

    if (jumioProps.value == null){
        return <div>No Jumio data</div>
    }
    let idScanImageUrl,idScanImageBacksideUrl,idScanImageFaceUrl;
    if ((jumioProps.value as JumioKYXData).workflow){
        const jk = jumioProps.value as JumioKYXData
        idScanImageUrl = jk.credentials
            .filter(c=>c.category == "ID")
            .flatMap(c=>c.parts)
            .filter(p=>p.classifier == "FRONT")
            .map(p=>p.href)[0]

        idScanImageBacksideUrl = jk.credentials
            .filter(c=>c.category == "ID")
            .flatMap(c=>c.parts)
            .filter(p=>p.classifier == "BACK")
            .map(p=>p.href)[0]

        idScanImageFaceUrl = jk.credentials
            .filter(c=>c.category == "SELFIE")
            .flatMap(c=>c.parts)
            .filter(p=>p.classifier == "FACE")
            .map(p=>p.href)[0]

        idScanImageUrl = idScanImageUrl && `${cpp.contextPath}api/jumio/kyx/fetch?url=${encodeURIComponent(idScanImageUrl)}`;
        idScanImageBacksideUrl = idScanImageBacksideUrl && `${cpp.contextPath}api/jumio/kyx/fetch?url=${encodeURIComponent(idScanImageBacksideUrl)}`;
        idScanImageFaceUrl = idScanImageFaceUrl && `${cpp.contextPath}api/jumio/kyx/fetch?url=${encodeURIComponent(idScanImageFaceUrl)}`;


    }else{
        const jd = jumioProps.value as JumioData
        const {jumioIdScanReference} = jd
        idScanImageUrl = jd.idScanImage && `${cpp.contextPath}api/jumio/fetch/${jumioIdScanReference}/images/front`;
        idScanImageBacksideUrl = jd.idScanImageBackside && `${cpp.contextPath}api/jumio/fetch/${jumioIdScanReference}/images/back`;
        idScanImageFaceUrl = jd.idScanImageFace && `${cpp.contextPath}api/jumio/fetch/${jumioIdScanReference}/images/face`;
    }


    const renderNotNull = (label,fileName,url)=>{
        if (url !=null){
            return <CustomFileFieldWithUrl fileUrl={url} fileName={fileName} label={label} notificationTmpl={checks} key={fileName}/>
        }else{
            return null;
        }
    }
    return <>
        {renderNotNull('Document Front','doc_front.jpg',idScanImageUrl)}
        {renderNotNull('Document Back','doc_back.jpg',idScanImageBacksideUrl)}
        {renderNotNull('Face','face.jpg',idScanImageFaceUrl)}
    </>
}

const JumioComponentIFrame = (jumioProps: JumioComponentProps) => {

    const deviceType = document.body.dataset.deviceType;
    const workflow_key = jumioProps.workflow_key;
    const value = jumioProps.value as JumioPreIntiateOptions;
    const _initProps =value &&  value['redirectUrl'] && value['transactionReference'] ? value as JumioInitResponse: null;
    const readOnly = !!jumioProps.validation?.readOnly
    const [completed,setCompleted] = useState(false);
    const [closed,setClosed] = useState(true);
    const formContainerContext = useContext(FormContainerContext);

    // handling message from Jumio iframe
    // docs: https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#example-iframe-logging-code
    const messageHandler = (_initResponse:JumioInitResponse)=> event => {
        // filter out irrelevant events
        const {redirectUrl:_src, transactionReference} = initResponse!;
        if (event.origin == window.origin || event.origin === new URL( _src).origin) {
            try {
                const data: (JumioIFrameMessageData | JumioSancusIFrameMessageData) =  typeof event.data == 'string' ? JSON.parse(event.data):event.data;
                if (data && data['source'] && data['source'].indexOf("react-devtools") === 0){
                    return;
                }
                console.info(data);
                // discrimination for success event after Jumio form completes
                if ((data as JumioIFrameMessageData).payload && (data as JumioIFrameMessageData).payload.value === "success") {
                    //This probably comes from JUMIO  itself
                    const new_data = omit(data,['authorizationToken'])
                    setCompleted(true);

                    new_data['transactionReference'] = transactionReference;


                    jumioProps.valueChangeCbk(jumioProps.id, new_data, true)
                }else if ((data as JumioSancusIFrameMessageData).name == "jumio-result-event"){
                    //This comes from the sacnus redirect frame
                    const {status,errorCode,allParams} = (data as JumioSancusIFrameMessageData).data;
                    if (status == 'success'){
                        console.log('Jumio success')
                    }else{
                        formContainerContext.raiseError('jumio_form_error','jumio_form_error', {errorCode,...allParams})
                    }
                }else{
                    //This is a message from Jumio
                    console.debug('Jumio message',data)
                }
            } catch (e) {
                // todo ignore devtools message
                console.error(e)
            }
        }
    };

    const [initResponse,setInitResponse] = useState<JumioInitResponse | null>(_initProps);
    const [error,setError] = useState<string | null>(null);

    useEffect(() => {
        if (!readOnly) {
            if (!initResponse) {
                let {locale,workflowId,userRef,customerInternalRef} = (value ?? {}) as JumioPreIntiateOptions

                const {tenantId,processInstanceId:rootProcessInstanceId,processDefKey:rootProcDefKey} = formContainerContext;

                customerInternalRef =  customerInternalRef ?? [tenantId,rootProcDefKey,rootProcessInstanceId].filter(s=>s).join(':');
                workflowId = workflowId ?? workflow_key;



                fetch(cpp.contextPath + "api/jumio/initiate",{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        locale,workflowId,userRef,customerInternalRef
                    })
                })
                    .then(r => r.json())
                    .then(r => {
                        setInitResponse(r)
                    })
                    .catch(err=>{
                        setError(err.message)
                    })
            }
        }
    }, [])

    useEffect(()=>{
        if (initResponse) {
            const messageHandlerInstance = messageHandler(initResponse);
            window.addEventListener("message", messageHandlerInstance)
            return () => window.removeEventListener("message", messageHandlerInstance)
        }
    },[initResponse])


    useEffect(()=>{
        //Ask for camera
        if (readOnly){
            return;
        }
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            console.log("Let's get this party started")
            navigator.mediaDevices.enumerateDevices().then(devices => {
                devices.forEach(device=>{
                    console.log(device.kind + ": " + device.label +
                        " id = " + device.deviceId);
                })
            }).then(()=>{
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(stream=> {
                        console.log('You let me use your video!')
                        stream.getTracks().forEach(track => track.stop())
                    })
                    .catch(function(err) {
                        window.alert('Cannot access video media!')
                        console.log('No video for you!',err)
                    });
            });
        }

    },[])




    const useStyles = makeStyles((theme) => ({
        jumio_iframe:{
            height: '100%',
            minHeight:"600px",
            maxHeight:"700px",
            '@media (max-height: 700px)':{
                maxHeight:"100vh",
            }
        },
        jumio_container: {
            position:"relative",
            height:"75vh",
            minWidth:theme['customProps']?.jumio_container || '300px',
            '@media (max-width: 300px)':{
                minWidth: '100vw'
            },
            width:"100%",

            minHeight:"600px",//Reserve the space
            maxHeight:"700px",
            '@media (max-height: 700px)':{
                maxHeight:"100vh",
                height:"100vh",
            },

            [theme.breakpoints.up('lg')]: {
                marginRight: theme['customProps']?.jumio_container_lg || '120px' //To look more centered when stepper is available
            }

        }

    }));
    const jumioStyles = useStyles();


    if (readOnly){

        return <JumioReadOnly {...jumioProps}/>
    }else {
        const modal = jumioProps.modal == 'true';
        const skipable = jumioProps.skipable ==='true';
        const landingText = jumioProps.landingText ?? "Show Jumio Frame";

        let preventFullScreen = false;
        if (jumioProps.preventFullScreen){
            const hasNeg = jumioProps.preventFullScreen.indexOf('!') ==0;
            if (hasNeg){
                const np = jumioProps.preventFullScreen.substring(1)
                preventFullScreen = deviceType !== np;
            }else if (jumioProps.preventFullScreen === 'true'){
                preventFullScreen = true;
            }else if (jumioProps.preventFullScreen === 'false'){
                preventFullScreen = false;
            }else{
                preventFullScreen = jumioProps.preventFullScreen.split(',').map(s=>s.trim()).some(p=>{
                    return p === deviceType;
                })

            }

        }
        // const allowFullScreen = fullScreenOnPC || deviceType !== 'Computer';

        const allowString = preventFullScreen ?"camera": "camera;fullscreen;accelerometer;gyroscope;magnetometer" ;

        const iframe = initResponse && <iframe src={initResponse.redirectUrl}
                                               id={"jumio"}
                                               allow={allowString}
                                               allowFullScreen={!preventFullScreen}
                                               className={jumioStyles.jumio_iframe}
                                               height={"100%"} width={"100%"}
        />
        if (modal) {
            return <>
                <Box flexDirection={"row"} display={"flex"} alignItems={"center"} justifyContent={"center"} style={{height:"75vh"}}>
                    <Grid direction={"column"} container alignItems={"center"} justifyContent={"center"}>
                        <Grid item>{landingText}</Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                size={"large"}
                                style={{visibility:closed?'visible':'hidden'}}
                                onClick={evt=>setClosed(false)}>
                                    <Typography variant={"body1"} align={"center"}>GO</Typography>
                            </Button>
                        </Grid>
                    </Grid>


                </Box>
                <Dialog open={!completed && !closed}
                        fullWidth={true}
                        maxWidth={'xl'}
                        BackdropProps={{invisible:true}}
                        onClose={evt=>{
                            setClosed(true)
                        }}
                >
                    <DialogContent>

                        {error && <div>Found error {error}</div>}
                        {!initResponse && <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
                            <CircularProgress />
                            <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
                        </div>}
                        {iframe}

                    </DialogContent>
                </Dialog>
            </>
        }else {

            return <Box flexDirection={"row"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        className={'jumio_container ' + jumioStyles.jumio_container}
            >
                {!initResponse &&

                        <Grid direction={"column"} container alignItems={"center"} justifyContent={"center"}>
                            {error && <Grid item>{error}</Grid>}
                            <Grid item><CircularProgress/></Grid>
                            <Grid item>
                                <Typography
                                    style={{justifyContent: "center", position: "fixed", top: "55%"}}
                                    variant={"body1"}
                                    align={"center"}
                                >Loading...please wait
                                </Typography>
                            </Grid>
                        </Grid>


                }
                {initResponse && <>
                    {false && <div style={{
                        position:'absolute',
                        height:'50px',
                        width:'500px',
                        border:'1px solid red',
                        top:'0px',
                        left:'0px'
                    }}></div>
                    /* This could have been a nice hack to hide the help button
                       if we can know the state of the Jumio flow
                       We should remove the hidder when the selfie step activates
                       */
                }
                    {iframe}
                </>}
            </Box>
        }


    }
}

export default JumioComponent;
