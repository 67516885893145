import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {useNavigate, useLocation} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useAlert} from "../context/alert/alertContext";
import {useForgotPasswordService} from "../context/users/forgotPassword.service";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(8),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonTypo: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));

const ForgotPassword = ({flow}) => {
    const classes = useStyles();
    const history = useNavigate();
    const { showAlert } = useAlert();
    const service = useForgotPasswordService();
    const {state} = useLocation();

    const [email, setEmail] = useState(state?.email || '');
    const [emailError, setEmailError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.debug(`challenge user ${'some-email'} with otp`);

            const serverResponse = await service.challengeUserOtp({email});
            if (serverResponse === "alreadyActivated") {
                flow = null;
            } else {
                flow = "activateAccount"
            }
            history('/forgotPasswordEnterOtp', {state:{flow, email}});
        } catch (e) {
            console.error(e);
            if (e.response && e.response.status == 404) {
                setEmailError('Email not found');
            } else {
                showAlert(`Unexpected error: ${e.message}`, 'error');
            }
        }
    }

    return (
        <Container component="div" maxWidth="xs">
            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'} height={60}></img>
                <Box p={2}>
                <Typography variant="h5">
                    {flow && flow === "activateAccount" ? "Activate account" : "Forgot password"}
                </Typography>
                </Box>
                <Typography variant="subtitle2">
                    Enter your email or user Id.
                </Typography>
                <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        error={!!emailError}
                        value={email}
                        variant="outlined"
                        margin="normal"
                        id="email"
                        label="Email or User Id"
                        autoFocus
                        fullWidth
                        helperText={emailError}
                        onChange={(evt) => {
                            setEmail(evt.target.value);
                        }}
                        // type="email"
                    />
                    <Button fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default ForgotPassword;
