import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useAlert} from "../context/alert/alertContext";
import {useForgotPasswordService} from "../context/users/forgotPassword.service";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(8),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonTypo: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));

export const ForcePasswordPolicyReset = () => {
    const location = useLocation();
    const params = useParams();
    const  state  = location.state || params;
    const history = useNavigate();
    console.log(state);
    if(typeof state !== 'object' || !state.userNameOrEmail){
        history('/');
    }
    const classes = useStyles();
    const { showAlert } = useAlert();
    const service = useForgotPasswordService();
    const { control, handleSubmit, errors } = useForm({
        mode: 'all',
    });
    const [errorComponent, setErrorComponent] = useState(null);

    const extractInfoError = (info) => {
        if(info && typeof info === 'object') {
            const errorInfoMessages = <ul>{Object.values(info).map(message=>{
                return <li><b>{message}</b></li>
            })}</ul>
            const elem = <>
                <Typography variant="subtitle2" color={'error'}>
                    Password does not match policy
                </Typography>
                {errorInfoMessages}
            </>
            return elem;
        }
        else{
            return null;
        }

    };

    const errorMessageElementFactory = (fieldError, rules = {}) => {
        const ruleMessages = [
            { type: 'required', messageProvider : (rules) => 'field is required' },
            { type: 'validate', messageProvider : (rules) => rules.hasOwnProperty('validate') ? rules.validate : '' },
            { type: 'min', messageProvider : (rules) => `min value ${rules.hasOwnProperty('min') ? rules.min : ''}` },
        ]
        if(fieldError) {
            for(let index = 0; index < ruleMessages.length; index ++) {
                const ruleMessage = ruleMessages[index];
                if(ruleMessage) {
                    if(fieldError.type === ruleMessage.type){
                        return (
                            <Typography color={'error'} variant={'subtitle2'}>{ruleMessage.messageProvider(rules)}</Typography>
                        );
                    }
                }
            }
        }
        return null;
    }

    const resetPassword = async (formData) => {
        console.log(formData);
        try {
            const result = await service.forceResetPassword({
                usernameOrEmail: state.userNameOrEmail,
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
            });
            if(result) {
                showAlert(`Successfully updated password`, 'success');
                history('/');
            }
            else {
                showAlert(`Could not update password`, 'error');
            }
        }
        catch (e) {
            if (e.response && e.response.status === 400 && e.response.data.code === 'password_policy') {
                const {code,message,rules} = e.response.data;
                const rulesElem = <ul>{Object.keys(rules).map(r=>{
                    const found = rules[r];
                    if (found){
                        return <li><b>{r}</b></li>
                    }else{
                        return <li>{r}</li>
                    }
                })}</ul>
                const _message = <>
                    {message}
                    {rulesElem}
                </>
                setErrorComponent(_message);

            } else if (e.response && e.response.status === 401) {
                showAlert(e, 'error');
            } else {
                console.error(e);
                showAlert(`Unexpected error: ${e.message}`, 'error');
            }
        }
    }

    return (
        <Container component="div" maxWidth="xs">
            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'} height={60}></img>
                <Box p={2}>
                    <Typography variant="h5">
                        Reset password
                    </Typography>
                </Box>
                {
                    extractInfoError(state && state.info)
                }
                <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(resetPassword)}>
                    <Controller
                        control={control}
                        name={'oldPassword'}
                        rules={{
                            required: true,
                        }}
                        defaultValue={''}
                        render={({onChange, onBlur, value}) => {
                            return (
                                <TextField
                                    required
                                    type={'password'}
                                    variant="outlined"
                                    margin="normal"
                                    label="Old password"
                                    fullWidth
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(evt) => {
                                        onChange(evt.target.value);
                                    }}
                                />
                            );
                        }}
                    />
                    {
                        errorMessageElementFactory(errors.oldPassword, { required: true })
                    }
                    <Controller
                        control={control}
                        name={'newPassword'}
                        rules={{
                            required: true,
                        }}
                        defaultValue={''}
                        render={({onChange, onBlur, value}) => {
                            return (
                                <TextField
                                    required
                                    type={'password'}
                                    variant="outlined"
                                    margin="normal"
                                    label="New password"
                                    fullWidth
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(evt) => {
                                        onChange(evt.target.value);
                                    }}
                                />
                            );
                        }}
                    />
                    {
                        errorMessageElementFactory(errors.newPassword, { required: true })
                    }
                    <Controller
                        control={control}
                        name={'repeatPassword'}
                        rules={{
                            required: true,
                            validate: (value) => {
                                const formValues = control.getValues();
                                return typeof formValues === 'object' && formValues['newPassword'] === value || false;
                            }
                        }}
                        defaultValue={''}
                        render={({onChange, onBlur, value}) => {
                            return (
                                <TextField
                                    required
                                    type={'password'}
                                    variant="outlined"
                                    margin="normal"
                                    label="Repeat password"
                                    fullWidth
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(evt) => {
                                        onChange(evt.target.value);
                                    }}
                                />
                            );
                        }}
                    />
                    {
                        errorMessageElementFactory(errors.repeatPassword, { required: true, validate: 'New password does not match' })
                    }
                    {
                        errorComponent
                    }
                    <Button fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Container>
    );
}
