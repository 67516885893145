
import * as React from "react";
import {
    ContextAware,
    ContextAwareGenericFieldProperties,
    GenericFieldParams
} from "./generic_component_base";
import {
    extractContextVariables,
    FieldGroupInfo,
    FieldGroupTemplate,
    FileChangeCallback,
    FormFieldValue,
    FormValues,
    ValueChangeCallback,
    ICheck, GroupCheck, IActions, mergeServiceGroups, mergeServices
} from "./FormContainerBase";
import {
    ContextVariable,
    makeContextEvaluator,
    SancusConditionEvaluator
} from "./conditionEvaluator.service";
import {makeShouldRender} from "./FormContainerUtils";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import {useContext} from "react";
import LayoutContext, {Layout} from "./LayoutContext";
import {FormField} from "./camunda_helper";
import DesignModeContext from "../common/DesignModeContext";
import EditFieldContext from "./EditFieldContext";



export type GroupComponentParams = {
    // id:string,
    name:string,
    path:string
    template: FieldGroupTemplate,
    value: FormValues,
    valueChangeCbk: ValueChangeCallback,
    fileChangeCbk: FileChangeCallback,
    readOnly?:boolean,
    checkMessages?: GroupCheck,
    index: number;
    designMode?: boolean;
    visibleElements?:number | null,
    methods?/*:UseFormMethods*/,
    label?: string | null,
    helperText?: string | null,
} & ContextAware;

export type GroupComponentState = {
    groupContextEvaluator: SancusConditionEvaluator,
    collapsed:boolean,
}


const toContextAware = (
    genericFieldProps: GenericFieldParams,
    context: SancusConditionEvaluator
): ContextAwareGenericFieldProperties => {
    return {
        ... genericFieldProps,
        contextEvaluator: context,
    }
};


export interface GroupRenderers {
    FieldCheckAvatar:React.FunctionComponent<{check:ICheck}>,
    GenericFieldComponent:React.FunctionComponent<GenericFieldParams>,
    RepeatableGridItem//:(props:Partial<{ item, key:String,xs:number,className:string, children:any}>)=>JSX.Element //TODO: Describe better

}

export abstract class GroupComponentBase extends React.Component<GroupComponentParams, GroupComponentState> {
    protected constructor(props: GroupComponentParams,protected GroupRenderers:GroupRenderers) {
        super(props);



        const groupContextEvaluator =
            makeContextEvaluator({
                variables: props.value && Object.keys(props.value).map((fieldName: string): ContextVariable => {
                    return {
                        variableName: fieldName,
                        variableValue: props.value[fieldName],
                    }
                }) || [],
            },props.contextEvaluator);

        this.state = {
            collapsed: this.props.visibleElements !=null? this.props.visibleElements < Object.keys(this.props.template).length : false,
            groupContextEvaluator: groupContextEvaluator,
        };
    }



    fileChangeCbk(key: string): ValueChangeCallback {
        return async (internal_key: string, value: FormFieldValue): Promise<void> => {
            const newKey=`${this.props.name}.${key}`;
            this.props.fileChangeCbk(newKey,value);
        }
    }

    valueChangeCbk(key:string): ValueChangeCallback {
        return async (internal_key: string ,value: FormFieldValue) => {
            console.debug("ChangeCBK for key",key,value,this.props.value[key],this.props.value[key] != value);
            if (this.props.value[key] !== value){ //Empty or null can be different from false
                this.props.value[key] = value;

                //update current context
                const groupContextEvaluator =
                    makeContextEvaluator({
                        variables: Object.keys(this.props.value).map((fieldName: string): ContextVariable => {
                            return {
                                variableName: fieldName,
                                variableValue: this.props.value[fieldName],
                            }
                        }),
                    },this.props.contextEvaluator);


                //If a value is conditionally hidden  - set to null
                Object.keys(this.props.value)
                    //get hidden field names
                    .filter(fieldName => {
                        return !this.shouldRenderField(fieldName, groupContextEvaluator);
                    })
                    //Unless it is readdonly
                    .filter(fieldName=>{
                        const fieldProps: GenericFieldParams = this.props.template[fieldName];
                        return !(fieldProps.validationConstraints?.some(v=>v.name === 'readonly'))
                    })
                    //reset values
                    .forEach(fieldName => {
                        const fieldProps: GenericFieldParams = this.props.template[fieldName];
                        const defaultValue = fieldProps.defaultValue;

                        this.props.value[fieldName] = defaultValue !=null ? defaultValue : undefined;
                    });

                console.debug("setting state");
                this.setState({
                    ... this.state,
                    groupContextEvaluator: groupContextEvaluator,
                });
                console.debug("Props callback");
                await this.props.valueChangeCbk(this.props.name,this.props.value);
            }

        }
    }

    /**
     * Returns a boolean to render or not field name from template containing the visible property rule
     * using the current state context evaluator.
     * @param fieldName
     * @param evaluator another evaluator to use
     * @protected
     */
    protected shouldRenderField(fieldName: string, evaluator?): boolean {
        if (this.props.designMode){
            return true;
        }

        const fieldProps: GenericFieldParams = this.props.template[fieldName];
        const shouldRender = makeShouldRender(evaluator && typeof evaluator === 'object' ? evaluator : this.state.groupContextEvaluator);
        const render = shouldRender(fieldProps);
        return render;
    }


    isReadOnly = (template): boolean  =>{
        return Object.keys(template).every(key=>{
            const _template = template[key];
            return _template.validationConstraints.some(v=>v.name === 'readonly')
        })
    }

    entryFactory(key:string, counter:number, checkMessages, readOnly:boolean,layout:Layout,hidden:boolean,idx?:number){

        const template:GenericFieldParams = this.props.template[key];
        let _value;
        if (this.props.value && this.props.value[key] != null){
            _value = this.props.value[key]
        }else{
            _value = template.value?.value
        }

        return this.renderEntry(key, counter, toContextAware(template, this.state.groupContextEvaluator),_value, checkMessages,readOnly,layout,hidden,idx);
    }

    renderEntry(key: string, counter:number, template: GenericFieldParams, _value: any, checkMessages: ICheck[],readOnly:boolean,layout:Layout,hidden:boolean,idx?:number):JSX.Element {
        const GenericFieldComponent = this.GroupRenderers.GenericFieldComponent;
        const RepeatableGridItem = this.GroupRenderers.RepeatableGridItem;


        const xs = template.properties?.component === 'Header'? 12 : 12 /* modified to remove xs={6} */;
        const md = template.properties?.xs && parseInt(template.properties?.xs);



        if( readOnly && template.properties?.component === 'Header' ){
            return <></>;
        }

        let ret;
        if (readOnly || this.props.name.startsWith("group_in_dialog_")){
            ret = <GenericFieldComponent key={key} {...template}
                                         _value={_value}
                                         data-idx-in-group={idx}
                                         index={this.props.index}
                                         path={this.props.path+'.'+key}
                                         checkMessages={checkMessages ? checkMessages[key]:undefined}
                                         valueChangeCbk={this.valueChangeCbk(key)}
                                         fileChangeCbk={this.fileChangeCbk(key)}

            />
        }else{
            const element = <GenericFieldComponent {...template}
                                                   _value={_value}
                                                   data-idx-in-group={idx}
                                                   path={this.props.path+'.'+key}
                                                   index={this.props.index}
                                                   valueChangeCbk={this.valueChangeCbk(key)}
                                                   fileChangeCbk={this.fileChangeCbk(key)}
                                                   checkMessages={checkMessages ? checkMessages[key]:undefined}
            />
            if (layout === 'facing'){
                ret = <GenericFieldComponent key={key} {...template}
                                             _value={_value}
                                             index={this.props.index}
                                             path={this.props.path+'.'+key}
                                             checkMessages={checkMessages ? checkMessages[key]:undefined}
                                             data-idx-in-group={idx}
                                             valueChangeCbk={this.valueChangeCbk(key)}
                                             fileChangeCbk={this.fileChangeCbk(key)} />
            }else{
                if (hidden){
                    ret = <RepeatableGridItem key={key} item xs={xs} md={md} data-idx-in-group={idx} className={'rg-item-hidden'} style={{
                        display: 'none',
                        visibility: 'hidden',
                        height: '0px',
                    }} >
                            {element}
                    </RepeatableGridItem>
                }else {
                    ret = <RepeatableGridItem key={key} item xs={xs} md={md} className={'rg-item'} data-idx-in-group={idx}>
                        {element}
                    </RepeatableGridItem>
                }
            }
        }

        return ret;



    }
    protected renderDesignMode(){
        return <></>
    }

    protected renderWithCheckFields(content:JSX.Element[],serviceNotificationTpl?:JSX.Element[]):JSX.Element{
        //Base implementation - overriden by Group in web
        return <>{content}</>;
    }

    render(){
        return <DesignModeContext.Consumer>{
        designMode=>{
            if(designMode){
                return this.renderDesignMode()
            }else{
                return <LayoutContext.Consumer>
                    {layout=>this._render(layout)}
            </LayoutContext.Consumer>}
        }}
        </DesignModeContext.Consumer>
    }

    setCollapsed = (_collapsed:boolean)=>{
        if (this.state.collapsed !== _collapsed) {
            this.setState({
                collapsed: _collapsed
            })
        }
    }

    protected abstract renderWithCollapser(content:JSX.Element):JSX.Element;


    componentDidMount() {
        const readOnly:boolean = this.props.readOnly !== undefined ?
            this.props.readOnly:
            this.isReadOnly(this.props.template);

        if (!readOnly && this.props.value && !this.props.value.uuid){
            const uuid = uuidv4();
            const nValue = {uuid,...this.props.value}
            this.props.valueChangeCbk(this.props.name,nValue).then(()=>{
                console.debug("Updated uuid for group",this.props.name);
            })

        }

    }

    _render(layout) {
        const label = this.props.label;
        const helperText = this.props.helperText;
        const FieldCheckAvatar = this.GroupRenderers.FieldCheckAvatar;
        const readOnly:boolean = this.props.readOnly !== undefined ?
            this.props.readOnly:
            this.isReadOnly(this.props.template);

        const checkServices = _.chain(this.props.checkMessages)
            .values()
            .flatten()
            .uniqBy(check => check.uuid)
            .value();
        // render notification template
        const serviceNotificationTpl = mergeServices(checkServices).map((service) => {
            return <FieldCheckAvatar check={service} key={service.service}/>
        })

        let counter=0;



        let anyHidden = false;

        const content = Object.keys(this.props.template).filter(this.shouldRenderField.bind(this)).map((key,idx)=>{
            const readonly_field = this.props.template[key].validationConstraints?.some(v=>v.name === 'readonly');
            const required_field = this.props.template[key].validationConstraints?.some(v=>v.name === 'required');
            const gIdx = this.props.index
            const has_errors = this.props.methods?.errors[key+'_'+gIdx] != null;

            const hidden = !has_errors && !required_field && this.props.visibleElements!=null && this.state.collapsed && idx >= this.props.visibleElements
            anyHidden = anyHidden || hidden;

            const template: GenericFieldParams = this.props.template[key];
            if (template.properties?.component === 'Header') {
                counter = 0;
            } else {
                counter++;
            }
            return this.entryFactory(key, counter, this.props.checkMessages, readOnly, layout,hidden,idx);

        })




        const contentWithChecks = this.renderWithCheckFields(content,readOnly && serviceNotificationTpl || undefined);

        if (anyHidden && !readOnly && this.props.visibleElements!=null && this.props.visibleElements < Object.keys(this.props.template).length){
            return this.renderWithCollapser(contentWithChecks);
        }

        return contentWithChecks;
    }
}
