export default {
    "$schema":"https://unpkg.com/@camunda/element-templates-json-schema/resources/schema.json",
    "name": "Generate Application Id",
    "id": "com.norbloc.sancus.integrations.GenerateAppId",
    "appliesTo": [
        "bpmn:Task"
    ],
    "icon":{
        "contents":"https://raw.githubusercontent.com/twbs/icons/main/icons/asterisk.svg"
    },
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },

    "properties": [
        {
            "label": "Application Id (variable name)",
            "type": "String",
            "value": "application_id",
            "editable": true,
            "binding": {
                "type": "camunda:outputParameter",
                "scriptFormat": "groovy",
                "source":
`def application_id =  org.apache.commons.lang3.RandomStringUtils.randomNumeric(6)
println "generated application_id:$application_id"
execution.setProcessBusinessKey(application_id)
return application_id`
            }
        },
        {
            "label": "Application URL (variable name)",
            "type": "String",
            "value": "application_url",
            "editable": true,
            "binding": {
                "type": "camunda:outputParameter",
                "source":`$\{appProps.get('app.server-host')}/show/$\{execution.processDefinition.key}?processInstanceId=$\{execution.processInstanceId}`
            }
        }
    ]
}