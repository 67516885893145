import {FormData, FormField, getStartForm, ProcessInstanceStatus, StartFormData, TaskFormData} from "./camunda_helper";
import contextPath from "./contextPath";
import { SancusConditionEvaluator } from "./conditionEvaluator.service";

export function registerProcessSSE(processInstanceId:string,messageCBK:(message:string | null)=>void):EventSource | null{ //TODO: Refactor as observable
    try{
    const evtSource = new EventSource(`${contextPath}api/sse/${processInstanceId}`, { withCredentials: true } );

        evtSource.onopen = event=>{}
        evtSource.onerror = event=>{}

        evtSource.onmessage = event=>{
            const message = event.data;
            messageCBK(message);
            if ('HistoricProcessInstanceEventEntity:end' === event.data){
                messageCBK(null);
                evtSource.close();
            }

        }

        return evtSource;
    }catch (e) {
        return null
    }

}


export function stateValue(fv_value){
    const name = fv_value.id;
    const type = fv_value.typeName;
    const javaType = fv_value.value.type.javaType;
    const repeatable = !!fv_value.properties?.repeatable
    const readonly = !! fv_value.validationConstraints?.some(v=>v.name === 'readonly')
    const required = !! fv_value.validationConstraints?.some(v=>v.name === 'required')
    let value = fv_value.value.value;

    if (repeatable){
        value = [value];
    }
    if (!readonly) {
        return {type, value, javaType, readonly, required, repeatable}
    }
}

export function fvValueReducer(acc,fv_value){
    const name = fv_value.id;
    const readonly = !! fv_value.validationConstraints?.some(v=>v.name === 'readonly')


    if (!readonly) {
        const sv = stateValue(fv_value);
        acc[name] = sv;
    }
    return acc;
}

/**
 * The should render field function.
 */
interface ShouldRenderField {
    (field: FormField): boolean;
}

/**
 * ShouldRender field function factory.
 * @param contextEvaluator
 */
export const makeShouldRender = (contextEvaluator: SancusConditionEvaluator): ShouldRenderField => {

    /**
     * - false on 'false' value.
     * - true on 'true' value.
     * - try evaluate expression on string value in given context (from factory) and return the boolean result.
     * - else true.
     * @param field
     * @return boolean.
     */
    return (field: FormField): boolean => {
        if(field && field.properties && field.properties.hasOwnProperty('visible')
            && typeof field.properties.visible === 'string') {
            const { visible } = field.properties;
            try{
                const isVisible = contextEvaluator.eval(visible);
                return !!isVisible
            }catch (e){
                console.debug("Error evaluating visible condition: %s",visible, e);
                return true;
            }
        }
        return true;
    };
};
