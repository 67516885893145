import {createContext, useContext, useEffect, useMemo, useState} from "react";
import cpp from "./contextPath";
import * as React from "react";
import {useIntl} from "react-intl";

export type Messages = {[msgId:string]:string} | null


export const LanguageContext = createContext<{
    lang:string | null | undefined,
    locale:string | null | undefined,
    messages:Messages,

    processDefinitionKey:string | null,
    setProcessDefinitionKey:(p:string)=>void
} | null>(null);



async function messagesPromiseFN(processDefinitionKey,lang){

    const url=`${cpp.publicURL}translations/${processDefinitionKey}_${lang}.tsv`
    return fetch(url).then(rsp=>{
        return rsp.text();
    }).then(txt=>{
        const rows = txt.split("\n");
        return rows.reduce((acc,row)=>{
            const cols = row.split('\t');
            acc[cols[0].trim()] = (cols[1] || '').trim();
            return acc;
        },{})
    }).catch(err=>{
        console.warn(`[messagesPromiseFN]Cannot find translations in ${url}`,err)
        return null;
    })
};



export function LanguageContextHandler(params){
    const [processDefinitionKey,setProcessDefinitionKey] = useState(params.processDefinitionKey)
    const [messages,setMessages] = useState<Messages>(null);
    useEffect(()=>{
        if (params.lang) {
            if (processDefinitionKey) {
                const paramPrefetchPromise = params.preFetch || Promise.resolve()
                paramPrefetchPromise.then(()=>messagesPromiseFN(processDefinitionKey,params.lang).then(_messages=>{
                    if (_messages == null){
                        console.log(`[messagesPromiseFN]Did not find any messages for ${processDefinitionKey}_${params.lang}`);
                        setMessages({});
                    }else {
                        console.log(`[messagesPromiseFN]Found messages for ${processDefinitionKey}_${params.lang}`);
                        setMessages(_messages);
                    }
                })).catch(err=>{
                    console.error(`[messagesPromiseFN]Could not fetch messages for ${processDefinitionKey}_${params.lang}`,err);
                    return {};
                })
            }else{
                setMessages({}); //TODO: Possibly coming from taskId and must resolve the processDefKey
            }
        }else{
            setMessages({})
        }
    },[processDefinitionKey])
    return (
        <LanguageContext.Provider value={{
            lang:params.lang,
            locale:params.lang,
            messages,

            processDefinitionKey,
            setProcessDefinitionKey

    }} >{messages? params.children : <></>}</LanguageContext.Provider>)
}


export function withLang(Node) {
    return useMemo(()=> {
        return function WithLang(props) {
            const lc = useContext(LanguageContext);
            const intl = useIntl();
            return <Node {...props} lang={lc?.lang} locale={lc?.locale} intl={intl}>{props.children}</Node>
        }
    },[Node.name]);

}
