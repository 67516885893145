import * as React from 'react';
import {useMemo} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {createAction} from 'redux-actions';
import {FormValues} from "../../common/FormContainerBase";


export namespace RepeatableDialogActions {
    export enum Type {
        SHOW_REPEATABLE_DIALOG = 'SHOW_REPEATABLE_DIALOG',
        CLOSE_REPEATABLE_DIALOG = 'CLOSE_REPEATABLE_DIALOG'
    }

    export const showRepeatableDialog = createAction<{repeatableGroupId:string,idx:number,value:FormValues,maxIdx:number}>(Type.SHOW_REPEATABLE_DIALOG);
    export const closeRepeatableDialog = createAction<{}>(Type.CLOSE_REPEATABLE_DIALOG);
}

export type RepeatableDialogActions = Omit<typeof RepeatableDialogActions, 'Type'>;
export const useRepeatableDialogActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = RepeatableDialogActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as RepeatableDialogActions;
};


