import * as React from "react";
import {useDispatch, useSelector} from 'react-redux';

import {ICheck, ILoggedUserState} from "sancus-client-common/dist/common/FormContainerBase";
import CheckComponent from "./Check.component";
import CheckFilterComponent from "./CheckFilter.component";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";
import {useCheckActions} from "sancus-client-common/dist/store/actions/checks";
import {Grid, Typography} from "@material-ui/core";
import {useContext} from "react";
import FormHandlerContext from "sancus-client-common/dist/common/handlers";

interface IChecksContainerProps {
	checks:ICheck[]
	valueChangeCbk(formDataProp: string, value: ICheck[]): void;
	processInstanceId?: string | null;
	loggedUserState?: ILoggedUserState;
	taskId: string | null | undefined;
	formReference: any;
}


/**
 * Checks and control container
 * @constructor
 */
const ChecksContainerComponent = (props: IChecksContainerProps) => {
	const dispatch = useDispatch();
	const formHandler = useContext(FormHandlerContext)
	const checkActions = useCheckActions(dispatch);

	const { checks:_checks, filter, selectedCheck } = useSelector((state: RootState) => {
		return {
			checks: state.checks,
			filter: state.checkFilter,
			selectedCheck: state.selectedCheck
		};
	});
	const checks = props.checks;

	const filteredChecks = React.useMemo(
		() => (filter ? checks.filter((c: ICheck) => {
			return c.status === filter
		}): checks),
		[checks, filter])
		.sort((a,b)=>{
			return a['idx']  - b ['idx'];
		});


	/**
	 * Used to scroll to view section
	 * @param checkId
	 */
	const scrollToSection = (checkId: string) => {
		if (selectedCheck && selectedCheck.uuid === checkId) {
			return;
		}
		const serviceCheckElem = document.getElementById(`${checkId}_view`);
		if (serviceCheckElem){
			serviceCheckElem.scrollIntoView();
		}else{
			console.warn(`No scroll element found for id ${checkId}_view`)
		}

	}
	// this is not an action cause it messes with state above store(formContainer state)
	const handleAddCheckNote = async (checkId: string, text: string) => {
		if (!props.taskId){
			throw new Error("taskId is not defined")
		}
		try {
			const latestChecksRes = await formHandler.addCheckNote(props.processInstanceId as string, checkId, props.taskId, text);
			const latestChecks = await latestChecksRes.json();
			props.valueChangeCbk('checks', latestChecks)
		} catch (e) {
			console.warn('error updating check')
			return;
		}

	}
	// this is not an action cause it messes with state above store(formContainer state)
	const updateCheckStatus = async (checkId: string, resolve: boolean) => {
		if (!props.taskId){
			throw new Error("taskId is not defined")
		}
		try {
			const latestChecksRes = await formHandler.setCheckStatus(props.processInstanceId as string, checkId, props.taskId, resolve);
			const latestChecks = await latestChecksRes.json();
			props.valueChangeCbk('checks', latestChecks)
		} catch (e) {
			console.warn('error updating check')
			return;
		}
	}

	const checksElements = filteredChecks
		.map((check: ICheck) => {
			const isSelected = !!(selectedCheck && selectedCheck.uuid === check.uuid);
			return <CheckComponent
				key={check.uuid}
				check={check}
				isSelected={isSelected}
				scrollToFields={scrollToSection}
				handleResolveCheck={updateCheckStatus}
				handleAddNote={handleAddCheckNote}
				loggedUserState={props.loggedUserState}
			/>
		});

	return  <Grid container direction="column" item xs style={{ background: "#ffffff", borderLeft: 'solid 1px rgba(0,0,0,10%)' }}>
		<Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ minHeight: '60px', padding: '16px' }}>
			<Grid item xs><Typography variant="subtitle1">Checklist</Typography></Grid>
			<CheckFilterComponent changeFilter={checkActions.applyAlertFilter} filterValue={filter}/>
		</Grid>
		<Grid id="checklist" item xs>
			{checksElements}
		</Grid>
	</Grid>
}

export default ChecksContainerComponent;
