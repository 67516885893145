import {useDrag, useDrop} from "react-dnd";


import * as React from "react";


export const element = Symbol("Element")
export const palette = Symbol("Palette")

export function WithDrop<T>(props:{children,accept,dropHandler:(dp:T,monitor)=>void}){
    const {children,dropHandler} = props;
    const [{ isOver }, drop] = useDrop<T,void,{isOver:boolean}>(() => ({
        accept: props.accept,
        drop: dropHandler,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        }),
    }))

    return <div style={{border:isOver && '3px purple solid' || ''}} ref={drop} >{children}</div>
}

export function WithDrag(props:{dragProps,type,children:((drag,isDragging)=>JSX.Element)}){
    const [{ isDragging }, drag] = useDrag(() => ({
        type: props.type,
        item: props.dragProps,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }))

    return props.children(drag,isDragging)

}
