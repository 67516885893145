import * as React from "react";
import {PEPResult,AlertEntity} from "sancus-client-common/dist/utils/pepApi.interface";
import * as pepApiUtil from 'sancus-client-common/dist/utils/pepApi.util';
import {Box, Divider, Grid, Link, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

import contextPath from "sancus-client-common/dist/common/contextPath";
import MomentUtils from "@date-io/moment";
import JsonTreeViewer from "./generic_component/JsonTreeViewer";
import {MemberCheckReport} from "./membercheckPep.component";
import LexisNexisReportComponent from "./pep/lexis-nexis/LexisNexisReportComponent";

const useStyles = makeStyles(() => ({
    green: {
        margin: '16px 0px',
        border: 'none',
        padding: '16px'
    },
    yellow: {
        margin: '16px 0px',
        border: 'none',
        padding: '16px'
    },
    red: {
        margin: '16px 0px',
        border: 'none',
        padding: '16px'
    }
}));

const momentUtils = new MomentUtils();

function Event(props) {
    const {event, idx} = props;

    return <Box mt={1} key={"event:" + idx}>
        <Grid>
        <Box mt={1} mb={1}>
            <Grid container direction={"row"} style={{justifyContent: 'space-between'}}>
                <Grid item>
                    <Grid item data-id={event.category.categoryCode}><Box fontWeight="fontWeightBold">{event.category.categoryDesc}</Box></Grid>
                    <Grid item data-id={event.subCategory.categoryCode}><Box fontStyle="italic">{event.subCategory.categoryDesc}</Box></Grid>
                </Grid>
                <Grid item>{event.eventDt}</Grid>
            </Grid>
        </Box>
        </Grid>
        <Grid><Box mt={1} mb={1}>{event.eventDesc}</Box></Grid>
    </Box>
}

function Attributes(props) {
    const {attributes} = props
    const riskography = attributes.filter(a => a.attCode === 'RGP').map(a => a.attVal).join("\n")
    const remarks = attributes.filter(a => a.attCode === 'RMK').map(a => a.attVal).join("\n")
}

export function AlertEntityCmp(props) {
    const entity:AlertEntity = props.entity;

    const name = entity.entityName;
    const birthDt = entity.entityTyp === 'P' && entity.birthDt && entity.birthDt[0] && momentUtils.moment(entity.birthDt[0]).format('YYYY-MM-DD');
    const birthCountry = entity.entityTyp === 'P' && entity.postAddr && entity.postAddr.filter(a => a.locatorTyp === 'BIRTH').map(a => a.countryCode.countryCodeValue)[0]

    return <Box>
        <Box mt={1} mb={1} key={"pep:" + entity.entityId}>
            <Grid container direction={"row"} style={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Grid item>
                    <Link href={entity.source.sourceURL} target="_blank" variant="body2" style={{color: 'black'}}>
                        <b>{name}</b>
                    </Link>
                </Grid>

                <Grid item>
                    <Grid container direction={"row"}>
                        {birthDt && <Grid>Born: {birthDt}</Grid>}
                        {birthCountry && <Grid>&nbsp;&nbsp;&nbsp;{birthCountry}</Grid>}
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container direction={"row"}>
                        <Grid>Match score: {entity.matchScore}</Grid>
                        <Grid>&nbsp;&nbsp;&nbsp;Risk score: {entity.riskScore}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        <Box>
            <ul>
                {entity.event.map((event, idx) =>
                    <li><Event event={event} idx={idx}/></li>
                )}
            </ul>
        </Box>
    </Box>
}

export default function PepReportComponent(props) {
    const provider = props.value?.provider;
    switch (provider) {
        case 'rdc':
            return <RDCReportComponent {...props}/>
        case 'sanctions-io':
            return <SanctionsIOReportComponent {...props}/>;
        case 'comply-advantage':
            return <ComplyAdvantageReportComponent {...props}/>
        case 'lexis-nexis':
            return <LexisNexisReportComponent {...props} />
        case 'membercheck':
            return !!props.value ? <MemberCheckReport check={props.value}/> : null;
        default:
            return <JsonTreeViewer name={'pep_meta'} label={provider+'_pep_meta'} value={props.value} valueChangeCbk={(...args)=>{
                console.log('valueChangeCbk',args)
            }} />
    }
}

export function ComplyAdvantageHitSnippet({hit}) {
    if (!hit){
        return <></>
    }
    const {score,doc} = hit;
    const {name,types,source_notes,associates} = doc

    const fields:any[] | undefined = doc.fields;
    const countries = fields?.find(field=> {
        return field.name == 'Country' || field.name == 'Countries'
    });

    const country = countries?.value

    const urls = source_notes && Object.keys(source_notes).map(sk=>{
        const v = source_notes[sk];
        const {name,url} = v;
        return {name,url}

    })
    const types_concat = types?.join(',')
    return <Grid item container direction={"column"} justifyContent={"space-between"} wrap={"nowrap"} >
        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}>Name: </Grid><Grid item>{name}</Grid>
        </Grid>
        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}>Country: </Grid><Grid item>{country}</Grid>
        </Grid>
        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}>Score: </Grid><Grid item>{score}</Grid>
        </Grid>
        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}> Types: </Grid><Grid item>{types_concat}</Grid>
        </Grid>
        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}>Sources: </Grid>
            <Grid item justifyContent={"space-between"}>{urls.map(({name,url})=>{
                return <a target="_blank" href={url}>{name}</a>
            })}</Grid>
        </Grid>

        <Grid item container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={5}>Associates: </Grid>
            <Grid item justifyContent={"space-between"}>{associates?.map(associate=>{
                const {name,association} = associate;
                return <Grid item>{name} ({association})</Grid>
            })}</Grid>
        </Grid>


    </Grid>
}

interface SanctionsIOPropsResult {
    data_source: { name: string; short_name: string };
    source_list_url: string | null;
    confidence_score: number;
    name: string;
    alt_names: string[] | null;
    date_of_birth: string[] | null;
    gender: string | null;
    additional_information: {
        gender: string | null;
        regime: string | string[] | null;
        position: string | null;
        date_designated: string[] | null;
        tax_id: string | null;
        uk_statement_of_reasons: string | null;
        uk_sanctions_list_ref: string | null;
        secondary_sanctions_risk: string | null;
        registration_number: string | null;
        government_gazette_number: string | null;
        organization_established_date: string | null;
        organization_type: string | null;
    } | null;
    address: string[] | null;
    place_of_birth: string[] | null;

    entity_type: string | null;
    citizenship: string[] | null;
    passport_details: string | null;
    country_residence: string[] | null;
    nationality: string[] | null;
    start_date: string | null;
    program: string[] | null;
    remarks: string | null;
    title: string | null;
    source_information_url: string | null;

}

export interface SanctionIOProps {
    query: { fullName?: string; orgName?: string; type: 'user' | 'org' };
    results: SanctionsIOPropsResult[];
}


const fromArrayOrString = (value: string | string[] | null): string | null =>{
    if (value && value.length > 0){
        if (Array.isArray(value)){
            return value.join(', ');
        }
        return value;
    }else{
        return null;
    }
}

export const SanctionsIOReportItemComponent = (props: {result: SanctionsIOPropsResult}) => {
    const result = props.result;

    return (
        <Grid container direction={'column'} wrap={'nowrap'} spacing={1}>
            <Grid item>
                <Typography variant={'subtitle1'}>{`${result.data_source.name} (${result.data_source.short_name})`}</Typography>
            </Grid>
            {
                !!result.source_list_url && <Grid item>
                    <a href={result.source_list_url} target={'_blank'}><Typography variant={'subtitle2'}>{`${result.source_list_url}`}</Typography></a>
                </Grid>
            }
            <Grid item>
                <Typography variant={'subtitle1'}>{`Confidence score: ${result.confidence_score}`}</Typography>
            </Grid>
            <Grid item>
                <Typography variant={'subtitle1'}>{`Name: ${result.name} ${result.gender || (result.additional_information && result.additional_information.gender) ? `(${result.gender || (result.additional_information && result.additional_information.gender)})`: ''}`}</Typography>
            </Grid>
            {
                result.title && <Grid item>
                    <Typography variant={'subtitle1'}>{`title: ${result.title}`}</Typography>
                </Grid>
            }
            {
                result.remarks && <Grid item>
                    <Typography variant={'subtitle1'}>{`Remarks: ${result.remarks}`}</Typography>
                </Grid>
            }

            {
                result.additional_information && result.additional_information.regime && result.additional_information.regime.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Regime: ${fromArrayOrString(result.additional_information.regime)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.position && result.additional_information.position.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Position: ${result.additional_information.position}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.date_designated && result.additional_information.date_designated.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Date designated: ${fromArrayOrString(result.additional_information.date_designated)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.tax_id && result.additional_information.tax_id.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Tax id: ${result.additional_information.tax_id}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.uk_statement_of_reasons && result.additional_information.uk_statement_of_reasons.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`UK statement of reasons: ${result.additional_information.uk_statement_of_reasons}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.uk_sanctions_list_ref && result.additional_information.uk_sanctions_list_ref.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`UK sanctions list ref: ${result.additional_information.uk_sanctions_list_ref}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.secondary_sanctions_risk && result.additional_information.secondary_sanctions_risk.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Secondary sanctions risk: ${result.additional_information.secondary_sanctions_risk}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.additional_information && result.additional_information.registration_number && result.additional_information.registration_number.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{
                            `Registration number: ${result.additional_information.registration_number}`
                        }</Typography>
                    </Grid> : <></>
            }

            {
                result.passport_details && <Grid item>
                    <Typography variant={'subtitle1'}>{`Passport details: ${result.passport_details}`}</Typography>
                </Grid>
            }
            {
                result.citizenship && result.citizenship.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Citizenship: ${fromArrayOrString(result.citizenship)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.country_residence && result.country_residence.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Country of residence: ${fromArrayOrString(result.country_residence)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.alt_names && result.alt_names.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Alt names: ${fromArrayOrString(result.alt_names)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.date_of_birth && result.date_of_birth.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Date of birth: ${fromArrayOrString(result.date_of_birth)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.place_of_birth && result.place_of_birth.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Place of birth: ${fromArrayOrString(result.place_of_birth)}`}</Typography>
                    </Grid> : <></>
            }
            {
                result.address && result.address.length > 0 ?
                    <Grid item>
                        <Typography variant={'subtitle1'}>{`Address: ${fromArrayOrString(result.address)}`}</Typography>
                    </Grid> : <></>
            }
            <Grid item>
                <Divider/>
            </Grid>
        </Grid>
    );
}

export const SanctionsIOReportComponent = (props) => {
    console.log(props);

    if (!props.value) {
        return <></>
    }
    console.debug('SanctionsIOReportComponent value:', props.value);

    const query: SanctionIOProps['query'] = props.value.query;
    const results: (SanctionIOProps['results']) = props.value.results;
    if (results){
        return <Grid container direction={'column'} wrap={'nowrap'} spacing={1}>
            {query && <Grid item>
                {
                    query.type === 'user'
                        ? <Typography variant={'h6'}>{`Query: ${query.fullName} (Individual)`} - {results.length} Results</Typography>
                        : <Typography variant={'h6'}>{`Query: ${query.orgName} (${query.type})`}- {results.length} Results</Typography>
                }
            </Grid>}
            <Grid item>
                <Divider/>
            </Grid>
            <Grid item><ul>
                {
                    results.map((result: SanctionsIOPropsResult, idx: number) => {
                        return (
                            <li><div className={"sanctions-io-hit"} key={idx}>
                                <SanctionsIOReportItemComponent result={result}/>
                            </div></li>
                        );
                    })
                }
            </ul></Grid>
        </Grid>
    }else{
        if (props.value.error){
            let msg;
            try{
                const asJson = JSON.parse(props.value.error);
                msg = asJson.detail;
            }catch (e){
                msg = props.value.error;
            }
            return <Typography >Got error from provider: {msg}</Typography>
        }
        return <></>
    }
}

export function ComplyAdvantageReportComponent(props) {
    if (!props.value){
        return <></>
    }
    const {content} = props.value;
    const {data} = content;

    const {match_status,risk_level,search_term,total_hits,total_matches,hits,total_blacklist_hits,blacklist_hits} = data;
    const classes = useStyles();
    return <Box>
        <Typography variant="body2">Found {total_hits} hits in {total_matches} matches for {search_term}</Typography>
        <Typography variant="body2">Match status: {match_status}</Typography>
        <Typography variant="body2">Risk level: {risk_level}</Typography>
        <Divider></Divider>
        <ul>{
            hits.map(hit=>{
                return <li><ComplyAdvantageHitSnippet hit={hit}/><Divider/></li>

            })
        }</ul>
    </Box>

}

export function RDCReportComponent(props) {
    const pepResults: PEPResult[] = (Array.isArray(props.value)?props.value : [props.value]) as PEPResult[];
    const classes = useStyles();

    return (
        <Grid>

            {pepResults.map(pepResult => {
                const query = pepApiUtil.getPersonQuery(pepResult);
                let nn =  '';
                if (query){
                    if (query.fullName){
                        nn = ` for ${query.fullName}`
                    }else if (query.familyName){
                        nn = ` for ${query.givenName || ''} ${query.familyName || ''}`
                    }
                }
                let className,message;
                const error = pepResult['error'];
                if (error){
                    className = classes.red;
                    message = `Could not fetch PEP Results${nn}:${error}`;
                }else if (pepApiUtil.hasAlerts(pepResult)){
                    className = classes.yellow;
                    message = `Potential matches found${nn}:`
                }else{
                    className = classes.green;
                    message=<>PEP Screening report{nn} is <b>CLEAR</b></>
                }
                return <Grid className={className}>
                        <Typography variant="body2">
                            {message}
                            {pepApiUtil.getAlertId(pepResult) ?
                                <Link href={`${contextPath}api/rdc/${pepApiUtil.getAlertId(pepResult)}/report`} target={"_blank"}>Download Report</Link>:
                                <></>
                            }
                        </Typography>
                        {
                            pepApiUtil.getAlertEntities(pepResult)?.map(entity => <AlertEntityCmp entity={entity}/>)

                        }
                    </Grid>
            })
            }
        </Grid>
    );
}

