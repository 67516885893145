// Require your custom property entries.

import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";
import {is,getBusinessObject} from "bpmn-js/lib/util/ModelUtil";

const LOW_PRIORITY = 500;

/**
 * Injects the 'Launch Form Builder' link in form tab
 * @param propertiesPanel
 * @param translate
 * @param eventBus
 * @constructor
 */
export default function LaunchDmnBuilderPropertiesProvider(propertiesPanel, translate, eventBus,bpmnFactory,moddle) {

    // Use a lower priority to ensure it is loaded after the basic BPMN properties.
    propertiesPanel.registerProvider(LOW_PRIORITY, this);

    this.getTabs = function (element) {

        return function (entries) {
            console.log('element: ',element);

            if (is(element, 'bpmn:BusinessRuleTask')) {
                console.log(element, 'entries: ',entries);
                //find "general" group
                const forms = entries.find(e => e.id === 'general');

                forms.groups.push({
                    id: 'DMN-editor',
                    label: ' DMN Editor',
                    entries: [entryFactory.link(translate, {
                        id: 'launchDMNBuilder',
                        buttonLabel: 'Launch DMN Editor',
                        showLink: function(){
                            console.log('showLink',arguments);
                            return true;
                        },
                        handleClick: function () {
                            eventBus.fire('DMN_editor.open', {data: element})
                        }
                    })]
                })
            }

            return entries;
        }
    };
}

LaunchDmnBuilderPropertiesProvider.$inject = ['propertiesPanel', 'translate', 'eventBus','bpmnFactory','moddle']
