import axios from 'axios';
import {contextPath} from "../contextPath";

const ProcessDefManagementServiceFactory = () => {
    const client = axios.create({
        baseURL: `${contextPath}api/processmgmt`,
    });

    return {
        fetchWorkflows: async () => {
            const result = await client.get('/');

            return result.data;
        },
        upload: async (files,fetchResult) => {
            const formData = new FormData();
            files.forEach((file,idx)=>{
              formData.append(`files`,file)
            })

            const url = fetchResult ? '/?fetchResults=true' : '/';
            const result = await client.post(url, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            return result.data;
        },
        update: async ({key, xmlContent,fetchResults}) => {
            const url = fetchResults ? `/${key}?fetchResults=true` : `/${key}`;
            const result = await client.put(url, xmlContent,{
                headers: {
                    'Content-Type': 'text/xml'
                }
            });
            return result.data;
        },
        updateDecision: async ({key, xmlContent,fetchResults}) => {
            const url = fetchResults ? `/${key}/decisionDefinition?fetchResults=true` : `/${key}/decisionDefinition`;
            const result = await client.put(url, xmlContent,{
                headers: {
                    'Content-Type': 'text/xml'
                }
            });
            return result.data;
        },
        getWorkflow: async ({key})=>{
            const result = await client.get(`/${key}`);
            return result.data;
        },
        getDecision: async ({key})=>{
            const result = await client.get(`/${key}/decisionDefinition`);
            return result.data;
        },

        downloadWorkflow: async ({key})=>{

            const result = await client.get(`/${key}`,{
                params: {download: true}
            });


            let fileName =  result.headers['content-disposition'].split('filename=')[1];
            if (fileName.indexOf("\"") == 0){
                fileName = fileName.substring(1,fileName.length-1)
            }
            let blob = new Blob([result.data]);

            if (window.navigator.msSaveOrOpenBlob){
                window.navigator.msSaveOrOpenBlob(blob,`${fileName}`);
            }else {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = `${fileName}`
                return new Promise(resolve => {
                    a.click()

                    setTimeout(()=>{
                        URL.revokeObjectURL(a.href);
                        resolve();
                    });
                })

            }

        },

        deleteWorkflow: async ({key, cascade}) => {
            const result = await client.delete(`/${key}`, {
                params: {
                    cascade,
                }
            });
            return result.data;
        },

        heartbeat: async ({key,changes}) =>{
            const result = await client.post(`/${key}/heartbeat`, changes);
            return result.data;
        }
    };
};

export default ProcessDefManagementServiceFactory;
