import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import {AlertContext} from "../context/alert/alertContext";
import { useNavigate, useLocation } from 'react-router-dom';

import {contextBaseUri, contextPath} from '../contextPath'
import {signInFormStyles, usersUrl} from "../variables";

import moment from 'moment';
import {ServerConfigContext} from "../context/serverConfig/serverConfigContext";
import {Tooltip} from "@material-ui/core";

export function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: signInFormStyles(theme),
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgotPassword: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));
export const handleSuccessLoginResponseFactory = (setUserLoggedIn, showAlert) => {
    return (response) => {
        console.log('SignIn: login response: ', response);
        const {data} = response;
        data.imageURL = data.imageURL || data.hasImageURL && `${usersUrl}/${data.id}/image`;

        // SRD-271 redirect to latest known location or url before redirection to login
        const storedLocation = sessionStorage.getItem('lats_location');
        if (storedLocation){
            setUserLoggedIn({user: data});
            return window.location.href = `${contextBaseUri}${storedLocation}`;
        }

        setUserLoggedIn({user: data});
        showAlert('Success logged in!', 'success');
    };
};

export const handleErrorLoginFactory = (showAlert, history, userId) => {
    return e => {
        if (e.response && e.response.status === 401) {
            if (e.response.data.type === 'UserLocked'){
                const {until,permanent} = e.response.data;
                if (permanent){
                    showAlert('This user id has been locked, try "forget password" to unlock it', 'error');
                }else{
                    const msg = moment(until).fromNow();
                    showAlert(`This user id has been locked, try again ${msg}`, 'error');
                }
            }
            else if (typeof e.response.data === 'object' && e.response.data.type === 'password_policy_error') {
                const info = Object.assign({}, e.response.data);
                delete info.type;
                history('/forcePasswordPolicyReset', { state: { userNameOrEmail: userId, info: info }});
            }
            else if(typeof e.response.data === 'object' && e.response.data.type === 'TFA') {
                if (e.response.data.code === 'tfa_not_configured') {
                    console.debug('TFA not configured, redirecting to setup TFA');
                    history('/setupTFA', { state: e.response.data });
                }
                else if(e.response.data.code === 'tfa_invalid') {
                    console.warn('invalid tf authentication code');
                    showAlert('Invalid Two Factor Authentication Code', 'error');
                }
                else if(e.response.data.code === 'require_tfa_verification') {
                    console.debug('TFA verification required, navigate to TFA verification');
                    history('/verifyTFA', { state: {userId : userId } });
                }
                else {
                    console.warn(`unknown tfa error code: ${e.response.data.code}`)
                    showAlert(`Two Factor Authentication error: ${e.response.data.code}`, 'error');
                }
            }
            else {
                showAlert('Invalid user id or password', 'error');
            }
        } else {
            console.error(e);
            showAlert(`Unexpected error: ${e.message}`, 'error');
        }
    }
}

export default function SignIn() {
    const classes = useStyles();
    const {setUserLoggedIn} = useContext(LoggedUserContext);
    const {showAlert} = useContext(AlertContext);
    const {ServerConfigState} = useContext(ServerConfigContext);
    const loginUrl = `${contextPath}api/users/login`;
    const userIdRef = useRef('');
    const passwordRef = useRef('');
    const tfaCodeRef = useRef('');
    const history = useNavigate();
    const { search } = useLocation();
    const theme = useTheme();

    const userClickSignIn = async (event) => {
        event.preventDefault();

        const userId = userIdRef.current.trim();
        const password = passwordRef.current.trim();
        const tfaCode = tfaCodeRef.current.trim();

        if (userId.trim()) {
            // TODO validation

            console.log(`request ${loginUrl}`);

            axios({
                method: 'post',
                url: loginUrl,
                data: {
                    email: userId,
                    password,
                    tfaCode
                }
            })
                .then(
                    handleSuccessLoginResponseFactory(setUserLoggedIn, showAlert),
                    handleErrorLoginFactory(showAlert, history, userId),
                )
        }
    };

    const [ oauthRegIds,setOauthRegIds ] = React.useState(null);
    const [samlRegIds,setSamlRegIds]  = useState(null);
    const [ disabledDefaultLogin, setDisabledDefaultLogin ] = useState();

    const fetchAndSetOAuthEnabled = async () => {
        const res = await axios.get(`${contextPath}api/sso/info`);
        setOauthRegIds(res.data.oauthProviders);
        setSamlRegIds(res.data.samlProviders);
        setDisabledDefaultLogin(res.data.disableDefaultLoginMethod);
    }

    useEffect(() => {
        fetchAndSetOAuthEnabled();
    }, []);

    const azureLogin = (oauthRegId) => ()=>{
        window.location.href = `${contextPath}oauth2/authorization/${oauthRegId}`;
    };

    const samlLogin = (samlRegId) => ()=>{
        window.location.href = `${contextPath}saml2/authenticate/${samlRegId}`;
    };

    const handleForgotPassword = () => {
        history('/forgotPassword', {state:{email: userIdRef.current.trim()}});
    };

    const handleActivateAccount = () => {
        history('/activateAccount', {state:{email: userIdRef.current.trim()}});
    };

    return (
        <Container component="div" maxWidth="xs" className={"logo_container logo_container-signin"}>
            <div className={classes.paper}>
                <img className={classes.logo} id={"signing-logo-img"}
                     style={{backgroundColor:theme?.customProps?.logoContainerColor}}
                     src={theme?.customProps?.logoUrl}
                     height={60}
                ></img>
                <Box p={2}>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                </Box>
                <form className={classes.form} noValidate>
                    {
                        disabledDefaultLogin === false
                            ? [
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="User ID"
                                    name="userId"
                                    autoComplete="username"
                                    autoFocus
                                    onChange={e => userIdRef.current = e.target.value}
                                />,
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={e => passwordRef.current = e.target.value}
                                />

                            ]
                            : null
                    }
                    {disabledDefaultLogin === false && ServerConfigState.emailIndependent === false &&
                        <Grid container direction="row" justifyContent="space-between">
                            <Typography className={classes.forgotPassword}
                                        color={'primary'}
                                        variant={'subtitle2'}
                                        onClick={handleForgotPassword}
                            >
                                Forgot password
                            </Typography>

                            <Typography className={classes.forgotPassword}
                                        color={'primary'}
                                        variant={'subtitle2'}
                                        onClick={handleActivateAccount}
                            >
                                Activate account
                            </Typography>
                        </Grid>
                    }
                    {/*<FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />*/}
                    {
                        disabledDefaultLogin === false
                            ? <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={userClickSignIn}
                            >
                                Sign In
                            </Button>
                            : null
                    }
                    {
                        !!oauthRegIds && oauthRegIds.map(oauthRegId=><Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={azureLogin(oauthRegId)}
                        >
                            Sign In with {oauthRegId}
                        </Button>)
                    }
                    {
                        !!samlRegIds && samlRegIds.map(samlRegId=><Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={samlLogin(samlRegId)}
                        >
                            Enterprise SSO ({samlRegId})
                        </Button>)
                    }
                    {
                        !!search && search.indexOf('?error=email_not_found') >= 0 &&
                            <Typography color={'error'} variant={'subtitle1'}>email not found</Typography>
                    }

                    {/*<Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>*/}
                </form>
            </div>
        </Container>
    );
}
