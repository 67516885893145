import {File} from './generic_component'

import 'core-js/es/string/replace-all'
import React from 'react';
import {makeStyles} from "@material-ui/core";



declare global {
    interface Navigator{
        msSaveOrOpenBlob(blob:any,fileName:string):void
    }

}

const ProgressWithErrors = ({progress,errors})=>{
    const styles = makeStyles(()=>({
        progress_error:{
            //Remove the bullet from ul
            listStyleType:"none",
            color:"red"
        }
    }))();

    if (errors.length > 0) {
        return <>
            <ul className={styles.progress_error}>
                {errors.map(e => <li>{e}</li>)}
            </ul>
            <p>{progress}</p>
        </>
    }else{
        return <p>{progress}</p>
    }

}


export async function handleExport(name:string  | ((formReference:any)=>string),formReference,setInProgress):Promise<void>{
    const _name:string = typeof name === 'string' ? name as string: name(formReference);

    setInProgress("Generating tsv")
    const sections = formReference.map(f=>f.properties?.visual_section).reduce((acc,f)=>{
        if (f != null && acc.indexOf(f) === -1){
            acc.push(f)
        }
        return acc;
    },[]);


    const files:{[fileName:string]:string | (()=>Promise<(string | (()=>Promise<string | File>))>)} = {};
    let tsv = formReference.sort((a,b)=>
    {
        const id_a = sections.indexOf(a.properties?.visual_section);
        const id_b = sections.indexOf(b.properties?.visual_section);

        return id_a - id_b;
    }).map(formField=>
    {
        const visualSection = formField.properties?.visual_section
        const {id,value,label} = formField;
        const actualValue = value.value;
        if (Array.isArray(actualValue)) {
            if (value.type === "file" || value.type?.name === "file") {
                return actualValue.map(({name, data}) => {
                    //TODO: Handle conflicts
                    files[name] = data;
                    if (id.endsWith("Obj")) {
                        //Filter Out as her is a ID field
                        return null;
                    }else{
                        return [visualSection,id,label,name].join('\t');
                    }
                })

            }
            return actualValue.map((av, idx) => {
                const template = formReference.find(f => f.id == id);
                let {label} = template;
                if (typeof av === 'object') {
                    return Object.keys(av).sort((a, b) => {
                        let id_a = formReference.findIndex(f => f.id === a);
                        if (id_a == -1) {
                            id_a = 65535
                        }
                        let id_b = formReference.findIndex(f => f.id === b);

                        if (id_b == -1) {
                            id_b = 65535
                        }
                        return id_a - id_b;
                    }).map(av_id => {
                        if (av_id === 'uuid') {
                            return null;
                        }
                        const inner_template = formReference.find(f => f.id == av_id);
                        const act_val = av[av_id]

                        if (inner_template?.type?.name === 'file') {
                            return act_val && (Array.isArray(act_val) && act_val || [act_val]).forEach(({name, data}) => {
                                //TODO: Handle conflicts
                                files[name] = data;

                                if (av_id.endsWith("Obj")) {
                                    return null; //Filter this out
                                }else{
                                    label = inner_template && inner_template.label
                                    return [visualSection, av_id + ':' + idx, label, name].join('\t')
                                }
                            })
                        }


                        if (inner_template && inner_template.label) {
                            label = inner_template && inner_template.label
                            const _act_val = act_val?.replaceAll && act_val?.replaceAll("\n","\\n").replaceAll("\t","\\t").replaceAll("\"","\\\"") || act_val;
                            return [visualSection, av_id + ':' + idx, label, _act_val].join('\t')
                        } else if (act_val) {
                            console.log("value without template:", av_id, idx, act_val)
                            // return [visualSection, av_id+':'+idx, av_id, act_val].join('\t')
                        }
                        return null

                    }).filter(l => l != null)
                }
                // else{
                //     return [visualSection, id, label, av].join('\t')
                // }
            }).flat();
        }else if (typeof actualValue === 'object'){
            //FIXME: top level group or custom field
            if (formField.properties?.group){

            }else{

            }
            return null;
        }else if (actualValue == null){
            //Filter out templates (group_repeatable)
            if (formField.properties?.group_repeatable) {
                return null;
            }
        }

        const _actValue = actualValue?.replaceAll && actualValue?.replaceAll("\n","\\n").replaceAll("\t","\\t").replaceAll("\"","\\\"") || actualValue;
        return [visualSection,id,label,_actValue].join('\t');
    }).filter(l=>{
        return l!=null
    }).flat().filter(l=>{
        return l!=null
    }).join("\n")

    //Add headers
    tsv = "Section\tID\tLabel\tValue\n"+tsv;

    // const blob = new Blob([tsv],{type:'text/tab-separated-values'})
    const JSZIP = require("jszip");
    const zip  = new JSZIP();
    setInProgress("Adding tsv in zip")
    zip.file(`${_name}.tsv`,tsv)

    const fileNames = Object.keys(files);


    const tot = fileNames.length
    let cnt = 0;
    const errors:string[] = [];


    const createStatusMsg = (msg) => {
        if (errors.length > 0) {
            return <ProgressWithErrors progress={msg} errors={errors}/>
        }else{
            return msg;
        }
    }
    for (const fileName of fileNames) {
        cnt ++;
        let _fileName = fileName
        let data:any = files[fileName];

        setInProgress(createStatusMsg(`Fetching ${_fileName} (${cnt}/${tot})`))

        try {
            while (typeof data === 'function') {
                const ret = await data();
                data = ret;
            }
            if (typeof data === 'object' && data.data) {
                _fileName = data.name;

                data = await fetch(data.data).then(rsp => {
                    setInProgress(createStatusMsg(`Fetched ${_fileName} (${cnt}/${tot})`))
                    return rsp.arrayBuffer();
                });
            }

            setInProgress(createStatusMsg(`Adding file ${_fileName} (${cnt}/${tot}) into zip`))
            zip.file(_fileName, data);
            setInProgress(createStatusMsg(`Added file ${_fileName} (${cnt}/${tot}) into zip`))
        }catch (e){
            const errMsg = `Failed to fetch file ${_fileName}: ${JSON.stringify(e)}`
            errors.push(errMsg)
            zip.file(_fileName+".failed", errMsg)
            setInProgress(createStatusMsg(`Failed to add file ${_fileName} (${cnt}/${tot}) into zip`))
        }
    }
    console.log("Generating")
    return zip.generateAsync({type:"blob"}).then(blob=>{

        if (window.navigator.msSaveOrOpenBlob){
            window.navigator.msSaveOrOpenBlob(blob,`${_name}.zip`);
        }else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = `${_name}.zip`
            return new Promise<void>(resolve => {
                a.click()

                setTimeout(()=>{
                    URL.revokeObjectURL(a.href);
                    resolve();
                });
            })

        }
    })

}