import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from '@material-ui/icons/Cached';
import axios from "axios";
import * as _ from 'lodash';
import {notificationsUrl, usersUrl} from "../variables";
import {AlertContext} from "../context/alert/alertContext";

moment().format();

const useStyles = makeStyles((theme) => ({
    notificationsListContainer: {
        overflowY: 'auto',
        maxHeight: '20rem',
        paddingRight: 1,
    },
    notificationItemNotRead: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    notificationItemAcknowledged: {
        backgroundColor: "rgba(255, 0, 0, 0.05)",
    },
    notificationContainsLink: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.light,
        }
    },
}));

export function Notifications() {
    const classes = useStyles();
    const {showAlert} = useContext(AlertContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [shownNotifications, setShownNotifications] = React.useState(null);
    const [showLoadMore, setShowLoadMore] = React.useState(true);
    const [countOfNotRead, setCountOfNotRead] = React.useState(0);
    const history = useNavigate();


    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveToLink = (notification) => {
        if (notification.params) {
            if (notification.notificationType === 'task') {
                history(`/tasks/${notification.params}`);
            }
        }
    };

    const open = Boolean(anchorEl);

    function fetchNotifications(options) {
        return axios({
            method: 'get',
            url: notificationsUrl,
            params: {
                lastSeenInsertionDate: options && options.date || undefined,
            }
        })
            .then((response) => {
                    const notifications = _.map(response.data, (responseItem) => {
                        return {
                            "id": responseItem.notification.id,
                            "name": responseItem.notification.name,
                            "description": responseItem.notification.description,
                            "insertionDate": responseItem.notification.insertionDate,
                            "params":  responseItem.notification.params,
                            "notificationType":  responseItem.notification.notificationType,
                            "status": responseItem.status,
                        }
                    });

                    return notifications
                },
                error => showAlert(error, 'error'));
    }

    function fetchUnreadNotificationsCount() {
        return axios({
            method: 'get',
            url: `${notificationsUrl}/unread`,
            showLoading: false,

        })
            .then((response) => {
                    const notificationsCount = response.data;
                    if (countOfNotRead !== notificationsCount) {
                        setCountOfNotRead(notificationsCount);
                    }
                },
                error => showAlert(error, 'error'));
    }

    function patchNotificationStatus(options) {
        const {notification, status} = options;

        return axios({
            method: 'PUT',
            url: `${notificationsUrl}/${notification.id}`,
            params: {
                status: status,
            }
        })
    }

    function initialFetchNotification () {
        fetchNotifications().then(notificationsData => {
            setShownNotifications([].concat(notificationsData));
            setShowLoadMore(true);
        }).then(fetchUnreadNotificationsCount)
    }

    const markSeenNotificationAsRead = async () => {
        const promises = [];
        _.each(shownNotifications, (notification) => {
            if (notification.status === "unread") {
                promises.push(patchNotificationStatus({notification, status: "read"}));
            }
        });

        await Promise.all(promises)
            .then(() => {
                    setShownNotifications(shownNotifications.map(notification => {
                        if (notification.status === "unread") {
                            notification.status = 'read';
                        }
                        return notification;
                    }));
                fetchUnreadNotificationsCount();
                },
                error => {
                    console.error('Notification Group change status error', error);
                    // need re-fetch as somewhere error happens
                    initialFetchNotification();
                });
    };

    const deleteNotification = (notification) => {
        patchNotificationStatus({notification, status: "acknowledged"})
            .then(() => {
                    setShownNotifications(shownNotifications.filter(n => {
                        return n.id !== notification.id;
                    }));
                },
                error => showAlert(error, 'error'))
            .finally(fetchUnreadNotificationsCount)

    };

    const loadMore = () => {
        fetchNotifications({date: shownNotifications[shownNotifications.length - 1] ? shownNotifications[shownNotifications.length - 1].insertionDate : undefined})
            .then(notificationsData => {
                if (!notificationsData.length){
                    setShowLoadMore(false);
                }
            setShownNotifications(shownNotifications.concat(notificationsData));
        })
            .then(fetchUnreadNotificationsCount)
    };

    useEffect(() => {
        initialFetchNotification();

        // fetch unread notifications
        const intervalId = setInterval(() => {
            fetchUnreadNotificationsCount();
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    function renderNotifications() {
        if (shownNotifications === null){
            return (
                <Grid>
                    ...
                </Grid>
            )
        }

        if (!shownNotifications.length){
            return (
                <Grid>
                    No Notifications...
                </Grid>
            )
        }


        return shownNotifications.map((notification) => {
            return (<div key={notification.id}>
                    <Divider/>
                    <Grid container direction="row"
                          justifyContent="space-between"
                          className={notification.status === "unread" && classes.notificationItemNotRead || notification.status === "acknowledged" && classes.notificationItemAcknowledged || ""}
                          wrap="nowrap"
                    >

                        <Grid container item direction="column"
                              className={notification.params && notification.notificationType && classes.notificationContainsLink || ""}
                              onClick={() => moveToLink(notification)}
                        >

                            <Typography variant="body2">{notification.name}
                            </Typography>
                            <Typography variant="caption"
                                        color="textSecondary">{moment(notification.insertionDate).format('MMMM DD YYYY')}
                            </Typography>

                        </Grid>

                        <IconButton onClick={() => deleteNotification(notification)}>
                            <DeleteIcon color="primary"/>
                        </IconButton>

                    </Grid>
                </div>
            )
        })
    }


    return (
        <div>
            <Box onClick={handleClick}>
                {
                    countOfNotRead ? (
                            <Badge color="secondary" variant="dot">
                                <NotificationsIcon color="disabled"/>
                            </Badge>) :
                        <NotificationsIcon color="disabled"/>
                }
            </Box>

            <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            // transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper elevation={3}>
                            <Box p={2}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Grid>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <Box p="2">
                                                <Typography color="inherit"
                                                            variant="h6">Notifications{countOfNotRead ? ` (${countOfNotRead})` : ''}</Typography>
                                            </Box>
                                            <IconButton onClick={() => initialFetchNotification()}>
                                                <CachedIcon color="primary"/>
                                            </IconButton>
                                        </Grid>

                                        <Grid className={classes.notificationsListContainer}>
                                            {renderNotifications()}
                                        </Grid>

                                        <Divider/>

                                        <Grid item container justifyContent="space-between">
                                            <Button color="primary" onClick={markSeenNotificationAsRead}
                                                    disabled={!(shownNotifications && shownNotifications.some(n => n.status === "unread"))}>
                                                Mark all as read
                                            </Button>
                                            <Button color="primary" onClick={loadMore} disabled={!showLoadMore}>Load more</Button>
                                        </Grid>
                                    </Grid>
                                </ClickAwayListener>
                            </Box>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
