export default {
    "name": "Docusign",
    "id": "com.norbloc.sancus.integrations.Docusign",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },
    "icon":{
        "contents":"https://www.docusign.com/themes/custom/cubic/favicon/favicon-32x32.png"
    },
    "properties": [
        {
            "label": "Activity",
            "type": "Hidden",
            "value": "docusign",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },

        {
            label:"Step Name",
            description: "Name of the step",
            type: 'String',
            binding:{
                type:'property',
                name:'sancus.widget.step'
            }

        },

        {
            "label": "User Id (Mapping)",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "userId"
            }
        },
        {
            "label": "Sign Text (Mapping)",
            "type": "Hidden",
            "value": "signText",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "signText"
            }
        },
        {
            "label": "Sign Text",
            "type": "String",

            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "signText"

            }
        },
        {
            "label": "signDocumentName (Mapping)",
            "type": "Hidden",
            "value": "signDocumentName",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "signDocumentName"
            }
        },

        {
            "label": "Document Name",
            "type": "String",
            "description": "Name of the signed document",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "signDocumentName"

            }
        },

        {
            "label": "Email (Mapping)",
            "type": "String",
            "editable": true,
            "value": "email",
            "binding": {
                "type": "camunda:in",
                "target": "email"
            }
        },
        {
            "label": "First Name (Mapping)",
            "type": "String",
            "editable": true,
            "value": "firstName",
            "binding": {
                "type": "camunda:in",
                "target": "firstName"
            }
        },

        {
            "label": "Last Name (Mapping)",
            "type": "String",
            "editable": true,
            "value": "lastName",
            "binding": {
                "type": "camunda:in",
                "target": "lastName"
            }
        },

        {
            "label": "Result Variable (Mapping)",
            "type": "String",
            "editable": true,
            "value": "docuSignField",
            "binding": {
                "type": "camunda:out",
                "source": "docuSignField"
            }
        },
        {
            label: "Check Merge Listener",
            "type":"Hidden",
            value:"#{mergeChecks.execute(execution)}",
            editable: false,
            binding: {
                type:"camunda:executionListener",
                event:"end",
                scriptFormat:"groovy"

            }


        }
    ]
}