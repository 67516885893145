import {ICheck} from "sancus-client-common/dist/common/FormContainerBase";
import {FieldCheckAvatarFactory} from "sancus-client-common/dist/common/FieldCheckAvatar";
import Avatar from "@material-ui/core/Avatar";
import * as React from "react";
import {Tooltip} from "@material-ui/core";


const HTooltip = (props:{title:string,children})=>{
    const text_2 = <div dangerouslySetInnerHTML={{__html:props.title}}/>
    return <Tooltip title={text_2}>{props.children}</Tooltip>
}

const FieldCheckAvatar = (props:{check:ICheck})=>{
    return <FieldCheckAvatarFactory Avatar={Avatar} Tooltip={HTooltip} check={props.check} doScroll = {(checkUUID:string)=>{
        const serviceCheckElem = document.getElementById(checkUUID);
        if (serviceCheckElem) {
            serviceCheckElem.scrollIntoView();
        }
    }} />
}

export default FieldCheckAvatar;