import * as React from 'react'

import {decorate, FormContainerBase} from "./FormContainerBase";
import FormHandlerContext from "./handlers"
import {JsonFormHandlerStrategy} from './json_handlers'
import {FormField} from "./camunda_helper";
import {IntlProvider, IntlConfig, useIntl} from "react-intl";




export type FormBuilderProps = {
    name:string,
    description:string,
    designMode?: boolean,
    formFields: FormField[],
    submitFN:(_vars)=>Promise<void>,
    allProcessVariablesNames?: string[],

    showSections?:boolean
}

export function FormBuilderBase(props:{
    FormContainer

    messages?:IntlConfig['messages']
    locale?:string

} & FormBuilderProps) {
    const FormContainer = props.FormContainer;
    const FCI = decorate(FormContainer)
    // @ts-ignore
    return <IntlProvider messages={props.messages || {}} locale={props.locale || "en"} defaultLocale={"en"}>
            <FormHandlerContext.Provider value={new JsonFormHandlerStrategy(props.name,props.description,props.formFields,props.submitFN)}>
                <FCI
                    noStep={true}
                    showSections={props.showSections}
                    hideStepper={false}
                    processDefinitionKey={"_processDefinitionKey"}
                    formBuilderProps={{
                        name: props.name,
                        description: props.description,
                        formFields: props.formFields,
                        submitFN: props.submitFN,
                        designMode: props.designMode,
                        allProcessVariablesNames: props.allProcessVariablesNames,
                    }}
                />
            </FormHandlerContext.Provider>
        </IntlProvider>
}
