import * as React from "react";
import {Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import {getQN, getWLMatches, Match, SearchResults} from "./utils";


const LexisNexisReportItemComponent = (props:{wlm:Match,idx?:number})=>{
    const classes = makeStyles({
        match:{
            border:'1px solid black',
            padding:'10px',
            margin:'10px'
        },
        matchItem:{
            display:'flex',
            flexDirection:'row'
        },
        matchItemDt:{
            fontWeight:'bold',
            marginRight:'10px'
        },
        matchItemDd:{
            marginLeft:'10px'
        },
        comments:{
            whiteSpace:'pre-line'
        }
    })();
    const {wlm,idx} = props;
    return <Grid className={classes.match}>
        {wlm.Error ? <Grid container direction={"row"} className={classes.matchItem}>
                <Grid item className={classes.matchItemDt} xs={4}>Error:</Grid>
                <Grid item className={classes.matchItemDd + ' ' + classes.comments}>
                    {wlm.Error.Message} ({wlm.Error.Code})
                </Grid>
            </Grid> :
            <>
                <Grid container direction={"row"} className={classes.matchItem}>
                    <Grid xs={4} item className={classes.matchItemDt}>
                        Name:
                    </Grid>
                    <Grid item className={classes.matchItemDd}>
                        {wlm.EntityName}
                    </Grid>
                </Grid>

                {wlm.EntityDetails && <>
                    {wlm.EntityDetails.AdditionalInfo?.map(a => {
                        return <Grid container direction={"row"} className={classes.matchItem}>
                            <Grid xs={4} item className={classes.matchItemDt}>{a.Type}:</Grid>
                            <Grid item className={classes.matchItemDd}>
                                {a.Value}
                            </Grid>
                        </Grid>
                    })}
                    <Grid container direction={"row"} className={classes.matchItem}>
                        <Grid item className={classes.matchItemDt} xs={4}>Comments:</Grid>
                        <Grid item className={classes.matchItemDd + ' ' + classes.comments}>
                            {wlm.EntityDetails.Comments}
                        </Grid>
                    </Grid>
                </>}
            </>
        }

    </Grid>
}

const LexisNexisReportComponent = (props:{value:SearchResults,idx?:number})=>{

    const {value,idx} = props;
    if (!value){
        return <div>Loading</div>;
    }
    const {query} = value;

    const wlMatches = getWLMatches(value)
    const qn = getQN(query)
    if (wlMatches){
        if (idx!=null && wlMatches[idx]){
            return <LexisNexisReportItemComponent wlm={wlMatches[idx]} idx={idx} />
        }
        return <Typography variant={"body2"}><Grid>
            <Grid> Found {wlMatches.length} matches for {qn}</Grid>
            <Divider/>
            <ul>
            {wlMatches.map((wlm,idx)=>{
                return <li><LexisNexisReportItemComponent wlm={wlm} idx={idx} /></li>
            })}
            </ul>
        </Grid></Typography>
    }else{
        return <div> No Matches found for {qn}</div>
    }



}

export default LexisNexisReportComponent;