import React, {useContext, useEffect, useState} from 'react';

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {TaskOverview} from "../components/tasksComponents/TaskOverview";
import Box from "@material-ui/core/Box";
import axios from "axios";
import {instanceUrl} from "../variables";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {contextPath} from "../contextPath";
import {AlertContext} from "../context/alert/alertContext";
import {StartFormData} from 'sancus-client-common/dist/common/camunda_helper'

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        backgroundColor: "#fff",
        boxShadow: "rgb(230 230 230) 0px 1px 1px 0px",
        marginBottom: '1px'
    },
    taskContainer: {
        paddingBottom: 72,
        '&': {
            position: 'relative'
        },
        '& #ActionContainer': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            margin: 0,
            padding: "16px 32px",
            background: '#ffffff',
            boxShadow: '0 -2px 5px -2px rgba(0, 0, 0, 0.12)',
            zIndex: 5,
            '& div': {
                justifyContent: 'flex-end'
            }
        },
        '& [class*="MuiButton-contained"]:active':{
            boxShadow: 'none'
        },
        '& [class*="MuiButton-contained"]:hover':{
            boxShadow: 'none'
        },
        '& [class*="MuiButton-contained"]':{
            boxShadow: 'none'
        },
        '& #form': {
            overflow: 'auto',
            background: '#ffffff'
        },
        '& #mainFormBox': {
            padding: '16px 32px 0px 32px'
        },
        '& #checks': {
            borderLeft: '1px solid ' + theme.palette.divider
        },
        '& #checklist': {
            maxHeight: '100%',
            overflow: 'auto',
            paddingBottom: 72
        },
        '& #checks p': {
            lineHeight: 2
        },
        '& #mainStepHeader h4': {
            fontSize: '1.5rem'
        },
        '& form h5': {
            fontSize: '1.25rem'
        },
        '& form > div': {
            padding: '0px'
        },
        '& form p': {
            lineHeight: 2,
            wordBreak: 'break-word'
        }
    },
}));





export const Journey = ({
                            instanceEnterCbk:_instanceEnterCbk,
                            instanceExitCbk:_instanceExitCbk,
                            taskEnterCbk
}) => {
    const {showAlert} = useContext(AlertContext);
    const history = useNavigate();
    const params = useParams();
    const {state} = useLocation();

    const processInstanceId = params.processInstanceId;
    const journeyName = params.journeyName;
    const taskId = params.taskId;
    const [title,setTitle] = useState(state?.title);

    const classes = useStyles();
    const [enteredTaskId,setEnteredTaskId] = useState(taskId);

    const onbeforeunload = (_processInstanceId,_taskId) => async (evt)=>{
        console.debug(`onbeforeunload called with processInstanceId=${_processInstanceId} and taskId=${_taskId}`,evt)
    }
    const createProcessUrl = `${contextPath}api/process/key`

    useEffect(()=>{
        if (state && state.data){
            axios({
                method: 'post',
                url:`${createProcessUrl}/${journeyName}/start`,
                data:state.data

            }).then(rsp=>{
                const processInstanceId  = rsp.data.processInstance.id
                history(`/run_journeys/${journeyName}/${processInstanceId}`,{replace:true});
            })
            return;
        }
    },[])

    useEffect(()=>{
        //This is the real unmount function that will be called when the component is unmounted
        //For some reason the taskId stored in state is not accessible from the onbeforeunload function
        //So we need to do this other useEffect block
        return async ()=>{
            await window._journeyCleanupFN(null);
        }
    },[])

    useEffect(()=>{
        const fn = onbeforeunload(processInstanceId,enteredTaskId);
        window._journeyCleanupFN = fn;
        window.addEventListener('beforeunload',fn);
        return ()=>{
            window.removeEventListener('beforeunload',fn);
        }
    },[processInstanceId,enteredTaskId])


    function deleteInstancePromise(processInstanceId,taskId,reason) {
        if (! reason){
            reason = "Cleanup";
        }
        const taskIdSnippet = taskId ?`taskId=${taskId}&`:''
        return axios({
            method: 'delete',
            url: `${instanceUrl}/${processInstanceId}?${taskIdSnippet}&reason=${reason}`,
        }).then((response) => {
            console.log('Instance was deleted with API: ', response);
        })
    }

    const instanceExitCbk = (pageKey) => (instanceStatus)=>{
        const {state,processInstanceId,endStates} = instanceStatus;
        if (pageKey !== "query_audit" && pageKey !== "search_customer") {
            if (state?.indexOf("TERMINATED") !== -1){
                showAlert('Process was terminated', 'warning');
            }else{
                if (endStates[0]){
                    const documentation = endStates[0].documentation || "Success!"
                    const alertType=  endStates[0].attributes?.state || 'success'
                    showAlert(documentation, alertType);
                }else {
                    showAlert('Success!', 'success');
                }
            }


        }
        if (_instanceExitCbk!=null){
            _instanceExitCbk(pageKey,instanceStatus);
        }
        history(-1);
    };

    const instanceEnterCbk = (currentPage) => (enterProcessInstanceId) => {
        console.log(`RunJourneys enterProcessInstanceId = ${enterProcessInstanceId}`);
        history(`/run_journeys/${currentPage}/${enterProcessInstanceId}`,{
            replace:true,
            state:{title}
        });
    };

    if (state?.data){
        return "Loading...";
    }

    const doExit = async () => {
        const reason = "Exit Journey"
        console.log("Cleanup process instance: %s from task %s %s",processInstanceId,enteredTaskId,reason)
        if (processInstanceId!=null && enteredTaskId!=null) {
            await deleteInstancePromise(processInstanceId,enteredTaskId,reason);
        }
        history(-1)
    }

    return (
        <Grid id="service" container direction="row" justifyContent="flex-start" alignItems="stretch" item xs={12} style={{flex: "1 1 auto"}}>
            <Grid container direction="column" item xs={12}>
                <Grid item className={classes.pageHeader} xs={12} style={{flex: "0 1 auto"}}>
                    <Box pl={4} pr={4} pt={1} pb={1}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">
                                {title}
                            </Typography>

                            <IconButton aria-label="close" onClick={() => doExit()}>
                                <CloseIcon/>
                            </IconButton>

                        </Grid>
                    </Box>
                </Grid>

                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" item xs={12}
                      style={{flex: "1 1 auto"}}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.taskContainer} item xs={12}>
                        <TaskOverview
                            key={enteredTaskId}
                            processDefinitionKey={journeyName}
                            processInstanceId={processInstanceId}
                            taskId={enteredTaskId}
                            instanceExitCbk={instanceExitCbk(journeyName)}
                            instanceEnterCbk={instanceEnterCbk(journeyName)}
                            beforeInstanceEnterCbk={arg=>{
                                if (Array.isArray(arg)) {
                                    //TODO: Could be a resumed (from session) instance
                                }else {
                                    const startFormData:StartFormData = arg;
                                    const {processDefinition} = startFormData;
                                    const {key,name,description,extensions} = processDefinition;
                                    const _title = name;
                                    if (_title && _title !== title) {
                                        setTitle(_title)
                                    }
                                }
                            }}

                            taskEnterCbk={_taskId=>{
                                console.log(`Entering task ${_taskId}`)
                                setEnteredTaskId(_taskId);
                                taskEnterCbk && taskEnterCbk(_taskId)

                            }}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
};
