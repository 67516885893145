import {GenericFieldParams} from "./generic_component_base";
import {FieldGroupTemplate, FormValues} from "./FormContainerBase";


export default class ValueFactories{

    static RepeatableBase(template:GenericFieldParams){
        if (Array.isArray(template?.value?.value)){
            return template?.value?.value
        }else {
            return [template?.value?.value]
        }
    }

    static GenericFieldComponentBase(fv_value:GenericFieldParams){
        let repeatable = fv_value.properties?.repeatable;
        if (repeatable){
            return ValueFactories.RepeatableBase(fv_value)
        }else{
            return fv_value.value?.value;
        }
    }

    static RepeatableGroupComponentBase(template: FieldGroupTemplate, provider): FormValues[] {
        if (provider && Array.isArray(provider)){
            return provider;
        }else {
            const singleValue: FormValues = ValueFactories.GroupComponentBase(template, provider);
            if (Array.isArray(singleValue)) {
                return singleValue;
            } else {
                return [singleValue];
            }
        }
    }

    static GroupComponentBase(template: FieldGroupTemplate, provider): FormValues {
        return Object.keys(template).reduce((acc: FormValues, key: string) => {
            const fv_value: GenericFieldParams = template[key];

            let val;
            if (fv_value._value != null){
                //We have value from parent component
                val = fv_value._value;
            }else if (provider[fv_value.id]){
                //We have value from the camunda variable for group
                val = provider[fv_value.id];
            }else if (fv_value.value !=null){
                //We have value from camunda variables for template
                val = fv_value.value.value
            }


            acc[fv_value.id] = val;
            return acc;
        },{})

    }
}

