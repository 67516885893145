import Button from "@material-ui/core/Button";
import {Grid, Tooltip} from "@material-ui/core";
import * as React from "react";

import {ActionsProperties, ActionsBase} from 'sancus-client-common/dist/common/ActionsBase'
import {IActionJson} from "sancus-client-common/dist/common/FormContainerBase"
import {CSSProperties} from "react";

export default function Actions(props: ActionsProperties) {
    return <ActionsBase key={"actions"} {...props}
                        doRender={(actionsConfig: IActionJson[], HandleButtonClick: (evt, action) => any) => {
                            return <Grid container spacing={1} id={"ActionList"}>
                                {
                                    actionsConfig.map(action => {
                                        const calc_visible = action.visible == null || props.contextEvaluator.eval(action.visible);
                                        const calc_disabled = action.disabled && props.contextEvaluator.eval(action.disabled);
                                        const disabled_tooltip = calc_disabled && action.disabled_tooltip;
                                        const extraStyles: CSSProperties = calc_visible ? {} : {display: 'none'}

                                        const button = <Grid item key={action.id || action.value} style={extraStyles}>
                                            <Button
                                                name={action.id || action.value} key={"button"}
                                                onClick={evt => HandleButtonClick(evt, action)}
                                                variant="contained"
                                                disabled={!!calc_disabled}

                                            >
                                                {action.label}
                                            </Button>
                                        </Grid>
                                        if (disabled_tooltip) {
                                            return <Tooltip title={disabled_tooltip}>{button}</Tooltip>;
                                        } else {
                                            return button;
                                        }
                                    })
                                }
                            </Grid>
                        }}/>
}
