import * as React from "react";
import {ReactNode} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles} from "@material-ui/core/styles";

import {buttonLabelSubstitutions} from "sancus-client-common/dist/common/action_buttons_base";
import { IActions} from "sancus-client-common/dist";

const myStyles = makeStyles((theme) => ({
    overwriteButtonStyles: {
        '& button': {
            boxShadow: 'none',
            "&:hover": {
                boxShadow: 'none'
            }
        },
        '& button:first-child': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    focused: {}
}));

export default function DropdownButtonsGroup(props: { children?: ReactNode[], actions?: IActions }) {
    //const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];
    const options = props.actions || [];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="row" justifyContent="flex-end">
                <ButtonGroup size="small" disableElevation variant="contained" color="primary" ref={anchorRef} aria-label="split button"
                             className={myStyles().overwriteButtonStyles}>
                    {props.children ? props.children[selectedIndex] : <></>}
                    <Button size="small" color="primary"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}>
                        <ArrowDropDownIcon/>
                    </Button>
                </ButtonGroup>

                {/*  to fix correct Popper's position where deleted 'disablePortal' */}
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 5}}
                        placement="top-end"
                        disablePortal={false}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: false,
                                boundariesElement: 'scrollParent',
                            },
                            arrow: {
                                enabled: false,
                                element: anchorRef.current,
                            },
                        }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => {
                                            let label: string;

                                            if (typeof option  === 'string'){
                                                label = option;
                                            }  else {
                                                label = option.label || option.value;
                                            }

                                            return <MenuItem
                                                key={label}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {(buttonLabelSubstitutions && buttonLabelSubstitutions[label]) || label}
                                            </MenuItem>
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                </Popper>
        </Grid>
    );
}
