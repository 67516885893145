import React, { useEffect, useState } from 'react';
import contextPath from "sancus-client-common/dist/common/contextPath"
import { Grid, Button } from '@material-ui/core';
import { useLayout } from 'sancus-client-common/dist/common/LayoutContext';

/**
 * Singpass api.
 */
export interface SingpassApiService {
    fetchLoginUrl(): Promise<string | never>;
}

export const SingpassApiServiceFactory = (config: { contextPath: string }): SingpassApiService => {
    return {
        fetchLoginUrl: async (): Promise<string | never> => {
            return window.fetch(`${config.contextPath}api/singpass/login-url`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response.statusText;
                    }
                })
                .then(response => {
                    return response.text();
                }).catch(error => {
                    console.error('could not fetch singpass login url', error);
                    return error;
                }) as Promise<string>;
        },
    }
};

const singpassService = SingpassApiServiceFactory({ contextPath: contextPath as any });

interface SingpassLoginProps {
    id: string;
    value: {
        code: string;
        state: string;
    } | null;
    validation: {
        readOnly?: boolean,
    };
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;
}

/**
 * The editable singpass login component.
 * @constructor
 */
const EditableSingpassLogin = (props: SingpassLoginProps) => {
    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly ? true : false;

    const [ disableLogin, setDisableLogin ] = useState<boolean>(isReadOnly || false);

    /**
     * extract spl storage event data and submit field value.
     * @param event
     */
    const handleSPLStorageEvent = (event) => {
        if(event && event.key === 'spl') {
            console.debug('storage event', event);
            try {
                const value = JSON.parse(event.newValue);
                if(value && value.code && value.state) {
                    props.valueChangeCbk(props.id, event.newValue, true);
                }
                else {
                    console.error(`invalid event value ${event.newValue}`);
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    /**
     * Subscribe fn to storage events
     */
    const subscribeStorageEvent = (fn: (event) => any) => {
        if(window && window.addEventListener) {
            console.log('subscribe to storage event');
            window.addEventListener('storage', fn);
        }
        else {
            console.warn('could not subscribe to storage event')
        }
    };

    /**
     * unsubscribe fn from storage event
     */
    const unsubscribeStorageEvent = (fn: (event) => any) => {
        if(window && window.removeEventListener) {
            console.log('unsubscribed from storage event');
            window.removeEventListener('storage', fn);
        }
        else {
            console.warn('could not unsubscribe from storage event');
        }
    };

    /**
     * fetch login url and open in new tab
     */
    const openLoginTab = async () => {
        try {
            const result = await singpassService.fetchLoginUrl();
            if(window && window.open) {
                window.open(
                    result,
                    '_blank'
                );
            }
            else {
                console.error('cannot open new tab for login');
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if(!isReadOnly) {
            subscribeStorageEvent(handleSPLStorageEvent);

            return () => {
                unsubscribeStorageEvent(handleSPLStorageEvent);
            }
        }
    },[]);

    return (
        <Grid container>
            <Grid item>
                <Button disabled={disableLogin} onClick={async () => {
                    if(!disableLogin) {
                        await openLoginTab();
                        //setDisableLogin(true);
                    }
                }} variant={'outlined'}>Login with Singpass</Button>
            </Grid>
        </Grid>
    );
}

/**
 * Singpass login wrapper.
 * @param props
 * @constructor
 */
export const SingpassLogin = (props: SingpassLoginProps) => {
    const layout = useLayout();

    return (
        layout === 'review'
            ? null
            : <EditableSingpassLogin {...props}/>
    );
};