import { createContext, useContext } from "react";
export type Layout = "default" | "facing"
    /**
     * visual representation of the review forms.
     */
    | 'review';

const EditFieldContext = createContext<boolean>(false)

export default EditFieldContext;

export const useEditField = () => {
    return useContext(EditFieldContext);
};

