import * as React from "react";
import {CSSProperties, ReactNode, useContext} from "react";
import {Box, Button, Grid, Typography, useTheme} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';
import DropdownButtonsGroup from './DropdownButtonsGroup';
import {ButtonBase, ButtonParams} from "sancus-client-common/dist/common/action_buttons_base";
import {Provider as ReduxProvider, useDispatch, useSelector} from "react-redux";
import {useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import {IActionJson,IActions} from "sancus-client-common/dist";
import {readOnlyStyleFactory} from "./generic_component/GenericFieldComponentSimple";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";



const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        marginLeft: '16px',
        '&:nth-child(1)': {
            marginLeft: '0'
        }
    },
    actionButton:{
       //disabled
        '&:disabled': {
            visibility: theme['customProps']?.actionButton?.disabled?.visibility ??  'visible',
        },

        '& back':{
            '&:disabled': {
                visibility: theme['customProps']?.actionButton?.back?.disabled?.visibility ??
                    theme['customProps']?.actionButton?.disabled?.visibility ??
                    'visible'
            }
        },


    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export const ActionContainer = (props: { children?: ReactNode[], actions?: IActions }) => {
    const theme = useTheme();
    const needRenderDropdownButtonGroup = props.actions && props.actions.length && props.children &&
        props.children.length > 1 && props.actions.length === props.children.length;
    const dispatch = useDispatch();
    const designerActions = useDesignerActions(dispatch)
    const designMode = useContext(DesignModeContext)
    const [isDragOver, setIsDragOver] = React.useState(false);
    const useReadOnlyStyles = makeStyles(readOnlyStyleFactory);
    const readOnlyClasses = useReadOnlyStyles();
    const selectedField = useSelector<RootState,RootState['designer']['selectedField']>(state=>state.designer?.selectedField);
    const selectedFieldClassName = (selectedField && selectedField.id === "actionsList" && readOnlyClasses.designerFieldSelected) || "";

    const BottomFormDropArea = ({children}) => {
        if (designMode) {
            // (!isDragOver && selectedField && selectedField.id ===  fv_value.id && readOnlyClasses.designerFieldSelected || " ")}
            return <div id="BottomFormDropArea"
                        className={isDragOver && readOnlyClasses.designerIsDragOverIndicateTop || selectedFieldClassName}
                        onDragOver={(e) => {
                            setIsDragOver(true);

                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onDragLeave={(e) => {
                            setIsDragOver(false);

                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onDrop={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            designerActions.onDropInPlace({type: 'bottomFormArea'})
                        }}
                        onFocusCapture={(evt) => {
                            evt.preventDefault();
                            designerActions.selectField({fieldId: 'actionsList'})
                        }}
                        onClickCapture={(evt) => {
                            designerActions.selectField({fieldId: 'actionsList'});
                        }}
            > {children}</div>
        } else {
            return children
        }
    }

    const hasBackAction = props.actions && props.actions[0] && (props.actions[0] === 'back' || (props.actions[0] as IActionJson).value == 'back')
    const marginActionContainerStyle:CSSProperties | undefined =
            {
                marginLeft:theme.spacing(2),
                marginRight:theme.spacing(2)
            };

    return <BottomFormDropArea>
        <Box mt={2} mb={2} id={"ActionContainer"}><Grid container direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center" style={marginActionContainerStyle}>
            {needRenderDropdownButtonGroup ? (<DropdownButtonsGroup children={props.children} actions={props.actions}/>)
                : props.children}

        </Grid></Box>
    </BottomFormDropArea>
}

export const BackButton = (params:ButtonParams)=>{
    const doRender = (handler?)=>{
        const classes = useStyles();
        const extraStyle = params.extraStyle as CSSProperties | undefined

        const theme = useTheme();
        const renderDisabled = theme['customProps']?.actionButton?.back?.disabled?.visibility ??
            theme['customProps']?.actionButton?.disabled?.visibility ??
            'hidden'
        const disabled = params.disabled;
        if (disabled && renderDisabled === 'hidden')
            return <></>;
        return <div className={classes.wrapper} style={extraStyle}>
            <Button id={"back"}
                    className={classes.actionButton+' back'+ (params.disabled?' disabled':'')}
                size="large"
                onClick={handler || params.handler}
                disabled={params.disabled }>
            {params.children}

            </Button>
        </div>
    }

    return <ButtonBase {...params} doRender={doRender} />
};


export const SubmitButton = (params:ButtonParams)=>{
    const doRender = ()=>{

        const classes = useStyles();

        const render = params.render;
        const BTNCMP = render == "link" ? ((p)=> <a {...p}>{p.children}</a>): Button;
        const extraStyle = params.extraStyle as CSSProperties | undefined
        return (
            <div className={classes.wrapper} style={extraStyle}>

                <BTNCMP
                    id={"startFlow"} size="large"
                    onClick={params.handler}
                    variant="contained"
                    color="primary"
                    disabled={params.disabled}
                    // endIcon={hasError?<Clear/>:null}
                    className={classes.actionButton+ (params.disabled?' disabled':'')+' '+params.children} >
                    <Typography>{params.children}</Typography>
                </BTNCMP>
            </div>)
    }



    return <ButtonBase {...params} doRender={doRender}/>
};

