export default {
    "name": "Jumio",
    "id": "com.norbloc.sancus.integrations.Jumio",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://www.jumio.com/app/uploads/2020/03/jumio-favicon-192x198-1.png"
    },
    entriesVisible:{
        '_all':true,
        'sancus.widget.step':true,
        'executionListeners': true,
        'properties':true
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "jumio_full",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "User Id variable Name",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "userId"
            }
        },
        {
            "label": "Last Name variable name",
            "type": "String",
            "value": "lastName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "lastName"
            }
        },
        {
            "label": "First Name variable name",
            "type": "String",
            "value": "firstName",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "firstName"
            }
        },
        {
            "label": "Nationality variable name",
            "type": "String",
            "value": "nationality",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "nationality"
            }
        },
        {
            "label": "Date of birth",
            "type": "String",
            "value": "dateOfBirth",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "dateOfBirth"
            }
        },
        {
            "label": "Document type",
            "type": "String",
            "value": "idDocumentType",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "idDocumentType"
            }
        },
        {
            "label": "Document Issuing date",
            "type": "String",
            "value": "idIssuingDate",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "idIssuingDate"
            }
        },
        {
            "label": "Document expiration date",
            "type": "String",
            "value": "idExpirationDate",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "idExpirationDate"
            }
        },
        {
            "label": "Document Issuing country",
            "type": "String",
            "value": "idIssuingCountry",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "idIssuingCountry"
            }
        },
        {
            "label": "Document number",
            "type": "String",
            "value": "idDocumentNumber",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "idDocumentNumber"
            }
        },

        {
            "label": "Jumio Data",
            "description": "This object contains jumio submission alongside check results",
            "type": "Hidden",
            "value": "jumio_data",
            "editable": false,
            "binding": {
                "type": "camunda:out",
                "source": "jumio_data"
            }
        },
        {
            "label": "Skipable",
            "type": "Dropdown",
            "choices": [
                {"name": "Yes", "value": "yes"},
                {"name": "No", "value": "no"}
            ],
            "editable": true,
            "value": "no",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "skipable"
            }
        },

        {
            "label": "Skipable_transfer",
            "type": "Hidden",
            "value": "skipable",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "skipable"
            }
        },


        {
            "label": "Action",
            "description": "Did the use skip the step?",
            "type": "Hidden",
            "value": "jumio_action",
            "editable": false,
            "binding": {
                "type": "camunda:out",
                "source": "action"
            }
        }
    ]
}
