import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';


/**
 * Custom updater intercepts postexecute of shape.create and applies the template to the newly created element
 * This allows the ChangeElementTemplateHandler of bpmn-js-properties to be invoked and apply the template on the element
 */
export default class CustomUpdater extends CommandInterceptor{
    constructor(eventBus, bpmnFactory,commandStack, modeling, bpmnjs,elementTemplates){
        super(eventBus);
        this.bpmnFactory= bpmnFactory;
        this.commandStack = commandStack;
        this.modeling  = modeling;
        this.bpmnjs = bpmnjs;
        this.elementTemplates=elementTemplates

        this.postExecute(evt=>{
            const {command,context,id,type}  = evt;
            const elementTemplates = this.elementTemplates;
            if (command === 'shape.create'){
                const {shape} = context;
                if (shape.sancusService){
                    const elementTemplate = elementTemplates.get('com.norbloc.sancus.integrations.'+shape.sancusService)

                    const command = 'propertiesPanel.camunda.changeTemplate';
                    const commandContext = {
                        element: shape,
                        newTemplate: elementTemplate
                    };

                    commandStack.execute(command, commandContext);
                }
            }else if (command === 'element.updateProperties'){
                const {element} = context;
                if (element.businessObject?.modelerTemplate){
                    if (element.businessObject?.modelerTemplate.startsWith('com.norbloc.sancus.integrations.')) {
                        const shortId = element.businessObject?.modelerTemplate.split('.').pop()
                        element.sancusService = shortId;
                    }
                }
            }
        })

    }
}

CustomUpdater.$inject = ['eventBus', 'bpmnFactory','commandStack','modeling', 'bpmnjs','elementTemplates']
