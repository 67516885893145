import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import Paper from "@material-ui/core/Paper/index";
import Grid from "@material-ui/core/Grid/index";
import service_default_icon from "../../assets/service_default_icon.svg"

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        cursor: "pointer",
        "& h3": {
            fontWeight: 100,
        },
        "& p": {
            margin: 8,
            fontWeight: 500,
            //color: '#1f2124',
            textAlign: 'left'
        }
    },
    icon: {
        background: "#ffffff",
        borderRadius: "100px",
        margin: "8px",
        boxShadow: "0 0 43px 0 rgb(0 0 0 / 11%)",
        height: "60px",
        width: "60px"
    }
}));

const image_fetch_cache = localStorage.getItem("image_fetch_cache")?JSON.parse(localStorage.getItem("image_fetch_cache")):{}

export default function JourneyCard({cardData, onCardClick}) {
    const classes = useStyles();
    const [img,setImg] = useState(cardData.img)
    const [attribution,setAttribution] = useState(null)

    const imgFailed = image_fetch_cache[cardData.img] === false;
    return (
        <Paper variant="outlined" square={true} className={classes.paper} id={cardData.name} onClick={() => {
            if (onCardClick) {
                onCardClick(cardData);
            }
        }}>
            <Grid container direction="row" alignItems="center">
                <Grid className={classes.icon} container direction="row" alignItems="center" justifyContent="center">
                    <img height="30" src={(!imgFailed && img )|| service_default_icon} onError={e=>{
                        if (e.currentTarget.src === service_default_icon)
                            return;
                        e.currentTarget.src = service_default_icon
                        if  (imgFailed) {
                            //Possibly we failed to fetch default image - network error maybe - clear the cache
                            delete image_fetch_cache[cardData.img];
                            localStorage.setItem("image_fetch_cache", JSON.stringify(image_fetch_cache))
                        }else {
                            image_fetch_cache[cardData.img] = false;
                            localStorage.setItem("image_fetch_cache", JSON.stringify(image_fetch_cache))
                        }
                    }}/>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" color='textSecondary'>
                        {cardData.title}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
