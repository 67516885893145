import {append as svgAppend, create as svgCreate} from 'tiny-svg';
import BpmnRenderer from "bpmn-js/lib/draw/BpmnRenderer";

/**
 * Provides more specific rendering form sancusService elements
 */
export default class CustomRendeder extends BpmnRenderer {
    constructor(config, eventBus, styles, pathMap,
                canvas, textRenderer,elementTemplates) {
        super(config, eventBus, styles, pathMap,
            canvas, textRenderer,1500);
        this.elementTemplates = elementTemplates;

        console.log('CustomRendeder config',config)

    }

    canRender(element){
        const serviceName = element.sancusService
        const templateId = element.businessObject?.modelerTemplate;
        const elementTemplates = this.elementTemplates;
        const elementTemplate = templateId && elementTemplates.get(templateId)
        return elementTemplate?.icon?.contents ;
    }

    drawShape(parent, shape){
        const serviceName = shape.sancusService

        const templateId = shape.businessObject?.modelerTemplate;
        const elementTemplates = this.elementTemplates;
        const elementTemplate = templateId && elementTemplates.get(templateId)

        const href = elementTemplate?.icon?.contents ;

        shape.type= elementTemplate.appliesTo[0];

        const {width,height} = shape;

        const bpmnShape = super.drawShape(parent,shape);

        const catGfx = svgCreate('image', {
            x: 3* width /4 -5,
            y: 3 * height /4 -5,
            width: width /4,
            height: height /4,
            href
        });

        return svgAppend(parent, catGfx);

    }

}


CustomRendeder.$inject = [
    'config.bpmnRenderer',
    'eventBus',
    'styles',
    'pathMap',
    'canvas',
    'textRenderer',
    'elementTemplates'
];
