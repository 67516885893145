// Require your custom property entries.

import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";
import {is,getBusinessObject} from "bpmn-js/lib/util/ModelUtil";

import ElementHelper from "bpmn-js-properties-panel/lib/helper/ElementHelper";
import ExtensionElementsHelper from "bpmn-js-properties-panel/lib/helper/ExtensionElementsHelper";
import cmdHelper from "bpmn-js-properties-panel/lib/helper/CmdHelper";

const LOW_PRIORITY = 500;

/**
 * Injects the 'Launch Form Builder' link in form tab
 * @param propertiesPanel
 * @param translate
 * @param eventBus
 * @constructor
 */
export default function LaunchFormBuilderPropertiesProvider(propertiesPanel, translate, eventBus,bpmnFactory,moddle) {

    // Use a lower priority to ensure it is loaded after the basic BPMN properties.
    propertiesPanel.registerProvider(LOW_PRIORITY, this);

    this.getTabs = function (element) {

        return function (entries) {
            if (is(element, 'bpmn:StartEvent') || is(element, 'bpmn:UserTask')) {
                //find "forms" group
                const forms = entries.find(e => e.id === 'forms');
                forms.groups.push({
                    id: 'form-builder',
                    label: ' Form Builder',
                    entries: [entryFactory.link(translate, {
                        id: 'launchFormBuilder',
                        buttonLabel: 'Launch Form Builder',
                        handleClick: function () {
                            eventBus.fire('formbuilder.open', {data: element})
                        }
                    })]
                })
            }

            /*if (is(element, 'bpmn:StartEvent') ||
                is(element, 'bpmn:UserTask') ||
                is(element, 'bpmn:CallActivity') ||
                (is(element, 'bpmn:Task') && element.sancusService)) {
                const extensionElements = entries.find(e => e.id === 'extensionElements')

                const nameSpace = 'camunda:Properties';
                const property = 'sancus.widget.step'
                extensionElements.groups.unshift({
                    id: 'sancus-widget',
                    label: 'Sancus Widget',
                    entries: [
                        entryFactory.textField(translate, {
                            id: property,
                            label: 'Step Name',
                            modelProperty: property,
                            get(elem){
                                const bo  = elem.businessObject;
                                const exts = ExtensionElementsHelper.getExtensionElements(bo,nameSpace)
                                if (exts && exts[0]) {
                                    const v=exts[0].values;
                                    const ext = v.find(e => {
                                        return e.name === property
                                    })
                                    return ext ? {[ext.name]:ext.value} : {[property]:''};
                                }
                                return {[property]:''}
                            },

                            set(elem,values){
                                const bo  = elem.businessObject;
                                const exts = ExtensionElementsHelper.getExtensionElements(bo,nameSpace)
                                if (exts && exts[0]) {
                                    const v=exts[0].values;
                                    const ext = v.find(e => {
                                        return e.name === property
                                    })
                                    if (ext){
                                        ext.value = values[property];
                                    }else{
                                        v.push({name:property,value:values[property]})
                                    }
                                    return cmdHelper.updateBusinessObject(elem, bo,  exts);
                                }else{
                                    const _p = Object.keys(values).map(v=>{
                                        return {name:v,value:values[v]}
                                    })
                                    const p = bpmnFactory.create(nameSpace,{
                                        values:_p
                                    });

                                    bo.extensionElements.values.push(p);

                                    return cmdHelper.updateBusinessObject(elem, bo,  bo.extensionElements);
                                }

                            }

                        })
                    ]
                })
            }*/

            return entries;

        }


    };
}

LaunchFormBuilderPropertiesProvider.$inject = ['propertiesPanel', 'translate', 'eventBus','bpmnFactory','moddle']
