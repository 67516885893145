export default {
    "name": "BolagVerket Lookup",
    "id": "com.norbloc.sancus.integrations.BolagsVerket",
    "appliesTo": [
        "bpmn:ServiceTask"
    ],
    "icon":{
        "contents":"https://bolagsverket.se/favicon.ico"
    },
    "properties": [
        {
            "label": "Activity",
            "type": "Hidden",
            "value": "#{corporateRegistriesLookupAndApplySubstitutions}",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "camunda:delegateExpression"
            }
        },

        {
            "label": "Corporate Reg Num",
            "type": "String",
            "description": "Registration Number",
            "editable": true,
            "value": "${corp_reg_num}",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "corp_reg_num"

            }
        }, {
            "label": "Corporate Reg Country",
            "type": "String",
            "description": "Registration country",
            "editable": true,
            "value": "${corp_reg_country}",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "corp_reg_country"

            }
        },

        {
            "label": "Substitutions",
            "type": "String",
            "description": "Specify where registry results should map to",
            "editable": true,
            "value": "['companyName':'company_basic.CorCD_CompName',\n'bolagsRegistrationDate':'company_basic.CorCD_RegDate' ]",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "substitutions",
                "scriptFormat": "groovy"
            }
        }
    ]
}