import * as React from "react";
import { ICheck } from "sancus-client-common/dist/common/FormContainerBase";
import {Grid, Typography, Box, Link, Divider, Dialog, DialogTitle, DialogContent} from "@material-ui/core";
import {useStyles} from "./pepComponent";
import {ComplyAdvantageHitSnippet, ComplyAdvantageReportComponent} from "./pepReportComponent";
import {useState} from "react";

interface AsiaVerifyPepProps {
    check: ICheck;
}

export const ComplyAdvantagePEP = (props) => {
    const classes = useStyles();
    console.debug('asia verify pep props: ', props);

    const { status, message, metaData } = props.check;
    const metadata: { entity?: { firstName?: string, lastName?: string, categories?: string[] }} | undefined = props.check.metaData;

    const {content,message:_message, status:_status} = props.check.metaData;
    if (_status == 'error') {
        return (
            <Grid container className={classes.yellow}>
                <Grid item>
                    <Typography variant="body2">Error: {_message}</Typography>
                </Grid>
            </Grid>
        );
    }
    const {data} = content;
    const {search_term,hits,total_hits} = data;

    const statusClassName = status == 1 ? classes.green : classes.yellow
    let messageContent =  ` for ${search_term}`

    let messageElement;
    if (status == 1) {
        messageElement = <>PEP Screening report {messageContent} is <b>CLEAR</b></>
    } else {
        messageElement = `${total_hits} potential matches found ${messageContent}:`
    }

    const [selectedHit,setSelectedHit] = useState<any>(null)
    return (<>
        <Grid container className={statusClassName} direction={"column"}>
            <Grid item><Typography variant="body2">{messageElement}</Typography></Grid>
            <Grid item>

                {hits.map(hit=> {
                    if (!hit){
                        return <></>
                    }
                    const {score} = hit;
                    return <Box mt={1} key={"comply_adv:" + hit.doc.name}><Link onClick={evt => {
                        evt.preventDefault();
                        setSelectedHit(hit);
                    }} variant="body2"
                          style={{color: 'black'}}>
                        <b>{hit.doc.name} ({score})</b>
                    </Link></Box>
                })}

            </Grid>
        </Grid>
        <Dialog open={selectedHit!=null} onClose={()=>setSelectedHit(null)} maxWidth={"md"}>
            <DialogTitle>{selectedHit?.doc?.name}</DialogTitle>
            <DialogContent>
                <ComplyAdvantageHitSnippet hit={selectedHit}/>
            </DialogContent>
        </Dialog>
        </>
    );
};