import {LinearProgress, TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {contextPath} from "../contextPath";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchBar from "material-ui-search-bar";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {Add, Edit, ExpandLess, ExpandMore} from "@material-ui/icons";
import {makeStyles} from '@material-ui/core/styles';
import {blackButtonStyles} from "../variables";
import Divider from "@material-ui/core/Divider";


const client = axios.create({
    baseURL: `${contextPath}api/config`,
});
const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        buttonContainer: blackButtonStyles,
    }
});

const AddNewProperty = (props)=>{
    const classes = useStyles();
    const history  = useNavigate();
    const [category,setCategory] = useState('');
    const [name,setName] = useState('');
    return <TableRow key={'new property'} className={classes.root}>
        <TableCell>
            <TextField label={"category"} value={category} onChange={evt=>{
                setCategory(evt.target.value)
            }} variant={"filled"}></TextField>
        </TableCell>
        <TableCell>
            <TextField label={"name"} value={name} onChange={evt=>{
                setName(evt.target.value);
            }} variant={"filled"}></TextField>
        </TableCell>
        <TableCell/>
        <TableCell/>
        <TableCell align={'right'}>
            <Add onClick={evt=>{
                history(`/dashboard/configuration/${category}/${name}`)
            }}/>
        </TableCell>
    </TableRow>

}

export const ListConfigurationProperties = props=>{
    const [searched,setSearched] = useState("");
    const pageSizeOptions = [5, 10, 25, {label: 'All', value: -1}];
    const [ pageSize, setPageSize ] = useState(pageSizeOptions[4]);
    const [page,setPage] = useState(0);

    const [itemsCount,setItemsCount] = useState(0);

    const [properties,setProperties] = useState(null);
    const [error,setError] = useState(null);

    useEffect(()=>{
        client.get(`/`).then(response=>{
            setProperties(response.data);
        }).catch(err=>{
            console.log(err);
            setProperties([])
        });
    },[]);

    useEffect(() => {
        //calculate pagination
        if(properties && properties.length > 0) {
            setItemsCount(properties.filter(searchFilter).length);
        }
    }, [properties, pageSize, searched]);

    const searchFilter = (property, index) => {
        if (!searched) {
            return true;
        }

        const s = searched.toLowerCase();
        return property.category?.toLowerCase().includes(s) || property.name?.toLowerCase().includes(s);
    }

    const pageFilter = (workflow, index) => {
        if (pageSize == null || pageSize < 0){
            return true;
        }
        return pageSize < 0
            ? true
            : index < pageSize * (page + 1) && index >= page * pageSize;
    }

    const handleChangePage = (evt, page) => {
        setPage(page);
    };

    const handlePageSizeChange = (evt) => {
        const numValue = isNaN(parseInt(evt.target.value)) ? pageSizeOptions[0] : parseInt(evt.target.value);
        setPageSize(numValue);
    };
    const history = useNavigate();


    const propertiesFiltered = properties && properties.filter(searchFilter).filter(pageFilter)
    const propertiesGrouped = propertiesFiltered && propertiesFiltered.reduce((acc,property)=>{
        const category = property.category;
        const name = property.name;
        if(!acc[category]){
            acc[category] = [];
        }
        acc[category].push(property);
        return acc;
    },{});

    return <Grid item>
        {properties==null && <LinearProgress/>}
        {error && <div style={{color:"red"}}>{error.message}</div>}
        <SearchBar
            value={searched}
            onChange={(searchVal) => {
                setSearched(searchVal);
                setPage(0);
            }}
            onCancelSearch={() => setSearched("")}
            style={{marginBottom: 2}}
        />

        <TableContainer component={Paper} elevation={0} square={true} style={{ borderBottom: '0px'}}>
            <Table>
                <TableHead>
                </TableHead>


        {propertiesGrouped && Object.keys(propertiesGrouped).map(category=>{
            const _properties = propertiesGrouped[category];
            return <ConfigurationCategory category={category} properties={_properties} cbk={props.cbk} onDelete={async property=> {
                try {
                    const rsp = await client.delete(`/${property.category}/${property.name}`);
                    const new_properties = properties.filter(p => !(p.category === property.category && p.name === property.name))
                    setProperties(new_properties);
                } catch (err) {
                    setError(err);
                }
            }} onEdit={property=>{
                history(`/dashboard/configuration/${property.category}/${property.name}`)
            }}/>
        })
        }

        <AddNewProperty/>
            </Table>
        </TableContainer>

    </Grid>
}

export const ConfigurationCategory = (props)=>{
    const classes = useStyles();
    const {category,properties,onEdit,onDelete} = props;
    const [collapsed,setCollapsed] = useState(true);

    return <>
                <TableRow>
                    <TableCell>
                        <Typography variant={'body1'}>
                        {category} ({properties.length}){collapsed?
                            <IconButton onClick={evt=>setCollapsed(false)}>
                                <ExpandLess/>
                            </IconButton>:
                            <IconButton onClick={evt=>setCollapsed(true)}>
                                <ExpandMore/>
                            </IconButton>
                        }
                        </Typography>
                    </TableCell>
                </TableRow>

                {collapsed ? <></>:<TableBody>
                    {
                        properties.map((property, index) => {
                            return (
                                <TableRow key={property.category+'/'+property.name} className={classes.root}>
                                    <TableCell/>

                                    <TableCell>
                                        <Typography variant={'body1'}>{property.name}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography variant={'body1'}>{property.value}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography variant={'body1'}>{property.bulk && property.bulk.substring(0,10)}</Typography>
                                    </TableCell>

                                    <TableCell align={'right'}>
                                        <Edit onClick={evt=>{
                                            onEdit(property)
                                        }}/>
                                        <DeleteIcon onClick={async (evt)=>{
                                            evt.preventDefault();
                                            onDelete(property)
                                        }}/>
                                    </TableCell>

                                </TableRow>
                            );
                        })
                    }

                </TableBody>}
                {/*<TableFooter>*/}
                {/*    <TableRow>*/}
                {/*        <TablePagination*/}
                {/*            rowsPerPageOptions={pageSizeOptions}*/}
                {/*            colSpan={4}*/}
                {/*            count={itemsCount}*/}
                {/*            rowsPerPage={pageSize}*/}
                {/*            page={page}*/}
                {/*            SelectProps={{*/}
                {/*                inputProps: {'aria-label': 'rows per page'},*/}
                {/*                native: true,*/}
                {/*            }}*/}
                {/*            onPageChange={handleChangePage}*/}
                {/*            onRowsPerPageChange={handlePageSizeChange}*/}
                {/*            ActionsComponent={TablePaginationActions}*/}
                {/*        />*/}
                {/*    </TableRow>*/}
                {/*</TableFooter>*/}
   </>

}

export const ConfirurationManagement  = props =>{


    const {category,name} = useParams();

    const [item,setItem] = useState(null);
    const [err,setErr] = useState(null);
    const [inprogress,setInprogress] = useState(false);

    const [value,setValue] = useState(item?.value);
    useEffect(()=>{
        client.get(`/${category}/${name}`).then(response=>{
            setItem(response.data);
            setValue(response.data.value)
        }).catch(err=>{
            setErr(err);
            setItem({
                category,
                name,
                bulk:''
            })
        });

    },[]);


    const ref = useRef(null);
    const history = useNavigate()
    return <Grid direction={"column"}>
        <Grid item>
            <TextField label={"category"} defaultValue={category} disabled></TextField>
        </Grid>
        <Grid item>
            <TextField label={"name"} defaultValue={name} disabled></TextField>
        </Grid>
        <Grid item>
            <TextField label={"value"} value={value} onChange={evt=>{
                setValue(evt.target.value)
            }}></TextField>
        </Grid>

        <Divider/>
        <Typography variant={"h6"}>Bulk</Typography>
        <Grid item>
            {item==null && err == null && <LinearProgress/>}
            {(item!=null || err !=null) && <TextareaAutosize
                defaultValue={item?.bulk || ''}
                fullWidth
                ref={ref}
                label={"bulk"}
                minRows={10}
                maxRows={30}
                cols={100}
                minLength={1000}
            >

            </TextareaAutosize>}
            {err !=null && <div style={{color:"red"}}>{err.message}</div>}
        </Grid>

        <Grid item container direction={"row"}>

            <Button disabled={inprogress} onClick={async(evt)=>{
                evt.preventDefault();
                setInprogress(true);
                try {
                    const rsp = await client.post(`/`, {
                        ...item,
                        value,
                        bulk: ref.current.value
                    });

                    rsp.data && props && props.cbk && props.cbk(rsp.data)
                    //Update version
                    setItem({
                        ...item,
                        value,
                        version: rsp.data.version
                    })
                }catch (err){
                    setErr(err);
                }
                setInprogress(false);
            }}>Save</Button>


            <Button onClick={evt=>{
                history(-1)
            }}>Close</Button>



        </Grid>
    </Grid>
}
