import * as React from "react";
import {useState} from "react";
import {
	Avatar,
	Box,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton, Link,
	Typography
} from "@material-ui/core";
import {ICheck,ILoggedUserState,INote} from "sancus-client-common/dist/common/FormContainerBase";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from '@material-ui/core/CardActionArea';
import {JumioCheckResultComponent,JumioKYXCheckResultComponent, OnfidoCheckResultComponent} from "./onfidoCheckResultComponent";
import {PepComponent} from "./pepComponent";
import {CHECK_STATUS} from "sancus-client-common/dist/constants/checkStatus.constant";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {useCheckActions} from "sancus-client-common/dist/store/actions/checks";
import ChatIcon from '@material-ui/icons/Chat';
import Checkbox from "@material-ui/core/Checkbox";
import contextPath from "sancus-client-common/dist/common/contextPath";
import Note from "./Note";
import MomentUtils from "@date-io/moment";
import {RefinitivComponent} from "./refinitivComponent";
import {getAvatarDetails} from 'sancus-client-common/dist/common/FieldCheckAvatar'
import {AsiaVerifyPep} from "./asiaVerifyPep.component";
import {OrbisPep} from "./orbisPep.component";
import {ComplyAdvantagePEP} from "./ComplyAdvantagePep.component";
import {SanctionsIOReportItemComponent} from "./pepReportComponent";
import LexisNexisPEPCheck from "./pep/lexis-nexis/LexisNexisPEPCheck";
import {MembercheckPep} from "./membercheckPep.component";

export interface ICheckProps {
	check: ICheck;
	isSelected: boolean;
	scrollToFields(s:string):void
	handleResolveCheck(checkId: string, resolve: boolean):void,
	handleAddNote(checkId: string, text: string):void,
	loggedUserState?: ILoggedUserState;
}

export const useStyles = makeStyles(() => ({
	red:{
		borderLeft: 'solid 3px #f44336',
		paddingLeft: 9,
		paddingTop: 6,
		paddingBottom: 6
	},
	blue: {
		borderLeft: 'solid 3px #039be5',
		paddingLeft: 9,
		paddingTop: 6,
		paddingBottom: 6
	},
	green: {
		borderLeft: 'solid 3px #99cc33',
		paddingLeft: 9,
		paddingTop: 6,
		paddingBottom: 6
	},
	yellow: {
		borderLeft: 'solid 3px #ffcc00',
		paddingLeft: 9,
		paddingTop: 6,
		paddingBottom: 6
	}
}));

const momentUtils = new MomentUtils();

interface SanctionsIoPepProps {
	check: ICheck;
}

export const SanctionsIoPep = (props: SanctionsIoPepProps) => {
	console.log(props.check);
	const classes = useStyles();

	const [selectedResult, setSelectedResult] = useState<any>(null);

	if (props.check.metaData.error){
		const statusClassName = classes.red
		let msg;
		try{
			const asJson = JSON.parse(props.check.metaData.error);
			msg = asJson.detail;
		}catch (e){
			msg = props.check.metaData.error;
		}
		return (
			<Grid container direction={'column'} wrap={'nowrap'} spacing={1} className={statusClassName}>
				<Grid item>
					<Typography>{msg}</Typography>
				</Grid>
			</Grid>
		)
	}
	const statusClassName = props.check.status == 1 ? classes.green : classes.yellow
	return (
		<Grid container direction={'column'} wrap={'nowrap'} spacing={1} className={statusClassName}>
			<Dialog open={!!selectedResult} onClose={() => setSelectedResult(null) } maxWidth={"md"}>
				<DialogTitle>{props.check.metaData.search?.params.name}</DialogTitle>
				<DialogContent>
					{
						selectedResult && <SanctionsIOReportItemComponent result={selectedResult}/>
					}
				</DialogContent>
			</Dialog>
			<Grid item>
				<Typography>{`${props.check.metaData.results.length} potential matches found for ${decodeURIComponent(props.check.metaData.search.params.name)}:`}</Typography>
			</Grid>
			<Grid item style={{ flexGrow: 1, overflowY: 'scroll'}}>
				<Grid container direction={'column'} wrap={'nowrap'} spacing={1}>
					{props.check.metaData.results.map((result, idx) => {
						return <Box mt={1} key={"sc_io:" + idx}>
							<Link onClick={evt => {
								evt.preventDefault();
								setSelectedResult(result);
							}} variant="body2"
								  style={{color: 'black'}}>
								<b>{result.name}</b></Link>


						</Box>
					})}
				</Grid>
			</Grid>
		</Grid>
	);
}

/**
 * Component to display each Check Card
 * @param props
 * @constructor
 */
const CheckComponent = (props: ICheckProps) => {
	const dispatch = useDispatch();
	const [ toggleComment, setToggleComment ] = useState(false);
	const checkActions = useCheckActions(dispatch);
	const classes = useStyles();
	const resolveHandler = () => props.handleResolveCheck(props.check.uuid, props.check.status === 2);
	const handleSubmitNote = (text: string) => props.handleAddNote(props.check.uuid, text);
	// TODO check should display the notes of all officers
	// TODO create form to add a new note
	const isResolved = props.check.status === CHECK_STATUS.resolved;
	const disabled = isResolved && !!(props.check.resolveInfo && props.check.resolveInfo.resolver === 'service');

	let {avatar,avatar_alt,service_message,color} = getAvatarDetails(props.check)

	let className = classes[color];


	let customComponent = <>
		<Grid className={className}>
			<Typography variant="body2"><span dangerouslySetInnerHTML={{__html: props.check.message}}/></Typography>
		</Grid>
	</>;

	switch (props.check.service){
		case 'onfido':
			if (props.check.metaData?.onfido_check_result) {
				customComponent = <OnfidoCheckResultComponent check={props.check}/>
			}
			break;
		case 'jumio':
			customComponent = <JumioCheckResultComponent check={props.check} />
			break;
		case 'jumio-kyx':
			customComponent = <JumioKYXCheckResultComponent check={props.check} />
			break;
		case 'pep':
			customComponent = <PepComponent pepResult={props.check}/>;
			break;
		case 'refinitiv':
			customComponent = <RefinitivComponent response={props.check.metaData} />;
			break;
		case 'asia verify pep':
			customComponent = <AsiaVerifyPep check={props.check}/>
			break;
		case 'comply-advantage':
			customComponent = <ComplyAdvantagePEP check={props.check}/>
			break;
		case 'sanctions-io':
			customComponent = <SanctionsIoPep check={props.check}/>
			break;
		case 'orbis':
			customComponent = <OrbisPep check={props.check}/>
			break;
		case 'membercheck':
			customComponent = <MembercheckPep check={props.check}/>
			break;
		case 'lexis-nexis':
			customComponent = <LexisNexisPEPCheck check={props.check}/>
			break;
		case 'gemi':
		case 'fides':
		case 'BolagsVerket':
		case 'manual':
		case 'dilligence':
		default:
			break;

	}
	//TODO translate lines 135, 140, 145
	// todo temp handle of null notes
	const noteTpl = props.check.notes ? props.check.notes.map((n,idx) => <Note note={n} key={idx} handleSubmitNote={handleSubmitNote}/>) : <></>

	return <Box id={props.check.uuid}>
			<Card elevation={0}
				style={{
				 // boxShadow : props.isSelected ? '0px 6px 42px 0px rgb(0 0 0 / 10%)' : 'none',
				 borderRadius: '9px', boxShadow:  '0px 6px 42px 0px rgb(0 0 0 / 10%)',
				 border: props.isSelected ? 'solid 3px #e7eff7' : 'solid 3px transparent',
				 background: props.isSelected ? 'white' : 'white',
				 // borderColor: props.isSelected ? '#26a69a' : 'transparent',
				 margin: '24px',
			 }}>
			{/* // commented as IE11 can't detect clicks inside
			  <CardActionArea>*/}
				{/*<CardContent  onClick={() => scrollToSection(`${props.data.service}_view`)}>*/}
				<CardContent  style={{ padding: '16px 16px 16px 8px', cursor: 'pointer' }} onClick={(e) => {
					if ((e.target as any).type === 'checkbox') {
						return;
					}
					checkActions.setSelectedCheck(props.check);
					props.scrollToFields(props.check.uuid);
				}}>
					<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
						<Checkbox
							checked={isResolved}
							disabled={disabled}
							onChange={resolveHandler} color="default"
						/>
						<Grid item xs>{customComponent}</Grid>
					</Grid>
				</CardContent>

				<Grid>
				{/* style={{ background: toggleComment ? '#ededed' : 'white'}}*/}

					<CardActions style={{ borderTop: 'solid 1px #ededed', padding: '0px 8px' }}>
						<Grid container direction="row" justifyContent="flex-start" alignItems="center">

							<IconButton aria-label="chat" style={{ color: '#9a9a9a' }} onClick={ e=>setToggleComment(!toggleComment) }><ChatIcon fontSize="small"/></IconButton>

							{ (props.check.resolveInfo && props.check.resolveInfo.resolver === 'service' && service_message) ?
								<Grid container direction="row" justifyContent="flex-end" alignItems="center" item xs>
									<Avatar src={avatar && avatar.length>0?avatar:'/not_found.png'} alt={avatar_alt} style={{width: '24px', height: '24px'}}/>
									<Box ml={1} mr={1}>
										<Typography variant="caption" ><span dangerouslySetInnerHTML={{__html: service_message!}}/></Typography>
									</Box>
								</Grid> : ''
							}


							{ props.check.status == CHECK_STATUS.resolved &&
								props.check.resolveInfo &&
								props.check.resolveInfo.resolver === 'user' &&
								props.check.resolveInfo.resolverInfo ?
								<Grid container direction="row" justifyContent="flex-end" alignItems="center" item xs>
									<Avatar src={`${contextPath}api/users/${props.check.resolveInfo.resolverInfo.id}/image`} style={{width: '24px', height: '24px'}}>

									</Avatar>
									< Box ml={1} mr={1}>
										<Typography variant="caption">
							       			Resolved by {props.check.resolveInfo.resolverInfo.firstName} {props.check.resolveInfo.resolverInfo.lastName}
							       			{/*{momentUtils.moment(props.check.resolveInfo.resolverInfo.date).fromNow()}*/}
										</Typography>
									</Box>
								</Grid>  : ''
							}
						</Grid>
					</CardActions>
					{noteTpl}
					{toggleComment ?
						<Note note={{text: ''}} handleSubmitNote={handleSubmitNote}/>:''
					}

				</Grid>

			{/*</CardActionArea>*/}
		</Card>
	</Box>;
}

export default CheckComponent
