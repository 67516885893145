import * as React from "react";
import * as _ from "lodash";
import {Typography, Grid, Box, Divider} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useState} from "react";
import {JumioData,JumioKYXData} from "./Jumio.component";

const useStyles = makeStyles(() => ({
    green: {
        borderLeft: 'solid 3px #99cc33',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },
    yellow: {
        borderLeft: 'solid 3px #ffcc00',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },red: {
        borderLeft: 'solid 3px #ff0000',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },black: {
        borderLeft: 'solid 3px #000000',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    }
}));

export type Breakdown = {
    result:"clear" | "consider",
    subResult?: "clear" |"caution" | "suspected" | "rejected",
    breakdown: {[key:string]:Breakdown},
    properties:{
        [key:string]: any
    }

}
export type Report = Breakdown & {name:string, id:string}

export type OnfidoCheckResult = {
    check: any,
    reports:Report[]
}


const colorCode = {
    "clear":"green",
    "caution":"yellow",
    "consider":"yellow",
    "suspected":"yellow",
    "rejected":"yellow"
}

function BreakDownComponent(props){
    const classes = useStyles();
    const [showCascade,setShowCascade] = useState(false);

    const {name,result,subResult,breakdown} = props;
    let breakDownCascade;
    if (breakdown && Object.keys(breakdown).length >0){
        breakDownCascade =
            Object.keys(breakdown).map(name=>{
                return <BreakDownComponent name={name} {...breakdown[name]} />
            })

    }else{
        breakDownCascade = null;
    }

    const onclick = evt=>{
        evt.preventDefault();
        evt.stopPropagation();
        setShowCascade(!showCascade)
    };
    const img = <Typography variant={"body2"}><a onClick={onclick}>{breakDownCascade? (showCascade?'Hide':'Show'):'----'}</a></Typography>
    const color = colorCode[subResult || result];

    return <Grid>
        <Grid container={true} direction={"row"} justifyContent={"space-between"} className={classes[color]}>
            <Box flexGrow={8}> <Typography variant={"body2"}> {name}</Typography></Box>
            <Box flexGrow={1}><Typography variant={"body2"}> <b>{subResult || result}</b></Typography></Box>
            <Box flexGrow={1}>{img}</Box>
        </Grid>
        {showCascade && breakDownCascade}


    </Grid>

}

export function OnfidoProvider(props){
    const checkResult: OnfidoCheckResult = props.check.metaData?.onfido_check_result;

    const documentReport = checkResult?.reports.find(r=>r.name ==='document')
    const fsReport =  checkResult?.reports.find(r=>r.name ==='facial_similarity_photo')


    const dsResult = documentReport?.subResult || documentReport?.result;
    const fsResult = fsReport?.subResult || fsReport?.result

    const checkUri = checkResult?.check?.resultsUri
    const checkDownloadUri = checkResult?.check?.downloadUri

    return {dsResult,fsResult,checkUri,checkDownloadUri}
}


export function OnfidoCheckResultComponent(props) {
    const classes = useStyles();
    const {dsResult,fsResult,checkUri,checkDownloadUri} = OnfidoProvider(props)

    const gotoReport = evt=>{
        evt.preventDefault();
        evt.stopPropagation();

    }

    const GotoHref =()=> <>Please find the detailed report <a href={checkUri} target={"_blank"}>here</a></>

    if (dsResult == 'clear' && fsResult == 'clear'){
        return (
            <Grid>
                <Typography className={`${classes.green}`} variant="body2">ID Document and Facial similarity report of applicant is in status <b>CLEAR</b>.<GotoHref/></Typography>
                {/*<BreakDownComponent {...fsReport} />
                <BreakDownComponent {...documentReport} />*/}
            </Grid>
        );
    }else{
        const fsStat = fsResult?.toUpperCase()
        const fsColor = fsResult && colorCode[fsResult] || "black"

        const fsColorIndex = fsResult && Object.keys(colorCode).indexOf(fsResult) || -1;



        const dsStat = dsResult?.toUpperCase()
        const dsColor = dsResult && colorCode[dsResult] || "black"
        const dsColorIndex = dsResult && Object.keys(colorCode).indexOf(dsResult) || -1;

        const colorIndex = Math.max(fsColorIndex,dsColorIndex);
        const color = Object.values(colorCode)[colorIndex];

        const fsm = <>
            Facial similarity report of applicant is in status <b>{fsStat}</b>.
            {/*<BreakDownComponent {...fsReport} />*/}
        </>

        const dsm = <>
            ID Document report of applicant is in status <b>{dsStat}</b>.
            {/*<BreakDownComponent {...documentReport} />*/}
        </>
        return <Grid>
            <Typography className={classes[color]} variant="body2">{fsm}<br/>{dsm}<br/><GotoHref/></Typography>
        </Grid>
    }


}

export function JumioKYXCheckResultComponent(props){
    const classes = useStyles();
    const checkResult: JumioKYXData = props.check.metaData;
    if (checkResult){
        const {capabilities,decision} = checkResult;
        const topLevelDecision = decision.type;
        const score = checkResult.decision.risk?.score;

        const notPassedCapabilities:JumioKYXData['capabilities'] = _.pickBy(capabilities, (va,k)=>va.some(v=>v.decision.type != "PASSED" && v.decision.type != "NOT_EXECUTED"))
        const notPassedCapabilitiesList = _.reduce(notPassedCapabilities, (acc,va,k)=>{
            va.filter(v=>v.decision.type != "PASSED")
                .forEach(v=>{
                    const type = v.decision.type;
                    const label = v.decision.details.label;
                    const risk = v.decision.risk?.score;
                    acc.push([k,type,label,risk]);
            });
            return acc
        }, [] as [string,string,string,number?][]);

        const notPassedCapabilitiesCmp = notPassedCapabilitiesList.length === 0 ? <>/</>:
            <Grid>
                <Divider/>
                <ul>
                    {notPassedCapabilitiesList.map(([k,type,label,risk])=>{

                        return <li key={k}>
                            <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                <Typography variant={"body2"}>
                                    {k}
                                </Typography></Grid>
                                <Grid item>
                                <Typography variant={"body2"}>
                                    {label}({type}) {risk && ` score:${risk}`}
                                </Typography></Grid>
                            </Grid>
                        </li>
                    })}
                </ul>

            </Grid>
        if (!topLevelDecision){
            return <Grid>
                <Typography className={classes.red} variant="body2">Report associated with the check does not contain decision info</Typography>
            </Grid>
        }
        let color,message;

        if (topLevelDecision == "WARNING"){
            color = classes.yellow
            message = `ID verification workflow passed with warnings (score ${score})`
        }else if (topLevelDecision == "REJECTED") {
            color = classes.red
            message = `ID verification workflow failed (score ${score})`
        }else{
            color = classes.green
            message = `ID verification workflow passed (score ${score})`
        }

        let notPassedCapabilitiesComp = <></>


        return <Grid>
            <Typography className={color} variant="body2">{message}</Typography>
            {notPassedCapabilitiesCmp}
        </Grid>

    }else{
        return <Grid>
            <Typography className={classes.red} variant="body2">No report is associated with the check</Typography>
        </Grid>
    }
}

export function JumioCheckResultComponent(props){
    const classes = useStyles();
    const checkResult: JumioData = props.check.metaData;
    if (checkResult){
        const vs = checkResult.verificationStatus
        const idv:{similarity:"MATCH" | "NO_MATCH" | "NOT_POSSIBLE", validity: boolean, reason: string} = checkResult.identityVerification && JSON.parse(checkResult.identityVerification)

        if (!idv){
            return <Grid>
                <Typography className={classes.red} variant="body2">Report associated with the check does not contain identity verification info</Typography>
            </Grid>
        }
        let color,message;
        if (vs != "APPROVED_VERIFIED"){
            color = classes.red
            message = `Not possible to verify the given document, reason: ${vs}`
        }else{
            if (idv.validity && idv.similarity === "MATCH"){
                color = classes.green
                message = `Document is valid and selfie matches the given document`
            }else{
                color = classes.yellow
                message = `Document is valid but similarity check returned ${idv.similarity} reason:${idv.reason}`
            }
        }
        return <Grid>
            <Typography className={color} variant="body2">{message}</Typography>
        </Grid>

    }else{
        return <Grid>
            <Typography className={classes.red} variant="body2">No report is associated with the check</Typography>
        </Grid>
    }
}