import * as _ from 'lodash';
import * as React from 'react';
import {Ref, useEffect, useLayoutEffect} from 'react';
import {useLayout} from 'sancus-client-common/dist/common/LayoutContext';
import {helperTextFromValidationAndAnnotation, ReadonlyDate} from "./generic_component";
import {Box, FormHelperText, Grid, makeStyles, Typography} from '@material-ui/core';
import {IndexDecoratorFormValidationUtilFactory} from 'sancus-client-common/dist/utils/formValidation.util';
import {useFormContext} from 'react-hook-form';
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {Moment} from "moment";
import {useIntl} from "react-intl";

const momentUtils = new MomentUtils();

const hasPendingConstraint = (constraints: any): boolean => {
    return Array.isArray(constraints) && !!constraints.find(item => item.name === 'pending') || false;
};

const ReviewPendingField = (props: { label: string, readOnlyClasses }) => {
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}><Typography variant="body2" className={`${props.readOnlyClasses.readOnlyLabel}`}>{props.label}:</Typography></Grid>
            <Grid item xs={6}><Typography variant="body2">Pending</Typography></Grid>
        </Grid>
    );
}

/**
 * the custom date wrapper view.
 * @param props
 * @constructor
 */
export const CustomDate = (props: { elemRef:Ref<HTMLInputElement>,fv_value: any,
    _value: any,
    readOnlyClasses: any,
    getNotificationTemplate: any,
    validation,
    onValueChange(value),
    onBlur(value: any),
    extra_props:{[key:string]:string} | undefined}) => {
    const layout = useLayout();
    const intl = useIntl();
    const parentPath = props.fv_value.path && props.fv_value.path.lastIndexOf('.') !== -1 ? props.fv_value.path.substring(0,props.fv_value.path.lastIndexOf('.')) : undefined
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.fv_value.index,parentPath,intl);
    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly;
    const required = props.validation && props.validation.required;
    const methods = useFormContext();
    const { errors } = methods;

    let format = props.extra_props?.format || "DD/MM/yyyy";
    const picker_type = props.extra_props?.picker_type || "date";

    const useStyles = makeStyles(theme=>({
        picker:{

        }
    }))

    const _orientation = picker_type !== 'datetime'?"landscape":"portrait"

    const [orientation,setOrientation] = React.useState<"landscape"|"portrait"|undefined>()

    const resizeListener = (evt)=>{
        const newOrientation = window.innerWidth < 600?"portrait":_orientation
        console.debug('resizeListener',window.innerWidth,orientation,newOrientation,_orientation);
        if (orientation !== newOrientation){
            setTimeout(()=>setOrientation(newOrientation))
        }
    };

    const throttled = _.throttle(resizeListener, 500);


    useLayoutEffect(()=>{
        if (layout === 'default') {
            if (window.innerWidth < 600 && orientation === "landscape") {
                setOrientation("portrait")
            }
            window.addEventListener('resize', resizeListener);
            return ()=>{
                window.removeEventListener('resize', resizeListener);
            }
        }
    },[])

    useEffect(()=>{
        console.debug('props.elemRef',props.elemRef)
    },[props.elemRef])
    const classes = useStyles();

    if(layout === 'default') {
        let ret;
        let PickerElement: typeof KeyboardDateTimePicker | typeof KeyboardTimePicker | typeof KeyboardDatePicker;

        switch(picker_type){
            case "datetime":
                format = "DD/MM/yyyy HH:mm:ss";
                PickerElement = KeyboardDateTimePicker
                break;
            case "time":
                format = "HH:mm:ss";
                PickerElement = KeyboardTimePicker
                break;
            default:
                PickerElement = KeyboardDatePicker
                break;
        }


        const StyledPicker = PickerElement //styled(PickerElement) will break keyboard functionality
        const _value = props._value ?
            (momentUtils.moment.utc(props._value, format).isValid() ?
                momentUtils.moment.utc(props._value, format) : (momentUtils.moment.utc(props._value).isValid() ? momentUtils.moment.utc(props._value) : null)) : null
        console.debug("Orientation:",orientation);
        const picker = <StyledPicker key={props.fv_value.id+':'+orientation} fullWidth
                                     variant="inline"
                                     inputVariant="filled"

                                      className = {classes.picker+' picker'}
                                      InputProps={{ /*disableUnderline: true*/}}
                                      orientation={orientation || _orientation}
                                      margin="normal"

                                      id={props.fv_value.id}
                                      name={props.fv_value.id}
                                      autoComplete={props.fv_value.path}
                                      label={<span dangerouslySetInnerHTML={{__html: (props.fv_value.label || props.fv_value.id)}}/>}
                                      format={format}
                                      value={_value }

                                      disableToolbar={isReadOnly}
                                      disabled={isReadOnly}
                                      onClick={event => {
                                          if (isReadOnly) {
                                              event.preventDefault();
                                              return false;
                                          }
                                      }}
                                      onChange={props.onValueChange}
                                      onBlur={props.onBlur}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}

                                      inputProps={{
                                          'data-section': props.fv_value.properties?.section,
                                          'cam-variable-name': props.fv_value.id,
                                          'cam-variable-type': props.fv_value.typeName,
                                          'autoComplete': props.fv_value.path,

                                      }}

                                      inputRef  = {props.elemRef}
                                      error = {formValidationUtil.hasFieldError(props.fv_value.id, errors)}
                                      helperText={helperTextFromValidationAndAnnotation(formValidationUtil.getFieldErrorMessage(props.fv_value.id, errors),props.extra_props)}
                                      required={required}

                                      autoOk={true}
                                      disablePast={props.extra_props?.disablePast === 'true'}
                                      disableFuture={props.extra_props?.disableFuture === 'true'}


        />
        if (props.extra_props?.xs){
            const xs = parseInt(props.extra_props?.xs) as 6|12
            ret = <Grid item xs={xs}>{picker}</Grid>
        }else{
            ret = picker;
        }
        return ret;
    }
    else if(layout === 'review' || layout === 'facing') {
        let valueAsCorrectString;
        let valueAsMoment: Moment | null = momentUtils.moment.utc(props._value,format);
        if (props._value) {
            if (valueAsMoment.isValid()) {
                valueAsCorrectString = props._value;
            }else{
                //Could be the extended form - noew we need to add time displacement
                valueAsMoment = momentUtils.moment.utc(props._value)
                if (valueAsMoment.isValid()){
                    valueAsCorrectString = valueAsMoment.local().format(format)
                }else{
                    valueAsCorrectString = props._value;
                    valueAsMoment = null;
                }
            }
        }
        let ret;
        if(hasPendingConstraint(props.fv_value && props.fv_value.validationConstraints)) {
            ret = <ReviewPendingField label={props.fv_value.label || props.fv_value.id} readOnlyClasses={props.readOnlyClasses}/>
        }
        else {
            ret = <ReadonlyDate fv_value={props.fv_value} valueAsCorrectString={valueAsCorrectString} readOnlyClasses={props.readOnlyClasses} getNotificationTemplate={props.getNotificationTemplate} />
        }

        return (
            ret
        );
    }
    else {
        return (
            <div>could not render date</div>
        );
    }

};
