import { handleActions } from 'redux-actions';
import {CheckActions} from '../actions/checks';
import {ICheck} from "../../common/FormContainerBase";

/**
 * Checks related actions
 */
export const checksReducer = handleActions<ICheck[], any>(
	{
		[CheckActions.Type.SET_CHECKS]: (state, action) => {
			return action.payload;
		}
	},
	{} as any
);