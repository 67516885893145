import React, { useContext } from 'react';
import {Route,  Navigate} from 'react-router-dom';
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";


export const Private = ({children}) => {
    const {LoggedUserState} = useContext(LoggedUserContext);
    return LoggedUserState.isLoggedIn ? (
        children
    ) : (
        <Navigate to="/" replace/>
    )
}


