import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useNavigate, useLocation} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import {useAlert} from "../context/alert/alertContext";
import {useForgotPasswordService} from "../context/users/forgotPassword.service";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(8),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonTypo: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));

const ForgotPasswordEnterOtp = () => {
    console.log("ForgotPasswordEnterOtp")
    const classes = useStyles();
    const history = useNavigate();
    const {state} = useLocation();
    const {showAlert} = useAlert();
    const service = useForgotPasswordService();

    const flow = state && state.flow || null;
    const email = state && state.email || '';

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [retry, setRetry] = useState(false);

    const handleRetryFlow = () => {
        history('/forgotPassword', {state:{flow, email}});
    };

    const handleResendOtp = async (e) => {
        e.preventDefault();
        try {
            const serverResponse = await service.challengeUserOtp({email});
            setOtpError(null);
            setRetry(false);
            showAlert(`A password notification has been sent to ${email}.`, 'success');
        } catch (e) {
            console.error(e);
            showAlert(`Unexpected error: ${e.message}`, 'error');
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.debug(`try validate otp with otp`);
            const passwordPolicy = await service.validateOtp({otp});
            if (passwordPolicy) {
                history('/resetPassword', {state:{otp, flow,passwordPolicy}});
            } else {
                showAlert('invalid otp', 'success');
            }
        } catch (e) {
            if (e.response && e.response.status === 401) {
                const {code, message, triesLeft} = e.response.data;
                if (code === 'otp_invalid') {
                    setOtpError(message);
                } else if (code === 'otp_expired') {
                    setOtpError(message);
                    setRetry(true);
                } else {
                    setOtpError(message);
                }
            } else if (e.response && e.response.status === 400 && e.response.data.otp){
                setOtpError(e.response.data.otp);
            } else {
                showAlert(`Unexpected error: ${e.message}`, 'error');
            }
        }
    };

    return (
        <Container component="div" maxWidth="xs">
            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'} height={60}></img>
                <Box p={2}>
                <Typography variant="h5">
                    {flow && flow === "activateAccount" ? "Activate account" : "Forgot password"}
                </Typography>
                </Box>
                <Typography variant="subtitle2">
                    A password notification has been sent to your {email}, enter OTP.
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={!!otpError}
                        value={otp}
                        variant="outlined"
                        margin="normal"
                        id="otp"
                        label="Otp"
                        fullWidth
                        required
                        disabled={retry}
                        helperText={otpError ? (
                            <>
                                {otpError} {retry && <>
                                    <Link component="button" variant="inherit" color="error" underline="always" style={{marginLeft: '10px'}}
                                          onClick={handleResendOtp}>Resend OTP to {email}</Link>
                                </>}
                            </>) : ""}
                        onChange={(evt) => {
                            setOtp(evt.target.value);
                        }}
                    />
                    <Grid item container justifyContent="space-between" spacing={1}>


                        <Grid item xs>
                            <Button
                                fullWidth
                                color={'primary'}
                                onClick={handleRetryFlow}
                                variant="contained"
                            >
                                Back
                            </Button>
                        </Grid>

                        <Grid item xs>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={retry}
                                    onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default ForgotPasswordEnterOtp;
