import React from 'react';
import {ICheck} from "sancus-client-common/dist";
import Grid from "@material-ui/core/Grid";
import {Dialog, DialogContent, DialogTitle, Divider, Link, Typography} from "@material-ui/core";
import {useStyles} from "./Check.component";

interface MembercheckCheckUserEntityRoles {
    title: string;
    segment: string;
    type: string;
    status: string;
    country: string;
    from: string;
    to: string;
}

interface UserEntityOfficialList {
    keyword: string;
    category: string;
    country: string;
    description: string;
    origin: string;
    measures: string;
    types: string;
    current: boolean;
}

interface MembercheckCheckUserEntity {
    categories: string;
    gender: string | null;
    deceased: 'YES' | 'NO';
    deceasedDate: string | null;
    dateOfBirth: string | null;
    placeOfBirth: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    title: string | null;
    matchScore: number;
    nationalities: string[];
    furtherInfo: string;
    roles: MembercheckCheckUserEntityRoles[];
    officialLists: UserEntityOfficialList[];
}

interface MembercheckCheckCorporateEntity {
    matchScore: number;
    categories: string | null;
    name: string | null;
    businessDescription: string | null;
    website: string | null;
    locations: {
        country: string;
        city: string;
        address: string;
        type: string;
    }[];
    identifiers: {
        type: string;
        value: string;
    }[];
    sources: {
        categories: string;
        url: string;
    }[];
}

interface MembercheckCheck {
    error?: any;
    corporateEntities: MembercheckCheckCorporateEntity[];
    userEntities: MembercheckCheckUserEntity[];
    provider: 'membercheck';
    query: {
        type: 'org' | 'user';
        fullName?: string;
        orgName?: string;
    };
}

export const MemberCheckCorporateEntityReport = (props: {corp: MembercheckCheckCorporateEntity}) => {
    return (
        <Grid container direction={'column'} wrap={'nowrap'} >
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'}>
                    <Grid item xs={4}>
                        <Typography >{`Match score:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.corp.matchScore}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Name:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.corp.name}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'}>
                    <Grid item xs={4}>
                        <Typography >{`Business description:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.corp.businessDescription}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'}>
                    <Grid item xs={4}>
                        <Typography >{`website:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.corp.website}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'}>
                    <Grid item xs={4}>
                        <Typography >{`Categories:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.corp.categories}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'} wrap={'nowrap'} >
                    <Grid item>
                        <Typography >{`Locations:`}</Typography>
                    </Grid>
                    {
                        Array.isArray(props.corp.locations) && props.corp.locations.map((location, index) => {
                            return (
                                <Grid item key={index}>
                                    <Grid container direction={'column'} wrap={'nowrap'}  style={{ padding: '6px' }}>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Country:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{location.country}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`City:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{location.city}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Address:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{location.address}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Type:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{location.type}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'} wrap={'nowrap'} >
                    <Grid item>
                        <Typography >{`Identifiers:`}</Typography>
                    </Grid>
                    {
                        Array.isArray(props.corp.identifiers) && props.corp.identifiers.map((identifier, index) => {
                            return (
                                <Grid item key={index}>
                                    <Grid container direction={'row'} wrap={'nowrap'} >
                                        <Grid item xs={4}>
                                            <Typography >{`Type:`}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>{identifier.type}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} wrap={'nowrap'} >
                                        <Grid item xs={4}>
                                            <Typography >{`Value:`}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>{identifier.value}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );

                        })
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'} wrap={'nowrap'} >
                    <Grid item>
                        <Typography >{`Sources:`}</Typography>
                    </Grid>
                    {
                        Array.isArray(props.corp.sources) && props.corp.sources.map((source, index) => {
                            return (
                                <Grid item key={index}>
                                    <Grid container direction={'row'} wrap={'nowrap'} >
                                        <Grid item xs={4}>
                                            <Typography >{`Category:`}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>{source.categories}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} wrap={'nowrap'} >
                                        <Grid item xs={4}>
                                            <Typography >{`URL:`}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography style={{ wordBreak: 'break-all' }}>{source.url}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export const MemberCheckUserEntityReport = (props: {user: MembercheckCheckUserEntity}) => {
    return (
        <Grid container direction={'column'} wrap={'nowrap'}  >
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Match score:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.matchScore}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`First name:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.firstName || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Middle name:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.middleName || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Last name:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.lastName || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Title:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{ wordBreak: 'break-all' }}>{props.user.title || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Gender:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.gender || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Deceased:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.deceased !== 'NO' ? props.user.deceasedDate : 'NO'}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Place of birth:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.placeOfBirth || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Date of birth:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.dateOfBirth || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Nationalities:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{Array.isArray(props.user.nationalities) && props.user.nationalities.map(n => n).join(',') || ''}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'} wrap={'nowrap'} >
                    <Grid item>
                        <Typography >{`Roles:`}</Typography>
                    </Grid>
                    {
                        Array.isArray(props.user.roles) && props.user.roles.map((role, index) => {
                            return (
                                <Grid item key={index}>
                                    <Grid container direction={'column'}  wrap={'nowrap'}>
                                        <Grid item>
                                            <Divider/>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Title:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Segment:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{ wordBreak: 'break-all' }}>{role.segment}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Type:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.type}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Status:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.status}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Country:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.country}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`From:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.from}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`To:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{role.to}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider/>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Categories:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.categories}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} wrap={'nowrap'} >
                    <Grid item xs={4}>
                        <Typography >{`Further info:`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{props.user.furtherInfo}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'} wrap={'nowrap'}  style={{ padding: '6px' }}>
                    <Grid item>
                        <Typography >{`Official lists:`}</Typography>
                    </Grid>
                    {
                        Array.isArray(props.user.officialLists) && props.user.officialLists.map((ol, index) => {
                            return (
                                <Grid item key={index}>
                                    <Grid container direction={'column'}  wrap={'nowrap'}>
                                        <Grid item>
                                            <Divider/>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Category:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.category}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Country:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.country}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Description:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.description}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Origin:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.origin}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Measures:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.measures}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Types:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{ol.types}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} wrap={'nowrap'} >
                                                <Grid item xs={4}>
                                                    <Typography >{`Is current:`}</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>{`${(ol.current)}`}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider/>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export const MemberCheckReport = (props: {check: MembercheckCheck}) => {
    console.debug(props);

    if (props.check.error){
        return <Grid container direction={'column'} wrap={'nowrap'}>
            <Grid item>
                <Typography>{`Membercheck failed due to internal error `}</Typography>
            </Grid>
        </Grid>
    }

    return (
        <Grid container direction={'column'} wrap={'nowrap'}>
            <Grid item>
                <Typography>Results:</Typography>
            </Grid>
            <Grid item>
                <Divider/>
            </Grid>
            <Grid item style={{ overflowY: 'auto', overflowX:'hidden'}}>
                {
                    <Grid container direction={'column'} wrap={'nowrap'} ><ul>
                        {
                            props.check.userEntities.map((entity, index) => {
                                return (
                                    <li><Grid item key={index}>
                                        <MemberCheckUserEntityReport user={entity} />
                                    </Grid></li>
                                );
                            })
                        }
                    </ul></Grid>
                }
                {
                    Array.isArray(props.check.corporateEntities) && <Grid container direction={'column'} wrap={'nowrap'} ><ul>
                        {
                            props.check.corporateEntities.map((entity, index) => {
                                return (
                                    <li><Grid item key={index}>
                                        <MemberCheckCorporateEntityReport corp={entity} />
                                    </Grid></li>
                                );
                            })
                        }
                    </ul></Grid>
                }
            </Grid>
        </Grid>
    );
};

interface MembercheckPepProps {
    check: ICheck;
}

export const MembercheckPep = (props: MembercheckPepProps) => {
    console.debug(props);
    const classes = useStyles();
    let statusClassName = classes.yellow;
    const {status,metaData: check} = props.check;

    const [selectedEntity, setSelectedEntity] = React.useState<MembercheckCheckUserEntity | MembercheckCheckCorporateEntity | null>(null);

    if (check.error){
        statusClassName = classes.red;
        return <Grid container direction={'column'} wrap={'nowrap'} className={statusClassName}>
            <Typography>{`Membercheck failed due to internal error `}</Typography>
        </Grid>
    }else{
        statusClassName = status == 1 ? classes.green : classes.yellow;
    }

    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={statusClassName}>
            <Dialog open={!!selectedEntity} onClose={() => setSelectedEntity(null)}>
                <DialogTitle>
                    {
                        check.query.type === 'user' && selectedEntity
                            ? `${selectedEntity ? `${(selectedEntity as MembercheckCheckUserEntity).firstName || ''} ${(selectedEntity as MembercheckCheckUserEntity).middleName || ''} ${(selectedEntity as MembercheckCheckUserEntity).lastName || ''}` : ''}`
                            : selectedEntity ? (selectedEntity as MembercheckCheckCorporateEntity).name || '' : ''
                    }
                </DialogTitle>
                <DialogContent>
                    {
                        check.query.type === 'user'
                            ? !!selectedEntity && <MemberCheckUserEntityReport user={selectedEntity as MembercheckCheckUserEntity} />
                            : !!selectedEntity && <MemberCheckCorporateEntityReport corp={selectedEntity as MembercheckCheckCorporateEntity}/>
                    }
                </DialogContent>
            </Dialog>
            <Grid item>
                <Typography variant="body2">{`Found ${check.corporateEntities && check.corporateEntities.length > 0 ? check.corporateEntities.length : check.userEntities.length > 0 ? check.userEntities.length : 0} matches for ${check.query.type === 'user' ? check.query.fullName : check.query.orgName}`}</Typography>
            </Grid>

            {
                check.userEntities.map((entity: MembercheckCheckUserEntity, index) => {
                    return (
                        <Grid item key={`user-${index}`}>
                            <Link href={'#'} onClick={evt => {
                                evt.preventDefault();
                                setSelectedEntity(entity)
                            }} variant="body2"
                              style={{color: 'black'}}>
                                <Typography variant="body2" style={{ fontWeight: 700 }}>{`${entity.firstName || ''} ${entity.middleName || ''} ${entity.lastName || ''}`}</Typography>
                            </Link>
                        </Grid>
                    );
                })
            }
            {
                check.corporateEntities.map((entity: MembercheckCheckCorporateEntity , index) => {
                    return (
                            <Grid item key={`corp-${index}`}>
                            <Link href={'#'} onClick={evt => {
                                evt.preventDefault()
                                setSelectedEntity(entity)
                            }} variant="body2"
                               style={{color: 'black'}}>
                                <Typography variant="body2" style={{ fontWeight: 700 }}>{`${entity.name || ''}`}</Typography>
                            </Link>
                        </Grid>

                    );
                })
            }
        </Grid>
    );
};