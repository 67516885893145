import * as React from "react";
import { ICheck } from "sancus-client-common/dist/common/FormContainerBase";
import { Grid, Typography, Box, Link } from "@material-ui/core";
import {useStyles} from "./pepComponent";

interface AsiaVerifyPepProps {
    check: ICheck;
}

export const AsiaVerifyPep = (props: AsiaVerifyPepProps) => {
    const classes = useStyles();
    console.debug('asia verify pep props: ', props);

    const { status, message, metaData } = props.check;
    const metadata: { entity?: { firstName?: string, lastName?: string, categories?: string[] }} | undefined = props.check.metaData;
    const statusClassName = status == 1 ? classes.green : classes.yellow

    let messageContent =  '';

    if (metadata && metadata.entity){
        if(metadata.entity.firstName || metadata.entity.lastName) {
            messageContent = ` for ${metadata.entity.firstName} ${metadata.entity.lastName}`
        }
    }else{
        const query = metaData.query;
        const queryAsString = query.type == 'org'?query.orgName: query.fullName ?? [query.firstName,query.lastName].filter(o=>o!=null).join(' ')
        messageContent = ` for ${queryAsString}`
    }

    let messageElement;
    if (status == 1) {
        messageElement = <>PEP Screening report {messageContent} is <b>CLEAR</b></>
    } else {
        messageElement = `Potential matches found ${messageContent}:`
    }


    return (
        <Grid container className={statusClassName}>
            <Grid item>
                <Typography variant="body2">{ messageElement }</Typography>
                {
                    metadata && metadata.entity && Array.isArray(metadata.entity.categories) && metadata.entity.categories.map((pepCategory: string, index: number) => {
                        return (
                            <Box mt={1} key={`pep:${pepCategory}${index}`}>
                                <Link variant="body2" style={{color: 'black'}}>
                                    <b>{ pepCategory }</b>
                                </Link>
                            </Box>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
};