import {CustomComponentsBaseParams} from "./generic_component_base";
import * as React from "react";
import {useContext, useEffect, useMemo, useState} from "react";

import {SancusConditionEvaluator} from "./conditionEvaluator.service";
import contextPath from "../common/contextPath";
import {ValueChangeCallback} from "./FormContainerBase";
import {getStore} from "../store";
import {IntlShape, useIntl} from "react-intl";
import {FormContainerContext} from "./FormContainerContext";
import { FieldDefaultsApiService } from "./fieldDefaultsService";
import {fidesConfigApi} from "./fidesConfig.service";
import DesignModeContext from "./DesignModeContext";
import {FidesFieldConfig} from "../CustomerData";


interface ConditionalChoice {
    condition: string;
    choices_str?: string;
    choices?: string[];
}

export type SelectionParams = CustomComponentsBaseParams & {
    header?:string,
    choices_str?:string,
    choices?:(string | Option)[]
    conditional_choices? : string | ConditionalChoice[],
    contextEvaluator: SancusConditionEvaluator,

    prepareValue?:((v:any)=>any),
    transformValueToChoice?:((v:any)=>string),
    transformChoiceToValue?:((c:string)=>any),
    priorityValues?:string,
    allowedValues?:string,
} & {
    diagnose?:boolean | 'info' | 'debug' | 'warn' | 'error'
}

export type MultiSelectionParams = SelectionParams & {
    freeSolo?:boolean,
    multiple?:boolean,
}

export interface Option {
    value: string;
    id?:string,
    label?: string;
    disabled?: boolean;
}

type RegistryItem = React.FunctionComponent & {requiredFN?:(value:any)=>boolean | string}
class Registry{

    private _registry:{[key:string]:RegistryItem} = {};
    constructor() {
        this._registry = {};
    }
    register(name:string,component:RegistryItem){
        registry[name] = component;
    }

    get(name:string):RegistryItem{
        return registry[name];
    }
}

export const registry  = new Registry();

export function choicesFrom(params:SelectionParams, intl: IntlShape): Option[] {
    const designMode = useContext(DesignModeContext);
    const {processDefKey,formDefId,formReference} = useContext(FormContainerContext)

    if (params.choices == null && params.choices_str == null){
        console.log("Problem in choices");
        return [];
    }

    let choicesFromString;

    let choices_str = params.choices_str;

    function checkAndEvalDynamicChoices(choices: any){
        if (choices && choices.dynamic_choices) {
           const ret = params.contextEvaluator && params.contextEvaluator.eval(choices.dynamic_choices);
           return ret;
        }
        return choices;
    }

    if (choices_str){
        // TODO get from store
        try{
            let _c =  JSON.parse(choices_str);
            _c = checkAndEvalDynamicChoices(_c);

            choicesFromString = _c && _c.map(choice=>{
                if (typeof choice === 'string'){
                    choice = {value:choice,label:choice};
                }

                const emptyLabel = choice.value == null || choice.value === '';
                let label;
                const lid= `${processDefKey}.${formDefId}.${params.id}.choice.${emptyLabel ? '__empty__':choice.value }`
                label = intl.formatMessage({
                    id:lid,
                    defaultMessage:''+(choice.label ?? choice.value ?? lid),
                    description:`Label for choice:${choice.id} in ${formDefId}`
                });
                if (emptyLabel && label && label.endsWith('__empty__')){
                    label = '';
                }

                return {value: choice.value, label};
            }) || [];
        }catch (e){
            if (designMode){
                return [{value:'__design_mode__',label:choices_str,disabled:true}]
            }else {
                throw e;
            }
        }
    }

    let choices = params.choices;
    choices = checkAndEvalDynamicChoices(choices);

    return ((choices || choicesFromString).map(c=>{

        if (typeof c === 'string'){
            return {value:c as string}
        }else {
            return c as Option;
        }
    }) ?? choicesFromString);

}

/**
 * Extract options from selection param properties.
 * Property parsing priority:
 * 1. conditional_choices
 * Json parse the json stringified conditional_choices value and return the first json stringified value of the
 * true evaluated expression of the choice condition property using the contextEvaluator.
 * 2. choices_str | choices
 * Json parse and return the choices_str json stringified value.
 * 3. empty.
 */
export const parseSelectOptions = (props: SelectionParams, intl: IntlShape): Option[] => {
    const {processDefKey,formDefId,formReference} = useContext(FormContainerContext)
    if(props.conditional_choices) {
        try {
            const conditionalChoices: ConditionalChoice[] = typeof props.conditional_choices === 'string' && JSON.parse(props.conditional_choices) || props.conditional_choices as ConditionalChoice[];
            const choice = conditionalChoices.find((conditionalChoice: ConditionalChoice): boolean => {
                return props.contextEvaluator && !!props.contextEvaluator.eval(conditionalChoice.condition);
            });



            if (choice) {

                choice.choices = (choice as any).choices.map((choice: string | {value: string; label: string;}) => {
                    const _value = typeof choice === 'string' ? choice : choice.value;
                    const _label = typeof choice === 'string' ? choice : choice.label;
                    const emptyLabel = !_value;
                    let label;
                    if (typeof choice === 'string'){
                        label = _label;
                    }else {
                        label = intl.formatMessage({
                            id: `${processDefKey}.${formDefId}.${props.id}.choice.${choice.value || '__empty__'}`,
                            defaultMessage: choice.label || choice.value,
                            description: `Choice for ${props.id}.choice.${choice.value} in ${formDefId}`,
                        });
                    }
                    if (emptyLabel && label && label.endsWith('__empty__')){
                        label = '';
                    }
                    return {
                        value:_value,
                        label
                    }
                });
            }

            return choice
                ? choicesFrom({
                    ... props,
                    choices: choice.choices,
                    choices_str: choice.choices_str
                }, intl)
                : [];
        }
        catch (e){
            console.error(e);
            return [];
        }
    }
    else if(props.choices_str || props.choices) {
        return choicesFrom(props, intl);
    }
    else {
        return [];
    }
};

const orderChoices = (choices, order: string[]) => {
    choices = [...choices];
    order = order.reverse();

    order.forEach(option => {
        const findIndex = choices.findIndex(c => c.value === option);
        if (findIndex > -1) {
            choices.splice(0, 0, choices.splice(findIndex, 1)[0]);
        }
    })
    return choices;
}

export const OrgPrefixSelectorFactory= (Option: (params: SelectionParams) => any, fieldDefaultsApiService: FieldDefaultsApiService) =>(params:SelectionParams) =>{
    const intl = useIntl();
    const [ choices, setChoices ] = useState<Option[]>(
        (params as any).choices_str
            ? parseSelectOptions(params, intl)
            : []
    );

    const parseChoicesAndApplyDefaults = async () => {
        try {
            const parsedChoices = choices;
            const config = await fieldDefaultsApiService.fetchConfig();
            const priorityValues = (params.priorityValues && params.priorityValues.split(',').map(s=>s.trim())) || config.orgPrefix.priorityValues;
            if(config.orgPrefix.priorityValues.length > 0) {
                setChoices(orderChoices(parsedChoices, [...priorityValues]));
            }
            else {
                setChoices(parsedChoices);
            }
        }
        catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        parseChoicesAndApplyDefaults();
    }, []);

    return <Option {...params} choices_str={undefined}
                   choices={choices} />
}

export const CurrencySelectorFactory= (Option: (params: SelectionParams) => any, fieldDefaultsApiService: FieldDefaultsApiService) =>(params:SelectionParams) =>{
    const [ choices, setChoices ] = useState([]);

    const parseChoicesAndApplyDefaults = async () => {
        const parsedChoices = require('../constants/currency.dataset').default;

        try {
            const config = await fieldDefaultsApiService.fetchConfig();
            const priorityValues = (params.priorityValues && params.priorityValues.split(',').map(s=>s.trim())) || config.currency.priorityValues;
            if(config.currency.priorityValues.length > 0) {
                setChoices(orderChoices(parsedChoices, [...priorityValues]));
            }
            else {
                setChoices(parsedChoices);
            }
        }
        catch (e) {
            console.error(e);
            //fallback for error: do not apply defaults
            setChoices(parsedChoices);
        }
    };

    useEffect(() => {
        parseChoicesAndApplyDefaults();
    }, []);

    return <Option {...params}
        choices={choices} />
}

export const CountrySelectorFactory = (Option: (params: SelectionParams) => any, fieldDefaultsApiService: FieldDefaultsApiService) =>(params:SelectionParams) =>{
    const intl = useIntl();

    const [ choices, setChoices ] = useState<(Option)[]>([]);

    const [fidesConfig,setFidesConfig] = useState<{config,fields}|null>(null);

    const parseChoicesAndApplyDefaults = () => {

        function checkAndEvalDynamicChoices(choices: any){
            if (choices && choices.dynamic_choices) {
                const ret = params.contextEvaluator && params.contextEvaluator.eval(choices.dynamic_choices);
                return ret;
            }
            return choices;
        }

        const {config} = fidesConfig || {}
        let countryPresets: FidesFieldConfig | undefined
        if (config) {
            //Take preset from usual vocabulary places
            countryPresets = config && (config['CorCD']?.fields['CorCD_RegCountry'] ?? config['Indv']?.fields['Indv_Nationality']);
            //Find at least one field that contains a country like preset
            if (!countryPresets) {
                countryPresets = Object.keys(config).flatMap((key: string) => {
                    const section = config[key];
                    return Object.keys(section.fields).map((fieldKey: string) => section.fields[fieldKey]);
                }).find(field => {
                    return field.presets && field.presets.find(preset => preset.key === 'GR')
                })
            }
        }

        const parsedChoices = countryPresets?.presets!.map(({key,value})=>{

            const label = intl.formatMessage({
                id:`country.${key}`,
                defaultMessage:value,
                description:value
            });
            return {
                value: key,
                label
            };
        }) ?? [];
        parsedChoices.sort((a,b)=>{
            if (a.label == b.label){
                return 0;
            }else if (a.label < b.label){
                return -1;
            }else{
                return 1;
            }
        });

        try {
            const config = fidesConfig?.config;

            let allowedValues:string[] | undefined;
            if (params.allowedValues) {
                let _allowedValues = params.allowedValues;
                try {
                    _allowedValues = JSON.parse(_allowedValues);
                    allowedValues = checkAndEvalDynamicChoices(_allowedValues);
                } catch (e) {
                    allowedValues = _allowedValues.split(',').map(s => s.trim())
                }

            }else{
                allowedValues = config?.country?.allowedValues;
            }

            let priorityValues:string[] | undefined;
            if (params.priorityValues) {
                let _priorityValues = params.priorityValues
                try {
                    _priorityValues = JSON.parse(_priorityValues);
                    priorityValues = checkAndEvalDynamicChoices(_priorityValues);
                } catch (e) {
                    priorityValues = _priorityValues.split(',').map(s => s.trim())
                }
            }else{
                priorityValues = config?.country?.priorityValues;
            }

            const allowedChoices = allowedValues!= undefined && allowedValues.length > 0 ? parsedChoices.filter(choice =>
                allowedValues!.includes(choice.value) ||
                allowedValues!.includes("all") ||
                allowedValues!.includes("ALL") ||
                allowedValues!.includes("*")
            ) : parsedChoices;

            if(priorityValues && priorityValues.length > 0) {
                return orderChoices(allowedChoices, [...priorityValues]);
            }
            else {
                return allowedChoices;
            }
        }
        catch (e) {
            console.error(e);
            //fallback for error: do not apply defaults
            return parsedChoices;
        }
    };

    const new_choices = parseChoicesAndApplyDefaults();



    //If we don't calculate the diff and always set the choices we will get infinite rerender loop as old_choice is always different than new_choice
    const new_choice_set = new Set(new_choices?.map(c=>c.value) ?? []);
    const old_choice_set = new Set(choices?.map(c=>c.value) ?? []);

    const choice_diff = new_choice_set.size !== old_choice_set.size || new_choices?.some((c: { value: string; })=>!old_choice_set.has(c.value)) || choices?.some(c=>!new_choice_set.has(c.value));

    useEffect(() => {
        if (choice_diff) {
            setChoices(new_choices);
        }
    }, [choice_diff]);

    useEffect(()=>{
        fidesConfigApi.fetchConfig().then(({config,fields})=>{
            setFidesConfig({config,fields});
        });
    },[])
    return <Option {...params}
                           choices={choices}
    />
}
//DocuSign (exprot to separate module)
export type SigningCeremonyResult = 'signing_complete' | 'error' | {
    signingCeremonyResult: 'signing_complete',
    status: string
};

/**
 * The DocuSign field value.
 */
export interface DSFieldValue {
    /**
     * The api's embedded signing ceremony recepient view url.
     */
    viewUrl: string;

    /**
     * the api's envelope id.
     */
    envelopeId: string;

    /**
     * the api's sign document id.
     */
    signDocumentId: string;

    /**
     * the api's embedded signing ceremony result.
     */
    signingCeremonyResult?: SigningCeremonyResult;
}

/**
 * Validate the value property is of type DSFieldValue and return it else return a validation error with reason.
 * @param value
 */
export const extractValidatedValueProperty = (value: any): [DSFieldValue | null, {reason: string}?] => {
    if (value) {
        if(!value.viewUrl) {
            return [null, {
                reason: 'DocuSign field value viewUrl property is unset.'
            }];
        }
        if(!value.envelopeId) {
            return [null, {
                reason: 'DocuSign field value envelopeId property is unset.'
            }];
        }
        if(!value.signDocumentId) {
            return [null, {
                reason: 'DocuSign field value signDocumentId property is unset.'
            }];
        }
        return [{
            viewUrl: value.viewUrl,
            envelopeId: value.envelopeId,
            signDocumentId: value.signDocumentId,
            ... !!value.signingCeremonyResult ? {signingCeremonyResult: value.signingCeremonyResult}: {},
        }]
    }
    else {
        return [null, {
            reason: 'DocuSign field value is unset.'
        }];
    }
};

export interface DSEmbeddedSignatureCeremonyComponentProps {
    /**
     * The prepared docusign field value.
     */
    value: DSFieldValue;
    /**
     * field id.
     */
    id: string;
    /**
     * submit value callback.
     * @param id
     * @param value
     * @param submit
     */
    valueChangeCbk: ValueChangeCallback;

    usePopup?:boolean;
    type?:string;
    label?:string;
}
export interface DocuSignComponentProperties {
    /**
     * the ds field value.
     */
    value?: DSFieldValue;

    validation: {
        readOnly?: boolean,
    };

    /**
     * the readonly mode file field label.
     */
    label: string;

    /**
     * camunda field id.
     */
    id: 'docuSignField';

    /**
     * on submit value callback.
     */
    valueChangeCbk: ValueChangeCallback;
}

/**
 * Render the Docusign embedded signing ceremony iframe and handle iframe events for value submit or error handling.
 */
export function DSEmbeddedSignatureCeremonyComponentFactory(ViewCmp:React.FunctionComponent< {viewUrl:string,children?,type?:string,label?:string,usePopup?:boolean}>,
                                                            ErrorCmp:React.FunctionComponent<{text:string}>,
                                                            regFunc:(cbk)=>void,
                                                            deregFunc:(cbk)=>void,
                                                            SubmitProcessIndicationCmp:React.FunctionComponent
):React.FunctionComponent<DSEmbeddedSignatureCeremonyComponentProps> {
    console.debug("Inside DSEmbeddedSignatureCeremonyComponentFactory");
    return  (props: DSEmbeddedSignatureCeremonyComponentProps) =>{
        const {value, valueChangeCbk, id,usePopup,type,label} = props;
        const {viewUrl} = value;
        const [error, setError] = useState(null);

        const [formContainerError, setFormContainerError] = useState(false);

        const [showSubmitIndicator, setShowSubmitIndicator] = useState(false);

        console.debug("Inside DSEmbeddedSignatureCeremonyComponent viewUrl:",viewUrl);

        /**
         * 'ds-result-event' event handler.
         * Submit result value and continue the flow.
         * @param event
         */
        const submitOnSuccessDsResultEvent = (event) => {
            if (event && event.data && event.data.name === 'ds-result-event') {
                const dsResult = event.data.data as SigningCeremonyResult;
                setShowSubmitIndicator(true);
                if (dsResult === 'signing_complete' || (typeof dsResult === 'object' && dsResult.signingCeremonyResult === 'signing_complete')) {
                    setTimeout(() => {
                        let v;
                        if (typeof dsResult === 'string'){
                            v = {
                                ...value,
                                signingCeremonyResult: dsResult,
                            }
                        }else{
                            v = {
                                ...value,
                                ...dsResult
                            }
                        }

                        valueChangeCbk(id, v, true);
                    }, 3000);
                } else {
                    console.error('unsuccessful signing ceremony');
                    console.error(event.data);
                    setError(event.data);
                }
            }
        };

        useEffect(() => {
            if (!error) {
                regFunc(submitOnSuccessDsResultEvent)

                return () => {
                    deregFunc(submitOnSuccessDsResultEvent)
                }
            }
        }, [error]);

        const store = getStore();
        const state = store.getState();
        const _formContainerError = state.error
        const _inProgress= state.inProgress

        if (_formContainerError && !formContainerError){
            setFormContainerError(true)
        }

        if (error ) {
            return <ErrorCmp text={"An error occurred : unsuccessful signing ceremony"}/>;
        }else if (formContainerError){
            return <ErrorCmp text={"An error occurred after the document signing"}/>;
        }else if (showSubmitIndicator){
            return <SubmitProcessIndicationCmp />
        }else{
            return <ViewCmp viewUrl={viewUrl} usePopup={usePopup} type={type} label={label}/>
        }
    }


}

export interface DSDocumentPreviewComponentProps {
    id:string;
    /**
     * the file field label.
     */
    label: string;
    /**
     * the ds field value.
     */
    value: DSFieldValue;
}

/**
 * DocuSign signature document preview component.
 * Render a custom file field for the specified signed document.
 * @constructor
 * @param CustomFileField
 */
export const DSDocumentPreviewComponentFactory =(CustomFileField)=> (props: DSDocumentPreviewComponentProps) => {
    const { id, label, value } = props;
    const { envelopeId, signDocumentId } = value;

    const fValue = [{
        data: `${contextPath}docusign/document?envelopeId=${envelopeId}&signDocumentId=${signDocumentId}`,
        mimeType: "application/pdf",
        name: "signature.pdf",
        type: "file",
    }];
    return (
        <CustomFileField
            id={id}
            filesValue={ fValue }
            readOnly={true}
            label={ label }
            fullWidth={true}
            margin="normal"
            type="file"
            variant="filled"
        />
    );
};

export class OperandHolder{
    constructor(private operand:string,private argument: string,private argument_type?:string){
    }

    getOperand(){
        return this.operand;
    }

    setOperand(operand:string){
        this.operand = operand;
    }
    getArgument(){
        return this.argument;
    }

    setArgument(argument){
        this.argument = argument;
    }
    toString(){
        return this.operand+" "+this.argument;
    }

    postProcessGet(){
        return this.toString();
    }

    static from(value:any,argument_type?:string){

        if (value == null || value === "") {
            return null;
        }else if (typeof value === 'string'){
            const parts = value.split(" ");
            return new OperandHolder(parts[0],parts[1]);
        }else if (value instanceof OperandHolder){
            return value;
        }
    }

}

export class YesNoHolder{
    static NONE =new YesNoHolder(null);
    static YES = new YesNoHolder(true);
    static NO = new YesNoHolder(false);

    constructor(private _value:boolean | null){}
    toString(){
        if (this._value == null){
            return "";
        }else {
            return this._value ? "1" : "0";
        }
    }

    public postProcessGet(){
        return this._value;
    }

    static formString(value:string){
        if (value == null || value === "") {
            return null;
        }else if (value === "1" || value=="Yes") {
            return YesNoHolder.YES
        }else{
            return YesNoHolder.NO;
        }

    }
}

export type IDocuSignComponent = React.FunctionComponent<DocuSignComponentProperties>

/**
 * DocuSign wrapper component.
 * Validate the prepared DocuSignField value and render error view for invalid prepared field value
 * else render the ds embedded signing ceremony component when on non readonly mode else render the
 * document preview component.
 */
export const DocuSignComponentFactory = (DSDocumentPreviewComponent:React.FunctionComponent<DSDocumentPreviewComponentProps>,
                                         DSEmbeddedSignatureCeremonyComponent:React.FunctionComponent<DSEmbeddedSignatureCeremonyComponentProps>,
                                         ErrorCmp:JSX.Element) =>(props: DocuSignComponentProperties) => {


    console.debug("Inside DocuSignComponentFactory");
    const { valueChangeCbk, value, id, validation, label } = props;
    const { readOnly } = validation;

    //validate component properties.
    const valueValidation = extractValidatedValueProperty(value);

    // case invalid configuration.
    if(valueValidation[1] || !valueValidation[0]) {
        console.error(valueValidation[1]);
        console.error('invalid DS configuration');
        console.error(value);
        return ErrorCmp;
    }
    // case valid configuration.
    else {
        const validatedValue = valueValidation[0] as DSFieldValue
        if (readOnly) {
            return (
                <DSDocumentPreviewComponent
                    id={id}
                    label={ label }
                    value={ validatedValue }
                />
            );
        }
        else {
            return (
                <DSEmbeddedSignatureCeremonyComponent
                    value={ validatedValue }
                    valueChangeCbk={ valueChangeCbk }
                    id={ id }
                />
            );
        }
    }
};
