import * as React from 'react';
import * as $ from 'jquery';
import { useLayout } from 'sancus-client-common/dist/common/LayoutContext';
import {ReadonlyTextField, CustomTextField, FacingTextField, LabelWithTooltip} from "./generic_component";
import { TextFieldProps, Grid, Typography, Box, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { IndexDecoratorFormValidationUtilFactory } from 'sancus-client-common/dist/utils/formValidation.util';
import { useFormContext } from 'react-hook-form';
import {Ref, useLayoutEffect, useRef} from "react";
import {useIntl} from "react-intl";




export type CustomCheckBoxProps = {
    elemRef:Ref<HTMLInputElement>,
    fv_value: any,
    _value: any,
    readOnlyClasses: any,
    getNotificationTemplate: any,
    validation,
    onValueChange(evt),
    helperText?: string,
    tooltip?: string,
}

/**
 * the custom checkbox wrapper view.
 * @param props
 * @constructor
 */
export const CustomCheckboxFactory = (props: CustomCheckBoxProps & {labelPlacement?:'end' | 'start' | 'top' | 'bottom',Control:((params)=>JSX.Element)}) => {
    const {tooltip,helperText} = props

    const Control = props.Control;
    const layout = useLayout();
    const intl = useIntl();
    const parentPath = props.fv_value.path && props.fv_value.path.lastIndexOf('.') !== -1 ? props.fv_value.path.substring(0,props.fv_value.path.lastIndexOf('.')) : undefined

    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.fv_value.index,parentPath,intl);

    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly ? true : false;
    const methods = useFormContext();
    const { errors } = methods;

    const hasFieldError = formValidationUtil.hasFieldError(props.fv_value.id, errors)
    const errorMsg = formValidationUtil.getFieldErrorMessage(props.fv_value.id, errors)

    const _helperText = hasFieldError ? errorMsg : helperText;

    const _value = props._value as boolean
    if(layout === 'default') {
        const outerGridRef = useRef<HTMLElement>(null);
        return (
            <Box /*mt={1} mb={0.5} ml={2} mr={2}*/ key={props.fv_value.id}>
                <FormControlLabel
                    control={
                        <Control

                            inputRef={props.elemRef}
                            outerGridRef={outerGridRef}
                            id={props.fv_value.id}
                            name={props.fv_value.id}

                            disabled={isReadOnly}
                            readOnly={isReadOnly}
                            required={props.validation.required}
                            error={formValidationUtil.hasFieldError(props.fv_value.id, errors)}
                            checked={_value}
                            // onChange={props.onValueChange}
                            onClick={evt=>{
                                evt.preventDefault();
                                const new_value = evt.target['checked'];

                                const fix_value = !_value
                                evt.target['checked'] = fix_value;
                                props.onValueChange(evt);
                                // if (new_value!=_value){

                                // }
                            }}
                            data-section={props.fv_value.properties?.section}

                            style={ _helperText!! ? {paddingTop: 0}:{}}
                            inputProps={{
                                autoComplete: props.fv_value.autocomplete || props.fv_value.id
                            }}
                        />
                    }
                    label={<>
                        <LabelWithTooltip props={props}/>
                        {
                            _helperText!!
                                ? <FormHelperText error={hasFieldError}>
                                    {_helperText}
                                </FormHelperText>
                                : ""
                        }
                    </>}
                />

            </Box>
        );
    }
    else if(layout === 'review') {
        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}}>
                <Grid item xs={5}><Typography variant="body2" className={`${props.readOnlyClasses.readOnlyLabel}`}>{<span dangerouslySetInnerHTML={{__html:(props.fv_value.label || props.fv_value.fv_key) }}/>}:</Typography></Grid>
                { props._value ? <Grid item xs={6}><Typography variant="body2">Yes</Typography></Grid> :
                    <Grid item xs={6}><Typography variant="body2">No</Typography></Grid> }
            </Grid>
        );
    }
    else if (layout === 'facing') {
        const outerGridRef = useRef<HTMLElement>(null);
        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}} innerRef={outerGridRef}>
                <Grid item xs={5}><Typography variant="body2" style={{  color: 'rgba(0, 0, 0, 0.54)' }}>{<span dangerouslySetInnerHTML={{__html:(props.fv_value.label || props.fv_value.id) }}/>}:</Typography></Grid>
                {/* the default view */}
                <Box mt={1} mb={0.5} ml={2} mr={2} key={props.fv_value.id}>
                    <FormControlLabel
                        labelPlacement={props.labelPlacement}
                        control={
                            <Control
                                id={props.fv_value.id}
                                name={props.fv_value.id}
                                error={formValidationUtil.hasFieldError(props.fv_value.id, errors)}
                                readOnly={isReadOnly}
                                disabled={isReadOnly}

                                required={props.validation.required}
                                checked={props._value as boolean}
                                onChange={props.onValueChange}
                                data-section={props.fv_value.properties?.section}


                                inputRef={props.elemRef}
                                outerGridRef={outerGridRef}
                            />
                        }
                        label={<LabelWithTooltip props={props}/>}
                    />
                    {
                        formValidationUtil.hasFieldError(props.fv_value.id, errors)
                            ? <FormHelperText error={formValidationUtil.hasFieldError(props.fv_value.id, errors)}>
                                {formValidationUtil.getFieldErrorMessage(props.fv_value.id, errors)}
                            </FormHelperText>
                            : ""
                    }
                </Box>
            </Grid>
        );
    }
    else {
        return (
            <div>could not render checkbox</div>
        );
    }

};

export const CustomCheckbox = (props: CustomCheckBoxProps ) => {
    return CustomCheckboxFactory({...props,Control:(params)=>{

        useLayoutEffect(()=>{
            //Outergrid will always be null as box does not accept ref in MUI 4
            const box = params.inputRef && params.inputRef['current'] && $(params.inputRef['current']);
            const outerGrid = params.outerGridRef && params.outerGridRef['current'] && $(params.outerGridRef['current']);
            if (box ) {
                const label = box.parents('label',outerGrid)
                const container = box.parents('.checkbox-container',outerGrid);

                const labelHeight = label.height();
                container.height(labelHeight!);

            }

        },[params.inputRef]);
        return <Box flex={"container"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}

                // borderRight={1}
                // borderColor={params.error? "error": "primary"}
                // marginRight={2}
                className={"checkbox-container"}
        >
            <Checkbox {...params}
                icon={<RadioButtonUncheckedIcon fontSize="medium"
                                                color={params.error? "error" : "primary"}
                />}
                checkedIcon={<CheckCircleIcon fontSize="medium"/>}
            />

    </Box>
    }});
}