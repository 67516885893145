import * as _ from 'lodash';
import * as $ from 'jquery';
import * as React from "react";
import {createRef, CSSProperties, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    FormData,
    getEmbeddedFormContent,
    ProcessInstanceStatus,
    TaskFormData
} from "sancus-client-common/dist/common/camunda_helper";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid, GridSize,
    Hidden,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Snackbar,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography, useTheme,
    withStyles
} from "@material-ui/core";
import {ActionContainer, BackButton, SubmitButton} from "./components/action_buttons";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/ar";
import "moment/locale/en-gb";
// import "moment/locale/br"; here is example with translated days of week in callendar
import GenericFieldComponent from "./components/generic_component/GenericFieldComponent";
import {RepeatableGroupComponent} from "./components/generic_component/RepeatableGroup";
import {GroupComponent} from "./components/generic_component/Group";
import {
    decorate,
    FieldsFormView,
    FormCallbacks,
    FormContainerBase,
    IActionJson,
    ICheck,
    mergeServiceGroups,
    TaskHistoryElement
} from "sancus-client-common/dist/common/FormContainerBase";
import ChecksContainerComponent from "./components/ChecksContainerComponent";
import UserSessionWidget from "./components/UserSessionWidget";
import {Provider as ReduxProvider, useDispatch} from "react-redux";
import {getStore} from "sancus-client-common/dist/store";
import {Alert, AvatarGroup} from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import FieldCheckAvatar from "./components/FieldCheckAvatar";
import {handleExport} from "./components/export_handler";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";
import GlobalLoader from "./components/GlobalLoader";

import contextPath from "sancus-client-common/dist/common/contextPath"
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import {useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import {
    GenericFieldComponentSimple,
    readOnlyStyleFactory
} from "./components/generic_component/GenericFieldComponentSimple";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FormContainerContext} from "sancus-client-common/dist/common/FormContainerContext";
import LayoutContext from "sancus-client-common/dist/common/LayoutContext";
import EditFieldContext from "sancus-client-common/dist/common/EditFieldContext";
import {

    useAnnotatorActions
} from "sancus-client-common/dist/store/actions/annotator.actions";

declare global {
    interface Window {
        jQuery: any;
        $: any;
    }

}
window.jQuery = $;

import ResizeSensor from 'resize-sensor'
import {generateClassNameSeed} from "./ThemeWrapper";
import {useForm, useFormContext} from "react-hook-form";
import {LinearProgress} from "@material-ui/core";

function isInViewport(el) {
    const threshold = 48;
    const rect = el.getBoundingClientRect();
    const {top,bottom} = rect;
    const height = (window.innerHeight || document.documentElement.clientHeight)
    return top <height-threshold && bottom >threshold;
}

const SectionContent = (props:{sectionName:string,serviceNotificationTpl?:JSX.Element[],section}) => {
    const ref = useCallback(_node => {
        if (_node !== null) {
            getStore().dispatch({
                type: 'ACTIVE_SECTION',
                payload: {
                    sectionName:props.sectionName,
                    node:_node
                },
            })
        }
    }, []);

    return <Grid ref={ref} key={props.sectionName} className={"section"} item xs={12}
                 style={ props.sectionName == 'no_section' ?
                     {
                         display: 'none',
                         visibility: 'hidden',
                         height:"0px"
                     }:{}
                }>
        <Box pl={4} pr={4} >
            <Box p={2}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <Typography variant="h5">
                        {props.sectionName}
                    </Typography>
                    {props.serviceNotificationTpl ? <AvatarGroup>
                        {props.serviceNotificationTpl}
                    </AvatarGroup> : <></>}
                </Grid>
            </Box>
            <Divider/>
            <Box pt={2} pb={2}
                 flex={1}
                 flexDirection={"row"}
                 className={"section-box"}
                 style={{
                     display: 'flex',
                     flexWrap: 'wrap',
                 }}
                 data-section-name={props.sectionName}
            >
                {props.section.fields.map(field => field.element)}
            </Box>
        </Box>
    </Grid>
};

const SectionIndex= (props:{sectionName:string}) => {
    const store = getStore();
    const [active,setActive] = useState(false);
    const [node,setNode] = useState<HTMLDivElement | null>(null);
    useEffect(()=>{
        const state = store.getState();
        const activeSections = state.section.activeSections;
        setNode(activeSections[props.sectionName]);

        const subscription = store.subscribe(()=>{
            const state:RootState = store.getState();
            const activeSections = state.section.activeSections;
            setNode(activeSections[props.sectionName]);
        });
        return ()=>subscription();
    },[])

    useEffect(()=>{
        if (node){
            const scrollListener = (evt?)=> {
                const inViewPort = isInViewport(node);
                setActive(inViewPort)
            }
            document.addEventListener("custom_scroll",scrollListener)
            scrollListener();
            return ()=>{
                document.removeEventListener("custom_scroll",scrollListener)
            }
        }
    },[node])

    return <Box onClick={_evt => {
        _evt.preventDefault();
        if (node){
            node.scrollIntoView(true);
        }
        setActive(true);

    }} className={active?"active":""}><Typography>{props.sectionName}</Typography></Box>
};




// polyfills for IE 11
// typeError object or method doesn't support 'reportValidity'

try {
    [HTMLInputElement, HTMLFormElement, HTMLSelectElement].forEach(el => {
        if (!el.prototype.reportValidity) {
            el.prototype.reportValidity = function () {
                if (this.checkValidity()) {
                    return true;
                } else {
                    let event;
                    if (typeof (Event) === 'function') {
                        event = new Event('submit');
                    } else {
                        event = document.createEvent('Event');
                        event.initEvent('invalid', true, true);
                    }
                    this.dispatchEvent(event);
                    return false;
                }
            }
        }

    })
}catch (e){
    console.log("Something went wrong while polyfilling reportValidity",e);
}

const FormHeader = ({fieldsFormView})=>{
    const designMode = useContext(DesignModeContext);
    const dispatch = useDispatch();
    const designerActions = useDesignerActions(dispatch)
    const [isDragOver, setIsDragOver] = React.useState(false);
    const useReadOnlyStyles = makeStyles(readOnlyStyleFactory);
    const readOnlyClasses = useReadOnlyStyles();

    const headerRef = useRef<HTMLDivElement>(null);

    const methods = useFormContext();

    useEffect(() => {
        if (headerRef.current){
            if (Object.keys(methods.errors).length == 0) {
                //If there are errors, view shall scroll to the first error
                headerRef.current.scrollIntoView();
            }
        }
    }, [headerRef.current]);

    if (designMode) {
        return <div id="upperFormDropArea"
                    className={"" + (isDragOver && readOnlyClasses.designerIsDragOverIndicateBottom) }
                    onDragOver={(e) => {
                        setIsDragOver(true);

                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDragLeave={(e) => {
                        setIsDragOver(false);

                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        designerActions.onDropInPlace({type: 'upperFormArea'})
                    }}
        >
        <Box id="mainStepHeader" key={"mainStepHeader"} mb={2}>
            <TextField id="formTitle"
                       label="Title"
                       variant="standard"
                       defaultValue={fieldsFormView.title}
                       onBlur={evt=>{
                        designerActions.changeFormProp({name:evt.target.value})
                       }}/>
        </Box>

        <Box id="stepSubHeader" key={"stepSubHeader"} mb={3}>
            <TextField id="formSubtitle" label="SubTitle" variant="standard"
                       defaultValue={fieldsFormView.description}
                       onBlur={evt=>{
                           designerActions.changeFormProp({description:evt.target.value})
                       }}/>
        </Box>
        </div>
    }else{
        return <>
            <Box id="mainStepHeader" key={"mainStepHeader"} mb={2}>
                <Typography variant="h4" component={"h1"}><span ref={headerRef}
                    dangerouslySetInnerHTML={{__html: fieldsFormView.title}}/>
                </Typography>
            </Box>
            {fieldsFormView.description != null ?
                <Box id="stepSubHeader" key={"stepSubHeader"} mb={3}>
                    <Typography variant="subtitle1" component={"h2"}><span
                        dangerouslySetInnerHTML={{__html: fieldsFormView.description}}/>
                    </Typography>
                </Box> : ""}
        </>
    }
}

export class WebFormContainer extends FormContainerBase{
    private prevEvent: { formId: string; width: number; height: number } | undefined;
    handleExport(name:string | ((formReference:any)=>string), formReference: any,setInProgress:(pg:any)=>void) {
        return handleExport(name,formReference,setInProgress);
    }

    handleExportServer = async ()=>{
        const taskId = this.state.taskId;

        window.open(`${contextPath}api/export/${taskId}`)


    }
    protected bodyRef = createRef<any>()
    protected mainBoxRef = createRef<any>()
    public constructor(props) {
        super(props,{
            //@ts-ignore
            RepeatableGroupComponent,GroupComponent,GenericFieldComponent
        });
    }

    private broadCastEvent = (evtType,args)=>{
        if (window && window.parent){
            const inFrame= window.parent !=window;
            console.debug("Sending message inFrame=%s",inFrame,evtType,args);
            window.parent.postMessage({
                "eventSource":"FormContainer",
                "event":evtType,
                "args":args
            },"*")
        }
    }

    protected wrapCallbacks(callbacks:FormCallbacks):FormCallbacks{
        if(!callbacks) {
            callbacks = {} as any;
        }
        console.log("Wrapping callbacks for Web client");
        const keys:(keyof FormCallbacks)[] = ['instanceEnter','taskEnter','taskExit','instanceExit','loading','error','formRendered']


        //@ts-ignore
        const wrappedCallbacks:FormCallbacks = keys.reduce((acc,evtType:string)=>{
            const cbk = callbacks[evtType];
            const wrappedCbk  = (...args)=>{
                console.log("Wrapped Callback",evtType,args)
                try {
                    this.broadCastEvent(evtType, args);
                }catch (e) {
                    console.debug("Error broadcasting event",evtType,args,e)
                }
                if (cbk) {

                    return cbk(...args)
                }
            }
            acc[evtType] = wrappedCbk
            return acc;
        },{});

        return wrappedCallbacks;
    }
    private doWithElem = (descr:string,elem:HTMLElement) =>{
        if (!elem){
            return;
        }
        const _e = $(elem);
        const height = _e.height();
        const clientHeight = _e[0].clientHeight;
        const offsetHeight = _e[0].offsetHeight;
        const scrollHeight = _e[0].scrollHeight;

        console.debug(`[${descr}] height=${height} clientHeight=${clientHeight} offsetHeight=${offsetHeight} scrollHeight=${scrollHeight}`)

    }

    private resizeListener = (evt,totHeight?:number | boolean,totWidth?:number | boolean)=> {

        if (this.state.formData && this.bodyRef.current) {
            let formId;

            /**
             * We try to solve the following problem here: When a multi select opens the selection (popper),
             * if the window does not have enough height, it will show scrollbars (or be cut of if scrollbars are disabled).
             * In order to deal with this, we can add the height of the pooper to the window height and cause the screen to resize.
             * This means that there will be only one scrollable area, outside the iframe.
             *
             * The second case is when there is a dialog inside the form (e.g. a repeatable row):
             * In that case we need to ensure that the window has enough size to contain the dialog.

             */
            const poppers = this.bodyRef.current.querySelectorAll(".MuiAutocomplete-popper");

            const formData = this.state.formData;
            if (formData['formKey']) {
                formId = "form_" + formData['formKey'];
            } else if (formData['task']) {
                formId = "form_" + formData['task'].taskDefinitionKey;
            } else if (formData['state']) {
                const {
                    processDefinitionKey,
                    processDefinitionName,
                    state,
                    endStates,
                    activeStates
                } = formData as ProcessInstanceStatus

                formId = endStates && endStates[0] && endStates[0].id ||
                    activeStates && activeStates[0] && activeStates[0].id;
            } else if (formData['processDefinition']) {
                formId = "form_" + formData['processDefinition'].key;
            } else {
                formId = "unknown"
            }

            this.doWithElem("bodyRef", this.bodyRef.current);
            this.doWithElem("mainBoxRef", this.mainBoxRef.current);
            this.doWithElem("formRef", this.formRef.current);

            let scrollHeight, scrollWidth;
            if (this.formRef?.current) {
                scrollHeight = this.formRef.current.scrollHeight - this.formRef.current.clientHeight;
                scrollWidth = this.formRef.current.scrollWidth - this.formRef.current.clientWidth;
            } else {
                scrollHeight = 0;
                scrollWidth = 0;
            }

            if (scrollWidth < 10){
                //Add this cutoff theshold to stop frame creep when no maxWidth is set
                scrollWidth = 0;
            }


            let totRequiredHeight = this.bodyRef.current.clientHeight + scrollHeight + 50; /* Just in case */
            const cMaxHeight = getComputedStyle(this.bodyRef.current).maxHeight;
            const maxHeight = cMaxHeight.endsWith("px") ? parseInt(cMaxHeight.substring(0,cMaxHeight.length-2)) : null;

            const isMaxHeight = maxHeight && this.bodyRef.current.clientHeight >= maxHeight;

            const totRequiredWidth = this.bodyRef.current.clientWidth + scrollWidth;

            const cMaxWidth = getComputedStyle(this.bodyRef.current).maxWidth;
            const maxWidth = cMaxWidth.endsWith("px") ? parseInt(cMaxWidth.substring(0,cMaxWidth.length-2)) : null;
            const isMaxWidth = maxWidth && this.bodyRef.current.clientWidth >= maxWidth;


            const dialogs = document.querySelectorAll(`.${generateClassNameSeed}-MuiDialog-root`);

            if (dialogs && dialogs.length>0){
                const dialog = dialogs[0];

                const className = dialog.className.split(' ').find(c=>c.indexOf("MuiDialog-root")!== -1);
                const prefix = className!.substring(0,className!.indexOf("-MuiDialog-root"));

                const dialogContentContainer = document.querySelector(`.${prefix}-MuiDialogContent-root`);
                const dialogHeight = dialogContentContainer!.scrollHeight;

                const dialogContentActions = document.querySelector(`.${prefix}-MuiDialogActions-root`);
                const dialogActionsHeight = dialogContentActions!.scrollHeight;



                const paper = document.querySelector(`.${prefix}-MuiDialog-paper`);
                /**
                 * If we let the margin unbounded, since it tries to center in the page it will creep and cause the
                 * window to ever grow, pushing the dialog down in every resize
                 */

                const margins = Math.min(window.innerHeight - paper!.clientHeight,60);

                const dTotHeight = dialogHeight+dialogActionsHeight+margins;
                if (dTotHeight > totRequiredHeight){
                    totRequiredHeight = dTotHeight;
                }
            }


            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let width,height;

            if (totHeight === true) {
                height = totRequiredHeight
            } else if (typeof totHeight=== 'number') {
                height = (poppers && !isMaxHeight) && Math.max(totHeight,windowHeight) || totHeight;
            }else{
                height = (poppers && !isMaxHeight) && Math.max(totRequiredHeight,windowHeight) || totRequiredHeight;
            }

            if (totWidth === true) {
                width = totRequiredWidth
            } else if (typeof totWidth=== 'number') {
                width = isMaxWidth ? totWidth : Math.max(totWidth, windowWidth)
            }else {
                width = isMaxWidth ? totRequiredWidth : Math.max(totRequiredWidth, windowWidth)
            }

            const mx:number | undefined = _.max([...poppers].map(popper=>{
                const br = popper.getBoundingClientRect();
                return br.y+br.height

            }));
            // console.log("Poppers (2) Resize height",mx);

            if (evt !=null){
                // console.log("From resize listener",!!poppers,height,mx)
            }
            if (totHeight){
                // console.log("From mutation observer",!!poppers,height,mx)
            }
            // console.log("FormId %s totRequiredHeight=%s totRequiredWidth=%s windowWidth=%s windowHeight=%s scrollHeight=%s scrollWidth=%s",
            //     formId, totRequiredHeight, totRequiredWidth, windowWidth, windowHeight,scrollHeight,scrollWidth
            // );

            if (Math.abs(height - windowHeight) < 5 && Math.abs(width - windowWidth)<5 ){
                // Use window height & width if the difference is less than 5px to prevent creeping
                height = windowHeight;
                width = windowWidth;

            }
            if (this.prevEvent &&
                this.prevEvent.formId === formId &&
                this.prevEvent.height === height &&
                this.prevEvent.width === width){
                // console.log("Skipping resize event for %s",formId)
                return;
            }

            this.prevEvent = {formId,height,width};

            window.setTimeout(() => {
                this.broadCastEvent("formRendered", {
                    formId,
                    height,
                    width,
                    maxHeight,
                    maxWidth
                })

            })


        }
    }
    private throttled = _.throttle(this.resizeListener.bind(this), 500);

    private mutationListener = (mutationList, observer)=>{
        const allMutations = observer.takeRecords();
        for (const mutation of mutationList) {
            if (mutation.type === "childList") {
                const poppers = mutationList.flatMap(m=>{
                    return m.addedNodes && [...m.addedNodes].filter(n=>{
                        if (n.className){
                            if (n.className.indexOf){
                                return n.className.indexOf("MuiAutocomplete-popper")!== -1
                            }else{
                                return false;
                            }
                        }else{
                            return false;
                        }

                    })
                })

                const dialogs = mutationList.flatMap(m=>{
                    return m.addedNodes && [...m.addedNodes].filter(n=>{
                        if (n.className){
                            if (n.className.indexOf){
                                return n.className.indexOf("MuiDialog-root")!== -1
                            }else{
                                return false;
                            }
                        }else{
                            return false;
                        }

                    })
                })

                const removedDialogs = mutationList.flatMap(m=>{
                    return m.removedNodes && [...m.removedNodes].filter(n=>{
                        if (n.className){
                            if (n.className.indexOf){
                                return n.className.indexOf("MuiDialog-root")!== -1
                            }else{
                                return false;
                            }
                        }else{
                            return false;
                        }

                    })
                });



                const windowHeight = window.innerHeight;
                const mx_popper:number | undefined = _.max(poppers.map(popper=>{
                    const br = popper.getBoundingClientRect();
                    // console.log("PP",br)
                    return br.y+br.height > windowHeight ? br.y+br.height : null

                }).filter(y=>y));

                if (mx_popper && mx_popper > windowHeight){
                    console.debug("Poppers Resize height",mx_popper,poppers);
                    this.resizeListener(null,mx_popper+200);
                }

                if (dialogs.length>0){
                    const dialog = dialogs[0];

                    const className = dialog.className.split(' ').find(c=>c.indexOf("MuiDialog-root")!== -1);
                    const prefix = className.substring(0,className.indexOf("-MuiDialog-root"));

                    const dialogContentContainer = document.querySelector(`.${prefix}-MuiDialogContent-root`);

                    console.debug('ResizeSensor',ResizeSensor,ResizeSensor.prototype)
                    const r = ResizeSensor.prototype;


                    r.constructor(dialogContentContainer,()=>{
                        this.resizeListener(null,true);
                    })

                    const dialogHeight = dialogContentContainer!.scrollHeight;

                    const dialogContentActions = document.querySelector(`.${prefix}-MuiDialogActions-root`);
                    const dialogActionsHeight = dialogContentActions?.scrollHeight ?? 0;



                    const paper = document.querySelector(`.${prefix}-MuiDialog-paper`);
                    const margins = window.innerHeight - paper!.clientHeight;
                    console.debug("Dialogs Resize height",dialogs,dialogHeight,dialogActionsHeight,margins);
                    this.resizeListener(null,dialogHeight+dialogActionsHeight+margins);
                }

                if (removedDialogs.length >0){
                    this.resizeListener(null,true);
                }

            } else if (mutation.type === "attributes") {

            }
        }
    }

    private throttlesMutationListener = _.throttle(this.mutationListener, 500);

    private observer = new MutationObserver(this.mutationListener);

    protected doAfterRenderCallback(phase: "mount" | "update") {
        console.debug("doAfterRenderCallback",phase)
        if (phase === 'mount') {
            window.addEventListener("resize", this.throttled);
            // this.observer.observe(this.bodyRef.current, { attributes: false, childList: true, subtree: false });
            this.observer.observe(document.body, {attributes: false, childList: true, subtree: false});
        }
        this.resizeListener(null);

    }
    protected doAfterUnmount(){
        window.removeEventListener("resize",this.throttled);
        this.observer.disconnect();
    }

    unstable_batchUpdate(cbk:()=>void){
        return ReactDOM.unstable_batchedUpdates(cbk);
    }
    async doHandleEmbeddedForm(formData: FormData,processInstanceId): Promise<{ document: Document; extraFields?: FieldsFormView['fields']; actions?: string | string[] } | null> {
        const formHTMLStr = await getEmbeddedFormContent(formData.formKey!);
        const form = new DOMParser().parseFromString(formHTMLStr, 'text/html')

        const scripts = Array.from(form.scripts);
        const ownScript = true || scripts.map(s=>s.src).some(src=> {
            return src && src.indexOf('enter')!= -1 && src.indexOf('bundle') != -1
        })

        if (ownScript){
            console.log("Form embeds own script - proceed as generic form")
            this.setState({externalScripts: []});
            //We are in a case of embedding enter inside the task list
            // We must assume the form is not embedded after all
            return null;
        }else {


            const taskFormData = formData as TaskFormData;
            const formView = this.handleForm(taskFormData.task?.id,processInstanceId,taskFormData,this.state.values,null);
            const {fields:extraFields,actions} = formView as FieldsFormView;

            this.setState({
                externalScripts: scripts,
                afterRender(){
                    if (!extraFields || extraFields.length == 0){
                        return;
                    }
                    const divInForm = document.createElement('div');
                    divInForm.id = 'div_in_form';
                    const formElem = form.querySelector('form');
                    if (formElem) {
                        formElem.appendChild(divInForm);
                        const elem = <Grid container
                                           direction="column"
                                           justifyContent="space-around"
                                           alignItems="stretch">
                            {extraFields.map(e => e.element)}
                        </Grid>

                        ReactDOM.render(elem, divInForm);
                    }
                }
            });

            return {document:form,extraFields,actions};


            /*
            afterRender:
             */
            /*const hasExtraFields = fieldsMapped && fieldsMapped[0];
            if (hasExtraFields) {
                const extraFields =
                    <Grid container
                          direction="column"
                          justifyContent="space-around"
                          alignItems="stretch">
                        {fieldsMapped}
                    </Grid>
                const formElem = form.querySelector('form');

                const divInForm = document.createElement('div');
                divInForm.id = 'div_in_form';
                formElem.appendChild(divInForm);
                ReactDOM.render(extraFields, divInForm);
            }*/
        }
    }



    renderDocument(document: Document){
        const __html = document.body.innerHTML
          return <div dangerouslySetInnerHTML={{__html}} ref={this.formRef}/>;
    }

    doRenderFields(fieldsFormView,fieldElementsWithIndex:(JSX.Element | [JSX.Element,JSX.Element])[]){

        const scrollForm = this.props.theme?.customProps?.scrollForm;

        const fieldElements:JSX.Element[] = [];
        const sectionIndexes:JSX.Element[] = [];

        fieldElementsWithIndex.forEach(f=>{
            if (Array.isArray(f)){
                const [i,e] = f;
                fieldElements.push(e);
                sectionIndexes.push(i);
            }else{
                fieldElements.push(f);
            }
        })


        const layout = this.props.layout;
        const formStyle:CSSProperties | undefined = (true || layout !== 'review' && layout !== 'facing')? {
            display:'flex',
            flexWrap:'wrap',
        } : undefined;

        return [<Grid item container key={"FormFragment"} id={fieldsFormView.formDefId || "FormFragment"}
                      className={"FormFragment"}
                      direction={"row"}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{width: "100%"}}
        >
            {sectionIndexes.length >0 && <Grid item xs={2} key={"FormFragmentSecHeaders"} >
                <Box border={"solid 1px black"} id={"FormFragmentSecHeaders"} display={{xs:'none',lg:'block'}}>
                {sectionIndexes}
                </Box>
            </Grid>}
            <Grid item key={"FormFragmentIn"} container direction={"column"} className={"form-fragment-in"}>
                <FormHeader fieldsFormView={fieldsFormView}/>
                <Grid item className={"form-grid-item"}>
                    <form id={"form_" + fieldsFormView.id}
                          ref={this.formRef}
                          style={formStyle}
                          className={scrollForm?'scroll-content':''}
                          key={"Fields"} onDrop={
                            () => console.log('DRAG_DROP_in FORM ')
                          }
                          onSubmit={e => {
                              e.preventDefault();
                              console.log("from onSubmit triggered", e);
                          }}
                    >
                        {fieldElements}
                    </form>
                </Grid>
            </Grid>
        </Grid>]
    }

    // renderFormDivAndActionContainer(formDefId,elements){
    //     return <span id={formDefId}>{elements}</span> ;
    // }

    doRenderSection(sectionName:string,section,serviceGroups,renderIndex?:boolean):JSX.Element | [JSX.Element,JSX.Element] {
        // render notification template
        serviceGroups = mergeServiceGroups(serviceGroups)
        const serviceNotificationTpl = Object.keys(serviceGroups).map((serviceName) => {
            return <FieldCheckAvatar check={serviceGroups[serviceName]} key={serviceName}/>
        })


        const sectionContent =  <SectionContent
            key={sectionName}
            sectionName={sectionName}
            section={section}
            serviceNotificationTpl={serviceNotificationTpl}
        ></SectionContent>
        if (renderIndex){
            const sectionIndex = renderIndex && <SectionIndex sectionName={sectionName} />
            return [sectionIndex,sectionContent]
        }else {
            return sectionContent;
        }

    }


    renderPaused(stateId,stateName,stateDesc,executionId,attributes?){
        const intl = this.props.intl





        return <PausedState intl={ this.props.intl} stateId={stateId} stateName ={stateName}
                            stateDesc={stateDesc}
                            attributes={attributes}
                            bodyRef={this.bodyRef}
                            executionId={executionId}
                            handleInterrupt={this.handleInterrupt.bind(this)}/>

    }

    renderEndState(state,endStates){
        return <EndState state={state}
                         endStates={endStates}
                         bodyRef = {this.bodyRef}
                         processInstanceId={this.state.processInstanceId}/>
    }

    renderError(error,standalone):JSX.Element {
        const extractedMessage = typeof error === 'string' ? error :
            error instanceof Error ? error.message : JSON.stringify(error);

        return <Grid item id={"errorPopup"} key={"errorPopup"}>
            <Snackbar open={!!error}>
                <Alert severity="error">
                    <Grid container direction={'row'}>
                        <Typography>
                            Unexpected error occurred: {extractedMessage}
                        </Typography>
                        <IconButton onClick={() => {
                            if (extractedMessage === 'SE:Unauthorized'){
                                window.location.reload();
                            }
                            this.errorCbk(null);
                        }}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Alert>
            </Snackbar>
        </Grid>
    }

    renderLoading() {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
        }}>
            Loading, Please wait...
            <CircularProgress/>
        </div>
    }

    doRenderActionContainer(actionButtons: JSX.Element[], actions?: (IActionJson | string)[]): JSX.Element[] {
        return [<ActionContainer actions={actions} key={'actionContainer'}>
            {actionButtons}
        </ActionContainer>]
    }

    renderSubmitButton = this._renderSubmitButton(SubmitButton as any,Tooltip);
    renderBackButton = this._renderBackButton(BackButton as any)

    doRenderUserSessionInfoWidget() {
        return <UserSessionWidget logoutUserSession={this.context.logoutUserSession}/>
    }

    doRenderStepper(currentTaskIdx:[number,number], taskHistory:TaskHistoryElement[],clickable:boolean):JSX.Element {
        const [currentStepIdx,_currentTaskIdx] = currentTaskIdx
        const processInstanceId = this.state.processInstanceId;
        const gotoStep = async (th:TaskHistoryElement,event) => {
            event.preventDefault();
            const td = th.taskDefs[0];
            const td_ext = th.taskDefData[0];
            const fullId = td_ext.fullId;
            if (processInstanceId) {
                let formData
                if (fullId.indexOf(":")!== -1){
                    const parts = fullId.split(":");
                    formData = await this.context.resumeActivityBefore(processInstanceId!, parts[0])
                }else {
                    formData = await this.context.resumeActivityBefore(processInstanceId!, td)
                }

                if (formData) {
                    const task = (formData as TaskFormData).task
                    const taskId = task.id
                    const taskName = task.name
                    this.newStateCbk(taskId, processInstanceId, formData);
                }
            }
        }

        // @ts-ignore
        return <Hidden xsDown key={"mainBoxStepper"}><Grid item xs>
            <Stepper id="stepper" activeStep={currentStepIdx} orientation="vertical" style={{ background: 'transparent' }} component={"nav"}>
                {taskHistory.map(th => {
                    return <Step key={th.stepName} completed={th.visited}>
                        <StepLabel ><a dangerouslySetInnerHTML={{__html:th.stepName}} onClick={event=>{
                            event.preventDefault();
                            if (clickable) {
                                gotoStep(th, event)
                            }
                        }}/>
                            {/*<span dangerouslySetInnerHTML={{__html:th.stepName}}/>*/}
                        {/*<span>{`${th.taskDefIdx +1 }/${th.taskDefs.length}`}</span>*/}
                        </StepLabel>
                    </Step>
                })}
            </Stepper>
        </Grid></Hidden>
    }
    doRenderAnnotator(modes:null | "" | string[]): JSX.Element[] {
        const fieldId_Mode = this.state.annotator?.annotatorPopup;
        if (!fieldId_Mode){
            return []
        }
        const {fieldId,mode} = fieldId_Mode;

        return [
            <AnnotateFieldDialog
                    fieldId={fieldId}
                    mode={mode}
                    annotations={this.state.annotator.annotations}
            />,
            <EditFieldDialog fieldId={fieldId} mode={mode}
                             fileChangeCbk={this.fileChangeCbk}
                             valueChangeCbk={this.valueChangeCbk}
                             edits={this.state.annotator.edits}
            />
        ]
    }

    doRender(div:JSX.Element,checks:ICheck[],key:string,formDefId){

        const showChecks = checks && checks.filter(check=>check.showCheck !==false).length > 0;
        const showStepper = this.shouldRenderStepper();


        const gridStepper = showStepper ? 2 : 0;
        const gridCheck = showChecks ? 4 : 0;
        const gridMain = 12 - gridStepper - gridCheck;

        const ret =  (
            // @ts-ignore
            <ReduxProvider store={getStore()} key={key}>
                <MuiPickersUtilsProvider locale={this.props["lang"] === "ar" ? "ar" : "en-gb"} utils={MomentUtils}>
                    {this.props.displayLogin ? <Grid container direction="row" justifyContent="flex-end" alignItems="stretch" item xs key={"UserSessionWidgetContainer"}
                          id={"UserSessionWidget"} >
                        {this.renderUserSessionInfoWidget()}
                    </Grid>: <></>}

                    {this.props.useInternalGlobalLoader ? <GlobalLoader/> : <></>}


                    <Grid container direction="row" justifyContent="flex-start"
                          alignItems="stretch"
                          item xs
                          wrap={"nowrap"}
                          id={formDefId} className={"formContainer"} style={{width: "100%"}}
                          ref={this.bodyRef}>
                        {this.renderStepper()}
                        <Grid container direction="column" item xs={gridMain as GridSize} style={{minHeight: "50vh"}} id={"mainBoxWrapper"} ref={this.mainBoxRef}>
                            <Grid container direction="column" id="form" item xs key={"mainBoxContent"}>
                                <Grid item xs>
                                    <Box id="mainFormBox" key={this.state.taskId}  onScroll={evt=>{




                                        const event = document.createEvent('Event');
                                        event.initEvent('custom_scroll', true, true);
                                        evt.target.dispatchEvent(event);
                                    }}>{div}</Box>
                                </Grid>
                            </Grid>
                            {this.renderError(this.state.error,false)}
                        </Grid>
                        {showChecks &&
                            <Grid container
                                  direction="column"
                                  id="checks"
                                  item xs={gridCheck as GridSize}
                                  className={'check_container'}
                                  key={`check_container`}>
                                <ChecksContainerComponent
                                  checks={checks.filter(check=>check.showCheck!== false)}
                                  valueChangeCbk={this.valueChangeCbk}
                                  processInstanceId={this.state.processInstanceId}
                                  loggedUserState={this.props.loggedUserState}
                                  taskId={this.state.taskId}
                                  formReference={(this.state.formData as FormData)?.formFields}
                                />
                        </Grid>}
                    </Grid>
                </MuiPickersUtilsProvider>
            </ReduxProvider>)



        return <DesignModeContext.Consumer >
            {design_mode=>{
              if (design_mode){
                  return ret;
              }else{
                  return ret;
              }

            }}
        </DesignModeContext.Consumer>;
    }
}

export const FormContainer = decorate(WebFormContainer);
export const FCS = withStyles(theme => {
    return ({
        root: {
            direction: theme.direction,
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            }

        },
    });
},{withTheme:true})(FormContainer);


export const AnnotateFieldDialog = props=>{
    const {fieldId,mode,annotations} = props;

    const value = annotations[fieldId]
    const valueRef = createRef<HTMLInputElement>();
    const publicRef = createRef<HTMLInputElement>();
    const levelRef = createRef<HTMLInputElement>();

    return <Dialog key={"annotator"}
                   open={!!fieldId && mode == 'annotate'}
                   onClose={() => {
                       getStore().dispatch({
                           type: 'CLOSE_ANNOTATOR_POPUP',
                           payload: {fieldId,mode},
                       });
                   }}>
        <DialogTitle>Annotate Field</DialogTitle>
        <DialogContent>
            <FormGroup>
                <FormControlLabel  control={
                    <Checkbox inputRef={publicRef} defaultChecked={value?.public ?? true}  />
                } label={"Public"}/>
                <FormControl fullWidth>
                    <InputLabel>Level</InputLabel>
                    <Select defaultValue={value?.level ?? "error"} inputRef={levelRef} >
                        <MenuItem value={"info"}>Info</MenuItem>
                        <MenuItem value={"warning"}>Warning</MenuItem>
                        <MenuItem value={"error"}>Error</MenuItem>

                    </Select>
                </FormControl>
                <Divider/>
                <FormControl fullWidth>

                    <TextField
                        label={"Comment"}
                        fullWidth={true}
                        multiline={true}
                        minRows={5}
                        defaultValue={value?.value ?? ""}
                        inputRef={valueRef}

                    />
                </FormControl>

            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                getStore().dispatch({
                    type: 'CLEAR_ANNOTATION',
                    payload: {fieldId,mode},
                });
            }} variant="contained" size="large" >Clear
            </Button>

            <Button onClick={() => {

                const _value = {
                    value: valueRef.current?.value,
                    level: levelRef.current?.value,
                    public: publicRef.current?.checked
                }

                getStore().dispatch({
                    type: 'CLOSE_ANNOTATOR_POPUP',
                    payload: {fieldId,value:_value},
                });


            }} variant="contained" color="primary" size="large" >Close
            </Button>



        </DialogActions>
    </Dialog>
}
export const EditFieldDialog = props=>{
    const dispatch = useDispatch();
    const annotatorActions  = useAnnotatorActions(dispatch);

    const {clearAnnotation,
        closeAnnotatorPopup,
        initAnnotations,
        showAnnotatorPopup} = annotatorActions;

    const {fieldId:fieldPath,mode,valueChangeCbk,fileChangeCbk,edits} = props;

    const lIdx = fieldPath.lastIndexOf('.');
    const fieldId = lIdx !== -1 ? fieldPath.substring(lIdx+1) : fieldPath;
    let fieldPrefix,fieldIdx;
    if (lIdx !== -1) {
        fieldPrefix = fieldPath.substring(0, lIdx);
        const brIdxStart = fieldPrefix.lastIndexOf('[');
        if (brIdxStart !== -1){
            const brIdxEnd = fieldPrefix.indexOf(']',brIdxStart);
            fieldIdx = fieldPrefix.substring(brIdxStart+1,brIdxEnd);
            fieldPrefix = fieldPrefix.substring(0,brIdxStart);
            fieldIdx = parseInt(fieldIdx);
        }
    }




    const formContainerContext = useContext(FormContainerContext);
    const formReference = formContainerContext?.formReference;
    const fv_value = fieldId && formReference.find(f=>f.id == fieldId);



    if (fieldPrefix) {
        const valueProvider = fieldPrefix && formReference.find(f => f.id == fieldPrefix);
        let value = valueProvider?.value?.value;
        if (fieldIdx != null && value) {
            value = value[fieldIdx];
        }
        if (value) {
            fv_value.value ??= {};
            fv_value.value.value = value[fieldId];
        }

    }

    const contextEvaluator = formContainerContext?.contextEvaluator;

    const validationConstraints =fv_value?.validationConstraints.filter(vc=>vc.name !== 'readonly');

    const editValue = edits[fieldPath]

    const [_value,set_value] = useState(editValue ?? fv_value?.value.value);

    const convertValue = (_v)=>{
        if (_v == null){
            return null;
        }
        if (_v == ''){
            return '';
        }
        const typeName = fv_value.typeName || fv_value.value?.type;
        if (typeName == 'boolean'){
            return _v == 'true';
        }else if (typeName == 'long' ) {
            return parseInt(_v);
        }else if  (typeName == 'float') {
            return parseFloat(_v);
        }else{
            return _v;
        }
    }

    const editDialogOuterRef = useRef();
    const editDialogRef = useRef();
    useLayoutEffect(()=>{
        if (editDialogRef.current){
            var elem = editDialogRef.current;
            console.log(elem)
        }
    },[editDialogRef.current])
    return <Dialog key={"annotator_editor"}
            open={!!fieldPath && mode == 'edit'}
            onClose={() => {
                closeAnnotatorPopup({
                    fieldId:fieldPath,mode
                });
            }}>
        <DialogTitle>Edit Field</DialogTitle>
        <DialogContent>
            {fv_value && <LayoutContext.Provider value={"default"}>
                <EditFieldContext.Provider value={true}>
                <Grid innerRef={editDialogRef}>
                <GenericFieldComponent
                key={fv_value.id+":edit"}
                {...fv_value}
                path={fv_value.id}
                contextEvaluator={contextEvaluator}
                _value={_value}


                valueChangeCbk = {async (key,value)=>set_value(convertValue(value))}
                fileChangeCbk ={ (...args)=>fileChangeCbk(...args) }
                formReference={formReference}
                validationConstraints={validationConstraints}
                /></Grid>
            </EditFieldContext.Provider>
            </LayoutContext.Provider> || null}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                clearAnnotation({fieldId:fieldPath,mode});
            }} variant="contained" size="large" >Clear
            </Button>

            <Button onClick={() => {
                closeAnnotatorPopup({fieldId:fieldPath,value:_value,mode});
                valueChangeCbk(fieldPath,_value);


            }} variant="contained" color="primary" size="large" >Update and close
            </Button>



        </DialogActions>
    </Dialog>
}

const EndState = (props:{state,endStates,processInstanceId,bodyRef}) => {

    const {state,endStates,processInstanceId,bodyRef} = props
    useEffect(()=>{
        if (bodyRef.current){
            bodyRef.current.scrollIntoView(true);
        }
    },[bodyRef.current])

    const name = endStates && endStates[0] && endStates[0].name || state;

    const documentation = (endStates && endStates[0] && endStates[0].documentation) ??
        `instance id ${processInstanceId} is ${state} without reaching an end state`;
    return <div ref={bodyRef}>
        <Box mb={2}>
            <Typography variant="h4">{name}</Typography>
        </Box>
        <Box mb={3}>
            <Typography variant="subtitle1">{documentation}</Typography>
            {/*<Typography variant="subtitle1">You can now close your browser</Typography>*/}
        </Box>
    </div>
}

const PausedState  = (props:{intl,bodyRef,stateId,stateName,stateDesc,attributes,executionId,handleInterrupt}) => {
    const {intl,bodyRef,stateId,stateName,stateDesc,attributes,executionId,handleInterrupt} = props;
    const theme=useTheme()
    useEffect(()=>{
        if (bodyRef.current){
            bodyRef.current.scrollIntoView(true);
        }
    },[bodyRef.current])

    const pauseMessageTitle =  intl.formatMessage({
        id:`pause_message.${stateId}.title`,
        defaultMessage:stateName,
        description:`pause_message.${stateId} Title`
    })

    const pauseMessageDesc =  intl.formatMessage({
        id:`pause_message.${stateId}.desc`,
        defaultMessage:stateDesc || "Please wait for the task to complete",
        description:`pause_message.${stateId} Message`
    });

    const refreshMessage=  intl.formatMessage({
        id:`pause_message_refresh`,
        defaultMessage:"You can manually refresh the page by clicking",
        description:`pause_message_refresh`
    });

    const interrupt_message=  intl.formatMessage({
        id:`pause_message_interrupt`,
        defaultMessage:"You can interrupt the wait state by clicking",
        description:`pause_message_interrupt`
    });


    let descParts;
    if (pauseMessageDesc.indexOf('__linear_progress(') !== -1){
        const idx = pauseMessageDesc.indexOf('__linear_progress');
        const idx_2 = pauseMessageDesc.indexOf(')__',idx+1);
        const prefix = pauseMessageDesc.substring(0,idx);
        const argStr = pauseMessageDesc.substring(idx+18,idx_2)
        let arg;
        try {
            arg = eval(argStr);
        }catch (e){
            console.error("Error evaluating linear progress argument",argStr,e)
            arg = null;
        }
        const suffix = pauseMessageDesc.substring(idx_2+3);
        descParts = [
            <Typography variant="subtitle1"  dangerouslySetInnerHTML={{__html:prefix}}></Typography>,
            <LinearProgress variant={arg != null ? "determinate":"query" } value={arg} style={{
                marginTop: theme.spacing(2),
                maxWidth: '500px'
            }}/>,
            suffix && <Typography variant="subtitle1"  dangerouslySetInnerHTML={{__html:suffix}}></Typography>

        ]
    }else{
        descParts = [
            <Typography variant="subtitle1"  dangerouslySetInnerHTML={{__html:pauseMessageDesc}}></Typography>
        ]
    }

    return <div id="refreshTasksH3" ref={bodyRef}>
        <Box mb={2}><Typography variant="h4" dangerouslySetInnerHTML={{__html: pauseMessageTitle}}/></Box>
        <Box mb={3}>
            {descParts}
            {/*{<Typography variant="subtitle1" style={{visibility:'hidden'}}>{refreshMessage} <a id="refreshTasks" href="#" onClick={this.handleRefreshTasks}>here</a></Typography>}*/}
            {attributes?.interrupt_signal &&
                <Typography variant="subtitle1" style={{visibility: 'hidden'}}>
                    {interrupt_message}
                    <a id="refreshTasks"
                      href="#"
                      onClick={evt => handleInterrupt(executionId, attributes.interrupt_signal, evt)}>here</a>
                </Typography>
            }
        </Box>
    </div>
}
