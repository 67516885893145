import * as React from "react";

import {GroupComponentState} from "./GroupBase";
import {ContextAware} from "./generic_component_base";
import {FieldGroupTemplate, FileChangeCallback, FormValues, ValueChangeCallback} from "./FormContainerBase";
import {RepeatableParams, RepeatableState} from "./RepeatableBase";
import {IntlShape} from "react-intl";
import LayoutContext from "./LayoutContext";
import {FormPropertyValidationConstraint} from "./camunda_helper";



export interface RepeatableGroupComponentParams extends ContextAware {
    // group name
    name: string,
    path: string,
    template: FieldGroupTemplate,
    values: FormValues[],
    valueChangeCbk: ValueChangeCallback,
    fileChangeCbk: FileChangeCallback,
    checkMessages,
    intl: IntlShape,
    designMode?: boolean,

    visibleElements?: number | null,
    methods?/*:UseFormMethods*/

    label?: string,
    properties?: {[key:string]:string},
    validationConstraints?: FormPropertyValidationConstraint[],
}


export interface RepeatableGroupComponentRenderers {
    Repeatable
}

export class RepeatableGroupComponentBase extends React.Component<RepeatableGroupComponentParams, GroupComponentState> {
    constructor(props: RepeatableGroupComponentParams, protected renderers: RepeatableGroupComponentRenderers) {
        super(props);
    }

    render() {
        const Repeatable = this.renderers.Repeatable;
        return (
            <LayoutContext.Consumer>
                {(layout) => {
                    return (
                        <Repeatable
                            layout={layout}
                            group={true}

                            id={this.props.name}
                            key={this.props.name}

                            {...this.props}
                            // valueChangeCbk={this.props.valueChangeCbk}
                            // fileChangeCbk={this.props.fileChangeCbk}
                            // name={this.props.name}
                            // template={this.props.template}
                            //
                            // values={this.props.values}
                            // contextEvaluator={this.props.contextEvaluator}
                            // checkMessages={this.props.checkMessages}
                            // designMode={this.props.designMode}
                            // intl={this.props.intl}
                            //
                            // methods={this.props.methods}
                            // visibleElements={this.props.visibleElements}
                            //
                            // label={this.props.label}
                            // properties={this.props.properties}
                            // validationConstraints={this.props.validationConstraints}
                            // formReference={this.props.formReference}
                        />
                    );
                }}
            </LayoutContext.Consumer>
        );
    }
}
