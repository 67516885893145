import {useNavigate, useLocation} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {contextPath} from "../contextPath";
import axios from "axios";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';
import Dialog from "@material-ui/core/Dialog";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(8),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonTypo: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));


const SetupTFAInner = (props)=> {
    const verifyTFAURL = `${contextPath}/api/user-mgmt/verify-tfa`;
    const codeRef = useRef('');
    const setupTFAURL = `${contextPath}/api/user-mgmt/setup-tfa`;
    const {userId, challenge} = props;
    const [qrCode, setQRCode] = useState(null)
    const [error, setError] = useState(null)
    const [verifyError,setVerifyError] = useState(null)
    const classes = useStyles();
    useEffect(() => {
        if (qrCode != null) {
            return;
        }
        axios.get(setupTFAURL, {
            params: {
                userId: userId,
                challenge: challenge
            }
        }).then((response) => {
            const {base64QRCode, scratchCodes} = response.data
            setQRCode({base64QRCode, scratchCodes});
        }).catch((error) => {
           setError(error);
        });
    },[])
    if (qrCode != null) {
        return <Container component="div" maxWidth="xs">

            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'}
                     height={60} alt={"Sancus logo"}></img>
                <Box p={2}>
                    <Typography variant="h5">Setup Two Factor Authentication</Typography>
                </Box>
                <Typography variant="subtitle2">
                    Scan the image in your google Authenticator
                </Typography>
                <div>
                    <img src={qrCode.base64QRCode} alt="QR Code"/>
                </div>
                <div>
                    <Typography variant="subtitle2">
                        Save these codes in a secure password manager. They can be used to recover access to your
                        account if your two factor authentication device is lost.
                    </Typography>
                    <ul>
                        {qrCode.scratchCodes.map((code) => {
                            return <li>{code}</li>
                        })}
                    </ul>
                </div>

                <div>
                    <Typography variant="subtitle2">
                        Enter the code from your authenticator app to continue
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="code"
                        label="Verify Code"
                        type="string"
                        id="code"
                        autoComplete="current-password"
                        error={verifyError!=null}
                        helperText={verifyError}
                        onChange={e => codeRef.current = e.target.value}
                    />
                    <Button name={"verify code"} variant={"contained"}
                            onClick={e => {
                        axios.get(verifyTFAURL, {
                            params: {
                                userId: userId,
                                challenge: challenge,
                                code: codeRef.current
                            }
                        }).then((response) => {
                            const changed = response.data;
                            if (changed) {
                                props.onSuccess(response.data);
                            }else{
                                setVerifyError("Invalid code")
                            }
                        }).catch((error) => {
                            setVerifyError(error)
                        })
                    }}> Verify Code</Button>

                    <Button name={"Cancel"} variant={"outlined"}
                            onClick={e => {
                                props.onSuccess(false);
                            }}>Cancel</Button>
                </div>
            </div>
        </Container>
    }else if (error!=null){
        return <div>{error.message}</div>
    }   else {
        return <div>Loading...</div>
    }
}


export const SetupTFA = ()=> {
    const {history} = useNavigate();
    const {state} = useLocation();
    const {userId, challenge} = state;

    return <SetupTFAInner
            userId={userId}
            challenge={challenge}
            onSuccess={()=>history("/login")}
    />

}

export const SetupTFADialog = (props)=>{
    const {userId} = props;
    return <Dialog open={props.open} onClose={()=>props.onClose(false)}>
        <SetupTFAInner userId={userId} onSuccess={()=>props.onClose(true)}/>
    </Dialog>
}

export const SetupTFAState = (props) =>{
    const {LoggedUserState, setUserLoggedIn, setUserLoggedOut} = useContext(LoggedUserContext);
    const [tfaDialogOpen, setTFADialogOpen] = useState(true);
    const {state} = useLocation();

    const userId = state?.userId || LoggedUserState.user.id;

    const history = useNavigate();
    return <>
        <Box>
            SETUP TFA!!!!
        </Box>
        <SetupTFADialog
            userId={userId}
            open={tfaDialogOpen}
            onClose={(outcome)=>{
                if (outcome) {
                    setTFADialogOpen(false);
                    history(-1)
                }
            }}
        /></>


}





