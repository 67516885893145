import {
    extractFieldType,
    GenericFieldParamsBase,
    isDateType,
    Validation
} from "../common/GenericFieldComponentSimpleBase";
import {GenericFieldParams, substitutions} from "../common/generic_component_base";

import {SancusConditionEvaluator} from "../common/conditionEvaluator.service";
import moment from "moment";
import {registry} from "../common/react_components_base";
import {FormPropertyValidationConstraint} from "../common/camunda_helper";
import {IntlShape} from "react-intl";
import {FieldErrors, FormState} from "react-hook-form";

/*export interface FormValidationError {
    [key: string]: {
        type: 'required' | 'minLength' | 'maxLength' | 'min' | 'max' | 'pattern' | 'email',
        message: string,
        ref: any
    };
}*/
export type FormValidationError = FieldErrors<any>;
type MessageProvider  = ()=>string;
type MessageProviderFactory  = (intl:IntlShape) => {[key:string]: MessageProvider};

const emailPattern = /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

const requiredFN = value=>{
    const ret = value!=null && value!=='';
    if (!ret){
        return "required"
    }
    return ret;
}

const messageMapProviderFactory = (intl=>({
    required: () => {

        return intl.formatMessage({
            id:`validation.error.fieldIsRequired`,
            defaultMessage:'This field is required',
            description:'Form validation field is required'
        });
    },
    minLength: (length: number) => {

        return intl.formatMessage({
            id: 'validation.error.minimumLengthRequired',
            defaultMessage: 'Field must be at least {length} characters long',
            description: `Form validation minimum length (${length})`,
        }, {length: `${length}`});
    },
    min: (value: number,isArray?:boolean) => {
        if (isArray){
            return intl.formatMessage({
                id: 'validation.error.minimumArraySizeRequired',
                defaultMessage: 'Must have at least {value} entries',
                description: `Form validation minimum value (${value})`,
            }, {value: `${value}`});
        }else {
            return intl.formatMessage({
                id: 'validation.error.minimumValueRequired',
                defaultMessage: 'Value must be greater than or equal {value}',
                description: `Form validation minimum value (${value})`,
            }, {value: `${value}`});
        }
    },
    max: (value: number,isArray?:boolean) => {
        if (isArray){
            return intl.formatMessage({
                id: 'validation.error.maximumArraySizeRequired',
                defaultMessage: 'Must have less than {value} entries',
                description: `Form validation minimum value (${value})`,
            }, {value: `${value}`});
        }else {
            return intl.formatMessage({
                id: 'validation.error.maximumValueRequired',
                defaultMessage: 'Value must be less than or equal {value}',
                description: `Form validation maximum value (${value})`,
            }, {value: `${value}`});
        }
    },
    maxLength: (length: number) => {

        return intl.formatMessage({
            id: 'validation.error.maximumLengthRequired',
            defaultMessage: 'Field cannot exceed {length} characters',
            description: `Form validation maximum length (${length})`,
        }, {length: `${length}`});
    },
    pattern: (pattern: RegExp) => {
        if (!pattern){
            return null;
        }
        const comment = pattern['comment'];
        let id,defaultMessage;
        if (comment){
            if (comment == '__hide'){
                id='__empty__';
                defaultMessage='';
            }else if (comment.indexOf("id:")==0){
                const firstSpace = comment.indexOf(" ");
                id = comment.substring(3,firstSpace);
                defaultMessage = comment.substring(firstSpace+1);
            }else{
                id =  `validation.error.patternRequired`;
                defaultMessage = comment;
            }
        }else{
            id =  `validation.error.patternRequired`;
            defaultMessage = 'pattern {pattern} required';
        }
        return intl.formatMessage({
            id,
            defaultMessage,
            description: `Form validation pattern (${pattern})`,
        }, {pattern: `${pattern}`});
    },
    email: ()=>{

        return intl.formatMessage({
            id: `validation.error.email`,
            defaultMessage: 'not valid email',
            description: `Form validation email`,
        }, {});
    }
}));

const defaultMessageProvider: MessageProvider = (): string => {
    return 'invalid field';
};



export interface FormValidationUtil {



    /**
     * Constructs and return the name key of the react-hook-form @Controller component.
     * !! hasFieldError and getFieldErrorMessage should also use this name key to get errors.!!
     * @param fieldName
     * @param repeatableIndex
     */
    fieldValidationKeyFactory(fieldName: string, repeatableIndex?: number): string;
    hasFieldError(fieldName: string, error: FormValidationError,formState?:FormState<any>): boolean;
    getFieldErrorMessage(fieldName: string, error: FormValidationError):string;
    extractRules(fv_value:GenericFieldParams): ValidationRules;
}





interface ValidationRule<T> {
    value: T;
    message: string;
}

interface ValidationRules {
    minLength?: ValidationRule<string>;
    maxLength?: ValidationRule<string>;
    required?: ValidationRule<boolean>;
    pattern?: ValidationRule<RegExp>;
    min?: ValidationRule<number>;
    max?: ValidationRule<number>;
    email?: ValidationRule<boolean>;
    validate?: {
        [key: string]: (value: string | null) => boolean | string;
    }
}

/**
 * Supported conditional validation rule names.
 */
type ConditionalValidationName = 'conditionalRequired' | 'conditionalMinValue' | 'conditionalMaxValue' | 'conditionalMaxLength' | 'conditionalMinLength';
/**
 * Supported conditional validation rule configurations.
 */
type ConditionalValidationRuleConfig =
    /**
     * conditionalRequired
     */
    string;

interface ConditionalValidationRule {
    /**
     * @param config
     * @returns true for valid rule configuration else false.
     */
    isValidConfig(config: ConditionalValidationRuleConfig): boolean;

    /**
     * Evaluate validation configuration upon context evaluator.
     * @param config
     * @param context
     */
    evaluate(config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never;

    /**
     * @returns the mapped validation rule configuration.
     */
    getMappedRuleConfig(): object;

    /**
     * The rule name.
     */
    getName(): ConditionalValidationName;
}

/**
 * Conditional required rule implementation.
 */
const conditionalRequiredRuleFactory = (): ConditionalValidationRule => {
    const getName = (): ConditionalValidationName => {
        return 'conditionalRequired';
    };

    const isValidConfig = (config: ConditionalValidationRuleConfig): boolean => {
        return typeof config === 'string' && config.length > 0;
    };

    const getMappedRuleConfig = (): object => {
        return {
            required: true
        };
    };

    const evaluate = (config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never => {
        const condition: string = config;
        return context.eval(condition);
    }

    return {
        getName,
        isValidConfig,
        getMappedRuleConfig,
        evaluate,
    }
}

/**
 * Conditional min value rule implementation.
 */
const conditionalMinValueRuleFactory = (): ConditionalValidationRule => {
    const getName = (): ConditionalValidationName => {
        return 'conditionalMinValue';
    };

    const isValidConfig = (config: ConditionalValidationRuleConfig): boolean => {
        return typeof config === 'string' && config.length > 0;
    };

    let minValue: number | null = null;

    const getMappedRuleConfig = (): object => {
        return minValue === null ? {} : {
            min: minValue
        };
    };

    const evaluate = (config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never => {
        const condition: string = config;
        const result = context.eval(condition);
        if(isFinite(result)) {
            minValue = result;
            return true;
        }
        else {
            minValue = null;
            return false;
        }
    }

    return {
        getName,
        isValidConfig,
        getMappedRuleConfig,
        evaluate,
    };
}

/**
 * Conditional max value rule implementation.
 */
const conditionalMaxValueRuleFactory = (): ConditionalValidationRule => {
    const getName = (): ConditionalValidationName => {
        return 'conditionalMaxValue';
    };

    const isValidConfig = (config: ConditionalValidationRuleConfig): boolean => {
        return typeof config === 'string' && config.length > 0;
    };

    let maxValue: number | null = null;

    const getMappedRuleConfig = (): object => {
        return maxValue === null ? {} : {
            max: maxValue
        };
    };

    const evaluate = (config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never => {
        const condition: string = config;
        const result = context.eval(condition);
        if(isFinite(result)) {
            maxValue = result;
            return true;
        }
        else {
            maxValue = null;
            return false;
        }
    }

    return {
        getName,
        isValidConfig,
        getMappedRuleConfig,
        evaluate,
    };
}

/**
 * Conditional max length rule implementation.
 */
const conditionalMaxLengthRuleFactory = (): ConditionalValidationRule => {
    const getName = (): ConditionalValidationName => {
        return 'conditionalMaxLength';
    };

    const isValidConfig = (config: ConditionalValidationRuleConfig): boolean => {
        return typeof config === 'string' && config.length > 0;
    };

    let maxValue: number | null = null;

    const getMappedRuleConfig = (): object => {
        return maxValue === null ? {} : {
            maxLength: maxValue
        };
    };

    const evaluate = (config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never => {
        const condition: string = config;
        const result = context.eval(condition);
        if(isFinite(result)) {
            maxValue = result;
            return true;
        }
        else {
            maxValue = null;
            return false;
        }
    }

    return {
        getName,
        isValidConfig,
        getMappedRuleConfig,
        evaluate,
    };
}

/**
 * Conditional min length rule implementation.
 */
const conditionalMinLengthRuleFactory = (): ConditionalValidationRule => {
    const getName = (): ConditionalValidationName => {
        return 'conditionalMinLength';
    };

    const isValidConfig = (config: ConditionalValidationRuleConfig): boolean => {
        return typeof config === 'string' && config.length > 0;
    };

    let minLength: number | null = null;

    const getMappedRuleConfig = (): object => {
        return minLength === null ? {} : {
            minLength: minLength
        };
    };

    const evaluate = (config: ConditionalValidationRuleConfig, context: SancusConditionEvaluator): boolean | never => {
        const condition: string = config;
        const result = context.eval(condition);
        if(isFinite(result)) {
            minLength = result;
            return true;
        }
        else {
            minLength = null;
            return false;
        }
    }

    return {
        getName,
        isValidConfig,
        getMappedRuleConfig,
        evaluate,
    };
}

interface ConditionalValidationRulesManager {
    /**
     * Apply rule for valid configuration to target configuration object for supported rule if condition evaluated success to context.
     * @param ruleName
     * @param ruleConfig the conditional validation the rule is tested.
     * @param context the context to apply the rule configuration validation.
     * @param targetConfiguration the target configuration object to apply the mapped conditional validation rule.
     * @returns the transformed? target configuration
     */
    applyConfiguration(ruleName: ConditionalValidationName, ruleConfig: any, context: SancusConditionEvaluator, targetConfiguration: object): object;

    /**
     * @param ruleName
     * @returns true if manager supports rule else false.
     */
    supports(ruleName: string): boolean;

    /**
     * Add support for rule.
     * @param rule
     */
    put(rule: ConditionalValidationRule): void;
}

const conditionalValidationRulesManagerFactory = (): ConditionalValidationRulesManager => {
    const reg: {[key: string]: ConditionalValidationRule} = {};

    const supports = (ruleName: string): boolean => {
        return !!reg[ruleName];
    };

    const applyConfiguration = (ruleName: ConditionalValidationName, ruleConfig: any, context: SancusConditionEvaluator, targetConfiguration: object): object => {
        if(supports(ruleName)) {
            const rule: ConditionalValidationRule = reg[ruleName];
            if(rule.isValidConfig(ruleConfig)) {
                const valid = rule.evaluate(ruleConfig, context);
                if(valid) {
                    const mappedConfig = rule.getMappedRuleConfig();
                    const res = Object.assign(targetConfiguration, mappedConfig);
                    return res;
                }
                else {
                    console.debug(`conditional rule ${ruleName} not valid in context and skipped`);
                }
            }
            else {
                console.warn(`invalid configuration for conditional validation rule: ${ruleName}`);
            }
        }
        else {
            console.warn(`configuration manager for conditional rule: ${ruleName} is not supported`);
        }
        return targetConfiguration;
    };

    const put = (rule: ConditionalValidationRule): void => {
        reg[rule.getName()] = rule;
    }

    return {
        supports,
        applyConfiguration,
        put,
    }
}

const conditionalValidationRulesManager = conditionalValidationRulesManagerFactory();
conditionalValidationRulesManager.put(conditionalRequiredRuleFactory());
conditionalValidationRulesManager.put(conditionalMinValueRuleFactory());
conditionalValidationRulesManager.put(conditionalMaxValueRuleFactory());
conditionalValidationRulesManager.put(conditionalMinLengthRuleFactory());
conditionalValidationRulesManager.put(conditionalMaxLengthRuleFactory());


class RegExpWithComments{
    private lastEncounter:[number,number,string] | null = null;
    private patternInternal: string;
    private pos: number;

    private regExp: RegExp;
    private comment: null | string;
    constructor(private pattern: string) {
        this.patternInternal = pattern.slice();
        this.pos = 0
        const [patternInternal,comment] = this.getPatternAndComment(pattern);
        this.comment = comment;
        this.regExp = new RegExp(patternInternal!);
        this.regExp['comment'] = comment;
    }
    getRegExp(){
        return this.regExp;
    }

    getPatternAndComment(pattern){


       let lastComment:string | null = null;
        const tei = this.getRegExpParts("(",")")

        while(true){
            const {done,value} = tei.next()
            if (done || value == null){
                break;
            }
            const [start,end,substring] = value!;


            if (substring.startsWith("(?#")){
                lastComment = substring.substring(3,substring.length-1);
                this.remove();
            }


        }
        return [this.patternInternal,lastComment];
    }

    remove(){
        if (this.lastEncounter){
            const [start,end] = this.lastEncounter;
            this.patternInternal = this.patternInternal.substring(0,start) + this.patternInternal.substring(end);
            this.pos -= (end-start);
        }
    }

    * getRegExpParts(openingToken:string,closingToken:string) {



        let isOpening = false
        const stack:number[] = [];
        while(true){
            const pattern = this.patternInternal;
            const openPos = pattern.indexOf(openingToken, this.pos);
            const closingPos = pattern.indexOf(closingToken, this.pos);
            if (openPos !=-1 && openPos < closingPos) {
                isOpening = true;
                this.pos = openPos;
            }else{
                isOpening = false;
                this.pos = closingPos;
            }

            if (this.pos == -1){
                if (stack.length > 0){
                    throw "Unbalanced tokens";
                }
                break;
            }
            let _pos = this.pos;
            let previousEscapes = 0;
            while (_pos > 0 && pattern.charAt(_pos-1) == '\\'){
                previousEscapes++;
                _pos--;
            }
            const isEscaped = previousEscapes % 2 == 1;
            if (isEscaped){

            }else if (isOpening){
                stack.push(this.pos);
                this.pos+=openingToken.length;
            }else {
                var open = stack.pop();
                var substring = pattern.substring(open!, this.pos + closingToken.length);
                this.lastEncounter = [open!, this.pos + closingToken.length, substring];
                this.pos+=closingToken.length;
                yield this.lastEncounter
            }
        }
        return null;
    }
}

/**
 * Extract validation from field.
 * @param fv_value
 */
export const extractValidation = (fv_value: undefined | {
    validationConstraints:FormPropertyValidationConstraint[],
    contextEvaluator:SancusConditionEvaluator,
    properties?:{
        pattern?:string,
        component?:string
    }
}): Validation => {
    const validation: Validation = fv_value && fv_value.validationConstraints && (fv_value.validationConstraints.reduce((acc, vc) => {
        let {name, configuration} = vc;
        let s_name = name as string;
        if (substitutions[name]){
            s_name = substitutions[name];
        }
        if(conditionalValidationRulesManager.supports(s_name)) {
            const config = conditionalValidationRulesManager.applyConfiguration(s_name as any, configuration, fv_value.contextEvaluator, acc);
            return config as Validation;
        }
        else {
            if (configuration) {
                acc[s_name] = configuration
            } else {
                acc[s_name] = true
            }
        }
        return acc;
    }, {})) || {};

    const pattern:string | undefined= fv_value?.validationConstraints?.find(vc=>vc.name == 'pattern')?.configuration || fv_value?.properties?.pattern;
    const email:FormPropertyValidationConstraint | undefined = fv_value?.validationConstraints?.find(vc=>vc.name == 'email')

    if (pattern){
        validation.pattern = new RegExpWithComments(pattern).getRegExp();
    }
    if (email){
        validation.email = true;
    }


    return validation;
};

const dateFormats = ['DD-MM-YYYY',
    'YYYY-MM-DDTHH:mm:ssZ',
    'DD/MM/YYYY','DD.MM.YYYY',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'DD-MM-YY',
    'DD/MM/YY',
    'DD.MM.YY',
    'YY-MM-DD',
    'YY/MM/DD',
    'YY.MM.DD'];
class IntlFormValidationUtil implements FormValidationUtil{
    constructor(protected intl: IntlShape){};

    fieldValidationKeyFactory(fieldName: string, repeatableIndex?: number,groupNameOrPath?:string): string {
        // return `${fieldName}${repeatableIndex !== undefined ? '_' + repeatableIndex : ''}`;
        if (groupNameOrPath != undefined){
            const [_groupName,_idx,fieldId] = IndexDecoratorFormValidationUtilFactory.getFieldDetails(groupNameOrPath);
            if (_groupName === undefined && _idx === undefined){
                //Then groupNameOrPath indeed contains the groupName
                if (repeatableIndex !== undefined) {
                    return `${groupNameOrPath}.${repeatableIndex}.${fieldName}`;
                }else {
                    return `${groupNameOrPath}.${fieldName}`;
                }
            }else{
                if (_idx != null){
                    return `${_groupName}.${_idx}.${fieldId}`;
                }else{
                    return `${_groupName}.${fieldId}`;
                }
            }
        }
        return `${fieldName}`;
    }


    private unwrapError(fieldName: string,error: FormValidationError): FormValidationError | null{
        const fieldPathParts = fieldName.split('.');
        if (!error){
            return null;
        }
        let clientErrorOnField = error;
        for (let i = 0; i < fieldPathParts.length; i++) {
            const fieldPath = fieldPathParts[i]
            if (/^(\w+)\[(\d+)]$/.test(fieldPath)){
                const [_,_fieldPath,_idx] = /^(\w+)\[(\d+)]$/.exec(fieldPath)!;
                const ret = clientErrorOnField[_fieldPath] && clientErrorOnField[_fieldPath][_idx];
                clientErrorOnField = ret;
            }else {
                const ret = clientErrorOnField[fieldPath];
                clientErrorOnField = ret;
            }
            if (!clientErrorOnField) {
                return null;
            }
        }
        return clientErrorOnField;
    }

    hasFieldError (fieldName: string, error: FormValidationError,stateFormDirtyForms?:FormState<any>): boolean {
        const clientErrorOnField = this.unwrapError(fieldName,error);
        if (!clientErrorOnField){
            return false;
        }
        //if the formState is passed - it means we want to skip validation for dirty forms (forms tah are being processed)
        // and only show validation error on submit
        const isOwnClientError = clientErrorOnField?.ref;
        const fromServer = clientErrorOnField?.origin == 'server';

        const clientErrorIsDirtyAware = clientErrorOnField && !(stateFormDirtyForms?.isDirty ?? false);
        const ret =  !!(clientErrorIsDirtyAware && (fromServer || isOwnClientError) && clientErrorOnField);
        if (typeof ret !== 'boolean'){
            console.error("hasFieldError:Field %s returned type %s",fieldName,ret);
        }
        return ret
    };



    formatMessage(type:string,config:any){
        const messageMapProvider =  messageMapProviderFactory(this.intl);
        const fn = messageMapProvider[type];
        if (!fn){
            return defaultMessageProvider();
        }
        if (Array.isArray(config)){
            return fn(...config);
        }else {
            return fn(config);
        }
    }

    getFieldErrorMessage (fieldName: string, error: FormValidationError){
        const clientErrorOnField = this.unwrapError(fieldName,error);
        if (!clientErrorOnField){
            return false;
        }

        const isOwnClientError = clientErrorOnField?.ref;
        const fromServer = clientErrorOnField?.origin == 'server';

        if (!(isOwnClientError||fromServer)){
            return '';
        }

        const messageMapProvider =  messageMapProviderFactory(this.intl);
        let message;
        if (clientErrorOnField){
            let {type,message:clientMessage,config} = clientErrorOnField;

            const fn = messageMapProvider[type];
            if (fn && !clientMessage){
                if (Array.isArray(config)){
                    clientMessage = fn(...config);
                }else {
                    clientMessage = fn(config);
                }
            }
            message = clientMessage;
        }

        if (message == "__empty__"){
            return ''
        }else if (!message){
            return defaultMessageProvider();
        }else{
            return message;
        }

    };

    extractRules (fv_value: GenericFieldParamsBase ) : ValidationRules {

        if (!fv_value.formReference){
            console.debug("No form reference for field",fv_value);
        }

        const isGroup = fv_value.formReference?.some(f=>{
            return f.properties?.group == fv_value.id
        })

        const isGroupRepeatable = fv_value.formReference?.some(f=>{
            return f.properties?.group_repeatable == fv_value.id
        });



        const validation: Validation = extractValidation(fv_value);

        const validationRules = Object.keys(validation).reduce((rules: ValidationRules, ruleName: string) => {
            const validationConfig = validation[ruleName];
            const messageMapProvider =  messageMapProviderFactory(this.intl);
            return {
                ...rules,
                [ruleName]: {
                    value: validationConfig,
                    message: messageMapProvider.hasOwnProperty(ruleName)
                        ? messageMapProvider[ruleName].apply(null, [validationConfig])
                        : defaultMessageProvider()
                }
            };

        }, {});

        if (validationRules.email){
            validationRules['validate'] = validationRules['validate'] || {}
            validationRules['validate']['email'] = (value: string | null): boolean | string => {
                if(value === null || value === '') {
                    return true;
                }else {
                    return emailPattern.test(value);
                }
            }
        }

        if(isDateType(extractFieldType(fv_value))) {
            validationRules['validate'] = {
                ...validationRules['validate'] ? validationRules['validate'] : {},
                date: (value: string | null): boolean | string => {
                    if(value === null || value === ''|| value === undefined) {
                        return true;
                    }
                    else {
                        var valid = dateFormats.find(df=>moment(value, df).isValid())

                        return !!valid;
                    }
                }
            }
        }else if (fv_value.properties?.component == 'YesNo'){
            if (validationRules.required) {
                delete validationRules['required'];

                validationRules['validate'] = {
                    ...validationRules['validate'] ? validationRules['validate'] : {},
                    required: (value: any): boolean | string => {
                        var vv = value && value.postProcessGet ? value.postProcessGet() : value;
                        if (value === '') {
                            return false;
                        } else {
                            return vv != null;
                        }
                    }
                }
            }

        }else if (fv_value.properties?.component){
            const Cmp = registry.get(fv_value.properties?.component)
            const {requiredFN} = Cmp
            if (requiredFN){
                validationRules['validate'] = {
                    ...validationRules['validate'] ? validationRules['validate'] : {},
                    required: (value:any): boolean | string => {
                        if (fv_value.validationConstraints?.find(vc=>vc.name=='required')){
                            return requiredFN(value);
                        }else{
                            return true;
                        }

                    }
                }
            }
        }else if (isGroupRepeatable){

            const toAssign = {} as any;
            if (validationRules.required) {
                delete validationRules['required'];
                toAssign.required = (value: any): boolean | string => {
                    return true;
                }
            }
            if (validationRules.min) {
                delete validationRules['min'];
                toAssign.min = (value: any): boolean | string => {
                    return true;
                }
            }

            if (validationRules.max) {
                delete validationRules['max'];
                toAssign.max = (value: any): boolean | string => {
                    return true;
                }
            }


            validationRules['validate'] = {
                ...validationRules['validate'] ? validationRules['validate'] : {},...toAssign
            }
        }
        return validationRules;
    }
}

export class IndexDecoratorFormValidationUtilFactory extends IntlFormValidationUtil{
    static isGroupField(fieldName:string):boolean{
        return fieldName.indexOf('.') !== -1;
    }
    static getFieldId(path:string):string{
        if (path.lastIndexOf('.') !== -1){
            return path.substring(path.lastIndexOf('.')+1);
        }else{
            return path;
        }
    }

    static getFieldDetails(path:string):[group:string | undefined,idx:number | undefined,fieldId:string]{
        //Path can be in the form groupName[idx].fieldId or groupName.idx.fieldId or groupName.fieldId
        if (/^\w+\[\d+]\.\w+$/.test(path)) {
            const idx = parseInt(path.substring(path.indexOf('[') + 1, path.indexOf(']')));
            const groupName = path.substring(0, path.indexOf('['));
            const fieldId = path.substring(path.lastIndexOf('.') + 1);
            return [groupName, idx, fieldId];
        } else if (/^\w+\.\d+\.\w+$/.test(path)) {
            const idx = parseInt(path.substring(path.indexOf('.') + 1, path.lastIndexOf('.')));
            const groupName = path.substring(0, path.indexOf('.'));
            const fieldId = path.substring(path.lastIndexOf('.') + 1);
            return [groupName, idx, fieldId];
        } else if (/^\w+\.\w+$/.test(path)) {
            const groupName = path.substring(0, path.lastIndexOf('.'));
            const fieldId = path.substring(path.lastIndexOf('.') + 1);
            return [groupName, undefined, fieldId];
        }else {
            return [undefined,undefined,path];
        }




    }

    private readonly groupName:string | undefined;
    private readonly fieldId:string | undefined;
    constructor(private index:number | undefined ,pathOrGroup:string | undefined, intl: IntlShape) {
        super(intl);
        if (this.index != null && pathOrGroup == null) {
            throw "Path or group is required for repeatable fields with index:";
        }
        if (pathOrGroup) {
            const [_groupName, _idx, fieldId] = IndexDecoratorFormValidationUtilFactory.getFieldDetails(pathOrGroup);
            if (_groupName === undefined && _idx === undefined) {
                this.groupName = pathOrGroup;
            }else{
                this.groupName = _groupName;
                this.fieldId = fieldId;
                if (_idx !== undefined && index !== _idx) {
                    throw `Index mismatch between path ${pathOrGroup} and index:${index} and _idx:${_idx}`
                }
                if (_idx === undefined && index !== undefined && index >=0) {
                    throw `Index mismatch between path ${pathOrGroup} and index:${index} and _idx:${_idx}`
                }
            }

            if (this.groupName === undefined) {
                throw "Failed to extract groupName from path:" + pathOrGroup;
            }
        }


    }

    fieldValidationKeyFactory(fieldName: string): string {
        return super.fieldValidationKeyFactory(fieldName, this.index,this.groupName);
    }

    getFieldErrorMessage(fieldName: string, error: FormValidationError){
        const key = this.fieldValidationKeyFactory(fieldName);
        return super.getFieldErrorMessage(key, error);
    }

    hasFieldError(fieldName: string, error: FormValidationError,state?:FormState<any>){
        const key = this.fieldValidationKeyFactory(fieldName);
        return super.hasFieldError(key, error,state);
    }
}

let _formFieldValidationUtil;

const formFieldValidationUtil = (intl)=> {
    if (_formFieldValidationUtil == null){
        _formFieldValidationUtil = new IntlFormValidationUtil(intl);
    }
    return _formFieldValidationUtil;
}

export default formFieldValidationUtil;


