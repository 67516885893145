import BpmnElementFactory from 'bpmn-js/lib/features/modeling/ElementFactory';
import sancusPalletElementsInfo from "./SancusPalletElementsInfo";

/**
 * Sancus Integrations are stored as special sancus:sancusService xml attribute on bpmn:CallActivity etc. (see SancusModdle.json)
 * Element factory takes care that a sancusService is populated with the correct attribute (businessObject.sancusService) upon creation
 * Note: the element template is applied using CustomUpdater on postExecute phase.
 */
export default class CustomElementFactory extends BpmnElementFactory {
    constructor(bpmnFactory, moddle) {
        console.debug("Contructor of CustomElementFactory")
        super(bpmnFactory, moddle);
    }


    create(elementType, attrs) {
        if (attrs.businessObject?.sancusService){
            attrs.sancusService = attrs.businessObject.sancusService
        }else if (attrs.businessObject?.modelerTemplate){

            if (attrs.businessObject?.modelerTemplate.startsWith('com.norbloc.sancus.integrations.')) {
                const shortId = attrs.businessObject?.modelerTemplate.split('.').pop()
                attrs.sancusService = shortId;
            }
        }




        //If there is already a businessObject then we don't need to create one
        if (attrs.sancusService && !attrs.businessObject) {
            const businessObject = this._bpmnFactory.create(attrs.type);

            const extensionElements = businessObject.extensionElements || this._moddle.create('bpmn:ExtensionElements');
            const integrationModdle = this._moddle.create('sancus:IntegrationExt');
            integrationModdle.sancusServiceExt = attrs.sancusService
            extensionElements.get('values').push(integrationModdle);

            Object.assign(businessObject, {
                name: attrs.sancusService,
                extensionElements,
                sancusService:attrs.sancusService
            });
            attrs.businessObject = businessObject;

            const element = super.create(elementType, attrs);

            return element;

        }
        return super.create(elementType, attrs);
    }
}

CustomElementFactory.$inject = [
    'bpmnFactory',
    'moddle'
];
