import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';
import {ICheck} from "../../common/FormContainerBase";

/**
 * Map available actions that change state
 */
export namespace CheckActions {
	export enum Type {
		APPLY_CHECK_FILTER = 'APPLY_CHECK_FILTER',
		SET_CHECKS = 'SET_CHECKS',
		SET_SELECTED_CHECK = 'SET_SELECTED_CHECK',
	}

	export const applyAlertFilter = createAction<string>(Type.APPLY_CHECK_FILTER);
	export const setChecks = createAction<ICheck[]>(Type.SET_CHECKS);
	export const setSelectedCheck = createAction<ICheck>(Type.SET_SELECTED_CHECK);
}

export type CheckActions = Omit<typeof CheckActions, 'Type'>;
export const useCheckActions = (dispatch: Dispatch) => {
	const { Type, ...actions } = CheckActions;
	return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as CheckActions;
};