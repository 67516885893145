import {AlertEntity, PEPResult, PEPResultV2, PEPResultV1, AlertInfo} from "./pepApi.interface";

/**
 * @param pepResult
 * @returns true if pep result has alert entities else false.
 */
export const hasAlerts = (pepResult: PEPResult): boolean => {
    const alert = getAlertEntities(pepResult);
    return !!alert && alert.length >0
}


/**
 * @param pepResult
 * @returns AlertEntity[] if any.
 */
export const getAlertEntities = (pepResult: PEPResult): AlertEntity[] | undefined => {
    let alertInfo: AlertInfo[] | undefined;
    if ("alerts" in pepResult) {
        alertInfo = (pepResult as PEPResultV2).alerts?.map(a => a.gridAlertInfo)
    } else if ("gridInquiryResultsRec" in pepResult) {
        //V1
        alertInfo = (pepResult as PEPResultV1).gridInquiryResultsRec.gridAlertRec.map(a => a.gridAlertInfo)
    }
    if (alertInfo) {
        return alertInfo.map(a => a.alerts).reduce<AlertEntity[]>((acc, a) => {
            acc.push(
                ...a.alertEntity,
                ...a.nonReviewedAlertEntity
            );
            return acc;
        }, [])
    }
}
export const getPersonQuery = (pepResult: PEPResult) => {
    let query;
    if ("alerts" in pepResult) {
        const queries = (pepResult as PEPResultV2).alerts?.map(a => a.gridAlertInfo.gridInquiryRec.gridInquiryInfo.gridPersonPartyInfo?.gridPersonData);
        query = queries && queries[0]
    }else if ("gridInquiryResultsRec" in pepResult) {
        const queries = (pepResult as PEPResultV1).gridInquiryResultsRec.gridAlertRec?.map(a => a.gridAlertInfo.gridInquiryRec.gridInquiryInfo.gridPersonPartyInfo?.gridPersonData);
        query = queries && queries[0]
    }
    if (query){
        return query.personName;
    }else{
        return pepResult['query'];
    }

}

export const getAlertId = (pepResult:PEPResult) =>{
    if ("alerts" in pepResult) {
        return (pepResult as PEPResultV2).alerts[0]?.gridAlertId
    }else if ("gridInquiryResultsRec" in pepResult) {
        return pepResult.gridInquiryResultsRec.gridAlertRec[0]?.gridAlertId;
    }
}
