import {FormHandlerStrategy} from "./handlers";
import {
    FormField,
    ProcessDefinition,
    ProcessInstance,
    ProcessInstanceStatus,
    StartFormData, Task,
    TaskFormData,
    Variables
} from "./camunda_helper";

export class JsonFormHandlerStrategy implements FormHandlerStrategy {
    constructor(private name:string,
                private description:string,
                private formFields:FormField[],
                private submitFN:(_vars)=>Promise<void>,
    ) {
    }

    loadTasks(processInstanceId: string): Promise<Task[]> {
        throw new Error("loadTask not implemented");
    }

    sendSignal(processInstanceId: string, signalName: string, variables?: Variables): Promise<void> {
        throw new Error("fetchTaskList not implemented");
    }

    raiseError(taskId: string, errorCode: string, errorName?: string, data?: { [p: string]: any }): Promise<TaskFormData | null>  {
        throw new Error("raiseError not implemented");
    }

    fetchTaskList(idr: "key" | "id", identifier: string,processInstanceId?:string | null): Promise<{ startForm: boolean; taskDefs: { [p: string]: string }; stepName: string }[]> {
        throw new Error("fetchTaskList not implemented");
    }

    getUserSessionInfo(): Promise<any> {
        throw new Error("getUserSessionInfo not implemented");
    }

    logoutUserSession(): Promise<any> {
        throw new Error("logoutUserSession not implemented");
    }

    getProcessInstanceStatus(processInstanceId: string): Promise<ProcessInstanceStatus> {
        throw new Error("getProcessInstanceStatus not implemented");
    }

    handleProcessInstanceId(processInstanceId: string): Promise<[string, (TaskFormData | ProcessInstanceStatus)]> {
        throw new Error("handleProcessInstanceId not implemented");
    }

    async handleProcessKey(key: string, resume: boolean): Promise<StartFormData | [string, (TaskFormData | ProcessInstanceStatus)]> {
        const processDefinition:ProcessDefinition = {id: "_processDef_id", key: "_processDef_key", name:this.name,description:this.description}
        const startFormData:StartFormData = {deploymentId: "_deploymentId", formFields:this.formFields, processDefinition}
        return startFormData;
    }

    handleTask(taskId: string): Promise<[string, TaskFormData]> {
        throw new Error("handleTask not implemented");
    }

    resumeActivityBefore(processInstanceId: string, activityId: string): Promise<TaskFormData | StartFormData | null> {
        throw new Error("resumeActivityBefore not implemented");
    }

    resumeActivityAfter(processInstanceId: string, activityId: string): Promise<TaskFormData | StartFormData | null> {
        throw new Error("resumeActivityAfter not implemented");
    }

    discardProcessInstance(processInstanceId:string,taskId:string,reason?:string):Promise<void>{
        throw new Error("discardProcessInstance not implemented");
    }

    async startProcessBy(idr: "id" | "key", processDefinitionIdOrKey: string, variables?: Variables, businessKey?: string): Promise<{ processInstance: ProcessInstance; taskFormData: TaskFormData | null }> {
        await this.submitFN(variables!);
        const processInstance:ProcessInstance = {definitionId: "_processDefinition_id", ended: true, id: "_processInstance_id", links: [], suspended: false};
        return {processInstance,taskFormData:null};
    }

    submitTaskForm(taskId: string, processInstanceId: string | null | undefined, taskDefId: string | null | undefined, variables: Variables): Promise<TaskFormData | null> {
        throw new Error("submitTaskForm not implemented")
    }

    addCheckNote(processInstanceId: string, checkId: string, taskId: string, text: string): Promise<any> {
        throw new Error("addCheckNote not implemented");
    }

    setCheckStatus(processInstanceId: string, checkId: string, taskId: string, resolve: boolean): Promise<any> {
        throw new Error("setCheckStatus not implemented");
    }

}
