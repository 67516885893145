import {combineReducers} from "redux";
import {RootState} from "./root.state";
import {checksReducer} from './check.reducer'
import {checksFilterReducer} from "./checkFilter.reducer";
import {selectedCheckReducer} from "./selectedCheck.reducer";
import {formContainerErrorReducer} from "./formContainerError.reducer";
import {formContainerInProgressReducer} from "./formContainerInProgress.reducer";
import {formContainerValueChangeReducer} from "./formContainerValueChange.reducer";
import {auditTasksReducer} from "./auditTasks.reducer";
import {userSessionInfoReducer} from "./userSessionInfo.reducer";
import {auditTaskReviewReducer} from "./auditTask.review.reducer";
import {sectionReducer} from "./section.reducer";
import {designerReducer} from "./designer.reducer";
import {formDialogReducer} from "./formDialog.reducer";
import {annotatorReducer} from "./annotator.reducer";
import {formContainerValidationErrorReducer} from "./formContainerValidationError.reducer";
import {repeatableDialogReducer} from "./repeatableDialog.reducer";

/**
 * Handle part of state
 */
export const rootReducer = combineReducers<RootState>({
	checks: checksReducer,
	checkFilter: checksFilterReducer,
	selectedCheck: selectedCheckReducer,
	error: formContainerErrorReducer,
	validationError: formContainerValidationErrorReducer,
	inProgress: formContainerInProgressReducer,
	valueChanged: formContainerValueChangeReducer,
	auditTasks: auditTasksReducer,
	userSessionInfo: userSessionInfoReducer,
	auditTaskForReview: auditTaskReviewReducer,
	section:sectionReducer,
	designer:designerReducer,
	shownFormDialog: formDialogReducer,
	annotator:annotatorReducer,
	showRepeatableDialog:repeatableDialogReducer
});
