import * as React from "react";
import {Grid, TextareaAutosize} from "@material-ui/core";
import {Ref, useState} from "react";

export default function JsonValueEditor<T=object | any[]>(props:{
    elemRef:Ref<HTMLTextAreaElement>,
    name:string,
    label:string,
    value:T
    readOnly?:boolean
    valueChangeCbk:(value:T)=>void
}){
    const [error,setError] = useState<unknown>()
    const asString = JSON.stringify(props.value,null, '\t')

    const onChange = event=>{
        event.preventDefault();
        if (props.readOnly){
            return false;
        }
        const value = event.target.value
        try {
            const toJson = JSON.parse(value)
            props.valueChangeCbk(toJson);
        }catch (e) {
            setError(e);
        }
    }

    return <Grid><TextareaAutosize style={{ width: '90%', padding: '32px', border: '1px solid #80808038' }}
            aria-label={props.label}
            value={asString}
            onChange={onChange}
            readOnly={props.readOnly}
            ref={props.elemRef}
    /></Grid>
}