import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {Box, Card, CardContent} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    red: {
        borderLeft: 'solid 3px #FF0000',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },
    green: {
        borderLeft: 'solid 3px #99cc33',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },
    yellow: {
        borderLeft: 'solid 3px #ffcc00',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    }

}));

export default function NotificationComponent ({notification, closeClicked}) {
    const allowedLevelsAndClassNames = {"info": "green", "warn": "yellow", "danger": "red"};
    const className = allowedLevelsAndClassNames[notification.level || ""] || "green";
    const classes = useStyles();

    let customComponent = <>
        <Grid className={classes[className]}>
            {/*<Typography variant="body2"><div dangerouslySetInnerHTML={{__html: message.message}}/></Typography>*/}
            <Typography variant="body2">{notification.text} {notification.closeable && <IconButton size="small"
                                                                                                   onClick={() => closeClicked && closeClicked()}
            ><ClearIcon fontSize="small"/></IconButton>}
            </Typography>
        </Grid>
    </>;

    return (
        <Box>
            <Card elevation={0}
                  style={{
                      borderRadius: '9px', boxShadow: '0px 6px 42px 0px rgb(0 0 0 / 10%)',
                      border: 'solid 3px #e7eff7',
                      background: 'white',
                      // borderColor: props.isSelected ? '#26a69a' : 'transparent',
                      margin: '24px',
                  }}>

                <CardContent style={{padding: '16px 16px 16px 8px'}}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs>{customComponent}</Grid>
                    </Grid>
                </CardContent>
                <Grid>
                </Grid>
            </Card>
        </Box>
    );
}
