import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import {AlertContext} from "../context/alert/alertContext";
import {contextPath} from "../contextPath";
import Grid from "@material-ui/core/Grid";
import DashboardCard from "../components/dashboradComponents/DashboardCard";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Doughnut from "../components/dashboradComponents/Doughnut";
import LineChart from "../components/dashboradComponents/LineChart";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import DoughnutSimple from "../components/dashboradComponents/DoughnutSimple";

import "chartist/dist/chartist.min.css"

import NotificationComponent from "../components/dashboradComponents/Notification";
import ChartJSCustomTooltip from "./ChartJSCustomTooltip";


let click_lock = false;

const search_journey='search_applications'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 35,
    },
    paper: {
        color: theme.palette.text.secondary,
    },
    chartTitle: {
        fontWeight: 100,
        padding: 16,
    },
    welcomeContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
}));


const dashboardCardsDictionary = {
    "pending": {
        title: "Pending Tasks",
        fetchLink: "/user_pending",
    },
    "completedToday": {
        title: "Completed Today",
        fetchLink: "/user_completed",
    },
    "group": {
        title: "Group Tasks",
        fetchLink: "/group_pending",
    },
    "stale": {
        title: "Overdue Tasks",
        fetchLink: "/stale",
    },
    "workflowActivity": {
        title: "",
        fetchLink: "/applications",
    },
};

const dashboardUrl = `${contextPath}api/dashboard`;
const notificationsUrl = `${contextPath}api/notifications/system`;
const createProcessUrl = `${contextPath}api/process/key`

function prepareDataForChart(receivedData = {}, chartName, colorsWithShades) {

    if (chartName === 'stale') {
        return {
            data: [
                {
                    label: 'More than 1 month',
                    value: receivedData.older,
                    filterName: 'stale_more',
                },
                {
                    label: '1 week',
                    value: receivedData.week,
                    filterName: 'stale_week',
                },
                {
                    label: '1 month',
                    value: receivedData.month,
                    filterName: 'stale_month',
                },
                {
                    label: '1-2 days',
                    value: receivedData.days,
                    filterName: 'stale_day',
                },
            ],
            colors: ['#FF183C', '#FF8400', '#ffcc00', '#99cc33']
        };
    }

    // for tests
    let _receivedData_hardcoded = [{
        "cnt": 13,
        "label": "Retail Onboarding",
        "tasks": [{
            "cnt": 5, "label": "Activity", "tasks": null
        }, {
            "cnt": 4,
            "label": "Put OTP",
            "tasks": null
        }, {
            "cnt": 3, "label": "Contact Step", "tasks": null
        }, {
            "cnt": 1, "label": "OnFido Authorization", "tasks": null
        }]
    }, {
        "cnt": 20,
        "label": "PEP hits review",
        "tasks": [{
            "cnt": 15, "label": "Review", "tasks": null
        }, {
            "cnt": 5, "label": "Approve hit", "tasks": null
        }]
    }];

    if (chartName === 'workflowActivity') {
        let dataSource = receivedData || [];

        const mixedColorsWithSades = colorsWithShades //_.shuffle(colorsWithShades);
        const mixedColorsWithSadesLength = mixedColorsWithSades.length;

        const config = {
            labels: [],
            filterNames: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                datalabels: {
                    anchor: 'center',
                    backgroundColor: null,
                    borderWidth: 0
                }
            }, {
                data: [],
                backgroundColor: [],
                datalabels: {
                    anchor: 'center',
                    backgroundColor: null,
                    borderWidth: 0
                }
            }],
        };



        let idx = 0;

        dataSource.forEach((processData, processIndex) => {
            processData.__idx = idx++;
            processData.__type = 'process';
            config.datasets[0].data.push(0);
            if (mixedColorsWithSadesLength >0) {
                config.datasets[0].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].main);
            }
            config.labels.push(processData.label);
            config.datasets[1].data.push(processData.cnt);
            if (mixedColorsWithSadesLength >0) {
                config.datasets[1].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].main);
            }
        });

        dataSource.forEach((processData, processIndex) => {
            const dataSource = processData.tasks || [];

            dataSource.forEach((taskData, taskIndex) => {
                taskData.__idx = idx++;
                taskData.__type = 'task';
                taskData.__relativeIdx = taskIndex;
                taskData.__parentIdx = processData.__idx;

                config.labels.push(taskData.label);
                config.datasets[0].data.push(taskData.cnt);

                // if we have more tasks than shades
                if (mixedColorsWithSadesLength >0) {
                    const shadesColorsLength = mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].shades.length;
                    config.datasets[0].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].shades[taskIndex % shadesColorsLength]);
                }
            });
        });

        return config;
    }

    if (chartName === 'completedToday') {
        const data = [];
        const labels = [];

        Object.keys(receivedData.month || []).forEach((dateString) => {
            data.push(receivedData.month[dateString]);
            labels.push(moment(dateString).format('MMM D'));
        });

        return {
            data,
            labels
        };
    }
}

function fetchDashboardData({filterName, additionalParams}) {
    const fetchLink = dashboardCardsDictionary[filterName].fetchLink;
    let params = additionalParams;

    return axios({
        method: 'get',
        url: `${dashboardUrl}${fetchLink}`,
        ...(params ? {params: params} : {}),
    })
        .then((response) => response.data)

}

function fetchNotifications() {
    return axios({
        method: 'get',
        url: notificationsUrl,
    })
        .then((response) => response.data)
}

function acknowledgedNotifications(id) {
    return axios({
        method: 'post',
        url: `${notificationsUrl}/${id}`,
    })
        .then((response) => response.data)
}

export const Dashboard = (props) => {

    let params = useParams();

    const {showAlert} = useContext(AlertContext);
    const {setUserLoggedOut, LoggedUserState} = useContext(LoggedUserContext);
    const [dashboardData, setDashboardData] = useState({});
    const [notifications, setNotifications] = useState([]);

    const history = useNavigate();
    const theme = useTheme();



    console.log('dashboardDataState: ', dashboardData);

    const colorsWithShades = theme?.custom?.dashboard?? [];
    useEffect(() => {

            Object.keys(dashboardCardsDictionary).forEach((filterName) => {
                fetchDashboardData({filterName}).then(
                    (data) => {
                        console.log(`${filterName} data:`, data);
                        setDashboardData(
                            (previousState) => {
                                return ({
                                    ...previousState,
                                    [filterName]: {
                                        data: data
                                    }
                                })
                            })
                    },
                    error => showAlert(error, 'error'))
            });

        fetchNotifications()
            .then(data => setNotifications(data),
                error => showAlert(error, 'error'));

        }, []
    );

    function pieOnClickCallback(pieData) {
        console.log('pieOnClickCallback run with options: ', pieData);

        if (pieData.filterName) {
            history(`/tasks?filter=${pieData.filterName}`);
        }
    }

    function onCardClickCallback(cardData) {
        console.log('onCardClickCallback run with options: ', cardData);

        if (cardData.filterName) {
            history(`/tasks?filter=${cardData.filterName}`);
        }
    }
    const classes = useStyles();

    return (
        <div id="dashboard" className={classes.root}>
            <Grid container spacing={5}>
                <Grid item xs={12}>

                    <Grid container className={classes.welcomeContainer}
                          direction="row"
                          justifyContent="center"
                          alignItems="center">

                        <Typography color="textSecondary" variant="h6" className={classes.chartTitle}>
                            Welcome back, {LoggedUserState.user.firstName || LoggedUserState.user.position || 'officer'}
                        </Typography>

                        <Typography color="textSecondary" variant="body2" className={classes.chartTitle} align="right">
                            {moment().format('dddd, D MMM  YYYY')}
                        </Typography>
                    </Grid>

                    <Grid container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start">

                        {notifications && notifications.length > 0 && (
                            notifications.map(notification => <NotificationComponent key={notification.id}
                                                                                     notification={notification}
                                                                                     closeClicked={() => {
                                                                                         acknowledgedNotifications(notification.id).then( () => setNotifications(notifications.filter(n => n.id !== notification.id)),
                                                                                             error => showAlert(error, 'error'));
                                                                                     }}/>)
                        )}

                    </Grid>

                    <Divider variant="fullWidth"/>

                </Grid>

                <Grid item xs={6} sm={6} md={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['pending'] ? (<DashboardCard
                        cardData={{
                            filterName: 'pending',
                            data: dashboardData['pending'].data,
                            ...dashboardCardsDictionary['pending']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                    {dashboardData['completedToday'] ? (<DashboardCard
                        cardData={{
                            filterName: 'completedToday',
                            data: ('today' in dashboardData['completedToday'].data) ? dashboardData['completedToday'].data.today : undefined,
                            ...dashboardCardsDictionary['completedToday']
                        }}
                        onCardClick={null}
                    />) : ''}
                </Grid>


                <Grid item xs={6} sm={6} md={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['group'] ? (<DashboardCard
                        cardData={{
                            filterName: 'group',
                            data: ('total' in dashboardData['group'].data) ? dashboardData['group'].data.total : undefined,
                            ...dashboardCardsDictionary['group']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>

                <Grid item xs={6} sm={6} md={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['stale'] ? (<DashboardCard
                        cardData={{
                            filterName: 'stale',
                            data: ('total' in dashboardData['stale'].data) ? dashboardData['stale'].data.total : undefined,
                            ...dashboardCardsDictionary['stale']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>


                <Grid item xs={12} sm={6}>
                    {dashboardData['completedToday'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Completed Task Activity
                        </Typography>
                        <Divider variant="fullWidth"/>
                        <LineChart
                            config={prepareDataForChart(dashboardData['completedToday'].data, 'completedToday', colorsWithShades)}
                        />
                    </Paper>) : ''}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {dashboardData['stale'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Overdue Tasks
                        </Typography>

                        <Divider variant="fullWidth"/>

                        <Doughnut
                            config={prepareDataForChart(dashboardData['stale'].data, 'stale', colorsWithShades)}
                            onClickCallback={pieOnClickCallback}
                        />
                    </Paper>) : ''}
                </Grid>

                <Grid item xs={12}>
                    {dashboardData['workflowActivity'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Overall Workflow Activity
                        </Typography>

                        <Divider variant="fullWidth"/>

                        <DoughnutSimple
                            config={prepareDataForChart(dashboardData['workflowActivity'].data, 'workflowActivity', colorsWithShades)}
                            customTooltip={{
                                title: (tooltipItems, data) => {
                                    const [tooltipItem] = tooltipItems;
                                    const {datasets,filterNames,labels} = data;
                                    const dataset = datasets[tooltipItem.datasetIndex];
                                    const index = tooltipItem.index;

                                    const label = labels[index];
                                    const value = dataset.data[index];

                                    return `${label}`;
                                },
                                footer: (tooltipItems, data) => {
                                    const [tooltipItem] = tooltipItems;
                                    const {datasets,filterNames,labels} = data;
                                    const dataset = datasets[tooltipItem.datasetIndex];
                                    const index = tooltipItem.index;

                                    const label = labels[index];
                                    const value = dataset.data[index];

                                    return `Total: ${value}`
                                },
                                label: (tooltipItem, data) =>{
                                    const dataSource = dashboardData['workflowActivity'].data || [];

                                    const {datasets,filterNames,labels} = data;
                                    // const dataset = datasets[tooltipItem.datasetIndex];
                                    const index = tooltipItem.index;

                                    const allItems = dataSource.flatMap(d=>{
                                        return [d,...(d.tasks||[])]
                                    })

                                    const hoveredItem = allItems.find(i=>i.__idx === index)
                                    let ret;
                                    if (hoveredItem) {
                                        ret =  Object.entries(hoveredItem.colors).map(([color, value])=>{
                                            let keyDescr;
                                            switch (color){
                                                case 'red':
                                                    keyDescr = 'Older than 30 days';
                                                    break;
                                                case 'orange':
                                                    keyDescr = 'Older than 7 days'
                                                    break;

                                                case 'yellow':
                                                    keyDescr = 'Older than 2 days';
                                                    break;
                                                case 'green':
                                                    keyDescr = 'Older than 10 minutes';
                                                    break;
                                                case 'blue':
                                                    keyDescr = 'In the last 10 minutes';
                                                    break;
                                                case 'white':
                                                    keyDescr = 'In the last 10 seconds';
                                                    break;
                                                default:
                                                    keyDescr = color;
                                            }


                                            return `${keyDescr}:\t ${value}`
                                        })
                                    }else {
                                        ret = [];
                                    }
                                    return ret;
                                },
                                _custom: (canvasSupplier,thisElem)=> tooltipModel=> {
                                    const canvas = canvasSupplier();
                                    const dataSource = dashboardData['workflowActivity'].data || [];


                                    const {dataPoints} = tooltipModel;
                                    if (!dataPoints || dataPoints.length === 0) {
                                        return;
                                    }
                                    const [dataPoint] = dataPoints;
                                    const {index,datasetIndex} = dataPoint;

                                    const allItems = dataSource.flatMap(d=>{
                                        return [d,...(d.tasks||[])]
                                    })

                                    const hoveredItem = allItems[index - 1]

                                    return ChartJSCustomTooltip(tooltipModel,hoveredItem,canvas,thisElem)
                                }
                            }}
                            clickHandler={(event,chartElements)=> {
                                // event.preventDefault();
                                event.stopPropagation();
                                const cbk = ({process,task})=>{
                                    const data = {
                                        queryProcessDefKey:process.key,
                                        // queryProcessTaskDef:task?.key,
                                        queryProcessActivityId:task?.key,
                                        queryProcessState: 'ACTIVE'
                                    }
                                    // history(`/run_journeys/${search_journey}`,{state:{data}})



                                    console.debug("Click locked",click_lock);
                                    if (click_lock) {
                                        console.debug("Click locked");
                                        return;
                                    }else {
                                        console.debug("Setting Click locked");
                                        click_lock = true;
                                    }
                                    axios({
                                        method: 'post',
                                        url:`${createProcessUrl}/${search_journey}/start`,
                                        data
                                    }).then(rsp=>{
                                        const processInstanceId  = rsp.data.processInstance.id
                                        history(`/run_journeys/${search_journey}/${processInstanceId}`)
                                    }).catch(e=>{
                                        showAlert(e,'error')
                                    }).finally(()=>{
                                        console.debug("Click unlocked")
                                        click_lock = false;
                                    })


                                };

                                let dataSource = dashboardData['workflowActivity'].data || [];
                                const [{_index:dataIndex,_datasetIndex:datasetIndex}] = chartElements;
                                console.log("OnClick",event,chartElements)
                                const allItems = dataSource.flatMap(d=>{
                                    return [d,...(d.tasks||[])]
                                })

                                const clickedItem = allItems.find((item,idx)=>item.__idx === dataIndex)

                                if (clickedItem) {
                                    let process,task;
                                    const parent = clickedItem.__parentIdx !== undefined && allItems.find((item,idx)=>item.__idx === clickedItem.__parentIdx)
                                    if (parent) {
                                        process = dataSource[parent.__idx];
                                        task = process.tasks[clickedItem.__relativeIdx];
                                    }else{
                                        process = dataSource[clickedItem.__idx];
                                        task = null;
                                    }
                                    cbk && cbk({process,task})
                                }

                            }}
                        />
                    </Paper>) : ''}
                </Grid>
            </Grid>
            <Outlet />
        </div>
    )
};
