import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';

/**
 * Map available actions that change state
 */
export namespace AuditTasksActions {
    export enum Type {
        SET_TIMELINE_ITEMS = 'SET_TIMELINE_ITEMS',
        SET_TASK_FOR_REVIEW = 'SET_TASK_FOR_REVIEW',
    }

    export const setTimelineItems = createAction<any[]>(Type.SET_TIMELINE_ITEMS);
    export const taskForReview = createAction<string>(Type.SET_TASK_FOR_REVIEW);
}

export type AuditTasksActions = Omit<typeof AuditTasksActions, 'Type'>;
export const useFormContainerActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = AuditTasksActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as AuditTasksActions;
};
