import * as  $ from 'jquery';
import *  as React from 'react';
import {HTMLAttributeAnchorTarget, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import contextPath from "sancus-client-common/dist/common/contextPath"
import {FieldDefaultsApiService, FieldDefaultsConfig} from 'sancus-client-common/dist/common/fieldDefaultsService';
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem, Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography, useTheme,
} from "@material-ui/core";
import OnfidoComponent from './OnfidoComponent'
import AuditTimeline from './AuditTimeline'
import UserSessionWidget from './UserSessionWidget'
import GlobalLoader from './GlobalLoader'
import DataTable from './DataTable'
import Actions from './Actions'

import ScriveComponent from "./Scrive.component";

import JumioComponent from "./Jumio.component";
import {
    choicesFrom,
    CountrySelectorFactory,
    CurrencySelectorFactory,
    DocuSignComponentFactory,
    DSDocumentPreviewComponentFactory,
    DSEmbeddedSignatureCeremonyComponentFactory,
    IDocuSignComponent,
    MultiSelectionParams, OperandHolder, Option,
    OrgPrefixSelectorFactory,
    parseSelectOptions, registry,
    SelectionParams, YesNoHolder
} from "sancus-client-common/dist/common/react_components_base";
import {
    CustomFileField,
    CustomFileFieldWithUrl,
    helperTextFromValidationAndAnnotation,
    LabelWithTooltip, ReadonlyTextField
} from "./generic_component";
import Grid from "@material-ui/core/Grid";
import {Alert, Autocomplete} from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {getNotificationTemplate, readOnlyStyleFactory} from "./generic_component/GenericFieldComponentSimple";
import {getStore} from "sancus-client-common/dist/store";
import {handleExport} from "./export_handler";
import LayoutContext, {useLayout} from 'sancus-client-common/dist/common/LayoutContext';
import {useIntl} from "react-intl";
import {Controller, useFormContext} from "react-hook-form";
import {CountryDialDatasetEntry, countryDialsDataset} from 'sancus-client-common/dist/constants/countryDial.dataset';

import formValidationUtil, {
    IndexDecoratorFormValidationUtilFactory
} from "sancus-client-common/dist/utils/formValidation.util";
import PepReportComponent from "./pepReportComponent";
import {AdobeSignComponent} from "./adobeSign.component";
import {Textarea, TextArea} from './textarea.component';


import {Graph as RD3Graph} from "react-d3-graph";

export {PepReportComponent}
export {AdobeSignComponent};
import {SingpassLogin} from './singpass.component';
import * as ReactDOM from "react-dom";
import {CustomComponentsBaseParams} from "sancus-client-common/dist/common/generic_component_base";

import MuiPhoneNumber from "material-ui-phone-number";

export {SingpassLogin};

export {Textarea, TextArea};

const useReadOnlyStyles = makeStyles((theme) => ({
    readOnlyLabel: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    readOnlyValue: {}
}));


export const Header = (props) => {
    const layout = useLayout();
    const {id, label} = props;
    if (layout === 'review' && props.validation?.readOnly) {
        return <></>;
    }
    if (label) {
        return <Box id={id} mt={2} mb={2}>
            <Box mt={2} mb={2}><Typography variant="body1">{label}</Typography></Box>
            {props.helperText && <Box mt={2} mb={2}><Typography variant="body2">{props.helperText}</Typography></Box>}
            {!props.hideDivider && <Divider/> || null}
        </Box>
    } else {
        return <Box mt={2} mb={2} id={id}>
            <Divider/>
        </Box>
    }
}
const messagesInFrench = {
    myMessage: "The process will continue automatically, please wait.. {ts, date, ::yyyyMMdd}",
};
export const DSEmbeddedSignatureCeremonyComponent = DSEmbeddedSignatureCeremonyComponentFactory(
    ({viewUrl, children, usePopup, type, label}) => {
        const classes = useStyles();
        type = type ?? 'docu-sign'
        if (usePopup) {
            label = label ?? "Click to sign document in different window";
            return <div id={`${type}-container`}>
                <Button
                    id={`${type}-button`}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={evt => {
                        evt.preventDefault();
                        window.open(viewUrl, `${type}`)
                    }}
                >{label}</Button>
            </div>
        } else {
            return (
                <div id={`${type}-iframe-container`} style={{width: "100%", height: "75vh"}}>
                    {children}
                    <iframe src={viewUrl}
                            id={`${type}-iframe`}
                            style={{width: "100%", height: "100%", border: "none"}}
                    ></iframe>
                </div>
            );
        }
    },

    ({text}) => <div>{text}</div>,
    (cbk) => window.addEventListener('message', cbk),
    (cbk) => window.removeEventListener('message', cbk),
    () => {

        const intl = useIntl();
        const pauseMessage3 = intl.formatMessage({
            id: 'pause_message_3',
            defaultMessage: "This process may take a few seconds. Page will be refreshed automatically.",
            description: 'Docusing wait message'
        });

        return <Grid container direction={'row'}>
            <Grid item>
                <Typography>
                    {pauseMessage3}
                </Typography>
            </Grid>
        </Grid>
    }
)

export const DSDocumentPreviewComponent = DSDocumentPreviewComponentFactory(
    CustomFileField
)

export const DocuSignComponent: IDocuSignComponent = DocuSignComponentFactory(
    DSDocumentPreviewComponent,
    DSEmbeddedSignatureCeremonyComponent,
    <div id="docu-sign-iframe-container">
        An error occurred, could not start signing ceremony.
    </div>
)

function searchUpwards(element: HTMLElement, predicate: (e: HTMLElement) => any) {
    let el = element as HTMLElement | null;
    while (el) {
        const pr = predicate(el)
        if (pr) {
            return [el, pr];
        } else {
            el = el.parentElement
        }
    }
    return [null, null];
}

function reportValidity(elem: HTMLElement): boolean | null {
    let _reportValidity = (elem as any).reportValidity;
    if (!_reportValidity) {
        [elem, _reportValidity] = searchUpwards(elem, el => (el as any).reportValidity)
    }
    if (_reportValidity) {
        const rv = _reportValidity.apply(elem);
        return rv;
    } else {
        return null;
    }
}

export const Error = (props) => {
    const [visibleEl, setVisibleEl] = useState(props.visible);

    const visible = visibleEl !== false && props.value != null;

    return visible ? (<Grid container direction={'row'} id={props.id} className={"error-component"}>
        <Alert severity="error" onClose={() => setVisibleEl(false)}>{props.value}</Alert>
    </Grid>) : <></>
}

export const ErrorPopup = (props) => {
    const [count, setCount] = useState(1);

    useEffect(() => {
        console.log(`ErrorPopup useEffect triggered...`);

        const store = getStore();
        const setError = (error: any) => {
            return {
                type: 'SET_ERROR',
                payload: error,
            }
        };

        // if not use this timeout error will throw: cannot flush updates when react is already rendering.
        setTimeout(() => {
            store.dispatch(setError(props.value));
        }, 200);

    }, [count]);

    return <></>
}

export const DataListSelector = (params: SelectionParams) => {
    const intl = useIntl()
    const choices = choicesFrom(params, intl as any)

    const methods = useFormContext();
    const {errors} = methods;
    const {onBlur, onChange} = params.fieldValidationCallbacks;

    const defaultValue = "";

    const selectedChoice = choices.find(c => c.value === params.value);
    let ret;
    const layout = useLayout();
    const readOnlyClasses = useReadOnlyStyles();
    if (params.validation?.readOnly) {
        ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                    style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}><Typography variant="body2"
                                          className={`${readOnlyClasses.readOnlyLabel}`}>{params.label}:</Typography></Grid>
            <Grid item xs={6}><Typography
                variant="body2">{selectedChoice ? `${selectedChoice.label || selectedChoice.value || ""}` : params.value}</Typography></Grid>
        </Grid>
    } else {
        const textField = <TextField
            id={params.id}
            select
            label={layout !== 'facing' && params.label}
            value={params.value || defaultValue}

            onChange={(event) => {
                event.preventDefault();

                onChange(event);

                const _value = event.target.value
                // params.setValue(_value as string)
                params.valueChangeCbk(params.id, _value)
            }}
            onBlur={onBlur}
            {...params.validation ?? {}}

            error={formValidationUtil(intl).hasFieldError(params.id, errors)}
            helperText={helperTextFromValidationAndAnnotation(formValidationUtil(intl).getFieldErrorMessage(params.id, errors), params)}

            inputProps={{
                name: params.name,
                id: params.id,
                'cam-variable-name': params.name,
                'cam-variable-type': "string",
            }}>
            {choices.map((choice, idx) => (
                <MenuItem key={choice.value} value={choice.value} disabled={params.validation?.readOnly}>
                    {choice.label || choice.value}
                </MenuItem>
            ))}
        </TextField>
        if (layout == 'facing') {
            ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                        style={{paddingLeft: '16px', paddingRight: "16px"}}>
                <Grid item xs={5}><Typography variant="body2"
                                              style={{color: 'rgba(0, 0, 0, 0.54)'}}>{params.label}:</Typography></Grid>
                <Grid item xs={6}>{textField}</Grid>
            </Grid>
        } else {
            ret = textField
        }
    }
    return ret;

}


function prepareValue(params: SelectionParams) {
    return params.prepareValue ? params.prepareValue(params.value) : params.value;
}

function transformChoice(params, event) {
    return params.transformChoiceToValue ? params.transformChoiceToValue(event.target.value) : event.target.value;
}

export const SingleOptionSelector = (params: SelectionParams) => {
    return <MultiSelector {...params} multiple={false} freeSolo={false}/>
}

export const NativeOptionSelector = (params: SelectionParams) => {
    const intl = useIntl();

    const methods = useFormContext();
    const {errors, control} = methods;
    const {onBlur, onChange} = params.fieldValidationCallbacks;


    const choices = parseSelectOptions(params, intl as any);

    const hasNullChoice = choices.some((c => c.value == null || c.value === ""));
    const defaultValue = "";

    const _value = prepareValue(params);
    const parentPath = params.path.lastIndexOf('.') !== -1 ? params.path.substring(0, params.path.lastIndexOf('.')) : undefined;
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(params.index, parentPath, intl);
    const _name = formValidationUtil.fieldValidationKeyFactory(params.id)
    useEffect(() => {
        if (_value !== params.value) {
            params.valueChangeCbk(params.id, _value).then(() => {
                control.setValue(_name, _value);
            })
        }
    }, [])

    useEffect(() => {
        if (params.diagnose) {
            const url = `${contextPath}diagnostics`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    logLevel: typeof params.diagnose == 'string' ? params.diagnose : 'info',
                    message: {
                        name: _name,
                        value: _value,
                        id: params.id,

                    }
                }),
            }).then(response => {
                console.debug("Diagnostics reported:", response.ok)
            }).catch(error => {
                console.error("Diagnostics error:", error)
            })
        }
    }, [params.id, _name, _value]);

    const choiceFromValue = params.transformValueToChoice && params.transformValueToChoice(_value) || _value;
    const selectedChoice = choices.find(c => c.value === choiceFromValue);

    let ret;
    const layout = useLayout();
    const readOnlyClasses = useReadOnlyStyles();
    const theme = useTheme();
    const labelRef = useRef<HTMLElement>(null);
    useLayoutEffect(() => {
        if (labelRef.current && params.elemRef && params.elemRef['current']) {
            const label = labelRef.current;
            const lcs = window.getComputedStyle(label);
            const lineHeightPx = lcs.getPropertyValue('line-height');
            const lineHeight = parseInt(lineHeightPx.substring(0, lineHeightPx.length - 2));
            const lbr = label.getBoundingClientRect();
            const lbrh = lbr.height;

            const elem = params.elemRef['current'] as HTMLElement;
            const ecs = window.getComputedStyle(elem);
            const ecshPx = ecs.getPropertyValue('height');
            const ecsh = parseInt(ecshPx.substring(0, ecshPx.length - 2));

            const ebr = elem.getBoundingClientRect();
            const ebrh = ebr.height;

            const labelTop = lbr.top;
            const labelBottom = lbr.bottom;
            const elemTop = ebr.top;
            const isLabelAboveElement = labelBottom <= elemTop || labelTop < elemTop;

            const lines = Math.floor(lbrh / lineHeight);
            const adjustedHeight = elem.dataset.adjustedHeight === "true";

            if (isLabelAboveElement) {
                if (lines > 1) {
                    let transform = window.getComputedStyle(label).transform;
                    if (!transform) {
                        return;
                    }
                    //Remote 10 pixels from transformY for each line
                    if (transform.indexOf("translate") != -1) {
                        const translate = transform ? /translate\(([^,]+),([^)]+)\)/.exec(transform) : null;
                        if (!translate) {
                            return;
                        }
                        const scale = transform ? /scale\(([^)]+)\)/.exec(transform) : null;
                        const translateX = translate ? parseInt(translate[1]) : 0;
                        let translateY = translate ? parseInt(translate[2]) : 0;
                        translateY = translateY - (lines - 1) * 10;
                        transform = `translate(${translateX}px,${translateY}px) ${scale ? `scale(${scale[1]})` : ""}`;

                        label.style.transform = transform;
                    } else if (transform.indexOf("matrix") != -1) {

                        const parsed = /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+),([^)]+)\)/.exec(transform);
                        let [a, b, c, d, tx, ty] = parsed!.slice(1).map(parseFloat);
                        ty -= (lines - 1) * 10;
                        transform = `matrix(${a},${b},${c},${d},${tx},${ty})`;

                        label.style.transform = transform;
                    }
                    return;
                } else {
                    //Do nothing
                    return;
                }
            }

            const labelWidth = lbr.width;
            const elemWidth = ebr.width;


            let mutationObserver = new MutationObserver(function (mutations) {
                if (label.dataset.adjustedWidth === "true") {
                    if (label.className.indexOf("client-MuiInputLabel-shrink") == -1) {
                        setTimeout(() => {
                            //Ensure the label has shrink class
                            label.className = label.className + " client-MuiInputLabel-shrink";
                        });
                    }
                }
            });
            mutationObserver.observe(label, {attributes: true});


            if (labelWidth >= elemWidth - 40) {
                label.className = label.className + " client-MuiInputLabel-shrink";
                label.dataset.shrink = "true";
                label.dataset.adjustedWidth = "true";
                /*
                                const scale = (elemWidth-40)/labelWidth;
                                if (!label.style.transform){
                                    const diff = 20;
                                    label.style.transform= `scale(${scale})`;
                                    label.style.paddingTop= `${diff}px`;
                                    label.style.paddingLeft= `${diff/2}px`;

                                }
                 */
            }


            if (lines > 1 && !adjustedHeight) {
                //FIXME: For very big texts (lines >6) the padding and height must be tuned
                const paddingTopPx = ecs.paddingTop;
                const paddingTop = parseInt(paddingTopPx.substring(0, paddingTopPx.length - 2));
                const paddingBottomPx = ecs.paddingBottom;
                const paddingBottom = parseInt(paddingBottomPx.substring(0, paddingBottomPx.length - 2));
                elem.style.height = `${ecsh + (lines - 1) * lineHeight * 0.75}px`;
                elem.style.paddingBottom = `${(paddingBottom - 6)}px`;
                elem.style.paddingTop = `${(paddingTop + 4)}px`;
                elem.dataset.adjustedHeight = "true";
            }

            const elemFWParent = elem.parentElement!.parentElement!;

            const elemFWParentWidth = elemFWParent.getBoundingClientRect().width;

            const enforceMaxWidth = null;
            if (enforceMaxWidth && elemFWParentWidth > enforceMaxWidth && elemFWParent.dataset.adjustedWidth !== "true") {
                elemFWParent!.style.maxWidth = `${Math.max(enforceMaxWidth, labelWidth)}px`;
                elemFWParent!.dataset.adjustedWidth = "true";
            }

            return () => mutationObserver.disconnect();
        }
    }, [params.elemRef, labelRef, _value]);


    const readonly = !!params.validation?.readOnly;
    if (readonly && (layout === 'review' || layout === 'facing')) {
        let display_value = selectedChoice ? `${selectedChoice.label || selectedChoice.value || ""}` : params.value
        if (display_value && typeof display_value == 'object') {
            if ((display_value as any).toString) {
                display_value = (display_value as any).toString();
            } else {
                display_value = JSON.stringify(display_value);
            }

        }
        ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                    style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5} key={"label"}>
                <Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`}>{params.label}:
                </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}
                  key={"value"}>
                <Grid item xs key={"value"}>
                    <Typography variant="body2" id={params.id} data-value={params.value}>
                        {display_value}
                    </Typography>
                </Grid>
                <Box ml={2} key={"notifications"}>
                    <Grid item>{getNotificationTemplate(params)}</Grid>
                </Box>
            </Grid>

        </Grid>
    } else {
        const hasError = formValidationUtil.hasFieldError(params.id, errors)
        const errorCmp = hasError ? <FormHelperText error={hasError}>
            {formValidationUtil.getFieldErrorMessage(params.id, errors)}
        </FormHelperText> : "";

        const element = <FormControl variant={params.helperText ? "filled" : "filled"}
                                     disabled={params.validation?.readOnly}

                                     margin={"normal"}
                                     fullWidth
                                     data-readonly={params.validation?.readOnly} {...params.validation ?? {}}
                                     error={formValidationUtil.hasFieldError(params.id, errors)}>
            {!params.helperText ? <InputLabel
                htmlFor={params.id}
                shrink={!!(_value ?? defaultValue)}
                innerRef={labelRef}
            >{params.label}</InputLabel> : null}

            {params.helperText
                ? <FormHelperText style={{
                    color: theme.palette.text.primary,
                }}>
                    <Typography variant="body1">{params.label} {params.required && <span aria-hidden="true"
                                                                                         className="client-MuiFormLabel-asterisk client-MuiInputLabel-asterisk">*</span>}

                    </Typography>
                    <Divider/>
                    <span dangerouslySetInnerHTML={{__html: params.helperText}}/>
                </FormHelperText>
                : ""}

            <Select
                inputRef={params.elemRef}
                native
                disabled={!!params.validation?.readOnly}
                required={!!params.required}
                value={_value ?? defaultValue}
                onChange={async (event) => {
                    event.preventDefault();
                    const _value = transformChoice(params, event)
                    onChange(event);
                    // params.setValue(_value as string)

                    await params.valueChangeCbk(params.id, _value)


                }}
                className={params.helperText ? "no-label" : "has-label"}
                onBlur={onBlur}
                inputProps={{
                    name: params.name,
                    id: params.id,
                    'cam-variable-name': params.name,
                    'cam-variable-type': "string",

                    'autoComplete': params.autocomplete || params.id,
                }}>
                {hasNullChoice ? <></> : <option key={"nullChoice"} value={""}>{""}</option>}
                {choices.map(choice => {
                    return <option key={choice.value} value={choice.value}>{choice.label || choice.value}</option>
                })}

            </Select>
            {errorCmp}

            {params.helperText_2
                ? <FormHelperText style={{
                    color: theme.palette.text.primary,
                }}>
                    <span dangerouslySetInnerHTML={{__html: params.helperText_2}}/>
                </FormHelperText>
                : ""}

        </FormControl>
        ret = element;
    }
    return ret;

}

export const OptionSelector = NativeOptionSelector;

export const RadioSelector = (params: SelectionParams) => {
    const intl = useIntl();

    const methods = useFormContext();
    const {errors} = methods;
    const {onBlur, onChange} = params.fieldValidationCallbacks;

    const choices = parseSelectOptions(params, intl as any);

    const hasNullChoice = choices.some((c => c.value == null || c.value === ""));
    const defaultValue = hasNullChoice ? "" : choices[0]

    const selectedChoice = choices.find(c => c.value === params.value);

    let ret;
    const layout = useLayout();
    const readOnlyClasses = useReadOnlyStyles();
    const parentPath = params.path.lastIndexOf('.') !== -1 ? params.path.substring(0, params.path.lastIndexOf('.')) : undefined;
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(params.index, parentPath, intl);

    if (layout === 'review') {
        ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                    style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}>
                <Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`}>{params.label}:</Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>
                <Grid item xs><Typography variant="body2" id={params.id}
                                          data-value={params.value}>{selectedChoice ? `${selectedChoice.label || selectedChoice.value || ""}` : params.value}</Typography></Grid>
                <Box ml={2}><Grid item>{getNotificationTemplate(params)}</Grid></Box>
            </Grid>

        </Grid>
    } else {
        const element = <FormControl
            component="fieldset"
            disabled={!!params.validation?.readOnly}
            error={formValidationUtil.hasFieldError(params.id, errors)}
            required={params.required}>
            {layout !== 'facing' && <Box mt={2} mb={1}><Typography variant="body1">{params.label}</Typography></Box>}
            <RadioGroup aria-label={params.label} name={params.name} value={params.value || defaultValue}

                        onChange={(event, value) => {
                            event.preventDefault();

                            onChange(event);

                            const _value = event.target.value
                            // params.setValue(_value as string)
                            params.valueChangeCbk(params.id, _value)
                        }
                        }
                        onBlur={onBlur}
            >
                {choices.map((choice, idx) => {
                    return <Box key={choice.id || choice.value} ml={2} mr={2}><FormControlLabel
                        key={choice.id || choice.value}
                        value={choice.value}
                        control={<Radio  {...params.validation ?? {}}/>}
                        label={choice.label || choice.value}
                        cam-variable-name={params.name}
                        cam-variable-type={"string"}
                    /></Box>
                })}
            </RadioGroup>
            {formValidationUtil.hasFieldError(params.id, errors)
                ? <FormHelperText error={formValidationUtil.hasFieldError(params.id, errors)}>
                    {formValidationUtil.getFieldErrorMessage(params.id, errors)}
                </FormHelperText>
                : ""}
        </FormControl>
        if (layout == 'facing') {
            ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                        style={{paddingLeft: '16px', paddingRight: "16px"}}>
                <Grid item xs={5}>
                    <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>{params.label}:</Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>
                    <Grid item xs>{element}</Grid>
                    <Box ml={2}><Grid item>{getNotificationTemplate(params)}</Grid></Box>
                </Grid>

            </Grid>
        } else {
            ret = element
        }
    }
    return ret;
}

export {OnfidoComponent};

export {JumioComponent};

export {ScriveComponent}

export {AuditTimeline};

export {CustomFileFieldWithUrl};

export {UserSessionWidget};

export {GlobalLoader};

export {DataTable};

export {Actions};

const fieldDefaultsApiBrowserServiceFactory = (): FieldDefaultsApiService => {
    let promise;
    return {
        fetchConfig(): Promise<FieldDefaultsConfig | never> {
            if (!promise) {
                promise = fetch(`${contextPath}api/field-defaults/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    })
                    .then(response => {
                        return response.json();
                    }) as Promise<FieldDefaultsConfig | never>;
            }
            return promise;
        },
    }
}
const fieldDefaultsApiService = fieldDefaultsApiBrowserServiceFactory();

export const CountrySelector = CountrySelectorFactory(OptionSelector, fieldDefaultsApiService)
export const CurrencySelector = CurrencySelectorFactory(OptionSelector, fieldDefaultsApiService)
export const OrgPrefixSelector = OrgPrefixSelectorFactory(OptionSelector, fieldDefaultsApiService)

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const MultiSelector = (props: MultiSelectionParams) => {
    const intl = useIntl()
    const choices = parseSelectOptions(props, intl as any);

    const labelForChoice = choices.reduce((acc, c) => {
        if (c.label && c.label !== c.value) {
            acc[c.value] = c.label;
        }
        return acc;
    }, {});

    let _value;
    if (props.value == null) {
        _value = [];
    } else if (typeof props.value === 'string') {
        try {
            _value = JSON.parse(props.value);
        } catch (e) {
            _value = props.value;
        }
    } else {
        _value = props.value;
    }

    if (_value != null && !Array.isArray(_value)) {
        console.warn("Multiselector %s got value '%s'", props.id, props.value)
        if (typeof _value == 'string' && _value.length > 0) {
            _value = [_value]
        } else {
            _value = [];
        }
    }

    const [__value, setValue] = useState<string[] | undefined>(undefined);

    const value = __value !== undefined ? __value : _value as string[];

    const methods = useFormContext();
    const {errors, control} = methods;

    let ret;
    const layout = useLayout();
    const readOnlyClasses = useReadOnlyStyles();
    const parentPath = props.path.lastIndexOf('.') !== -1 ? props.path.substring(0, props.path.lastIndexOf('.')) : undefined;
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.index, parentPath, intl);
    const readonly = !!props.validation?.readOnly;
    if (readonly && (layout === 'review' || layout === 'facing')) {
        ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                    style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}>
                <Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`}>{props.label}:</Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>


                <Grid item xs><Typography variant="body2" id={props.id}>{
                    value && value.map(v => {
                        const label = labelForChoice[v];
                        return label ?? v;
                    }).join(", ")
                }</Typography></Grid>

                <Box ml={2}><Grid item>{getNotificationTemplate(props)}</Grid></Box>
            </Grid>
        </Grid>
    } else {

        /*useEffect(()=>{
            if (props.elemRef && props.elemRef['current']){
                console.log('props.elemRef',props.elemRef['current']);
                (props.elemRef['current'] as HTMLInputElement).setAttribute('inputmode','none');
            }
        },[props.elemRef])*/


        const hasOwnErrors = formValidationUtil.hasFieldError(props.id, errors)
        const ownErrors = formValidationUtil.getFieldErrorMessage(props.id, errors)
        let _value = value?.map(v => ({value: v, id: v}))
        if (_value?.length == 0) {
            control.setValue(formValidationUtil.fieldValidationKeyFactory(props.id), null)
        } else {
            control.setValue(formValidationUtil.fieldValidationKeyFactory(props.id), _value)
        }

        useLayoutEffect(() => {
            if (props.elemRef) {
                const jq = $(props.elemRef['current']);

                const root = jq.parents('.client-MuiAutocomplete-root');
                const labelJQ = root.find('label');

                const label = labelJQ[0]
                if (label == null) {
                    return;
                }
                const lcs = window.getComputedStyle(label);
                const lineHeightPx = lcs.getPropertyValue('line-height');
                const lineHeight = parseInt(lineHeightPx.substring(0, lineHeightPx.length - 2));

                const lbr = label.getBoundingClientRect();
                const lbrh = lbr.height;

                const elemJQ = root.find('.client-MuiFilledInput-adornedStart')
                const elem = elemJQ[0];
                if (!elem) {
                    return;
                }
                const ecs = window.getComputedStyle(elem);
                const ecshPx = ecs.getPropertyValue('height');
                const ecsh = parseInt(ecshPx.substring(0, ecshPx.length - 2));

                const ebr = elem.getBoundingClientRect();
                const ebrh = ebr.height;


                const labelTop = lbr.top;
                const labelBottom = lbr.bottom;
                const elemTop = ebr.top;

                const lines = Math.ceil(lbrh / lineHeight);
                const adjustedHeight = elem.dataset.adjustedHeight === "true";

                const isLabelAboveElement = labelBottom <= elemTop || labelTop < elemTop;
                if (isLabelAboveElement) {
                    let transform = window.getComputedStyle(label).transform;
                    if (!transform) {
                        return;
                    }
                    //Remote 10 pixels from transformY for each line
                    if (transform.indexOf("translate") != -1) {
                        const translate = transform ? /translate\(([^,]+),([^)]+)\)/.exec(transform) : null;
                        if (!translate) {
                            return;
                        }
                        const scale = transform ? /scale\(([^)]+)\)/.exec(transform) : null;
                        const translateX = translate ? parseInt(translate[1]) : 0;
                        let translateY = translate ? parseInt(translate[2]) : 0;
                        translateY = translateY - (lines - 1) * 10;
                        transform = `translate(${translateX}px,${translateY}px) ${scale ? `scale(${scale[1]})` : ""}`;

                        label.style.transform = transform;
                    } else if (transform.indexOf("matrix") != -1) {

                        const parsed = /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+),([^)]+)\)/.exec(transform);
                        let [a, b, c, d, tx, ty] = parsed!.slice(1).map(parseFloat);
                        ty -= (lines - 1) * 10;
                        transform = `matrix(${a},${b},${c},${d},${tx},${ty})`;

                        label.style.transform = transform;
                    }
                }

                const labelWidth = lbr.width;
                const elemWidth = ebr.width;

                const labelHeight = lbr.height;
                let mutationObserver = new MutationObserver(function (mutations) {
                    if (label.dataset.adjustedWidth === "true") {
                        if (label.className.indexOf("client-MuiInputLabel-shrink") == -1) {
                            setTimeout(() => {
                                //Ensure the label has shrink class
                                label.className = label.className + " client-MuiInputLabel-shrink";
                            });
                        }
                    }
                });
                mutationObserver.observe(label, {attributes: true});
                if (labelWidth >= elemWidth - 40) {
                    label.className = label.className + " client-MuiInputLabel-shrink";
                    label.dataset.shrink = "true";
                    label.dataset.adjustedWidth = "true";
                    /*
                                        const scale = (elemWidth-40)/labelWidth;
                                        if (!label.style.transform){

                                            const diff = 20;
                                            label.style.transform= `scale(${scale})`;
                                            label.style.paddingTop= `${diff}px`;
                                            label.style.paddingLeft= `${diff/2}px`;



                                        }

                     */
                }

                if (lines > 1 && !adjustedHeight) {

                    const paddingTopPx = ecs.paddingTop;
                    const paddingTop = parseInt(paddingTopPx.substring(0, paddingTopPx.length - 2));
                    const paddingBottomPx = ecs.paddingBottom;
                    const paddingBottom = parseInt(paddingBottomPx.substring(0, paddingBottomPx.length - 2));
                    // elem.style.height = `${ecsh + (lines-1)*lineHeight*0.75}px`;

                    elem.style.paddingTop = `${(paddingTop + (lines - 1) * lineHeight)}px`;
                    elem.dataset.adjustedHeight = "true";
                }
                return () => mutationObserver.disconnect();
            }
        }, [props.elemRef, _value]);

        const [open, setOpen] = useState(false);
        const element = <Controller
            render={cProps => {

                return <Autocomplete
                    fullWidth={true}
                    open={open}
                    className={props.helperText ? "no-label" : "has-label"}
                    onOpen={() => {
                        setOpen(true)
                    }}
                    onClose={() => {
                        setOpen(false)
                    }}
                    PaperComponent={(props, context) => {
                        const {children} = props;
                        return <Paper {...props} />
                    }}
                    id={props.id}
                    autoHighlight
                    multiple={props.multiple ?? true} //Setting multiple to false triggers getOptionLabel
                    // instead of renderTags
                    freeSolo={props.freeSolo}
                    options={choices}
                    disabled={!!props.validation?.readOnly}
                    disableCloseOnSelect
                    disablePortal={window.parent != window} //Disable portal when in iframe
                    // If set to true, poppers in the review app move way bellow the bottom of the page


                    getOptionLabel={(option) => {
                        const ret = option.label ?? option.value;
                        if (typeof ret.toLowerCase !== 'function') {
                            console.error("Unexpected option:", option);
                        }
                        return ret;
                    }}
                    onChange={(event, newValue: (null | (string | Option) | (string | Option)[])) => {
                        if (newValue == null) {
                            newValue = [];
                        } else if (!(Array.isArray(newValue))) {
                            newValue = [newValue];
                        }
                        const v = newValue.map(v => typeof v === 'string' ? v : v.value)
                        const vs = JSON.stringify(v)

                        const ownReference = props.formReference.find(f => f.id == props.id) || {typeName: 'unknown'}
                        const {typeName} = ownReference
                        setValue(v);
                        /*
                            If backend expects the return value to be a string, it will parse the string to object using objectMapper.
                            We should be accomodating this by providing a JSON parseable string
                            If backend expects object or json, it should handle serialization/deserialization there
                         */
                        props.valueChangeCbk(props.id, typeName == 'string' ? vs : v);
                        if (v?.length == 0) {
                            cProps.onChange(null)
                        } else {
                            cProps.onChange(v)
                        }
                    }}

                    value={_value}
                    renderOption={(option, {selected}) => {
                        return (
                            <>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{backgroundColor: '#fff', marginRight: 8}}
                                    checked={selected || value.indexOf(option.value) >= 0}
                                />
                                {option.label || option.value}
                            </>
                        );
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                label={labelForChoice[option.value] || option.id || option.value}
                                {...getTagProps({index})}
                            />
                        ))
                    }}
                    getOptionSelected={(option, value) => {
                        return option.value === value.value;
                    }}
                    getOptionDisabled={(option: { value: string }) => {
                        return value.indexOf(option.value) >= 0;
                    }}
                    onInputChange={(event, newInputValue) => {
                        console.log(newInputValue);
                    }}


                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            label={props.label}

                            margin={"normal"}
                            error={hasOwnErrors}
                            inputRef={props.elemRef}
                            helperText={helperTextFromValidationAndAnnotation(ownErrors, props)}
                            required={props.required}
                            inputProps={{
                                ...params.inputProps || {},
                                autoComplete: props.autocomplete || props.id,
                                inputMode: props.freeSolo ? 'text' : 'none'
                            }}

                        />
                    )}
                />
            }
            }
            control={control}
            name={formValidationUtil.fieldValidationKeyFactory(props.id)}
        />


        ret = element;
    }
    return ret;


};

export const MultiCountrySelector = CountrySelectorFactory(MultiSelector, fieldDefaultsApiService)
export const MultiCurrencySelector = CurrencySelectorFactory(MultiSelector, fieldDefaultsApiService)


export const ExportData = (props: { name: string, formReference: any, setInProgress: (pg: any) => void }) => {
    return <button name={"export"} onClick={evt => {
        evt.preventDefault();
        handleExport(props.name, props.formReference, props.setInProgress)
    }}>Export</button>
}


export const YesNo = (props: SelectionParams) => {
    let choices_str = props.choices_str;
    if (choices_str) {
        choices_str = JSON.parse(choices_str)
    }


    const choices = [
        {
            value: "0",
            label: choices_str && choices_str[1] || "No"
        }, {
            value: "1",
            label: choices_str && choices_str[0] || "Yes"
        }];

    const prepareValue = (value: YesNoHolder | boolean | null | any) => {
        if (value != null) {
            if (typeof value === 'boolean') {
                return value ? YesNoHolder.YES : YesNoHolder.NO
            } else if (value instanceof YesNoHolder) {
                return value
            } else if (value === "") {
                return value;
            } else if (value === "false") {
                return YesNoHolder.NO;
            } else if (value === "true") {
                return YesNoHolder.YES;
            } else {
                console.error("Unexpected value type for YesNo field:", value);
                return "";
            }
        } else {
            return YesNoHolder.NONE;
        }
    }

    const transformValueToChoice = (value: YesNoHolder | null) => {
        if (value != null) {
            return value.toString();
        } else {
            return "";
        }
    }
    const transformChoiceToValue = (choice: string) => {
        if (choice == null || choice === "") {
            return "";
        } else if (choice === "1") {
            return YesNoHolder.YES;
        } else if (choice === "0") {
            return YesNoHolder.NO;
        } else {
            console.log("Unexpected choice value:", choice);
            return null;
        }
    }


    return <OptionSelector

        {...props}
        choices={choices}
        prepareValue={prepareValue}
        transformValueToChoice={transformValueToChoice}
        transformChoiceToValue={transformChoiceToValue}/>
}

export const YesNoRadio = YesNo;

interface GrantIDService {
    login(): Promise<{ redirectUrl: string }>;
}

const GrantIDServiceFactory = (): GrantIDService => {
    return {
        login: async (): Promise<{ redirectUrl: string }> => {
            return window.fetch(`${contextPath}grandid/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response
                    } else {
                        throw response.statusText;
                    }
                })
                .then(response => {
                    return response.json();
                }).catch(error => {
                    console.error('could not start BankID login', error);
                    return error;
                }) as Promise<{ redirectUrl: string }>;
        },
    };
};

const grantIDService = GrantIDServiceFactory();

interface GrandIDLoginProps {
    id: 'GrandIDLogin',
    value?: BankIDUserDetails | { redirectUrl: string, sessionId: string };
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;
}

interface BankIDUserDetails {
    personalNumber: string;
    firstName: string;
    lastName: string;
    signature?: string;
}

export const GrandIDLogin = (props: GrandIDLoginProps) => {
    const {redirectUrl, sessionId} = props.value as { redirectUrl: string, sessionId: string }

    /**
     * handle the success login event message.
     * @param event
     */
    const handleSuccessLoginEventMessage = (event) => {
        if (event && event.data && event.data.name === 'grandID-login') {
            console.debug(event.data);
            if (event.data.details) {
                props.valueChangeCbk(props.id, event.data.details, true);
            }
        }
    };

    useEffect(() => {
        console.debug('subscribe handle success grandid listener')
        window.addEventListener("message", handleSuccessLoginEventMessage);

        return () => {
            window.removeEventListener("message", handleSuccessLoginEventMessage);
            console.debug('unsub handle success grandid listener');
        }
    }, []);

    return (
        <div id="grandid-iframe-container" style={{width: "100%", height: "75vh"}}>
            <iframe src={redirectUrl} id="grandid-iframe" style={{width: "100%", height: "100%", border: "none"}}/>
        </div>
    );
};

/**
 * ---- BankID component -----
 */

interface AuthenticateProps {
    personalNumber: string;
}

interface SignatureProps {
    personalNumber: string;
    userVisibleText: string;
    signText?: string;
}

interface CollectProps {
    orderRef: string;
}

export interface BankIDService {
    authenticate(props: AuthenticateProps): Promise<string | never>;

    collect(props: CollectProps): Promise<BankIDUserDetails | never>;
}

interface BankIDServiceFactoryConfig {
    contextPath: string;
}

export const bankIDServiceFactory = (config: BankIDServiceFactoryConfig) => {

    return {
        collect: async (props: CollectProps): Promise<BankIDUserDetails | never> => {
            const response = await window.fetch(`${config.contextPath}api/bankid/collect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(props),
            });
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response.status;
            }
        },
        authenticate: async (props: AuthenticateProps): Promise<string | never> => {
            const response = await window.fetch(`${config.contextPath}api/bankid/authenticate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(props),
            });
            if (response.status >= 200 && response.status < 300) {
                return response.text();
            } else {
                throw response.status;
            }
        },
        sign: async (props: SignatureProps): Promise<string | never> => {
            const response = await window.fetch(`${config.contextPath}api/bankid/sign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(props),
            });
            if (response.status >= 200 && response.status < 300) {
                return response.text();
            } else {
                throw response.status;
            }
        },
    };
};

const bankIDService = bankIDServiceFactory({contextPath: contextPath as any});

interface AuthenticateWithPersonalNumberProps {
    navigateToCollectDataView(orderRef: string): void;

    signatureConfig?: SignatureConfig;
}

const AuthenticateWithPersonalNumber = (props: AuthenticateWithPersonalNumberProps) => {
    const [personalNumber, setPersonalNumber] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const handlePersonalNumberOnChange = (evt) => {
        setPersonalNumber(evt.target.value);
    };

    const handleAuthenticate = async () => {
        if (personalNumber) {
            if (props.signatureConfig) {
                try {
                    console.debug('initiate authenticate with BankID');
                    const result = await bankIDService.sign({
                        personalNumber,
                        userVisibleText: props.signatureConfig.userVisibleSignText,
                        signText: props.signatureConfig.signText,
                    });
                    console.debug(`fetched orderRef: ${result}`);
                    props.navigateToCollectDataView(result);
                } catch (e) {
                    console.error(`could not authenticate with BankID: ${e}`);
                    setError(true);
                }
            } else {
                try {
                    console.debug('initiate authenticate with BankID');
                    const result = await bankIDService.authenticate({personalNumber});
                    console.debug(`fetched orderRef: ${result}`);
                    props.navigateToCollectDataView(result);
                } catch (e) {
                    console.error(`could not authenticate with BankID: ${e}`);
                    setError(true);
                }
            }
        }
    };

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <TextField label="Personal number" variant="outlined" required value={personalNumber}
                           onChange={handlePersonalNumberOnChange}/>
            </Grid>
            {
                error &&
                <Grid item>
                    <Typography color={'error'}>Could not authenticate, please try again</Typography>
                </Grid>
            }
            <Grid item>
                <Button color={'primary'} onClick={handleAuthenticate}>Authenticate with personal number</Button>
            </Grid>
        </Grid>
    );
};

interface CollectBankIDDataProps {
    orderRef: string;

    cancel(): void;

    onSuccessfulCollect(details: BankIDUserDetails);
}

const CollectBankIDData = (props: CollectBankIDDataProps) => {
    console.log(`CollectBankIDData:`, props);

    const [error, setError] = useState<boolean>(false);

    const handleCancel = () => {
        props.cancel();
    };

    const handleCollectData = async () => {
        try {
            const result = await bankIDService.collect({orderRef: props.orderRef});
            if (result) {
                console.debug(result);
                props.onSuccessfulCollect(result);
            } else {
                setError(true);
            }
        } catch (e) {
            console.error(e);
            setError(true);
        }
    }

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Typography>identify in external application...</Typography>
            </Grid>
            {
                error &&
                <Grid item>
                    <Typography color={'error'}>Pending user identification in external application.</Typography>
                </Grid>
            }
            <Grid item container spacing={2}>
                <Grid item>
                    <Button color={'primary'} onClick={handleCancel}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button color={'primary'} onClick={handleCollectData}>Next</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface SignatureConfig {
    userVisibleSignText: string;
    signText: string;
}

interface BankIDLoginProps {
    id: 'BankIDLogin',
    value?: BankIDUserDetails;
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;

    signatureConfig?: SignatureConfig;
}

export const BankIDLogin = (props: BankIDLoginProps) => {
    const steps = ['authenticate', 'collect'];
    const [currentStep, setCurrentStep] = useState<string>(steps[0]);
    const [stepProps, setStepProps] = useState<any>();

    const handleNextStep = (props: any) => {
        const idx = steps.indexOf(currentStep);
        if (idx >= 0 && idx < steps.length - 1) {
            setStepProps(props);
            setCurrentStep(steps[idx + 1]);
        }
    };

    const handlePrevStep = () => {
        const idx = steps.indexOf(currentStep);
        if (idx > 0 && idx <= steps.length - 1) {
            setStepProps(undefined);
            setCurrentStep(steps[idx - 1]);
        }
    };

    const onSuccessfulCollect = (details: BankIDUserDetails) => {
        props.valueChangeCbk(props.id, JSON.stringify(details), true);
    };

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                {
                    props.signatureConfig
                        ? <Typography>Signature with BankID</Typography>
                        : <Typography>Sign in with BankID</Typography>
                }
            </Grid>
            <Grid item>
                {
                    currentStep === steps[0] &&
                    <AuthenticateWithPersonalNumber navigateToCollectDataView={handleNextStep}
                                                    signatureConfig={props.signatureConfig}/>
                }
                {
                    currentStep === steps[1] && stepProps &&
                    <CollectBankIDData orderRef={stepProps} cancel={handlePrevStep}
                                       onSuccessfulCollect={onSuccessfulCollect}/>
                }
            </Grid>
        </Grid>
    );
};

interface BankIDCollectSignatureProps {
    id: string,
    value?: BankIDUserDetails;
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;

    data: string;
}

export const BankIDCollectSignature = (props: BankIDCollectSignatureProps) => {
    console.log(props);

    const [error, setError] = useState<boolean>(false);
    const [config, setConfig] = useState();

    useEffect(() => {
        if (props.value && typeof props.value === 'string') {
            try {
                const cfg = JSON.parse(props.value);
                if (cfg) {
                    setConfig(cfg);
                } else {
                    setError(true);
                }
            } catch (e) {
                setError(true);
            }
        } else {
            setError(true);
        }
    }, []);

    return (
        <Grid container direction={'column'}>
            {
                error ? <Grid item>
                        <Typography color={'error'}>
                            invalid signature configuration
                        </Typography>
                    </Grid>
                    : config && <Grid item>
                    <BankIDLogin {...props as any} signatureConfig={config}/>
                </Grid>
            }
        </Grid>
    );
}

type CountryDialPhoneNumberProps = CustomComponentsBaseParams & {
    id: string;
    value?: string;
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;
    fieldValidationCallbacks: {
        onBlur(string?);
        onChange(string?);
    }
    index?: number;
    required?: boolean;
    default_country?: string;
    priorityValues?: string;
}

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: theme.spacing(1),
        width: '25ch',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const EditableCountryDialPhoneNumber = (props: CountryDialPhoneNumberProps) => {
    const intl = useIntl()
    const [preferredCountries, setPreferredCountries] =
        useState<string[] | undefined>(props.priorityValues?.split(',').map(s => s.trim()));

    const formFieldValidationKey = formValidationUtil(intl).fieldValidationKeyFactory(props.id, props.index)
    const methods = useFormContext();
    const {errors, control} = methods;
    const {onBlur, onChange} = props.fieldValidationCallbacks;
    const error = formValidationUtil(intl).hasFieldError(props.id, errors)
    const helperText = helperTextFromValidationAndAnnotation(formValidationUtil(intl).getFieldErrorMessage(props.id, errors), props)

    useEffect(() => {

        control.setValue(formFieldValidationKey, props.value)
        if (!props.priorityValues) {
            fieldDefaultsApiService.fetchConfig().then(config => {
                setPreferredCountries(config?.country?.priorityValues);
            });
        }
    }, [])

    return <MuiPhoneNumber
        onChange={value => {
            // if (value == '+') {
            //     value = null;
            // }
            onChange(value)
            props.valueChangeCbk(props.id, value)
        }}
        onBlur={evt => {
            onBlur(evt)
        }}

        value={props.value}
        label={props.label}
        error={error}

        // inputRef={props.elemRef}
        inputProps={{
            ref: props.elemRef,
            'autoComplete': props.path,
        }}
        helperText={helperText}

        // variant="filled"
        margin="normal"
        fullWidth
        required={!!props.required}
        disabled={!!props.validation?.readOnly}
        defaultCountry={props.default_country?.toLowerCase()}
        preferredCountries={preferredCountries}
        dropdownClass={error ? "Mui-error MuiOutlinedInput" : "MuiOutlinedInput"}

    />


}

const EditableCountryDialPhoneNumber_Old = (props: CountryDialPhoneNumberProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const empty: CountryDialDatasetEntry = {name: '', code: '', dial_code: ''};
    const isEmpty = (entry: CountryDialDatasetEntry): boolean => {
        return entry.code === '';
    };
    const [selectedCountry, setSelectedCountry] = useState<CountryDialDatasetEntry>(empty);
    const [options, setOptions] = useState<CountryDialDatasetEntry[]>([]);
    const [phone, setPhone] = useState<string>('');

    const formFieldValidationKey = formValidationUtil(intl).fieldValidationKeyFactory(props.id, props.index)
    const methods = useFormContext();
    const {errors, control} = methods;
    const {onBlur, onChange} = props.fieldValidationCallbacks;
    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly ? true : false;

    const formatPhone = (country: CountryDialDatasetEntry, value: string): string => {
        return `${country.dial_code} ${value}`;
    };

    const extractPhoneFromFieldValue = (value): string => {
        if (value && typeof value === 'string') {
            const [code, number] = value.split(' ');
            return number ? number : '';
        }
        return '';
    };

    const extractOptionFromFieldValue = (options: CountryDialDatasetEntry[], value?: string): CountryDialDatasetEntry | null => {
        if (value && typeof value === 'string') {
            const [code, number] = value.split(' ');
            if (code) {
                const found = options.find(entry => entry.dial_code === code);
                return found ? found : null;
            }
        }
        return null;
    };

    const updatePhone = (value: string): void => {
        setPhone(value);
        onChange(value);
    };

    useEffect(() => {
        //append empty value
        const opts = [
            empty,
            ...countryDialsDataset,
        ];
        setOptions(opts);

        //extract default field values
        const found = extractOptionFromFieldValue(opts, props.value);
        if (found) {
            setSelectedCountry(found);
        } else {
            setSelectedCountry(empty);
        }

        //extract default phone value
        const defaultValue = extractPhoneFromFieldValue(props.value);
        setPhone(defaultValue);
        control.setValue(formFieldValidationKey, defaultValue);
    }, []);

    const handleSelectedCountrySelected = (event) => {
        const {value} = event.target;
        if (typeof value === 'string') {
            const found = options.find(opt => opt.code === value);
            if (found) {
                if (isEmpty(found)) {
                    updatePhone('');
                    setSelectedCountry(empty);
                    props.valueChangeCbk(props.id, '');
                } else {
                    setSelectedCountry(found);
                    props.valueChangeCbk(props.id, formatPhone(found, phone));
                }
            } else {
                updatePhone('');
                props.valueChangeCbk(props.id, '');
            }
        } else {
            updatePhone('');
            props.valueChangeCbk(props.id, '');
        }
    };

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        setPhone(value);
        onChange(value);
    };

    const handleUpdateFormFieldValue = (event) => {
        onBlur();
        props.valueChangeCbk(props.id, formatPhone(selectedCountry, phone));
    };

    return (
        <Grid container wrap={'nowrap'} spacing={1}>
            <Grid item>
                <FormControl variant="filled" margin={"normal"} disabled={isReadOnly}>
                    <Select native
                            disableUnderline
                            onChange={handleSelectedCountrySelected}
                            value={selectedCountry.code}
                    >
                        {
                            options.map(entry => {
                                return (
                                    <option key={entry.code} value={entry.code}>
                                        {entry.name || entry.code}
                                    </option>
                                )
                            })
                        }

                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    required={!!props.required}
                    disabled={isEmpty(selectedCountry) || isReadOnly}
                    error={formValidationUtil(intl).hasFieldError(props.id, errors)}
                    helperText={helperTextFromValidationAndAnnotation(formValidationUtil(intl).getFieldErrorMessage(props.id, errors), props)}
                    label={props.label}
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{selectedCountry.dial_code}</InputAdornment>
                    }}
                    value={phone}
                    onChange={handlePhoneChange}
                    onBlur={handleUpdateFormFieldValue}
                />
            </Grid>
        </Grid>
    );
};

const ReadonlyCountryDialPhoneNumber = (props: CountryDialPhoneNumberProps) => {
    const useStyles = makeStyles(readOnlyStyleFactory);
    const readOnlyClasses = useStyles();
    const {id, path, value, label} = props;
    const fv_value = {id, path, value, label}

    return (
        <ReadonlyTextField
            fv_value={fv_value}
            _value={value}
            readOnlyClasses={readOnlyClasses}
            getNotificationTemplate={getNotificationTemplate}
        />
    );
};

export const CountryDialPhoneNumber = (props: CountryDialPhoneNumberProps) => {
    const layout = useLayout();

    return (
        layout === 'review'
            ? <ReadonlyCountryDialPhoneNumber {...props}/>
            : <EditableCountryDialPhoneNumber {...props}/>
    );
};

/**
 * Signicat api integration
 */


interface SignicatServiceFactoryConfig {
    contextPath: string;
}

/**
 * Signicat api service
 */
interface SignicatService {
    login: {
        defaultCountry: string;
        authURLFactory(country: string, method?: string): string;
    };
    signature: {
        /**
         * Fetch signed document result value.
         * @param country
         * @param signOrderId
         * @param signTaskId
         * @param signDocumentId
         */
        getSignedDocumentResult(country: string, signOrderId: string, signTaskId: string, signDocumentId: string): Promise<string>;
        signedDocumentURLFactory(country: string, documentId: string): string;
    };
}

export const signicatServiceFactory = (config: SignicatServiceFactoryConfig): SignicatService => {
    const defaultLoginCountry = 'SE';

    return {
        login: {
            defaultCountry: defaultLoginCountry,
            authURLFactory(country: string, method?: string): string {
                let url = `${config.contextPath}api/signicat/${country ? country : defaultLoginCountry}/login`
                if (method) {
                    url = url + `?method=${method}`
                }
                return url;

            },
        },
        signature: {
            getSignedDocumentResult(country: string, signOrderId: string, signTaskId: string, signDocumentId: string): Promise<string> {
                return window.fetch(`${contextPath}api/signicat/sign/doc?country=${country}&signOrderId=${signOrderId}&signTaskId=${signTaskId}&signDocumentId=${signDocumentId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        if (response.status >= 200 && response.status < 300) {
                            return response
                        } else {
                            throw response.statusText;
                        }
                    })
                    .then(response => {
                        return response.text();
                    }).catch(error => {
                        console.error('could not fetch signed document', error);
                        return error;
                    }) as Promise<string>;
            },
            signedDocumentURLFactory(country: string, documentId: string): string {
                return `${contextPath}api/signicat/sign/document?country=${country}&documentId=${documentId}`;
            }
        }
    };
};

const signicatService = signicatServiceFactory({contextPath: contextPath as any});

type SignicatValues = {
    //the login result props
    birthdate?: string;
    familyName?: string;
    givenName?: string;
    locale?: string;
    name?: string;
    personalNumber?: string;
    sub?: string;
    //the prepared value prop
    //default value defaults to SE if not set
    loginCountry?: string;
    method?: string;
}

interface IFrameLoginProps<T> {
    id: string;
    //value?: string;
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;

    value: T
}

/**
 * The readonly component view
 * @param props
 * @constructor
 */
const ReadonlyIFrameLoginView = <T, >(props: IFrameLoginProps<T>) => {
    console.debug(props);

    return (
        <div>{JSON.stringify(props.value)}</div>
    );
};


type IFrameInternal = { type: string; src: string; eventName: string; usePopup?: boolean }

/**
 * login component
 * @param props
 * @constructor
 */
const IFrameLoginView = <T, >(props: IFrameLoginProps<T> & IFrameInternal) => {
    console.debug(props);
    /**
     * iframe event handler
     * @param event
     */
    const handleSuccessLoginEventMessage = (event) => {
        if (event && event.data && event.data.name === props.eventName) {
            console.debug(event.data);
            if (event.data && event.data.data && event.data.data.details) {
                console.debug(event.data.data.details);
                props.valueChangeCbk(props.id, event.data.data.details, true);
            }
        }
    };

    useEffect(() => {
        console.debug(`subscribe handle success ${props.type} listener`)
        window.addEventListener("message", handleSuccessLoginEventMessage);

        return () => {
            window.removeEventListener("message", handleSuccessLoginEventMessage);
            console.debug(`unsub handle success ${props.type} listener`);
        }
    }, []);
    const classes = useStyles();
    const cmp = props.usePopup ?
        <div id={`${props.type}-auth-container`}>
            <Button
                id={`${props.type}-auth-button`}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={evt => {
                    evt.preventDefault();
                    window.open(props.src, `${props.type}`)
                }}
            >{props.label}</Button>

            {/*<a id={`${props.type}-auth-link`} href="#"*/}
            {/*    onClick={evt=>{*/}
            {/*        evt.preventDefault();*/}
            {/*        window.open(props.src,`${props.type}`)*/}
            {/*    }}*/}
            {/*>{props.label}</a>*/}
        </div> :

        <div id={`${props.type}-auth-container`} style={{width: "100%", height: "75vh"}}>
            <iframe id={`${props.type}-auth-iframe`} src={props.src}
                    style={{width: "100%", height: "100%", border: "none"}}/>
        </div>
    return cmp;
};

const IFrameLogin = <T, >(props: IFrameLoginProps<T> & IFrameInternal) => {
    const isReadOnly = !!props.validation?.readOnly;

    let component;
    if (isReadOnly) {
        component = <ReadonlyIFrameLoginView {...props} />;
    } else {

        component = <IFrameLoginView {...props} />;
    }

    return component;
};

export const SignicatLogin = (props: IFrameLoginProps<SignicatValues>) => {
    const src = signicatService.login.authURLFactory(props.value?.loginCountry || '', props.value?.method)

    const propsWithLoginCountry = props.value.loginCountry
        ? props
        : {
            ...props,
            value: {
                ...props.value,
                loginCountry: signicatService.login.defaultCountry
            }
        };

    return <IFrameLogin src={src} type={'signicat'} eventName={'signicat-result-event'} {...propsWithLoginCountry} />
}

export const UAEPassLogin = (props: IFrameLoginProps<void>) => {
    const src = `${contextPath}api/uae_pass/login`
    const eventName = 'uae-pass-result-event'
    return <IFrameLogin src={src} eventName={eventName} type={'uae-pass'} usePopup={true} {...props} />

}


export type SSOLoginProps = IFrameLoginProps<void> & { providers: string }

export const SSOLogin = (props: SSOLoginProps) => {
    const [availableProviders, setAvailableProviders] = useState<string[]>([])
    useEffect(() => {
        const ap = fetch(`${contextPath}api/sso/info`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            })
            .then(response => {
                return response.json();
            }).then(data => {
                setAvailableProviders(data);
            });

    }, [])

    const providers = props.providers && JSON.parse(props.providers);

    return <div>
        {providers.map((provider, index) => {
            const src = `${contextPath}oauth2/authorization/${provider}`
            const type = `${provider}-type`
            const eventName = `${provider}-result-event`
            const label = `Login using ${provider}`

            return <IFrameLogin
                key={`ssologin-${provider}`}
                {...props}
                src={src}
                eventName={eventName}

                type={type}
                label={label}
                usePopup={true}
            />
        })}
    </div>;
}

/**
 * Signicat signature.
 */


interface SignicatSignatureProps {
    id: string;
    //value?: string;
    validation: {
        readOnly?: boolean,
    };
    /**
     * the readonly mode field label.
     */
    label: string;
    /**
     * on submit value callback.
     */
    valueChangeCbk: (key, value, submit?) => void;

    /**
     * value is set from @PrepareSignicatSignatureDelegate
     */
    value: {
        /**
         * signing document session ref id
         */
        documentId: string;
        /**
         * signing order id
         */
        orderId: string;
        /**
         * the signing url
         */
        signingUrl: string;
        /**
         * signing task id
         */
        signTaskId: string;
        /**
         * signed doc id
         */
        signDocId: string;
        /**
         * signing country
         */
        signedCountry: string;
        completed: boolean;
    }
}

/**
 * Signicat readonly view component.
 * Fetch and show signed document value.
 * @param props
 * @constructor
 */
const ReadonlySignicatSignatureView = (props: SignicatSignatureProps) => {

    const isSignCompleted = (props: SignicatSignatureProps): boolean => {
        return typeof props === 'object' && typeof props.value === 'object' && props.value.completed === true;
    };

    return (
        isSignCompleted(props)
            ? <CustomFileFieldWithUrl {...{
                fileUrl: signicatService.signature.signedDocumentURLFactory(props.value.signedCountry, props.value.documentId),
                fileName: 'signed-document',
                label: 'Signed Document'
            }}/>
            : <div>document signature not completed</div>
    );
};

/**
 * Signicat signing ceremony component.
 * @param props
 * @constructor
 */
const SignicatSignatureView = (props: SignicatSignatureProps) => {
    /**
     * Handle iframe response jsp event message.
     * @param event
     */
    const handleSignatureEventMessage = (event) => {
        if (event && event.data && event.data.name === 'signicat-result-event') {
            console.debug(event.data);
            if (event.data && event.data.data && event.data.data.details) {
                props.valueChangeCbk(props.id, {
                    ...props.value,
                    completed: event.data.data.details === 'success',
                }, true);
            }
        }
    };

    useEffect(() => {
        console.debug('subscribe handle signicat listener')
        window.addEventListener("message", handleSignatureEventMessage);

        return () => {
            window.removeEventListener("message", handleSignatureEventMessage);
            console.debug('unsub handle signicat listener');
        }
    }, []);

    return (
        <div id={'signicat-se-auth-container'} style={{width: "100%", height: "75vh"}}>
            <iframe id={'signicat-se-auth-iframe'} src={props.value.signingUrl}
                    style={{width: "100%", height: "100%", border: "none"}}/>
        </div>
    );
};
/**
 * Signicat signature wrapper component.
 * Validate the prepared value and selects the readonly or signing ceremony component.
 * Use @see PrepareSignicatSignatureDelegate to prepare field configuration value.
 * @param props
 * @constructor
 */
export const SignicatSignature = (props: SignicatSignatureProps) => {
    const isReadOnly = typeof props.validation === 'object' && props.validation.readOnly ? true : false;

    /**
     * validate prepared value.
     * @param props
     * @returns boolean
     */
    const hasValidValue = (props: SignicatSignatureProps): boolean => {
        const {value} = props;
        if (value && typeof value === 'object' && typeof value.documentId === 'string'
            && typeof value.orderId === 'string' && typeof value.signingUrl === 'string'
            && typeof value.completed === 'boolean' && typeof value.signDocId === 'string'
            && typeof value.signedCountry === 'string' && typeof value.signTaskId === 'string') {
            return true;
        } else {
            return false;
        }
    }

    return (
        hasValidValue(props)
            ? isReadOnly
                ? <ReadonlySignicatSignatureView {...props} />
                : <SignicatSignatureView {...props} />
            : <div>
                Invalid field configuration
            </div>
    );
};

declare global {
    interface Window {
        grecaptcha: any;
    }
}
export const Recaptcha = (props: {
    id: string;
    valueChangeCbk: (key, value, submit?) => void;
    validation: {
        readOnly?: boolean,
    },
    site_key: string;
    suspended?: string;
    tokens_required?: string;
    value: string

}) => {
    type TokenHolder = {
        siteKey: string,
        token: string | null,
        timestamp: number
    }
    const isReadOnly = !!props.validation?.readOnly;
    const siteKey = props.site_key;
    const [tokens, setTokens] = useState<TokenHolder[] | null>(null)
    const suspended = props.suspended == 'true'
    const tokens_required = props.tokens_required && parseInt(props.tokens_required) || 1

    const appendScript = () => new Promise<typeof window.grecaptcha>((resolve, reject) => {
        const script = document.createElement("script");
        if (!document.getElementById("recaptcha-script")) {
            script.id = "recaptcha-script";
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
            // script.async = true;

            script.onload = evt => {
                console.log("recaptcha script loaded", siteKey);
                resolve(window.grecaptcha)
            }
            document.head.appendChild(script);
        } else {
            window.grecaptcha && resolve(window.grecaptcha)
        }

    })

    useEffect(() => {
        if (tokens == null) {
            if (suspended) {
                var array: TokenHolder[] = [...Array(tokens_required)].map((_, _2) => {
                    return {siteKey, token: null, timestamp: 0}
                });
                setTokens(array)
            } else {
                appendScript().then(async gr => {
                    gr.ready(async (...args) => {
                        const tokens: TokenHolder[] = [];
                        for (let i = 0; i < tokens_required; i++) {
                            try {
                                const token = await gr.execute(siteKey, {action: 'homepage'})
                                tokens.push({siteKey, token, timestamp: Date.now()})
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        setTokens(tokens)
                    })
                })
            }
        } else {
            props.valueChangeCbk(props.id, tokens, false)
        }
        return () => {
            const recaptcha_badge = document.getElementsByClassName('grecaptcha-badge')
            Array.from(recaptcha_badge).forEach(badge => {
                (badge as HTMLDivElement).style.display = 'none'
            })

        }

    });

    return <></>
}

export const Graph = (props) => {

    const [widthHeight, setWidthHeight] = useState({width: 800, height: 1000})

    const {width, height} = widthHeight
    const divRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (divRef.current) {
            const br = divRef.current.getBoundingClientRect()
            if (br.width != width || br.height != height) {
                setWidthHeight({width: br.width, height: br.height})
            }
        }
    }, [divRef.current]);

    const focusedNodeId = props.value.OwnershipExplorer.Nodes && props.value.OwnershipExplorer.Nodes[0].ROOT_BVD_ID_NUMBER;
    if (!focusedNodeId)
        return <div>no data</div>

    if (!props.value.OwnershipExplorer.Edges) {
        return <div>
            <div>Data found but no relationships were returned</div>
            <ul>
            {props.value.OwnershipExplorer.Nodes.map(n => {
                return <li>{n.ROOT_NAME}</li>
            })}
            </ul>
        </div>
    }

    const [openedNodes, setOpenedNodes] = useState<string[]>([focusedNodeId]);


    const links = props.value.OwnershipExplorer.Edges?.map(edge => {
        // const childNode = props.value.OwnershipExplorer.Nodes.find(n=>n.ROOT_BVD_ID_NUMBER == edge.ChildBvDID)
        // const parentNode = props.value.OwnershipExplorer.Nodes.find(n=>n.ROOT_BVD_ID_NUMBER == edge.ParentBvDID)
        // const ownerShipType = childNode?.OE_OWNERSHIP_ROLE
        // const upwards = ownerShipType == 'Shareholder'
        return {
            source: edge.ParentBvDID,
            target: edge.ChildBvDID,
            label: edge.DirectPercentage,
        }
    }).filter(l => {
        return openedNodes.find(n => n == l.source) || openedNodes.find(n => n == l.target)
    }) ?? [];


    const foundNodes = props.value.OwnershipExplorer.Nodes?.filter(n => {
        return links.find(l => l.source == n.ROOT_BVD_ID_NUMBER) || links.find(l => l.target == n.ROOT_BVD_ID_NUMBER)
    })
    const nodes = foundNodes.map(n => {
        const isOpen = openedNodes.find(o => o == n.ROOT_BVD_ID_NUMBER)
        const isRoot = n.ROOT_BVD_ID_NUMBER == focusedNodeId
        const hasChildren = props.value.OwnershipExplorer.Edges?.find(l => l.ChildBvDID == n.ROOT_BVD_ID_NUMBER)

        const color = isRoot? 'red' : (isOpen ? 'lightgreen' : 'lightblue');
        const symbolType = hasChildren ? 'square' : 'circle';

        return {
            id: n.ROOT_BVD_ID_NUMBER,
            name: n.ROOT_NAME + (isRoot?'': '(' + n.OE_OWNERSHIP_ROLE + ')'),
            color,
            symbolType,
            ...(isRoot ?  {x:50,y:50}:{})
        }
    }) ?? [];



    const data = {
        nodes,
        links,
        focusedNodeId
    }

    const myConfig = {
        nodeHighlightBehavior: false,
        automaticRearrangeAfterDropNode: true,
        directed: true,
        collapsible: true,
        node: {
            color: "lightgreen",
            size: 120,
            highlightStrokeColor: "blue",
            labelProperty: "name",
        },
        link: {
            highlightColor: "lightblue",
            renderLabel: true
        },
        d3: {
            alphaTarget: 0.05,
            gravity: -100,
        }
    };

    const onClickNode = function (nodeId, node) {
        if (openedNodes.find(n => n == nodeId)) {
            //Do nothing
        } else {
            setOpenedNodes([...openedNodes, nodeId])
        }
    };

    const onClickLink = function (source, target) {
        window.alert(`Clicked link between ${source} and ${target}`);
    };


    return <div className={"graph-wrapper"} ref={divRef}><RD3Graph
        id={props.id} // id is mandatory
        data={data}
        height={height}
        width={width}
        viewBox={`0 0 100 100`}
        config={myConfig}
        onClickNode={onClickNode}
        onClickLink={onClickLink}


    /></div>;
}

export const Link = (props: CustomComponentsBaseParams<string> & {
    text: string,
    target: HTMLAttributeAnchorTarget
}) => {
    const href = props.value
    const target = props.target as HTMLAttributeAnchorTarget || "_blank"
    const id = props.id;
    const text = props.text || href
    const label = props.label

    const layout = useLayout();
    if (true || layout == 'facing' || layout == 'review') {
        return <Grid container wrap={'nowrap'} spacing={1}>
            <Grid item>
                <Typography>{props.label}</Typography>
            </Grid>
            <Grid item>
                <Typography><a
                    id={props.id}
                    href={href}
                    target={target}

                >{text}</a></Typography>
            </Grid>
        </Grid>
    } else {
        return <Box mt={1} mb={0.5} ml={2} mr={2} key={props.id}>
            <FormControlLabel
                control={
                    <a
                        id={props.id}
                        href={href}
                        target={target}

                    >{text}</a>
                } label={label}
            />

        </Box>
    }

}


export const Operation = (props: CustomComponentsBaseParams<string> & { argument_type?: string }) => {
    const argument_type = props.argument_type
    const operandHolder = OperandHolder.from(props.value);
    const readOnly = props.validation?.readOnly
    const layout = useLayout();
    if (layout === 'review' && readOnly) {
        return <Grid container wrap={'nowrap'} spacing={1}>
            <Grid item>
                <Typography>{props.label}</Typography>
            </Grid>
            <Grid item>
                <Typography>{operandHolder?.toString() || ""}</Typography>
            </Grid>
        </Grid>
    }

    return <TextField
        variant={"filled"}
        fullWidth
        disabled={readOnly}
        label={props.label}
        value={operandHolder?.getArgument()}
        onChange={evt => {
            const newOperandHolder = new OperandHolder(operandHolder?.getOperand() || "", evt.target.value)
            props.valueChangeCbk(props.id, newOperandHolder)
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Select
                    aria-label="toggle operand"
                    disableUnderline={true}
                    onChange={evt => {
                        const value = evt.target.value as string
                        const newOperandHolder = new OperandHolder(value, operandHolder?.getArgument() || "")
                        props.valueChangeCbk(props.id, newOperandHolder)
                    }}
                    value={operandHolder?.getOperand()}
                    disabled={readOnly}

                >
                    <option value={""}>{""}</option>
                    <option value={">="}>{">="}</option>
                    <option value={">"}>{">"}</option>
                    <option value={"<="}>{"<="}</option>
                    <option value={"<"}>{"<"}</option>
                    <option value={"!="}>{"!="}</option>
                    <option value={"="}>{"="}</option>
                </Select>
            </InputAdornment>
        }}

    />
}

Operation.requiredFN = (value) => {
    const holder = OperandHolder.from(value);
    return holder && holder.getOperand() && holder.getArgument()
}


export const GeoIP = (props) => {
    const [ip, setIp] = useState(props.value);
    useEffect(() => {
        fetch('https://api.ipify.org?format=json').then(res => res.json()).then(res => {
            const ip = res.ip;
            fetch(`${contextPath}api/geoip/?ip=${encodeURIComponent(res.ip)}`).then(res => res.text()).then(res => {
                const country = res;
                const ret = {ip, country};
                ReactDOM.unstable_batchedUpdates(() => {
                    setIp(ret)

                    props.valueChangeCbk(props.id, ret, false)
                    if (!props.readOnly) {
                        if (props.ip_id) {
                            props.valueChangeCbk(props.ip_id, ip, false)
                        }

                        if (props.country_id) {
                            props.valueChangeCbk(props.country_id, country, false)
                        }


                    }
                });
            });

        })

    }, []);
    return <div style={{visibility: 'hidden'}}>{ip?.ip || ''} {ip?.country || ''}</div>
}

(function registerAll() {
    const all = require('./react_components')
    Object.keys(all).forEach(key => {
        const value = all[key];
        registry.register(key, value)
    })
}())

