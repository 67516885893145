import *  as React from 'react';
import {CSSProperties, useLayoutEffect, useMemo, useRef, useState} from 'react';
import * as _ from 'lodash';
import * as $ from 'jquery';

import ScrollParent from 'scrollparent';

import clsx from 'clsx';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Tooltip
} from "@material-ui/core";
import { useLayout } from 'sancus-client-common/dist/common/LayoutContext';
import GetAppIcon from '@material-ui/icons/GetApp';
import MomentUtils from "@date-io/moment";

const momentUtils = new MomentUtils();



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const va = a[orderBy];
    const vb = b[orderBy];
    if (va == null && vb == null){
        return 0;
    }else if (va == null){
        return 1;
    }else if (vb == null){
        return -1;
    }else if (vb < va) {
        return -1;
    }else if (vb > va) {
        return 1;
    }else {
        return 0;
    }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

enum SupportedFieldTypes {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date',
    DATETIME = 'datetime'
}

interface HeadCell {
    /*disablePadding: boolean;*/
    id: string;
    label: string;
    hidden?: string | boolean;
    fieldType?: SupportedFieldTypes;
    /*numeric: boolean;*/
}

interface DataToShow {
    id: string;

    [key: string]: string | number | boolean;
}


/*const headCells: HeadCell[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    { id: 'calories', numeric: false, disablePadding: false, label: 'Calories' },
    { id: 'fat', numeric: false, disablePadding: false, label: 'Fat (g)' },
];*/

type SelectType = "none" | "single" | "multi";

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
    selectType: SelectType;
    readOnlyMode: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, selectType, readOnlyMode} = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {
                    selectType === "multi" && <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                        />
                    </TableCell>
                }
                {
                    selectType === "single" && <TableCell padding="checkbox"></TableCell>
                }

                {headCells.map((headCell, index) => (
                    <TableCell key={headCell.id} style={{display: headCell.hidden ? 'none' : 'table-cell'}}
                        /*align={headCell.numeric ? 'right' : 'left'}*/
                        /*padding={headCell.disablePadding ? 'none' : 'default'}*/
                               sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                {!readOnlyMode && (
                    <TableCell
                        key={"editActions"}
                        align={'right'}
                    >
                        Actions
                    </TableCell>
                )}

            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
    tableLabel: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const {numSelected, tableLabel} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {tableLabel}
                </Typography>
            )}
            {/*{numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}*/}
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        zeroTextInputMargin: {
            "& [class*=\"MuiOutlinedInput\"]": {margin: 0}
        }
    }),
);

export default function DataTable(props) {
    console.log('DataTable renders', props);
    let receivedDataToShow: DataToShow[] = props.value;
    let whereAddedGenericId;

    if (receivedDataToShow == null){
        receivedDataToShow = [];
    }

    //for better select work we have to check id or create it and mark as added before submit data
    if (receivedDataToShow && receivedDataToShow.length) {
        if (!("id" in receivedDataToShow[0])) {
            receivedDataToShow = receivedDataToShow.map((r, i) => {
                if (r.id) {
                    return r;
                } else {
                    whereAddedGenericId = true;
                    r.id = String(i + 1);
                    r.idWasAddedByGUI = true;
                    return r;
                }
            })
        }
    }


    let labels: HeadCell[] = [];
    if (props.columnsLabels) {
        try {
            labels = typeof props.columnsLabels === 'string' ? JSON.parse(props.columnsLabels) : props.columnsLabels as HeadCell[];
        } catch (e) {
            console.error(e);
        }
    }

    if (!labels.length) {
        throw new Error('DataTable rowsLabels is required');
    }

    if (!props.selectType) {
        throw new Error('DataTable selectType is required');
    }

    const firstLabelId = labels.filter(l=>!l.hidden)[0].id;

    const defaultOrderBy = props.orderBy || firstLabelId;
    const defaultOrder = props.defaultOrder || 'asc';
    const tableLabel = props.tableLabel;
    const selectType: SelectType = props.selectType;
    const selectOutputFieldName = props.selectOutputFieldName;
    const showPagination = props.showPagination === 'true';
    const setSelectValueEval = props.setSelectValueEval;
    const layout = useLayout();
    const readOnlyMode = props.validation && props.validation.readOnly || layout === 'review';

    if (selectType !== "none" && !setSelectValueEval) {
        throw new Error('DataTable setSelectValueEval is required if selectType used');
    }

    const defaultRowsPerPage = showPagination && (props.rowsPerPage && parseInt(props.rowsPerPage, 10) || 10) || receivedDataToShow.length;
    const classes = useStyles();
    const [dataToShow, setDataToShow] = useState<DataToShow[]>(receivedDataToShow);

    const rowsPerPageOptions = useMemo(() => {
        const ret = [5,10,25,50,100].filter(r=>r<=receivedDataToShow.length);
        if (ret.indexOf(receivedDataToShow.length) == -1){
            ret.push(receivedDataToShow.length);
        }
        return ret;
    },[receivedDataToShow.length]);

    const [order, setOrder] = useState<Order>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [currentlyEditField, setCurrentlyEditField] = useState<{ [key: string]: string } | null>(null);
    const currentEditRowFieldsValueRef = useRef<{ [key: string]: string | number | boolean }>({});

    console.log('DataTable  currentlyEditField', currentlyEditField);
    console.log('DataTable  currentEditRowFieldsValueRef.current', currentEditRowFieldsValueRef.current);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedRows = dataToShow.map((n) => n["id"] as string);
            setSelectedRows(newSelectedRows);
            const calculated = calculateSelectValueEval(dataToShow)
            console.debug('selectOutputFieldName, calculateSelectValue(row):: ', selectOutputFieldName, calculated);
            props.valueChangeCbk(selectOutputFieldName, calculated, false);

        }else {
            setSelectedRows([]);
            props.valueChangeCbk(selectOutputFieldName, [], false);
        }

    }

    function calculateSelectValueEval(data: any | any[]): string | string[] {
        //TODO: Add support for the generic context evaluation
        let value;
        try {
            if (data == null){
                //Fallback to identity function
                return data;
            }else if (typeof setSelectValueEval === 'function'){
                if (Array.isArray(data)){
                    value = data.map(d=>setSelectValueEval(d));
                }else {
                    value = setSelectValueEval(data);
                }
                return value;
            }else if (typeof setSelectValueEval == 'string'){
                const evalFN = eval(setSelectValueEval);
                if (Array.isArray(data)){
                    value = data.map(d=>evalFN(d));
                }else {
                    value = evalFN(data);
                }
                return value;
            }else{
                return setSelectValueEval;
            }
        } catch (e) {
            console.error('calculateSelectValueEval ', e);
            throw e;
        }
    }

    const handleFieldClickForEdit = (event: React.MouseEvent<unknown>, row: DataToShow, label: HeadCell) => {
        if (readOnlyMode) {
            return;
        }

        setCurrentlyEditField({[row.id]: label.id});
        currentEditRowFieldsValueRef.current = {};
    };

    const handleSelectClick = (event: React.MouseEvent<unknown>, row: DataToShow) => {
        if (selectType === "none") {
            return;
        }

        const selectedId: string = row["id"];
        const selectedIndex = selectedRows.indexOf(selectedId);

        if (selectType === "single") {
            if (selectedIndex === -1) {
                setSelectedRows([selectedId]);

                const calcValue = calculateSelectValueEval(row);
                console.log('selectOutputFieldName, calculateSelectValue(row) ::', selectOutputFieldName, calcValue);
                return props.valueChangeCbk(selectOutputFieldName, calcValue, false);
            } else {
                setSelectedRows([]);
                return props.valueChangeCbk(selectOutputFieldName, null, false);
            }
        }

        let newSelected: string[] = [...selectedRows];

        if (selectedIndex === -1) {
            newSelected.push(selectedId);
        } else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelectedRows(newSelected);
        const filtered = dataToShow.filter(d => newSelected.indexOf(d["id"]) > -1);
        const calculated = calculateSelectValueEval(filtered)

        console.debug('selectOutputFieldName, calculateSelectValue(row):: ', selectOutputFieldName, calculated);
        props.valueChangeCbk(selectOutputFieldName, calculated, false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const removeIdsAddedByGUI = (rows) => {
        if (whereAddedGenericId) {
            const cleanRows = _.cloneDeep(rows);

            _.each(cleanRows, field => {
                if (field["idWasAddedByGUI"]) {
                    delete field["idWasAddedByGUI"];
                    delete field["id"];
                }
            })
            return cleanRows;
        } else {
            return rows;
        }
    }


    const handleSaveRowChanges = (event, row) => {
        if (Object.keys(currentEditRowFieldsValueRef.current).length) {
            setDataToShow(prevState => {
                const changedRow = prevState.filter(r => r.id === row.id)[0];

                Object.keys(currentEditRowFieldsValueRef.current).forEach((labelId) => {
                    // cast value according to field type
                    const columnLabel = _.find(labels, l => l.id == labelId);

                    if (columnLabel && columnLabel.fieldType === SupportedFieldTypes.STRING) {
                        changedRow[labelId] = currentEditRowFieldsValueRef.current[labelId];
                    }

                    if (columnLabel && columnLabel.fieldType === SupportedFieldTypes.NUMBER) {
                        changedRow[labelId] = parseInt(currentEditRowFieldsValueRef.current[labelId] + "", 10);
                    }

                    if (columnLabel && columnLabel.fieldType === SupportedFieldTypes.BOOLEAN) {
                        changedRow[labelId] = !!currentEditRowFieldsValueRef.current[labelId];
                    }


                    if (!columnLabel) {
                        changedRow[labelId] = currentEditRowFieldsValueRef.current[labelId];
                    }
                });


                props.valueChangeCbk(props.id, removeIdsAddedByGUI([...prevState]), false);
                currentEditRowFieldsValueRef.current = {};
                setCurrentlyEditField(null);
                return [...prevState];
            })
        } else {
            setCurrentlyEditField(null);
        }
    };


    const handleDeleteRowClick = (event, row) => {
        setDataToShow(prevState => {
            const valuesForSubmit = prevState.filter(r => r.id !== row.id);

            currentEditRowFieldsValueRef.current = {};
            setCurrentlyEditField(null);
            props.valueChangeCbk(props.id, removeIdsAddedByGUI(valuesForSubmit), false);

            //check and delete if row was selected
            const selectedIndex = selectedRows.indexOf(row.id);

            if (selectedIndex > -1) {
                if (selectType === "single") {
                    setSelectedRows([]);
                    props.valueChangeCbk(selectOutputFieldName, calculateSelectValueEval(dataToShow.filter(d => selectedRows.indexOf(d["id"]) > -1)), false);
                } else {
                    selectedRows.splice(selectedIndex, 1);
                    setSelectedRows([...selectedRows]);
                    props.valueChangeCbk(selectOutputFieldName, calculateSelectValueEval(dataToShow.filter(d => selectedRows.indexOf(d["id"]) > -1)), false);
                }
            }

            return valuesForSubmit;
        })
    };


    const handleChangeFieldValue = (event) => {
        currentEditRowFieldsValueRef.current[event.target.name] = event.target.value;
    };

    const handleChangeCheckboxFieldValue = (event) => {
        currentEditRowFieldsValueRef.current[event.target.name] = event.target.checked;
    };


    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;

    const RenderCellField = ({row, label}) => {
        if (!readOnlyMode) {
            if (currentlyEditField) {
                if (currentlyEditField[row.id] /*&& currentlyEditField[row.id] === label.id*/) {
                    const style = undefined;


                    if (label.fieldType === SupportedFieldTypes.STRING) {
                        return <TextField id="standard-basic" defaultValue={row[label.id]}
                                          variant="outlined"
                                          style={style}
                                          className={classes.zeroTextInputMargin}
                                          name={label.id}
                                          onChange={handleChangeFieldValue}
                        />
                    }
                    if (label.fieldType === SupportedFieldTypes.NUMBER) {
                        return <TextField id="standard-basic" defaultValue={row[label.id]}
                                          variant="outlined"
                                          style={style}
                                          className={classes.zeroTextInputMargin}
                                          name={label.id}
                                          onChange={handleChangeFieldValue}
                        />
                    }


                    if (label.fieldType === SupportedFieldTypes.BOOLEAN) {
                        return  <Checkbox
                            defaultChecked={!!row[label.id]}
                            style={style}
                            color="primary"
                            name={label.id}
                            onChange={handleChangeCheckboxFieldValue}
                        />
                    }

                    return <TextField id="standard-basic" defaultValue={row[label.id]}
                                      variant="outlined"
                                      className={classes.zeroTextInputMargin}
                                      name={label.id}
                                      onChange={handleChangeFieldValue}
                    />
                }
            }
            if (label.fieldType === SupportedFieldTypes.BOOLEAN) {
                return <Grid onClick={(event) => handleFieldClickForEdit(event, row, label)}>
                    <Checkbox
                        defaultChecked={!!row[label.id]}
                        disabled
                        color="primary"
                        name={label.id}
                        onChange={handleChangeCheckboxFieldValue}
                    />
                </Grid>
            }

            return <Grid onClick={(event) => handleFieldClickForEdit(event, row, label)}>{row[label.id]}</Grid>
        }

        if (label.fieldType === SupportedFieldTypes.BOOLEAN) {
            return  <Checkbox
                defaultChecked={!!row[label.id]}
                disabled
                color="primary"
                name={label.id}
                onChange={handleChangeCheckboxFieldValue}
            />
        }
        const ret = row[label.id];
        switch (label.fieldType) {
            case SupportedFieldTypes.DATE:
                const format = label.format || "DD/MM/yyyy";
                const timezone = label.timezone || "UTC";
                if (ret){
                    if (timezone === "UTC") {
                        return momentUtils.moment.utc(ret).format(format);
                    }else if (timezone === "local"){
                        return momentUtils.moment(ret).format(format);
                    }else{
                        //We don't support custom timezones yet
                        return momentUtils.moment.utc(ret).format(format);
                    }
                }else{
                    return "";
                }
            default:
                return ret?.toString() ?? "";
        }

    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataToShow.length - page * rowsPerPage);
    const divRef = useRef<HTMLDivElement | null>(null);
    const spacerRef = useRef<HTMLTableRowElement | null>(null);
    const [spacerMaxHeight,setSpacerMaxHeight] = useState(0);
    useLayoutEffect(()=>{

        if(divRef.current){
            const jqDiv = $(divRef.current);
            //@ts-ignore
            const scrollp = ScrollParent(divRef.current);

            console.log("scrollp",scrollp);
        }

        const scrollParent = document.getElementById("form");
        const actionContainer = document.getElementById("ActionContainer");
        const actionContainerHeight = actionContainer?.getBoundingClientRect().height || 0;

        const isSoleElement = props.formReference?.length == 1;
        if (divRef.current && divRef.current?.clientHeight >0 && scrollParent) {

            const offsetHeight = scrollParent.offsetHeight;
            const scrollHeight = scrollParent.scrollHeight;
            const scrollParentBR = scrollParent.getBoundingClientRect();
            const scrollParentTOP = scrollParentBR.top;
            if (isSoleElement) {
                //This is very tricky and can make the form not scrollable.
                // It makes sense to only enforce if dataTable is the sole element of the form
                scrollParent.style.overflowY = "hidden";
            }
            const table = divRef.current.querySelector("table");
            if (table){


                const tableParent = table.parentElement;
                const tableParentBR = tableParent!.getBoundingClientRect();
                const tableParentTOP = tableParentBR.top;
                const diffTop = tableParentTOP-scrollParentTOP;

                tableParent!.style.overflowY = "auto"

                const offsetModifier = window.innerHeight - offsetHeight;
                console.log("offsetModifier", window.innerHeight,offsetHeight,offsetModifier);
                // tableParent!.style.maxHeight = (offsetHeight - 150) + "px";

                // const modifier = 160;
                const modifier = actionContainerHeight+diffTop;
                tableParent!.style.maxHeight = `calc(100vh - ${offsetModifier+modifier}px)`;

                const thead = table.querySelector("thead");
                thead!.style.position = "sticky";
                thead!.style.top = "0";
                thead!.style.zIndex = "1";
                thead!.style.backgroundColor = "lightgray";
/*
                const mainFormBox = document.getElementById("mainFormBox");
                const mainFormBox1stChild = mainFormBox && mainFormBox.querySelector("div:first-child") as HTMLDivElement;
                // mainFormBox1stChild!.style.maxHeight = (offsetHeight - 100) + "px";
                mainFormBox1stChild!.style.maxHeight = `calc(100vh - ${offsetModifier+80}px)`;

 */
            }



            if (!isSoleElement && scrollHeight > offsetHeight) { //since we set overflowY to hidden we don't need this
                if (spacerMaxHeight !== 0) {
                    setSpacerMaxHeight(0);
                }
            }else {
                const divHeight = divRef.current.offsetHeight;
                const marginBottom = 80;
                const newSpacerMaxHeight = offsetHeight - divHeight - marginBottom;
                // const newSpacerMaxHeight = divHeight - marginBottom;
                if (spacerRef.current) {
                    const spacer = spacerRef.current;
                    if (spacerMaxHeight !== newSpacerMaxHeight && newSpacerMaxHeight > 100) {
                        setSpacerMaxHeight(newSpacerMaxHeight);
                        //Super hacky
                        setTimeout(() => {
                            spacer.style.height = newSpacerMaxHeight + "px";
                        })
                    }
                }
            }
        }
    },[divRef.current,spacerRef.current])

    const colspan = labels && labels.filter(l => !l.hidden).length + (selectType !== "none" ? 1 : 0) + (!readOnlyMode ? 1 : 0) || 0;

    const [openDownloadPopup,setOpenDownloadPopup] = useState(false);
    const downloadNameRef = useRef<HTMLInputElement>(null)
    const prepHandleDownloadAsCsv = ()=>{
        setOpenDownloadPopup(true);
    }

    const handleDownloadAsCsv = () => {
        let name = downloadNameRef.current?.value || "download.csv";
        //create the csv data
        const csvDataFactory = (labels: HeadCell[], data: DataToShow[]): string => {
            // Empty array for storing the values
            const csvRows: string[] = [];

            // Headers is basically a keys of an object
            // which is id, name, and profession
            const headers: string[] = labels.map(l => l.label);

            // As for making csv format, headers must
            // be separated by comma and pushing it
            // into array
            csvRows.push(headers.join(','));

            // Pushing Object values into array
            // with comma separation
            /*const values: string = Object.values(data).join(',');
            csvRows.push(values)*/
            data.forEach((d: DataToShow) => {
                csvRows.push(Object.values(labels).map(l => {
                    let value = d[l.id];
                    if (typeof value === 'string' && value.includes(',')){
                        value = `"${value}"`;
                    }
                    return value;
                }).join(','))
            });

            // Returning the array joining with new line
            return csvRows.join('\n')
        };

        const download = function (data: string) {

            // Creating a Blob for having a csv file format
            // and passing the data with type
            const blob = new Blob([data], { type: 'text/csv' });

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob)

            // Creating an anchor(a) tag of HTML
            const a = document.createElement('a')

            // Passing the blob downloading url
            a.setAttribute('href', url)

            // Setting the anchor tag attribute for downloading
            // and passing the download file name
            if (!name.endsWith('.csv')){
                name += '.csv';
            }
            a.setAttribute('download', name);
            // Performing a download with click
            a.click()
        };
        const csvData = csvDataFactory(labels, dataToShow);
        download(csvData);
        setOpenDownloadPopup(false);
    };

    return (<>
        <div className={classes.root+" tableDivRef"} ref={divRef}>
            <Paper className={classes.paper+" tablePaper"} elevation={0}>
                {tableLabel ? <EnhancedTableToolbar numSelected={selectedRows.length} tableLabel={tableLabel}/> : <></>}
                <TableContainer>
                    <Table
                        className={classes.table+ "tableTable"}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedRows.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={dataToShow.length}
                            headCells={labels}
                            selectType={selectType}
                            readOnlyMode={readOnlyMode}
                        />
                        <TableBody>

                                {stableSort(dataToShow, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row["id"]);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row["id"]}
                                                selected={isItemSelected}
                                            >
                                                {selectType !== "none" && <TableCell padding="checkbox" >
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                        onClick={(event) => handleSelectClick(event, row)}
                                                    />
                                                </TableCell>}

                                                {labels.map(label => {
                                                    if (label.id == firstLabelId) {
                                                        return <TableCell style={{display: label.hidden ? 'none' : 'table-cell'}}

                                                                          id={labelId} scope="row"
                                                                          {...(selectType !== "none" ? {padding: "none"} : {})}>
                                                            <RenderCellField row={row} label={label}/>
                                                        </TableCell>
                                                    }

                                                    return <TableCell /*align="right"*/ style={{display: label.hidden ? 'none' : 'table-cell'}}>
                                                        <RenderCellField row={row} label={label}/>
                                                    </TableCell>
                                                })
                                                }

                                                {!readOnlyMode && (
                                                    <TableCell align="right">
                                                        {
                                                            currentlyEditField && currentlyEditField[row.id] ?
                                                                (<IconButton aria-label="save"
                                                                             onClick={(event) => handleSaveRowChanges(event, row)}>
                                                                    <DoneIcon color="primary" fontSize="large"/>
                                                                </IconButton>) :

                                                                (<IconButton aria-label="edit"
                                                                             onClick={(event) => handleFieldClickForEdit(event, row, labels[0])}>
                                                                    <EditIcon color="primary"/>
                                                                </IconButton>)
                                                        }

                                                        <IconButton aria-label="delete"
                                                                    onClick={(event) => handleDeleteRowClick(event, row)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow id={"emptyRowSpacer"} style={{
                                        height:Math.min( 53 * emptyRows,spacerMaxHeight)
                                    }} ref={spacerRef}>
                                        <TableCell colSpan={colspan}/>
                                    </TableRow>
                                )}

                        </TableBody>

                    </Table>
                </TableContainer>
                <Grid container direction={'row'} wrap={'nowrap'} spacing={1}>
                    <Grid item>
                        <Tooltip title={"Export to csv"}>
                            <IconButton onClick={()=>prepHandleDownloadAsCsv()}>
                                <GetAppIcon/>
                            </IconButton>
                        </Tooltip>

                    </Grid>
                    {
                        showPagination && <Grid item style={{marginLeft: 'auto'}}>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={dataToShow.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                    }
                </Grid>
            </Paper>
            {/*<FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />*/}
        </div>
        <Dialog
            open={openDownloadPopup}
            onClose={()=>setOpenDownloadPopup(false)}
            aria-labelledby="download-file-name"
        >
            <DialogTitle id="download-file-name">{"Select a name for the file"}</DialogTitle>
            <DialogContent>
                <TextField
                    inputRef={downloadNameRef}
                    defaultValue={"download.csv"}
                    value={null}
                    label={"Name of the file"}
                />
            </DialogContent>
            <DialogActions >
                <Box pr={1}>
                    <Button onClick={()=>setOpenDownloadPopup(false)} color="primary">
                        Cancel
                    </Button>
                </Box>
                <Box p={2}>
                    <Button onClick={()=>handleDownloadAsCsv()} variant="contained" color="primary" autoFocus>
                        Export
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        </>
    );
}
