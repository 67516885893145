
export default class ElementBuilder{
    constructor(element) {
        this.element = element;
    }
    static idPrefix = "com.norbloc.sancus.integrations.";

    static forCalledActivity(name,id,callActivityName){
        const element = {
            "name": name,
            "id": id,
            "appliesTo": [
                "bpmn:CallActivity"
            ],
            "properties": [
                {
                    "label": "Activity",
                    "type": "String",
                    "value": callActivityName,
                    "editable": false,
                    "binding": {
                        "type": "property",
                        "name": "calledElement"
                    }
                }
            ]
        }
        return new CalledActivityElementBuilder(element)
    }

    static forService(name,id,delegateExpression){
        const element = {
            "name": name,
            "id": id,
            "appliesTo": [
                "bpmn:ServiceTask"
            ],
            "properties": [
                {
                    "label": "Activity",
                    "type": "String",
                    "value": delegateExpression,
                    "editable": false,
                    "binding": {
                        "type": "property",
                        "name": "camunda:delegateExpression"
                    }
                }
            ]
        }
        return new ElementBuilder(element)
    }

    allEntriesVisible(){
        this.element.entriesVisible = {
            '_all':true
        }
        return this;
    }
    entriesVisible(...entriesVisible){

        this.element.entriesVisible = entriesVisible.reduce((acc,e)=>{
            acc[e]=true
            return acc;
        },{})
        return this;
    }


    addIcon(iconURL){
        this.element.icon={
            contents: iconURL
        }
        return this;
    }

    addExecutionListener(script,editable,label,event){
        event = event || "end"
        label = label || "Execution Listener"
        const prop = {
            "label": label,
            "type":editable ? "Text":"Hidden",
            value:script,
            editable: !!editable,
            binding: {
                type:"camunda:executionListener",
                event:event,
                scriptFormat:"groovy"

            }
        }
        this.element.properties.push(prop);
        return this;
    }

    exposeVariableByName(inVariable,outVariableHolder) {

        const script = `
println "outVariableHolder = ${outVariableHolder}, inVariable=${inVariable}"
def outVarName = execution.getVariable('${outVariableHolder}');
println "outVarName = $outVarName"
if (outVarName != null && ! outVarName.isEmpty()){
    def value = execution.getVariable('${inVariable}');
    println "value=$value"
    println "execution.setVariable($outVarName,$value);"
    execution.setVariable(outVarName,value);
}
        `
        return this.addExecutionListener(script,false,"Expose variable")

    }

    addInputParam(name,label,description,value,hidden,extras){
        const prop =  {
            "label": label,
            "type": hidden?"Hidden":"String",
            "editable": !hidden,
            "binding": {
                "type": "camunda:inputParameter",
                "name": name
            }
        }
        if (description){
            prop.description = description;
        }
        if (value){
            prop.value = value;
        }
        if (extras){
            Object.assign(prop,extras);
        }
        this.element.properties.push(prop);
        return this;
    }

    addScriptInputParam(name, label, description, value,hidden, extras) {
        const prop = {
            "label": label,

            "type": hidden?"Hidden":"Text",
            "editable": !hidden,
            "binding": {
                "type": "camunda:inputParameter",
                "name": name,
                "scriptFormat": "groovy"
            }
        }

        if (description){
            prop.description = description;
        }
        if (value){
            prop.value = value;
        }
        if (extras){
            Object.assign(prop,extras);
        }
        this.element.properties.push(prop);
        return this;
    }
}

class CalledActivityElementBuilder extends ElementBuilder{
    constructor(element) {
        super(element);
    }

    addInMappingAll(){
        var prop = {
            "label": "Input all",
            "type": "Hidden",
            "binding": {
                "type": "camunda:in",
                "variables": "all"
            }
        };
        this.element.properties.push(prop)
        return this;

    }

    addInMapping(name,label,extras){
        const prop =  {
            "label":label,
            "type": "Hidden",
            "editable": false,
            "value": name,
            "binding": {
                "type": "camunda:in",
                "target": name
            }
        }
        if (extras){
            Object.assign(prop,extras);
        }
        this.element.properties.push(prop);
        return this;
    }

    addOutMappingAll(){
        var prop = {
            "label": "Output all",
            "type": "Hidden",
            "binding": {
                "type": "camunda:out",
                "variables": "all"
            }
        };
        this.element.properties.push(prop)
        return this;
    }

    addInMappingAndParam(name,label,description,value,extras){
        this.addInMapping(name,label+"(Mapping)")
        this.addInputParam(name,label,description,value,false,extras)
        return this;
    }

    addOutMapping(name,label,hidden){
        const prop = {
            "label": label,
            "type": hidden?"Hidden":"String",
            "value": name,
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": name
            }
        }
        this.element.properties.push(prop);
        return this;
    }
}

