import {
    getStartForm,
    getTaskForm,
    isStateEnded,
    loadTasks,
    ProcessInstance,
    ProcessInstanceStatus,
    StartFormData,
    Task,
    TaskFormData,
    Variables, withErrorHandler
} from "./camunda_helper";
import contextPath from "./contextPath";
import {createContext} from "react";


export interface FormHandlerStrategy {
    handleProcessInstanceId(processInstanceId:string):Promise<[string,TaskFormData | ProcessInstanceStatus]>;

    loadTasks(processInstanceId:string):Promise<Task[]>;
    handleTask(taskId):Promise<[string,TaskFormData]>;
    sendSignal(processInstanceId:string,signalName:string,variables?:Variables):Promise<void>;

    handleTask(taskId:string):Promise<[string,TaskFormData]>
    handleProcessKey(key:string,resume:boolean):Promise<StartFormData | [string,TaskFormData | ProcessInstanceStatus]>
    fetchTaskList(idr:'key' | 'id',identifier:string,instanceId?:string | null,taskId?:string | null):Promise<{startForm:boolean,taskDefs:{[taskDefId:string]:string},stepName:string}[]>

    raiseError(taskId:string,errorCode:string,errorName?:string,data?:{[key:string]:any}):Promise<TaskFormData | null>

    getProcessInstanceStatus(processInstanceId:string):Promise<ProcessInstanceStatus>

    resumeActivityBefore(processInstanceId:string,activityId:string):Promise<TaskFormData | StartFormData | null >
    resumeActivityAfter(processInstanceId:string,activityId:string):Promise<TaskFormData | StartFormData | null >

    discardProcessInstance(processInstanceId:string,taskId:string,reason?:string):Promise<void>

    startProcessBy(idr:'id'|'key',
                 processDefinitionIdOrKey:string,
                 variables?:Variables,
                 businessKey?:string):Promise<{processInstance:ProcessInstance,taskFormData:TaskFormData | null} >

    submitTaskForm(taskId: string,
                   processInstanceId: string | null | undefined,
                   taskDefId: string | null | undefined,
                   variables: Variables): Promise<TaskFormData | null>
    setCheckStatus(processInstanceId: string, checkId: string, taskId: string, resolve: boolean):Promise<any>
    addCheckNote (processInstanceId: string, checkId: string, taskId: string, text: string):Promise<any>
}


export class CamundaServerFormHandlerStrategy implements FormHandlerStrategy{
    async sendSignal(processInstanceId:string,signalName:string,variables?:Variables):Promise<void>{
        if (!processInstanceId){
            throw new Error('processInstanceId must not be null');
        }
        if (!signalName){
            throw new Error('signalName must not be null');
        }

        const response:Response = await fetch(`${contextPath}api/instance/${processInstanceId}/signal/${signalName}`,{
            method:'POST',
            credentials: 'include',
            body: (variables !=null ? JSON.stringify(variables):null),
            headers:{
                'content-type': 'application/json'
            }
        });

    }

    async raiseError(taskId:string,errorCode:string,errorName?:string,data?:{[key:string]:any}):Promise<TaskFormData | null>{
        if (!taskId){
            throw new Error('taskId must not be null');
        }
        if (!errorCode){
            throw new Error('error must not be null');
        }

        const response:Response = await fetch(`${contextPath}api/tasks/${taskId}/error`,{
            method:'POST',
            credentials: 'include',
            body: JSON.stringify({errorCode,errorName,data}),
            headers:{
                'content-type': 'application/json'
            }
        });
        return await withErrorHandler<TaskFormData>(response);
    }
    async handleProcessInstanceId(processInstanceId:string):Promise<[string,TaskFormData | ProcessInstanceStatus]>{
        if (!processInstanceId){
            throw new Error('processInstanceId must not be null');
        }
        const tasks = await loadTasks(processInstanceId)
        const firstTask:Task = tasks[0];
        if (firstTask){
            return this.handleTask(firstTask.id)
        }else{
            const status = await this.getProcessInstanceStatus(processInstanceId)
            if (isStateEnded(status.state)){
                return ["ended",status]
            }else{
                return ["paused",status];
            }
        }

    }


    loadTasks(processInstanceId: string): Promise<Task[]> {
        return loadTasks(processInstanceId);
    }

    async handleTask(taskId:string):Promise<[string,TaskFormData]>{
        const taskFormData = await getTaskForm(taskId);
        return  [taskId,taskFormData];
    }

    async handleProcessKey(key:string,resume:boolean):Promise<StartFormData | [string,TaskFormData | ProcessInstanceStatus]>{
        try {
            const formData = await getStartForm('key', key, resume)
            if ((formData as TaskFormData)?.task) {
                const _formData = formData as TaskFormData
                return [_formData.task.id, _formData];
            } else {
                return formData as StartFormData
            }
        }catch (e){
            console.error("Error during getStartForm",e);
            throw e;
        }

    }

    async fetchTaskList(idr:'key' | 'id',identifier:string,processInstanceId?:string | null,taskId?:string | null):Promise<{startForm:boolean,taskDefs:{[taskDefId:string]:string},stepName:string}[]> {
        let url;
        if (!identifier){
            throw new Error('identifier must not be null');
        }

        if (idr === 'key') {
            url = `${contextPath}api/process/key/${identifier}/userTasks`;
        } else {
            url = `${contextPath}api/process/id/${identifier}/userTasks`;
        }

        let query = {taskId,processInstanceId}

        let queryString = Object.keys(query)
            .filter(key => query[key])
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(query[key])).join('&');
        if (queryString){
            url = url + "?" + queryString;
        }

        const rsp = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        })
        return await withErrorHandler(rsp);
    }

    async getUserSessionInfo():Promise<any> {
        const url = `${contextPath}api/users/login`;

        const rsp = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        })
        return await withErrorHandler(rsp);
    }

    async logoutUserSession():Promise<any> {
        return await fetch(`${contextPath}api/users/logout`,{
            method:'POST',
            credentials:'include',
        });
    }

    async getProcessInstanceStatus(processInstanceId:string):Promise<ProcessInstanceStatus> {
        if (!processInstanceId){
            throw new Error('processInstanceId must nor be null');
        }
        const response:Response = await fetch(`${contextPath}api/instance/${processInstanceId}/status`,{
            method:'GET',
            credentials: 'include',
        });
        return await withErrorHandler<ProcessInstanceStatus>(response)
    }

    async resumeActivityBefore(processInstanceId:string,activityId:string):Promise<TaskFormData | StartFormData | null >{
        if (!processInstanceId){
            throw new Error('processInstanceId must nor be null');
        }
        const response:Response = await fetch(`${contextPath}api/instance/${processInstanceId}/startBefore/${activityId}`);
        return await withErrorHandler<TaskFormData | StartFormData | null >(response)
    }

    async resumeActivityAfter(processInstanceId:string,activityId:string):Promise<TaskFormData | StartFormData | null >{
        if (!processInstanceId){
            throw new Error('processInstanceId must nor be null');
        }
        const response:Response = await fetch(`${contextPath}api/instance/${processInstanceId}/startAfter/${activityId}`,{
            method:'GET',
            credentials: 'include',

        });
        return await withErrorHandler(response)
    }

    async discardProcessInstance(processInstanceId:string,taskId:string,reason?:string){
        if (!processInstanceId){
            throw new Error('processInstanceId must nor be null');
        }
        let queryString = `?taskId=${taskId}`
        if (reason){
            queryString = queryString+"&reason="+encodeURIComponent(reason);
        }
        const response:Response = await fetch(`${contextPath}api/instance/${processInstanceId}${queryString}`,{
            method:'DELETE',
            credentials: 'include',

        });
        return await withErrorHandler<void>(response)
    }

    async startProcessBy(idr:'id'|'key',
                                  processDefinitionIdOrKey:string,
                                  variables?:Variables,
                                  businessKey?:string):Promise<{processInstance:ProcessInstance,taskFormData:TaskFormData | null} >{
        const endPoint = idr == 'key'?`key/${processDefinitionIdOrKey}` : `id/${processDefinitionIdOrKey}`;



        const rsp = await fetch(`${contextPath}api/process/${endPoint}/start`,{
            method:'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: (variables !=null ? JSON.stringify(variables):null)
        })

        return await withErrorHandler<{processInstance:ProcessInstance,taskFormData:TaskFormData}>(rsp);


    }
    async submitTaskForm(taskId: string,processInstanceId:string | null | undefined,taskDefId:string | null | undefined, variables:Variables):Promise<TaskFormData | null>{
        let url = `${contextPath}api/tasks/${taskId}/submitForm`;
        if (taskDefId !=null || processInstanceId !=null){
            url = url+"?";
            if (processInstanceId !=null){
                url = url + `processInstanceId=${processInstanceId}`
                if (taskDefId !=null){
                    url = url +"&"
                }
            }
            if (taskDefId !=null){
                url = url + `taskDefId=${taskDefId}`
            }
        }


        const rsp = await fetch(`${contextPath}api/tasks/${taskId}/submitForm?processInstanceId=${processInstanceId}&taskDefId=${taskDefId}`,{
            method:'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body:(variables !=null? JSON.stringify(variables):null) })
        console.log(`_submitTaskForm returned status :${rsp.status}`)
        let json;
        return await withErrorHandler(rsp);
    }

    /**
     * update a checks status
     * @param processInstanceId
     * @param checkId
     * @param taskId
     * @param resolve
     */
    async setCheckStatus(processInstanceId: string, checkId: string, taskId: string, resolve: boolean):Promise<any> {
        return await fetch(`${contextPath}api/instance/${processInstanceId}/check/${checkId}?taskId=${taskId}`,{
            method:'POST',
            credentials:'include',
            body: JSON.stringify({resolve}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    /**
     * update a checks notes
     * @param processInstanceId
     * @param checkId
     * @param taskId
     * @param text
     */
    async addCheckNote (processInstanceId: string, checkId: string, taskId: string, text: string) {
        return fetch(`${contextPath}api/instance/${processInstanceId}/check/${checkId}/notes?taskId=${taskId}`,{
            method:'POST',
            credentials:'include',
            body: JSON.stringify({text}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}

const FormHandlerContext = createContext(new CamundaServerFormHandlerStrategy());
export default FormHandlerContext;

