export default {
    "name": "BankID Signature",
    "id": "com.norbloc.sancus.integrations.SignicatSignature",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://developer.signicat.com/enterprise/css/signicat-css/dist/logo/favicon/favicon-16x16.png"
    },
    entriesVisible:{
        'sancus.widget.step':false,
        properties:true
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "bankid_signature",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "BAnkID Signed Document (Mapping)",
            "type": "String",
            "value": "bankID_signature",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "bankID_signature"
            }
        },
        {
            "label": "Personal Number (Mapping)",
            "type": "String",
            "value": "personalNumber",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "personalNumber"
            }
        },

        {
            "label": "Sign Text (Mapping)",
            "type": "Hidden",
            "value": "signText",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "signText"
            }
        },

        {
            "label": "Sign Text",
            "type": "String",
            "editable": true,
            "value":"Sign Text",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "signText"
            }
        },


        {
            "label": "User Visible Sign Text (Mapping)",
            "type": "Hidden",
            "value": "userVisibleText",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "userVisibleText"
            }
        },

        {
            "label": "User Visible Sign Text",
            "type": "String",
            "editable": true,
            "value":"User Visible Sign Text",
            "binding": {
                "type": "camunda:inputParameter",
                "name": "userVisibleText"
            }
        },

        {
            "label": "Logged in User Id (Mapping)",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "userId"
            }
        }
    ]
}