export default {
    "name": "Adobe Sign",
    "id": "com.norbloc.sancus.integrations.AdobeSign",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://www.adobe.com/content/dam/dx-dc/favicons/favicon.ico"
    },
    entriesVisible:{
        'sancus.widget.step': false,
        properties: true
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "adobe_sign",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },

        {
            "label": "User Id Mapping",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "userId"
            }
        },

        {
            "label": "AdobeSign Signed Document (Mapping)",
            "type": "String",
            "value": "adobe_sign",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "adobe_sign"
            }
        },
        {
            "label": "Document (Mapping)",
            "type": "Hidden",
            "value": "DOCUMENT",
            "editable": false,

            "binding": {
                "type": "camunda:in",
                "target": "DOCUMENT"
            }
        },

        {
            "label": "Document",
            "type": "String",
            "description": "A byte array or a FileUpload handler",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "DOCUMENT"
            }
        },

        {
            "label": "Recipient email (Mapping)",
            "type": "Hidden",
            "value": "RECIPIENT_EMAIL",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "RECIPIENT_EMAIL"
            }
        },

        {
            "label": "Recipient email",
            "type": "String",

            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "RECIPIENT_EMAIL"
            }
        },

    ]
}