export default {
    "name": "Manual Check",
    "id": "com.norbloc.sancus.integrations.ManualCheck",
    "appliesTo": [
        "bpmn:ServiceTask"
    ],
    "icon":{
        "contents":"https://icons.getbootstrap.com/assets/icons/check2-circle.svg"
    },
    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "#{addManualChecks}",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "camunda:delegateExpression"
            }
        },

        {
            "label": "Manual Check Message",
            "type": "String",

            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "messageText"

            }
        },

        {
            "label": "Manual Check Sections",
            "type": "String",

            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "messageSections"

            }
        },{
            "label": "Manual Check Fields",
            "type": "String",

            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "messageFields"

            }
        }
    ]
}