import { handleActions } from 'redux-actions';
import {FormContainerActions} from "../actions/formContainer.actions";

export const formContainerErrorReducer = handleActions<any, any>(
    {

        [FormContainerActions.Type.SET_ERROR]: (state, action) => {
            return action.payload;
        },
        [FormContainerActions.Type.CLEAR_ERROR]: (state, action) => {
            return null;
        },
    },
    {} as any
);