import {FormField, FormProperty} from "./camunda_helper";
import {SancusConditionEvaluator} from "./conditionEvaluator.service";
import {AnyCheckType, FileChangeCallback, ICheck, ValueChangeCallback} from "./FormContainerBase";
import {Ref, SyntheticEvent} from "react";

export const substitutions = {
    'minlength':'minLength',
    'maxlength':'maxLength',
    'readonly':'readOnly',
    'required':'required'
}

export function htmlType(camType:string){
    switch (camType.toLowerCase()) {
        case "boolean":
            return "checkbox";
        case "long":
            return "number"
        case "date":
            return "date"
        case "datetime":
            return "datetime"
        default:
            return camType.toLowerCase();
    }
}

export type FormReference = FormField[]

export interface ContextAware {
    contextEvaluator: SancusConditionEvaluator;
    formReference: FormReference
}

export interface FieldValidationCallbacks {
    onChange(value: any): void;
    onBlur(evt?:SyntheticEvent<HTMLElement>): void;
}

export type GenericFieldParams<T=any> = FormProperty & {
    properties:{
        type?:string,
        component?:string
        repeatable?:string,
        section?:string,
        [key:string]:string | undefined,
        visible?: string,
    }
    _value: any //Holds the current value passed down from the FormContainer
    valueChangeCbk: ValueChangeCallback;
    fileChangeCbk:FileChangeCallback
    index?:number
    path:string,
    checkMessages?: AnyCheckType
    fieldValidationCallbacks?: FieldValidationCallbacks;

    focused?:boolean
} & ContextAware;


export type ContextAwareGenericFieldProperties = GenericFieldParams & ContextAware;

export type CustomComponentsBaseParams<T=string,K extends string=never> = {

    id:string,
    path:string,
    autocomplete?:string,
    name:string,
    label:string,

    helperText?:string,
    helperText_2?:string,

    value?:T,
    _value?:T,
    validation:{
        readOnly?,
        maxLength?
        minLength?
        required?,
        max?,
        min?,
        pattern?
    },
    valueChangeCbk: ValueChangeCallback,
    fileChangeCbk:FileChangeCallback,
    contextEvaluator: SancusConditionEvaluator,
    formDefId:string,
    fieldValidationCallbacks: FieldValidationCallbacks;

    required?:boolean,
    formReference: FormReference,
    elemRef:Ref<any>,

    checkMessages?: AnyCheckType


    //Extra properties from  the component are unwinded here
} & { //Can be injected by withLang
    lang?:string,
    locale?:string
} & {//can be repeatable field
    index?: number;
} & Record<K, string>