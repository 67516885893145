import contextPath from './contextPath';
import {FidesConfig} from "../CustomerData";

interface FidesConfigAndFields {
    config: FidesConfig,
    fields: string[] | undefined,
}

interface FidesConfigApi {
    fetchConfig(): Promise<FidesConfigAndFields | never>;
}

interface FidesConfigApiConfig {
    contextPath: string
}

const FidesConfigApiFactory = (config: FidesConfigApiConfig): FidesConfigApi => {
    let cached: FidesConfigAndFields | null = null;

    return {
        fetchConfig: async (): Promise<FidesConfigAndFields | never> => {
            if(cached) {
                return Promise.resolve(cached) as any;
            }
            else {
                const promise = fetch(`${config.contextPath}api/field-defaults/fides-config/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        } else {
                            throw response;
                        }
                    })
                    .then(response => {
                        return response.json();
                    }) as Promise<FidesConfigAndFields | never>;
                cached = await promise;
            }
            return cached;
        }
    }
};

export const fidesConfigApi = FidesConfigApiFactory({contextPath} as any);