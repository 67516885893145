import React, {createRef, useContext, useEffect, useState} from 'react';
import DmnViewer from 'dmn-js';
import DmnModeler from 'dmn-js/lib/Modeler';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {Grid, Modal, withStyles} from "@material-ui/core";
import initialDmnTemplate from './initial_dmn'
import Ids from 'ids';

import {makeStyles, useTheme} from "@material-ui/core/styles";

import 'dmn-js/dist/assets/diagram-js.css';
import 'dmn-js/dist/assets/dmn-font/css/dmn.css';
import 'dmn-js/dist/assets/dmn-js-shared.css';
import 'dmn-js/dist/assets/dmn-js-drd.css';
import 'dmn-js/dist/assets/dmn-js-decision-table.css';
import 'dmn-js/dist/assets/dmn-js-decision-table-controls.css';
import 'dmn-js/dist/assets/dmn-js-literal-expression.css';
import 'dmn-js/dist/assets/dmn-font/css/dmn-embedded.css';

import 'dmn-js-properties-panel/dist/assets/properties-panel.css';

import './dmn.css';

import {
    DmnPropertiesPanelModule,
    DmnPropertiesProviderModule,
} from 'dmn-js-properties-panel';


import workflowManagementServiceFactory from "../../services/workflowManagement.service";
import {AlertContext} from "../../context/alert/alertContext";

export const DEFAULT_LAYOUT = {
    width: 300
};
export const MIN_WIDTH = 150;
export const MAX_WIDTH = 800;

const useStyles = makeStyles((theme) => ({
    "dmnContent": {
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex',
    },
    "actionButtons": {
        position: 'fixed',
        bottom: '20px',
        left: '20px'
    },
    "properties-panel-parent": {
        backgroundColor: "#f8f8f8",
        borderLeft: "1px solid #ccc",
        "&:empty": {
            display: 'none'
        },
        '> .djs-properties-panel': {
            paddingBottom: '70px',
            minHeight: '100%'
        }
    }
}));

const workflowManagementService = workflowManagementServiceFactory();

export default function ReactDmnEditor(props) {
    const classes = useStyles();
    const containerRef = createRef();
    const [isThisNewDecision, setIsThisNewDecision] = useState(false);
    const [dmnModeler, setDmnModeler] = useState(null);
    const idsGenerator = new Ids([32, 36, 1]);
    const _dmnXML = props.diagramXML || (props.decisionReferenceId ? null : initialDmnTemplate("", idsGenerator));
    const [dmnXML, setDmnXML] = useState(_dmnXML);
    const {showAlert} = useContext(AlertContext);

    if (props.diagramXML) {
        setIsThisNewDecision(false);
    }

    console.debug('ReactDmnEditor props: ', props);

    useEffect(() => {
        if (dmnXML === null) {
            return;
        }

        const container = containerRef.current;
        const _dmnModeler = new DmnModeler({
            container,
            keyboard: {
                bindTo: document
            },
            drd: {
                propertiesPanel: {
                    parent: '#dmn-properties-panel'
                },
                additionalModules: [
                    DmnPropertiesPanelModule,
                    DmnPropertiesProviderModule
                ]
            },
        })

        _dmnModeler.importXML(dmnXML)
            .then((results) => {
                window.my_dmnModeler = _dmnModeler;
                console.debug(' _dmnModeler importXML results: ', results);
            }, (err) => {
                console.error('could not import DMN diagram', err);
            });

        setDmnModeler(_dmnModeler);

        return function cleanup() {
            console.log('ReactDmn module unmount')
            _dmnModeler.destroy();
        }
    }, [dmnXML]);

    useEffect(() => {
        if (props.decisionReferenceId && !dmnXML) {
            workflowManagementService.getDecision({key: props.decisionReferenceId}).then((result) => {
                setIsThisNewDecision(false);
                setDmnXML(result);
            }, (err) => {
                if (err && err.response && err.response.status === 404) {
                    setIsThisNewDecision(true);
                    setDmnXML(initialDmnTemplate(props.decisionReferenceId, idsGenerator));
                }
            });
        } else {
            setIsThisNewDecision(true);
        }
    }, [props.decisionReferenceId])

    return (
        <Grid className={classes.dmnContent}
              onDrop={async evt => {
                  const dt = evt.dataTransfer;
                  const item = dt.items[0];
                  const file = dt.files[0];
                  const fileExtension = file && file.name.split('.').pop() || "";

                  if (item.kind === 'file' && (item && item.type === 'application/dmn' || fileExtension.toLowerCase() == 'dmn')) {
                      evt.preventDefault();
                      evt.stopPropagation();

                      const file = dt.files[0];
                      const text = await file.text();

                      setDmnXML(text);
                  }
              }}
              onDragOver={evt => {
                  const dt = evt.dataTransfer;
                  const item = dt.items[0];

                  if (item.kind === 'file') {
                      evt.preventDefault();
                      evt.stopPropagation();
                  }
              }}
        >
            <Grid className={""} id={"dmn-canvas"} ref={containerRef} style={{width: '100%', height: '100vh',}}/>

            <Grid className={'properties-panel-parent ' + classes['properties-panel-parent']}
                  id="dmn-properties-panel"/>

            <Grid className={classes.actionButtons} container direction="row" alignItems="center">
                <Box pr={1}>
                    <Button onClick={() => {
                        props.onClose && props.onClose();
                    }} color="primary">
                        Close
                    </Button>
                </Box>

                <Box pr={1}>
                    <Button onClick={async () => {
                        const {xml} = await dmnModeler.saveXML({format: true});
                        const xmlDoc = new DOMParser().parseFromString(xml, "text/xml");
                        const dmnId = xmlDoc.getElementsByTagName("decision")[0].getAttribute("id");
                        const blob = new Blob([xml], {type: "text/xml"});
                        const a = document.createElement('a');

                        a.href = URL.createObjectURL(blob);
                        a.download = `${dmnId}.dmn`

                        return new Promise(resolve => {
                            a.click()

                            setTimeout(() => {
                                URL.revokeObjectURL(a.href);
                                resolve();
                            });
                        })

                    }} color="primary">
                        Download
                    </Button>
                </Box>

                <Box p={2}>
                    <Button onClick={async () => {
                        const {xml} = await dmnModeler.saveXML({format: true});
                        console.debug('On save dmn:', xml);

                        //get proccess name from xml as file name
                        let parser;
                        let xmlDoc;

                        if (window.DOMParser) {
                            parser = new DOMParser();
                            xmlDoc = parser.parseFromString(xml, "text/xml");
                            // Internet Explorer
                        } else {
                            /* global ActiveXObject */
                            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                            xmlDoc.async = false;
                            xmlDoc.loadXML(xml);
                        }

                        const dmnId = xmlDoc.getElementsByTagName("decision")[0].getAttribute("id");

                        //create flow
                        if (isThisNewDecision === true) {
                            const fileName = dmnId ? dmnId + '.dmn' : "newDecision.dmn";
                            const blob = new Blob([xml], {type: "text/xml"});
                            const file = new File([blob], fileName);

                            workflowManagementService.upload([file]).then((createResponse) => {
                                showAlert(`Decision ${dmnId} successfully created`, 'success');
                                props.onSave && props.onSave({xml, dmnId});
                            }, (err) => {
                                console.error('workflowManagementService create error: ', err);
                                showAlert(err, 'error');
                            });

                            //update flow
                        } else {
                            workflowManagementService.updateDecision({
                                key: props.decisionReferenceId,
                                xmlContent: xml
                            }).then(() => {
                                showAlert(`Decision ${props.decisionReferenceId} updated successfully`, 'success');
                                props.onSave && props.onSave({xml, dmnId});
                            }, (err) => {
                                console.error('workflowManagementService update error: ', err);
                                showAlert(err, 'error');
                            });
                        }

                    }} variant="contained" color="primary" autoFocus>
                        Save Changes
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
