import sancusElementTemplates from './element_templates'

console.log("sancusElementTemplates",sancusElementTemplates)

export default class SancusTemplatesRegistrar{
    constructor(elementTemplates) {
        console.log("Contructor of SancusTemplatesRegistrar",elementTemplates)
        elementTemplates.set(sancusElementTemplates)
    }
}

SancusTemplatesRegistrar.$inject = [
    'elementTemplates'
]