import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import {FieldValidationCallbacks, GenericFieldParams, htmlType, substitutions} from "./generic_component_base";
import {RepeatableCheck} from "./FormContainerBase";
import {RepeatableParams} from "./RepeatableBase";
import {useFormContext, Controller} from "react-hook-form";
import formValidationUtil from '../utils/formValidation.util';
import {GenericFieldParamsBase} from "./GenericFieldComponentSimpleBase";
import {useIntl} from "react-intl";
import LayoutContext, {useLayout} from "./LayoutContext";
import {useEffect, useLayoutEffect} from "react";

export default function GenericFieldComponentBase<T>(fv_value: GenericFieldParams & {
    renderers: {
        GenericFieldComponentSimple: React.FunctionComponent<GenericFieldParams>
        Repeatable: React.ComponentType<RepeatableParams>
    }
}) {
    const intl = useIntl();
    // todo pass alertMessage to this?
    const {GenericFieldComponentSimple, Repeatable} = fv_value.renderers

    const methods = useFormContext();
    const { control, errors, clearErrors,formState } = methods;
    const formFieldName = formValidationUtil(intl).fieldValidationKeyFactory(fv_value.id, fv_value.index);
    const fieldError = errors[formFieldName];
    const validationRules = formValidationUtil(intl).extractRules(fv_value as any,intl) as any;


    useLayoutEffect(() => {
        //clear errors for updated conditional field validation rules.
        if (fieldError && fieldError.type) {
            const foundRule = validationRules[fieldError.type] || validationRules?.validate[fieldError.type]
            if (!foundRule) {
                clearErrors(formFieldName);
                console.debug(`cleared error for conditional field: ${formFieldName}`);
            }
        }
    }, [fieldError, validationRules, clearErrors, formFieldName])


    const renderSimple = () => {
        const fieldIdx = fv_value.formReference.findIndex(field => field.id === fv_value.id);


        const formHasErrors = Object.keys(errors).length > 0;
        const isFirstField = fieldIdx === 0;

        const isFirstError = formHasErrors && Object.keys(errors)[0] === formFieldName;

        const focused = !formState.isDirty && ((!formHasErrors && isFirstField) || isFirstError);

        const type = fv_value.properties?.type || htmlType(fv_value.typeName)
        const value = fv_value._value ?? fv_value.value?.value;
        const initValue = value ?? (type == "checkbox" ? false : "")

        const isGroupField = fv_value.properties?.group_field;
        const isGroupRepeatable = fv_value.properties?.group_repeatable;

        const parentPath = (isGroupField || isGroupRepeatable) && fv_value.path;
        const controllerName = formValidationUtil(intl).fieldValidationKeyFactory(fv_value.id, fv_value.index,parentPath);
        return (
            <Controller name={controllerName}
                        defaultValue={initValue}
                        rules={validationRules}
                        control={control}

                        render={({onChange, onBlur, value}) => {
                            const fieldValidationCallbacks: FieldValidationCallbacks = {
                                onBlur, onChange
                            };
                            return (
                                <GenericFieldComponentSimple key={fv_value.id}
                                                             {...fv_value}
                                                             {...{fieldValidationCallbacks}}

                                                             focused={focused}
                                />
                            );
                        }}
            >
            </Controller>
        );
    }
    const renderRepeatable = (template: GenericFieldParams, repeatable, _value) => {
        return (
            <LayoutContext.Consumer>
                { (layout) => {
                    console.log('layout aba: ', layout);
                    return (
                        <Repeatable
                            id={fv_value.id}
                            key={fv_value.id}
                            name={fv_value.id}
                            template={template}
                            checkMessages={fv_value.checkMessages as RepeatableCheck}
                            config={repeatable}
                            values={_value}
                            contextEvaluator={template.contextEvaluator}
                            fileChangeCbk={fv_value.fileChangeCbk}
                            valueChangeCbk={fv_value.valueChangeCbk}
                            formReference={fv_value.formReference}
                            intl={intl}
                            layout={layout as any}
                        />
                    );
                }}
            </LayoutContext.Consumer>
        );
    }


    let repeatable = fv_value.properties?.repeatable;
    if (repeatable) {
        try {
            repeatable = JSON.parse(repeatable);
        } catch (e) {
        }

        const _value = fv_value._value as any[]
        const template = cloneDeep(fv_value)
        delete template.properties.repeatable;

        return renderRepeatable(template, repeatable, _value);
    } else {
        return renderSimple()
    }

}


