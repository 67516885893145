export interface SearchResults {
    query:{[key:string]:string},
    provider: 'lexis-nexis',
    Records:Record[],
    SearchEngineVersion: string,
    ClientReference: string
}

export interface Record {
    Record: number
    RecordDetails: RecordDetails
    ResultID: number
    RunID: number
    Watchlist: Watchlist
}

export interface RecordDetails {
    AcceptListID: number
    AccountAmount: string
    AccountDate: string
    AccountGroupID: string
    AccountOtherData: string
    AccountProviderID: string
    AccountMemberID: string
    AccountType: string
    AddedToAcceptList: boolean
    DPPA: string
    EFTType: string
    EntityType: string
    Gender: string
    GLB: number
    LastUpdatedDate: string
    Name: Name
    RecordState: RecordState
    SearchDate: string
}

export interface Name {
    First: string
    Full: string
    Generation: string
    Last: string
    Middle: string
    Title: string
}

export interface RecordState {
    AddedToAcceptList: boolean
    AlertState: string
    AssignmentType: string
    MatchStates: MatchState[]
    Status: string
}

export interface MatchState {
    MatchID: number
    Type: string
}

export interface Watchlist {
    Matches: Match[]
    Status: string
}

export interface Match {
    AcceptListID: number
    AddedToAcceptList: boolean
    AddressName: boolean
    AutoFalsePositive: boolean
    BestAddressIsPartial: boolean
    BestCountry: string
    BestCountryScore: number
    BestCountryType: string[]
    BestDOBIsPartial: boolean
    BestName: string
    BestNameScore: number
    CheckSum: number
    Conflicts: Conflicts
    EntityDetails: EntityDetails
    Error:{
        Code: number
        Message: string
    }
    EntityName: string
    EntityScore: number
    EntityUniqueID: string
    FalsePositive: boolean
    File: File
    GatewayOFACScreeningIndicatorMatch: boolean
    ID: number
    MatchReAlert: boolean
    PreviousResultID: number
    ReasonListed: string
    ResultDate: string
    SecondaryOFACScreeningIndicatorMatch: boolean
    TrueMatch: boolean
    FalseMatchUpdate: string[]
    Relationships: Relationship[]
    PEPs: Pep[]
    SourceItems: SourceItem[]
    DateModified: string
    Status: string
}

export interface Conflicts {
    AddressConflict: boolean
    CitizenshipConflict: boolean
    CountryConflict: boolean
    DOBConflict: boolean
    EntityTypeConflict: boolean
    GenderConflict: boolean
    IDConflict: boolean
    PhoneConflict: boolean
}

export interface EntityDetails {
    AdditionalInfo: AdditionalInfo[]
    Addresses: Address[]
    AKAs: Aka[]
    Comments: string
    DateListed: string
    EntityType: string
    Gender: string
    IDs: Id[]
    ListReferenceNumber: string
    Name: Name3
}

export interface AdditionalInfo {
    ID: number
    Type: string
    Value: string
}

export interface Address {
    City?: string
    Country: string
    ID: number
    PostalCode?: string
    StateProvinceDistrict?: string
    Type: string
    Street1?: string
    Street2?: string
}

export interface Aka {
    Category: string
    ID: number
    Name: Name2
    Type: string
}

export interface Name2 {
    Full: string
    First?: string
    Last?: string
}

export interface Id {
    ID: number
    Number: string
    Type: string
    Issuer?: string
    Label?: string
}

export interface Name3 {
    First: string
    Full: string
    Last: string
    Middle?: string
}

export interface File {
    Build: string
    Custom: boolean
    ID: number
    Name: string
    Published: string
    Type: string
}

export interface Relationship {
    Group: string
    Type: string
    EntityId: number
    DateModified: string
    EntityName: string
    OwnershipPercentage: number
    Segments: string
}

export interface Pep {
    Country: string
    SubCategories: string[]
    AdminLevel: string
    DateModified: string
    Status: string
    IsPrimary: boolean
    CountryRole: string
    GoverningRole: string
    EffectiveDateType: string
    EffectiveDate: string
    ExpirationDateType: string
    ExpirationDate?: string
    GoverningInstitution?: string
    AdminUnit1?: string
    AdminUnit2?: string
}

export interface SourceItem {
    SourceURI: string
    DateModified: string
}


export function getWLMatches(value:SearchResults){
    const {Records} = value;
    return Records && Records.flatMap(r=>{
        const {RecordDetails,Watchlist} = r;
        return Watchlist && Watchlist.Matches || [];
    })
}

export function getQN(query){
    return query && (query.fullName || query.firstName && (query.firstName+ ' '+query.lastName) || query.orgName)
}