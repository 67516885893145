export default (definitionId, idsGenerator)=>`<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" id="decision_${idsGenerator.next()}" name="definitions" namespace="http://camunda.org/schema/1.0/dmn">
  <decision id="${definitionId ? definitionId : `definitions_${idsGenerator.next()}`}" name="">
    <decisionTable id="decisionTable_${idsGenerator.next()}">
      <input id="input1" label="">
        <inputExpression id="inputExpression1" typeRef="string">
          <text></text>
        </inputExpression>
      </input>
      <output id="output1" label="" name="" typeRef="string" />
    </decisionTable>
  </decision>
</definitions>`;
