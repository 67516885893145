import React, {useContext, useEffect, useRef} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles/index';
import * as _ from 'lodash';
import Typography from '@material-ui/core/Typography/index';
import Grid from "@material-ui/core/Grid";
import {isElementIntoView, scrollToElement} from "../../utils";
import {LoggedUserContext} from "../../context/loggedUser/loggedUserContext";
import Avatar from "@material-ui/core/Avatar";

import MenuItem from "@material-ui/core/MenuItem";
import {tasksUrl} from "../../variables";
import {UsersContext} from "../../context/users/usersContext";
import {AlertContext} from "../../context/alert/alertContext";
import {GroupsContext} from "../../context/groups/GroupsContext";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {Box, Chip, FormControl, InputLabel, OutlinedInput, Select, TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        borderLeft: '4px solid transparent',
        boxShadow: '0 1px 1px 0 #e6e6e6',
        padding: 16,
        minHeight: 150,
        flex: "1 1 auto",
        cursor: "pointer",
        "&:hover": {
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.24)',
            backgroundColor: 'white',
            borderColor: theme.palette.primary.main
        }
    },
    activeCardContainer: {
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.24)',
        borderLeft: '3px solid ' + theme.palette.primary.main,
        backgroundColor: 'white',
    },
    taskTitle: {
        fontWeight: theme.palette.text.fontWeightBold,
    },
    assigneeTitle: {
        color: theme.palette.text.secondary
    },
    assigneeSubject: {
        color: theme.palette.text.secondary
    },
    createdAtTitle: {
        color: '#aaaaaa'
    },
    small_avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}));

export default function TaskCard({taskItem, isActive, onTaskClick, onTaskAssignCbk}) {
    const classes = useStyles();
    const taskChangeUrl = `${tasksUrl}/${taskItem.task.id}`;
    const {LoggedUserState} = useContext(LoggedUserContext);
    const {UsersState} = useContext(UsersContext);
    const {GroupsState} = useContext(GroupsContext);
    const {showAlert} = useContext(AlertContext);

    const cardRef = useRef();

    useEffect(() => {
        if (isActive) {
            if (!isElementIntoView(cardRef.current)) {
                scrollToElement(cardRef.current);
            }
        }
    }, [isActive]);


    const handleAssignUser = (event) => {
        event.stopPropagation();
        const userId = event.target.value;

        return axios({
            method: 'post',
            url: taskChangeUrl,
            params: {
                assign: userId,
            }
        }).then(response => {
                onTaskAssignCbk();
            },
            error => showAlert(error, 'error')
        );

    };

    const makeAssignGroupsAPICall = (groupIds) => {
        return axios({
            method: 'post',
            url: taskChangeUrl,
            params: {
                assignGroupIds: "",
            },
            data: groupIds,
        })
        .then(response => {
                onTaskAssignCbk();
            },
            error => showAlert(error, 'error')
        );
    };

    const canUserAssignGroup = LoggedUserState.user.roles && (LoggedUserState.user.roles.indexOf('ADMIN') > -1 || LoggedUserState.user.roles.indexOf('MANAGER') > -1);

    let assigneeJsx = null;
    let groupJsx = null;
    let createdAtJsx = null;

    const assignedUser = taskItem.assignee || null;
    const assignedGroups = taskItem.candidateGroups;
    const task = taskItem.task;

    const [selectedGroups, setSelectedGroups] = React.useState(assignedGroups);

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const groupId = event.target.value;

        setSelectedGroups(prevGroups => {
            if (checked) {
                const addedGroup = GroupsState.find(g => g.id === groupId);

                return [...prevGroups, addedGroup]
            } else {
                return prevGroups.filter(g => g.id !== groupId)
            }
        });
    };

    const handleCloseAssignGroupMenu = (event) => {
        event.stopPropagation();
        // after close submenu we compare  assignedGroups with selectedGroups and make API call if changes exists

        if (selectedGroups.length !== assignedGroups.length) {
            return makeAssignGroupsAPICall(_.map(selectedGroups, g => g.id));
        }

        let wasAssignedBefore = true;

        _.each(selectedGroups, (sg => {
            wasAssignedBefore = _.find(assignedGroups, g => g.id === sg.id);
            if (!wasAssignedBefore) {
                return makeAssignGroupsAPICall(_.map(selectedGroups, g => g.id));
            }
        }));
    };

    let candidatesForAssign = [];

    if (assignedGroups.length) {
        candidatesForAssign = assignedGroups.reduce((result, assignedGroup) => {
            if (assignedGroup && UsersState[assignedGroup.id]) {
                if (LoggedUserState.user.roles.indexOf('ADMIN') > -1 || LoggedUserState.user.groupIds.indexOf(assignedGroup.id) > -1) {
                    return result.concat(UsersState[assignedGroup.id]) || [];
                }
            }

            return result;
        }, []);
        candidatesForAssign = _.uniqBy(candidatesForAssign,r=>r.id)
    } else {
        candidatesForAssign = UsersState['all'] || [];
    }

    if (isActive) {
        assigneeJsx = (
            <FormControl fullWidth>
                <InputLabel id="assignee-label">Assignee</InputLabel>
                <Select
                    labelId="assignee-label"
                    id="assignee-select"
                    value={assignedUser?.id}
                    label="Assignee"
                    onChange={handleAssignUser}
                    // input={<OutlinedInput id="assignee-input" label="assignee-label" />}
                >
                    <MenuItem value={undefined} disabled={true}>Unassigned</MenuItem>
                    {candidatesForAssign.map((user, index) => (
                        <MenuItem value={user.id} key={user.firstName+' '+user.lastName}>{user.firstName} {user.lastName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );

        groupJsx = (
            <FormControl>
                <InputLabel id="group-label">Group</InputLabel>
                {canUserAssignGroup?
                <Select
                    labelId="group-label"
                    id="group-select"
                    value={selectedGroups}
                    renderValue={(selected)=>{
                        // return
                        // return selected.map(g => g?.name).join(', ')
                        return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            <>{selected.map(value =><Chip key={value} label={value.name} />)}</>
                        </Box>
                    }}
                    label="Group"
                    multiple
                    // input={<OutlinedInput id="group-input" label="group-label" />}
                    onClose={handleCloseAssignGroupMenu}
                >
                    <MenuItem value={undefined} disabled={true}>Unassigned</MenuItem>
                    {GroupsState.map((group, index) => (
                        <MenuItem value={group} key={group.id}>
                            <Checkbox checked={selectedGroups.map(g => g?.name).indexOf(group.name) > -1}
                                      value={group.id} onClick={handleCheckboxChange}/>
                            <ListItemText primary={group.name}/>{}
                        </MenuItem>
                    ))}
                </Select>:
                    <TextField disabled={true} value={assignedGroups.map(g => g?.name).join(', ')}></TextField>}
            </FormControl>
            /*<Grid style={{flex: "1 1 auto"}}>
                <Typography className={classes.assigneeTitle} variant="overline">
                    Group
                </Typography>

                <Grid container direction="row" justifyContent="flex-start" alignItems="center" onClick={e=>{
                    if(canUserAssignGroup){
                        handleOpenAssignGroupMenu(e)
                    }
                }}>

                    <Grid item xs>
                        {assignedGroups.length ? (
                            <Typography variant="overline">
                                {assignedGroups.map(g => g?.name).join(', ')}
                            </Typography>
                        ) : (
                            <Typography variant="caption">
                                Unassigned
                            </Typography>
                        )}
                    </Grid>

                    {canUserAssignGroup ?
                        <ArrowDropDownIcon fontSize="large"/> : null}

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElOfAssignGroup}
                        open={Boolean(anchorElOfAssignGroup)}
                        onClose={handleCloseAssignGroupMenu}>

                        {GroupsState.map((group, index) => (
                            <MenuItem key={group.id} value={group}>
                                <Checkbox checked={selectedGroups.map(g => g?.name).indexOf(group.name) > -1}
                                          value={group.id} onChange={handleCheckboxChange}/>
                                <ListItemText primary={group.name}/>{}
                            </MenuItem>
                        ))}

                    </Menu>
                </Grid>
            </Grid>*/)

    } else {
        if (assignedUser) {
            assigneeJsx = (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{flex: "1 1 auto"}}>
                    <Avatar src={assignedUser.imageURL} className={classes.small_avatar}/>
                    <Typography className={classes.assigneeTitle} variant="overline">
                        {(assignedUser.firstName || '')} {(assignedUser.lastName || '')}
                    </Typography>
                </Grid>
            );

        } else {
            assigneeJsx =
                (<Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{flex: "1 1 auto"}}>
                    <Typography className={classes.assigneeTitle} variant="overline">
                        Unassigned
                    </Typography>
                </Grid>);

        }

        createdAtJsx = <Typography className={classes.createdAtTitle} variant="caption">
            {task.relativeTime}
        </Typography>
    }

    return (
        <Grid ref={cardRef} className={classes.cardContainer + (isActive ? ` ${classes.activeCardContainer}` : '')}
              onClick={() => onTaskClick && onTaskClick(taskItem)} container direction="column" justifyContent="flex-start"
              alignItems="stretch">

            {createdAtJsx}

            <Grid item xs style={{flex: "1 1 auto"}}>
                <Typography variant="h6" className={classes.taskTitle}>
                    {task.name}
                </Typography>
            </Grid>

            {assigneeJsx}
            {groupJsx}

        </Grid>
    );
}
