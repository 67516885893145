import * as React from "react";
import {RefinitivResponse} from 'sancus-client-common/dist/utils/refinitiv.interface'
import {Box, Divider, Grid, Link, Modal} from "@material-ui/core";

import {useStyles, modalStylesF} from "./pepComponent";
import {useState} from "react";

function RefinitivResultContainer(props: { results: RefinitivResponse['results'] }) {
    const {results} = props
    return <Grid item container direction={"row"} style={{justifyContent: 'space-between'}}>
        <ol>
            {results.map(result => <Grid item>
                <li><RefinitivResult result={result} key={result.resultId}/></li>
            </Grid>)}
        </ol>
    </Grid>
}

function RefinitivResult(props: { result: RefinitivResponse['results'][number] }) {
    const result = props.result
    return <Box>
        <Grid container direction={"column"}>
            <Grid item><b>{result.primaryName}</b>({result.matchScore}%)</Grid>
            <Grid item>{result.category} ({result.matchedNameType}: {result.matchedTerm})</Grid>
            <Divider/>
            <Grid item> Appears in the following categories:
                <ul> {[...new Set(result.categories)].map(cat => <li><b>{cat}</b></li>)}</ul>
            </Grid>
            <Divider/>
            <Grid item>Events:
                <ul>
                    {result.events.map(event =>
                        <li>{event.fullDate}: {event.type}</li>
                    )}
                </ul>
            </Grid>
            <Divider/>
            <Grid item> Countries:
                <ul>
                    {Object.entries(result.countryLinks.reduce<{[key:string]:string[]}>((acc,cl) => {
                        if (acc[cl.type]){
                            acc[cl.type].push(cl.countryText)
                        }else{
                            acc[cl.type] = [cl.countryText];
                        }
                        return acc;

                    },{})).map(([type,countries])=>{
                        return <li>{type}: {countries.join(", ")} </li>
                    })}
                </ul>
            </Grid>
        </Grid>
    </Box>
}

export function RefinitivComponent(props: { response: RefinitivResponse }) {
    const refinitivResponse = props.response

    const modalStyles = modalStylesF();
    const [modalBody, setModalBody] = useState<JSX.Element | null>()
    const handleClose = (event) => {
        setModalBody(null)
    }
    const classes = useStyles();
    const className = refinitivResponse.results!=null? refinitivResponse.results.length > 0 ? classes.yellow : classes.green :classes.red

    const handleOpen = (evt) => {
        setModalBody(
            <div className={modalStyles.dialog}>
                <RefinitivResultContainer results={refinitivResponse.results}/>
            </div>)
    }


    return <><Box mt={1} mb={1} key={"pep:" + refinitivResponse.caseId}>
        <Grid container direction={"row"} style={{justifyContent: 'space-between'}}>
            <Grid item className={className}>
                {refinitivResponse.results==null?
                    <>
                        Error invoking Refinitiv PEP Screen
                    </>:<>
                        Search for <Link onClick={evt => handleOpen(evt)} variant="body2"
                                         style={{color: 'black'}}><b>{refinitivResponse.name}</b></Link> returned {refinitivResponse.results.length} results
                    </>
                }

            </Grid>
        </Grid>
    </Box>
        <Modal open={!!modalBody} onClose={handleClose} style={{ overflow: 'scroll' }}>{modalBody!}</Modal>
    </>

}