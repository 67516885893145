import { handleActions } from 'redux-actions';
import {SectionActions} from "../actions/section.actions";

export const sectionReducer = handleActions({
    [SectionActions.Type.ACTIVE_SECTION]: (state,action)=>{
        const {sectionName,node} = action.payload;
        if (!state.activeSections){
            state.activeSections = {}
        }
        state.activeSections[sectionName] = node
        return {...state}
    },

    [SectionActions.Type.SCROLL_INTO_SECTION]: (state,action)=>{
        state.scrollIntoSection = action.payload.sectionName
        return {...state}
    }
},{activeSections:{}})
