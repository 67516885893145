import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useNavigate, useLocation} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useAlert} from "../context/alert/alertContext";
import {useForgotPasswordService} from "../context/users/forgotPassword.service";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonTypo: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));

const ResetPassword = () => {
    const classes = useStyles();
    const history = useNavigate();
    const {showAlert} = useAlert();
    const service = useForgotPasswordService();
    const {state} = useLocation();

    const {email,flow,passwordPolicy} = state ?? {};

    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [retry, setRetry] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newPassword) {
            return setPasswordError("Password can't be empty!");
        }

        if (newPassword !== repeatPassword) {
            return setRepeatPasswordError('Repeat password does not match');
        }

        try {
            const otp = (state && state.otp) || '';
            const result = await service.resetPassword({otp, newPassword});
            if (result) {
                showAlert('Password updated successfully.', 'success');
                history('/');
            } else {
                showAlert('Could not update password.', 'error');
                setRetry(true);
            }
        } catch (e) {
            if (e.response && e.response.status === 401 && e.response.data) {
                const {code, message} = e.response.data;
                if (code === 'otp_expired') {
                    setRepeatPasswordError(message);
                    setRetry(true);
                } else {
                    setRepeatPasswordError(message);
                    setRetry(false);
                }
            } else if (e.response && e.response.status === 400 && e.response.data.code === 'password_policy') {
                const {code,message,rules} = e.response.data;
                const rulesElem = <ul>{Object.keys(rules).map(r=>{
                    const found = rules[r];
                    if (found){
                        return <li><b>{r}</b></li>
                    }else{
                        return <li>{r}</li>
                    }
                })}</ul>
                const _message = <>
                    {message}
                    {rulesElem}
                    </>
                setRepeatPasswordError(_message);
                setRetry(false);

            }else{
                setRepeatPasswordError(e.message);
                setRetry(false);
            }


        }
    };

    const handleRetryFlow = (evt) => {
        evt.preventDefault();
        history('/forgotPassword', {state:{flow}});
    }

    return (
        <Container component="div" maxWidth="xs">
            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'} height={60}></img>
                <Box p={2}>
                <Typography variant="h5">
                    {flow && flow === "activateAccount" ? "Set password" : "Reset password"}
                </Typography>
                </Box>
                <Typography variant="subtitle2">
                    {flow && flow === "activateAccount" ? "Enter a password." : "Enter a new password."}
                </Typography>
                <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        error={!!passwordError}
                        value={newPassword}
                        variant="outlined"
                        margin="normal"
                        id="password"
                        name="password"
                        label="Password"
                        autoFocus
                        fullWidth
                        required
                        disabled={retry}
                        type="password"
                        helperText={passwordError}
                        onChange={(evt) => {
                            setNewPassword(evt.target.value);
                        }}
                    />
                    <TextField
                        error={!!repeatPasswordError}
                        value={repeatPassword}
                        variant="outlined"
                        margin="normal"
                        id="password_repeat"
                        name="password_repeat"
                        label="Password (repeat)"
                        fullWidth
                        required
                        disabled={retry}
                        type="password"
                        helperText={repeatPasswordError }
                        onChange={(evt) => {
                            setRepeatPassword(evt.target.value);
                        }}
                    />

                    <Grid item container justifyContent="space-between" spacing={1}>
                        <Grid item xs>
                        <Button fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleRetryFlow}
                        >Back</Button>
                        </Grid>

                        <Grid item xs>
                        <Button fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={retry}
                        >
                            Submit
                        </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default ResetPassword;
