import {Autocomplete} from "@material-ui/lab";
import {Box, Button, Chip, FormControl, Grid, InputAdornment, Modal, TextField} from "@material-ui/core";
import {CloudUpload} from "@material-ui/icons";
import * as React from "react";
import {File, FileProps, helperTextFromValidationAndAnnotation} from "./generic_component";
import {useRef} from "react";
import formValidationUtil from "sancus-client-common/dist/utils/formValidation.util";
import {useFormContext} from "react-hook-form";
import {useIntl} from "react-intl";

const CustomFileAutocomplete = (props:FileProps)=>{
    const intl = useIntl();

    const {id,maxFiles,required,label,files,readOnly,ref,elemRef,fileNameFromValue,handleDeleteFile,handleOpen,fieldValidationCallbacks,_triggerParentOnChange} = props;

    const inputElemRef = useRef<HTMLInputElement | null>(null);
    const value:File[] = Array.isArray(files) && (files.length > 0 ? files : null) || [];

    
    const supportsMultiple:boolean | undefined = maxFiles > 1;

    if (!id){
        throw "cannot render controlled object without id";
    }
    const {errors} = useFormContext();

    const hasOwnErrors = formValidationUtil(intl).hasFieldError(id, errors)
    const ownErrors = formValidationUtil(intl).getFieldErrorMessage(id, errors)
    const helperText = helperTextFromValidationAndAnnotation(ownErrors,props)

    return <FormControl id={id} ref={elemRef} margin={"normal"}>
        <Autocomplete
            disabled={readOnly}
            disableClearable
            onChange={evt=>{}}
            onInputCapture={evt=>{}}
            options={value}
            multiple
            renderTags={(tagValue, getTagProps) => {
                return <Grid xs={11}> <Box flex flexWrap={"wrap"} flexDirection={"row"} >
                    {tagValue.map((option, index) => {
                        const tagProps = getTagProps({index})
                        const fileName = fileNameFromValue(option)
                        return <Chip {...tagProps}
                                     label={fileName}

                                     onDelete={async evt=>{
                                         await handleDeleteFile(option,index);
                                     }}
                                     onClick={async event => {
                                         event.preventDefault();
                                         event.stopPropagation();
                                         await handleOpen(event,option as File, false);

                                     }}
                        />
                    })}
                </Box></Grid>
            }}

            value={value}

            getOptionLabel={option=>{
                if  (!option) {
                    return '';
                }else if (typeof option === 'string') {
                    return option;
                }else if (Array.isArray(option)) {
                    return option.map(o => o.name).join(',') ?? '';
                }else if (option.name) {
                    return option.name;
                }else {
                    return 'dnkn';
                }
            }}

            renderOption={(option,state)=>{
                return <div>Option</div>
            }}

            getOptionSelected={(option, value)=>{
                return true;
            }}

            getOptionDisabled={(option)=>{
                return true;
            }}

            renderInput={
                params=>{
                    const _onChange = params.inputProps['onChange'];
                    const _endAdornment = params.InputProps.endAdornment;
                    const _startAdornment = params.InputProps.startAdornment;
                    return <><TextField {...params}
                                        required={required}
                                        disabled={readOnly}
                                        minRows={1}
                                        error={hasOwnErrors}
                                        helperText={helperText}
                                        multiline={true}
                                        margin={"normal"}
                                        variant={"outlined"}
                                        label={label}
                                        InputLabelProps={{...params.InputLabelProps, shrink:true}}
                                        InputProps={{
                                            startAdornment: _startAdornment,
                                            endAdornment:<InputAdornment
                                                position={"start"}>
                                                <Button
                                                    onClick={evt=>{
                                                        if (! readOnly && value.length < maxFiles) {
                                                            inputElemRef.current?.click()
                                                        }
                                                    }}
                                                    style={{color:hasOwnErrors?'red':undefined}}
                                                    disabled={readOnly || value.length >=maxFiles}
                                                    className={[hasOwnErrors ? 'Mui-error':'',(readOnly || value.length >=maxFiles)? 'Mui-disabled':''].join(' ')}
                                                ><CloudUpload/></Button>

                                            </InputAdornment>
                                        }}

                                        onBlur={evt=>{
                                            fieldValidationCallbacks.onBlur(evt);
                                        }}
                    />
                        <input
                            ref={inputElemRef}
                            style={{display:"none"}}
                            type="file"
                            multiple={supportsMultiple}

                            onChange={async (evt)=>{
                                const files = evt.target.files;
                                const newFiles = [...value]
                                if (files){
                                    for (let i=0;i<files.length;i++){
                                        const file = files.item(i);
                                        newFiles.push(file as unknown as File);
                                    }
                                }
                                inputElemRef.current!.value = '';
                                await _triggerParentOnChange(newFiles)
                            }}
                        />
                    </>


                }}
        />
    </FormControl>
}

export default CustomFileAutocomplete;
