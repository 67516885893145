export default function URLParams():{[key:string]:string | null}{
    const l_search_params:{[key:string]:string | null} = {}
    if (window.location.search.length >0) {
        window.location.search.substring(1).split("&").forEach((token) => {
            const idx = token.indexOf('=')
            const key = decodeURIComponent(idx != -1 ? token.substring(0, idx) : token);
            const value = idx != -1 ? decodeURIComponent(token.substring(idx + 1)) : null;
            l_search_params[key] = value;
        }, {})
    }
    return l_search_params;
}


export function hashCode(str){
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const character = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}