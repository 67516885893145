import * as React from "react";
import {
    LegacyRef,
    MutableRefObject,
    Ref,
    SyntheticEvent,
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import * as _ from "lodash";
import {makeStyles, styled} from "@material-ui/core/styles";
import {
    Box,
    FormHelperText,
    Grid,
    InputAdornment,
    Link,
    Modal,
    TextField,
    TextFieldProps,
    Tooltip, useTheme
} from "@material-ui/core";
import contextPath from "sancus-client-common/dist/common/contextPath";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from '@material-ui/icons/GetApp';
import {getStore} from "sancus-client-common/dist/store";
import IconButton from "@material-ui/core/IconButton";
import LayoutContext from "sancus-client-common/dist/common/LayoutContext";
import {useIntl} from "react-intl";


import {useFormContext} from "react-hook-form";

import {getNotificationTemplate} from "./generic_component/GenericFieldComponentSimple";
import {AnnotationContext} from "sancus-client-common/dist/common/GenericFieldComponentSimpleBase";
import CustomFileAutocompleteComponent from "./customFile.Autocomplete.component";
import CustomFileSimpleButtonComponent from "./customFile.simpleButton.component";
import {IndexDecoratorFormValidationUtilFactory} from "sancus-client-common/dist/utils/formValidation.util";
import {FieldValidationCallbacks} from "sancus-client-common/dist/common/generic_component_base";


export type FileProps<ET=HTMLElement> = {
    id:string,
    maxFiles:number
    required:CustomFileFieldProps['required'],
    label:CustomFileFieldProps['label'],
    files:File[] | undefined,
    readOnly:boolean,
    ref?:any //MutableRefObject<HTMLInputElement | null>,
    elemRef?:any //MutableRefObject<ET | null>,
    fileNameFromValue: any,
    handleOpen:HandleOpenSignature,
    handleDeleteFile:HandleDeleteSignature,
    fieldValidationCallbacks:FieldValidationCallbacks,
    _triggerParentOnChange: (newFiles: File[]) => Promise<void>,
    fv_value: any
}


const useReadOnlyStyles = makeStyles((theme) => ({
    readOnlyLabel: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

class SancusAnchor extends HTMLAnchorElement{
    private fetched = false;
    constructor() {
        super();

    }



    connectedCallback () {
        this.addEventListener("click", e => {
            if (this.fetched ){

            }else {
                e.preventDefault();

                const name = this.dataset['name'] || this.innerText;
                const data = this.dataset['href']!;
                const mimeType = this.dataset['mimetype']
                const size = this.dataset['size'] && parseInt(this.dataset['size'])

                createFileDataURL({name, mimeType, data}).then(rsp => {
                    this.fetched = true;
                    if (rsp) {
                        this.download = name!;
                        this.href = rsp;
                        this.click();
                    }
                })
            }


        });

    }
    disconnectedCallback () {

    }
}
customElements.define("sancus-anchor",SancusAnchor,{extends: "a"})


const ReadMore = ({text,size=500})=>{
    const [showAll,setShowAll] = useState(false);
    const toggle = evt=>{
        evt.preventDefault();

        setShowAll(!showAll);
    }
    if (text && text.length >size){
        if (showAll){
            return <>
                {text}
                <a onClick={toggle}>Read less</a>
            </>
        }else {
            return <>
                {text.substring(0, size) + '...'}
                <a onClick={toggle}>Read More</a>
            </>
        }
    }else{
        return <>{text}</>;
    }
}

export function ReadonlyTextField({fv_value,_value,getNotificationTemplate,readOnlyClasses}){
    if (typeof _value === 'object'){
        _value= JSON.stringify(_value);
    }
    return <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}}>
        <Grid item xs={5} key={"label"}>
            <Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`} itemID={fv_value.id+'-typo-label'}>{fv_value.label}:</Typography>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6} key={"value"}>
            <Grid item xs key={"text"}>
                <Typography variant="body2" id={fv_value.id}><ReadMore text={_value}/></Typography>
            </Grid>
            <Box ml={2} key={"notifications"}>
                <Grid item>{getNotificationTemplate(fv_value)}</Grid>
            </Box>
        </Grid>
    </Grid>
}

export const FacingTextField = ReadonlyTextField;

export function ReadonlyDate({fv_value,valueAsCorrectString,getNotificationTemplate,readOnlyClasses}){
    return  <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}}>
        <Grid item xs={5}><Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`}>{fv_value.label}:</Typography></Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>
            <Grid item xs><Typography variant="body2" id={fv_value.id}>{valueAsCorrectString ?? ""} </Typography></Grid>
            <Box ml={2}><Grid item>{getNotificationTemplate(fv_value)}</Grid></Box>
        </Grid>
    </Grid>
}

export const FacingDate = ReadonlyDate

class NullHolder{
    private constructor() {}
    postProcessGet(){
        return null;
    }
    toString(){
        return '';
    }

    static INSTANCE = new NullHolder();

}

function inputComponentForType (type:string):undefined | Partial<{
    placeholder:string,
    prefix:string,
    suffix:string,
    inputComponent:React.ComponentType<TextFieldProps>
}>{
    if (!type){
        return undefined;
    }
    if (type.indexOf("percentage") > -1){
        const hasArgs = type.indexOf('(') > "percentage".length;
        if (hasArgs){
            const begin = type.indexOf('(');
            const end = type.indexOf(')');
            const argString = type.substring(begin+1,end).trim();
            const args = argString.split(',');
            let [format] = args;

            format = format || '##.##'
            return {placeholder:format,suffix:'%'}
        }else{
            return {placeholder:'##.##',suffix:'%'}
        }

    }
}

export function CustomTextField(props) {

    const helperText = props.helperText;
    const type = props.type;
    const {placeholder,prefix,suffix,inputComponent} = inputComponentForType(type) || {};
    const startAdornment = prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : undefined;
    const endAdornment = suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : undefined;


    const InputProps = {
        disableUnderline: true
    }
    if (startAdornment){
        InputProps['startAdornment'] = startAdornment;
    }
    if (endAdornment){
        InputProps['endAdornment'] = endAdornment;
    }

    const passTroughProps = _.omit(props,'errorText')

    if (!helperText) {
        return <>
            <TextField {...passTroughProps}
                      helperText={null}
                      value={props.value || ''}
                      InputProps={InputProps}
                      InputLabelProps={{style: {pointerEvents: "auto"}}}/>
            {props.errorText != null ? <FormHelperText error={true}>
                {props.errorText}
            </FormHelperText> : null
            }
            </>;
    }else{
        return <>

            <TextField {...passTroughProps}
                        helperText={null}
                        placeholder={placeholder}
                        value={props.value || ''}
                        InputProps={InputProps}
                        InputLabelProps={{style: {pointerEvents: "auto"}}}/>
            <FormHelperText >
                {typeof helperText == 'string' ?
                <span dangerouslySetInnerHTML={{__html:helperText}}/> : helperText}
            </FormHelperText>
            {props.errorText != null ? <FormHelperText error={true}>
                {props.errorText}
            </FormHelperText> : null}
        </>;
    }
}

const useCustomFileField = makeStyles((theme) => ({
    dropzone: {
        border: '1px dashed #e6e6e6',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fff',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        minHeight: '160px',
        '&:hover': {
            backgroundColor: '#fff',
            boxShadow: `rgba(0, 0, 0, 0.1) 0px 2px 4px 0px`,
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `0 2px 4px 0 rgba(0,0,0,0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)`,
            borderColor: theme.palette.primary.main,
        },
        '& [class*="MuiFilledInput-input"]': {
            padding: `27px 12px 16px`,
            opacity: 0
        },
        '& [class*="MuiDropzoneArea-icon"]': {
            color: 'rgba(0, 0, 0, 0.2)'
        }
    },
    dropzoneparagraph: {
        // fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 400
    }
}));

export type File = {
    name:string,
    mimeType?:string,
    size?: number
    data:(string | (()=>Promise<string | File>))
}

export async function createFileDataURL(file:File):Promise<string | null>{
    let {name,mimeType,data} = file;
    let dataUrl;
    if (typeof data === 'function') {
        const dataBase64:(string | File) = await data();
        if (typeof dataBase64 === 'string') {
            file.data = dataBase64;
        }else {
            file.data  =dataBase64.data;
        }
        if (mimeType) {
            dataUrl = `data:${mimeType};base64,${dataBase64}`
        } else {
            dataUrl = dataBase64;
        }
    }else if (data == null){
        //Unfortunate case where we got error during fetch
        console.error("Error During Fetch, data is null")
        dataUrl = null;
    }else if (data.indexOf('file:') == 0) {
        // const url = data.replace('file:', contextPath + 'api/files/')
        const filePath = data.substring("file:".length);
        const url = contextPath + 'api/files?filePath=' + encodeURIComponent(filePath)+'&encode_as=text';
        const rsp = await fetch(url);
        const txt = await rsp.text();
        dataUrl = `data:${mimeType};base64,${txt}`
    }else if  (data.indexOf('http:') == 0 || data.indexOf('https:') == 0 ) {
        const url = data;
        const rsp = await fetch(url);
        //FIXME: This is most probably wrong. Http urls should give back the raw file and not the base64 representation
        const txt = await rsp.text();
        dataUrl = `data:${mimeType};base64,${txt}`
    }else if (data.indexOf('/') == 0){
        //Could be base64
        try{
            const a  = atob(data);
            dataUrl = `data:${mimeType};base64,${data}`
        }catch (e){
            //could be relative URL
            const url = data;
            const rsp = await fetch(url);
            //FIXME: This is most probably wrong. Http urls should give back the raw file and not the base64 representation
            const txt = await rsp.text();
            dataUrl = `data:${mimeType};base64,${txt}`
        }
    }else if  (data.indexOf('data:') == 0) {
        dataUrl = data;
    } else {
        //data is base64
        dataUrl = `data:${mimeType};base64,${data}`
    }
    return dataUrl;
}

const modalStyles = makeStyles(() => ({
    fileModal: {
        position: 'fixed',
        top: '50vh',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
    },
}));


export type CustomFileFieldProps = {
    id: string;
    label: string;
    filesValue?: File[];
    filesLimit?: string;
    required: boolean;
    readOnly: boolean;
    onChange: (event: any) => void;
    ref?: Ref<HTMLInputElement> | null;
    tooltip?: string;
    helperText?: string;
    scope?: string;
    fieldValidationCallbacks?: any;
    index?: number;
    path?: string;
    fileComponent?: 'simpleButton' | 'autocomplete' | 'dropzone',
    focused?: boolean;
    elemRef: MutableRefObject<HTMLElement | null>;
}

export type HandleOpenSignature = (event:SyntheticEvent<HTMLElement> ,file: string | File | File[],useURL?:boolean) =>Promise<boolean | string>;
export type HandleDeleteSignature = (file: string | File, index: number) => Promise<void>;


export function CustomFileField(props:CustomFileFieldProps) {
    const intl = useIntl();
    const parentPath = props.path && props.path.lastIndexOf('.') > -1 ? props.path!.substring(0, props.path!.lastIndexOf('.')) : undefined;
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.index,parentPath,intl);
    const controlName = formValidationUtil.fieldValidationKeyFactory(props.id);
    const ref = props.ref;
    const fileModalStyles = modalStyles();
    const [modalBody,setModalBody] = useState<JSX.Element | null>()


    const files = props.filesValue ?? [];
    const readOnly = props.readOnly;

    const fv_value = props['fv_value'];
    const elemRef = props.elemRef;


    /*

    // if you need to handle (preview, delete) files  directly in dropzone uncomment this

    const prepareStoredFileForDropzonePreview = async (file) => {
        const {name, mimeType, data} = file;
        const dataUrl = await createFileDataURL(file);
        if (dataUrl) {
            return fetch(dataUrl)
                .then(function (res) {
                    return res.arrayBuffer();
                })
                .then(function (buf) {
                    return {
                        data: dataUrl,
                        file: new File([buf], name, {type: mimeType})
                    };
                })
        }else{
            return null;
        }
    };

    useEffect(() => {
        if (props.filesValue && props.filesValue.length) {
            const promises: Promise<FileObject | null>[] = [];
            props.filesValue.forEach(file => promises.push(prepareStoredFileForDropzonePreview(file)));

            Promise.all(promises).then((values) => {
                setDropzoneFiles(values.filter(v=>v!=null) as FileObject[]);
            });
        }else {
            setDropzoneFiles([]);
        }
    }, [props.filesValue]);
    */

    const handleOpen:HandleOpenSignature = async (event:SyntheticEvent<HTMLElement> ,file: string | File | File[],useURL?:boolean):Promise<boolean | string>=>{
        if (typeof file === 'string'){
            return Promise.resolve(true);
        }

        if (Array.isArray(file)){
            file = file[0]
        }
        let {name,mimeType,data} = file;

        const store = getStore();
        const setInProgress = (value: boolean) => {
            return {
                type: 'SET_IN_PROGRESS',
                payload: value,
            }
        };

        const setError = (error: any) => {
            return {
                type: 'SET_ERROR',
                payload: error,
            }
        };

        let dataUrl;
        useURL = !!useURL || (typeof data  === 'string' && data.startsWith("fides:"));
        if (useURL) {
            if (typeof data  === 'string') {
                if (data.startsWith("file:")) {
                    const fileName = data.substring("file:".length);
                    const enc = encodeURIComponent(fileName)
                    dataUrl = `${contextPath}api/files?filePath=${enc}`;
                } else if (data.startsWith("fides:")){
                    const token = data.substring('fides:'.length);
                    const url = contextPath + 'api/fides/document/' + token;
                    const headFN = async()=>{
                        let _url = url+'?show=head';
                        if (name){
                            _url = _url+"&name="+encodeURI(name!)
                        }
                        const rsp = await fetch(_url,{method:'GET'});
                        if (rsp.status < 300) {
                            const {name:_name,mimeType} = await rsp.json();
                            return {
                                name:(_name || name),
                                mimeType
                            };
                        } else {
                            throw {status:rsp.status}
                        }
                    }

                    const dataAsFN = async ()=>{
                        const {name,mimeType} = await headFN();
                        return {name,mimeType,data:url+'?encode_as=binary&name='+encodeURI(name)}
                    }
                    data = dataAsFN;


                }else {
                    dataUrl = data;
                }
            }
            if (typeof data === 'function'){
                store.dispatch(setInProgress(true));
                try {
                    const dataRSP = (await data()) as File;
                    dataUrl = dataRSP.data;
                    mimeType = dataRSP.mimeType
                    name = dataRSP.name
                }catch (e){
                    store.dispatch(setError(e));
                }finally {
                    store.dispatch(setInProgress(false));
                }
            }
        }else{
            try {
                store.dispatch(setInProgress(true));
                dataUrl = await createFileDataURL(file);
            } catch (e) {
                store.dispatch(setError(e));
            } finally {
                store.dispatch(setInProgress(false));
            }
        }

        if (mimeType == null){
            if (dataUrl !=null){
                if (typeof dataUrl === 'string' && dataUrl.startsWith("data:")) {
                    const idx = dataUrl.indexOf(';base64');
                    mimeType = dataUrl.substring("data:".length, idx);
                }else if (dataUrl.mimeType){
                    mimeType = dataUrl.mimeType;
                }
            }
        }

        let _dataUrl;
        if (mimeType == "image/tiff" && dataUrl !=null){
            //Server would handle tiff files

            if (!dataUrl.startsWith("data:")) {
                _dataUrl = dataUrl + (dataUrl.indexOf("?") != -1 ? "&" : "?") + "action=preview";
            }else {
                _dataUrl = await fetch(`${contextPath}/api/files/convertTiffDataUrl`, {
                    method: 'POST',
                    body: dataUrl,
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'text/plain'
                    }
                });
            }
        }else{
            _dataUrl = dataUrl;
        }


        let mimeDiv;

        let extraStyles = {}
        if (_dataUrl !=null) {
            if (mimeType && (mimeType as string).startsWith("image")) {

                mimeDiv = <div style={{

                    maxWidth: '75vw',
                    maxHeight: 'calc(95vh - 64px)',
                    overflowY: "auto"

                }}>
                    <PreviewImage  dataUrl={_dataUrl}/>
                </div>


            } else if ((mimeType as string) === ("application/pdf")) {

                extraStyles = {
                    width: '75vw',
                    height: '75vh'
                };

                mimeDiv = <object name={name} type={mimeType} data={_dataUrl} style={{
                    width : '100%',
                    height:'100%'
                }} title={name+':title'}>
                    <param name={'name'} value={name}/>
                    <param name={'title'} value={'title:'+name}/>
                </object>
            } else {
                console.log("Cannot preview MIME TYPE",mimeType)
                mimeDiv = "Download"; /*
                    <Box m={2}>
                        <p>Preview is not supported for {mimeType} </p>
                    </Box>
                    */
            }
        } else {
            mimeDiv = null;
                /*<Box m={2}>
                    <p>Could not fetch file due to internal error</p>
                </Box>*/
        }


        function downloadFile():string{
            const a = document.createElement('a');
            let url;
            if (dataUrl.startsWith('data:')){
                url = dataUrl;
            }else{
                url = dataUrl+'&action=download';
            }

            a.href = url;
            a.download = name
            a.click()
            return url;
        }
        if (mimeDiv) {
            if (mimeDiv === "Download"){
                return downloadFile();
            }else {
                const modalBody = <div className={fileModalStyles.fileModal+' fileModal'} style={extraStyles}>
                    {dataUrl ?
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Box ml={2} my={1}>
                                <Typography variant="subtitle2">{name}</Typography>
                            </Box>
                            <Box my={1}>
                                <IconButton onClick={downloadFile}>
                                    <GetAppIcon color={"primary"}/>
                                </IconButton>
                            </Box>
                        </Grid> : <></>
                    }
                    {mimeDiv}
                </div>
                setModalBody(modalBody)

            }
        }
        return true;
    }

    const handleClose = (event)=>{
        setModalBody(null)
    }

    const fileNameFromValue = (_f: any) => {
        const {name,size} = _f;
        if (name){
            if (size){
                return `${name} (${size})`
            }else{
                return `${name}`
            }
        }else {
            return 'Click to open';
        }
    }

    const nameFromValue = (_v:any[])=>{
        return _v && _v.map(_f => fileNameFromValue(_f)).join("\n")
    }

    const scope = props.scope;
    const fieldValidationCallbacks = props.fieldValidationCallbacks;
    const _triggerParentOnChange = async (newFiles: File[]) => {



        const likeEventStructure = {
            preventDefault: () => {
            },
            target: {
                reportValidity: () => {
                },
                type: 'file',
                files: newFiles,
                scope
            }
        };

        control.setValue(controlName,newFiles.length == 0 ?null : newFiles);
        fieldValidationCallbacks.onChange(newFiles.length == 0 ?null : newFiles);
        props.onChange(likeEventStructure)
        // const _hasErrors = await control.trigger(controlName)
    };

    const methods = useFormContext();
    const { errors,control } = methods;

    const handleDeleteFile:HandleDeleteSignature = async (file, index) => {
        let newFiles = [...files];
        newFiles.splice(index, 1);
        await _triggerParentOnChange([...newFiles]);
        // control.setValue(formValidationUtil.fieldValidationKeyFactory(props.id, props.index), newFiles);
    };
    const layout = useContext(LayoutContext);

    let ret;
    const readOnlyClasses = useReadOnlyStyles();

    const withModal = (_modalAnchorRef:MutableRefObject<HTMLElement | null> | null) => (_modal:HTMLDivElement | null)=>{

        const inFrame = window.parent !== window;
        if (!inFrame){
            return;
        }
        let _modalAnchor = _modalAnchorRef?.current;
        if (_modalAnchor == null && _modal != null){
            //Assume disablePortal = true
            _modalAnchor = _modal.parentElement;
        }
        if (_modal !=null && _modalAnchor) {
            const viewPortBottom = window.innerHeight;
            const _viewPortCenter = _modalAnchor.getBoundingClientRect().top + _modalAnchor.getBoundingClientRect().height/2;


            const modalFile = _modal.querySelector('.fileModal') as HTMLDivElement;
            const contentHeight = modalFile ? modalFile.clientHeight : 0;
            if (_viewPortCenter && modalFile){
                if (_viewPortCenter < contentHeight /2) {
                    (modalFile as HTMLElement)!.style.top = `${contentHeight/2 + 10 }px`
                }else if (_viewPortCenter > viewPortBottom - contentHeight/2) {
                    (modalFile as HTMLElement)!.style.top = `${viewPortBottom - contentHeight /2 - 10}px`
                }else {
                    (modalFile as HTMLElement)!.style.top = `${_viewPortCenter}px`
                }
            }
        }
    }

    if (readOnly && (layout === 'review' || layout === 'facing')){
        ret = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}} id={props.id} >
            <Grid item xs={5}><Typography variant="body2" className={`${readOnlyClasses.readOnlyLabel}`}>{props.label}:</Typography></Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>
                <Typography variant="body2">
                    {files ? files.map((file, index) => (
                        <Link key={`${file.size}_${index}`} onClick={async event => {
                            event.preventDefault();
                            event.stopPropagation();
                            await handleOpen(event,[file],true);
                        }}> {nameFromValue([file])} </Link>
                    )) : null}
                    <Modal open={!!modalBody}
                           onClose={handleClose}
                           disablePortal={true}
                           ref={withModal(props.elemRef)}

                    >{modalBody ?? <></>}</Modal>
                </Typography>
                <Box ml={2}><Grid item>{getNotificationTemplate(props)}</Grid></Box>

            </Grid>
        </Grid>
    }else{
        
        const filesLimit = props.filesLimit && props.filesLimit !== '' && parseInt(props.filesLimit) || 1;
        const Component =  props.fileComponent === 'autocomplete' ? CustomFileAutocompleteComponent : CustomFileSimpleButtonComponent;
        const grid = <><Component
            {...{id:props.id,
                label:props.label,
                fv_value,
                path:props.path,
                files,
                readOnly,
                required:props.required,
                maxFiles:filesLimit,
                ref,
                helperText:props.helperText,
                fileNameFromValue,
                handleDeleteFile,
                handleOpen,
                _triggerParentOnChange,
                fieldValidationCallbacks,
                focused:props.focused,
                elemRef
            }
        }/>
        <Modal
            disablePortal={true}
            open={!!modalBody}
            onClose={handleClose}
            ref={withModal(props.elemRef)}
        >{modalBody?? <></>}</Modal>
        </>
        ret=  grid;
    }
    return ret;

}


export function helperTextFromValidationAndAnnotation(helperTextFormValidator,props){
    const annotation = useContext(AnnotationContext)
    const helperTextAnnotation = annotation?.value
    const tooltip = props.tooltip
    const _helperText = props.helperText
    const allHelperTexts = [tooltip,_helperText,helperTextFormValidator, helperTextAnnotation].filter(a=>a)
    let helperText;
    if (allHelperTexts.length > 1){
        helperText =
            <ul>
                {allHelperTexts.map(a=><li><span dangerouslySetInnerHTML={{__html:a}}/></li>)}
            </ul>
    }else if (allHelperTexts.length === 1){
        helperText = allHelperTexts[0]
    }else{
        helperText = null
    }
    return helperText;

}


export function PreviewImage(props:{dataUrl:string}) {
    const {dataUrl} = props
    const theme = useTheme();
    const [imgError,setImgError] = useState<string | null>(null);

    if (imgError){
        return <Typography variant="body2" color="error" style={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1)
        }}>{imgError}</Typography>
    }else {
        return <img src={dataUrl}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                    alt={"Preview Image"}
                    onError={evt => {
                        setImgError("Error Loading Image")
                    }}
        />
    }
}

export function CustomFileFieldWithUrl(props: {
    fileUrl: string;
    fileName: string;
    label: string;
    value?: string;
    notificationTmpl?;// as can be used as independent component
}) {

    const notificationTmpl = props.notificationTmpl || getNotificationTemplate(props)

    const fileModalStyles = modalStyles();

    const [modalBody,setModalBody] = useState<{modalBody:JSX.Element,url} | null>();



    const handleOpen = async (fileUrl: string,name?:string)=>{
        let dataUrl = fileUrl;
        const fRsp = await fetch(dataUrl);
        const mimeType = fRsp.headers.get('content-type');
        const disposition = fRsp.headers.get('content-disposition');
        if (!name) {
            if (disposition) {
                name = disposition.split(';').map(a => a.trim()).filter(a => a.startsWith('filename=')).map(a => a.substring('filename='.length))[0];
                if (name.length > 1 && name.startsWith('"') && name.endsWith('"')) {
                    name = name.substring(1, name.length - 1); //remove quotes
                }
            } else {
                name = "file";
            }
        }
        const asBlob = await fRsp.blob();
        const url = URL.createObjectURL(asBlob);

        let mimeDiv;
        let extraStyles = {};


        if (disposition){
            mimeDiv = "Download_Immediate";
            const fileReader = new FileReader();
            fileReader.onload = (event)=>{
                const _d = event.target!.result;
                const a = document.createElement('a');
                a.href = _d as string;
                a.download = name ?? "file"
                a.click()
            }
            fileReader.readAsDataURL(asBlob);
        }else {
            //Duplicate code
            if (mimeType && (mimeType as string).startsWith("image") && (mimeType as string).indexOf("tiff") == -1) {
                mimeDiv = <div style={{

                    maxWidth: '75vw',
                    maxHeight: 'calc(95vh - 64px)',
                    overflowY: "auto"

                }}>
                    <PreviewImage dataUrl={dataUrl}/>
                </div>

            } else if ((mimeType as string) === ("application/pdf")) {

                extraStyles = {
                    width: '75vw',
                    height: '75vh'
                };

                mimeDiv = <object name={name} type={mimeType!} data={url} width={"100%"} height={"100%"}
                                  title={name + ':title'}>
                    <param name={'name'} value={name}/>
                    <param name={'title'} value={'title:' + name}/>
                </object>
            } else {
                mimeDiv = "Download_2" /*
                    <Box m={2}>
                        <p>Preview is not supported for {mimeType} </p>
                    </Box>
                    */
            }
        }


        if (mimeDiv === "Download_2") {
            const a = document.createElement('a');
            if (dataUrl.startsWith('data:')) {
                a.href = dataUrl;
            } else {
                a.href = dataUrl + '&action=download';
            }

            a.download = name
            a.click()
        }else if (mimeDiv === "Download_Immediate"){
            //Do nothing
        }else {
            const modalBody = <div className={fileModalStyles.fileModal+' fileModal'} style={extraStyles}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Box ml={2} my={1}>
                        <Typography variant="subtitle2">{name}</Typography>
                    </Box>
                    <Box my={1}>
                        <IconButton onClick={evt=>{
                            const a = document.createElement('a');
                            let url;
                            if (dataUrl.startsWith('data:')){
                                url = dataUrl;
                            }else{
                                url = dataUrl+'&action=download';
                            }

                            a.href = url;
                            a.download = name ?? "file"
                            a.click()
                            return url;
                        }}>
                            <GetAppIcon color={"primary"}/>
                        </IconButton>
                    </Box>
                </Grid>
                {mimeDiv}
            </div>;
            setModalBody({url, modalBody});
        }
    };

    const handleClose = (event)=>{

        if (modalBody && modalBody.url){
            URL.revokeObjectURL(modalBody.url);
        }
        setModalBody(null)
    };

    const fileUrl = props.fileUrl ? props.fileUrl : props.value;
    const fileName = props.fileName

    return <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}><Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{props.label}:</Typography></Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" item xs={6}>
                <Typography variant="body2">
                    <Link onClick={async event => {
                        event.preventDefault();
                        event.stopPropagation();
                        await handleOpen(fileUrl!,fileName); }}>
                        {fileName}
                    </Link>
                    <Modal open={!!(modalBody && modalBody.modalBody)} onClose={handleClose}>{modalBody?modalBody.modalBody:<></>}</Modal>
                </Typography>
                <Box ml={2}><Grid item>{notificationTmpl}</Grid></Box>
            </Grid>
        </Grid>
}



export const RepeatableGridItem = styled(Grid)(st =>({
    paddingRight: st.theme.spacing(1),
    paddingLeft: st.theme.spacing(1),

}),{name:'RepeatableGridItem',classNamePrefix:'repeatable-grid-item'});


export const LabelWithTooltip = (_props)=>{
    let {props,label,tooltip} = _props;
    label = label || props.fv_value.label || props.fv_value.id
    const labelRef = _props.labelRef ?? useRef<HTMLElement>(null);
    tooltip = tooltip || props?.tooltip;
    const [showTooltip,setShowTooltip] = useState(false);
    if (false && tooltip){
        const IconWithTooltip = () => (
            <Tooltip title={tooltip} style={{marginLeft:'4px',marginBottom:'1px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
            </Tooltip>
        );



        return <><span ref={labelRef} dangerouslySetInnerHTML={{__html: (label)}}/><IconWithTooltip/></>

        /*
        return <>
            <span dangerouslySetInnerHTML={{__html: (label)}} />
            <button onClick={(evt)=>{
                evt.preventDefault();
                setShowTooltip(!showTooltip)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-question-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path
                      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
            </button>
            <div style={{display:showTooltip?'block':'none'}}>{tooltip}</div>
        </>
         */
    }else{
        return <span ref={labelRef} dangerouslySetInnerHTML={{__html: (label)}}/>
    }
}


















