import * as React from "react";
import OnfidoComponentBase, {CompletionData} from "sancus-client-common/dist/common/OnfidoComponentBase";
import {OnfidoCheckResultComponent} from "./onfidoCheckResultComponent";
import { CustomFileFieldWithUrl} from "./generic_component";
import { flatMap } from "lodash";
// @ts-ignore

import cpp from "sancus-client-common/dist/common/contextPath";


export default class OnfidoComponent extends OnfidoComponentBase {

    async withSDK(id,applicantId,sdkToken,valueChangeCbk) {
        const {lang} = this.props;

        const url=`${cpp.publicURL}translations/onfido_${lang}.json`
        const messages = lang && await fetch(url).then(rsp=>rsp.json()).catch(err=>{
            console.log(`Failed to fetch onfido messages for ${lang}`,err);
            return null;
        }) || null;

        const css = flatMap(Array.from(document.styleSheets),s=>{try {
                return Array.from(s.cssRules)
            }catch (e){
                return []
            }
        }).filter(rule=>rule.cssText.indexOf("onfido")!== -1);



        const Onfido  = await import('onfido-sdk-ui');
        if (Onfido) {
            const that = Onfido.init({
                token: sdkToken,
                containerId: 'onfido-mount',
                async onComplete(data: CompletionData) {
                    console.log('Onfido::onComplete', data);
                    valueChangeCbk(id, {applicantId, data}, true)
                },
                onError(error) {
                    console.log('Onfido::onError', error);
                    valueChangeCbk(id, {applicantId, error}, true)
                },
                steps: [
                    'document',
                    'face'
                ],
                ...(messages !=null ? {
                    language: {
                        locale: lang,
                        phrases: messages,
                        mobilePhrases: messages,
                    }
                } : {}),
            })

            const cssAsString = css.map(c=>c.cssText).join('\n');
            const style = document.createElement('style');
            style.setAttribute('id','onfido-reloaded');
            style.innerText = cssAsString;
            document.head.appendChild(style);

            return that;
        }else{
            throw "Cannot load Onfido module";
        }

    }

    renderResultComponent(checkInfo) {
        return <OnfidoCheckResultComponent check={checkInfo}/>
    }

    renderImages(options):JSX.Element {
        return (<>
                {options.imageSrcs.map((src, index) => (
                    <CustomFileFieldWithUrl key={src} fileUrl={src} fileName={options.fileNames[index]} label={options.labels[index]}/>
                ))}
            </>
        )
        /*return  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden',
            borderBottom: 'solid 1px black', borderTop: 'solid 1px black', paddingTop: 16, paddingBottom: 16 }}>
            <GridList style={{ flexWrap: 'nowrap', transform: 'translateZ(0)'}} cols={1.5}>
                {imageSrcs.map((src) => (
                    <GridListTile key={src} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around',
                        overflow: 'hidden', borderRight: 'solid 1px black' }}>
                        <img src={src} style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto', height: '100%', borderRadius: 16 }}/>
                    </GridListTile>
                ))}
            </GridList>
        </div>*/

    }

    doRender(view) {
        return <div id="onfido-mount">
            {view}
        </div>
    }


}
