import { handleActions } from 'redux-actions';
import {FormContainerActions} from "../actions/formContainer.actions";

export const formContainerInProgressReducer = handleActions<any, any>(
    {
        [FormContainerActions.Type.SET_IN_PROGRESS]: (state, action) => {
            return action.payload;
        },
    },
    {} as any
);