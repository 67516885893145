import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {getStore} from "sancus-client-common/dist/store";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
}));

export default function GlobalLoader() {
    const classes = useStyles();
    const [inProgress, setProgress] = React.useState<null | boolean | string | number | { message: string; percent: number }>(null);

    useEffect(() => {
        // Redux store
        const store = getStore();
        console.log('GlobalLoader use effect triggered');

        const onReduxStoreDataChange = () => {
            const inProgressState = getStore().getState().inProgress;

            if (inProgressState !== null && inProgressState !== inProgress) {
                setProgress(inProgressState);
            }

            if ((inProgressState === null || inProgressState === false) && inProgress) {
                setProgress(inProgressState);
            }
        };

        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);
        onReduxStoreDataChange();

        // code here work like in componentWillUnmount()
        return () => {
            console.log('FormContainer GlobalLoader use effect unsubscribeStore');
            unsubscribeStore();
        }
    });

    function CircularProgressWithLabel(props) {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }


    function selectLoader() {
        if (typeof inProgress === "boolean") {
            return <CircularProgress size={50}/>
        }

        if (typeof inProgress === "string" || React.isValidElement(inProgress)) {
            return (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Box p={2} style={{
                        background: '#ffffff',
                        borderRadius: '9px',
                        boxShadow: '0px 0px 10px rgb(0 0 0 / 15%)'
                    }}>
                        <Box p={2}>
                            <Typography variant="body1" color="textSecondary">
                                {inProgress}
                            </Typography>
                        </Box>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Box p={2}>
                                <CircularProgress size={50}/>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            )
        }else if (typeof inProgress === "number") {
            return <CircularProgressWithLabel value={inProgress} size={50}/>
        }else if (inProgress !== null && typeof inProgress === "object") {
            if ("message" in inProgress && "percent" in inProgress) {
                return (
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="body2" color="textSecondary">
                            {inProgress!.message}
                        </Typography>
                        <CircularProgressWithLabel value={inProgress!.percent} size={50}/>
                    </Grid>
                )
            } else {
                return <CircularProgress size={50}/>
            }
        }else {
            return <CircularProgress size={50}/>
        }
    }

    if (inProgress === null || inProgress === false) {
        return <></>;
    } else {
        console.log('GlobalLoader  triggered');
        return (
            <Backdrop className={classes.backdrop} open={true}>
                {selectLoader()}
            </Backdrop>
        )
    }
}
