import *  as React from 'react';
import {useState, useEffect, useRef} from "react";
import * as _ from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import {deepOrange, deepPurple, yellow, blue} from '@material-ui/core/colors';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Typography from '@material-ui/core/Typography';
import MomentUtils from "@date-io/moment";
import Avatar from "@material-ui/core/Avatar";
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import contextPath from "sancus-client-common/dist/common/contextPath";
import {getStore} from "sancus-client-common/dist/store";
import {hashCode} from "sancus-client-common/dist/utils/utils";

const momentUtils = new MomentUtils();

const useStyles = makeStyles((theme) => ({
    orangeAvatar: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    yellowAvatar: {
        color: 'white',
        backgroundColor: yellow[700],
    },
    blueAvatar: {
        color: 'white',
        backgroundColor: blue[500],
    },
    smallAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    timelineSeparator: {
        position: "relative",
        minWidth: '40px'
    },
    separatorIcon: {
        position: "absolute",
        bottom: "12px",
        left: "0px",
        backgroundColor: '#ffffff',
        border: 'solid 5px rgb(231, 239, 247)',
        '&:hover': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
            background: '#ffffff'
        }
    },
    borderAvatarPending: {
        backgroundColor: 'rgb(255, 204, 0)',
        color: 'rgb(255, 204, 0)',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        padding: '2px',
        borderRadius: '50%',
        position: 'relative',
        '&::after': {
            position: 'absolute',
            top: '-2px',
            left: '-2px',
            bottom: '-2px',
            right: '-2px',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        }
    },
    borderAvatarCompleted: {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        padding: '2px',
        borderRadius: '50%',
        position: 'relative'
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1.2)',
            opacity: 0,
        },
    },
    timelineOppositeContent: {
        maxWidth: '120px',
        textAlign: 'center'
    },
    timelineContent: {
        margin: '16px',
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px'
    },
    timelineConnector: {
        width: "1px"
    }
}));

interface AuditItemFromServer {
    "running": boolean,
    "type": "INSTANCE" | "TASK",
    "id": string,
    "name": string,
    "userIds": string[],
    "started": string,
    "ended": string,
    "children"?: AuditItemFromServer []
}

interface AuditItem extends AuditItemFromServer {
    visualIcon: "avatar" | "collapsed" | "expanded" | "more";
    label: string;
    key: string; // jsut react el key
    relativeTime: string;
    instanceId: string;
    taskId?: string;
    assignee?: {
        name: string,
        imageURL: string,
        avatarColor: string,
    }
    "children"?: AuditItem []
}

export default function AuditTimeline(props) {
    console.log('CustomizedTimeline renders', props);
    const {auditLogEntries, users, hasMore, firstResult, maxResults} = props.value;
    const avatarColorsNames = ['blueAvatar', 'yellowAvatar', 'purpleAvatar', 'orangeAvatar'];
    const avatarColorsNamesLength = avatarColorsNames.length;

    const [timelineItems, setTimelineItems] = useState<AuditItem[]>([]);
    const needScrollBottom = useRef(false);

    useEffect(() => {
        console.log(`AuditTimeline useEffect triggered...`);

        _.each(users, (user) => {
            if (!user.hasImageURL) {
                const avatarIdx = hashCode(user.id) % avatarColorsNamesLength
                user.avatarColorName = avatarColorsNames[avatarIdx];
            }
        });

        // TODO delete this after server will be fixed
        const avoidDuplicates: any [] = _.uniqBy(auditLogEntries, function (item: AuditItemFromServer) {
            return item.id;
        });

        _.each(avoidDuplicates, (item: AuditItem) => {
            extendWithDisplayData(item);

            if (item.children && item.children.length) {
                if (item.children.length === 1) {
                    item.visualIcon = "avatar";
                    item.taskId = item.children[0].id;
                } else {
                    item.visualIcon = "collapsed";
                    const instanceId = item.instanceId;

                    _.each(item.children, item => extendWithDisplayData(item, instanceId))
                }

            } else {
                // instance without children
                item.visualIcon = "avatar";
            }
        });
        const store = getStore();
        const setAuditTask = (payload: any) => {
            return {
                type: 'SET_TIMELINE_ITEMS',
                payload: payload,
            }
        };

        let storedTasks = [];
        if (firstResult) {
            store.dispatch(setAuditTask([]));
        } else {
            storedTasks = store.getState().auditTasks || [];
        }
        const haveToShow = storedTasks.concat(auditLogEntries);

        store.dispatch(setAuditTask(haveToShow));

        let loadMore: any = [];
        //
        if (hasMore) {
            loadMore.push({
                type: "LOAD_MORE",
                visualIcon: "more",
                label: "More events in customer history",
                key: "loadMore",
                relativeTime: "",
                instanceId: "",
                assignee: {
                    name: "Load more",
                    imageURL: "",
                    avatarColor: "",
                }
            });
        }

        needScrollBottom.current = true;
        setTimelineItems([...(haveToShow || []), ...loadMore]);
    }, []);

    useEffect(() => {
        if (timelineItems && timelineItems.length && needScrollBottom.current) {
            const serviceCheckElem = document.getElementById(`${timelineItems[timelineItems.length - 1].key}_timeline`);
            if (serviceCheckElem) {
                serviceCheckElem.scrollIntoView();
            }
        }
    }, [timelineItems]);

    function extendWithDisplayData(item, instanceId?) {
        item.visualIcon = "avatar";
        item.label = item.name;
        item.key = item.id;

        if (item.type === "INSTANCE") {
            item.instanceId = item.id;
        } else {
            item.taskId = item.id;
            item.instanceId = instanceId;
        }

        if (item.userIds.length) {
            let firstUser;
            let name = _.map(item.userIds, (userId) => {
                if (users[userId]) {
                    if (!firstUser) {
                        firstUser = users[userId];
                    }
                    return users[userId].firstName + users[userId].firstName ? `${users[userId].firstName} ${users[userId].lastName}` : userId;
                } else {
                    return undefined
                }
            }).join(', ');

            item.assignee = firstUser ? {
                name: name,
                imageURL: firstUser.imageURL || `${contextPath}api/users/${firstUser.id}/image`,
                avatarColor: firstUser.avatarColorName,
            } : null;
        } else {
            item.assignee = null;
        }


        if (item.running) {
            item.relativeTime = 'Pending';
        } else {
            item.relativeTime = momentUtils.moment(item.ended).fromNow();
        }
    }

    const classes = useStyles();

    function renderTimelineItem(item, type) {
        return (
            // @ts-ignore
            <TimelineItem key={type + ":" + item.key} id={item.key + "_timeline"}>
                <TimelineOppositeContent className={classes.timelineOppositeContent}>
                    <Box pr={1}>
                        <Typography variant="caption"
                                    color={item.relativeTime === 'Pending' ? "textSecondary" : "textSecondary"}>
                            {item.relativeTime}
                        </Typography>
                    </Box>
                </TimelineOppositeContent>

                <TimelineSeparator className={classes.timelineSeparator}>
                    {
                        item.visualIcon === "collapsed" && (
                            <Box>
                                <Box><Box
                                    className={item.relativeTime === 'Pending' ? classes.borderAvatarPending : classes.borderAvatarCompleted}>
                                    <Avatar alt={item.assignee?.name} src={item.assignee?.imageURL}
                                            className={(item.assignee?.avatarColor ? (' ' + classes[item.assignee?.avatarColor]) : "")}/>
                                </Box></Box>
                                <IconButton aria-label="expand"
                                            onClick={() => {
                                                item.visualIcon = "expanded";
                                                needScrollBottom.current = false;
                                                setTimelineItems((prevState) => [...prevState]);
                                            }}
                                            size="small"
                                            className={classes.separatorIcon}>
                                    <UnfoldMoreIcon/>
                                </IconButton>
                            </Box>

                        )}
                    {
                        item.visualIcon === "avatar" && (
                            <Box><Box
                                className={item.relativeTime === 'Pending' ? classes.borderAvatarPending : classes.borderAvatarCompleted}>
                                <Avatar alt={item.assignee?.name} src={item.assignee?.imageURL}
                                        className={(item.type !== 'INSTANCE' ? classes.smallAvatar : "") + (item.assignee?.avatarColor ? (' ' + classes[item.assignee?.avatarColor]) : "")}/>
                            </Box></Box>)
                    }
                    {
                        item.visualIcon === "expanded" && (
                            <Box>
                                <Box><Box
                                    className={item.relativeTime === 'Pending' ? classes.borderAvatarPending : classes.borderAvatarCompleted}>
                                    <Avatar alt={item.assignee?.name} src={item.assignee?.imageURL}
                                            className={(item.assignee?.avatarColor ? (' ' + classes[item.assignee?.avatarColor]) : "")}/>
                                </Box></Box>
                                <IconButton aria-label="collapse"
                                            onClick={() => {
                                                item.visualIcon = "collapsed";
                                                needScrollBottom.current = false;
                                                setTimelineItems((prevState) => [...prevState]);
                                            }}
                                            size="small"
                                            className={classes.separatorIcon}>
                                    <UnfoldLessIcon/>
                                </IconButton>
                            </Box>
                        )
                    }
                    {
                        item.visualIcon === "more" && (
                            <IconButton aria-label="load more"
                                        onClick={() => {
                                            props.valueChangeCbk("internalComponentAction", "Load More", true);
                                        }}
                                        size="small"
                                        className={classes.separatorIcon}>
                                <MoreHorizIcon/>
                            </IconButton>
                        )
                    }
                    <TimelineConnector className={classes.timelineConnector}/>
                </TimelineSeparator>

                <TimelineContent className={classes.timelineContent}>
                    <Box pl={1}
                         onClick={() => {
                             console.log(" TimeLine clicked: instanceId: ", item.instanceId, ' taskId: ', item.taskId);

                             if (item.relativeTime === 'Pending' && item.taskId) {
                                 const setAuditTaskForReview = (payload: any) => {
                                     return {
                                         type: 'SET_TASK_FOR_REVIEW',
                                         payload: payload,
                                     }
                                 };
                                 getStore().dispatch(setAuditTaskForReview(item.taskId));
                             }
                         }}>
                        <Typography variant={item.type === 'INSTANCE' || item.type === 'LOAD_MORE' ? "body1" : "body1"}
                                    color={item.relativeTime === 'Pending' ? "textPrimary" : "textPrimary"} style={{whiteSpace:"pre-wrap"}} >
                            <div dangerouslySetInnerHTML={{__html:item.label}} />

                        </Typography>
                        <Typography variant="caption"
                                    color={item.relativeTime === 'Pending' ? "textSecondary" : "textSecondary"}>
                            {item.assignee?.name}
                        </Typography>
                    </Box>
                </TimelineContent>
            </TimelineItem>
        )
    }

    if ( timelineItems.length === 0 ) {
        return <Typography variant="body1" color="textPrimary">No historical logs for select search criteria.</Typography>
    } else {
        return <Timeline align="left">
            {
                timelineItems && timelineItems.map((item, index) => {
                    if (item.visualIcon === 'collapsed' || item.visualIcon === 'avatar' || item.visualIcon === 'more') {
                        return renderTimelineItem(item, "instance");
                    }

                    if (item.visualIcon === 'expanded') {
                        return item.children && item.children.reduce((acc, children) => [...acc, renderTimelineItem(children, "instance")],
                            [renderTimelineItem(item, "task")]);
                    }
                })
            }
        </Timeline>
    }
}
