import * as React from "react";
import {Card, FormControl, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CheckActions} from "sancus-client-common/dist/store/actions/checks";

interface ICheckFilterProps {
	statuses?: string[];
	changeFilter: typeof CheckActions.applyAlertFilter;
	filterValue: number;
}

/**
 * check filter control component
 * @param props
 * @constructor
 */
const CheckFilterComponent = (props: ICheckFilterProps) => {

	const useStyles = makeStyles((theme) => ({
		formControl: {},
		select: {
			"&": {
				fontSize: '13px',
				fontWeight: '400'
			},
			"& :focus": {
				background: 'transparent'
			}

		}
	}));

	const classes = useStyles();

	const handleFilterChange = React.useCallback(
		(event: any) => {
			props.changeFilter(event.target.value);
		},
		[props.changeFilter]
	);
	return <FormControl className={classes.formControl}>
			<Select className={classes.select}
				value={props.filterValue || 0}
				onChange={handleFilterChange}
				displayEmpty disableUnderline
				inputProps={{ 'aria-label': 'Without label' }}>
				<MenuItem value={0}>All</MenuItem>
				<MenuItem value={1}>Completed</MenuItem>
				<MenuItem value={2}>Unresolved</MenuItem>
			</Select>
		</FormControl>;
}

export default CheckFilterComponent;