import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';

/**
 * Map available actions that change state
 */
export namespace UserSessionInfoActions {
    export enum Type {
        SET_USER_SESSION_INFO = 'SET_USER_SESSION_INFO',
    }

    export const setUserSessionInfo = createAction<any[]>(Type.SET_USER_SESSION_INFO);
}

export type UserSessionInfoActions = Omit<typeof UserSessionInfoActions, 'Type'>;
export const useFormContainerActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = UserSessionInfoActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as UserSessionInfoActions;
};
