import {handleActions} from 'redux-actions';
import {DesignerActions} from "../actions/designer.actions";
import {
    createFieldFromPaletteElement,
    FormField,
    FormProperty
} from "../../common/camunda_helper";
import _ from "lodash";


let counter = 0;
let group_counter = 0

function updateSelectedField(state) {
    if (state.selectedField) {
        const formFieldIndex = state.formFields.findIndex(f => f.id === state.selectedField?.id);

        if (formFieldIndex < 0) {
            state.selectedField = null;
        } else {
            if (formFieldIndex !== state.selectedField.idx) {
                state.selectedField = {...state.formFields[formFieldIndex]};
                state.selectedField.idx = formFieldIndex;
            }
        }
    }
}

export const designerReducer = handleActions({
    [DesignerActions.Type.SELECT_FIELD]: (state, action) => {
        const {fieldId} = action.payload
        const fieldIdx = state.formFields.findIndex(f => f.id === fieldId);
        if (fieldIdx != null && state.selectedField?.idx !== fieldIdx) {
            state.selectedField = state.formFields[fieldIdx]
            state.selectedField.idx = fieldIdx;
            if( state.selectedItem) {
                state.selectedItem = null;
            }
            return {...state}
        }
        return state;
    },

    [DesignerActions.Type.CHANGE_GROUP_ID]: (state, action) => {
        const {oldId, newId, type} = action.payload

        state.formFields.forEach(f => {
            if (f.properties && f.properties[type] && f.properties[type] === oldId) {
                f.properties[type] = newId;
            }
        })

        state.selectedItem = Object.assign({}, state.selectedItem, {id: newId});

        return {...state}
    },

    // this can be used to select everything which is not a field
    // Group, repeatable Group, Actions,
    [DesignerActions.Type.SELECT_ITEM]: (state, action) => {
        const {id, type, extraData} = action.payload
        if ( state.selectedItem?.id !== id) {
            if( state.selectedField) {
                state.selectedField = null;
            }
            state.selectedItem = {id, type, extraData};
            return {...state}
        }
        return state;
    },
    [DesignerActions.Type.DRAG_START]: (state, action) => {
        const {type, fieldId, groupId, component, defaultProperties, defaultValidationConstraints} = action.payload
        const fieldIdx = state.formFields.findIndex(f => f.id === fieldId);
        console.log('DesignerActions.DRAG_START type', type, fieldId && ('fieldId: ' + fieldId) || '', groupId && ('groupId: ' + groupId) || '', component && ('component: ' + component) || '', defaultProperties && ('defaultProperties: ' +  JSON.stringify(defaultProperties)) || '',  defaultValidationConstraints && ('defaultValidationConstraints: ' + JSON.stringify(defaultValidationConstraints)) || '');

        state.dragElement = {type: type, fieldIdx: fieldIdx === -1 ? undefined : fieldId, groupId, component, defaultProperties, defaultValidationConstraints};
        return state;
    },
    [DesignerActions.Type.DROP_IN_PLACE]: (state, action) => {
        const {type: typeOfDropArea, fieldId: dropInFieldId, groupId: dropInGroupId} = action.payload
        const dragElement = state.dragElement;

        console.log('DesignerActions.DROP_IN_PLACE typeOfDropEl: ', typeOfDropArea, dropInFieldId && (' DropInFieldId: ' + dropInFieldId) || '', dropInGroupId && (' dropInGroupId: ' + dropInGroupId) || '', ' dragElement: ', dragElement);

        if (!dragElement) {
            return state;
        }

        // palette element dropped
        if (dragElement.fieldIdx === undefined && !dragElement.groupId) {
            if (state.formFields == null) {

            }
            let field: Partial<FormProperty>;

            if (dragElement.type === 'group' || dragElement.type === 'group_repeatable' || dragElement.type === 'group_in_dialog') {
                //create mock and hidden field as a placeholder for the group
                group_counter++;

                let group_name = `${dragElement.type}_${group_counter}`;

                if (dragElement.type === 'group_in_dialog'){
                    dragElement.type = 'group';
                }

                field = {
                    id: `__hidden_field_of_${dragElement.type}_${group_counter}`,
                    type: {name: 'string'},
                    typeName: 'string', //This one is prevalent to the others
                    value: {
                        value: null,
                        type: {
                            name: 'string'
                        },
                        transient: false
                    },
                    validationConstraints: [],
                    properties: {
                        [dragElement.type]: group_name,
                        'visibility': 'hidden'
                    }
                }
            } else {
                field = createFieldFromPaletteElement(`field_${counter++}`, dragElement);
            }

            if (typeOfDropArea === 'upperFormArea') {
                state.formFields.splice(0, 0, field);

            } else if (typeOfDropArea === 'bottomFormArea') {
                state.formFields.push(field);

            } else if (typeOfDropArea === 'field') {
                const dropInFieldIdx = state.formFields.findIndex(f => f.id === dropInFieldId);
                const dropInField = state.formFields[dropInFieldIdx];

                if (dragElement.type === 'group' || dragElement.type === 'group_repeatable') {
                    // 'group' or 'group_repeatable'
                    const fieldBelongsTo = dropInField.properties['group'] && 'group' || dropInField.properties['group_repeatable'] && 'group_repeatable';

                    if (fieldBelongsTo) {
                        const dropInGroupId = dropInField.properties[fieldBelongsTo];
                        const dropFirstGroupFieldIdx = state.formFields.findIndex(f => dropInField.properties[fieldBelongsTo] === dropInGroupId);

                        state.formFields.splice(dropFirstGroupFieldIdx, 0, field);
                    } else {
                        state.formFields.splice(dropInFieldIdx, 0, field);
                    }
                } else {
                    if (dropInField.properties?.group) {
                        field.properties!.group = dropInField.properties['group'];
                    }else if (dropInField.properties?.group_repeatable) {
                        field.properties!.group_repeatable = dropInField.properties.group_repeatable;
                    }
                    state.formFields.splice(dropInFieldIdx, 0, field);
                }

            } else if (typeOfDropArea === 'group' || typeOfDropArea === 'group_repeatable') {
                const firstGroupIdx = state.formFields.findIndex(f => f.properties![typeOfDropArea] === dropInGroupId);
                const groupLength = (state.formFields.filter(f => f.properties![typeOfDropArea] === dropInGroupId)).length;
                const dropInFieldIdx = firstGroupIdx + groupLength - 1;
                const dropInField = state.formFields[dropInFieldIdx];

                if (dragElement.type === 'group' || dragElement.type === 'group_repeatable') {
                    // here we just add group before drop group
                    const dropFirstGroupFieldIdx = state.formFields.findIndex(f => f.properties![typeOfDropArea] === dropInGroupId);
                    state.formFields.splice(dropFirstGroupFieldIdx, 0, field);

                } else {
                    // here we just add field to group
                    if (dropInField.properties['group']) {
                        field.properties!['group'] = dropInField.properties['group'];
                    }
                    if (dropInField.properties['group_repeatable']) {
                        field.properties!['group_repeatable'] = dropInField.properties['group_repeatable'];
                    }

                    state.formFields.splice(dropInFieldIdx, 0, field);
                }
            }

        } else if (dragElement.groupId) {
            // exists group dragged

            const firstGroupFieldIdx = state.formFields.findIndex(f => f.properties![dragElement.type] === dragElement.groupId);
            const groupFieldsLength = (state.formFields.filter(f => f.properties![dragElement.type] === dragElement.groupId)).length;
            const lastGroupFieldIdx = firstGroupFieldIdx + groupFieldsLength - 1;

            if (typeOfDropArea === 'upperFormArea' && firstGroupFieldIdx !== 0) {
                const movedFields = state.formFields.splice(0, firstGroupFieldIdx);
                state.formFields.splice(lastGroupFieldIdx - firstGroupFieldIdx + 1, 0, ...movedFields);
            }

            if (typeOfDropArea === 'bottomFormArea' && lastGroupFieldIdx !== state.formFields.length - 1) {
                const movedFields = state.formFields.splice(firstGroupFieldIdx, groupFieldsLength);
                state.formFields.push(...movedFields);
            }

            if (typeOfDropArea === 'field') {
                const dropInFieldIdx = state.formFields.findIndex(f => f.id === dropInFieldId);
                const dropInField = state.formFields[dropInFieldIdx];

                if (!(dropInField.properties!['group'] || dropInField.properties!['group_repeatable'])) {
                    // if drop happened in just field
                    const movedFields = state.formFields.splice(firstGroupFieldIdx, groupFieldsLength);

                    if (dropInFieldIdx < firstGroupFieldIdx) {
                        state.formFields.splice(dropInFieldIdx, 0, ...movedFields);
                    } else {
                        state.formFields.splice(dropInFieldIdx - groupFieldsLength, 0, ...movedFields);
                    }
                } else {
                    // if drop happened in another Group's field
                    const fieldBelongsTo = dropInField.properties['group'] && 'group' || dropInField.properties['group_repeatable'] && 'group_repeatable';
                    const dropInGroupId = dropInField.properties![fieldBelongsTo];
                    const dropFirstGroupFieldIdx = state.formFields.findIndex(f => f.properties![fieldBelongsTo] === dropInGroupId);
                    const movedFields = state.formFields.splice(firstGroupFieldIdx, groupFieldsLength);

                    if (dropFirstGroupFieldIdx < firstGroupFieldIdx) {
                        state.formFields.splice(dropFirstGroupFieldIdx, 0, ...movedFields);
                    } else {
                        state.formFields.splice(dropFirstGroupFieldIdx - groupFieldsLength, 0, ...movedFields);
                    }
                }
            }

            if ((typeOfDropArea === 'group' || typeOfDropArea === 'group_repeatable') && dropInGroupId) {
                const dropFirstGroupFieldIdx = state.formFields.findIndex(f => f.properties![typeOfDropArea] === dropInGroupId);
                const movedFields = state.formFields.splice(firstGroupFieldIdx, groupFieldsLength);

                if (dropFirstGroupFieldIdx < firstGroupFieldIdx) {
                    state.formFields.splice(dropFirstGroupFieldIdx, 0, ...movedFields);
                } else {
                    state.formFields.splice(dropFirstGroupFieldIdx - groupFieldsLength, 0, ...movedFields);
                }
            }

        } else if (dragElement.fieldIdx) {
            // Form's field dragged
            const DragFieldIdx = state.formFields.findIndex(f => f.id === dragElement.fieldIdx);

            if (typeOfDropArea === 'upperFormArea') {
                const draggedField = state.formFields[DragFieldIdx];
                draggedField.properties['group'] && delete draggedField.properties['group'];
                draggedField.properties['group_repeatable'] && delete draggedField.properties['group_repeatable'];

                state.formFields.splice(DragFieldIdx, 1);
                state.formFields.splice(0, 0, draggedField);

            } else if (typeOfDropArea === 'bottomFormArea') {
                const draggedField = state.formFields[DragFieldIdx];
                draggedField.properties['group'] && delete draggedField.properties['group'];
                draggedField.properties['group_repeatable'] && delete draggedField.properties['group_repeatable'];

                state.formFields.splice(DragFieldIdx, 1);
                state.formFields.push(draggedField);

            } else if (typeOfDropArea === 'field') {
                const dropInFieldIdx = state.formFields.findIndex(f => f.id === dropInFieldId);
                const dropInField = state.formFields[dropInFieldIdx];

                if (dropInFieldIdx !== DragFieldIdx) {
                    const movedField = state.formFields[DragFieldIdx];

                    if (dropInField.properties['group']) {
                        movedField.properties['group'] = dropInField.properties['group'];
                    }
                    if (dropInField.properties['group_repeatable']){
                        movedField.properties['group_repeatable'] = dropInField.properties['group_repeatable'];
                    }

                    state.formFields.splice(DragFieldIdx, 1);
                    state.formFields.splice(dropInFieldIdx, 0, movedField);
                }

            } else if ((typeOfDropArea === 'group' || typeOfDropArea === 'group_repeatable') && dropInGroupId) {
                const firstGroupFieldIdx = state.formFields.findIndex(f => f.properties![typeOfDropArea] === dropInGroupId);
                const groupFieldsLength = (state.formFields.filter(f => f.properties![typeOfDropArea] === dropInGroupId)).length;
                const lastGroupFieldIdx = firstGroupFieldIdx + groupFieldsLength - 1;

                const movedField = state.formFields[DragFieldIdx];
                const dropInField = state.formFields[lastGroupFieldIdx];

                state.formFields.splice(DragFieldIdx, 1);
                // insert before hidden field
                state.formFields.splice(lastGroupFieldIdx - (DragFieldIdx < lastGroupFieldIdx ? 1 : 0), 0, movedField);

                if (dropInField.properties['group']) {
                    movedField.properties!['group'] = dropInField.properties['group'];
                }

                if (dropInField.properties['group_repeatable']) {
                    movedField.properties!['group_repeatable'] = dropInField.properties['group_repeatable'];
                }
            }
        }

        state.dragElement = undefined;
        state.formFields = [...state.formFields];
        updateSelectedField(state);
        return {...state}
    },

    [DesignerActions.Type.REMOVE_FIELD]: (state, action) => {
        const {idx, fieldId, groupId, type} = action.payload;

        console.log('DesignerActions.REMOVE_FIELD ',type && (' type: ' + type) || '', fieldId && ('fieldId: ' + fieldId) || '', groupId && ('groupId: ' + groupId) || '');

        if (fieldId) {
            state.formFields = state.formFields.filter(f => f.id !== fieldId);
        }

        if (type && groupId) {
            state.formFields = state.formFields.filter(f => !f.properties || f.properties[type] !== groupId);

            if( state.selectedItem && state.selectedItem.id === groupId) {
                state.selectedItem = null;
            }
        }

        updateSelectedField(state);
        return {...state}
    },

    [DesignerActions.Type.CHANGE_FIELD_IDX]: (state, action) => {
        const {oldIdx, newIdx} = action.payload
        return {...state}
    },

    [DesignerActions.Type.CHANGE_FORM_PROP]: (state, action) => {
        const {name, description} = action.payload
        let changed = name || description
        if (name) {
            state.name = name
        }
        if (description) {
            state.description = description;
        }
        if (changed) {
            return {...state}
        } else {
            return state
        }
    },

    [DesignerActions.Type.CHANGE_FIELD_PROP]: (state, action) => {
        const {idx} = action.payload
        const field = _.omit(action.payload, ['idx'])
        const currentField = state.formFields[idx];
        const updatedField = {...currentField, ...field}
        state.formFields[idx] = updatedField

        state.selectedField = {...updatedField, idx}
        return {...state}
    },

    [DesignerActions.Type.RESET]: (state,action)=>{
        const newState = action.payload
        return newState || {
            name: "",
            description:"",
            formFields: []
        };
    }
},{});
