import { handleActions } from 'redux-actions';
import {CheckActions} from '../actions/checks';
import {ICheck} from "../../common/FormContainerBase";

/**
 * Checks related actions
 */
export const selectedCheckReducer = handleActions<ICheck | null, any>(
	{
		[CheckActions.Type.SET_SELECTED_CHECK]: (state, action) => {
			// toggle selected check
			const existingSelectedCheck = !!(state && state.uuid === action.payload.uuid);
			if (existingSelectedCheck) {
				return null
			}
			return action.payload
		}
	},
	{} as any
);