import {handleActions} from 'redux-actions';
import {AnnotatorActions} from "../actions/annotator.actions";
import {RootState} from "./root.state";


export const annotatorReducer = handleActions({
    [AnnotatorActions.Type.INIT_ANNOTATIONS]: (state:RootState['annotator'], action) => {
        const {annotations,edits} = action.payload
        state.annotations = annotations ?? {};
        state.edits = edits ?? {};
        return {...state};
    },

    [AnnotatorActions.Type.SHOW_ANNOTATOR_POPUP]: (state:RootState['annotator'], action) => {
        const {fieldId,mode} = action.payload
        state.annotatorPopup = {fieldId,mode};

        return {...state};
    },

    [AnnotatorActions.Type.CLOSE_ANNOTATOR_POPUP]: (state:RootState['annotator'], action) => {
        const {fieldId, value,mode} = action.payload
        if (mode == 'edit') {
            if (value !== undefined) {
                state.edits[fieldId] = value;
            }
        }else if (mode=='annotate') {
            if (value !== undefined) { //explicit null  clears the value
                state.annotations[fieldId] = value;
            }
        }
        state.annotatorPopup = null;
        return {...state}
    },

    [AnnotatorActions.Type.CLEAR_ANNOTATION]: (state:RootState['annotator'], action) => {
        const {fieldId,mode} = action.payload
        if (mode == 'edit') {
            delete state.edits[fieldId];
        }else if (mode=='annotate') {
            delete state.annotations[fieldId];
        }
        state.annotatorPopup = null;

        return {...state}
    }
},{annotatorPopup:null,annotations:{},edits:{}});
