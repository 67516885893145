import {createContext, useContext} from "react";
import {FormReference} from "./generic_component_base";
import {SancusConditionEvaluator} from "./conditionEvaluator.service";
import {IntlShape} from "react-intl";

//@ts-ignore
export const FormContainerContext = createContext<{
    tenantId?:string,
    processDefKey:string,

    processInstanceId:string,
    taskId?:string,

    formDefId:string,
    formReference:FormReference,
    contextEvaluator:SancusConditionEvaluator,
    formExtensions:{[key:string]:string},

    raiseError:(errorCode:string,errorName?:string,data?:{[key:string]:any})=>void,
    raiseAction:(action:string, data?:{[key:string]:any})=>void,

    showStepper(show:boolean | null):void,
    intl:IntlShape
}>();

export const useFormContainerContext = ()=>useContext(FormContainerContext);

