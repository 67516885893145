import * as React from "react";
import * as ReactDOM from 'react-dom';
import {MutableRefObject, useEffect, useRef, useState} from "react";
import {
    CustomFileFieldProps,
    File, FileProps,
    HandleDeleteSignature,
    HandleOpenSignature,
    helperTextFromValidationAndAnnotation
} from "./generic_component";
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    Typography,
    useTheme
} from "@material-ui/core";
import formValidationUtil from "sancus-client-common/dist/utils/formValidation.util";
import {useFormContext} from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import {useIntl} from "react-intl";
import {FieldValidationCallbacks} from "sancus-client-common/dist/common/generic_component_base";



type DownloadAnchorProps = {
    handleOpen: HandleOpenSignature,
    file: File
    autoDownload?: boolean,
    elemRef: MutableRefObject<HTMLAnchorElement | null>

}
const DownloadAnchor = (props:DownloadAnchorProps) => {
    const {handleOpen,file,elemRef} = props;
    const [href,setHref] = useState("#");
    const [download,setDownload] = useState<string | undefined>(undefined);


    useEffect(()=>{
        if (props.autoDownload) {
            if (!href.endsWith("#") && elemRef.current) {
                console.log("Clicking", elemRef.current)
                elemRef.current!.click();
            }
        }
    },[href,elemRef.current])

    return <a
        href={href}
        ref={elemRef}
        download={download}
        onClick={async event=>{

            const elem:HTMLElement = event.target as HTMLElement;
            const anchor = elem.closest('a');
            const href = anchor!.href;
            if (href.endsWith("#")) { // e.g. 'https://local.norbloc.com/#'
                event.preventDefault();
                event.stopPropagation();
                const d_url  = await handleOpen(event,file, false);
                if (typeof d_url == 'string') {
                    ReactDOM.unstable_batchedUpdates(()=> {
                        setDownload(file.name);
                        setHref(d_url);
                    });
                }
            }
        }}><Typography variant={"body2"}>
        {file.name}

    </Typography></a>
}


const CustomFileSimpleButton = (props: FileProps<HTMLAnchorElement>) => {
    const intl = useIntl();
    const {id,maxFiles,required,label,files,readOnly,elemRef,fileNameFromValue,handleOpen,handleDeleteFile,fieldValidationCallbacks,_triggerParentOnChange,fv_value} = props;

    const inputElemRef = useRef<HTMLInputElement | null>(null);
    const supportsMultiple:boolean | undefined = maxFiles > 1;

    const methods = useFormContext();
    const { errors,control } = methods;

    const {path} = fv_value

    const hasOwnErrors = formValidationUtil(intl).hasFieldError(path, errors,methods.formState)
    const ownErrors = formValidationUtil(intl).getFieldErrorMessage(path, errors)
    const helperText = helperTextFromValidationAndAnnotation(null,props)

    if (!id){
        throw "cannot render controlled object without id";
    }

    const theme=useTheme();

    return <>
        <input key={"hidden_input"}
            ref={inputElemRef}
            style={{display:"none"}}
            type="file"
            multiple={supportsMultiple}

            onChange={async (evt)=>{
                const _files = evt.target.files;
                const newFiles:File[] = files ?? [];
                if (_files){
                    for (let i=0;i<_files.length;i++){
                        const file = _files.item(i);
                        newFiles.push(file as unknown as File);
                    }
                }
                inputElemRef.current!.value = '';
                await _triggerParentOnChange(newFiles)
            }}
        />
        <Grid container direction={"column"} wrap={"nowrap"}
              className={['simple_file_container',readOnly? 'Mui-disabled':''].join(' ')}
              onBlur={evt=>{
                  fieldValidationCallbacks.onBlur(evt)
              }}
              key={"simple_file_container"}
        >

            <Grid  key={'simple_file_label'} item className={'simple_file_label'} style={{
                display:"flex",
                flexDirection:"column",  //In order to center the label on the button
                justifyContent:"space-around"
            }} >
                {/*<Typography variant={"subtitle1"} ><b>{label}</b></Typography>*/}
                <Typography variant={"body1"} ><b>{label} {props.required && <span aria-hidden="true"
                                                                                    className="client-MuiFormLabel-asterisk client-MuiInputLabel-asterisk">*</span>}</b></Typography>
            </Grid>

            {
                helperText
                    ? <FormHelperText>
                        {helperText}
                    </FormHelperText>
                    : null
            }

            {files?.map((file,index) =><Grid item key={'file_'+file.name}>
                <Grid container direction={"row"} key={file.name} wrap={"nowrap"}>
                    <Grid item className={'simple_file_item'} style={{
                        verticalAlign:"bottom",
                        display:"flex",
                        flexDirection:"column",  //In order to center the label on the button
                        justifyContent:"space-around"
                    }} key={"name"}>
                        <DownloadAnchor file={file} handleOpen={handleOpen} elemRef={elemRef}/>
                    </Grid>
                    {!readOnly && <Grid item key={"delete_icon"}>
                        <a href={"#"} onClick={evt=>{
                            evt.preventDefault();
                            handleDeleteFile(file,index)
                        }}><CloseIcon style={{
                            transform: "translate(0px, 0px) scale(0.8)",
                        }}/></a>
                    </Grid> || <></>}
                </Grid>
            </Grid>
            )}
            {!readOnly && (files?.length ?? 0)  <maxFiles && <Grid item className={'simple_file_upload'} key={"file_upload"}>
                <Button

                    ref={elemRef}
                    variant={"outlined"}
                    color={"primary"}
                    className={'simple_file_upload_button'}
                    style={{
                        marginTop:theme.spacing(2)
                    }}

                    onClick={evt=>{
                        evt.preventDefault();
                        inputElemRef.current!.click();
                    }}

                > Upload file</Button></Grid> || <></>}
        </Grid>
        {
            hasOwnErrors
                ? <FormHelperText error={hasOwnErrors}>
                    {ownErrors}
                </FormHelperText>
                :null
        }

    </>
}

export default CustomFileSimpleButton;
