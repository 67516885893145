import contextPath from "./contextPath";
import {SancusConditionEvaluator} from "./conditionEvaluator.service";
import {DataTypes} from "../CustomerData";
import {Option} from "./react_components_base";
export type ProcessDefinition = {
    id:string,
    name:string,
    description:string,
    key:string,

    extensions?:{[key:string]:string}
    //and many more,

}
type Process = {
    id:string, // 	The id of the process instance.
    definitionId:string,// 	The id of the process definition.
    businessKey?:string, // 	The business key of the process instance.
    caseInstanceId?:string, // 	String 	The case instance id of the process instance.
    tenantId?:string //	The tenant id of the process instance.
    ended:boolean ,//	A flag indicating whether the instance is still running or not.
    suspended:boolean, // 	A flag indicating whether the instance is suspended or not.
    links: {
        method: "GET" | "POST"
        href: string,
        rel: string
    }, // 	A JSON array containing links to interact with the instance.
}

export type Variable = {
    value:string,
    type?:'boolean'|'bytes'|'short'|'integer'|'long'|'double'|'date'|'string'|'null'|'file'|'json'|'xml'|'object' ,
    label?:string, //Not there actually
    valueInfo?: ValueInfo,
} & { //Custom additions
    required: boolean;
    readonly:boolean
    repeatable: boolean;
}

export type Variables = {[key:string]: Variable
}

export type FormPropertyValidationConstraintName = 'required' | 'minlength' | 'maxlength' | 'min' | 'max' | 'readonly' | 'pending' | 'pattern' | 'email';

export interface FormPropertyValidationConstraint {
    name: FormPropertyValidationConstraintName,
    configuration?:"string"
}

type FormValueType = {

    name:string,
    javaType?:string,
    primitiveValueType?:boolean,
    abstract?:boolean,
    parent?:FormValueType

}

export type FormProperty<PType=string> = {
    id:string,
    label?:string,
    type:{name:string},
    typeName:string,
    defaultValue:any;
    value:{
        objectType?: string; //When camunda sends back java objects this is the Java Class
        objectTypeName?: string; //Similar to above?
        value:any,
        serializationDataFormat?:string
        deserialized?:boolean
        valueSerialized?:string
        type:FormValueType
        transient:boolean
    } | null,
    validationConstraints: FormPropertyValidationConstraint[],
    properties:{
        [key:string]:PType
    },
    businessKey?:boolean
}

export type FormField<T=string> = FormProperty<T>

export type FormData = {
    formKey?:string,
    deploymentId:string,
    formProperties?:FormProperty[],
    formFields:FormField[]
}

type TaskExtensionKey = string

export type Task = {
    id:string,
    name:string,
    description?:string,
    owner:string,
    priority:number
    assignee:string,

    processDefinitionId:string,
    processInstanceId:string,
    executionId: string,


    taskDefinitionKey: string
    // and many more
    extensions?:{[key:string]:string}
}

export type TaskFormData = FormData & {task:Task}

export type StartFormData = FormData & { processDefinition:ProcessDefinition }

//const taskService = new camClient.resource('task');


export const  withErrorHandler = async <T>(response:Response) => {
    const status = response.status;
    const bodyType = response.headers.get('Content-Type');
    let data;
    if(bodyType && bodyType.indexOf('application/json') >= 0){
        try{
            data = await response.json();
        }catch (_e){
            data = (_e as Error).message;
        }
    }else{
        data = await response.text();
    }

    const errorObj:undefined | {status:number,data:any,text?:string,message?:string} = status > 300 && {status,data} || undefined
    if (errorObj &&typeof data === 'string') {
        errorObj!.text = data;
    }
    if (response.status === 401 || response.status === 403){
        errorObj!.message = 'SE:Unauthorized'
        throw errorObj;
    } else if (response.status >= 300){
        throw errorObj;
    } else {
        return data as T ;
    }
}

const getFormDataAPI = async function(taskId,allVarsMode?:'readonly'|'readwrite'):Promise<TaskFormData>{
    const response:Response = await fetch(`${contextPath}api/tasks/${taskId}/formData`,{
        method:'GET',
        credentials: 'include',
    });

    return await withErrorHandler<TaskFormData>(response);
}

export type ValueInfo = any;
export type FormInfo = {key:string,contextPath?:string}
export type FieldValue = {value:string,type:string,valueInfo:ValueInfo}
export type FieldValueMap = {[fieldName:string]:FieldValue}


export async function getStartForm(idr:'id'|'key',processDefinitionIdOrKey:string,resume?:boolean):Promise<FormData>{

    let endpoint = idr == 'key'?`key/${processDefinitionIdOrKey}`:`id/${processDefinitionIdOrKey}`;

    endpoint = `${contextPath}api/process/${endpoint}/formData`

    if (resume !=null){
        endpoint = endpoint + `?resumeState=${resume}`
    }

    const response: Response = await fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
    });

    return await withErrorHandler<StartFormData>(response)

}

export async function getTaskForm(taskId: string):Promise<TaskFormData> {
    const taskFormData = await getFormDataAPI(taskId);
    return taskFormData;

}

export async function loadTasks(processInstanceId):Promise<Task[]> {
    const rsp = await fetch(`${contextPath}api/instance/${processInstanceId}/tasks`,{
        method:'GET',
        credentials: 'include',
    })

    return await withErrorHandler<Task[]>(rsp);
}

export function createFieldFromPaletteElement(id, paletteElement){
    const field: any = {
        id: id,
        type: {name: paletteElement.type},
        typeName: paletteElement.type, //This one is prevalent to the others
        value: {
            value: null,
            type: {
                name: paletteElement.type
            },
            transient: false
        },
        validationConstraints: [],
        properties: {}
    }

    if (paletteElement.component) {
        field.properties!.component = paletteElement.component;
    }

    if (paletteElement.defaultProperties) {
        Object.keys(paletteElement.defaultProperties).forEach(key =>  field.properties![key] = paletteElement.defaultProperties[key]);
    }

    if (paletteElement.defaultValidationConstraints && paletteElement.defaultValidationConstraints.length) {
        field.validationConstraints = paletteElement.defaultValidationConstraints;
    }

    return field;
}

export type ProcessInstance = {
    businessKey?: string,
    caseInstanceId?: string,
    definitionId: string,
    ended: boolean,
    id: string,
    links: any[],
    suspended:boolean,
    tenantId?:string
}


export type PIEV = { executionId?:string,id: string, name: string,type:string, documentation: string, attributes: { [key: string]: string } }

export type ProcessInstanceStatus = {
    processInstanceId:string,
    processDefinitionId:string,
    processDefinitionKey:string,
    processDefinitionName:string,
    state: "STATE_ACTIVE" | "STATE_SUSPENDED" | "STATE_COMPLETED" | "STATE_EXTERNALLY_TERMINATED" | "STATE_INTERNALLY_TERMINATED"
            |"ACTIVE" | "SUSPENDED" | "COMPLETED" | "EXTERNALLY_TERMINATED" | "INTERNALLY_TERMINATED";
    endStates?:PIEV[]
    activeStates?:PIEV[]
}

export function isStateEnded(state:ProcessInstanceStatus['state']){
    return (state == 'STATE_COMPLETED' || state == 'COMPLETED' || state == 'STATE_EXTERNALLY_TERMINATED' || state == 'STATE_INTERNALLY_TERMINATED' ||state == 'EXTERNALLY_TERMINATED' || state == 'INTERNALLY_TERMINATED' )
}

export async function getRenderedTaskForm(taskId:string){
    const response:Response = await fetch(`${contextPath}api/tasks/${taskId}/renderedForm`,{
        method:'GET',
        credentials:'include'
    });
    const data = await response.text();
    if (response.status >= 300){
        console.error(`In getRenderedTaskForm got status ${response.status}`,data)
        throw {status:response.status,data:data};
    }else {
        return data;
    }
}

export async function getRenderedStartForm(processDefinitionId:string){
    const response:Response = await fetch(`${contextPath}api/process/id/${processDefinitionId}/renderedForm`,{
        method:'GET',
        credentials:'include'
    });
    return withErrorHandler(response);
}

export async function getEmbeddedFormContent(formKey:string){
    const url = formKey.replace('embedded:app:/', contextPath.contextPath); //If contextPath is null assume root
    const formHTMLRsp = await fetch(url,{
        method:'GET',
        credentials: 'include',
    });
    return withErrorHandler<string>(formHTMLRsp);
}

