import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useResolvedPath, NavLink, useParams, useLocation} from 'react-router-dom'
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    tab: {
        minHeight: '60px'
    }
}));

export default function NavTabs() {
    const {LoggedUserState, setUserLoggedIn, setUserLoggedOut} = useContext(LoggedUserContext);
    const classes = useStyles();

    const params = useParams()
    const location = useLocation();

    const {pathname} = location;
    const matchTask = pathname.indexOf("/tasks") === 0;

    const matchDashboard =pathname.indexOf("/dashboard") === 0;

    const tabIndex = matchTask ? 1 : (matchDashboard ? 0 : 2);
    const [value, setValue] = React.useState(tabIndex);

    console.debug(`NavTabs: stateValue: ${value}  TabIndex: ${tabIndex}`);

    useEffect(() => {
        if (value !== tabIndex) {
            console.debug(`NavTabs useEffect routeChange stateValue:  ${value} Must be TabIndex: ${tabIndex} before setState`);
            setValue(tabIndex);
        }
    }, [tabIndex]);

    const {su,admin,officer,manager} = LoggedUserState.user;

    return (
        <div className={classes.root}>
            <Tabs value={value} aria-label="simple tabs example" indicatorColor="primary" textColor="primary">

                {(su || officer || manager) && <Tab label="Dashboard" {...a11yProps(0)} className={classes.tab}
                     component={NavLink}
                     to="/dashboard"
                     activestyle={{
                         //fontWeight: "bold",
                     }}/>}

                {(su || officer || manager) &&  <Tab label="Tasks" {...a11yProps(1)} className={classes.tab}
                     component={NavLink}
                     to="/tasks"
                /> }

                <Tab label="Services" {...a11yProps(2)} className={classes.tab}
                     component={NavLink}
                     to="/run_journeys"
                />
            </Tabs>
        </div>
    );
}
