import { handleActions } from 'redux-actions';
import {AuditTasksActions} from '../actions/auditTasks.actions';

/**
 * auditTasks related actions
 */
export const auditTaskReviewReducer = handleActions<any[], any>(
	{
		[AuditTasksActions.Type.SET_TASK_FOR_REVIEW]: (state, action) => {
			return action.payload;
		}
	},
	{} as any
);
