import ElementBuilder from './utils'

const elementBuilder = ElementBuilder
    .forService('Send Email',ElementBuilder.idPrefix+'SendEmail','#{sendEmail}')
    .addIcon("https://raw.githubusercontent.com/twbs/icons/main/icons/envelope.svg")
    .addInputParam("subject","Subject","Subject of email")
    .addInputParam("contentText","Text content","Text content of email")
    .addInputParam("recipientEmail","Email of recipient","Email address of recipient")
    .addInputParam("senderEmail", "Email of sender (Optional)", "If not set, default used")

export default elementBuilder.element
