import {createTheme} from '@material-ui/core/styles';
import {contextPath, contextBaseUri,publicURL} from "./contextPath";


const defaultThemeData = {
    palette: {
        primary: {
            main: '#26a69a'
        },
        secondary: {
            main: 'rgba(38,166,154,0.1)'
        },

        "text": {
            "secondary": "#133662"
        },
        "background": {
            "default": "#ffffff"
        }
    }
}


const theme_presets = (theme)=>{
    
    const primary_main = theme?.palette?.primary?.main ?? defaultThemeData.palette.primary.main;
    const secondary_main = theme?.palette?.secondary?.main ?? defaultThemeData.palette.secondary.main;
    
    const textTransform = "none";
    return {
        "customProps": {
            "sancus-variant": "client",
        },
        "custom": {
            "dashboard": [
                {
                    "label": "blue",
                    "main": "#0E56AF",
                    "shades": ["#0e56af", "#2666B7", "#3E77BF", "#5688C7", "#6E99CF", "#86AAD7", "#9EBBDF", "#B6CCE7", "#CEDDEF"]
                },
                {
                    "label": "darkblue",
                    "main": "#133662",
                    "shades": ["#133662", "#2A4A71", "#425E81", "#597291", "#7186A0", "#899AB0", "#A0AEC0", "#B8C2CF", "#CFD6DF"]
                },
                {
                    "label": "lightBlue",
                    "main": "#bae6fe",
                    "shades": ["#bae6fe", "#C0E8FE", "#C7EBFE", "#CEEDFE", "#D5F0FE", "#DCF2FE", "#E3F5FE", "#EAF7FE", "#F1FAFE"]
                },
                {
                    "label": "red",
                    "main": "#FF183C",
                    "shades": ["#ff183c", "#FF2F4F", "#FF4663", "#FF5D76", "#FF748A", "#FF8B9D", "#FFA2B1", "#FFB9C4", "#FFD0D8"]
                },
                {
                    "label": "warnBlue",
                    "main": "#183C67",
                    "shades": ["#183c67", "#2F4F76", "#466385", "#5D7694", "#748AA3", "#8B9DB2", "#A2B1C2", "#B9C4D1", "#D0D8E0"]
                }
            ]
        },
        "typography": {
            "fontFamily": "Metropolis"
        },
        "overrides": {
            "MuiCssBaseline": {
                "@global": {
                    /*
                    //Definition of Metropolis font moved in metropolis.css
                    "@font-face": [ {
                        "fontFamily": "MetropolisBold",
                        "src": `url(${publicURL}fonts/Metropolis-Bold.woff2) format("woff2"), url(${publicURL}fonts/Metropolis-Bold.woff) format("woff"), url(${publicURL}fonts/Metropolis-Bold.ttf) format("truetype")`
                    } ]
                    */
                }
            },
            "MuiTabs": {
                "indicator": {
                    "height": 0
                }
            },
            "MuiTab": {
                "root": {
                    "&[class*=\"selected\"]": {
                        "background": secondary_main ,
                    }
                }
            },
            "MuiButton": {
                "root": {
                    "textTransform": "capitalize",
                    "boxShadow":  "none"
                },
                "contained": {
                    "textTransform": "capitalize",
                    "boxShadow":  "none"
                }
            },

            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: primary_main,
                    },
                },
            },

            MuiRadio: {
                colorSecondary: {
                    '&$checked': {
                        color: primary_main,
                    },
                },
            },

            "MuiPaper": {
                "outlined": {
                    "border": "none",
                    "boxShadow": "0px 6px 42px 0px rgb(0 0 0 / 10%)",
                    "borderRadius": "9px"
                }
            },
            "MuiFilledInput": {
                "root": {
                    "overflow": "hidden",
                    "backgroundColor": "transparent",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "borderRadius": "5px",
                    "&:hover": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "&$focused": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "margin": "27px 0px 0px",
                        "padding": "8px 16px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "& [class*=\"MuiFilledInput-input\"]:focus": {
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiSelect-select\"]:focus": {
                        "borderRadius": "inherit",
                        "backgroundColor": "inherit"
                    },
                    "& [class*=\"MuiSelect-icon\"]": {
                        "top": "calc(50%)"
                    },
                    "&[class*=\"MuiFilledInput-adornedEnd\"]":{
                        //no empty space is intentional - we want the input element to not have padding
                        "paddingRight": "0px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"]": {
                        "position": "absolute",
                        "right": "0px",
                        "bottom": "18px",
                        "paddingRight": "12px",
                        "paddingLeft": "12px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"] [class*=\"MuiIconButton\"]": {
                        "padding": "3px"
                    }

                },
                "focused": {}
            },
            "MuiAvatar":{
                "root":{
                    "color": "#ffffff",
                },
                "colorDefault":{
                    "color": "#ffffff"
                }
            },
            "MuiAutocomplete": {
                "inputRoot": {
                    "&[class*=\"MuiFilledInput\"]": {
                        "padding": "0px",
                        "paddingRight": "0px",
                        "marginTop": "27px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "&[class*=\"MuiFilledInput\"]:before": {
                        "border": "none"
                    },
                    "&[class*=\"MuiFilledInput\"]:after": {
                        "border": "none"
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "margin": "0px",
                        "padding": "8px 16px",
                        "border": "none"
                    }
                }
            },
            "MuiOutlinedInput": {
                "root": {
                    "overflow": "hidden",
                    "backgroundColor": "transparent",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:hover": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "&$focused": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none",
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiOutlinedInput\"]": {
                        "margin": "27px 0px 0px",
                        "padding": "8px 36px 8px 16px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "& [class*=\"MuiOutlinedInput-notchedOutline\"]": {
                        "border": "none"
                    },
                    "& [class*=\"MuiOutlinedInput-input\"]:focus": {
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"]": {
                        "position": "absolute",
                        "right": "0px",
                        "bottom": "18px",
                        "marginLeft": "0px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"] [class*=\"MuiIconButton\"]": {
                        "padding": "3px"
                    }
                },
                "adornedEnd": {
                    "paddingRight": "0px"
                },
                "focused": {}
            },
            "MuiInputLabel": {
                "filled": {
                    "transform": "translate(5px, 5px) scale(1)",
                    "color": "#656566",
                    "fontSize": ".875rem",
                    "lineHeight": "1.15",
                    "textTransform": textTransform,
                    "&[class*=\"MuiInputLabel-shrink\"]": {
                        "color": "#656566",
                        "transform": "translate(5px, 5px) scale(1)"
                    }
                },
                "outlined": {
                    "transform": "translate(5px, 5px) scale(1)",
                    "color": "#656566",
                    "fontSize": ".875rem",
                    "lineHeight": "1.15",
                    "textTransform": textTransform,
                    "&[class*=\"MuiInputLabel-shrink\"]": {
                        "color": "#656566",
                        "transform": "translate(5px, 5px) scale(1)"
                    }
                }
            },
            "MuiFormHelperText": {
                "contained": {
                    "marginLeft": "5px",
                    "marginRight": "5px"
                }
            },
            "MuiFormControl": {
                "marginNormal": {
                    "marginTop": "0px",
                    "marginBottom": "15px"
                }
            },
            "MuiDropzoneArea": {
                "root": {
                    "border": "1px solid",
                    "borderColor": "#ccc",
                    "overflow": "hidden",
                    "borderRadius": "4px",
                    "backgroundColor": "#fff",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "minHeight": "160px",
                    "&:hover": {
                        "backgroundColor": "#fff"
                    },
                    "&$focused": {
                        "backgroundColor": "#fff",
                        "borderColor": "#0c6db3"
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "padding": "27px 12px 16px",
                        "opacity": 0
                    },
                    "& [class*=\"MuiDropzoneArea-icon\"]": {
                        "color": "#ccc"
                    }
                },
                "text": {
                    // "fontSize": "1rem",
                    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
                    "lineHeight": "1.5",
                    "letterSpacing": "0.00938em",
                    "color": "rgba(0, 0, 0, 0.54)",
                    "fontWeight": "400"
                }
            },
            "MuiGrid": {
                "root": {
                    "& [id=\"dropZoneHeader\"]": {
                        "marginLeft": "0px"
                    },
                    "& [id=\"dropZoneHeader\"] p": {
                        "color": "#656566",
                        "zIndex": 1,
                        "fontSize": ".875rem",
                        "transform": "translate(5px, 5px) scale(1)",
                        "lineHeight": "1.15",
                        "pointerEvents": "none",
                        "textTransform": textTransform
                    },
                    "& [data-repeatable=\"true\"] div[data-idx-in-group=\"0\"] ": {
                        "position": "relative"
                    },
                    //"& [data-repeatable=\"true\"] >  div > div > div > div:before": {
                    "& [data-repeatable=\"true\"] div[data-idx-in-group=\"0\"]:before": {
                        "position": "absolute",
                        "top": "30px",
                        "left": "-32px",
                        "width": "21px",
                        "content": "\"\"",
                        "display": "inline-block",
                        "transform": "translateY(-1rem)",
                        "borderTop": "3px solid #BBE7FF"
                    },

                    "&[id=\"service\"] [class*=\"section\"] > div": {
                        "padding": "0"
                    }
                }
            }
        },
        ...theme
    }
}

const defaultTheme = createTheme(theme_presets(defaultThemeData));
async function themePromiseFN(themeName){
    try {
        const theme_rsp = await fetch(`${publicURL}themes/theme_${themeName}.json`)
        const theme_json = await theme_rsp.json();
        const theme = createTheme(theme_presets(theme_json));

        if (theme_json.customProps){
            Object.defineProperty(theme,'customProps',theme_json.customProps);
        }

        return theme;

    }catch (e){
        console.error("While fetching theme got",e);
        return defaultTheme;
    }

}

export default themePromiseFN;





