import React, {useContext, useEffect, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {blackButtonStyles} from "../variables";
import {makeStyles} from '@material-ui/core/styles';
import {TablePaginationActions} from "./UserManagement";
import workflowManagementServiceFactory from "../services/workflowManagement.service";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField/TextField";
import {AlertContext} from "../context/alert/alertContext";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {DropzoneDialog} from 'material-ui-dropzone'
import ReactBpmn from "../components/bpmn/ReactBpmn";
import SearchBar from "material-ui-search-bar";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "@material-ui/core";

function parseXmlToXmlDom(xml) {
    let xmlDoc;

    if (window.DOMParser) {
        const parser = new DOMParser();
        xmlDoc = parser.parseFromString(xml, "text/xml");

        // Internet Explorer
    } else {
        /* global ActiveXObject */
        xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async = false;
        xmlDoc.loadXML(xml);
    }

    return xmlDoc;
}

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        buttonContainer: blackButtonStyles,
    }
});

const workflowManagementService = workflowManagementServiceFactory();

const CreateWorkflowDialog = ({openCreateDialog, setOpenCreateDialog, onCreateWorkflow, fetchAllWorkflows}) => {
    const { showAlert } = useContext(AlertContext);
    useEffect(() => {
        //clear if dialog is not visible.
        if(!openCreateDialog) {

        }
    }, [openCreateDialog]);

    return  <DropzoneDialog
        open={openCreateDialog}
        onSave={async files=>{
            onCreateWorkflow(files)
                .then(
                    () => showAlert("Upload successful", "success"),
                    err => {
                        console.error(err);
                        showAlert(`Unexpected error`, 'error')
                    }
                );

            setTimeout(() => {
                fetchAllWorkflows();
            }, 1000);

            setOpenCreateDialog(false);
        }}
        acceptedFiles={['.bpmn','.dmn']}
        showPreviews={true}
        showFileNames={true}
        filesLimit={10}
        showFileNamesInPreview={true}
        showAlerts={false}
        useChipsForPreview={true}
        // maxFileSize={5000000}
        onClose={()=>setOpenCreateDialog(false)}
    />
};

const EditWorkflowDialog = ({workflow, openDialog, closeDialog, onEditWorkflow}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ name, setName ] = useState(workflow.name);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await workflowManagementService.editWorkflow({
                id: workflow.id,
                name,
            });
            if(result) {
                onEditWorkflow(result);
                //closeDialog();
                showAlert(`workflow ${result.name} edited successfully`, 'success');
            }
            else {
                showAlert(`Could not edit`, 'error');
            }
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error`, 'error');
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Edit workflow ${workflow.name}`}</DialogTitle>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <TextField fullWidth variant="outlined"
                                       margin={"normal"}
                                       label={"Workflow id"}
                                       required={true}
                                       value={workflow.id}
                                       disabled={true}
                            />
                            <TextField fullWidth variant="outlined" margin={"normal"}
                                       label={"Workflow name"}
                                       required={true}
                                       value={name}
                                       onChange={(evt) => {
                                           setName(evt.target.value);
                                       }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={() => {
                            closeDialog();
                        }} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                        <Button variant="contained" color="primary" autoFocus type="submit">
                            Edit Workflow
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const DeleteWorkflowDialog = ({workflow, openDialog, closeDialog, onDeleteWorkflow}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ cascadeDel, setCascadeDel ] = useState(false);


    const handleSubmit = async () => {
        try {
            await workflowManagementService.deleteWorkflow({
                key: workflow.key,
                cascade: cascadeDel,
            });

            // if we here then we got 200
            onDeleteWorkflow(workflow.id);
            setCascadeDel(false);
            showAlert(`workflow ${workflow.key} deleted successfully`, 'success');
        }
        catch (e) {
            console.error(e);

            if (e.response && e.response.status === 409) {
                showAlert(e, 'error');
            } else {
                showAlert(`Unexpected error`, 'error');
            }
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Delete workflow ${workflow.key}`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deleting a workflow cannot be undone!
                </DialogContentText>


                <Grid container direction={'row'}>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox value={cascadeDel}
                                          color="primary"
                                          onChange={(evt) => {
                                              setCascadeDel(evt.target.checked);
                                          }}
                                />
                            }
                            label="Cascade"
                        />

                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Box pr={1}>
                <Button onClick={() => {
                    closeDialog();
                }} color="primary">
                    Cancel
                </Button>
                </Box>
                <Box p={2}>
                <Button variant="contained" color="primary" autoFocus onClick={handleSubmit}>
                    Delete Workflow
                </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const WorkflowManagement = (props) => {
    const params = useParams()
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);
    const history = useNavigate();
    const routerWorkflowId = params.workflowId || '';

    const pageSizeOptions = [5, 10, 25, {label: 'All', value: -1}];
    const [ pageSize, setPageSize ] = useState(pageSizeOptions[1]);
    const [ page, setPage ] = useState(0);
    const [ searched, setSearched ] = useState("");
    const [ itemsCount, setItemsCount ] = useState(0);
    const [ workflows, setWorkflows ] = useState([]);

    const fetchAllWorkflows = async () => {
        try {
            const result = await workflowManagementService.fetchWorkflows();
            setWorkflows(result);
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error: could not fetch workflows`, 'error');
        }
    };

    useEffect(() => {
        fetchAllWorkflows();
    }, []);

    useEffect(() => {
        //calculate pagination
        if(workflows.length > 0) {
            setItemsCount(workflows.filter(searchFilter).length);
        }
    }, [workflows, pageSize, searched]);

    const handleChangePage = (evt, page) => {
        console.debug(`set page: ${page}`);
        setPage(page);
    };

    const handlePageSizeChange = (evt) => {
        console.debug(`set page size parsed value: ${evt.target.value}`);
        const numValue = isNaN(parseInt(evt.target.value)) ? pageSizeOptions[0] : parseInt(evt.target.value);
        console.debug(`set page size: ${numValue}`);
        setPageSize(numValue);
    };

    const searchFilter = (workflow, index) => {
        if (!searched) {
            return true;
        }

        const s = searched.toLowerCase();
        return workflow.key?.toLowerCase().includes(s) || workflow.name?.toLowerCase().includes(s) || workflow.description?.includes(s);
    }

    const pageFilter = (workflow, index) => {
        return pageSize < 0
            ? true
            : index < pageSize * (page + 1) && index >= page * pageSize
                ? true
                : false;
    }

    const [ openCreateDialog, setOpenCreateDialog ] = useState(false);

    const onCreateWorkflow = async (files) => {
        const deployment = await workflowManagementService.upload(files);
    };

    const onEditWorkflow = (workflow) => {
        console.debug(`edit workflow:`);
        console.debug(workflow);
        handleCloseEditDialog();
        setTimeout(() => {
            setWorkflows(workflows.map(g => g.id === workflow.id ? ({...workflow}) : g));
        });
    };

    const onDeleteWorkflow = (workflowId) => {
        console.debug(`delete workflow: ${workflowId}`);
        handleCloseDeleteDialog();
        setTimeout(() => {
            setWorkflows(workflows.filter(g => g.id !== workflowId));
        });
        //TODO handle set page if all page items deleted!
    }

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const enteredBPMNRef = useRef(null);

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ editOrDeleteItem, setEditOrDeleteItem ] = useState(null);

    useEffect(() => {
        if(workflows.length > 0 && routerWorkflowId) {
            if (routerWorkflowId === '_new') {
                setOpenEditDialog(true);
            } else {
                const workflow = workflows.find(w => w.key === routerWorkflowId);
                if (workflow) {
                    setEditOrDeleteItem(workflow);

                    workflowManagementService.getWorkflow({key: workflow.key}).then((result) => {
                        enteredBPMNRef.current = result;
                        setOpenEditDialog(true);
                    });
                }
            }
        }
    }, [workflows]);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditOrDeleteItem(null);
        enteredBPMNRef.current = null;
        handleCloseEditMenu();
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        routerWorkflowId && history(`/dashboard/workflow_management/`);

        handleCloseEditMenu();
    }

    const handleCloseEditMenu = () => {
        routerWorkflowId && history(`/dashboard/workflow_management/`);
        setAnchorEl(null);
        setEditOrDeleteItem(null);
        console.debug('clear edit/delete item');
    };

    const handleOpenEditMenuFactory = (workflow) => {
        return (evt) => {
            console.debug('edit delete item:')
            console.debug(JSON.stringify(workflow));
            setEditOrDeleteItem(workflow);
            setAnchorEl(evt.currentTarget);
        };
    };

    return (<>
        <Box p={4}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Box pb={3} pl={2}>
                        <Typography variant={'h6'}>Workflows</Typography>
                    </Box>
                </Grid>

                <Grid item>
                    <Grid className={classes.buttonContainer} container direction="row">
                        <Box p={1} pb={3}>
                            <Button variant="contained" color="primary" onClick={() => {
                                setOpenCreateDialog(true);
                            }}>Upload workflow</Button>
                        </Box>
                        <Box p={1} pb={3}>
                            <Button variant="contained" color="primary" onClick={() => {
                                history(`/dashboard/workflow_management/_new`);
                                enteredBPMNRef.current = null;
                                setEditOrDeleteItem(null);
                                setOpenEditDialog(true);
                            }}>Create workflow</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <SearchBar
                    value={searched}
                    onChange={(searchVal) => {
                        setSearched(searchVal);
                        setPage(0);
                    }}
                    onCancelSearch={() => setSearched("")}
                    style={{marginBottom: 2}}
                />
                <TableContainer component={Paper} elevation={0} square={true} style={{ borderBottom: '0px'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell align={'right'}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                workflows.filter(searchFilter).filter(pageFilter).map((workflow, index) => {
                                    return (
                                        <TableRow key={workflow.key} className={classes.root}>
                                            <TableCell>
                                                <Typography variant={'body1'}>{workflow.key}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>
                                                    <span dangerouslySetInnerHTML={{__html:workflow.name}}/>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>{workflow.version}</Typography>
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <IconButton onClick={handleOpenEditMenuFactory(workflow)}>
                                                    <MoreHorizIcon color="primary"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={pageSizeOptions}
                                    colSpan={4}
                                    count={itemsCount}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handlePageSizeChange}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>

                <CreateWorkflowDialog openCreateDialog={openCreateDialog} setOpenCreateDialog={setOpenCreateDialog}
                                      onCreateWorkflow={onCreateWorkflow} fetchAllWorkflows={fetchAllWorkflows}/>
                {/*{
                editOrDeleteItem && <EditWorkflowDialog workflow={editOrDeleteItem} openDialog={openEditDialog && !!editOrDeleteItem} closeDialog={handleCloseEditDialog} onEditWorkflow={onEditWorkflow}/>
            }*/}
                {
                    editOrDeleteItem && <DeleteWorkflowDialog workflow={editOrDeleteItem}
                                                              openDialog={openDeleteDialog && !!editOrDeleteItem}
                                                              closeDialog={handleCloseDeleteDialog}
                                                              onDeleteWorkflow={onDeleteWorkflow}/>
                }
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleCloseEditMenu}
                >
                    <MenuItem onClick={async () => {
                        await workflowManagementService.downloadWorkflow({key: editOrDeleteItem.key})
                        handleCloseEditMenu();
                    }}>Download</MenuItem>
                    <MenuItem onClick={async () => {
                        enteredBPMNRef.current = await workflowManagementService.getWorkflow({key: editOrDeleteItem.key})
                        history(`/dashboard/workflow_management/${editOrDeleteItem.key}`);

                        setOpenEditDialog(true);
                    }}>Edit</MenuItem>

                    {   // TODO this is "Duplicate" feature which duplicate flow with uploading
                        /*<MenuItem onClick={async () => {
                        try {
                            const xml = await workflowManagementService.getWorkflow({key: editOrDeleteItem.key})

                            const xmlDoc = parseXmlToXmlDom(xml);
                            const processNode = xmlDoc.getElementsByTagName("bpmn:process") || xmlDoc.getElementsByTagName("bpmn2:process");
                            let processId = processNode[0].getAttribute("id");
                            let processName = processNode[0].getAttribute("name");

                            processNode[0].setAttribute("id", processId + "_COPY_" + Date.now().valueOf());
                            processNode[0].setAttribute("name", processName + "_COPY_");

                            processId = processNode[0].getAttribute("id");

                            const oSerializer = new XMLSerializer();
                            const sXML = oSerializer.serializeToString(xmlDoc);
                            const fileName = processId + ".bpmn";
                            const blob = new Blob([sXML], {type: "text/xml"});
                            const file = new File([blob], fileName);
                            const createResponse = await workflowManagementService.upload([file]);
                            const newWorkflow = createResponse && createResponse.deployedProcessDefinitions && createResponse.deployedProcessDefinitions[0] || null;

                            showAlert(`Workflow ${newWorkflow ? '"' + newWorkflow.key + '"' : ''} successfully created`, 'success');
                            setAnchorEl(null);
                            setEditOrDeleteItem(null);

                            fetchAllWorkflows();
                        } catch (e) {
                            console.error(e);

                            if (e.response && e.response.status === 409) {
                                showAlert(e, 'error');
                            } else {
                                showAlert(`Unexpected error`, 'error');
                            }
                        }

                    }}>Duplicate</MenuItem>*/}

                    <MenuItem onClick={async () => {
                        try {
                            const xml = await workflowManagementService.getWorkflow({key: editOrDeleteItem.key})

                            const xmlDoc = parseXmlToXmlDom(xml);
                            const processNode = xmlDoc.getElementsByTagName("bpmn:process")[0] || xmlDoc.getElementsByTagName("bpmn2:process")[0];
                            let processId = processNode.getAttribute("id");
                            let processName = processNode.getAttribute("name");

                            processNode.setAttribute("id", processId + "_COPY_" + Date.now().valueOf());
                            processNode.setAttribute("name", processName + "_COPY_");

                            processId = processNode.getAttribute("id");

                            const oSerializer = new XMLSerializer();
                            const sXML = oSerializer.serializeToString(xmlDoc);

                            enteredBPMNRef.current = sXML;
                            history(`/dashboard/workflow_management/${processId}`);

                            setAnchorEl(null);
                            setEditOrDeleteItem(null);
                            setOpenEditDialog(true);
                        } catch (e) {
                            console.error(e);

                            if (e.response && e.response.status === 409) {
                                showAlert(e, 'error');
                            } else {
                                showAlert(`Unexpected error`, 'error');
                            }
                        }

                    }}>Duplicate</MenuItem>

                    <MenuItem onClick={() => {
                        setOpenDeleteDialog(true);
                    }}>Delete</MenuItem>
                </Menu>
            </Box>


            <Modal
                open={openEditDialog}
                onClose={() => {
                }}
                /*keepMounted={true}
                disablePortal={true}*/
            >
                    <ReactBpmn
                        diagramXML={enteredBPMNRef.current}
                        onHeartbeat={async (changes)=>{

                            await workflowManagementService.heartbeat({
                                key: editOrDeleteItem ? editOrDeleteItem.key : "_new_flow",
                                changes
                            });
                        }}
                        onSave={async (xml) => {
                            try {
                                if (editOrDeleteItem) {
                                    await workflowManagementService.update({
                                        key: editOrDeleteItem.key,
                                        xmlContent: xml
                                    });

                                    showAlert(`workflow ${editOrDeleteItem.key} updated successfully`, 'success');
                                } else {
                                    //get proccess name from xml as file name
                                    const xmlDoc = parseXmlToXmlDom(xml);
                                    const processNode = xmlDoc.getElementsByTagName("bpmn:process")[0] || xmlDoc.getElementsByTagName("bpmn2:process")[0];

                                    const processId = processNode.getAttribute("id");
                                    const fileName = processId ? processId  + '.bpmn' : "newWorkflow.bpmn";
                                    const blob = new Blob([xml], {type: "text/xml"});
                                    const file = new File([blob], fileName);
                                    const createResponse = await workflowManagementService.upload([file],true);
                                    const newWorkflow = createResponse && createResponse.deployedProcessDefinitions && createResponse.deployedProcessDefinitions[0] || null;

                                    showAlert(`Workflow ${newWorkflow ? '"'+ newWorkflow.key + '"' : ''} successfully created`, 'success');

                                    if (newWorkflow) {
                                        history(`/dashboard/workflow_management/` + newWorkflow.key);
                                        setEditOrDeleteItem(newWorkflow);
                                    }
                                }

                                fetchAllWorkflows();
                            }
                            catch (e) {
                                console.error(e);

                                if (e.response && e.response.status === 409) {
                                    showAlert(e, 'error');
                                } else {
                                    showAlert(`Unexpected error`, 'error');
                                }
                            }
                        }}
                        onClose={() => handleCloseEditDialog()}
                    />
            </Modal>

        </>
    );
};

export default WorkflowManagement;
