import React, {useContext, useEffect, useRef, useState} from "react";
import axios from 'axios';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import {AlertContext} from "../context/alert/alertContext";
import {handleErrorLoginFactory, handleSuccessLoginResponseFactory} from "./SignIn";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {contextPath} from "../contextPath";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    logo: {
        "boxShadow": "0 6px 42px 0 rgb(0 0 0 / 20%)",
        "borderRadius": "100px"
    }
}));

export const TFALogin = (props) => {
    const classes = useStyles();
    const [code, setCode] = useState('');
    const {setUserLoggedIn} = useContext(LoggedUserContext);
    const {showAlert} = useContext(AlertContext);
    const loginUrl = `${contextPath}api/users/login`;
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();
    const state  = location.state || params;
    const userId = state && state.userId || '';


    const handleSubmit = (e) => {
        try {
            axios({
                method: 'post',
                url: loginUrl,
                data: {
                    tfaCode: code
                }
            })
                .then(
                    handleSuccessLoginResponseFactory(setUserLoggedIn, showAlert),
                    handleErrorLoginFactory(showAlert, history, userId),
                );
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <Container component="div" maxWidth="xs">
            <div className={classes.paper}>
                <img className={classes.logo} src={'https://norbloc.com/wp-content/uploads/2021/03/sancus@3x-1.png'} height={60}></img>
                <Box p={2}>
                    <Typography variant="h5">
                        Two-factor authentication
                    </Typography>
                </Box>
                <Typography variant="subtitle2">
                    Enter your two-factor authentication code.
                </Typography>
                <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        value={code}
                        variant="outlined"
                        margin="normal"
                        id="code"
                        label="Two-factor authentication code"
                        autoFocus
                        fullWidth
                        onChange={(evt) => {
                            setCode(evt.target.value);
                        }}
                    />
                    <Button fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Container>
    );
};