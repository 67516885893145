import ElementBuilder from './utils'

const elementBuilder = ElementBuilder
    .forCalledActivity("Risk Calculator","com.norbloc.sancus.integrations.RiskCalculator","risk_calculator")
    .addIcon('https://raw.githubusercontent.com/twbs/icons/main/icons/calculator-fill.svg')
    // .entriesVisible('_all','sancus.widget.step','executionListeners','properties')
    .allEntriesVisible()
    .addInMappingAll()

    .addInMappingAndParam("missing_CorIndv_score","Missing CorIndv Score",null,"10")
    .addInMappingAndParam("missing_CorFATCA_score","Missing CorFATCA Score",null,"10")
    .addInMappingAndParam("missing_CorCD_score","Missing CorCD Score",null,"10")
    .addInMappingAndParam("corcd_pep_flag_name","Variable name for the CorCD pep flag",null,"pep_flag")

    .addInMappingAndParam("default_CorIndv_counrty","Default Nationality when missing for Individuals",null)
    .addInMappingAndParam("default_CorFATCA_counrty","Default Country when missing for FATCA",null)
    .addOutMapping("risk_by_country","Risk Score")
    .addOutMapping("checks","Checks",true)

export default elementBuilder.element