import {createTheme, Theme, ThemeOptions} from "@material-ui/core/styles";
import {publicURL} from "sancus-client-common/dist/common/contextPath";


const defaultThemeData = {
    palette: {
        primary: {
            main: '#26a69a'
        },
        secondary: {
            main: 'rgba(38,166,154,0.1)'
        },

        "text": {
            "secondary": "#133662"
        },
        "background": {
            "default": "#ffffff"
        }
    }
}


const theme_presets = (theme) => {
    const textTransform = "none";
    const primary_main = theme?.palette?.primary?.main ?? defaultThemeData.palette.primary.main;
    const secondary_main = theme?.palette?.secondary?.main ?? defaultThemeData.palette.secondary.main;
    return {

        "customProps": {
            "sancus-variant": "client",
            "logoUrl": "https://storage.googleapis.com/norbloc_public/images/BankL.png"
        },
        "shadows": ["none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none",
            "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none"],
        "typography": {
            "fontFamily": "Metropolis",
        },
        "overrides": {
            "MuiCssBaseline": {
                "@global": {
                    /*
                    //Definition of fonts moved to metropolis.css file

                    "@font-face": [{
                        "fontFamily": "MetropolisBold",
                        "src": `url(${publicURL}fonts/metropolis/Metropolis-Bold.woff2) format(\"woff2\"), url(${publicURL}fonts/metropolis/metropolis-Bold.woff) format(\"woff\"), url(${publicURL}fonts/metropolis/metropolis-Bold.ttf) format(\"truetype\")`
                    }
                    */
                }
            },
            "MuiFilledInput": {
                "root": {
                    "overflow": "hidden",
                    "backgroundColor": "transparent",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "borderRadius": "5px",
                    "&:hover": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "&$focused": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "margin": "0px 0px 0px",
                        "padding": "8px 16px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "& [class*=\"MuiFilledInput-input\"]:focus": {
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiSelect-select\"]:focus": {
                        "borderRadius": "inherit",
                        "backgroundColor": "inherit"
                    },
                    "& [class*=\"MuiSelect-icon\"]": {
                        "top": "calc(20%)"
                    },

                    "&[class*=\"MuiFilledInput-adornedEnd\"]":{
                        //no empty space is intentional - we want the input element to not have padding
                        "paddingRight": "0px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"]": {
                        "position": "absolute",
                        "right": "0px",
                        "bottom": "18px",
                        "paddingRight": "12px",
                        "paddingLeft": "12px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"] [class*=\"MuiIconButton\"]": {
                        "padding": "3px"
                    }

                },
                "focused": {}
            },
            "MuiAutocomplete": {
                "inputRoot": {
                    "&[class*=\"MuiFilledInput\"]": {
                        "padding": "0px",
                        "paddingRight": "0px",
                        "marginTop": "0px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "&[class*=\"MuiFilledInput\"]:before": {
                        "border": "none"
                    },
                    "&[class*=\"MuiFilledInput\"]:after": {
                        "border": "none"
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "margin": "0px",
                        "padding": "8px 16px",
                        "border": "none"
                    }
                }
            },
            "MuiOutlinedInput": {
                "root": {
                    "overflow": "hidden",
                    "backgroundColor": "transparent",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:hover": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none"
                    },
                    "&$focused": {
                        "backgroundColor": "transparent",
                        "boxShadow": "none",
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiOutlinedInput\"]": {
                        "margin": "0px 0px 0px",
                        "padding": "8px 36px 8px 16px",
                        "border": "1px solid #ccc",
                        "borderRadius": "5px"
                    },
                    "& [class*=\"MuiOutlinedInput-notchedOutline\"]": {
                        "border": "none"
                    },
                    "& [class*=\"MuiOutlinedInput-input\"]:focus": {
                        "borderColor": "#656566"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"]": {
                        "position": "absolute",
                        "right": "0px",
                        "bottom": "18px",
                        "marginLeft": "0px"
                    },
                    "& [class*=\"MuiInputAdornment-positionEnd\"] [class*=\"MuiIconButton\"]": {
                        "padding": "3px"
                    }
                },
                "adornedEnd": {
                    "paddingRight": "0px"
                },
                "focused": {}
            },
            "MuiInputLabel": {
                "filled": {
                    "position": "unset",
                    "transform": "translate(5px, 0px) scale(1)",
                    "color": "#656566",
                    "fontSize": ".875rem",
                    "lineHeight": "1.15",
                    "textTransform": textTransform,
                    "&[class*=\"MuiInputLabel-shrink\"]": {
                        "color": "#656566",
                        "transform": "translate(5px, 0px) scale(1)"
                    }
                },
                "outlined": {
                    "position": "unset",
                    "transform": "translate(5px, 0px) scale(1)",
                    "color": "#656566",
                    "fontSize": ".875rem",
                    "lineHeight": "1.15",
                    "textTransform": textTransform,
                    "&[class*=\"MuiInputLabel-shrink\"]": {
                        "color": "#656566",
                        "transform": "translate(5px, 0px) scale(1)"
                    }
                }
            },
            "MuiFormHelperText": {
                "contained": {
                    "marginLeft": "5px",
                    "marginRight": "5px"
                }
            },
            "MuiFormControl": {
                "marginNormal": {
                    "marginTop": "15px",
                    "marginBottom": "15px"
                }
            },
            "MuiButton": {
                "root": {
                    "textTransform": "capitalize",
                    "boxShadow": "none"
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: primary_main,
                    },
                },
            },

            MuiRadio: {
                colorSecondary: {
                    '&$checked': {
                        color: primary_main,
                    },
                },
            },
            "MuiStepper": {
                "root": {
                    "&>[class*=\"MuiStep-completed\"] [class*=\"MuiStepLabel-iconContainer\"]": {
                        "background": primary_main,
                        "borderRadius": "50%",
                        "width": "32px",
                        "height": "32px",
                        "marginRight": "8px",
                        "position": "relative"
                    },
                    "&>[class*=\"MuiStep-completed\"] [class*=\"MuiStepLabel-iconContainer\"] svg": {
                        "background": "#ffffff",
                        "borderRadius": "50%",
                        "width": "5px",
                        "height": "5px",
                        "left": "14px",
                        "top": "14px",
                        "position": "absolute"
                    },
                    "& :not([class*=\"Mui-disabled\"])>[class*=\"MuiStepLabel-iconContainer\"]": {
                        "background": primary_main,
                        "borderRadius": "50%",
                        "width": "32px",
                        "height": "32px",
                        "marginRight": "8px",
                        "position": "relative"
                    },
                    "& :not([class*=\"Mui-disabled\"])>[class*=\"MuiStepLabel-iconContainer\"] svg": {
                        "background": "#ffffff",
                        "borderRadius": "50%",
                        "width": "5px",
                        "height": "5px",
                        "left": "14px",
                        "top": "14px",
                        "position": "absolute"
                    },
                    "& [class*=\"MuiStepLabel-iconContainer\"]": {
                        "background": "transparent",
                        "width": "32px",
                        "height": "32px",
                        "marginRight": "8px",
                        "position": "relative"
                    },
                    "& [class*=\"MuiStepLabel-iconContainer\"] svg": {
                        "background": "#656566",
                        "borderRadius": "50%",
                        "width": "20px",
                        "height": "20px",
                        "left": "6px",
                        "top": "6px",
                        "position": "absolute"
                    },
                    "& [class*=\"MuiStepConnector-vertical\"]": {
                        "marginLeft": "16px",
                        "padding": "0"
                    },
                    "& [class*=\"MuiStepLabel-label\"] a": {
                        "color": "#656566"
                    },
                    "& [class*=\"MuiStepLabel-completed\"] a": {
                        "color": primary_main
                    },
                    "& [class*=\"MuiStepLabel-active\"] a": {
                        "color": primary_main
                    },
                    "& path": {
                        "display": "none"
                    },
                    "& circle": {
                        "display": "none"
                    },
                    "& text": {
                        "display": "none"
                    }
                }
            },
            "MuiDropzoneArea": {
                "root": {
                    "border": "1px solid",
                    "borderColor": "#ccc",
                    "overflow": "hidden",
                    "borderRadius": "4px",
                    "backgroundColor": "transparent",
                    "transition": "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "minHeight": "160px",
                    "&:hover": {
                        "backgroundColor": "transparent"
                    },
                    "&$focused": {
                        "backgroundColor": "#fff",
                        "borderColor": secondary_main
                    },
                    "& [class*=\"MuiFilledInput-input\"]": {
                        "padding": "0px 12px 16px",
                        "opacity": 0
                    },
                    "& [class*=\"MuiDropzoneArea-icon\"]": {
                        "color": "#ccc"
                    }
                },
                "containedPrimary:hover": {
                    // "fontSize": "1rem",
                    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
                    "lineHeight": "1.5",
                    "letterSpacing": "0.00938em",
                    "color": "rgba(0, 0, 0, 0.54)",
                    "fontWeight": "400"
                },
                "text": {
                    // "fontSize": "1rem"
                }
            },
            "MuiGrid": {
                "root": {
                    "& a": {
                        "color": primary_main,
                        // "fontFamily": "MetropolisRegular",
                        // "fontSize": "1rem",
                        "lineHeight": 1,
                        "padding": "5px 0",
                        "textDecoration": "none"
                    },
                    "& [id=\"mainFormBox\"]": {
                        "margin": "40px 0px"
                    },
                    "& [id=\"dropZoneHeader\"]": {
                        "marginLeft": "0px"
                    },
                    "& [id=\"dropZoneHeader\"] p": {
                        "color": "#656566",
                        "zIndex": 1,
                        "fontSize": ".875rem",
                        "transform": "translate(5px, 5px) scale(1)",
                        "lineHeight": "1.15",
                        "pointerEvents": "none",
                        "textTransform": textTransform
                    },
                    "& [id=\"docu-sign-iframe-container\"]": {
                        "height": "600px"
                    },
                    "& [id=\"docu-sign-iframe\"]": {
                        "border": "none",
                        "width": "100%",
                        "height": "100%"
                    },
                    "& [id=\"action-bar-btn-continue\"]": {
                        "background": primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-Modal-inner\"]": {
                        "maxWidth": "30em",
                        "background": "transparent",
                        "border": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-NavigationBar-back\"]": {
                        "display": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-DocumentSelector-option\"][class*=\"onfido-sdk-ui-DocumentSelector-optionHoverDesktop\"]": {
                        "border": "1px solid #e6e6e6",
                        "borderRadius": "10px"
                    },
                    "& [class*=\"onfido-sdk-ui-DocumentSelector-option\"][class*=\"onfido-sdk-ui-DocumentSelector-optionHoverDesktop\"]:hover": {
                        "boxShadow": "none",
                        "border": "1px solid"+ primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-DocumentSelector-option\"][class*=\"onfido-sdk-ui-DocumentSelector-optionHoverDesktop\"]:active": {
                        "boxShadow": "none",
                        "border": "1px solid"+ primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-DocumentSelector-option\"][class*=\"onfido-sdk-ui-DocumentSelector-optionHoverDesktop\"]:focus": {
                        "boxShadow": "none",
                        "border": "1px solid"+ primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-primary\"]": {
                        "background": primary_main,
                        "marginBottom": "8px",
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-primary\"][class*=\"onfido-sdk-ui-Button-hoverDesktop\"]:hover": {
                        "background": primary_main,
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-primary\"][class*=\"onfido-sdk-ui-Button-hoverDesktop\"]:active": {
                        "background": primary_main,
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Theme-link\"]": {
                        "borderBottomColor": "transparent",
                        "background": "transparent",
                        "color": primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-Theme-link\"]:hover": {
                        "color": "#333333"
                    },
                    "& [class*=\"onfido-sdk-ui-Theme-link\"]:active": {
                        "color": "#333333"
                    },
                    "& [class*=\"onfido-sdk-ui-crossDevice-CrossDeviceLink-smsLinkOption\"]:before": {
                        "display": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-crossDevice-CrossDeviceLink-copyLinkOption\"]:before": {
                        "display": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-QRCode-qrCodeHelpIcon\"]": {
                        "display": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon\"]": {
                        "display": "none"
                    },
                    "& [class*=\"onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel\"]:before": {
                        "borderColor": "#666666"
                    },
                    "& [class*=\"onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel\"]:after": {
                        "borderColor": "#666666"
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-secondary\"]": {
                        "border": "1px solid "+secondary_main,
                        "color": primary_main,
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-secondary\"][class*=\"onfido-sdk-ui-Button-hoverDesktop\"]:hover": {
                        "backgroundColor": "transparent",
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Button-button-secondary\"][class*=\"onfido-sdk-ui-Button-hoverDesktop\"]:active": {
                        "backgroundColor": "transparent",
                        "height": "40px"
                    },
                    "& [class*=\"onfido-sdk-ui-Camera-btn\"]:hover": {
                        "backgroundColor": primary_main
                    },
                    "& [class*=\"onfido-sdk-ui-Error-container-warning\"]": {
                        "backgroundColor": secondary_main
                    },
                    "& [class*=\"onfido-sdk-ui-PageTitle-titleSpan\"]": {
                        "width": "100%",
                        "display": "block",
                        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
                        "fontSize": "30px",
                        "color": "#333333",
                        "lineHeight": "1.2",
                        "textAlign": "center",
                        "marginBottom": "20px"
                    },
                    "& [class*=\"onfido-sdk-ui-PageTitle-subTitle\"]": {
                        "fontWeight": "600",
                        "width": "100%",
                        "display": "block",
                        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
                        "fontSize": "16px",
                        "color": "#333333",
                        "lineHeight": "1.7",
                        "textAlign": "center",
                        "marginBottom": "20px"
                    },
                    "& [class*=\"onfido-sdk-ui-Theme-footer\"]": {
                        "display": "none"
                    }
                }
            }
        },...theme
    }
}



//@ts-ignore
export const createSancusTheme = (theme_data,...args) => createTheme(theme_presets(theme_data),...args)