import {Avatar, Box, Grid, Input, Typography, Divider, Button} from "@material-ui/core";
import {INote} from "sancus-client-common/dist/common/FormContainerBase";
import * as React from "react";
import {useState} from "react";
import MomentUtils from "@date-io/moment";
import contextPath from "sancus-client-common/dist/common/contextPath";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	noteHeader: {
		color: '#aaaaaa',
		fontSize: 12
	},
	noteAuthor: {
		color: '#1f2124'
	},
}));

interface INoteProps {
	note: Partial<INote>
	handleSubmitNote(text: string): void
}
const momentUtils = new MomentUtils();
const Note = (props: INoteProps) => {
	const [text, setText] = useState(props.note.text || "");
	// todo restrict 1 per user?
	const handleSubmit = () => {
		props.handleSubmitNote(text as string)
		setText("");
	}

	const classes = useStyles();

	return <Box p={2} style={{ background: !!props.note.user ? '#ffffff' : '#F6F9FC'}}>
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			{ props.note.user ? <Avatar src={`${contextPath}api/users/${props.note.user.id}/image`} style={{width: '24px', height: '24px'}}/> : '' }
			<Grid container direction={"column"} item xs>
				< Box ml={1} mr={1}>
					{ props.note.user ? <Typography className={`${classes.noteHeader}`} variant="caption">
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<b className={`${classes.noteAuthor}`}>{props.note.user.firstName} {props.note.user.lastName}</b> <span style={{ fontSize: 10 }}>{momentUtils.moment.utc(props.note.date).fromNow()}</span>
						</Grid>
						</Typography> : '' }
					<Input placeholder="Add a comment"
								 inputProps={{ 'aria-label': 'description' }}
								 style={{ fontSize: 12 }}
								 disabled={!!props.note.user}
								 value={text}
						    	 multiline={true}
								 onChange={e => setText(e.target.value)}
						   disableUnderline={!!props.note.user}
						   fullWidth />
				</Box>
			</Grid>
			{ props.note.user ? '': <Button size="small" disabled={text.length <= 0} onClick={handleSubmit}>Add</Button> }
		</Grid>
	</Box>
}

export default Note
