import contextPath from "./common/contextPath";
import {FormField, FormProperty} from "./common/camunda_helper";
import moment from "moment";

import jwtDecode, {JwtPayload} from "jwt-decode";
import {Option} from "./common/react_components_base";

const reserved_identifiers = ['id','uuid','__registry_uuid']
const filterRepeatableList = (fieldName) => !fieldName.includes('_List');

type IIntermediateSection = {
    sectionName: string;
    subSections: any;
    fields: Partial<IResultField>[];
}
type ISubSectionCategory = {
    subSections: {[key: string] : any}[];
    sectionName: string;
}
type IResultField  = FormField<string | number | Option[] | undefined>;


const transformPresets = (preset: {key: string, value: string}) => {
    return {
        value: preset.key,
        label: preset.value
    }
}

/**
 * field types fetched from Fides
 */
export enum FieldTypes {
    BOOLEAN = 99999,
    TEXT_BOX = 101,
    NUMERIC = 102,
    TEXT_AREA = 200,
    E_SIGNATURE_FILE = 201,
    E_SIGNATURE = 202,
    SELECT = 300,
    SELECT_1 = 301,
    MULTI_SELECT = 400,
    MULTI_CHECK = 600,
    FILE_1 = 700,
    RADIO = 800,
    HAS_BENEFICIAL = 1091,
    DATE = 900,
    TIMESTAMP = 901,
    /*
    thirdParty services data 1300 to 1399
    num type: 1300 -->  onfido   "{"check":"3d840a67-6b76-49bd-ab95-dc59e5721084","applicant":"55021ba7-92a6-4b72-a82d-05094542b2g5"}"
    */
    ONFIDO_ID = 1300
}

export enum DataTypes {
    TEXT = 100,
    NUMERIC = 200,
    SINGLE_SELECT = 300, // select, radio etc
    MULTI_SELECT = 400, // Multi-select, multi-check
    FILE = 700,
    E_SIGNATURE_FILE = 750,
    DATE = 800,
    E_SIGNATURE = 900,
    BOOLEAN = 999999,
}

type MetaProperties = {[key:string]:string}

export type FidesFieldConfig = {
    id:number
    name:string
    locale:Locale
    value: string
    repeatable:boolean;

    type: FieldTypes,

    presets?:{key:string,value:string}[],

    meta?: MetaProperties

}

type Locale = string

export type SectionConfig = {
    "name" : string,
    "id" : number,
    "repeatable" : boolean,
    "locale" : Locale,
    "value" : string,
    "fields" : {[fieldName:string]:FidesFieldConfig}
}

export type FidesConfig = {[sectionName:string]:SectionConfig}

function fileMapper(token,f,group?:{[fieldId:string]:any}){
    let cId;
    let jwtToken;
    let prefer='ucp';
    try {
        jwtToken = jwtDecode<JwtPayload>(token)
        const customerData = jwtToken['customerData'];
        //SRD-493: UCP returns token with customer_id
        if (customerData && customerData.categoryId){
            cId = customerData.categoryId;
            prefer = 'fides';
        }else if (customerData && customerData.category_id){
            cId = customerData.category_id;
            prefer = 'ucp';
        }
        cId = customerData && (customerData.categoryId || customerData.category_id);
    }catch (e){
        //Token is not jwt but in the form "AE/1/DED000031/1108_2/df807413d58ae0b62b67dccfc12da44e"
        cId = token.split("/")[2];
        prefer = 'fides';
    }

    const url = contextPath + `api/fides/document/${token}?prefer=${prefer}`;
    const id = f.id;

    let name:string | null = null;
    if (id === 'KYCTxn_TxnDocObj'){
        const descr = group!['KYCTxn_Descr'] && handleMultilang(group!['KYCTxn_Descr'])
        let doc = group!['KYCTxn_TxnDoc'] && handleMultilang(group!['KYCTxn_TxnDoc'])

        if (doc){ //String part inside parentheses
            doc = doc.replace(/\(.*\)/,'').trim();
        }else{
            doc = descr;
        }

        const docId = group!['KYCTxn_TxnDocID']
        name = (doc && (doc.replaceAll(' ','_') + '_' ) || '') + docId;
    }else if (id === 'CorCD_RequiredLicensesProof'){
        name = 'CERTIFICATE_'+cId
    }else if (id==='CorCD_MoA'){
        name = 'MOA_'+cId;
    }

    const headFN = async()=>{
        let _url = url+'&show=head';
        if (name){
            _url = _url+"&name="+encodeURI(name!)
        }
        const rsp = await fetch(_url,{method:'GET'});
        if (rsp.status < 300) {
            const {name:_name,mimeType} = await rsp.json();
            return {
                name:(_name || name),
                mimeType
            };
        } else {
            throw {status:rsp.status}
        }
    }

    const dataAsFN = async ()=>{
        const {name,mimeType} = await headFN();
        return {name,mimeType,data:url+'&encode_as=binary&name='+encodeURI(name)}
    }

    const fetchFN = async ()=> {
        try {
            const rsp = await fetch(url);
            if (rsp.status < 300) {
                const txt = await rsp.text();
                return txt;
            } else {
                return null
            }
        } catch (e) {
            return null;
        }
    };
    return [{data:dataAsFN,name}]
}

/**
 * Mapping field types(stored in DB) to DataTypes used to process values
 * @param field
 */

type Type = string
type Component=string | undefined;
type Mapper = ((value:any,f:Partial<IResultField>,groupCtx?:any)=>any);

const dateParser = (v:string | number)=>{
    if (typeof v === 'number') {
        return moment(v);
    }else {
        if (v.match(/^[0-9]+$/)) {
            const num = parseInt(v);
            return moment(num);
        } else {
            return moment(v)
        }
    }
}


const mappedFieldTypesToDataTypes = <T extends { fieldType: FieldTypes,meta?: MetaProperties}>(field: T):[DataTypes,Type,Component?,Mapper?] => {
    const dateStringFromMeta = (v, defFormat)=>{
        const {timezone,format} = field.meta ?? {timezone:"utc",format:defFormat}
        let tzaware = dateParser(v);
        const isUTC = tzaware.isUTC();
        if (timezone === 'utc'){
            tzaware = tzaware.utc();
        }else if (timezone === 'local') {
            tzaware = tzaware.local();
        }else{
            //FIXME: This would obviously fail since we don't import moment-timezone
            //tzaware = dateParser(v).tz(timezone);
            tzaware = tzaware.utc();
        }

        return tzaware.format(format);
    }
    switch(field.fieldType){
        case FieldTypes.BOOLEAN:
            return [DataTypes.BOOLEAN,"checkbox"]
        case FieldTypes.DATE:

            return [DataTypes.DATE, "date",undefined,v=>{
                return dateStringFromMeta(v,"DD/MM/yyyy");
            }]
        case FieldTypes.TIMESTAMP:
            return [DataTypes.DATE, "date",undefined,v=>{
                return dateStringFromMeta(v,"DD/MM/yyyyTHH:mm:ss.SSS Z");
            }]
        case FieldTypes.E_SIGNATURE:
            return [DataTypes.E_SIGNATURE,"file",undefined,fileMapper]
        case FieldTypes.E_SIGNATURE_FILE:
        case FieldTypes.FILE_1:
            return [DataTypes.E_SIGNATURE_FILE, "file",undefined,fileMapper]
        case FieldTypes.NUMERIC:
            return [DataTypes.NUMERIC, "number",undefined,v=>{
                return v && parseInt(v) || 0;
            }]
        case FieldTypes.MULTI_CHECK:
        case FieldTypes.MULTI_SELECT:
            return [DataTypes.MULTI_SELECT,"text","MultiSelector"]
        case FieldTypes.RADIO:
            return [DataTypes.SINGLE_SELECT,"text","RadioSelector"]
        case FieldTypes.SELECT:
        case FieldTypes.SELECT_1:
            return [DataTypes.SINGLE_SELECT,"text","OptionSelector"]
        case FieldTypes.TEXT_AREA:
        case FieldTypes.TEXT_BOX:
            return [DataTypes.TEXT,"text"]
        case FieldTypes.ONFIDO_ID:
        case FieldTypes.HAS_BENEFICIAL:
        default:
            return [DataTypes.TEXT,"text"]
    }
};



const addTemplateFieldInfo = (f : Partial<IResultField>,_fieldsConf:FidesConfig,providers: Partial<IResultField>[],allowWrites):Partial<IResultField> => {
    // skip re-enhancing
    if (f.type) {
        return f as IResultField
    }
    const sectionName = f.id!.split('_')[0]

    const sectionConf = _fieldsConf[sectionName];
    const fieldConf = sectionConf?.fields[f.id!];
    let label = fieldConf?.value
    let fides_id = fieldConf?.id

    let section =  _fieldsConf[sectionName]?.value
    let visual_section = _fieldsConf[sectionName]?.value

    label = label || f.id!
    section = section || sectionName
    visual_section  = visual_section || sectionName

    const [fidesType,sancustype,component,adapter] = mappedFieldTypesToDataTypes({fieldType: fieldConf?.type || FieldTypes.TEXT_BOX})
    const field = _fieldsConf[sectionName]?.fields[f.id!]
    if (!field) {
        console.debug("Cannot find field for id: %s in section %s",f.id,sectionName)
    }

    const choices = field && field.presets?.map(transformPresets)
    let _value = f.value?.value;
    const isPendingValue = _value === 'Pending';

    if (adapter !=null) {
        _value = adapter && f.value?.value != null && adapter(f.value?.value,f) || f.value?.value
        if (f.properties?.group || f.properties?.group_repeatable){
            //Must also adapt the value in the provider
            const groupName = f.properties?.group || f.properties?.group_repeatable
            const group = providers.find(_f=>_f.id === groupName);
            const groupValue = group?.value?.value as { [key:string]:any } | { [key:string]:any }[]
            if (Array.isArray(groupValue)){
                //Group Repeatable
                groupValue.forEach(_groupValue=>{
                    _groupValue[f.id!] = _groupValue[f.id!]!=null && adapter(_groupValue[f.id!],f,_groupValue) || _groupValue[f.id!]
                })
            }else{
                //PLain Group
                groupValue[f.id!].value = groupValue[f.id!]!=null && adapter(groupValue[f.id!],f,groupValue) || groupValue[f.id!]
            }
        }
    }
    const value:FormField['value'] = {
        value: !isPendingValue ? _value : null,
        transient:true,
        type: {
            name: sancustype,
        }
    }


    const properties:{[key:string]:any} = {
        ...f.properties,
        section,
        visual_section,
        fides_field_id:fides_id
    }
    if (choices){
        properties.component = component || 'OptionSelector'
        properties.choices = choices
    }

    let validationConstraints = f.validationConstraints;
    if (validationConstraints == null) {
        validationConstraints = [];
        if (!allowWrites || allowWrites?.indexOf(f.id) === -1) {
            validationConstraints.push({name: "readonly"});
            if(isPendingValue) {
                validationConstraints.push({name: "pending" as any});
            }
        }
    }
    const retF:Partial<IResultField> = {
        ...f,
        value,
        label,
        properties,
        type: {
            name: sancustype,
        },
        typeName: sancustype,
        validationConstraints
    }

    //@ts-ignore
    return retF;
}

const handleMultilang = (value: any) => {
    if (Array.isArray(value)) {
        if (Array.isArray(value[0]) && value[0][0].lang) {
            //Multiple multilang options
            return value.map(v => v.find(langValue => langValue.lang === 'eng')?.text).filter(t => t != null).join('\n');
        }else if (value[0].lang) {
            return value.find(langValue => langValue.lang === 'eng').text
        }
    }else if (typeof value === 'string') {
        try {
            const valuePerLang = JSON.parse(value);
            return handleMultilang(valuePerLang)

        } catch (e) {
            return value;
        }
    }
    return value;
}
export type CustomerData = {
    [section:string]:CustomerSection
}
type DateAsLong = number
export type FidesValue = {
    value:any,
    updatedAt?:DateAsLong,
    expiredAt?:DateAsLong,
    verifierOrganizationName?:string
}
export type CustomerSection = {
    [field:string]:FidesValue | FidesValue[],
}
export type CustomerInfo = {}
export enum RequestStatus {
    Deleted=(-1),
    Created=(0),
    Sent=(1),
    InProgress=(2),
    Answered=(3),
    Confirmed=(4),
    OtherVerified=(5),
    Archived=(6),
    Cancelled=(7),
    NewLead=(8)
}
export type Contact = {
    name,email,mobile:string
    contactEmail?:string
    docType: "id" | "passport" | "visa"
}
export type CustomerMetadata = {
    riskClass,orgRefNum:string
    requestStatus:RequestStatus;
}

export type CustomerExtendedInfo = {
    customerInfo:CustomerInfo,
    contacts: Contact[]
    customerMetadata: CustomerMetadata
}
export type Payload = {
    customerData: CustomerData,
    customerExtendedInfo:CustomerExtendedInfo
}

// Transform fides v2 payload structure to formFields structure
const transform = (customerData:CustomerData,customerExtendedInfo:CustomerExtendedInfo, fidesFieldConf:FidesConfig,fidesFields:string[] | undefined,transient,allowWrites:string[]) => {
    if (! customerData || ! fidesFieldConf){
        return [];
    }



    let subSectionCategories: ISubSectionCategory[] = [];
    // construct non repeatable field values
    const formFieldsData = Object.keys(customerData)
        .map((sectionName):IIntermediateSection => {
            const fidesSectionData = customerData[sectionName];
            return {
                sectionName: sectionName,
                subSections: fidesSectionData[`${sectionName}_List`] || [],
                fields: Object.keys(fidesSectionData)
                    .filter(filterRepeatableList)
                    // This line  filters out null values and values that are not in the fides fields list
                    .filter(fieldName=>fidesFields == null || fidesFields.indexOf(fieldName) !== -1)
                    .filter(fieldName=>(fidesSectionData[fieldName] as FidesValue)?.value != null)
                    .map((fieldName) => {
                        return {
                            value: {
                                value: handleMultilang((fidesSectionData[fieldName] as FidesValue).value),
                                transient:transient,
                                type: {
                                    name: "string"
                                },
                            } as FormField['value'],
                            id: fieldName
                        }
                    })
            }
        })
        .reduce((flatFields: Partial<IResultField>[], section:IIntermediateSection)=>{
            subSectionCategories.push({
                sectionName: section.sectionName,
                subSections: section.subSections
            })
            return [ ...flatFields, ...section.fields]
        }, [])
    // append repeatable data
    subSectionCategories.forEach((sectionCategory) => {
        if (sectionCategory.subSections.length > 0) {
            // field values per sub section
            const values = sectionCategory.subSections.map((subSection) => {
                let fieldValues = {};
                Object.keys(subSection).forEach(k => {
                    if (!subSection[k]) {
                        return;
                    }
                    if (reserved_identifiers.indexOf(k) !== -1){
                        fieldValues[k] = subSection[k]
                    }else{
                        fieldValues[k] = handleMultilang(subSection[k].value)
                    }
                })
                return {
                    uuid: sectionCategory.sectionName+":"+subSection.id,
                    ...fieldValues
                }
            })
            // append repeatable fields without data
            const validationConstraints:FormProperty['validationConstraints'] =
                (!allowWrites || allowWrites?.indexOf(sectionCategory.sectionName) === -1) ? [{name:"readonly"}] :[];

            const repFields =
                Object.keys(fidesFieldConf[sectionCategory.sectionName]?.fields || {})
                    // This line  filters out null values and values that are not in the fides fields list
                    .filter(fieldName=>fidesFields == null || fidesFields.indexOf(fieldName) !== -1)
                    .filter(fieldName=> fidesFieldConf[sectionCategory.sectionName].fields[fieldName].repeatable)
                    .filter(fieldName=>sectionCategory.subSections.some(s=>s[fieldName] != null ));

            if (repFields.length > 0) {

                repFields
                    .forEach((k) => {
                        const sectionConf = fidesFieldConf[sectionCategory.sectionName];
                        const fieldConf = fidesFieldConf[sectionCategory.sectionName]?.fields[k!];
                        formFieldsData.push({
                            id: k,
                            value: {
                                value: null,
                                transient: transient,
                                type: {
                                    name: "string",
                                    fidesType: fieldConf?.type
                                },
                            } as FormField['value'],
                            properties: {
                                visible: `${k} != null`,
                                section: sectionConf?.value || sectionCategory.sectionName,
                                group_repeatable: sectionCategory.sectionName,
                                visual_section: sectionConf?.value || sectionCategory.sectionName,
                                choices: fieldConf?.presets?.map(transformPresets),
                                fides_field_id: fieldConf?.id
                            }, validationConstraints
                        })
                    })


                formFieldsData.push({
                    id: sectionCategory.sectionName,
                    value: {
                        type: {name: 'object'},
                        value: values,
                        transient: transient
                    } as FormField['value'],
                    properties: {
                        section: fidesFieldConf[sectionCategory.sectionName]?.value || sectionCategory.sectionName,
                        visual_section: fidesFieldConf[sectionCategory.sectionName]?.value || sectionCategory.sectionName,
                    },
                    type: {name: 'object'},
                    typeName: 'object',
                    validationConstraints
                })
            }

        }
    })
    let ret =  formFieldsData.map(f=>addTemplateFieldInfo(f,fidesFieldConf,formFieldsData,allowWrites));
    if (fidesFields){
        ret = ret.sort((a,b)=>{
            let ia = a && a.id && fidesFields.indexOf(a.id);
            if (ia == -1){
                ia = Infinity
            }
            let ib = b && b.id && fidesFields.indexOf(b.id);
            if (ib == -1){
                ib = Infinity
            }

            return (ia!=null && ib!=null && (ia <ib ?-1 : (ia >ib?1:0))) || 0
        });
    }else{
        //Sort by fides field id
        ret= ret.sort((a,b)=>{
            let ia = a?.properties?.['fides_field_id'];
            let ib = b?.properties?.['fides_field_id'];

            return (ia!=null && ib!=null && (ia <ib ?-1 : (ia >ib?1:0))) || 0
        })
    }

    //Append fields from metadata section
    if (customerExtendedInfo && customerExtendedInfo.customerMetadata) {
        const {customerInfo, customerMetadata, contacts} = customerExtendedInfo

        const customerMetadataCfg = {
            OBAConsent: {
                type: 'boolean',
                label: "Customer consent collected at the lead source"
            },
            applicant: {
                isGroup: true,
                groupMembers: {
                    email: {type: 'string',label:"Email"},
                    mobile: {type: 'string',label:"Mobile"},
                    name: {type: 'string',label:"Name"}
                }
            },
            originatorOrgId: {
                type: 'string',
                label:" Originator",
                properties:{
                    component: 'OptionSelector',
                    choices_str:JSON.stringify({
                        dynamic_choices: 'organizations.map(o=>{return {value:o.id,label:o.name}})'
                    })

                }
            },
            productCode: {
                type: 'string',
                label:"Product Code"
            },
            purposeOfAccount: {
                type: 'string',
                label:"Purpose of account"
            },
            otherPurposeOfAccount: {
                type: 'string',
                label:"Other purpose of account"
            }

        }
        const metaSectionName = 'Application Details'
        const cfgToFields = (cfgKey, cfg, dataObject): Partial<IResultField>[] => {
            const value = dataObject[cfgKey]
            if (value == null) {
                return [];
            }
            if (cfg.isGroup) {
                const ret = Object.keys(cfg.groupMembers).flatMap(gcfKey => {
                    const gcf = cfg.groupMembers[gcfKey];
                    const entry = cfgToFields(gcfKey, gcf, dataObject[cfgKey])
                    entry.forEach(e => e.properties!['group'] = cfgKey)
                    return entry;
                })
                return ret;
            } else {
                return [{
                    id: cfgKey,
                    label: cfg.label || cfgKey,
                    value: {
                        type: {name: cfg.type},
                        value: dataObject[cfgKey] && handleMultilang(dataObject[cfgKey]),
                        transient: transient
                    } as FormField['value'],
                    properties: {
                        ...(cfg.properties || {} ),
                        section: metaSectionName,
                        visual_section: metaSectionName
                    },
                    type: {name: cfg.type},
                    typeName: cfg.type,
                    validationConstraints: [{name: "readonly"}]
                }]
            }
        }
        const metaFields = Object.keys(customerMetadataCfg).flatMap(cfgKey => {
            const cfg = customerMetadataCfg[cfgKey];
            const entries = cfgToFields(cfgKey, cfg, customerMetadata)
            return entries;
        })

        const status = customerMetadata.requestStatus

        const statusField:FormProperty = {
            businessKey: false,
            defaultValue: status,
            id: 'requestStatus',
            label: 'Status',
            value: {
                type: {name: 'string'},
                value: status,
                transient: transient
            } as FormField['value'],
            properties: {
                section: 'Customer Metadata',
                visual_section: 'Customer Metadata'
            },
            type: {name: 'string'},
            typeName: 'string',
            validationConstraints: [{name: "readonly"}]
        }

        ret = [...metaFields, ...ret,statusField];
    }


    return ret;
}



export default transform;

// console.log(JSON.stringify(transform(fidesPayload.customerData)));
