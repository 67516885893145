import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';
import {FormFieldValue} from "../../common/FormContainerBase";


/**
 * Map available actions that change state
 */
export namespace FormContainerActions {
    export enum Type {
        SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR',
        SET_ERROR = 'SET_ERROR',
        CLEAR_ERROR = 'CLEAR_ERROR',
        SET_IN_PROGRESS = 'SET_IN_PROGRESS',
        TRIGGER_VALUE_CHANGE = 'TRIGGER_VALUE_CHANGE',
    }

    export const setValidationError = createAction<any>(Type.SET_VALIDATION_ERROR);
    export const setError = createAction<any>(Type.SET_ERROR);
    export const clearError = createAction<void>(Type.CLEAR_ERROR);
    export const setInProgress = createAction<boolean | string | number | { message: string; percent: number }>(Type.SET_IN_PROGRESS);
    export const triggerValueChange = createAction<null | { key: string, value: FormFieldValue, submit?: boolean }>(Type.TRIGGER_VALUE_CHANGE);
}

export type FormContainerActions = Omit<typeof FormContainerActions, 'Type'>;
export const useFormContainerActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = FormContainerActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as FormContainerActions;
};
