import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItem, ListItemSecondaryAction,
    ListItemText,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import {useState} from "react";

interface SelectorOption {
    value: string;
    label: string;
}

export type SelectorOptionType = string | SelectorOption;

interface SelectOptionsEditorProps {
    options: SelectorOptionType[],
    onChangeOptions: (options: SelectorOptionType[]) => void
}

const SelectOptionsEditor = (props: SelectOptionsEditorProps)=> {
    const [ optionValue, setOptionValue ] = useState<string>('');
    const [ optionLabel, setOptionLabel ] = useState<string>('');
    const [ showError, setShowError ] = useState<boolean>(false);
    const [selectedOptionForEdit, setSelectedOptionForEdit] = useState<number | null>(null);
    const options = props.options;

    const handleAddOption = () => {
        let optionToAdd: SelectorOptionType = {value: optionValue, label: optionLabel};

        if (!optionValue && !optionLabel){
            optionToAdd = "";
        }
        if (optionValue && !optionLabel){
            optionToAdd = optionValue;
        }
        let choices_str;

        if (selectedOptionForEdit === null) {
            choices_str = [...options,optionToAdd];
        } else{
            options[selectedOptionForEdit] = optionToAdd;
            choices_str = [...options];
        }

        props.onChangeOptions(choices_str);

        setOptionValue('');
        setOptionLabel('');
        setShowError(false);
        setSelectedOptionForEdit(null);
    };

    const handleCloseOption = () => {
        setOptionValue('');
        setOptionLabel('');
        setShowError(false);
        setSelectedOptionForEdit(null);
    };

    const handleDeleteOptionFactory = (option: SelectorOptionType): (event) => any => {
        return (e) => {

            e.stopPropagation();
            e.preventDefault();

            if(selectedOptionForEdit !== null) {
                setOptionValue('');
                setOptionLabel('');
            }

            props.onChangeOptions([...options.filter(opt => opt !== option)])

            setSelectedOptionForEdit(null);
        };
    };

return (
    <Grid container direction={'column'} spacing={1}>
        <Grid item>
            <Toolbar disableGutters={true}>
                <Grid item container direction={'column'}>
                    <Grid item>
                        {
                            selectedOptionForEdit === null && <IconButton
                                onClick={handleAddOption}>
                                <AddIcon fontSize={'small'}/>
                            </IconButton>
                        }

                        {
                            selectedOptionForEdit !== null && (<>
                                    <IconButton onClick={handleAddOption}>
                                        <CheckIcon fontSize={'small'}/>
                                    </IconButton>

                                    <IconButton onClick={handleCloseOption}>
                                        <CloseIcon fontSize={'small'}/>
                                    </IconButton>
                                </>
                            )
                        }

                    </Grid>
                    <Grid item>
                        <TextField variant={'outlined'}
                                   label={'value'}
                                   value={optionValue}
                                   onChange={(event) => {
                                       setOptionValue(event.target.value);
                                       if (showError) {
                                           setShowError(false);
                                       }
                                   }}
                                   onBlur={() => {
                                       /*if(optionValue.length === 0){
                                           setShowError(true);
                                       }*/
                                   }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant={'outlined'}
                                   label={'label'}
                                   value={optionLabel}
                                   onChange={(event) => {
                                       setOptionLabel(event.target.value);
                                       if (showError) {
                                           setShowError(false);
                                       }
                                   }}
                                   onBlur={() => {
                                       /*if(optionLabel.length === 0){
                                           setShowError(true);
                                       }*/
                                   }}
                        />
                    </Grid>
                    {
                        showError &&
                        <Grid item>
                            <Typography variant={'subtitle2'} color={'error'}>both fields required</Typography>
                        </Grid>
                    }
                </Grid>
            </Toolbar>
        </Grid>
        <Grid item>
            <Divider/>
        </Grid>
        <Grid item>
            <List>
                {
                    options && options.map((option: SelectorOptionType, index: number) => {
                        let key;
                        let primary;
                        let optionValue = '';
                        let optionLabel = '';

                        if (typeof option === 'string') {
                            key = `${option}-${index}`;
                            primary = `"${option}"-"${option}"`
                            optionValue = option;
                        } else {
                            key = `${option.value}-${index}`;
                            primary = `"${option.value}"-"${option.label}"`
                            optionValue = option.value;
                            optionLabel = option.label;
                        }

                        return (
                            <ListItem key={key} selected={selectedOptionForEdit === index} onClick={() => {
                                setOptionValue(optionValue);
                                setOptionLabel(optionLabel);
                                setShowError(false);
                                setSelectedOptionForEdit(index);
                            }}>
                                <ListItemText primary={primary}></ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton onClick={handleDeleteOptionFactory(option)}>
                                        <DeleteIcon fontSize={'small'}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                }
                {
                    options && options.length === 0 &&
                    <Typography>No options</Typography>
                }
            </List>
        </Grid>
    </Grid>)

}

export default SelectOptionsEditor;
