import {Variables} from "../common/camunda_helper";


function valueToCam(el:HTMLInputElement,camType){
    const value = el.value;
    if (camType == null){
        return value;
    }
    switch (camType.toLowerCase()) {
        case "null":
            return null;
        case "boolean":
            return el.checked
        case "date":
            return value;
        case "long":
            return parseInt(value,10)
        default:
            return value;

    }
}

export function convert(form:HTMLFormElement) {

    let _params: Variables = Array.from(form.elements).filter((_el: Element) => {
        const el = _el as HTMLInputElement;
        //Do not re-submit readonly values
        if (el.tagName !== 'INPUT' && el.tagName !== 'SELECT') {
            return false; //filter out buttons inside form (e.g. injected by onfido)
        }
        const readonly = el.getAttribute("readonly") != null || el.readOnly;
        return !readonly;
    }).reduce((acc, _el: Element) => {
        const el = _el as HTMLInputElement
        const key = el.name || el.id
        const camType = el.getAttribute('cam-variable-type')
        const value = valueToCam(el, camType);

        const repeatableWrapper = el.closest('[data-repeatable="true"]')

        let currentValue = acc[key];
        if (currentValue === undefined) {
            currentValue = {value, type: camType, repeatable: repeatableWrapper != null};
        } else if (Array.isArray(currentValue.value)) {
            currentValue.value.push(value)
        } else {
            currentValue = {
                value: [currentValue.value],
                type: `json`,
                repeatable: currentValue.repeatable
            }
            currentValue.value.push(value);
        }

        // acc[key] = currentValue;
        acc[key] = currentValue.value;
        return acc;
    }, {});

    Object.keys(_params).forEach(pk => {
        const pv = _params[pk];
        if (pv.type === 'json') {
            pv.value = JSON.stringify(pv.value);
        } else if (pv.repeatable) {
            pv.value = JSON.stringify([pv.value])
            pv.type = 'json'
        }

    })

    return _params;
}