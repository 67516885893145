/**
 * Create ContextPad & paletter entries for the various supported integrations
 */
import palletElements from "./SancusPalletElementsInfo";

export default class CustomContextPad {
  constructor(config, contextPad, palette, create, elementFactory, injector, translate,elementTemplates) {
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    this.config = config;

    this.elementTemplates = elementTemplates;

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get('autoPlace', false);
    }

    contextPad.registerProvider(this);
    palette.registerProvider(this);
  }

  appendServiceTask = (element,elementTemplate)=> {
    const {
      autoPlace,
      elementFactory,
      appendServiceTaskStart
    } = this;

    const {id,appliesTo} = elementTemplate
    const shortId = id.split('.').pop()
    return (event) =>{
      if (autoPlace) {
        const shape = elementFactory.createShape({
          type: appliesTo[0],
          sancusService: shortId
        });
        try {
          autoPlace.append(element, shape);
        }catch (e){
          appendServiceTaskStart(element,elementTemplate,event);
        }
      } else {
        appendServiceTaskStart(element,elementTemplate,event);
      }
    }
  }

  appendServiceTaskStart = (element,elementTemplate,event)=> {
    const {elementFactory,create} = this;
    const {id,name,icon,appliesTo} = elementTemplate
    const shortId = id.split('.').pop()
    const shape = elementFactory.createShape({
      type: appliesTo[0],
      sancusService: shortId
    });

    create.start(event, shape, element);
  }

  getContextPadEntries(element){
    return this._getEntries(element,'append.service-task')
  }

  getPaletteEntries(element) {
    const artifacts = this._getEntries(element,'create.integration')

    artifacts['create.integration.integrations-toggle-button'] = {
      group: palletElements['integrations-toggle-button'].group,
      imageUrl: palletElements['integrations-toggle-button'].img,
      title: this.translate(palletElements['integrations-toggle-button'].name),
      action: {
        click: function (event) {
          const targetEl = document.querySelector(` .djs-palette-entries [data-group='integrations']`);
          if (targetEl) {
            targetEl.classList.toggle("hide");
          }
        }
      }
    }
    artifacts['create.integration.integration-separator']={
      group: palletElements['integration-separator'].group,
      title: this.translate(palletElements['integration-separator'].name),
      separator: true
    }
    return artifacts;
  }


  _getEntries(element,prefix) {
    const {
      translate,
      config,
      appendServiceTask
    } = this;

    const ret =  {};

    const elementTemplates = this.elementTemplates
    Object.keys(elementTemplates._templates).reduce((acc, templateId) => {
      const elementTemplate = elementTemplates.get(templateId)
      const {id,icon} = elementTemplate
      const shortId = id.split('.').pop()
      acc[`${prefix}.${shortId}`] = {
        group: 'integrations',
        title: translate(`Append ${shortId} Task`),
        action: {
          dragstart: appendServiceTask(element,elementTemplate),
          click: appendServiceTask(element,elementTemplate)
        }
      };
      if (icon){
        acc[`${prefix}.${shortId}`].imageUrl = icon.contents + '" width="16" height=16'
      }else{
        acc[`${prefix}.${shortId}`].className= 'bpmn-icon-service-task'
      }
      return acc;
    },ret);
    return ret;
  }
}

CustomContextPad.$inject = [
  'config',
  'contextPad',
  'palette',
  'create',
  'elementFactory',
  'injector',
  'translate',
  'elementTemplates'
];
