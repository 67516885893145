import {ContextAware, FormReference, GenericFieldParams} from "./generic_component_base";
import * as React from "react";
import {FunctionComponent, JSX, useEffect} from "react";
import ValueFactories from "./ValueFactories";
import {
    FieldGroupTemplate,
    FieldOrGroupCheck,
    FileChangeCallback,
    FormValues,
    GroupCheck,
    RepeatableCheck,
    ValueChangeCallback
} from "./FormContainerBase";

import _entries from 'lodash/entries'
import {v4 as uuidv4} from 'uuid';
import {IntlShape, useIntl} from "react-intl";
import {Layout} from "./LayoutContext";
import {FormPropertyValidationConstraint} from "./camunda_helper";
import {useFormContext, UseFormMethods} from "react-hook-form";
import formValidationUtil, {IndexDecoratorFormValidationUtilFactory} from "../utils/formValidation.util";
export interface RepeatableParams extends ContextAware {
    id: string;
    name: string;

    label?: string;
    properties?: {[key:string]:string};
    validationConstraints?: FormPropertyValidationConstraint[];

    /**
     * FieldGroupTemplate in case of group === true
     * GenericFieldParams in case of group !== true
     */
    template: FieldGroupTemplate | GenericFieldParams;
    config?: any;
    values: FormValues[];
    valueChangeCbk: ValueChangeCallback;
    fileChangeCbk: FileChangeCallback;
    group?: boolean;
    checkMessages?: RepeatableCheck;
    designMode?: boolean;
    intl: IntlShape
    layout: Layout;

    visibleElements?: number;
    methods?: UseFormMethods;
}

export type RepeatableState = {
    idxGenerator:number,
    selectedGroup?: any
}

export abstract class RepeatableBase extends React.Component<RepeatableParams, RepeatableState> {

    valueChangeCbk(_index,useHolder?): ValueChangeCallback {
        const currIndx = this.props.values?.length ?? 0;
        const holder = useHolder ? (this.props.values?.slice() ?? []): this.props.values;
        return async (key, value): Promise<void> => {
            const idx = _index

            const uuid = uuidv4();
            if (!value.uuid) {
                value.uuid = uuid;
            }

            if (idx !== -1) {
                holder[_index] = value;
            }else{
                holder.push(value);
            }
            this.props.valueChangeCbk(key, holder);
        }
    }

    fileChangeCbk = (_index,useHolder?)=>(key,value)=> {
        const idx = _index;

        let newKey;
        if (this.props.group){
            const lastIdx = key.lastIndexOf(".");
            const prefix = key.substring(0,lastIdx);
            const suffix = key.substring(lastIdx);
            newKey = `${prefix}[${idx}]${suffix}`
        }else{
            newKey = `${this.props.name}[${idx}].${key}`
        }
        this.props.fileChangeCbk(newKey,value);
    }

    addNewValue = () =>{
        const template = this.props.template as FieldGroupTemplate;
        const provider = {};
        const formValues = ValueFactories.GroupComponentBase(template,provider)

        if (formValues && !formValues.uuid){
            formValues.uuid = uuidv4();
        }
        return formValues as FormValues;
    }

    addNew = (_event)=>{

        const newIdx = this.props.values.length+1;
        const [newValue,newEntry] = this.entryFactory(undefined,newIdx,[],this.props.formReference,this.props,this.props.intl,true);
        this.props.values.push(newValue);
        this.props.valueChangeCbk(this.props.name,[...this.props.values]).then(()=>{
            //Do nothing
        });
    }

    deleteInIndex = (idx:number) => {
        return _event => {
            _event.preventDefault();

            //delete section's file fields
            Object.keys(this.props.template)
                .filter(fieldName => this.props.template[fieldName].typeName === "file" && this.props.values[idx][fieldName])
                .forEach(fieldName => this.fileChangeCbk(idx)(`${this.props.name}.${fieldName}`, undefined));

            this.props.values.splice(idx, 1);
            this.props.valueChangeCbk(this.props.name, [...this.props.values]).then(()=>{
                //Do nothing
            });
        }
    }

    isReadOnly(): boolean {
        if(this.props.layout === 'review') {
            return true;
        }
        if (this.props.group){
            const template = this.props.template as FieldGroupTemplate;
            return Object.keys(template).every(key=>{
                const _template = template[key];
                return _template.validationConstraints.some(v=>v.name === 'readonly')
            })
        }else{
            const template  = this.props.template as GenericFieldParams
            return template.validationConstraints.some(v=>v.name === 'readonly')
        }
    }

    abstract renderGeneric(idx,template,value,src);
    abstract renderGroup(idx: number, template: FieldGroupTemplate, value: FormValues, readOnly: boolean, checkMessages:GroupCheck | undefined,formReference:FormReference,src:RepeatableParams): JSX.Element;
    abstract renderGroupWithDeleteBtn(idx: number, template: FieldGroupTemplate, value: FormValues, readOnly: boolean, checkMessages:GroupCheck | undefined,formReference:FormReference,src:RepeatableParams): JSX.Element;
    abstract entryRenderer():RendererType;
    renderGroupRow(idx: number, template: FieldGroupTemplate, value: FormValues, readOnly: boolean, checkMessages:GroupCheck | undefined,formReference:FormReference,src:RepeatableParams,isNew:boolean): JSX.Element {

        const hasIdentifiers = Object.keys(this.props.template).some(key=>{
            const _template = this.props.template[key];
            return _template.properties.identifier !== undefined;
        });

        if (this.isReadOnly() || !hasIdentifiers){
            return this.renderGroup(idx,template,value,readOnly,checkMessages,formReference,src);
        }else{
            const _isNew = value._isNew;
            delete value._isNew;
            return <RepeatableEntryRow idx={idx}
                                       id={this.props.id}
                                       name={this.props.name}
                                       maxIdx={this.props.values.length}
                                       template={template}
                                       value={value}
                                       readOnly={readOnly}
                                       checkMessages={checkMessages}
                                       formReference={formReference}
                                       src={src}
                                       contextEvaluator={this.props.contextEvaluator}
                                       Renderer={this.entryRenderer()}
                                       deleteInIndex={this.deleteInIndex.bind(this)}
                                       fileChangeCallback={this.fileChangeCbk(idx).bind(this)}
                                       renderGroup={this.renderGroup.bind(this)}
                                       valueChangeCallback={this.valueChangeCbk(idx).bind(this)}
                                       isNew={isNew || _isNew}
            />
        }
    };
    abstract renderEntry(idx,readOnly,component,key?:string);

    entryFactory = (value: FormValues | undefined, idx: number, checkMessages: FieldOrGroupCheck | undefined,formReference:FormReference,src:RepeatableParams,intl:IntlShape,isNew:boolean): [FormValues, JSX.Element] => {
        let component,
            formValues: FormValues;
        const readOnly = this.isReadOnly();

        if (!this.props.group){
            const template = this.props.template as GenericFieldParams;
            formValues = value === undefined
                ? ValueFactories.GenericFieldComponentBase(template)
                : value as FormValues;

            if (formValues && !formValues.uuid){
                formValues.uuid = uuidv4();
            }

            component = this.renderGeneric(idx,template,formValues,this.props);
        }
        else {
            const template  = this.props.template as FieldGroupTemplate;
            formValues = value === undefined
                ? ValueFactories.GroupComponentBase(template,{})
                : value as FormValues;
            if (!formValues.uuid){
                formValues.uuid = uuidv4();
            }
            const hasIdentifiers = Object.keys(template).some(key=>{
                const _template = template[key];
                return _template.properties.identifier !== undefined;
            });


            const designMode = this.props.designMode;

            if (hasIdentifiers && !designMode){
                component = this.renderGroupRow(idx, template, formValues, readOnly, checkMessages as GroupCheck | undefined,formReference,src,isNew);
            }else{
                if (readOnly){
                    component = this.renderGroup(idx, template, formValues, readOnly, checkMessages as GroupCheck | undefined,formReference,src);
                }else{
                    component = this.renderGroupWithDeleteBtn(idx, template, formValues, readOnly, checkMessages as GroupCheck | undefined,formReference,src);
                }
            }

        }

        formValues._isNew = isNew;

        return [formValues as FormValues, this.renderEntry(idx,readOnly,component,formValues?.uuid)];
    }

    checksForSection = (v:FormValues) =>{
        const uuid = v.uuid;
        if (uuid && this.props.checkMessages){
            const allCheckMessages = this.props.checkMessages as GroupCheck[]
            return allCheckMessages.map(cm=>{
                return Object.keys(cm).reduce((acc,cm_fieldId)=>{
                    const fieldChecks = cm[cm_fieldId];
                    const new_values = fieldChecks.filter(fc=>{
                        return fc.sectionuuid === uuid || fc.fields && fc.fields.indexOf(uuid+':'+cm_fieldId) !== -1;
                    })
                    acc[cm_fieldId] = new_values;
                    return acc;

                },{} as GroupCheck)
            }).filter(cm=>Object.keys(cm).length > 0)
        }else{
            return [] as RepeatableCheck;
        }
    }



    render(): JSX.Element {
        const readonly = this.isReadOnly();

        const allCheckMessages = this.props.checkMessages;

        const label = this.props.label
        const properties = this.props.properties
        const helperText = properties?.helperText
        const entryHelperText = properties?.entryHelperText
        const validationConstraints = this.props.validationConstraints

        let provider:FormValues[] | undefined = undefined;
        if (Array.isArray(this.props.values)){
            provider = this.props.values;
        }else if (typeof this.props.values === 'string'){
            try {
                provider = JSON.parse(this.props.values);
                if (!Array.isArray(provider)){
                    console.warn('RepeatableBase: values is not an array',provider)
                }
            }catch (e){
                console.warn('RepeatableBase: values is not an array',provider)
            }
        }else{
            console.warn('RepeatableBase: values is not an array',provider)
        }
        const entries: JSX.Element[] = provider
            ? provider.map((v: FormValues, i: number): JSX.Element => {
                //@ts-ignore
                const checkMessages:GroupCheck[] = this.checksForSection(v)
                const _c2:GroupCheck[] =  checkMessages.filter(c=>{
                    const en = _entries(c) as any[];
                    const allEmpty = en.every(([k,v])=>v.length === 0)
                    return !allEmpty;
                })
                const _cm = _c2.reduce((acc,c)=>{
                    Object.keys(c).forEach(cField=>{
                        const cChecks = c[cField];
                        if (acc[cField]){
                            const non_existent_cChecks = cChecks.filter(i=>{
                                return acc[cField].map(c=>c.uuid).indexOf(i.uuid) === -1
                            });
                            acc[cField].push(...non_existent_cChecks)
                        }else{
                            acc[cField] = cChecks
                        }
                    })

                    return acc;
                },{})
                // console.debug('checkMessages',checkMessages,_cm)
                const [nv, ne]: [FormValues, JSX.Element] = this.entryFactory(v, i, _cm,this.props.formReference,this.props,this.props.intl,false);
                return ne;
            })
            : [];

        return this.doRender(readonly, entries,label,properties);
    }

    abstract doRender(readonly: boolean, entries: JSX.Element[],label?:string,properties?:{[key:string]:string}): JSX.Element;
}


export type RendererType = FunctionComponent<RepeatableGroupRowRendererParams>;

const RepeatableEntryRow = (props: {
    id:string
    name:string,
    idx: number,
    maxIdx:number,
    template: FieldGroupTemplate,
    value: FormValues,
    readOnly: boolean,
    checkMessages:GroupCheck | undefined,
    formReference,
    src,
    deleteInIndex,
    contextEvaluator,
    Renderer:RendererType,
    renderGroup:RepeatableBase['renderGroup']
    valueChangeCallback:ValueChangeCallback
    fileChangeCallback:FileChangeCallback,

    isNew:boolean
}) => {
    const intl = useIntl();
    const methods = useFormContext();

    const hasError = methods && methods.formState.errors[props.name] && methods.formState.errors[props.name][props.idx];

    const __values = methods.getValues()[props.name];
    const _values = __values && __values[props.idx];
    const formValidationUtil = new IndexDecoratorFormValidationUtilFactory(props.idx,props.name,intl)
    const _keys = _values && Object.keys(_values).map(k=>{
        const _k = formValidationUtil.fieldValidationKeyFactory(k);
        return _k;
    });
    const hasErrorBool = !!hasError;
    const _keyLength = _keys?.length ?? 0;

    useEffect(()=>{
        if (hasError){
            console.log('hasError',props.id,props.idx,hasError)
        }else if (_values){
            const key = `${props.name}.${props.idx}`
            methods.trigger(_keys).then(r => {
                console.debug('trigger', props.name,props.idx, r)
                const errors = methods.formState.errors[props.name] && methods.formState.errors[props.name][props.idx];
                if (errors) {
                    console.log('errors', props.name, props.idx, errors)
                }
            });
        }
    },[hasErrorBool,_keyLength])

    const asString = (template:FieldGroupTemplate,value:FormValues):string =>{
        const keys = Object.keys(template).filter(k=>{
            const t = template[k];
            return t.properties.identifier;
        });

        const ret = keys.map(k=>{
            const v = value[k];
            return v?.toString() ?? '';
        }).join(' ');

        return ret;
    }


    const asText = asString(props.template,props.value)
    const Renderer = props.Renderer;





    const errorTexts = hasError && Object.keys(props.template).map(key=>{
        const errorEntry = methods?.formState.errors[props.name][props.idx][key]
        const errorText = errorEntry && errorEntry.message;
        if (errorText){
            const label = props.template[key].label ?? key;
            return [label,errorText];
        }else{
            return false;
        }
    }).filter(t=>t).map(t=>{
        const [label,errorText] = t as [string,string]
        return <li>{label}: {errorText}</li>
    })

    // const errorText = errorTexts? <ul>{errorTexts}</ul> : undefined;

    const errorText = hasErrorBool && intl.formatMessage({
        id:'errorInRepeatable',
        defaultMessage:'There are missing / wrong entries for this record',
        description:'Repeatable Component Has Errors'
    }) || undefined;


    return <Renderer
        id={props.id}
        idx={props.idx}
        maxIdx={props.maxIdx}
        value={props.value}
        hasError={hasError != undefined}
        errorText={errorText}
        asText={asText}
        deleteInIndex={props.deleteInIndex}
        checkMessages={props.checkMessages}

        fileChangeCbk={props.fileChangeCallback}
        formReference={props.formReference}
        renderGroup={props.renderGroup}
        src={props.src}
        template={props.template}
        valueChangeCbk={props.valueChangeCallback}

        isNew={props.isNew}
    />
}

export type RepeatableGroupRowRendererParams = {
    id:string,
    idx:number,
    maxIdx:number,
    value,
    hasError:boolean,
    errorText?:string | JSX.Element,
    asText,
    deleteInIndex,
    checkMessages,
    formReference,
    renderGroup:RepeatableBase['renderGroup']
    src,
    template,
    valueChangeCbk:ValueChangeCallback,
    fileChangeCbk:FileChangeCallback,

    isNew:boolean,
    addButtonRef?:HTMLElement
}