import {
    FormData,
    FormField,
    ProcessDefinition,
    FormProperty, isStateEnded,
    ProcessInstanceStatus,
    StartFormData,
    TaskFormData,
    Variables, PIEV, FormPropertyValidationConstraint
} from "./camunda_helper";
import * as React from "react";
import {JSX} from "react"
import {BaseSyntheticEvent, Context, createRef, CSSProperties, RefObject, useContext} from "react";
import * as _ from 'lodash';
import {makeShouldRender, registerProcessSSE} from "./FormContainerUtils";
import {ContextAware, GenericFieldParams} from "./generic_component_base";
import ValueFactories from "./ValueFactories";

import contextPath, {setContextPath} from "./contextPath";
import {buttonLabelSubstitutions, ButtonParams} from "./action_buttons_base";
import {
    ContextVariable,
    makeContextEvaluator,
    SancusConditionEvaluator
} from "./conditionEvaluator.service";
import {RepeatableGroupComponentParams} from "./RepeatableGroupBase";
import { GroupComponentParams} from "./GroupBase";

import {getStore} from "../store";
import FormHandlerContext, {FormHandlerStrategy} from "./handlers";
import transform from "../CustomerData";
import {FormContainerActions} from "../store/actions/formContainer.actions";
import setError = FormContainerActions.setError;
import {FormBuilderProps} from "./FormBuilderBase";
import LayoutContext, {isValidLayout, Layout, useLayout} from "./LayoutContext";
import {convert} from "./action_button_utils";
import _pickBy from "lodash/pickBy";
import {IntlProvider, IntlShape, RawIntlProvider, useIntl} from "react-intl";
import {hashCode} from "../utils/utils";
import {
    useForm,
    FormProvider,
    useFormContext,
    SubmitHandler,
    UseFormMethods,
    FieldErrors,
    ErrorOption
} from "react-hook-form";
import {LanguageContext} from "./LanguageContext";
import { FormContainerContext } from "./FormContainerContext";
import {fidesConfigApi} from "./fidesConfig.service";
import {ICheck, GroupCheck,RepeatableGroupCheck, RepeatableCheck,ILoggedUserState,mergeServiceGroups} from "./Check.utils";
import {Options as IntlMessageFormatOptions} from "intl-messageformat/src/core";
import setValidationError = FormContainerActions.setValidationError;
import EditFieldContext from "./EditFieldContext";
import {RootState} from "../store/reducers/root.state";
import formFieldValidationUtil, {IndexDecoratorFormValidationUtilFactory} from "../utils/formValidation.util";

export * from "./Check.utils";


declare global{
    interface Window {
        messages:{[id:string]:[string,Error]}
    }
}

export type NewFormCbk = (taskId:string,processId:string,formData:FormData | ProcessInstanceStatus)=>void
export type TaskHistoryElement = {
    taskDefs:string[],
    taskDefIdx:number,
    stepName:string,
    visited:boolean,
    current:boolean
    taskDefData:{
        fullId:string,
        startForm: boolean
        stepName: string,
        taskName: string,
        taskDefId:string,
        visited?:boolean
    }[]
}

export type FormView =  FieldsFormView | {document:Document}
export type FieldsFormView = {
    id?:string | null,
    formDefId?:string | null,
    title:string,
    description:string,
    fields: {section:string | undefined,element:JSX.Element, visualSection?: string}[]
    actions:string | string[]
}

export type FormCallbacks = {
    beforeInstanceEnter?:(arg:(StartFormData | [string,TaskFormData | ProcessInstanceStatus]))=>boolean,
    instanceEnter?:(processInstanceId:string)=>void,
    taskEnter?:(taskId:string)=>void,
    taskExit?:(taskId:string)=>void,
    instanceExit?:(processInstanceStatus:ProcessInstanceStatus)=>void,
    loading:(_l:boolean)=>void
    error:(err:any)=>boolean
    formRendered:(formId,height,width)=>void
}

export type FormContainerProps = //Would not work with union obviously
    {
        processDefinitionKey?:string,
        resumeState?:boolean
    } &
    { processDefinitionId?:string } &
    { processInstanceId?:string} &
    {taskId?:string } & {
        noStep?:boolean | string
        renderLoading?:boolean | string
        showSections?:boolean | "index"
        hideStepper?:boolean | string
        displayLogin?:boolean
        useInternalGlobalLoader?: boolean
        callbacks?:FormCallbacks,
        contextPath?:string,
        loggedUserState?: ILoggedUserState
    }  & {
        variables:{[key:string]:any }
    } & {
        intl: IntlShape,
        theme: any;
        layout?: "default" | "facing" | 'review',
        renderActionContainer?: boolean | "true" | "false"
        methods/*: UseFormMethods*/
    } & {
    formBuilderProps?: FormBuilderProps
};



type eval_expression = string;

export type DialogData = {
    name: string,
    label: string,
    description?: string,
    actions?: IActions,
    OkHandler: (action?: string) => void | Promise<undefined | void>
}

export type IActionJson = {
    id?:string, //for future reference - should take the value if null
    "value": string,
    "label"?: string,
    "dialog"?: Omit<DialogData, 'OkHandler'>,
    visible?:eval_expression,
    disabled?:eval_expression,
    disabled_tooltip?:eval_expression,
    tooltip?:eval_expression,
    render?:ButtonParams['render'],

};


export type IActions = (IActionJson | string)[];

export type FormContainerState = {
    formView:FormView,
    endStates:PIEV[],
    formData?:FormData | ProcessInstanceStatus,
    formFields?:FormField[],
    embeddedFormDocument?:Document | null | undefined,
    processDefinitionId?:string,
    processInstanceId?:string | null,
    taskId?:string | null
    formId?:string | null,
    formDefId?:string | null,
    currentTaskIdx:[number,number],
    taskHistory:TaskHistoryElement[]
    externalScripts:HTMLScriptElement[],
    afterRender?:()=>void,
    inProgress?:boolean,
    error?:any,
    values:FormValues,
    files?:{[key:string]:any}
    submit:string | null
    checks?: ICheck[],
    annotator:{
        edits:{[fieldId:string]: any}
        annotations:{[fieldId:string]: { value:string,public:boolean,level:'info' | 'error' | 'warning' | 'success' }},
        annotatorPopup:{fieldId:string | null, mode:string | null} | null
    },
    mustShowSections?: FormContainerProps['showSections'],
    showStepper?:boolean,
    formTaskExtensionProps: ExtractedFormTaskExtensionProps;
}

export type GroupByStrategy = 'task' | 'visual_section';

// TODO
export type FormFieldValue = any;

/**
 * Form fields and groups value mapper.
 */
export interface FormValues {
    /**
     * Field name maps to FormFieldValue
     * Group name maps to FormValues[]
     */
    [fieldOrGroupName: string]: FormFieldValue | FormValues[];
}

export interface FieldGroupTemplate {
    [fieldName: string]: GenericFieldParams;
}

export interface FieldGroupInfo {
    repeatable: boolean;
    section?: string;
    template: FieldGroupTemplate;
    label?: string;
    properties?: {[key:string]:string};
    validationConstraints?: FormPropertyValidationConstraint[];
}

export type ContextAwareFieldGroupInfo = FieldGroupInfo & ContextAware;

export const toContextAwareFieldGroupInfo = (fieldGroupInfo: FieldGroupInfo, contextEvaluator: SancusConditionEvaluator,formReference): ContextAwareFieldGroupInfo => {
    return {
        ... fieldGroupInfo,
        contextEvaluator: contextEvaluator,
        formReference
    }
}
/**
 * Grouped fields by group name mapper. The empty '' key contains non grouped fields.
 */
export interface GroupedFields {
    [groupName: string]: FieldGroupInfo;
}

export const extractContextVariables = (template: FieldGroupTemplate): ContextVariable[] => {
    return Object.keys(template).map(fieldName => {
        const {id, value, _value} = template[fieldName];
        return {
            variableName: id,
            variableValue: value && value.value || _value,
        };
    });
};

const getContextVariablesFromGroupedFields = (fieldsGrouped, _values): ContextVariable[] => {
    return Object.keys(fieldsGrouped)
        .map((groupName: string): FieldGroupInfo => fieldsGrouped[groupName])
        .filter(fieldGroup => !!fieldGroup && !!fieldGroup.template && !fieldGroup.repeatable)
        .map((fieldGroup: FieldGroupInfo): FieldGroupTemplate => fieldGroup.template)
        .reduce((acc: ContextVariable[], fieldGroupTemplate: FieldGroupTemplate) => ([
            ... acc,
            ... extractContextVariables(fieldGroupTemplate),
        ]), [])
        .map((variable: ContextVariable): ContextVariable => {
            return {
                ... variable,
                variableValue: _values.hasOwnProperty(variable.variableName)
                    ? _values[variable.variableName]
                    : variable.variableValue,
            }
        });
};

//TODO
export interface FileChangeCallback {
    (key: string, value: any): void;
}

//TODO
export interface ValueChangeCallback {
    (key: string, value: FormFieldValue, submit?: boolean): Promise<void>;
}

export interface FormContainerRenderers{
    RepeatableGroupComponent:React.ComponentType<RepeatableGroupComponentParams>,
    GroupComponent:React.ComponentType<GroupComponentParams>,
    GenericFieldComponent:React.ComponentType<GenericFieldParams>
}

interface ExtractedFormTaskExtensionProps {
    layout: Layout;
    [key: string]: string;
}

const extractFormExtensionPropsOrDefault = (formData: TaskFormData | StartFormData | ProcessInstanceStatus): ExtractedFormTaskExtensionProps => {
    if (!formData){
        return extractLayoutFromExtensionsOrDefault({});
    }

    const extOfForm = ((formData as TaskFormData) && (formData as TaskFormData).task && (formData as TaskFormData).task.extensions) ||
        ((formData as StartFormData) && (formData as StartFormData).processDefinition && (formData as StartFormData).processDefinition.extensions);

    const extOfStatus = {}
    var _p = formData as ProcessInstanceStatus;

    var {activeStates,endStates} = _p;

    activeStates && activeStates.forEach(a=>{
        a.attributes && Object.assign(extOfStatus,a.attributes)
    })

    endStates && endStates.forEach(a=>{
        a.attributes && Object.assign(extOfStatus,a.attributes)
    })


    const ext = extOfForm || extOfStatus || {};
    return extractLayoutFromExtensionsOrDefault(ext);
};

const extractLayoutFromExtensionsOrDefault = (extensions: {[key: string]: string}): { layout: Layout } => {
    const LAYOUT_EXT_PROP = 'sancus.form.layout';
    const defaultLayout = 'default';


    const allExtensions = Object.keys(extensions).reduce((acc,k)=>{
        if (k.startsWith('sancus.form')){
            const nk = k.substring('sancus.form.'.length)
            acc[nk] = extensions[k];
        }
        return acc;
    },{});

    return {
        ...allExtensions,
        layout: isValidLayout(extensions[LAYOUT_EXT_PROP])
            ? extensions[LAYOUT_EXT_PROP] as Layout
            : defaultLayout
    };
};

export abstract class FormContainerBase extends React.Component<FormContainerProps, FormContainerState> {
    declare context:React.ContextType<Context<FormHandlerStrategy & {getUserSessionInfo:any, logoutUserSession:any}>>
    protected wrapCallbacks(callbacks) {
        console.debug("Default wrap callbacks");
        return callbacks;
    }


    protected eventSource?: EventSource
    protected setEventSource: (eventSource) => void;
    protected intervalHandler?: number | null;

    static groupByStrategy: GroupByStrategy = 'visual_section';
    protected globalContextEvaluator: SancusConditionEvaluator;
    private unsubscribeStore: () => void;  // used for Redux subscription on store
    private unsubscribeServerValidation: () => void;  // used for Redux subscription on store
    static contextType = FormHandlerContext
    private readonly callbacks?:FormCallbacks


    protected formRef = createRef<any>()
    protected constructor(props:FormContainerProps,protected renderers:FormContainerRenderers) {
        super(props);

        let startFormData:StartFormData | undefined;
        if(this.props.formBuilderProps) {
            const processDefinition:ProcessDefinition = {id: "_processDef_id", key: "_processDef_key", name:this.props.formBuilderProps.name,description:this.props.formBuilderProps.description}
            startFormData = {deploymentId: "_deploymentId", formFields:this.props.formBuilderProps.formFields , processDefinition}
        }

        this.state = {
            submit: null,
            values: {},
            formView:{} as FormView,
            currentTaskIdx:[-1,-1],
            taskHistory:[] as TaskHistoryElement[],
            externalScripts:[] as HTMLScriptElement[],
            endStates:[],
            formData: this.props.formBuilderProps && startFormData,
            formFields: this.props.formBuilderProps && this.props.formBuilderProps.formFields,
            formTaskExtensionProps: extractFormExtensionPropsOrDefault({} as any),
            annotator: {
                annotations: {},
                edits:{},
                annotatorPopup: null
            }
        } as FormContainerState

        this.setEventSource = eventSource=>{
            this.eventSource = eventSource;
        }

        if (props.contextPath){
            setContextPath(props.contextPath)
        }

        this.unsubscribeStore = () => {};
        this.unsubscribeServerValidation = ()=>{};

        this.globalContextEvaluator = makeContextEvaluator({
                    variables: []
                });
        // this.redirectService = new RedirectHelper()
        console.debug("Wraping callbacks")
        this.callbacks = this.wrapCallbacks(this.props.callbacks);
    }

    abstract unstable_batchUpdate(cbk:()=>void);

    handleRefreshTasks = async (_event?:BaseSyntheticEvent)=>{
        const processInstanceId = this.state.processInstanceId;
        const [taskId,_formData] = await this.context.handleProcessInstanceId(processInstanceId!);
        const newProcessInstanceId = (_formData as ProcessInstanceStatus).processInstanceId || processInstanceId;
        await this.newStateCbk(taskId,newProcessInstanceId,_formData);
        return  [taskId,_formData];
    }

    handleInterrupt = async (executionId,signalName,_event?:BaseSyntheticEvent)=>{
        _event && _event.preventDefault();
        await this.context.sendSignal(executionId,signalName);
        await this.handleRefreshTasks();
    }



    fetchTaskListHandler = async (taskId,_processInstanceId): Promise<void>  => {
        const intl = this.props.intl

        const identifier = this.props.processDefinitionId || this.props.processDefinitionKey;
        const idr = this.props.processDefinitionId ? 'id' : 'key';

        const processInstanceId = _processInstanceId || this.state.processInstanceId || this.props.processInstanceId;
        let taskList;
        try{
            taskList = await this.context.fetchTaskList(idr,identifier!,processInstanceId,taskId);
        }catch (e){
            console.warn(e);
            return;
        }
        taskList = taskList.map(t => {
            let stepId = Object.keys(t.taskDefs)[0];
            const processDefinitionKey = this.props.processDefinitionKey;
            const stepName = intl.formatMessage({
                id: `${processDefinitionKey}.${stepId}`,
                description: t.stepName,
                defaultMessage: t.stepName
            })

            return {
                stepName: stepName,
                taskDefs:Object.keys(t.taskDefs),
                taskDefData:Object.keys(t.taskDefs).map((_t,_i)=>{
                    const td = t.taskDefs[_t]
                    return {
                        ...td,
                        taskDefId:_t,
                        visited: t.startForm && _i ==0,


                    }
                }),
                taskDefIdx:t.startForm?0:-1,
                visited:t.startForm,
                current:t.startForm
            }
        });

        return this.setState({taskHistory: taskList});
    };

    //[]
    setupContinueProcessOrTask = async () => {

        const noStep = this.props.noStep === 'true' || this.props.noStep === true

        let handleFN:()=>Promise<any> = undefined as any;
        let newStateFN:(data)=>Promise<void> = undefined as any;

        const taskHistory = this.state.taskHistory;
        const isTaskHistoryEmpty = !(taskHistory && taskHistory[0]);


        if (this.props.taskId) {
            const taskId = this.props.taskId;
            handleFN = () => this.context.handleTask(taskId);
            newStateFN = (data) => {
                const [_taskId, _formData] = data;
                return this.newStateCbk(_taskId, _formData.task.processInstanceId, _formData);
            }
            handleFN().then(data => {
                /*if (isTaskHistoryEmpty) {
                    this.fetchTaskListHandler().then(() => newStateFN(data));
                } else {
                    return newStateFN(data);
                }*/
                return newStateFN(data);
            }).catch((error) => {
                this.errorCbk(error);
                // if (e.message.includes('SE:')) {
                    // const errorType = e.message.split(':')[1];
                    // // In case of unauthorised error break process for login first
                    // if (errorType === 'Unauthorized') {
                    //     this.redirectService
                    //         .redirect(`${contextPath}enter.html?key=login_otp&prev_url=${window.location.href}`)
                    // }
                // }
            });
        } else {
            if (this.props.processInstanceId) {
                handleFN = () => this.context.handleProcessInstanceId(this.props.processInstanceId!)
                newStateFN = (data) => {
                    const [_taskId,_formData] = data;
                    return this.newStateCbk(_taskId,this.props.processInstanceId,_formData);
                };
            } else if (this.props.processDefinitionKey){
                const resumeState = this.props.resumeState
                handleFN = async () => {
                    try {
                        const r = await this.context.handleProcessKey(this.props.processDefinitionKey!, !!resumeState);
                        if (this.props.callbacks?.beforeInstanceEnter){
                            this.props.callbacks.beforeInstanceEnter(r);
                        }
                        return r
                    }catch (e){
                        throw e;
                    }
                }
                newStateFN = (data) => {
                    if (Array.isArray(data)){
                        const [_taskId,_formData] = data;
                        return this.newStateCbk(_taskId, _formData.task.processInstanceId, _formData).then(data=>{
                            return data;
                        });;
                    } else if (data != null){

                        return this.newStateCbk(null, null, data).then(data=>{
                            return data;
                        });
                    } else {
                        return Promise.reject("handleProcessKey returned null");
                    }
                }
            }
            const processDefinitionKey = this.props.processDefinitionKey
            const processDefinitionId = this.props.processDefinitionId || this.state.processDefinitionId
            const processInstanceId = this.props.processInstanceId || this.state.processInstanceId

            let chain;
            if (isTaskHistoryEmpty && (processDefinitionId || processDefinitionKey)) {
                const idr = processDefinitionId ? 'id' : 'key';
                const identifier = processDefinitionId || processDefinitionKey;


                // if (!noStep){
                //     //Moved inside newStateCbk
                //     chain = this.context.fetchTaskList(idr,identifier!,processInstanceId)
                //         .catch(err=>{
                //             console.warn(err);
                //             return [];
                //         })
                //         .then(fetchTaskListHandler)
                //         .then(taskList => {
                //             this.setState({taskHistory: taskList});
                //         })
                // }else{
                    chain = Promise.resolve()
                // }
                chain
                    .then(handleFN)
                    .catch(error=>{
                        this.errorCbk(error);
                        throw error;
                    })
                    .then(data => newStateFN(data))
                    .catch(err=>{
                        console.log("Has error in form",err);
                        this.errorCbk(err)
                    })

            } else if (isTaskHistoryEmpty && processInstanceId){

                if (!noStep){
                    chain = this.context.getProcessInstanceStatus(processInstanceId);
                }else{
                    chain = Promise.resolve()
                }

                chain
                    .then(handleFN)
                    .then(data => newStateFN(data))
                    .catch(err=>{
                        this.errorCbk(err);
                    })

            }

        }

        return () => {
        }
    }
    //[processInstanceId]
    setupRegisterSSE = (prevInstanceId?:string | null)=>{
        const processInstanceId = this.state.processInstanceId;

        if(processInstanceId !=null && processInstanceId !=prevInstanceId){
            if (prevInstanceId && this.eventSource){
                console.debug(`Closing SSE for ${prevInstanceId} and opening for ${processInstanceId}`)
                this.eventSource.close();
            }

            console.debug(`Registering SSE for ${processInstanceId}`);
            const eventSource = registerProcessSSE(processInstanceId, messageStr => {
                let message;
                try{
                    message = messageStr && JSON.parse(messageStr);
                }catch (e) {
                    message  = messageStr;
                }
                console.debug('SSE Message',message)

            })

            this.setEventSource(eventSource);
        }
    }
    //[externalScripts]
    setupLoadExternalScripts = (prevExternalScripts?:HTMLScriptElement[])=>{
        const prevScriptSrcs = prevExternalScripts && prevExternalScripts
            .map(p=>p.src)
            .filter(src=>src != null) || [];

        const externalScripts = this.state.externalScripts;

        externalScripts.map(async script=>{
            if (script.src){
                if (prevScriptSrcs.indexOf(script.src) != -1){
                    return null;
                }
                try {
                    const rsp = await fetch(script.src, {
                        method: 'get',
                        credentials: 'include',
                    });
                    const txt = await rsp.text();
                    if (rsp.status >= 300) {
                        throw new Error(JSON.parse(txt))
                    } else {
                        return eval(txt);
                    }
                }catch (e){
                    console.log("Failed to parse script {}",script.src,e)
                }
            }
        })
    }

    checkRefreshTaskStatus = ()=>{
        if (this.state.taskId == 'paused'){
            if (!this.intervalHandler){
                this.intervalHandler = window.setInterval(async () => {
                    const [taskId,_formData] = await this.handleRefreshTasks();
                }, 5000)
            }
        }else{
            if (this.intervalHandler){
                window.clearTimeout(this.intervalHandler);
                this.intervalHandler = null;

            }
        }
    }

    getCurrentStateFromStore():RootState {
        const store = getStore();
        const state = store.getState();
        return state;
    }

    updateServerValidation = () => {
        const store = getStore();
        const currentState = this.getCurrentStateFromStore();
        const methods:UseFormMethods = this.props.methods;
        if (currentState.validationError) {
            const {status, data} = currentState.validationError;
            const{error,type} = data;
            const {id,name,config,value,message,path,isArray,idx} = error;

            const clientMessage = formFieldValidationUtil(this.props.intl).formatMessage(name,isArray ? [config,true]:config);

            const fieldId = path ?? id;

            const errorOpts = {message:clientMessage,type:name } as ErrorOption
            errorOpts['config'] = config;
            errorOpts['origin'] = 'server'
            methods.setError(fieldId, errorOpts);
            store.dispatch(setValidationError(null));
        }
    };

    updateStateFromStore = () => {
        const currentState = this.getCurrentStateFromStore();

        if (this.state.error !== currentState.error) {
            this.setState({error: currentState.error});
        }

        if (this.state.annotator !== currentState.annotator) {
            this.setState({annotator: currentState.annotator});
        }


        if (currentState.valueChanged) {
            const {key, value, submit} = currentState.valueChanged;
            console.debug('updateStateFromStore  currentState.valueChanged',currentState.valueChanged);
            this.valueChangeCbk(key, value, submit).then(()=>{
            });
            // clear stored form values changes
            getStore().dispatch({
                type: 'TRIGGER_VALUE_CHANGE',
                payload: null,
            });

        }
    }


    componentDidMount() {
        console.debug("componentDidMount");

        const {errors} = this.props.methods;

        const store = getStore();
        this.unsubscribeStore = store.subscribe(this.updateStateFromStore);

        this.unsubscribeServerValidation = store.subscribe(this.updateServerValidation);


        this.setupContinueProcessOrTask()
            .then(()=>{
                console.debug("setupContinueProcessOrTask done")
            }).catch(error=>{
                console.debug("Failed setupContinueProcessOrTask",error);
                this.errorCbk(error)
        });
        // this.setupRegisterSSE();
        this.setupLoadExternalScripts()

        this.state.afterRender && this.state.afterRender();

        this.doAfterRenderCallback("mount");
    }

    protected abstract doAfterRenderCallback(phase:"mount" | "update"): void;
    protected abstract doAfterUnmount(): void;
    componentDidUpdate(prevProps: Readonly<FormContainerProps>, prevState: Readonly<FormContainerState>, snapshot?: any) {
        console.debug("componentDidUpdate")
        const {errors} = this.props.methods;

        try {
            const prevInstanceId = prevState.processInstanceId;
            const currentInstanceId = this.state.processInstanceId;
            // this.setupRegisterSSE(prevInstanceId);


            const prevExternalScripts = prevState.externalScripts;
            this.setupLoadExternalScripts(prevExternalScripts)

            this.checkRefreshTaskStatus();
            const prevAfterRender = prevState.afterRender;
            const currentAfterRender = this.state.afterRender;
            if (currentAfterRender && currentAfterRender !== prevAfterRender) {
                currentAfterRender();
            }

            this.doAfterRenderCallback("update");
        }catch (e){
            console.warn("caught exception during component update",e);
            this.errorCbk(e);
        }
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");

        this.unsubscribeStore();
        this.unsubscribeServerValidation();

        const setAuditTask = (payload: any) => {
            return {
                type: 'SET_TIMELINE_ITEMS',
                payload: payload,
            }
        };

        // clear stored AuditTasks
        getStore().dispatch(setAuditTask([]));

        // clear stored form values changes
        getStore().dispatch({
            type: 'TRIGGER_VALUE_CHANGE',
            payload: null,
        });

        // clear opened dialog
        getStore().dispatch({
            type: 'ACTIVE_DIALOG',
            payload: null,
        });

        // clear opened dialog
        getStore().dispatch({
            type: 'ACTIVE_DIALOG',
            payload: null,
        });

        if (this.eventSource !=null){
            this.eventSource.close();
        }

        if (this.intervalHandler){
            clearInterval(this.intervalHandler);
            this.intervalHandler = null;
        }

        this.doAfterUnmount();
    }

    inProgressCbk = (pg:any)=>{
        const currPG = this.state.inProgress;
        if (currPG == pg){
            return;
        }
        this.unstable_batchUpdate(()=>{
            this.setState({inProgress:pg});
            if (this.callbacks?.loading){
                this.callbacks.loading(pg);
            }
            const store = getStore();
            const setInProgress = (value: any) => {
                return {
                    type: 'SET_IN_PROGRESS',
                    payload: value,
                }
            };
            store.dispatch(setInProgress(pg));
        })
    }

    errorCbk = (error: any) => {
        const store = getStore();
        if (error == null){
            //Clear error from click on state
            store.dispatch(setError(error));
        }else{
            const {status,data,message} = error;
            let handled = false;
            if (status === 400 && data?.type === 'fieldValidation'){
                const store = getStore();
                store.dispatch(setValidationError(error));

                handled = true;
            }else if (this.callbacks && this.callbacks.error){
                handled = this.callbacks.error(error);
            }
            if (!handled) {
                store.dispatch(setError(error));
            }
        }
    }

    newStateCbk = async (_taskId:string | null | "ended" | "paused", _processInstanceId: string | null | undefined, _formData:FormData | ProcessInstanceStatus) => {
        console.log(`newStateCbk with taskId:${_taskId} processId:${_processInstanceId}`)
        const noStep = this.props.noStep === 'true' || this.props.noStep === true
        if (!noStep) {
            await this.fetchTaskListHandler(_taskId,_processInstanceId);
        }

        const task = (_formData as TaskFormData).task

        let formId,formDefId;

        const oldProcessInstanceId = this.state.processInstanceId;
        if (_processInstanceId && _processInstanceId!== oldProcessInstanceId){
            if (this.callbacks?.instanceEnter){
                this.callbacks.instanceEnter(_processInstanceId);
            }
        }
        //If at this point we should have valid form data, we should also have valid processId
        const embeddedForm = await this.handleEmbeddedForm(_formData as FormData,(_processInstanceId || oldProcessInstanceId)!)
        const embeddedFormDocument = embeddedForm && embeddedForm.document;



        let newTaskHistory:TaskHistoryElement[] = this.state.taskHistory
        let visitedNode = true;
        let found = false;

        let values:(FormValues | undefined) = undefined;
        let transformedData:(FormField[] | undefined) = undefined;

        if ((_formData as FormData).formFields){
            transformedData = await this.transformData((_formData as FormData).formFields);
            values = this.calculateValues(transformedData);
        }

        let [currentStepIdx,currentTaskIdx]:[number|null,number|null] = [null,null];
        if (task) {
            //If there are no more immediate user tasks , the task will be null
            const oldTaskId = this.state.taskId;
            if (task.id !== oldTaskId){
                if (this.callbacks?.taskEnter){
                    this.callbacks.taskEnter(task.id);
                }
            }
            newTaskHistory = []
            let lastVisitedIndex = -1
            this.state.taskHistory.forEach((taskHistoryElement, idx) => {
                let _taskIdx = taskHistoryElement.taskDefs.indexOf(task.taskDefinitionKey)
                if (taskHistoryElement.visited){
                    lastVisitedIndex = idx;
                }
                if (_taskIdx !== -1) {
                    found = true;
                    currentStepIdx = idx;
                    currentTaskIdx = _taskIdx;
                    taskHistoryElement.taskDefIdx = _taskIdx;
                    taskHistoryElement.taskDefData[_taskIdx].visited = true
                }

                if (visitedNode && _taskIdx === -1) {
                    visitedNode = true;
                } else {
                    visitedNode = false;
                }
                newTaskHistory.push({...taskHistoryElement, visited: visitedNode, current: found})
            })


            if (!found) {
                if (task.extensions && task.extensions['sancus.widget.main_flow']!=='false') {


                    currentStepIdx = lastVisitedIndex + 1;

                    newTaskHistory.splice(currentStepIdx, 0, {
                        stepName: task.name,
                        taskDefs: [task.taskDefinitionKey],
                        taskDefData: [{
                            //FIXME: THis must be tested for nested activities
                            fullId: task.taskDefinitionKey,
                            taskName: task.name,
                            stepName: task.extensions && task.extensions['sancus.widget.step'] || task.name,
                            startForm: false,

                            taskDefId: task.taskDefinitionKey,
                            visited: false
                        }],
                        taskDefIdx: -1,
                        visited: false,
                        current: true
                    })
                }
            }
        }else if (_taskId === 'ended'){
            if (this.callbacks?.instanceExit){
                this.callbacks?.instanceExit(_formData as ProcessInstanceStatus)
            }
        }

        const hasFidesData =  (_formData as FormData).formFields?.find(f => f.type?.name === 'fides');

        const taskExtensions = (_formData as TaskFormData).task?.extensions;
        const startFormExtensions = (_formData as StartFormData).processDefinition?.extensions;

        const extensions = taskExtensions ?? startFormExtensions;
        const showSectionsFromExtension = extensions && extensions['sancus.form.show_sections'];
        const mustShowSections = hasFidesData ?
            hasFidesData.properties.section_index ?
                'index' : true
            : showSectionsFromExtension == 'index'?
                'index':!!showSectionsFromExtension
        const newState:Partial<FormContainerState> = {
            taskId:_taskId,
            processInstanceId:_processInstanceId,
            formData:_formData,
            embeddedFormDocument,
            taskHistory:newTaskHistory,
            mustShowSections,
            formTaskExtensionProps: extractFormExtensionPropsOrDefault(_formData as TaskFormData || {}),
        }
        if (currentStepIdx != null){
            newState.currentTaskIdx = [currentStepIdx, currentTaskIdx as unknown as number];
        }

        if (values){
            newState.values = values
        }

        if (transformedData){
            (newState.formData as FormData).formFields = transformedData;
        }

        const checks = FormContainerBase.parseChecks(values?.checks, (newState.formData as FormData).formFields);
        const annotationsIdx = (newState.formData as FormData).formFields?.findIndex(f=>f.id=="__sancus_annotations") ?? -1;
        const editsIdx = (newState.formData as FormData).formFields?.findIndex(f=>f.id=="__sancus_edits") ?? -1;
        //remove annotations from formFields
        let annotations,edits;
        if (annotationsIdx !== -1){
            annotations = (newState.formData as FormData).formFields[annotationsIdx].value?.value;
            // const o = (newState.formData as FormData).formFields.splice(annotationsIdx,1);
            // console.log("Removed annotations",o);
        }
        if (editsIdx !== -1){
            edits = (newState.formData as FormData).formFields[editsIdx].value?.value;
            // const o = (newState.formData as FormData).formFields.splice(editsIdx,1);
            // console.log("Removed edits",o);
        }

        if (annotations){
            getStore().dispatch({

                type: 'INIT_ANNOTATIONS',
                payload: {annotations,edits},

            })
        }

        if (checks) {
            checks.forEach(c=>{
                c.notes = c.notes || []
            })
            newState.checks = checks;
        }

        console.log("Setting new State");
        this.setState(newState as FormContainerState);

        //we add this like a global "Loader hider", to inform subscribers that data is fetched
        this.inProgressCbk(false);

        // SRD-260 we add this to make 1 call of check user's session after all data received and rendered
        if (this.props.displayLogin === true) {
            const setUserSessionInfo = (value: any) => {
                return {
                    type: 'SET_USER_SESSION_INFO',
                    payload: value,
                }
            };

            this.context.getUserSessionInfo()
                .then((data) => {
                    console.log('getUserSessionInfo :  ', data);
                    getStore().dispatch(setUserSessionInfo(data));
                })
                .catch(err => {
                    console.warn(err);
                    console.log('getUserSessionInfo Error:  ', err);
                    const store = getStore();

                    if (store.getState().userSessionInfo) {
                        store.dispatch(setUserSessionInfo(null));
                    }
                })
        }
    };

    /**
     * Extract form values from form data.
     * @param formFields
     * @returns FormValues
     */
    calculateValues(formFields: FormField[]): FormValues {
        // const fieldsTransformed = this.transformData(formData.formFields);
        const fieldsGrouped: GroupedFields = this.groupFields(formFields);
        // todo change logic(calculate provider first then calculate _value?)


        const groupProvider = fieldsGrouped[""] && Object.values(fieldsGrouped[""].template).reduce((acc,fv_value)=>{
            let _value = ValueFactories.GenericFieldComponentBase(fv_value);
            const isGroup = fieldsGrouped[fv_value.id] != null;
            if (isGroup && typeof _value === 'string'){
               try {
                   _value = JSON.parse(_value)
               }catch (e){
                     console.warn("Failed to parse value",_value)
               }
            }
            acc[fv_value.id] = _value
            return acc;
        },{});

        const groupNames = Object.keys(fieldsGrouped).filter(groupKey=>groupKey!== "")

        const fieldsMapped = groupNames.reduce((acc: FormValues, groupKey: string) => {

            const section = fieldsGrouped[groupKey].section
            if (fieldsGrouped[groupKey].repeatable){
                //Get current value for group (groupProvider[groupKey] ) or template's defaults (fieldsGrouped[groupKey])
                const provider = groupProvider && groupProvider[groupKey] || fieldsGrouped[groupKey];
                const _value: FormValues[] = ValueFactories.RepeatableGroupComponentBase(fieldsGrouped[groupKey].template, provider);
                acc[groupKey] = _value;
            }else {
                const provider = groupProvider && groupProvider[groupKey] || fieldsGrouped[groupKey];
                const _value = ValueFactories.GroupComponentBase(fieldsGrouped[groupKey].template,provider);
                acc[groupKey] = _value;
            }

            return acc;
        },{})

        const fieldsWithoutGroup = groupProvider && Object.keys(groupProvider).filter(g=>groupNames.indexOf(g) === -1).reduce((acc,k)=>{
            const v = groupProvider[k];
            acc[k] = v;
            return acc;
        },{});

        const allFields = Object.assign(fieldsMapped,fieldsWithoutGroup);

        return allFields;
    }

    fileChangeCbk: FileChangeCallback = (key: string, value: any): void => {
        const files = this.state.files;
        let changed = false;
        if (!files){
            this.setState({files:{[key]:value}})
        }else{
            if (files[key] !== value){
                files[key] = value;
                this.setState({files:{...files}})
            }
        }

    }

    valueChangeCbk: ValueChangeCallback = (key: string, value: FormFieldValue, submit?: boolean | string) => {
        const {taskId,processDefinitionId,values,submit:prevSubmit} = this.state;
        const formId = taskId || processDefinitionId || this.props.processDefinitionKey


        const setValue = (key,newValue)=>{
            const lIdx = key.lastIndexOf('.');
            const fieldId = lIdx !== -1 ? key.substring(lIdx+1) : key;
            let fieldPrefix,fieldIdx;
            let valueProvider,oldValue,setter;
            if (lIdx !== -1) {
                fieldPrefix = key.substring(0, lIdx);
                const brIdxStart = fieldPrefix.lastIndexOf('[');
                if (brIdxStart !== -1){
                    const brIdxEnd = fieldPrefix.indexOf(']',brIdxStart);
                    fieldIdx = fieldPrefix.substring(brIdxStart+1,brIdxEnd);
                    fieldPrefix = fieldPrefix.substring(0,brIdxStart);
                    fieldIdx = parseInt(fieldIdx);
                }

                if (fieldPrefix) {
                    valueProvider = values[fieldPrefix];

                    if (fieldIdx != null && Array.isArray(valueProvider)) {
                        valueProvider = valueProvider[fieldIdx];
                    }
                    if (valueProvider) {
                        oldValue = valueProvider[fieldId];
                        setter = (_v)=>{
                            valueProvider[fieldId] = _v;
                            return valueProvider;
                        }

                    }
                }

            }else{
                oldValue = values[key];
                setter = (_v)=>{
                    values[key] = _v;
                    return values;
                }
            }

            if (oldValue !== newValue){
                setter(newValue);
                return true;
            }else{
                return false;
            }




        }

        let newState = null as any;

        const shouldRender = makeShouldRender(this.globalContextEvaluator);

        if (setValue(key,value)) {
            //@ts-ignore
            if(this.state.formData && Array.isArray(this.state.formData.formFields)) {
                //@ts-ignore
                const fieldsGrouped = this.groupFields(this.state.formData.formFields);
                const contextFieldVariables: ContextVariable[] = getContextVariablesFromGroupedFields(fieldsGrouped, values);

                this.globalContextEvaluator.updateContext({
                    variables: contextFieldVariables
                });

                //@ts-ignore
                const hidden = this.state.formData.formFields
                    .filter(field => !shouldRender(field))
                    //Exclude read only values
                    .filter((field:FormField)=>!(field.validationConstraints?.some(v=>v.name === 'readonly')))
                    .filter(field=>{
                        return Object.keys(fieldsGrouped).indexOf(field.id) === -1
                    });
                hidden.forEach(field => {
                    const fieldId = field.id

                    if(values.hasOwnProperty(fieldId)) {
                        const defaultValue = field.defaultValue
                        values[fieldId] = defaultValue!=null ? defaultValue : undefined ;
                    }
                });
            }

            // SRD-427
            if (key === 'internalComponentAction'){
                values[key] = value;
            }

            newState = {values:{...values},submit:!!submit};
            if (key === "checks"){
                const checks = FormContainerBase.parseChecks(values?.checks, (this.state.formData as FormData).formFields);
                if (checks) {
                    checks.forEach(c=>{
                        c.notes = c.notes || []
                    })
                    newState.checks = checks;
                }
            }
        }
        let promise;
        if (newState){
            promise = new Promise<void>((resolve, reject) => {
                this.setState(newState,()=>{
                    //@ts-ignore
                    resolve()
                })
            })
        }else {
            promise = Promise.resolve();
        }

        if (submit){
            promise = promise.then(()=>{
                return this.startFlow(typeof submit === 'string'?submit as string : null)();
            })
        }
        return promise;
    }
    groupFields = (formFields: FormField[]): GroupedFields   => {
        if (formFields && formFields.reduce) {
            const fieldsGrouped = formFields.reduce((acc: GroupedFields, fv_value: FormField) => {
                const group_repeatable = fv_value.properties?.group_repeatable
                const group = fv_value.properties?.group || group_repeatable || "";
                const section = fv_value.properties?.section;
                const fv_key = fv_value.id;

                if (fv_key == 'actionsList') {
                    return acc;
                }

                const genericFieldParams: GenericFieldParams = {
                    ...fv_value,
                    path: fv_key,
                    contextEvaluator: this.globalContextEvaluator,
                    _value: fv_value.value?.value,
                    valueChangeCbk: this.valueChangeCbk,
                    fileChangeCbk: this.fileChangeCbk,
                    formReference: formFields
                }

                if (!acc[group]) {
                    acc[group] = {

                        repeatable: !!group_repeatable,
                        section,
                        template: {[fv_key]: genericFieldParams}
                    };
                } else {


                    acc[group].template[fv_key] = genericFieldParams
                    acc[group].repeatable = !!group_repeatable
                    acc[group].section = section
                }
                return acc
            }, {})


            Object.keys(fieldsGrouped).filter(groupKey=>groupKey !== "").forEach(groupKey=>{
              //Enhance group with possible data from provider object
              const provider = formFields.find(f=>f.id === groupKey);
              if (provider){
                  const tgt = fieldsGrouped[groupKey];
                  tgt.label = provider.label;
                  tgt.properties = provider.properties;
                  tgt.validationConstraints = provider.validationConstraints;
              }

            })

            return fieldsGrouped;
        }else{
            console.debug("formFields are null or not array",formFields);
            return {};
        }
    }

    getConstraintsForKey = (key:string):FormProperty['validationConstraints']=>{
        const formData = this.state.formData;
        if ((formData as FormData).formFields) {
            const formFields = (formData as FormData).formFields
            // const transformedFields = this.transformData((formData as FormData).formFields);
            const groups: GroupedFields = this.groupFields(formFields);
            if (groups[key]) {

                const own = formFields.find(f=>f.id === key);
                const ownConstraints:FormPropertyValidationConstraint[] = own?.validationConstraints || [];
                const template  = groups[key].template;



                const readonly = Object.keys(template).every(key=>{
                    const _template = template[key];
                    return _template.validationConstraints.some(v=>v.name === 'readonly')
                })

                const ownReadOnly = ownConstraints.some(v=>v.name === 'readonly')
                if (readonly && !ownReadOnly){
                    ownConstraints.push({name:'readonly'})
                    return ownConstraints
                }else{
                    return ownConstraints;
                }
            }else{
                const formField = formFields.find(f=>f.id === key);
                const constraints = formField?.validationConstraints || [];
                return constraints;
            }
        }
        return []
    }

    async transformData(formFields:FormField[] | undefined){
        const fidesData = formFields?.find(f => f.type?.name === 'fides')
        if (fidesData?.value?.value) {
            const config =  await fidesConfigApi.fetchConfig();
            const {payload:{customerData,customerExtendedInfo},allowWrites} = fidesData.value.value
            const transformedFidesData = transform(customerData,customerExtendedInfo, config.config, config.fields,true,allowWrites) as FormField[];
            return formFields!.map(f=>{
                if (f.type?.name !== 'fides'){
                    return f;
                }else{
                    return transformedFidesData;
                }
            }).flat()
        }
        return formFields || []
    }

    handleForm = (
        taskId: string | null | undefined,
        processInstanceId: string | null | undefined,
        _formData:FormData | ProcessInstanceStatus,
        _values: FormValues,
        embeddedForm?:Document | null
    ): FormView | "ended" | "paused" | null => {
        if (taskId == "ended" || taskId== "paused"){
            return taskId;
        }

        const formData = _formData as FormData;
        if (!formData){
            return null;
        }

        const name = (formData as TaskFormData).task?.name || (formData as StartFormData).processDefinition?.name;
        const description = (formData as TaskFormData).task?.description || (formData as StartFormData).processDefinition?.description;

        const formDefId = (formData as TaskFormData).task?.taskDefinitionKey || (formData as StartFormData).processDefinition?.key;
        const formKey = formData.formKey || ((formData as TaskFormData).task && (formData as TaskFormData).task.id) || ((formData as StartFormData).processDefinition && processInstanceId);

        // const fieldsTransformed = this.transformData(formData.formFields)
        const fieldsGrouped = this.groupFields(formData.formFields);

        const contextFieldVariables: ContextVariable[] = getContextVariablesFromGroupedFields(fieldsGrouped, _values);

        this.globalContextEvaluator.updateContext({
            variables: contextFieldVariables
        });

        // in designMode (Form Designer) render all fields
        const designMode = this.props.formBuilderProps?.designMode;
        const shouldRender = designMode ? (f: any) => true : makeShouldRender(this.globalContextEvaluator);

        const groupReverseIdx = Object.keys(fieldsGrouped).reduce((acc,groupName)=>{
            const group = fieldsGrouped[groupName].template;
            Object.keys(group).forEach(fieldName=>{
                acc[fieldName] = groupName;
            })
            return acc;
        },{})

        const groupVisited =[] as string[];
        // gather checks
        const checks = FormContainerBase.parseChecks(this.state.values?.checks,formData.formFields)
        const fieldsMapped = formData.formFields?.filter(shouldRender) ?? [];
        /*const { fieldsMapped, hidden } = formData.formFields.reduce((acc: { fieldsMapped: FormField[], hidden: FormField[] }, cur: FormField) => {
            const hide = shouldRender(cur);
            if(hide) {
                acc.hidden.push(cur);
            }
            else {
                acc.fieldsMapped.push(cur);
            }
            return acc;
        }, { fieldsMapped: [], hidden: [] });*/

        const fieldsMapped_1 = fieldsMapped.filter(fd=>{
            const fieldId = fd.id;
            if (fieldId === 'actionsList'){
                return false;
            }
            const inGroup = groupReverseIdx[fieldId] || fieldsGrouped[fieldId] && fieldId;
            if (inGroup && inGroup !== ''){
                const visited = groupVisited.indexOf(inGroup) !== -1;
                if (visited){
                    return false
                }else{
                    groupVisited.push(inGroup);
                    return true;
                }
                return false
            }else{
                return true;
            }
        });

        const fieldsMapped_2 = fieldsMapped_1.map((formValue: FormField, index): FormField & {visualSection:string}=> {
            return {
                ... formValue,
                visualSection: formValue.properties?.visual_section || 'no_section',
                //contextEvaluator: this.globalContextEvaluator,
                // @ts-ignore
                baseOrderIndex: index,
            }
        });

        const visual_sections:string[] = []

        const fieldsMapped_3 = fieldsMapped_2.sort((prev, next) => {
            let businessLogicOrder = 1;

            // leave default order in task view
            if (!this.props.showSections &&  !this.state.mustShowSections){
                // @ts-ignore
                return prev.baseOrderIndex-next.baseOrderIndex
            }


            if (FormContainerBase.groupByStrategy === 'task') {
                const pSectionName = prev.properties?.section || "no_section";
                const nSectionName = next.properties?.section || "no_section";
                if (pSectionName != nSectionName){
                    // return pSectionName > nSectionName ? 1 : -1;
                }else{
                    // @ts-ignore
                    return prev.baseOrderIndex-next.baseOrderIndex
                }
            }

            if (prev.visualSection && visual_sections.indexOf(prev.visualSection) === -1){
                // @ts-ignore
                if (next.visualSection && visual_sections.indexOf(next.visualSection) === -1 && next.baseOrderIndex < prev.baseOrderIndex){
                    visual_sections.push(next.visualSection)
                }
                visual_sections.push(prev.visualSection)
            }

            if (next.visualSection && visual_sections.indexOf(next.visualSection) === -1){
                visual_sections.push(next.visualSection)
            }

            if (prev.visualSection !== next.visualSection){
                const iprev = visual_sections.indexOf(prev.visualSection);
                const inext = visual_sections.indexOf(next.visualSection);
                return iprev < inext?-1 : iprev > inext? 1 : 0
                // return prev.visualSection > next.visualSection ? 1 : -1;
            }else{
                // @ts-ignore
                return prev.baseOrderIndex-next.baseOrderIndex
            }



        });
        const fieldsMapped_4 = fieldsMapped_3.map(fv_value=>{
            const fieldId = fv_value.id;
            let groupKey;
            let group = fieldsGrouped[fieldId];
            if (group){
                groupKey = fieldId;
            }else{
                groupKey = groupReverseIdx[fieldId];
                group = groupKey && fieldsGrouped[groupKey];
            }
            if (group){
                const section = group.section;
                if (group.repeatable){
                    let _value: FormValues[] = _values[groupKey] as FormValues[];
                    // calculate field check messages per group
                    const checkMessagesPerGroup:RepeatableCheck = checks && _value? _value.map(fieldsMap => {
                        const groupFieldChecks =  (Object.keys(fieldsMap).reduce((fieldsCheckMessage, f) => {
                            const fieldCheckMessage:ICheck[] = checks.filter((check: ICheck) => {
                                const e = fieldsMap['uuid'] === check.sectionuuid;

                                const a = e && check.fields && check.fields.indexOf(f) >=0;

                                const c = fieldsMap['uuid']+":"+f;
                                const b = check.fields && check.fields.indexOf(c) >=0;
                                return a || b;
                            });
                            if (fieldCheckMessage.length > 0){
                                fieldsCheckMessage[f] = fieldCheckMessage
                            }
                            return fieldsCheckMessage;
                        }, {}))

                        return groupFieldChecks;
                    }) : []

                    const groupChecks = _value?.flatMap(fieldsMap=>{
                        return checks.filter((check: ICheck) => {
                            const e = fieldsMap['uuid'] === check.sectionuuid;
                            return e && !check.fields;
                        });
                    })
                    if (groupChecks && groupChecks.length >0) {
                        checkMessagesPerGroup.push({uuid: groupChecks});
                    }

                    // skip empty checkMessage groups
                    const filteredCheckMessagesPerGroup= checkMessagesPerGroup.filter(g => !_.isEmpty(g))
                    // console.log(`CHECK MESSAGE FOR FIELDS IN ${groupKey}`);
                    // console.log(filteredCheckMessagesPerGroup);
                    return {
                        visualSection: fv_value.visualSection,
                        section,
                        element: this.renderGroupRepeatable(groupKey,toContextAwareFieldGroupInfo(group, this.globalContextEvaluator,fieldsMapped),_value,section, filteredCheckMessagesPerGroup,fieldsMapped)
                    };
                }else {
                    let _value = _values[groupKey] as {[key:string]:any};
                    let _value_2 = formData.formFields.find(f=>f.id === groupKey)?.value?.value;

                    const uuid = _value_2?.uuid;

                    const groupChecks = checks && checks.filter(c=>{
                        return uuid  && c.sectionuuid === uuid;
                    })

                    const checkMessages:GroupCheck = groupChecks && groupChecks.length >0 && _value && Object.keys(_value).reduce((acc,fieldId)=>{
                        const checksForField = groupChecks.filter(c=>c.fields && c.fields.indexOf(fieldId) !== -1);
                        acc[fieldId] = checksForField;
                        return acc;
                    },{}) || {}
                    return {
                        visualSection: fv_value.visualSection,
                        section,
                        element: this.renderGroup(
                            groupKey,
                            groupKey,
                            toContextAwareFieldGroupInfo(group, this.globalContextEvaluator,fieldsMapped),
                            _value,
                            section,
                            checkMessages,
                            fieldsMapped
                        )
                    };
                }
            }else{
                let _value = _values[fv_value.id];
                let section = fv_value.properties?.section
                const checkMessages = checks && checks.filter((check: ICheck) => {
                    return check.fields && check.fields.indexOf(fv_value.id) >=0
                });
                return {
                    visualSection: fv_value.visualSection,
                     section,
                     element: this.renderGenericComponent(fv_value,_value,section, Array.isArray(checkMessages) && checkMessages || [], this.globalContextEvaluator,fieldsMapped)
                    };
            }
        });

        const fieldsMapped_5 = fieldsMapped_4.reduce((acc,cmp)=>{
            acc.push(cmp);
            return acc;
        },[] as {element:JSX.Element,section:string | undefined}[])

        const actions:string | string[] = formData.formFields?.filter(fv_value=>fv_value.id === 'actionsList').map(fv_value=>{
            return fv_value.value!.value;
        })[0] || []

        if (embeddedForm){

            return {
                id: formKey,
                formDefId,
                title: name,
                description: description,
                actions: actions,
                fields: fieldsMapped_5,
                document: embeddedForm
            };

        }


        return {
            id: formData.formKey,
            formDefId,
            title: name,
            description: description,
            actions: actions,
            fields: fieldsMapped_5
        }
    }

    renderForm(fieldsFormView):JSX.Element[]{
        let formDiv;
        if ((fieldsFormView as any).document) {
            const document: Document = (fieldsFormView as any).document;
            formDiv = [this.renderDocument(document)];
        } else {
            formDiv = this.renderFields(fieldsFormView)
        }

        return formDiv;
    }
    abstract renderError(error,standalone);
    abstract renderLoading();
    protected renderFormDivAndActionContainer(formDefId,elements){
        return elements;
    }

    render() {
        let div,key

        let {formData,embeddedFormDocument,taskId,processInstanceId,values,error} = this.state;

        let formDefId = formData && ((formData as TaskFormData).task?.taskDefinitionKey || (formData as StartFormData).processDefinition?.key);

        if (taskId){
            key = taskId;
        }
        const layout = this.state.formTaskExtensionProps.layout
        let taskDefinitionKey;
        if (formData && formData.hasOwnProperty("task")) {
            taskDefinitionKey = (formData as any).task.taskDefinitionKey
        } else if (formData && formData.hasOwnProperty("processDefinition")) {
            taskDefinitionKey = (formData as any).processDefinition.key
        }
        const formView = this.handleForm(taskId,processInstanceId,formData!,values,embeddedFormDocument);

        if (!formView && ! error) {
            if (this.props.renderLoading !== false && this.props.renderLoading !== "false") {
                div = this.renderLoading()
            } else {
                div = <></>
            }
        }else if (formView && typeof formView === 'object' ) {
            const fieldsFormView = formView as FieldsFormView;
            const actions = fieldsFormView.actions;
            if (taskDefinitionKey) {
                fieldsFormView.id = taskDefinitionKey;
            }
            let formDiv = this.renderForm(fieldsFormView);
            let actionContainer;
            if (this.props.renderActionContainer !== false && this.props.renderActionContainer !== "false") {
                actionContainer = this.renderActionContainer(actions)
            } else {
                actionContainer = [null]
            }
            let annotatorElement;
            if (layout == 'review'){
                const annotatorFromExtension = this.state.formTaskExtensionProps['annotator'];
                const annotatorModes=  annotatorFromExtension && annotatorFromExtension.split(',').map(s=>s.trim())


                annotatorElement = this.renderAnnotator(annotatorModes);
            }else{
                annotatorElement = null
            }

            // div = <>
            //     {formDiv}
            //     {actionContainer}
            // </>
            div = this.renderFormDivAndActionContainer(formDefId,[...formDiv,annotatorElement,...actionContainer].filter(e=>e))
        } else if (formView == 'paused'){
            key="paused"
            const {processDefinitionKey,processDefinitionName,state,endStates,activeStates} = formData as ProcessInstanceStatus
            if (activeStates && activeStates[0]) {
                const {executionId,id, name, documentation,attributes} = activeStates[0];
                if (attributes?.terminal){
                    div = this.renderEndState("COMPLETED",activeStates);
                }else {
                    div = this.renderPaused(id, name, documentation,executionId,attributes);
                }
            }else{
                if (endStates && endStates[0]){
                    //An internal end state has been reached but the main process has not caught up yet
                    const {id, name, documentation,attributes} = endStates[0];
                    if (state === 'ACTIVE' || state === 'STATE_ACTIVE') {
                        //An internal end state has been reached but the main process has not caught up yet
                        div = this.renderPaused(id, name, documentation,attributes);
                    }else{
                        div = this.renderEndState(state,endStates);
                    }
                }else {
                    //No info whatsoever
                    if (state === 'INTERNALLY_TERMINATED' || state === 'STATE_INTERNALLY_TERMINATED') {
                        div = this.renderPaused(state, processDefinitionName, "The running process instance was terminated from within");
                    } else if (state === 'EXTERNALLY_TERMINATED' || state === 'STATE_EXTERNALLY_TERMINATED') {
                        div = this.renderPaused(state, processDefinitionName, "The running process instance was terminated from external actors");

                    } else {
                        div = this.renderPaused(state, processDefinitionName, `Process is in state ${state}`);
                    }
                }

            }

        }else if (formView == 'ended'){
            key="end_state"
            const {state,endStates} = formData as ProcessInstanceStatus
            div = this.renderEndState(state,endStates);
        }else{
            div = <></>
        }

        const checks = this.state.checks || []
        const renderedDiv = this.doRender(div,checks,key,formDefId!);
        // @ts-ignore
        // const facing = this.state.formData?.formFields && this.state.formData?.formFields.length >200;
        return <LayoutContext.Provider value={ this.state.formTaskExtensionProps.layout }>
                <FormContainerContext.Provider value={{
                    processDefKey:this.props.processDefinitionKey!,

                    processInstanceId:(this.state.processInstanceId || this.props.processInstanceId)!,
                    taskId:this.state.taskId!,

                    formDefId:taskDefinitionKey,
                    formReference:(formData as FormData)?.formFields,
                    formExtensions: this.state.formTaskExtensionProps,
                    contextEvaluator: this.globalContextEvaluator,

                    raiseError: (errorCode,errorName,data)=> this.raiseError(errorCode,errorName,data),
                    raiseAction: (action,data)=>this.startFlow(action,data),

                    showStepper(show: boolean) {
                    },
                    intl: this.props.intl,
                }}>
                    <EditFieldContext.Provider value={false}>
                    {renderedDiv}
                    </EditFieldContext.Provider>
                </FormContainerContext.Provider>
        </LayoutContext.Provider>;
    }
    renderAnnotator(modes:null | ""| string[]):JSX.Element[]{
        //Possible add comm on code for web & mobile flavors
        return this.doRenderAnnotator(modes)
    }
    abstract doRenderAnnotator(modes:null |"" | string[]):JSX.Element[]

    renderFields(fieldsFormView:FieldsFormView):JSX.Element[]{
        const processDefinitionKey = this.props.processDefinitionKey;
        const intl = this.props.intl
        if (fieldsFormView.formDefId){
            fieldsFormView.description = fieldsFormView.description ?

                intl.formatMessage({
                    id:`${processDefinitionKey}.${fieldsFormView.formDefId}_DESC`,
                    defaultMessage: fieldsFormView.description,
                    description: `Translated value for ${processDefinitionKey}.${fieldsFormView.formDefId} description`
                }): '';
            fieldsFormView.title = intl.formatMessage({
                id: `${processDefinitionKey}.${fieldsFormView.formDefId}`,
                defaultMessage: fieldsFormView.title,
                description: `Translated value for ${processDefinitionKey}.${fieldsFormView.formDefId} title`
            });
        }

        const showSections = this.props.showSections || this.state.mustShowSections;
        const renderIndex = showSections === 'index'
        const fieldElements = showSections ?
            this.injectDividers(fieldsFormView.fields,renderIndex) :
            fieldsFormView.fields.map(e => e.element);

        return this.doRenderFields(fieldsFormView,fieldElements)
    }

    handleEmbeddedForm = async(formData:FormData,processInstanceId:string):Promise<{document:Document,extraFields?:FieldsFormView['fields'],actions?:string | string[]} | null> => {
        if (formData.formKey && formData.formKey.startsWith("embedded:app:")){
            return this.doHandleEmbeddedForm(formData,processInstanceId)

        }else{
            this.setState({externalScripts: []});
            return null;
        }

    }

    abstract doHandleEmbeddedForm(formData:FormData,processInstanceId:string):Promise<{document:Document,extraFields?:FieldsFormView['fields'],actions?:string | string[]} | null>

    static VisibleElements = null;

    renderGroupRepeatable(groupKey: string, group: ContextAwareFieldGroupInfo, _value: { [p: string]: any }[], section: string | undefined,  checkMessages: RepeatableGroupCheck | undefined,formReference):JSX.Element {
        const {RepeatableGroupComponent} = this.renderers
        return <RepeatableGroupComponent key={groupKey}
                                         name={groupKey}
                                         path={groupKey}
                                         data-section={section}
                                         template={group.template}
                                         values={_value ?? []}
                                         contextEvaluator={group.contextEvaluator}
                                         checkMessages={checkMessages}
                                         fileChangeCbk={this.fileChangeCbk}
                                         valueChangeCbk={this.valueChangeCbk}
                                         formReference={formReference}
                                         intl={this.props.intl}
                                         designMode={this.props.formBuilderProps?.designMode}
                                         methods = {this.props.methods}
                                         visibleElements={FormContainerBase.VisibleElements}

                                         label={group.label}
                                         properties={group.properties}
                                         validationConstraints={group.validationConstraints}

        />
    }


    renderGroup(fieldId: string, groupKey: string, group: ContextAwareFieldGroupInfo, _value: { [p: string]: any }, section: string | undefined,  checkMessages: GroupCheck | undefined,formReference):JSX.Element{
        const {GroupComponent} = this.renderers
        return <GroupComponent
            index={-1}//TODO?
            name={fieldId}
            key={groupKey}
            path={groupKey}
            data-section={section}
            template={group.template}
            checkMessages={checkMessages}
            value={_value}
            contextEvaluator={group.contextEvaluator}
            fileChangeCbk={this.fileChangeCbk}
            valueChangeCbk={this.valueChangeCbk}
            formReference={formReference}
            methods = {this.props.methods}
            designMode={this.props.formBuilderProps?.designMode}

            visibleElements={FormContainerBase.VisibleElements}
        />
    }

    renderGenericComponent(fv_value:FormProperty, _value: any, section: string | undefined, checkMessages: ICheck[], contextEvaluator: SancusConditionEvaluator,formReference):JSX.Element{
        const {GenericFieldComponent} = this.renderers

        return <GenericFieldComponent
            key={fv_value.id}
            {...fv_value}
            path={fv_value.id}
            contextEvaluator={contextEvaluator}
            _value={_value}
            data-section={section}
            checkMessages={checkMessages}
            valueChangeCbk = {this.valueChangeCbk}
            fileChangeCbk ={ this.fileChangeCbk }
            formReference={formReference}
        />
    }


    abstract renderDocument(document: Document):JSX.Element;

    static _injectDividers(fields: FieldsFormView['fields'],checks:ICheck[]): { sectionName,section,serviceGroups }[]{
        const filterProperty = FormContainerBase.groupByStrategy === 'task' ?  'section' : 'visualSection';
        const withSections = fields.reduce((acc,field)=>{
            const {visualSection, element, section} = field;
            const sectionName:string = (FormContainerBase.groupByStrategy === 'task' ?  section : visualSection) as string;

            const sectionArray = acc[sectionName] || {fields:[]}
            sectionArray.fields.push(field)

            acc[sectionName] = sectionArray
            return acc;
        },[])

        // gather checks and filter

        return Object.keys(withSections)
            // .filter(sectionName=>{
            //     return sectionName !== 'no_section'
            // })
            .map(sectionName=>{
                const section = withSections[sectionName];

                const fieldWithChecks = fields
                    .filter(f => f[filterProperty] === sectionName &&
                        f.element.props.checkMessages?.length > 0 &&
                        f.element.props.checkMessages[0].hasOwnProperty('uuid')) // filter out repeatable group messages
                    .map(f => f.element.props);

                const sectionGlobalChecks = checks &&
                    checks.filter(c=>
                        c.sections && c.sections.indexOf(sectionName) !=-1 &&
                        c.sectionuuid == null //This prevents checks of the repeatable sections to be displayed in the generic section summary
                    ) || []

                const serviceGroups = fieldWithChecks.reduce((acc, f) => {
                    f.checkMessages.forEach((checkMessage) => {
                        // acc[checkMessage.service] = acc[checkMessage.service] || checkMessage
                        acc[checkMessage.uuid] = acc[checkMessage.uuid] || checkMessage
                    })
                    return acc
                }, {})

                sectionGlobalChecks.forEach(c=>{
                    serviceGroups[c.uuid] = serviceGroups[c.uuid] || c;
                });
                return {sectionName,section,serviceGroups}

            })
            //link same service checks
            .map((sectionsAndChecks: { sectionName: string, section, serviceGroups: {[key: string]: ICheck}}) => {
                return {
                    sectionName: sectionsAndChecks.sectionName,
                    section: sectionsAndChecks.section,
                    serviceGroups:sectionsAndChecks.serviceGroups
                    // serviceGroups: mergeServiceGroups(sectionsAndChecks.serviceGroups)
                }
            });
    }

    injectDividers(fields: FieldsFormView['fields'],renderIndex?:boolean): (JSX.Element | [JSX.Element,JSX.Element])[]{

        const checks = FormContainerBase.parseChecks(this.state.values?.checks,(this.state.formData as FormData).formFields)
        const rt = FormContainerBase._injectDividers(fields,checks);
        return rt
            // .filter(({sectionName})=>sectionName !== 'no_section')
            .map(({sectionName,section,serviceGroups})=>this.doRenderSection(sectionName,section,serviceGroups,renderIndex))
    }

    abstract doRenderSection(sectionName:string,section,serviceNotificationTpl,renderIndex):JSX.Element | [JSX.Element,JSX.Element]

    abstract doRenderFields(fieldsFormView:FieldsFormView,fieldElements:(JSX.Element | [JSX.Element,JSX.Element])[]):JSX.Element[]


    abstract doRenderActionContainer(buttons:JSX.Element[], actions?: string | (IActionJson | string)[]):JSX.Element[];
    // abstract renderSubmitButton(_k,_v,localize,extraStyles?, extraData?):JSX.Element;
    abstract renderSubmitButton(action:IActionJson,localize:boolean):JSX.Element;
    abstract renderBackButton(fireAction:boolean,extraStyle?):JSX.Element;

    showDialog = (options: DialogData) => {
        getStore().dispatch({
            type: 'ACTIVE_DIALOG',
            payload: options,
        });
    }

    protected _renderSubmitButton<T>(
        Component: React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonParams> & React.RefAttributes<T>>,
        Tooltip: ((props:{title:string,children:JSX.Element}) =>JSX.Element)
    ) {
        const processDefinitionKey = this.props.processDefinitionKey;
        const formDefId = this.state.formData && ((this.state.formData as TaskFormData).task?.taskDefinitionKey || (this.state.formData as StartFormData).processDefinition?.key);
        return (action:IActionJson,localize:boolean) => {
            const intl = this.props.intl;

            const {value:_k, label:_v, dialog} = action

            const defaultLabel = buttonLabelSubstitutions[_k] || _v || _k
            const label = localize ? intl.formatMessage({
                id: `${processDefinitionKey}.${formDefId}.action.${_k}`,
                defaultMessage: defaultLabel,
                description: `Label for action ${_k} in ${formDefId}`
            }) : defaultLabel;

            const {error, inProgress} = this.state;

            const calc_disabled = (action.disabled && this.globalContextEvaluator.eval(action.disabled))

            const disabled = error || inProgress || calc_disabled;

            const render = action.render

            let extraStyle:CSSProperties = {}
            const calc_visible = action.visible == null || this.globalContextEvaluator.eval(action.visible)

            if (!calc_visible){
                extraStyle.display =  "none";
            }

            const tooltip = (calc_disabled && action.disabled_tooltip) || action.tooltip;

            const handler = dialog? (evt=>{
                this.showDialog({
                    ...dialog!,
                    OkHandler: (action) => {
                        if (action) {
                            return this.startFlow(action)(evt)
                        } else {
                            return this.startFlow(_k)(evt)
                        }
                    }
                })
                return Promise.resolve();
            }):this.startFlow(_k);

            const component = <Component
                key={_k}
                handler={handler}
                disabled={!!disabled}
                extraStyle={extraStyle}
                render={render}

            >{label}</Component>

            if (tooltip){
                return <Tooltip title={tooltip}>{component}</Tooltip>
            }else{
                return component;
            }


        }
    }
    protected _renderBackButton<T>(Component: React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonParams> & React.RefAttributes<T>>){
        return (fireAction,extraStyle?)=> {
            const {currentTaskIdx,error,inProgress} = this.state
            const [currentStepIdx,_currentTaskIdx] = currentTaskIdx;

            const intl = this.props.intl
            const back = intl.formatMessage({
                id:'action.back',
                defaultMessage:'Back',
                description:'Action Back'
            });

            const disabled = !fireAction && (error || inProgress || currentStepIdx<=1 && _currentTaskIdx<1/*First step cannot go back*/)
            return <Component
                key={"backButton"}
                disabled={!!disabled}
                extraStyle={extraStyle}
                handler={evt=>fireAction?this.startFlow("back")(evt):this.resumeState(evt)}
            >{back}</Component>
        }
    }

    renderActionButtons(_actions:(string | IActionJson)[]):JSX.Element[]{

        const intl = this.props.intl

        const {taskHistory,currentTaskIdx:[currentStepIdx,_currentTaskIdx]} = this.state;


        let actionButtons;
        let defaultActionStr: string;

        if (taskHistory.length == 0){
            defaultActionStr = intl.formatMessage({
                id: 'action.submit',
                defaultMessage:'Submit',
                description: 'Action Submit'
            });
        }else if (currentStepIdx <0 ){
            defaultActionStr = intl.formatMessage({
                id: 'action.getStarted',
                defaultMessage:'Get Started',
                description: 'Action Get Started'
            });
        }else if (currentStepIdx == taskHistory.length - 1 && _currentTaskIdx == taskHistory[currentStepIdx].taskDefs.length -1){
            defaultActionStr = intl.formatMessage({
                id: 'action.complete',
                defaultMessage:'Complete',
                description: 'Action Complete'
            });
        }else{
            defaultActionStr = intl.formatMessage({
                id:'action.next',
                defaultMessage: 'Next',
                description:'Action Next'
            });
        }


        const defaultAction:IActionJson = {
            value: defaultActionStr
        };
        const hasActions = _actions[0];

        const isIActionJson = (action: string | IActionJson): action is IActionJson =>{
            return (typeof action === 'object') && !!(action.value || action.label);
        }
        // @ts-ignore
        const actionsOrDefault: IActionJson[] = hasActions ?
            _actions.map(action => {
                if (action) {
                    if (action === 'hide') {
                        return {value:action!};
                    }else if(isIActionJson(action)){
                        const label = action.label || action.value
                        const value = action.value || label
                        const dialog =  action.dialog
                        return {value, label, dialog};
                    } else if (typeof action === 'string'){
                        return {value:action!, label:action!};
                    }else{
                        throw `Cannot process action ${action}`
                    }
                }else{
                    return defaultAction;
                }
            }) : (this.props.noStep? []:[defaultAction])



        actionButtons = actionsOrDefault.map((action)=>{
            let {value} = action;
            const localize = action !== defaultAction


            if (value!.indexOf('hide') !== -1) {
                action.visible = "false"
            }

            return this.renderSubmitButton(action,localize)
        })

        return actionButtons;
    }

    renderActionContainer(actions: IActionJson | string| IActions):JSX.Element[]{
        let _actions: IActions;

        const parseActionsList = (dataToParse) => {
            if (typeof dataToParse === 'string'){
                let parsed;
                try {
                    parsed = JSON.parse(dataToParse);

                    if (!Array.isArray(parsed)) {
                        parsed = [parsed];
                    }
                }catch (e) {}

                if (parsed){
                    return parsed;
                } else {
                    return dataToParse.split(",").map(s => s.trim());
                }

            }else if (Array.isArray(dataToParse)){
                return  dataToParse.map(dp=>{
                    if (typeof dp === 'string'){
                        try{
                            return JSON.parse(dp);
                        }catch (e){
                            return dp.trim()
                        }
                    }else if (typeof dp === 'object'){
                        return dp;
                    }else{
                        throw {message:"Unsupported construct for actionsList",data:dp}
                    }
                });
            }else if (typeof dataToParse === 'object'){
                return [dataToParse];
            }else{
                throw {message:"Unsupported construct for actionsList",data:dataToParse}
            }
        }

        _actions = parseActionsList(actions);

        let hideBack = _actions.indexOf('back_hide') !== -1

        let fireAction = _actions.indexOf('back') !== -1 || _actions.indexOf('back_hide') !== -1
        if (fireAction){
            _actions = _actions.filter(a=>a!=='back' && a!=='back_hide')
        }
        const {currentTaskIdx} = this.state;
        const [currentStepIdx,_currentTaskIdx] = currentTaskIdx;

        const actionButtons = this.renderActionButtons(_actions)
        if (fireAction || (currentStepIdx >= 1 && !this.props.noStep)){
            const extraStyle = hideBack ? {display: "none"} : {}
            actionButtons.unshift(this.renderBackButton(fireAction,extraStyle))
        }
        return this.doRenderActionContainer(actionButtons, _actions);
    }


    abstract renderPaused(stateId,stateName,stateDesc,executionId?,attributes?):JSX.Element;


    abstract renderEndState(state: ProcessInstanceStatus['state'],
                            endStates?: PIEV[]):JSX.Element


    protected shouldRenderStepper(){
        const {showStepper,formTaskExtensionProps} = this.state;

        const showStepperExtension = formTaskExtensionProps['navigation'];
        const showStepperProps = this.props.noStep === true || this.props.noStep === "true" || this.props.hideStepper === true || this.props.hideStepper === "true" /*|| taskHistory == null || taskHistory.length <2*/

        let willRenderStepper;
        if (showStepper != null){
            willRenderStepper = showStepper;
        }else if (showStepperExtension != null){
            willRenderStepper = showStepperExtension !== 'none';
        }else{
            willRenderStepper = !showStepperProps;
        }
        return willRenderStepper;
    }

    renderStepper(){
        const {taskHistory,currentTaskIdx,formTaskExtensionProps,formData,endStates} = this.state;

        if (!formData && !endStates[0]){
            //Still loading - skip
            return <></>
        }

        const clickable = formTaskExtensionProps['navigation'] == 'active';
        const willRenderStepper = this.shouldRenderStepper();
        return   willRenderStepper?  this.doRenderStepper(currentTaskIdx,taskHistory,clickable) : <></>
    }

    renderUserSessionInfoWidget() {
        if (this.props.displayLogin === true) {
            return this.doRenderUserSessionInfoWidget()
        }
    }

    abstract doRenderUserSessionInfoWidget();
    abstract doRenderStepper(currentTaskIdx:[number,number],taskHistory:TaskHistoryElement[],clickable:boolean):JSX.Element;

    abstract doRender(div:JSX.Element,checks:ICheck[],key:string,formDefId:string):JSX.Element;

    /**
     * Extracting Unique Checks received from server.
     * @param checks all checks in state
     * @param formFields all formFields in state: Used to order the checks in sections
     */
    static parseChecks(checks:ICheck[] | undefined,formFields:FormField[]): ICheck[] {

        const findField = (c:ICheck,varId)=>{
            return !!c.fields?.some(f=>f === varId || f['field'] === varId)
        }

        const orderHelper : ICheck[] = [];

        //In case no checks found
        //state.values?.checks
        if (checks == null || checks.length == 0) {
            return [];
        }

        const checksWithoutFields = checks.filter(check=>{
            return check.sections && check.sections.length > 0 && (!check.fields || check.fields.length === 0)
        });

        const visualSections:{[key:string]:FormField[]} = formFields.reduce((acc,f)=>{
            const vs = f.properties?.visual_section || "no_section"
            if (acc[vs]){
                acc[vs].push(f)
            }else{
                acc[vs] = [f]
            }
            return acc;
        },{})

        const groups = new Set<string>();
        const group_repeatables = new Set<string>();

        const fieldsAndSections = _.flatMap(Object.keys(visualSections).map(v=>{
            const mm:{type:string,obj:string | FormField}[] = [{type:'vs',obj:v}];
            mm.push(...visualSections[v].map(i=>{
                const g = i.properties?.group;
                const gr = i.properties?.group_repeatable;
                if (g){
                    groups.add(g);
                };
                if (gr){
                    group_repeatables.add(gr);
                }

                return {type:'field',obj:i}
            }))
            return mm;
        }))

        let idx = 0;

        //Iterate formFields
        fieldsAndSections.forEach(fs => {
            if (fs.type === 'field') {
                const variable = fs.obj as FormField

                const varId = variable.id;
                const anyCheckMatch = checks.filter(c=>findField(c,varId))

                if (anyCheckMatch.length >0){
                    // console.log(anyCheckMatch)
                }

                const isGroupRepeatable = (variable:FormProperty)=>{
                    return group_repeatables.has(variable.id)
                }

                const isGroup = (variable:FormProperty)=>{
                    return groups.has(variable.id)
                }

                if (variable.value && variable.value.value && isGroupRepeatable(variable)) {
                    // Case 1: Repeatable fields , having values in array
                    if (typeof variable.value.value === 'string'){
                        variable.value.value = JSON.parse(variable.value.value)
                    }
                    variable.value.value.forEach((repeatable: any) => {
                        const foundCheck = checks.filter((check: ICheck) => (
                            (check.sectionuuid &&
                            (check.sectionuuid === repeatable.uuid ||
                                !!check.fields?.some(f=>(f as any).sectionUUID === repeatable.uuid)
                            )) || (
                                check.fields?.some(f=>f.startsWith(repeatable.uuid+":"))
                            )

                        )).map(c=>{
                            c['idx'] = idx++;
                            return c;
                        });
                        // If Check with same sectionuuid found
                        if (foundCheck && foundCheck.length >0) {
                            orderHelper.push(...foundCheck);
                        }
                    });
                }else if (variable.value && variable.value.value && isGroup(variable)){
                    //Case 2: Field is Group
                    const uuid = variable.value.value['uuid'];
                    const foundCheck = checks.filter((check: ICheck) => (check.sectionuuid && check.sectionuuid === uuid)).map(c=>{
                        c['idx'] = idx++;
                        return c;
                    });
                    // If Check with same sectionuuid found
                    if (foundCheck && foundCheck.length >0) {
                        orderHelper.push(...foundCheck);
                    }
                } else {
                    // Case 3: Non-repeatable fields
                    const foundCheck = checks.filter((check: ICheck) => {
                        if (check.fields) {// Case 3.1 Check with fields properties
                            return !check.sectionuuid && findField(check,varId);
                        } else if (check.sections) {// Case 3.2 Check with sections properties
                            return variable.properties.visual_section === check.sections[0];
                        } else { // Case 3.3 non of above
                            return false;
                        }
                    }).map(c=>{
                        c['idx'] = idx++;
                        return c;
                    });
                    if (foundCheck && foundCheck.length >0) {
                        orderHelper.push(...foundCheck);
                    }
                }
            }else{
                const vsName = fs.obj as string
                const foundChecks = checks.filter(c=>c.sections && c.sections.indexOf(vsName) !== -1).map(c=>{
                    c['idx'] = idx++;
                    return c;
                })
                orderHelper.push(...foundChecks)
            }
        });

        // Filter checks duplicates for section checks.
        const result = orderHelper.reduce((acc: ICheck[], check: ICheck) => {
            const foundObject = acc.find((object: ICheck) => object.uuid === check.uuid);
            if (foundObject) {
                return acc;
            }
            acc.push(check);
            return acc;
        }, []);
        return result;
    }
    abstract handleExport(name,formReference,setProgress);

    abstract handleExportServer();

    resumeState  = async (_event?)=>{
        const formHandler = this.context;
        const {formData,embeddedFormDocument,processInstanceId,processDefinitionId,taskHistory,currentTaskIdx,taskId,values,inProgress} = this.state;
        const [currentStepIdx,_currentTaskIdx] = currentTaskIdx;

        if (_event && typeof _event.preventDefault == 'function'){
            _event.preventDefault();
        }

        if (_event.ctrlKey && _event.altKey){
            return this.startFlow(null)();
        }

        let visited = false;
        let resumeTaskIdx = _currentTaskIdx;

        let resumeStep = taskHistory[currentStepIdx];

        do {
            resumeStep = resumeTaskIdx > 0 ? resumeStep : taskHistory[currentStepIdx-1];
            resumeTaskIdx = resumeTaskIdx > 0 ? resumeTaskIdx - 1 : resumeStep.taskDefs.length - 1;
            visited = resumeStep.taskDefData[resumeTaskIdx]?.visited ?? false;
            if (visited || resumeTaskIdx == 0) {
                break;
            }else{
                resumeTaskIdx -= 1;
            }
        }while (!visited)


        if (resumeStep){

            const prevActivityName = resumeStep.taskDefs[resumeTaskIdx];
            const prevActivityData = resumeStep.taskDefData[resumeTaskIdx]

            const preActivityFullId = prevActivityData.fullId;

            if (this.callbacks?.taskExit && taskId){
                this.callbacks.taskExit(taskId);
            }
            let formData;
            if (preActivityFullId.indexOf(":") !== -1) {
                const parts = preActivityFullId.split(":");
                formData = await formHandler.resumeActivityBefore(processInstanceId!, parts[0]);
            } else {
                formData = await formHandler.resumeActivityBefore(processInstanceId!, prevActivityName)
            }

            if (formData){
                const task = (formData as TaskFormData).task
                const taskId = task.id
                const taskName = task.name
                await this.newStateCbk(taskId,processInstanceId,formData);
            }else{
                const status = await formHandler.getProcessInstanceStatus(processInstanceId!)
                let newTaskId,newTaskFormData;
                if (isStateEnded(status.state)){
                    newTaskId = "ended";
                    newTaskFormData = status;
                }else{
                    newTaskId = "paused";
                    newTaskFormData = status;
                }
                await this.newStateCbk(newTaskId,processInstanceId,newTaskFormData);
            }
        }
        return true;
    }





    private _handleNewTaskFormData = async (processId:string,taskFormData:TaskFormData | null)=>{
        const formHandler = this.context;
        let newTaskId,newTaskFormData;
        if (taskFormData?.task == null){
            const status = await formHandler.getProcessInstanceStatus(processId)
            //If there is a user task in the active states
            const activeStates = status.activeStates;
            const userTasks = activeStates && activeStates.filter(s=>s.type === 'UserTaskImpl');
            const userTask = userTasks && userTasks[0];
            if (userTask){
                const tasks = await formHandler.loadTasks(processId);
                if (tasks && tasks[0]){
                    const ht = await formHandler.handleTask(tasks[0].id);
                    if (ht) {
                        return ht;
                    }
                }
            }

            if (isStateEnded(status.state)){
                // checkForPausedTask();
                newTaskId = "ended";
                newTaskFormData = status;
            }else{
                newTaskId = "paused";
                newTaskFormData = status;
            }
        }else{
            newTaskId = taskFormData.task.id;
            newTaskFormData = taskFormData;
        }

        return [newTaskId,newTaskFormData];
    }

    raiseError = async  (errorCode,errorName,data)=>{
        const taskId = this.state.taskId;
        if (!taskId){
            console.log("Can raise error only from task forms")
        }else {
            const newTaskFormData = await this.context.raiseError(taskId, errorCode, errorName, data);
            return await this.withNewTaskFormData(taskId,this.state.processInstanceId,false,newTaskFormData);
        }
    }

    startFlow = (action,extraVars?) =>  {
        const methods = this.props.methods

        const formHandler:FormHandlerStrategy = this.context;
        const processDefinitionKey = this.props.processDefinitionKey;
        const {processDefinitionId,taskHistory,currentTaskIdx:[currentStepIdx,_currentTaskIdx],taskId,values,files,error,inProgress} = this.state;
        const taskDefId = taskHistory[currentStepIdx]?.taskDefs[_currentTaskIdx]
        const formReference = (this.state.formData as FormData).formFields
        const noStep = typeof this.props.noStep === 'boolean'?this.props.noStep as boolean: this.props.noStep === "true";

        const handleValidForm = async (_event:SubmitHandler<Record<string, any>>)=>{

            let _form = this.formRef.current ;
            let form;

            let _values;
            if (_form && _form.tagName !== 'FORM'){
                form = _form.querySelector('form') as HTMLFormElement
                _values = convert(form);
            }else {
                form = _form as HTMLFormElement
                _values = values as Variables;
            }

            _values = {...this.props.variables,..._values} //variables already in the form overwrite variables in the params

            /*
            const rv = form!=null ? form.reportValidity?form.reportValidity(): true : true; //TODO:Check Vaidation in mobiles somehow
            if (!rv){
                console.log("Form has validation errors");
                return false;
            }
            */

            const postProcessMapper = (v)=>{
                if (v== null){
                    return v;
                }else if (v.postProcessGet) {
                    return v.postProcessGet();
                } else if (Array.isArray(v)){
                    return v.map(vi=>{
                        return postProcessMapper(vi)
                    });
                }else if (typeof v === 'object'){
                    Object.keys(v).forEach(k=>{
                        v[k] = postProcessMapper(v[k]);
                    });
                    return v;
                }else{
                    return v;
                }
            }


            _values = _.mapValues(_values,(v,k)=>{
                return postProcessMapper(v);
            });

            _values['action'] = action;
            if (action === '__export'){
                this.inProgressCbk(true);
                //FIXME: THis should not be placed here. Specialized functions (like get the name for a CustomerData form)
                //should not be placed in a generic component
                // However, the whole functionality should be re-implemented server-side
                //to avoid long waits in the browser
                const nameFN = (fr)=>{
                    const v = fr.filter(f=>f.id === 'CorCD_RegNum').map(f=>f.value?.value)[0]
                    return v && "DED_"+v ||  "export";
                }
                this.handleExport(nameFN,formReference,this.inProgressCbk).then(()=>{

                }).catch(err=>{
                    this.errorCbk(err);
                }).finally(()=>{
                    this.inProgressCbk(false);
                })
                return;
            }else if (action === '__export_server'){
                this.handleExportServer();
                return;
            }else if (action === '__export_server'){
                this.handleExportServer();
                return;
            }

            if (values['internalComponentAction']){
                _values['action'] = values['internalComponentAction'];
            }

            if (files){
                const file_keys = await Promise.all(Object.keys(files)
                    .filter(file_key => files![file_key])
                    .map(async file_key => {
                        const _files = files![file_key] as any[];
                        const dataBase64ArrayPromise = _files.map(async file => {
                            if (typeof file.data === 'function') {
                                const dataBase64 = await file.data() as Promise<string>;
                                file.data = dataBase64;
                            }
                            return file.data;
                        })
                        await Promise.all(dataBase64ArrayPromise);
                        return file_key;
                    }))

                _values['_files'] = file_keys;
            }

            //Add annotations
            if (this.state.annotator.annotations){
                _values["__sancus_annotations"] = this.state.annotator.annotations
            }

            if (this.state.annotator.edits){
                _values["__sancus_edits"] = this.state.annotator.edits
            }


            //Filter out read only values
            _values = _pickBy(_values,(value,key)=>{
                const vc = this.getConstraintsForKey(key);
                const readonly = vc.some(c=> c.name === 'readonly')
                return !readonly
            })

            this.inProgressCbk(true);



            let processInstanceId;
            try {
                if (taskId != null) {
                    processInstanceId = this.state.processInstanceId!;
                    const taskFormData:TaskFormData | null = await formHandler.submitTaskForm(taskId,processInstanceId,taskDefId, _values)
                    return await this.withNewTaskFormData(taskId,processInstanceId,noStep,taskFormData);

                } else {
                    const {processInstance, taskFormData} =
                        await formHandler.startProcessBy(processDefinitionId ? 'id' : 'key', (processDefinitionId || processDefinitionKey)!, _values)
                    return await this.withNewTaskFormData(null,processInstance.id,noStep,taskFormData);
                }


                return true;
            }catch (e) {
                if ((e as any).data?.type !== 'fieldValidation'){
                    console.error("Got error",e);
                }

                this.errorCbk(e)
                return false;
            }finally {
                this.inProgressCbk(false)

            }
        }
        const handleErrorForm =  (async (errors:FieldErrors<any>, event) => {
            const methods:UseFormMethods = this.props.methods;

            const allReadonly = Object.keys(errors).every(e=>{
                const idx = e.lastIndexOf('.');
                const name = e.substring(idx+1);
                const vc = this.getConstraintsForKey(name);

                if (vc.length == 0 && IndexDecoratorFormValidationUtilFactory.isGroupField(e)){
                    const name2 = IndexDecoratorFormValidationUtilFactory.getFieldId(e);
                    const vc2 = this.getConstraintsForKey(name2);
                    return vc2.some(c=> c.name === 'readonly')
                }else {
                    const readonly = vc.some(c => c.name === 'readonly')
                    return readonly
                }
            })

            if (allReadonly){
                //We can ignore the validation errors as they are not submitted anyway.
                //This situation can happen in review forms - some fields might not be valid but yet they are in the form
                //Since they are read-only there is no way to mitigate the situation
                await handleValidForm(event);
            }else {
                //unset dirty
                const {values} = this.state
                console.log("During form submit: errors", errors, event, values);
                methods.reset(undefined, {errors: true})
            }

        })

        const handleBackAction = async ()=>{
            const {taskId,processInstanceId} = this.state;
            const _values = {action}
            const taskFormData:TaskFormData | null = await formHandler.submitTaskForm(taskId!,processInstanceId,taskDefId, _values)

            if (this.callbacks?.taskExit){
                this.callbacks.taskExit(taskId!);
            }
            if (noStep){
                /*
                 * noStep means that we won't display the next task after submitting the currentOne.
                 * Typically this means there is a wrapper application that contains the formContainer
                 * and it will be notified by the taskExit callback registered
                 */
                return;
            }
            let rsp = await this._handleNewTaskFormData(processInstanceId!, taskFormData)
            const [newTaskId,newTaskFormData] = rsp;
            return await this.newStateCbk(newTaskId,processInstanceId!,newTaskFormData);
        }

        const methodHandler = methods.handleSubmit(handleValidForm,handleErrorForm);
        return (_event?)=>{
            if (_event && typeof _event.preventDefault == 'function'){
                _event.preventDefault();
            }

            if (_event && _event.ctrlKey && _event.altKey){
                const formHandler:FormHandlerStrategy = this.context;
                const {processInstanceId,taskId} = this.state;
                return formHandler.discardProcessInstance(processInstanceId!,taskId!,"User Hidden click");
            }

            if(action !== 'back') {
                return methodHandler(_event);
            }else{
                return handleBackAction();
            }
        }

    }

    private async withNewTaskFormData(taskId:string | null | undefined,processInstanceId:string | null | undefined,noStep:boolean,taskFormData: TaskFormData | null) {
        if (taskId!=null) {
            if (this.callbacks?.taskExit) {
                this.callbacks.taskExit(taskId);
            }
            if (noStep) {
                /*
                 * noStep means that we won't display the next task after submitting the currentOne.
                 * Typically this means there is a wrapper application that contains the formContainer
                 * and it will be notified by the taskExit callback registered
                 */
                return;
            }
            if (taskFormData?.task?.processInstanceId && taskFormData?.task?.processInstanceId != processInstanceId) {
                //FIXME: Check if it is required to swithc to the sub process id or is it better to keep  the root level
                processInstanceId = taskFormData?.task?.processInstanceId
            }
            let rsp = await this._handleNewTaskFormData(processInstanceId!, taskFormData)
            const [newTaskId, newTaskFormData] = rsp;

            const newProcessInstanceId = newTaskFormData.task?.processInstanceId || newTaskFormData.processInstanceId;
            await this.newStateCbk(newTaskId, newProcessInstanceId, newTaskFormData);
        }else if (processInstanceId){
            if (noStep){
                /*
                 * We have started a new workflow. Wrapping application shall be notified by the instanceEnter callback
                 * TODO: Check
                 */
                return;
            }

            let rsp = await this._handleNewTaskFormData(processInstanceId, taskFormData)
            const [newTaskId,newTaskFormData] = rsp;
            await this.newStateCbk(newTaskId,processInstanceId!,newTaskFormData);
        }
    }
}

const intlDefaultValuesCmp = (Cmp)=>{
    return (...chunks) => {
        const cs = chunks.join()
        return `<${Cmp}>${cs}</${Cmp}>`
    }
}
const intlDefaultValues = ['b','li','ul','a','u','ol','lo','hr','br'].reduce((acc,c)=>{
    acc[c] = intlDefaultValuesCmp(c)
    return acc;
},{})


let m_gen = 0;

function useGForms(){
    const methods = useForm({
        mode: "all"
    });

    methods['uuid'] = 'outer'+(m_gen++);
    return methods;
}

export const DecoratedNode = (props) =>{

    const methods = useGForms();

    const Node = props.Node;

    let intl:IntlShape;
    try{
        intl = useIntl()
    }catch (e){
        console.error('Failed to instantiate intl',e);
        intl = {
            formatMessage:(({defaultMessage})=>defaultMessage)
        }as IntlShape
    }

    const _formatMessage = intl.formatMessage;


    // @ts-ignore
    intl.formatMessage = function (messageDescriptor, values) {
        // const _values = {...intlDefaultValues, ...(values||{}) }
        const _values = values || {}
        try {
            if (window.messages == null){
                window.messages = {}
            }
            window.messages[messageDescriptor.id] = [messageDescriptor.defaultMessage, new Error()];
            //Replace dangling <hr> and <br> messages

            const opts:IntlMessageFormatOptions = {
                ignoreTag: true
            }

            if (messageDescriptor.id?.indexOf("__empty__") !== -1){
                return '__empty__';
            }else {

                const val = _formatMessage.bind(intl).call(
                    intl,
                    messageDescriptor,
                    _values,
                    opts);
                return val;
            }
        }catch (e){
            throw e;
        }
    }

    return <FormProvider {...methods}>
            <Node {...props} intl={intl} methods={methods}/>
    </FormProvider>
}


export const FCIntlProvider = (params)=>{
    const languageContext = useContext(LanguageContext);
    const {locale,messages} =  languageContext ?? {}

    if (locale && messages){
        console.debug("Will use IntlProvider")
        // @ts-ignore
        return <IntlProvider messages={messages} locale={locale} defaultLocale="en">{params.children}</IntlProvider>
    }else{
        console.debug("Will use IntlProvider")
        /* //this was commented because of SRD-377, TODO check if we need this
        console.debug("Will use MockIntlProvider")
        const mockIntl = {formatMessage:(descriptor,values,opts)=>descriptor.defaultMessage} as IntlShape
        return <RawIntlProvider value={mockIntl}>{params.children}</RawIntlProvider>*/

        // @ts-ignore
        return <IntlProvider messages={{}} locale={"en"} defaultLocale={"en"}>{params.children}</IntlProvider>
    }
}

export function decorate(Node: any) {
    return (props: any)=><FCIntlProvider>
        <DecoratedNode {...props} Node={Node}/>
    </FCIntlProvider>
}

