import *  as React from 'react';
import {getStore} from "../store";

import {SancusConditionEvaluator} from "./conditionEvaluator.service";
import {useIntl} from "react-intl";
import {useContext} from "react";
import {FormContainerContext} from "./FormContainerContext";
import {DialogData, IActionJson, IActions} from "./FormContainerBase";



export type ActionsProperties = {
    contextEvaluator: SancusConditionEvaluator;
    actionsConfig: string | IActions;
    setSelectValueContextEval: string;
    selectOutputFieldName: string;

}

type DoRender = {
    doRender:(actionsConfig:IActionJson[],HandleButtonClick:(evt,action:IActionJson)=>any)=>JSX.Element
}

export function ActionsBase(props:ActionsProperties & DoRender) {
    const selectOutputFieldName = props.selectOutputFieldName;
    const setSelectValueContextEval = props.setSelectValueContextEval;
    let actionsConfig:IActionJson[];

    try {
        actionsConfig = typeof props.actionsConfig === 'string' ? JSON.parse(props.actionsConfig) : props.actionsConfig;
    } catch (e) {
        throw e;
    }

    if (!selectOutputFieldName) {
        throw new Error('Actions component: selectOutputFieldName is required');
    }

    if (!setSelectValueContextEval) {
        throw new Error('Actions component: setSelectValueContextEval is required');
    }

    if (!actionsConfig) {
        throw new Error('Actions component: actionsConfig is required');
    }

    const store = getStore();

    const showDialog = (options: any) => {
        getStore().dispatch({
            type: 'ACTIVE_DIALOG',
            payload: options,
        });
    }

    const triggerValueChange = (payload: any) => {
        return {
            type: 'TRIGGER_VALUE_CHANGE',
            payload: payload,
        }
    };

    const HandleButtonClick = (event,action:IActionJson) => {
        let evalResult:boolean |undefined;
        try {
            if (typeof setSelectValueContextEval === 'string') {
                evalResult = props.contextEvaluator.eval(setSelectValueContextEval);
            }
        } catch (e) {
            console.error('props.contextEvaluator.eval() ', e);
            throw e;
        }
        if (action.dialog){
            showDialog({...action.dialog, OkHandler: (_a)=>{
                    store.dispatch(triggerValueChange({
                        key: selectOutputFieldName,
                        value: evalResult,
                        submit: action.id || action.value
                    }));
                }})
        }else {
            store.dispatch(triggerValueChange({
                key: selectOutputFieldName,
                value: evalResult,
                submit: action.id || action.value
            }));
        }
    }

    const intl = useIntl()

    const {processDefKey,formDefId,formReference:_formReference} = useContext(FormContainerContext)

    const locActionsConfig = actionsConfig.map(ac=>{
        const locLabel = intl.formatMessage({
            id:`${processDefKey}.${formDefId}.action.${ac.id}`,
            defaultMessage:ac.label,
            description:`Value for ${formDefId}.action.${ac.id}`,
        })
        return {...ac, label:locLabel}
    })


    return props.doRender(locActionsConfig,HandleButtonClick);
}