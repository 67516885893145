export default {
    "name": "nAMS Email OTP",
    "id": "com.norbloc.sancus.integrations.NAMSEmailOTP",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://raw.githubusercontent.com/twbs/icons/main/icons/envelope-open.svg"
    },
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },

    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "nams_otp",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "User Email",
            "type": "String",
            "value": "email",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "email"
            }
        },

        {
            "label": "Prefer",
            "type": "Dropdown",
            "value":"fides",
            "choices": [
                {"name": "Fides", "value": "fides"},
                {"name": "UCP", "value": "ucp"},
            ],
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "prefer"
            }
        },

        {
            "label": "Prefer (Mapping)",
            "type": "String",
            "value": "prefer",
            "editable": false,
            "binding": {
                "type": "camunda:in",
                "target": "prefer"
            }
        },

        {
            "label": "User Id variable Name",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "userId"
            }
        },{
            "label": "User Token",
            "type": "String",
            "value": "token",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "token"
            }
        }
    ]
}