export default {
    "name": "Scrive",
    "id": "com.norbloc.sancus.integrations.Scrive",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },
    "icon":{
        "contents":"https://www.scrive.com/app/uploads/2020/12/favicon-32x32-1.png"
    },
    "properties": [
        {
            "label": "Activity",
            "type": "Hidden",
            "value": "scrive",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "User Id (Mapping)",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "userId"
            }
        },

        {
            "label": "Template Id",
            "type": "String",
            "description": "Template ID",
            "editable": true,
            "value": "8222115557376405778",
            "constraints":{
                "notEmpty":true
            },
            "binding": {
                "type": "camunda:inputParameter",
                "name": "templateId"
            }
        },

        {
            "label": "Template Id (Mapping)",
            "type": "Hidden",
            "editable": false,
            "value": "templateId",
            "binding": {
                "type": "camunda:in",
                "target": "templateId"
            }
        },

        {
            "label": "Document Title",
            "type": "String",
            "description": "Title of the document to be signed, as it will appear in the scrive flows",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "documentTitle"
            }
        },

        {
            "label": "Document Title (Mapping)",
            "type": "Hidden",
            "editable": false,
            "value": "documentTitle",
            "binding": {
                "type": "camunda:in",
                "target": "documentTitle"
            }
        },

        {
            "label": "Document File Name",
            "type": "String",
            "description": "File name of the document to be signed, as it will appear in the scrive flows",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "signDocumentFileName"
            }
        },

        {
            "label": "Document File name (Mapping)",
            "type": "Hidden",
            "editable": false,
            "value": "signDocumentFileName",
            "binding": {
                "type": "camunda:in",
                "target": "signDocumentFileName"
            }
        },
        {
            "label": "Company Reg Id (Mapping)",
            "type": "String",
            "editable": true,
            "value": "CorCD_RegNum",
            "binding": {
                "type": "camunda:in",
                "target": "CorCD_RegNum"
            }
        },
        {
            "label": "Company Name (Mapping)",
            "type": "String",
            "editable": true,
            "value": "CorCD_CompName",
            "binding": {
                "type": "camunda:in",
                "target": "CorCD_CompName"
            }
        },
        {
            "label": "Document Id (Mapping)",
            "type": "String",
            "editable": true,
            "value": "idDocumentNumber",
            "binding": {
                "type": "camunda:in",
                "target": "idDocumentNumber"
            }
        },

        {
            "label": "First Name (Mapping)",
            "type": "String",
            "editable": true,
            "value": "firstName",
            "binding": {
                "type": "camunda:in",
                "target": "firstName"
            }
        },

        {
            "label": "Last Name (Mapping)",
            "type": "String",
            "editable": true,
            "value": "lastName",
            "binding": {
                "type": "camunda:in",
                "target": "lastName"
            }
        },

        {
            "label": "Mobile Phone (Mapping)",
            "type": "String",
            "editable": true,
            "value": "mobile",
            "binding": {
                "type": "camunda:in",
                "target": "mobile"
            }
        },{
            "label": "Email (Mapping)",
            "type": "String",
            "editable": true,
            "value": "email",
            "binding": {
                "type": "camunda:in",
                "target": "email"
            }
        },

        {
            "label": "Result Variable (Mapping)",
            "type": "String",
            "editable": true,
            "value": "scrive_data",
            "binding": {
                "type": "camunda:out",
                "source": "scrive_data"
            }
        }
    ]
}
