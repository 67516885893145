import {handleActions} from 'redux-actions';
import {RootState} from "./root.state";
import {RepeatableDialogActions} from "../actions/repeatableDialog.actions";


export const repeatableDialogReducer = handleActions({
    [RepeatableDialogActions.Type.SHOW_REPEATABLE_DIALOG]: (state:RootState['showRepeatableDialog'], action) => {
        return {...action.payload};
    },
    [RepeatableDialogActions.Type.CLOSE_REPEATABLE_DIALOG]: (state:RootState['showRepeatableDialog'], action) => {
        return {repeatableGroupId:'',idx:0};
    }
},{repeatableGroupId:'',idx:0});
