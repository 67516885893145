import {
    FieldValidationCallbacks, FormReference,
    GenericFieldParams,
    htmlType,
    substitutions
} from "./generic_component_base";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import * as React from "react";
import {useIntl} from "react-intl";
import {AnyCheckType} from "./FormContainerBase";
import {FormContainerContext} from "./FormContainerContext";
import {withLang} from "./LanguageContext";
import {MomentInput} from "moment";
import {extractValidation} from "../utils/formValidation.util";
import {useLayout} from "./LayoutContext";
import {getStore} from "../store";

export type Validation = {[key:string]:string | true | RegExp | ((...args)=>boolean | string)}
export type GenericFieldRenderers = {

    renderCustom:<V,E>(elemRef,customComponent,fv_key,label,value:V,setValue:(v:V)=>void,extra_props:{[key:string]:string},validation:Validation,checkMessages:AnyCheckType | undefined,formReference:FormReference, fieldValidationCallbacks: FieldValidationCallbacks)=>JSX.Element,
    renderCheckBox:<V,E>(elemRef,fv_key,_value:V,setValue:(v:V)=>void,error:E,setError:(e:E)=>void,validation:Validation,readOnly:boolean,extra_props:any,label: string,formReference:FormReference, fieldValidationCallbacks: FieldValidationCallbacks)=>JSX.Element,
    renderDate:<V extends MomentInput,E=string>(elemRef,fv_key,_value:V,setValue:(v:V)=>void,error:E,setError:(e:E)=>void,validation:Validation,readOnly,extra_props:{[key:string]:string} | undefined,label: string,formReference:FormReference, fieldValidationCallbacks: FieldValidationCallbacks)=>JSX.Element,
    renderFile:<V,E>(elemRef,fv_key,_value:V,setValue:(v:V)=>void,error:E,setError:(e:E)=>void,validation:Validation,label,type,readOnly:boolean,extra_props,formReference:FormReference, fieldValidationCallbacks: FieldValidationCallbacks)=>JSX.Element
    renderText:<V = string,E=string | null>(elemRef,fv_key,_value:V,setValue:(v:V)=>void,error:E,setError:(e:E)=>void,validation:Validation,type,readOnly:boolean,extra_props,formReference:FormReference,label: string, fieldValidationCallbacks: FieldValidationCallbacks)=>JSX.Element
    renderOnfido:()=>JSX.Element
    renderPep:()=>JSX.Element
    renderJSON:(elemRef,fv_key,label,readOnly,formReference)=>JSX.Element

    renderToolTip:(title:string,target:JSX.Element)=>JSX.Element
    renderAnnotated:(fv_key,fv_value,target:JSX.Element,readonly:boolean,modes:"" | string[],xs,dataItems)=>JSX.Element
}

export const AnnotationContext = createContext<null | {value:string,level:string}>(null)

export const extractFieldType = (fv_value: GenericFieldParamsBase): string | undefined => {

    let type = fv_value.properties?.type || fv_value.typeName && htmlType(fv_value.typeName);
    return type;
}

export const isDateType = (type: string | undefined): boolean => {
    return type === 'date';
}

export type GenericFieldParamsBase = GenericFieldParams & {renderers:GenericFieldRenderers}
export function GenericFieldComponentSimpleBase<T>(fv_value:GenericFieldParamsBase ){

    const fv_key = fv_value.id;
    let type = extractFieldType(fv_value);

    if (fv_value.properties?.type){
        // delete fv_value.properties.type;
    }
    // Translated messages in French with matching IDs to what you declared
    const layout = useLayout();
    const intl = useIntl();
    const {processDefKey,formDefId,formReference:_formReference,formExtensions} = useContext(FormContainerContext)

    const label = intl.formatMessage({
        id:`${processDefKey}.${formDefId}.${fv_key}`,
        defaultMessage:fv_value.label,
        description:`Value for ${formDefId}.${fv_key}`,
    })
    fv_value = {
        ...fv_value,
        label,
    };

    /* _value is a value that is held in the Components state, updated with setValue during onChange event
       fv_value.value is the value that comes from the server
       fv_value._value is coming from the FormContainer state
     */

    let value = fv_value._value ?? fv_value.value?.value;

    //FIXME: THis should happen server side
    //Files come as arrays
    if (type == 'object' && value && (value.type == 'file' || value[0] && value[0].type)){
        type = 'file';
    }

    if (value && typeof value === 'string' && value.indexOf("$")!= -1){
        value = fv_value.contextEvaluator.eval(value)
    }

    const initValue = value ?? (type == "checkbox"?false:"")


    const [_value,setValue] = useState<string | boolean| undefined>(undefined);
    //TODO remove this when file validation is implemented
    const [error,setError] = useState<string | boolean | null>(null);

    const checkMessages = fv_value.checkMessages;
    const formReference = fv_value.formReference
    const validation: Validation = extractValidation(fv_value);

    const filterConditionalValidationRuleProps = (propName: string): boolean => {
        return propName !== 'com.norbloc.sancus.validators.SancusConditionalRequiredValidator' &&
            propName !== 'com.norbloc.sancus.validators.SancusRequiredValidator' &&
            propName !== 'com.norbloc.sancus.validators.GroupFormFieldConditionalRequiredValidator' &&
            propName !== 'com.norbloc.sancus.validators.SancusMaxLengthValidator' &&
            propName !== 'com.norbloc.sancus.validators.GroupFormFieldConditionalMaxLengthValidator' &&
            propName !== 'com.norbloc.sancus.validators.SancusMaxValueValidator' &&
            propName !== 'com.norbloc.sancus.validators.GroupFormFieldConditionalMaxValueValidator' &&
            propName !== 'com.norbloc.sancus.validators.SancusMinLengthValidator' &&
            propName !== 'com.norbloc.sancus.validators.GroupFormFieldConditionalMinLengthValidator' &&
            propName !== 'com.norbloc.sancus.validators.SancusMinValueValidator' &&
            propName !== 'com.norbloc.sancus.validators.GroupFormFieldConditionalMinValueValidator';
    };
    const extra_props: {[key:string]:string} = fv_value.properties &&
        (Object.keys(fv_value.properties)
            .filter(pk => pk !== 'type')
            .filter(filterConditionalValidationRuleProps)
            .reduce((acc, pk) => {
                let pv = fv_value.properties[pk];
                if (pv) {
                    acc[pk] = pv
                } else {
                    acc[pk] = ""
                }
                return acc;
            }, {})) || {};

    const dataItems = Object.keys(fv_value).filter(key => key.startsWith('data-')).reduce((acc, key) => {
        acc[key] = fv_value[key];
        return acc;
    },{});

    const readOnly = !!(validation.readOnly || validation.readonly) //If there is any value in a readonly constraint, it is readonly

    const customComponent = fv_value.properties?.component;
    let ret;
    const elemRef = useRef()

    useEffect(()=>{
        if (fv_value.focused){

            const ele = elemRef.current as unknown as HTMLElement
            if (ele && ele.focus){
                ele.focus();
            }else{
                console.log("Must Focus",fv_value.id,elemRef.current)
            }

        }
    },[fv_value.focused,elemRef.current])


    if (customComponent){
        // delete fv_value.properties?.component;

        ret = fv_value.renderers.renderCustom(elemRef,customComponent,fv_key,label,(_value || initValue || value),setValue,extra_props,validation,checkMessages,formReference,fv_value.fieldValidationCallbacks!)

    }else {
        if (type === 'checkbox') {
            //                                      (fv_key,_value,setValue,error,setError,validation,readOnly,label,formReference, fieldValidationCallbacks)
            ret= fv_value.renderers.renderCheckBox(elemRef,fv_key,(_value || initValue || value),setValue,error,setError,validation,readOnly,extra_props,label,formReference, fv_value.fieldValidationCallbacks as FieldValidationCallbacks);
        }else if (type=== 'date') {
            ret =  fv_value.renderers.renderDate(elemRef,fv_key, (_value || initValue|| value) as string, setValue as ((string)=>void), error, setError, validation, readOnly,extra_props, label, formReference, fv_value.fieldValidationCallbacks as FieldValidationCallbacks);
        }else if (type=== 'datetime') {
            let exp = {...extra_props,format:"DD/MM/yyyy HH:mm:ss",picker_type:"datetime"}
            ret = fv_value.renderers.renderDate(elemRef,fv_key, (_value || initValue|| value) as string, setValue as ((string)=>void), error, setError, validation, readOnly,exp, label, formReference, fv_value.fieldValidationCallbacks as FieldValidationCallbacks);
        } else if(type == 'file') {
            const nameFromValue = (_v:any[])=>{
                return _v && _v.map(_f=>`${_f.name} (${_f.size})`).join("\n")
            }
            //const label = readOnly ? fv_value.label : nameFromValue(_value as any) || <span dangerouslySetInnerHTML={{__html:(fv_value.label || fv_key) }}/>
            // const label = fv_value.label;
            ret=fv_value.renderers.renderFile(elemRef,fv_key,(_value || initValue || value),setValue,error,setError,validation,label,type,readOnly,extra_props,formReference, fv_value.fieldValidationCallbacks as FieldValidationCallbacks);


        }else if (type === 'object') {
            const javaObjectType = fv_value.value?.objectType;
            const javaObjectTypeName = fv_value.value?.objectTypeName;

            if (fv_value.id === 'onfido_check_result' && fv_value.value?.objectType === 'com.norbloc.sancus.services.OnfidoHelperService$CheckInfo') {
                return null
            } else if (fv_value.id === 'pepScreenRealtime') {
                return null
            } else if (fv_value.id === 'checks') {
                return null
            }else if (fv_value.id === 'scrive_data') {
                fv_value.properties['visual_section'] = "Signature"
                fv_value.properties['component'] = "ScriveComponent"
                ret = fv_value.renderers.renderCustom(elemRef,customComponent,fv_key,label,value,setValue,extra_props,validation,checkMessages,formReference,fv_value.fieldValidationCallbacks!)
            } else {
                ret = fv_value.renderers.renderJSON(elemRef,fv_key, label, readOnly,formReference)
            }
        }else if (type === 'json'){
            ret = fv_value.renderers.renderJSON(elemRef,fv_key, label, readOnly,formReference)
        }else{
            ret = fv_value.renderers.renderText(elemRef,fv_key,(_value || initValue || value),setValue,error,setError,validation,type,readOnly,extra_props,formReference,label, fv_value.fieldValidationCallbacks as FieldValidationCallbacks);
        }
    }

    const store = getStore();
    const path = fv_value.path
    const state = store.getState();
    const annotator = state.annotator;
    const hasAnnotations = annotator?.annotations && annotator?.annotations[path];


    const annotatable = fv_value.properties.annotatable?.toLowerCase() !== 'false' &&
        fv_value.properties.annotatable?.toLowerCase() !== 'no';

    const editable = fv_value.properties.editable?.toLowerCase() !== 'false' &&
        fv_value.properties.editable?.toLowerCase() !== 'no';

    const modes = formExtensions.annotator?.split(",").map(s=>s.trim()).filter(m=>{
        if (m == 'edit' && !editable){
            return false;
        }
        if (m == 'annotate' && !annotatable){
            return false;
        }
        return true;
    });

    const xs = fv_value.properties.xs ? parseInt(fv_value.properties.xs) : undefined;

    if (readOnly && layout == 'review') {
        return fv_value.renderers.renderAnnotated(path, fv_value,ret,false,modes,xs,dataItems);
    } else {

        return fv_value.renderers.renderAnnotated(path, fv_value,ret,true,modes,xs,dataItems);
    }

}
