import {createAction} from 'redux-actions';
import {DialogData} from "../../common/FormContainerBase";


export namespace FormDialogActions {
    export enum Type {
        ACTIVE_DIALOG='ACTIVE_DIALOG',
    }

    export const activeDialog = createAction<DialogData>(Type.ACTIVE_DIALOG);
}

export type FormDialogActions = Omit<typeof FormDialogActions, 'Type'>;
