import { handleActions } from 'redux-actions';
import {CheckActions} from '../actions/checks';
import {CHECK_STATUS} from "../../constants/checkStatus.constant";

/**
 * Check filter criteria relative actions
 */
export const checksFilterReducer = handleActions<CHECK_STATUS, any>(
	{
		[CheckActions.Type.APPLY_CHECK_FILTER]: (state, action) => {
			return action.payload;
		},
	},
	{} as any
);