import * as React from "react";
import {useEffect, useRef} from "react";
import {createGenerateClassName, jssPreset, StylesProvider, ThemeOptions} from "@material-ui/core/styles";
import {create, sheets} from "jss";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import rtl from 'jss-rtl';
import {createSancusTheme} from "./theme_factory";


export const generateClassNameSeed = 'client';

const generateClassName = createGenerateClassName({
    seed: generateClassNameSeed
});

const createGenerateId = () => {
    let counter = 0
    let timestamp = new Date().getTime();
    return (rule, sheet) => {
        return `sancus--${rule.key}-${timestamp}-${counter++}`
    }
}

const jss = create({
    plugins: jssPreset().plugins.slice(0),
    createGenerateId
});

const jss_rtl = create({
    plugins: [...jssPreset().plugins, rtl()],
    createGenerateId
});


export default function ThemeWrapper<P,T = typeof React.Component>(props:{theme_data:ThemeOptions,locale?:string | null,children}){
    const theme_data ={...props.theme_data};
    const arSA = {
        props: {
            MuiAutocomplete: {
                clearText: 'Clear',
                closeText: 'Close',
                loadingText: 'Loading…',
                noOptionsText: 'No options',
                openText: 'Open',
            },
            MuiDropzoneArea: {
                dropzoneText: 'قم بسحب وإسقاط ملف هنا أو انقر',
            },
        },
    };

    let theme;
    let jssSelector = jss;
    // lang provider
    if (props.locale === 'ar') {
        jssSelector = jss_rtl;
        theme_data.direction = 'rtl';
        theme = createSancusTheme(theme_data, arSA);
    } else {
        theme = createSancusTheme(theme_data);
    }

    const domRef = useRef<HTMLElement>();
    useEffect(()=>{
        // if (theme_data.direction == 'rtl' && domRef.current){
        //     domRef.current!.id='RootRef'
        //     domRef.current!.dir='rtl'
        // }
    },[])

    return <ThemeProvider theme={theme}>
        <StylesProvider jss={jssSelector} generateClassName={generateClassName}>
            <CssBaseline />
                <div dir={theme_data.direction}>
                    {props.children}
                </div>
        </StylesProvider>
    </ThemeProvider>
}

ThemeWrapper.sheets = sheets