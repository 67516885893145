import * as React from "react";
import {PEPResult,AlertEntity} from "sancus-client-common/dist/utils/pepApi.interface";
import * as pepApiUtil from 'sancus-client-common/dist/utils/pepApi.util';
import {Box, Grid, Link, Modal, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {ICheck} from "sancus-client-common/dist/common/FormContainerBase";
import {useState} from "react";
import {AlertEntityCmp} from "./pepReportComponent";

export const useStyles = makeStyles(() => ({
    green: {
        borderLeft: 'solid 3px #99cc33',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },
    yellow: {
        borderLeft: 'solid 3px #ffcc00',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    },
    red: {
        borderLeft: 'solid 3px #ff0000',
        paddingLeft: 9,
        paddingTop: 6,
        paddingBottom: 6
    }
}));

export const modalStylesF = makeStyles(() => ({
    dialog: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        overflowY: 'scroll',
        minWidth: '500px',
        maxHeight: '100%',
        padding: '30px'
    },
}));


export function PepComponent(props) {
    const modalStyles = modalStylesF();
    const check: ICheck = props.pepResult;
    const [modalBody,setModalBody] = useState<JSX.Element | null>()

    const handleClose = (event)=>{
        setModalBody(null)
    }

    const {status} = check;
    let message:any = check.message;

    const pepResult: PEPResult = check.metaData as PEPResult;
    const classes = useStyles();

    const className = status == 1 ? classes.green : classes.yellow
    if (!message) {
        const query = pepApiUtil.getPersonQuery(pepResult);
        if (query) {
            let nn =  '';

            if (query.fullName){
                nn = ` for ${query.fullName}`
            }else if (query.familyName){
                nn = ` for ${query.givenName || ''} ${query.familyName || ''}`
            }else if (query.orgName){
                nn = ` for ${query.orgName}`
            }

            if (status == 1) {
                message = <>PEP Screening report {nn} is <b>CLEAR</b></>
            } else {
                message = `Potential matches found ${nn}:`
            }
        }else{
            if (status == 1) {
                message = <>PEP Screening report is <b>CLEAR</b></>
            } else {
                message = "Potential matches found:"
            }
        }
    }else{
        message = <div dangerouslySetInnerHTML={{__html:message}}/>
    }

    const handleOpen = (entity,evt)=>{
        setModalBody(
            <div className={modalStyles.dialog}>
                <AlertEntityCmp entity={entity}/>
            </div>)
    }

    return (
        <Grid>
           <Grid className={className}>
                <Typography variant="body2">{message}</Typography>
                {
                    pepApiUtil.getAlertEntities(pepResult)?.map(entity => {
                        return (
                            <Box mt={1} key={"pep:" + entity.entityId}>
                                <Link onClick={evt=>handleOpen(entity,evt)} variant="body2"
                                      style={{color: 'black'}}>
                                    <b>{entity.entityName}</b>
                                </Link>
                            </Box>
                        );
                    })
                }
            </Grid>
            <Modal open={!!modalBody} onClose={handleClose}>{modalBody!}</Modal>
        </Grid>
    );
}

