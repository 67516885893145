import bankId_login from "./bankId_login";
import bankId_signature from "./bankId_signature";
import bolagsverket from "./bolagsverket";
import docusign from './docusign'
import email from './email'
import sendEmail from './sendEmail'
import onfido from "./onfido";
import jumio from "./jumio";
import rdc from "./rdc";
import review_task from "./review_task";
import scrive from "./scrive";
import manual_check from "./manual_check";

import signicat_login from "./signicat_login";
import signicat_signature from "./signicat_signature";
import adobe_sign from "./adobe_sign";

import risk_calculator from "./risk_calculator";

import logger from './logger'
import audit_record from "./audit_record";
import nams_email from "./nams_email";

import generate_id from "./generate_application_id";

const allTemplates =  [bankId_login,bankId_signature,bolagsverket,docusign,email,nams_email,sendEmail,onfido,jumio,rdc,review_task,scrive,manual_check,
    signicat_login,signicat_signature, adobe_sign, risk_calculator,logger,audit_record,generate_id]

export default allTemplates