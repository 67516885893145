export default {
    "name": "Email OTP",
    "id": "com.norbloc.sancus.integrations.EmailOTP",
    "appliesTo": [
        "bpmn:CallActivity"
    ],
    "icon":{
        "contents":"https://raw.githubusercontent.com/twbs/icons/main/icons/envelope-open.svg"
    },
    entriesVisible:{
        'sancus.widget.step':true,
        properties:true
    },

    "properties": [
        {
            "label": "Activity",
            "type": "String",
            "value": "email_otp",
            "editable": false,
            "binding": {
                "type": "property",
                "name": "calledElement"
            }
        },
        {
            "label": "User Email",
            "type": "String",
            "value": "email",
            "editable": true,
            "binding": {
                "type": "camunda:in",
                "target": "email"
            }
        },
        {
            "label": "User Id variable Name",
            "type": "String",
            "value": "userId",
            "editable": true,
            "binding": {
                "type": "camunda:out",
                "source": "userId"
            }
        }
    ]
}