import {CustomComponentsBaseParams} from "./generic_component_base";
import {CheckResult} from "../utils/onfidoApi.interface";
import * as React from "react";
import contextPath from "./contextPath";

import jwtDecode, {JwtPayload} from "jwt-decode";

export type IdAware = {id:string};
export type Face = IdAware & {variant: "standard"}
export type Document = IdAware & {
    side: "front" | "back",
    type: "passport"
}

export type CompletionData = {
    face: Face,
    document_front: Document,
    document_back?: IdAware
    document?:{ //This is what we get from mobile clients
        front:Document,
        back?:IdAware
    }
}

export type OnfidoWrapperParams = CustomComponentsBaseParams<{
    applicantId:string,
    sdkToken?:string,
    applicant?:{[key:string]:string},
    data:CompletionData,
    onfido_check_result?:CheckResult
} >;

export type OnfidoWrapperState = {
    applicant,
    face,
    documents:any[],
    images:any[]
}



export  default abstract class OnfidoComponentBase extends React.Component<OnfidoWrapperParams,OnfidoWrapperState> {
    protected onfidoOut?:Promise<{tearDown:()=>void}>;


    abstract withSDK(id, applicantId, sdkToken, valueChangeCbk):Promise<{tearDown:()=>void}>;


    componentDidMount() {
        const readOnly = !!this.props.validation?.readOnly

        let {id, value, valueChangeCbk} = this.props;

        const valueParsed = typeof this.props.value === "string" ?
            (this.props.value && JSON.parse(this.props.value)) :
            this.props.value;
        if (!valueParsed) {
            return;
        }

        const {applicantId, sdkToken, applicant} = valueParsed;

        if (sdkToken && !readOnly) {
            const jwtToken = jwtDecode<JwtPayload>(sdkToken)
            const exp = jwtToken.exp;
            if (exp && exp < (new Date().getTime() + 1) / 1000) {
                const error = "token has expired"
                console.log('Onfido::onError', error);
                valueChangeCbk(id, {applicantId, error}, true);
            }
            this.onfidoOut = this.withSDK(id, applicantId, sdkToken, valueChangeCbk)
        }
    }

    componentWillUnmount() {
        if (this.onfidoOut){
            this.onfidoOut.then(o=>{
                if (o?.tearDown){
                    o.tearDown();
                }
            })
        }
    }

    abstract renderImages(options: { labels: string[], fileNames: string[], imageSrcs: string[] }): JSX.Element;

    abstract renderResultComponent(checkInfo)
    abstract doRender(view);
    render() {
        const readOnly = !!this.props.validation?.readOnly
        const renderOptions: { labels: string[], fileNames: string[], imageSrcs: string[] } = {
            labels: [],
            fileNames: [],
            imageSrcs: [],
        };

        let {id, value, valueChangeCbk} = this.props;
        const valueParsed = typeof this.props.value === "string" ?
            (this.props.value && JSON.parse(this.props.value)) :
            this.props.value;
        let faceId, documents;
        if (valueParsed) {
            faceId = valueParsed.data?.face?.id;

            const document_front = valueParsed.data?.document_front || valueParsed.data?.document?.front;
            const document_back = valueParsed.data?.document_back || valueParsed.data?.document?.back;

            documents = [document_front,document_back].filter(e => e != null);

            renderOptions.labels.push('face');
            renderOptions.fileNames.push('face');

            if (document_front) {
                renderOptions.labels.push('document_front');
                const d = document_front as any;
                renderOptions.fileNames.push((d.type || '') + (`${d.side ? ('_' + d.side): ''}`) || 'file');
            }

            if (document_back) {
                renderOptions.labels.push('document_back');
                const d = document_back as any;
                renderOptions.fileNames.push((d.type || '') + (`${d.side ? ('_' + d.side): ''}`) || 'file');
            }

            renderOptions.imageSrcs = valueParsed?.data && [`${contextPath}api/onfido/face/${faceId}`, ...documents.map(d => {
                return `${contextPath}api/onfido/document/${d.id}`
            })]
        }

        let view:JSX.Element[] = [];
        if (readOnly) {
            if (renderOptions.imageSrcs && renderOptions.imageSrcs.length) {
                view.push(this.renderImages(renderOptions));
            }
            // temp disable on fido view inside form
            // const checkInfo = this.props.value?.onfido_check_result
            // if (checkInfo) {
            //     view.push(this.renderResultComponent(checkInfo))
            // }
        }

        return this.doRender(view)
    }
}
