import React, { useEffect, useState } from 'react';
import cpp from "sancus-client-common/dist/common/contextPath";
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {CustomFileFieldWithUrl} from "./generic_component";

type SignatureStatus = 'SIGNED' | 'DOCUMENTS_NOT_YET_PROCESSED' | 'IN_PROCESS' | 'OUT_FOR_SIGNATURE';

interface AdobeSignService {
    getSignUrl(props: { documentId: string }): string;
    pollSigningStatus(props: { documentId: string }): Promise<{ status: SignatureStatus }>;
    documentURLFactory(props: { documentId: string }): string;
}

export const adobeSignServiceFactory = (config: { baseUrl: string }): AdobeSignService => {
    return {
        getSignUrl(props: { documentId: string }): string {
            return `${config.baseUrl}api/adobe_sign/${props.documentId}/signUrl`;
        },
        pollSigningStatus: async (props: { documentId: string }): Promise<{ status: SignatureStatus }> => {
            const response = await fetch(`${config.baseUrl}api/adobe_sign/${props.documentId}/poll`);
            const data = await response.json();
            console.log(`poll result: ${JSON.stringify(data)}`);

            return data;
        },
        documentURLFactory(props: { documentId: string }): string {
            return `${config.baseUrl}api/adobe_sign/${props.documentId}/document`;
        }
    };
};

const service: AdobeSignService = adobeSignServiceFactory({ baseUrl: cpp.contextPath});

interface AdobeSignComponentValue {
    documentId: string;
}

interface AdobeSignComponentProps {
    id: string;
    label: string;
    value: AdobeSignComponentValue;
    valueChangeCbk(key: string, value: AdobeSignComponentValue, submit?: boolean): Promise<void>;
}

const validateValue = (value: any): AdobeSignComponentValue | null => {
    if(value && typeof value === 'object') {
        return value as AdobeSignComponentValue;
    }
    return null;
};

const isReadOnly = (validationObj?: {readOnly: string}) => {
    return validationObj && typeof validationObj === 'object' && validationObj.readOnly === 'true';
};

const AdobeSignReadOnly = (props: AdobeSignComponentProps) => {
    const [ status, setStatus ] = useState<SignatureStatus>();

    const isSigned = (status?: SignatureStatus): boolean => {
        return status === 'SIGNED';
    };

    const fetchStatus = async (documentId: string) => {
        try {
            const result = await service.pollSigningStatus({ documentId });
            setStatus(result.status);
        }
        catch (e) {
            console.error(e);
            //TODO show error
        }
    };

    useEffect(() => {
        fetchStatus(props.value.documentId);
    }, []);

    return (
        <Grid container direction={'column'} wrap={'nowrap'} spacing={2}>
            <Grid item>
                <Typography variant={'h6'}>{props.label}</Typography>
            </Grid>
            {
                status && !isSigned(status)
                    ? <Grid item>
                        <Typography>Document is not signed</Typography>
                    </Grid>
                    : null
            }
            <Grid item>
                <CustomFileFieldWithUrl {...{
                    fileUrl: service.documentURLFactory({ documentId: props && props.value && props.value.documentId || ''}),
                    fileName: 'signed-document',
                    label: 'Signed Document'
                }}/>
            </Grid>
        </Grid>
    );
}

const AdobeSignSignature = (props: AdobeSignComponentProps) => {
    const [ status, setStatus ] = useState<SignatureStatus>();
    const [ signedPollTimeout, setSignedPollTimeout ] = useState();

    const isReadyToFetchSignUrl = (status?: SignatureStatus): boolean => {
        return status === 'OUT_FOR_SIGNATURE';
    };

    const isSigned = (status?: SignatureStatus): boolean => {
        return status === 'SIGNED';
    };

    const fetchStatus = async (documentId: string) => {
        try {
            const result = await service.pollSigningStatus({ documentId });
            setStatus(result.status);
        }
        catch (e) {
            console.error(e);
            //TODO show error
        }
    };

    useEffect(() => {
        fetchStatus(props.value.documentId);
    }, []);

    useEffect(() => {
        if(!isSigned(status)) {
            if(!signedPollTimeout) {
                const timeout = setTimeout(async () => {
                    await fetchStatus(props.value.documentId);
                    setSignedPollTimeout(undefined);
                }, 3000);
                setSignedPollTimeout(timeout as any);
            }
        }
        else {
            console.debug('submit value and continue');
            props.valueChangeCbk(props.id, props.value, true);
        }
    }, [signedPollTimeout]);

    return (
        <Grid container direction={'column'} wrap={'nowrap'} spacing={2}>
            <Grid item>
                <Typography variant={'h6'}>{props.label}</Typography>
            </Grid>
            {
                isReadyToFetchSignUrl(status)
                    ? <Grid item>
                        <iframe src={service.getSignUrl({ documentId: props.value.documentId })}
                                width="930"
                                height="750"/>
                    </Grid>
                    : <Grid>
                        <Typography>loading..</Typography>
                    </Grid>
            }
        </Grid>
    );
}

export const AdobeSignComponent = (props) => {
    console.log(props);
    const value = validateValue(props.value);

    if (value) {
        return (
            isReadOnly(props.validation)
                ? <AdobeSignReadOnly {...props}/>
                : <AdobeSignSignature {...props}/>
        );
    }
    else {
        return (
            <div>
                field configuration not setup properly.
            </div>
        );
    }
};