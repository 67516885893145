import {RepeatableGridItem} from "../generic_component";
import * as React from "react";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormHelperText,
    Grid,
    IconButton,
    withStyles
} from "@material-ui/core";
import GenericFieldComponent from "./GenericFieldComponent";
import {GroupComponentBase} from "sancus-client-common/dist/common/GroupBase";
import FieldCheckAvatar from "../FieldCheckAvatar";
import Typography from "@material-ui/core/Typography";
import {readOnlyStyleFactory} from "./GenericFieldComponentSimple";
import {element, WithDrag} from "../../designer_utils";
import {useDispatch, useSelector} from "react-redux";
import {useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import DeleteIcon from "@material-ui/icons/Delete";
import {getStore} from "sancus-client-common/dist/store";
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import {makeStyles} from "@material-ui/core/styles";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";
import {DialogData} from "sancus-client-common/dist/common/FormContainerBase"
import {useFormContext} from "react-hook-form";
import * as _ from "lodash";
import {useFormContainerContext} from "sancus-client-common/dist/common/FormContainerContext";
import {IndexDecoratorFormValidationUtilFactory} from "sancus-client-common/dist/utils/formValidation.util";

const groupClasses = makeStyles((theme) => ({
    designerGroup: {border: "1px dashed grey", position: 'relative'},
    designerGroupInDialog: {border: "1px dashed lightblue", borderRadius: 5, position: 'relative'},
}));


const DesignerGroupElement = ({children, groupId, groupType}) => {
    const designerActions = useDesignerActions(useDispatch());
    const [deleteIsShown, setDeleteIsShown] = React.useState(false);
    const [isDragOver, setIsDragOver] = React.useState(false);
    const myClasses = groupClasses();
    const readOnlyStyles = makeStyles(readOnlyStyleFactory)();

    //console.log('groupId:', groupId ,' groupType:', groupType);
    const selectedItem = useSelector<RootState,RootState['designer']['selectedItem']>(state=>state.designer?.selectedItem);
    const groupIsSelectedClass = selectedItem && selectedItem.id ===  groupId && selectedItem.type ===  groupType && readOnlyStyles.designerFieldSelected || " ";

    return <WithDrag dragProps={{}} type={element}>
        {(drag, isDragging) => <div
            ref={drag}
            /*onFocusCapture={evt => {
                evt.preventDefault();
                designerActions.selectField({fieldId: fv_value.id})
            }}
            onClickCapture={evt => {
                designerActions.selectField({fieldId: fv_value.id})
            }}*/

            onMouseEnter={() => setDeleteIsShown(true)}
            onMouseLeave={() => setDeleteIsShown(false)}

            onDragOver={(e) => {
                setIsDragOver(true);

                e.stopPropagation();
                e.preventDefault();
            }}
            onDragLeave={(e) => {
                setIsDragOver(false);

                e.stopPropagation();
                e.preventDefault();
            }}
            onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
                designerActions.onDropInPlace({type: groupType, groupId: groupId});
            }}
            onFocusCapture={(evt)=>{
                console.log('event Group onFocusCapture', evt.target);
                if (evt.target && evt.target['dataset'] && evt.target['dataset'].button) {
                    // skip next actions as manage(delete) button clicked
                } else {
                    evt.preventDefault();
                    designerActions.selectItem({type: groupType, id: groupId})
                }
            }}
            onClick={(evt) => designerActions.selectItem({type: groupType, id: groupId})}

            onDragStart={() => designerActions.onDragStart({type: groupType, groupId: groupId})}
            className={
                (groupId.includes("group_in_dialog_") ? myClasses.designerGroupInDialog : myClasses.designerGroup) + " " + (isDragOver && readOnlyStyles.designerIsDragOverGroup || " ")
                + " " + (!isDragOver && groupIsSelectedClass)}
        >
            <Typography variant="caption" display="block" gutterBottom style={{paddingRight: 5}} align="right">{groupId}</Typography>

            {children}

            {deleteIsShown && <div style={{position: 'absolute', bottom: '0px', right: '0px'}}>
                <IconButton data-button="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                designerActions.removeField({type: groupType, groupId: groupId})
                            }}>
                    <DeleteIcon fontSize="large" color="primary"/>
                </IconButton>
            </div>}

        </div>
        }
    </WithDrag>
}


const GroupInDialogElement = ({children, groupName}) => {
    const [dialogData, setDialogData] = useState<null | DialogData>(null);
    const designMode = useContext(DesignModeContext);
    const formContainerContext = useFormContainerContext();
    const methods = useFormContext();
    const { control, errors, clearErrors } = methods;


    useEffect(() => {
        const onReduxStoreDataChange = () => {
            const shownFormDialog = getStore().getState().shownFormDialog;

            if (!dialogData && !shownFormDialog) {
                setDialogData(null);
            }

            if (!dialogData && shownFormDialog && shownFormDialog!.name === groupName) {
                setDialogData(shownFormDialog);
            }

            if (dialogData && shownFormDialog && dialogData!.name !== shownFormDialog!.name) {
                setDialogData(null);
            }

            if (dialogData && !shownFormDialog) {
                setDialogData(null);
            }
        };

        // Redux store
        const store = getStore();
        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);

        // code here work like in componentWillUnmount()
        return () => unsubscribeStore();
    }, []);


    if (!designMode) {
        return <Dialog key={"modalContainer" + groupName}
                      open={!!dialogData}
            onClose={() => {
                //Check for validation errors
                const errorsNotReadOnly = Object.keys(errors).filter(key => {

                    let field = formContainerContext.formReference.find(f => f.id === key);
                    if (!field && IndexDecoratorFormValidationUtilFactory.isGroupField(key)){
                        const fieldId = IndexDecoratorFormValidationUtilFactory.getFieldId(key);
                        field = formContainerContext.formReference.find(field => field.id === fieldId);
                    }
                    return field && !field.validationConstraints.some(vc => vc.name === 'readonly');
                });

                if (_.isEmpty(errorsNotReadOnly)) {
                    getStore().dispatch({
                        type: 'ACTIVE_DIALOG',
                        payload: null,
                    });
                }
            }}>


            {dialogData && dialogData.label &&
                <DialogTitle>
                    <Typography variant="h6">{dialogData.label}</Typography>
                </DialogTitle>
            }

            <DialogContent>
                {dialogData && dialogData.description && <Typography variant="subtitle1">{dialogData.description}</Typography>}
                <Divider/>
                {children}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => {
                    console.log(`Close clicked`);

                    getStore().dispatch({
                        type: 'ACTIVE_DIALOG',
                        payload: null,
                    });


                }} variant="contained" size="large" key={"btn_close"}>Close
                </Button>

                {(dialogData?.actions || [{label:'Ok'}]).map(action=>{
                    const actionValue = typeof action === 'string' ?action:action.value
                    const actionLabel = typeof action === 'string' ?action:action.label || action.value
                    return <Button onClick={() => {
                                        console.log(`${actionValue} clicked`);
                                        const _methods = methods;
                                        if (_.isEmpty(errors)) {
                                            getStore().dispatch({
                                                type: 'ACTIVE_DIALOG',
                                                payload: null,
                                            });

                                            dialogData && dialogData.OkHandler && dialogData.OkHandler(actionValue);
                                        }
                                   }}
                                   key={"btn_"+actionValue}
                                   variant="contained"
                                   size="large"
                                   color="primary">{action.label ?? 'Ok'}
                        </Button>
                })}

            </DialogActions>
        </Dialog>
    } else {
        return <>{children}</>
    }
}

class _GroupComponent extends GroupComponentBase {
    constructor(props) {
        super(props,{FieldCheckAvatar,GenericFieldComponent,RepeatableGridItem});
    }

    protected renderDesignMode(): JSX.Element {
        const children = this._render(null)
        /*const dropHandler = function(){
            console.log("In group ",arguments)
        }*/

        //consider group's type: group or repeatable
        const firstFieldTemplate = this.props.template[Object.keys(this.props.template)[0]];
        const groupType = firstFieldTemplate["properties"]['group_repeatable'] !== undefined ?  'group_repeatable' : 'group';

        return <DesignerGroupElement groupId={this.props.name} groupType={groupType}>
            {/*<WithDrop accept={[palette, element]} dropHandler={dropHandler}
                      style={{isOverBorder: '1px chartreuse solid', border: "1px dashed grey"}}>{children}</WithDrop>*/}
            {children}
        </DesignerGroupElement>
    }

    protected renderWithCollapser(content:JSX.Element){



        const collapsed = this.state.collapsed;
        return <Collapser collapsed={collapsed} path={this.props.path} setCollapsed={(c)=>this.setCollapsed(c)}>{content}</Collapser>
    }

    protected renderWithCheckFields(content:JSX.Element[],serviceNotificationTpl?:JSX.Element[]) {
        const readOnlyClasses = this.props['classes'];
        const label = this.props.label
        const helperText = this.props.helperText

        const contentNoData = <Grid container direction="row" justifyContent="space-between" alignItems="flex-start"
                                    style={{paddingLeft: '16px', paddingRight: "16px"}}>
            <Grid item xs={5}>
                <Typography variant="body2" className={`${readOnlyClasses?.readOnlyLabel}`}>This section does not
                    hold any data</Typography>
            </Grid>
        </Grid>;

        let _content = (content.length == 0 && this.props.readOnly) ? contentNoData : content;
        if (content.length == 0){
            if (this.props.readOnly){
                _content = contentNoData;
            }else{
                _content = content
            }
        }else{
            _content = content;
        }

        let renderedData;

        if (serviceNotificationTpl) {
            renderedData =
                <Grid key={this.props.name} id={this.props.name} className={"group"}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      wrap={"nowrap"} //wrap={"wrap"} will cause entries to spread and occupy blank space
                >
                    {serviceNotificationTpl.length > 0 ?
                        (<Box pb={2} pl={2} pr={2}>
                            <Grid container direction="row" justifyContent="flex-end"
                                  alignItems="center">{serviceNotificationTpl}</Grid>
                        </Box>) : <></>
                    }
                    {_content}
                </Grid>
        } else {
            renderedData =
                <Grid key={this.props.name} id={this.props.name} container direction="column" justifyContent="space-between"
                      alignItems="flex-start">
                    {label}
                    {helperText ?<FormHelperText>
                        <span dangerouslySetInnerHTML={{__html:helperText}}/>
                    </FormHelperText>:null}
                    <Grid container direction="row" justifyContent="space-between"
                          alignItems="flex-start">
                        {_content}
                    </Grid>
                </Grid>
        }

        if (this.props.name.includes("group_in_dialog_")) {
            return <GroupInDialogElement groupName={this.props.name}>{renderedData}</GroupInDialogElement>
        } else {
            return renderedData;
        }
    }
}
const Collapser = ({collapsed,path,setCollapsed,children})=>{

    const refOuter = useRef<HTMLDivElement>(null);
    const refInner = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if (refOuter.current!=null && refInner.current!=null){
            const {height:heightOuter,top:originalTopOuter} = refOuter.current!.getBoundingClientRect();
            const {height:heightInner,top:originalTopInner} =  refInner.current!.getBoundingClientRect();

            const scrollListener = evt=>{
                const scollPixels = evt.target.scrollTop;
                // console.log("Scrolled",scollPixels);

                const topInner = refInner.current!.getBoundingClientRect().top;
                const diff = originalTopInner - topInner;
                if (diff>0){
                    if (diff<heightOuter-heightInner){
                        refInner.current!.style.top = diff + "px";
                    }else{
                        refInner.current!.style.top = (heightOuter-heightInner) + "px";
                    }
                }
            }
            // FIXME: The algorithm needs work
            // document.addEventListener("custom_scroll",scrollListener)
            // return ()=>document.removeEventListener("custom_scroll",scrollListener)
        }
    },[refOuter,refInner])
    return <Grid ref={refOuter} id={"collapser-"+path}
                 key={"collapser-"+path}

                 xs={12}
                 onScroll={evt=>{
                     console.log("Scroll",evt)
                 }}
                 style={{
                     position:'relative',
                     display:"flex"
                 }} >
        <div ref={refInner} key={"collapsed-actions-"+path}
             id={"collapsed-actions-"+path}
             style={{
                 position:'absolute',
                 top:'0px',
                 right:'0px',

             }}>
            <a onClick={evt=>{
                evt.preventDefault();
                setCollapsed(!collapsed)
            }}>{collapsed?'Expand':'Collapse'}</a>
        </div>
        <Grid xs={10}>{children}</Grid>

    </Grid>
}
export const GroupComponent  = withStyles(readOnlyStyleFactory)(_GroupComponent)
