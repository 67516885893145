import * as React from "react";

import {GenericFieldComponentSimple} from "./GenericFieldComponentSimple";
import {Repeatable} from "./Repeatable";
import GenericFieldComponentBase from "sancus-client-common/dist/common/GenericFieldComponentBase";
import {GenericFieldParams} from "sancus-client-common/dist/common/generic_component_base";

export default function GenericFieldComponent(fv_value:GenericFieldParams){
    return (
        <GenericFieldComponentBase
            {...fv_value} renderers={{GenericFieldComponentSimple,Repeatable}}
        />
    );
}