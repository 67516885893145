export default {
    "name": "Review Task",
    "id": "com.norbloc.sancus.integrations.ReviewTask",
    "appliesTo": [
        "bpmn:UserTask"
    ],
    "properties": [
        {
            "label":"Src Definition",
            "type":"String",
            "editable": true,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "all_vars_src",
            }
        },
        {
            "label": "All vars",
            "type": "Hidden",
            "value":   `def all_vars = [
                            mode:'readonly', 
                            no_section:'pepScreenRealtime,onfido_check_result,alerts,checks'
                        ];
                        if (execution.getVariable('all_vars_src')!=null{
                            all_vars.all_vars_src=execution.getVariable('all_vars_src');
                        }
                        return all_vars`,
            "editable": false,
            "binding": {
                "type": "camunda:inputParameter",
                "name": "all_vars",
                "scriptFormat": "groovy"
            }
        }
    ]
}
