import axios from 'axios';
import {contextPath} from "../contextPath";

const groupManagementServiceFactory = () => {
    const client = axios.create({
        baseURL: `${contextPath}api/group-mgmt`,
    });

    return {
        fetchGroups: async () => {
            const result = await client.get('/');

            return result.data;
        },
        createGroup: async ({name, groupId,groupType,attributes}) => {
            const result = await client.post('/', {
                name, groupId,groupType,attributes
            });
            return result.data;
        },
        editGroup: async ({groupId, name,groupType,attributes}) => {
            const result = await client.put(`/${groupId}`, {
                name,groupType,attributes
            });
            return result.data;
        },
        deleteGroup: async ({id, cascade}) => {
            const result = await client.delete(`/${id}`, {
                params: {
                    cascade,
                }
            });
            return result.data;
        }
    };
};

export default groupManagementServiceFactory;
