import * as React from "react";
import { ICheck } from "sancus-client-common/dist/common/FormContainerBase";
import { Grid, Typography, Box, Link } from "@material-ui/core";
import {useStyles} from "./pepComponent";
import {getAvatarDetails} from 'sancus-client-common/dist/common/FieldCheckAvatar'
import * as _ from "lodash"


export const OrbisPep = (props: { check: ICheck }) => {
    const classes = useStyles();
    console.debug('orbis pep props: ', props);
    let {avatar,avatar_alt,service_message,color} = getAvatarDetails(props.check)

    let className = classes[color];

    const { status, message, metaData } = props.check;

    if (message){
        return <Grid className={className}>
            <Typography variant="body2"><div dangerouslySetInnerHTML={{__html: props.check.message}}/></Typography>
        </Grid>
    }

    const query = metaData.query;
    const queryAsString = query.type == 'org'?query.orgName: query.fullName ?? [query.firstName,query.lastName].filter(o=>o!=null).join(' ')

    if (status == 1){
        return <Grid className={className}>
            <Typography variant="body2"><b>{queryAsString}</b> does not Appear in GRID Databases</Typography>
        </Grid>
    }else{
        const grid = metaData['#GRID']
        const {GRID_MATCH_MEDIA_INDICATOR,GRID_MATCH_PEP_INDICATOR,GRID_MATCH_SANCTIONS_INDICATOR,GRID_MATCH_WATCHLIST_INDICATOR} = _.mapValues(grid,(v,k,o)=>{
            if (typeof v === 'string'){
                return v === 'Yes'
            }else{
                return v;
            }
        })


        return <Grid className={className}>
            <Typography variant="body2"><b>{queryAsString}</b> Appears in GRID Database for
                <ul>
                    { GRID_MATCH_MEDIA_INDICATOR && <li>Adverse Media</li> }
                    { GRID_MATCH_PEP_INDICATOR && <li>Politically Exposed Persons (PEP)</li> }
                    { GRID_MATCH_SANCTIONS_INDICATOR && <li>Sanctions</li> }
                    {GRID_MATCH_WATCHLIST_INDICATOR && <li> WathcLists</li> }
                </ul>
            </Typography>
        </Grid>
    }



};