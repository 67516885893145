import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';
import {FormField} from "../../common/camunda_helper";
import {RootState} from "../reducers/root.state";


export type DesignerElementProps = {
    name: string,
    type: string,
    component?: string,
    defaultProperties?: {[key: string]: any}
    defaultValidationConstraints?: {[key: string]: any}[]
}

export namespace DesignerActions {
    export enum Type {
        REMOVE_FIELD = 'REMOVE_FIELD',
        CHANGE_FIELD_IDX='CHANGE_FIELD_IDX',
        CHANGE_GROUP_ID='CHANGE_GROUP_ID',
        SELECT_FIELD='SELECT_FIELD',
        SELECT_ITEM = 'SELECT_ITEM', // can be used for select group

        DRAG_START='DRAG_START',
        DROP_IN_PLACE='DROP_IN_PLACE',

        CHANGE_FORM_PROP = 'CHANGE_FORM_PROP',
        CHANGE_FIELD_PROP = 'CHANGE_FIELD_PROP',

        RESET='RESET'
    }
    export const removeField = createAction<{idx?:number,fieldId:string}>(Type.REMOVE_FIELD);
    export const changeFieldIdx = createAction<{newIdx:number,oldIdx:number}>(Type.CHANGE_FIELD_IDX)
    export const changeGroupId = createAction<{newId:number | string,oldId:number | string, type: string}>(Type.CHANGE_GROUP_ID)
    export const selectField = createAction<{idx?:number,fieldId:string}>(Type.SELECT_FIELD)
    export const selectItem = createAction<{ id?: number | string, type: string, extraData?: any }>(Type.SELECT_ITEM)

    export const onDragStart = createAction<{ type?: string, idx?: number, fieldId: string, groupId?: string }>(Type.DRAG_START)
    export const onDropInPlace = createAction<{type?: string, fieldId?:string}>(Type.DROP_IN_PLACE)

    export const changeFormProp = createAction<Partial<Omit<RootState['designer'],'formFields'>>>(Type.CHANGE_FORM_PROP)
    export const changeFieldProp = createAction<{idx:number,field:Partial<FormField>}>(Type.CHANGE_FIELD_PROP);

    export const reset = createAction<RootState['designer'] | void>(Type.RESET)
}

export type DesignerActions = Omit<typeof DesignerActions, 'Type'>;
export const useDesignerActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = DesignerActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as DesignerActions;
};
