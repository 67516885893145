import {getProcessVariables} from "@bpmn-io/extract-process-variables";

export function getAllProcessVariables() {
    const _bpmnViewer = window.my_bpmnViewer;
    const canvas = _bpmnViewer.get('canvas');
    const rootElement = canvas.getRootElement();
    const allVariables = getProcessVariables(rootElement.businessObject);

    return allVariables;
}

export function getAllElements() {
    const _bpmnViewer = window.my_bpmnViewer;
    const elements = _bpmnViewer.get('elementRegistry').filter(() => true);

    console.log('getAllElements: ', elements);
    return elements;
}

export function getAllSancusServicesElements() {
    const _bpmnViewer = window.my_bpmnViewer;
    const elementRegistry = _bpmnViewer.get('elementRegistry');
    const sancusElements = elementRegistry.filter((function (element) {
        return element.sancusService;
    }));

    console.log('getAllSancusServicesElements sancusElements: ', sancusElements);
    return sancusElements;
}

function is(element, type) {
    return (
        element &&
        typeof element.$instanceOf === 'function' &&
        element.$instanceOf(type)
    );
}

function getExtensionElements(element, type) {
    let elements = [];
    const extensionElements = element.get('extensionElements');

    if (typeof extensionElements !== 'undefined') {
        const extensionValues = extensionElements.get('values');

        if (typeof extensionValues !== 'undefined') {
            elements = extensionValues.filter(value => {
                return is(value, type);
            });
        }
    }

    return elements;
}

function getExtensionElementsInputVariable(element) {
    let elements = [];
    const inputParameters = [];
    const extensionElements = element.get('extensionElements');

    if (typeof extensionElements !== 'undefined') {
        const extensionValues = extensionElements.get('values');

        if (typeof extensionValues !== 'undefined') {
            elements = extensionValues.filter(value => {
                return is(value, "camunda:InputOutput");
            });

            elements.forEach(el=>{
                inputParameters.push(...el.get("inputParameters"));
            })
        }
    }

    return inputParameters;
}

export function validateSancusElements() {
    const sancusElements = getAllSancusServicesElements();

    if (!sancusElements.length) {
        return;
    }

    const allOutVariables = getAllProcessVariables();
    console.log('allOutVariables: ', allOutVariables);

    sancusElements.forEach((element) => {
        const uniqueInVariablesNames = getElementInVariablesNames(element);
        const missedVariablesNames = [];

        uniqueInVariablesNames.forEach((inVariableName) => {
            const existsInOut = allOutVariables.findIndex((el) => el.name === inVariableName) > -1

            if (!existsInOut) {
                missedVariablesNames.push(inVariableName);
            }
        });

        const modeling = window.my_bpmnViewer.get('modeling');

        if (missedVariablesNames.length) {
            console.log('Invalid element', element, ' has missed', missedVariablesNames);
            if (!element.isInvalid) {
                // setting colors
                element.isInvalid = true;
                modeling.setColor([element], {
                    stroke: 'red',
                    //fill: 'yellow'
                });
            }

            element.isInvalid = true;
        } else {
            if (element.isInvalid) {
                // removing previously set colors
                element.isInvalid = false;
                modeling.setColor([element], null);
            }

            element.isInvalid = false;
        }
    })
}

export function hideDescription(element) {
    if (element && element.sancusService) {
        const overlays = window.my_bpmnViewer.get('overlays');

        overlays.remove({element: element});
    }
}

export function getElementInVariablesNames(element) {
    const uniqueInVariablesNames = {};
    const uniqueInputVariablesNames = {};

    getExtensionElementsInputVariable(element.businessObject).forEach((moddleElement) => {
        if (moddleElement.name) {
            uniqueInputVariablesNames[moddleElement.name] = moddleElement.name;
        }
    });

    getExtensionElements(element.businessObject, 'camunda:In').forEach((moddleElement) => {
        if (moddleElement.source) {
            uniqueInVariablesNames[moddleElement.source] = moddleElement.source;
        }
    });

    console.log(`uniqueInputVariablesNames: `,uniqueInputVariablesNames,` uniqueInVariablesNames: `,uniqueInVariablesNames);

    return Object.keys(uniqueInVariablesNames).filter(inVariable => !uniqueInputVariablesNames[inVariable]);
}

export function validateSancusElementAndShowDescription(element) {
    if (element && element.sancusService) {
        const allOutVariables = getAllProcessVariables();
        const uniqueInVariablesNames = getElementInVariablesNames(element);
        const missedVariablesNames = [];

        console.log('allOutVariables: ', allOutVariables);

       uniqueInVariablesNames.forEach((inVariableName) => {
            const existsInOut = allOutVariables.findIndex((el) => el.name === inVariableName) > -1

           if (!existsInOut) {
                missedVariablesNames.push(inVariableName);
            }
        });

        const overlays = window.my_bpmnViewer.get('overlays');

        //overlays.remove({element: element}); //it can also delete "popup-panel" of active element

        if (missedVariablesNames.length) {
            // attach the overlayHtml to the element node
            overlays.add(element, {
                position: {
                    bottom: -5,
                    left: 0
                },
                html: `<div style="min-width: 100px; background: yellow; color: black; border: 1px solid orange; padding: 0.3em; text-align: center">
            <span>Required variables are missing:</span> 
            <br/><strong>${missedVariablesNames.join(',')}</strong>
            </div>`
            });
        }
    }
}
