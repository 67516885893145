import { handleActions } from 'redux-actions';
import {AuditTasksActions} from '../actions/auditTasks.actions';

/**
 * auditTasks related actions
 */
export const auditTasksReducer = handleActions<any[], any>(
	{
		[AuditTasksActions.Type.SET_TIMELINE_ITEMS]: (state, action) => {
			return action.payload;
		}
	},
	{} as any
);
