import * as React from "react";
import cpp from "sancus-client-common/dist/common/contextPath";
import {CustomComponentsBaseParams} from "sancus-client-common/dist/common/generic_component_base";
import {CustomFileFieldWithUrl} from "./generic_component";
import {DSEmbeddedSignatureCeremonyComponent} from "./react_components";
import { DSFieldValue } from "sancus-client-common/dist/common/react_components_base";

export type ScriveData = {
    userName?:string;
    password?:string;
    documentId:string;
    documentTitle:string;
    status:string;
    scrive_sign_url:string;
}

export type ScriveComponentProps = CustomComponentsBaseParams<ScriveData,'usePopup'>;



const ScriveComponent = (params: ScriveComponentProps) => {

    const {id,valueChangeCbk,value} = params
    let usePopup = (params.usePopup && params.usePopup === 'true') ?? true ;
    if (!value){
        return <div>Scrive component has not been initialized</div>
    }
    const {userName,password,documentId,status,documentTitle,scrive_sign_url} = value!;


    const readOnly = !!params.validation?.readOnly
    if (readOnly){

        let url = `${cpp.contextPath}api/scrive/document/${documentId}/main`
        if (userName!=null){
            url=url+'?userName='+encodeURIComponent(userName);
            if (password !=null){
                url=url+'&password='+encodeURIComponent(password);
            }
        }
        return <CustomFileFieldWithUrl fileUrl={url} fileName={documentTitle || "file_name"} label={"Signed Application"} />
    }else {
        const dsValue:DSFieldValue = {
            viewUrl:scrive_sign_url,
            envelopeId:'n/a',
            signDocumentId:documentId
        }
        return <DSEmbeddedSignatureCeremonyComponent id={id} valueChangeCbk={valueChangeCbk} value={dsValue} usePopup={true}/>
    }
}

export default ScriveComponent;