import {createAction} from 'redux-actions';

export namespace SectionActions {
    export enum Type {
        ACTIVE_SECTION='ACTIVE_SECTION',
        SCROLL_INTO_SECTION = 'SCROLL_INTO_SECTION'
    }

    export const activeSection = createAction<string>(Type.ACTIVE_SECTION);
    export const scrollIntoSection = createAction<string>(Type.SCROLL_INTO_SECTION)
}

export type SectionActions = Omit<typeof SectionActions, 'Type'>;
