import { handleActions } from 'redux-actions';
import {FormContainerActions} from "../actions/formContainer.actions";

export const formContainerValueChangeReducer = handleActions<any, any>(
    {
        [FormContainerActions.Type.TRIGGER_VALUE_CHANGE]: (state, action) => {
            return action.payload;
        },
    },
    {} as any
);
