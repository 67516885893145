import * as React from "react";
import {ICheck} from "sancus-client-common/dist";
import {getWLMatches, SearchResults, getQN, Match} from "./utils";
import {Box, Dialog, DialogContent, DialogTitle, Grid, Link, Typography} from "@material-ui/core";
import {useState} from "react";
import LexisNexisReportComponent from "./LexisNexisReportComponent";
import {useStyles} from "../../Check.component";

const LexisNexisPEPCheck = (props: { check: ICheck }) => {

    const searchResult: SearchResults = props.check.metaData as SearchResults;
    var {query} = searchResult;
    const qn = getQN(query)
    const wlMatches:Match[] = searchResult && getWLMatches(searchResult);

    const classes = useStyles();

    const [selectedHit, setSelectedHit] = useState<number | null>(null)

    if (wlMatches) {
        const statusClassName = classes.yellow;
        return <Grid container direction={'column'} wrap={'nowrap'} spacing={1} className={statusClassName}>
            <Grid item>
                <Typography variant={"body2"}>Found {wlMatches.length} matches for {qn}</Typography>

                {wlMatches.map((wlMatch,idx)=>{
                    return <Box mt={1} key={"lexis_nmexis:" + wlMatch.EntityUniqueID}><Link variant="body2"
                                 style={{color: 'black'}}
                                 href={'#'} onClick={evt => {
                                    evt.preventDefault()
                                    setSelectedHit(idx)
                    }}><b>{wlMatch.EntityName}</b></Link></Box>
                })}

                <Dialog open={selectedHit != null} onClose={() => setSelectedHit(null)} maxWidth={"md"}>
                    <DialogTitle>{selectedHit!= null ? wlMatches[selectedHit]?.EntityName : null}</DialogTitle>
                    <DialogContent>
                        <LexisNexisReportComponent value={searchResult} idx={selectedHit!}/>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    } else {
        const statusClassName = classes.green;
        return <Grid container direction={'column'} wrap={'nowrap'} spacing={1} className={statusClassName}>
            <Grid item><Typography variant={"body2"}>No matches found</Typography>
            </Grid>
        </Grid>
    }
}

export default LexisNexisPEPCheck;