import { useMemo } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { createAction } from 'redux-actions';
import {FormField} from "../../common/camunda_helper";
import {RootState} from "../reducers/root.state";


export namespace AnnotatorActions {
    export enum Type {
        INIT_ANNOTATIONS = 'INIT_ANNOTATIONS',
        SHOW_ANNOTATOR_POPUP = 'SHOW_ANNOTATOR_POPUP',
        CLOSE_ANNOTATOR_POPUP = 'CLOSE_ANNOTATOR_POPUP',
        CLEAR_ANNOTATION = 'CLEAR_ANNOTATION',
    }
    export const initAnnotations = createAction<{
        annotations:RootState['annotator']['annotations']}>(Type.INIT_ANNOTATIONS);
    export const showAnnotatorPopup = createAction<{fieldId:string,mode:string}>(Type.SHOW_ANNOTATOR_POPUP);
    export const closeAnnotatorPopup = createAction<{fieldId:string,value:string,mode:string}>(Type.CLOSE_ANNOTATOR_POPUP)
    export const clearAnnotation = createAction<{fieldId:string,mode:string}>(Type.CLEAR_ANNOTATION)

}

export type AnnotatorActions = Omit<typeof AnnotatorActions, 'Type'>;
export const useAnnotatorActions = (dispatch: Dispatch) => {
    const { Type, ...actions } = AnnotatorActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as AnnotatorActions;
};
