import { Store, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { rootReducer } from './reducers/root.reducer';
import {RootState} from "./reducers/root.state";
let store;
/**
 * store initialization
 * @param initialState
 */

const defaultInitialState:RootState = {
	checks: [] ,
	checkFilter: 0,
	selectedCheck: null,

	error: null,
	validationError: null,
	inProgress: false,
	valueChanged: null,

	auditTasks: null,
	auditTaskForReview: null,
	userSessionInfo: null,

	section: {activeSections:{}},
	designer :{},
	shownFormDialog: null,
	annotator:{
		annotations:{},
		edits:{} ,
		annotatorPopup:null
	},showRepeatableDialog:{
		repeatableId:'',
		idx:0
	}
}

function configureStore(initialState: RootState): Store<RootState> {
	return createStore(rootReducer as any, initialState as any, devToolsEnhancer({name: 'checksStore'})) as Store<RootState>;
}

export const getStore = (initialState?:Partial<RootState>) => {

	const state = Object.assign(defaultInitialState,initialState ?? {})

	if (!store){
		store = configureStore(state);
		return store;
	}else{
		return store;
	}
};

