import {FormBuilderBase, FormBuilderProps} from "sancus-client-common/dist/common/FormBuilderBase";
import {FormContainer} from "./FormContainer";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {ServerStyleSheets, ThemeOptions} from "@material-ui/core/styles";
import ThemeWrapper from "./ThemeWrapper";
import {renderToStaticMarkup} from 'react-dom/server'
import {Box, Button, Divider, FormControlLabel, Grid, Switch, TextField, Typography, Paper, CardContent, Card, CardHeader, Radio, Checkbox, Toolbar, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, FormControl, InputLabel, ListSubheader} from "@material-ui/core";
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {getStore} from "sancus-client-common/dist/store";
import {Provider as _ReduxProvider, useDispatch, useSelector} from "react-redux";
import {RootState} from "sancus-client-common/dist/store/reducers/root.state";
import {DesignerElementProps, useDesignerActions} from "sancus-client-common/dist/store/actions/designer.actions";
import {palette,element, WithDrag, WithDrop} from "./designer_utils";
import {
    createFieldFromPaletteElement,
    FormField,
    FormPropertyValidationConstraint,
    FormPropertyValidationConstraintName
} from "sancus-client-common/dist/common/camunda_helper";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import {IActionJson} from "sancus-client-common/dist";
import * as _ from 'lodash';
import {Autocomplete} from "@material-ui/lab";
import SelectOptionsEditor, {SelectorOptionType} from "./components/formDesigner/SelectOptionsEditor";

export {FormBuilderProps} from "sancus-client-common/dist/common/FormBuilderBase";

export enum themeSide {
    en = "ltr",
    ar = "rtl",
    el = "ltr",
}

const PaletteElementDictionaryObject = {
    "Text": {name: "Text", type: "string"},
    "Date": {name: "Date", type: "date"},
    "Number": {name: "Number", type: "long"},
    "CheckBox": {name: "CheckBox", type: "boolean"},
    "File": {name: "File", type: "file"},
    "Selection": {name: "Selection", type: "string", component: "OptionSelector"},
    "Phone": {name: "Phone", type: "string", component: "CountryDialPhoneNumber"},
    "Header": {name: "Header", type: "string", component: "Header"},
    "Actions for Repeatable": {
        name: "Actions for Repeatable", type: "string", component: "Actions",
        defaultValidationConstraints: [{name: "readonly"}],
        defaultProperties: {
            selectOutputFieldName: "select_customer",
            setSelectValueContextEval: "`${categoryId}/${category}/${countryIso2}`",
            actionsConfig: `[{"id": "renew", "label": "Renew" }, {"id": "revoke", "label": "Revoke" }]`,
        }
    },
    "Group": {name: "Group", type: "group"},
    "Repeatable Group": {name: "Repeatable Group", type: "group_repeatable"},
    "Group in Dialog": {name: "Group in Dialog", type: "group_in_dialog"}
};
const PaletteElementDictionary= Object.keys(PaletteElementDictionaryObject).map(k=>PaletteElementDictionaryObject[k]);

export const FormBuilder = (props:FormBuilderProps) => {
    return <FormBuilderBase {...props} FormContainer={FormContainer} />
}


const PaletteElement = (props:DesignerElementProps)=>{
    const designerActions = useDesignerActions(useDispatch())

    return <WithDrag dragProps={props} type={palette}>
        {(drag,isDragging)=><Grid item>
            <Button ref={drag}
                    onDragStart={() => designerActions.onDragStart({type: props.type, component: props.component, defaultProperties: props.defaultProperties, defaultValidationConstraints: props.defaultValidationConstraints})}
                    style={{opacity:isDragging ?0.5:1 }}>{props.name}</Button>
        </Grid>}
    </WithDrag>
}

const Palette = (props)=>{
    const designMode = useContext(DesignModeContext)
    const designerActions = useDesignerActions(useDispatch())
    return <WithDrop
            accept={element}
            dropHandler={(dropProps:{id:string},monitor)=>{
                designerActions.removeField({fieldId:dropProps.id})
            }}>
        <Grid container direction={"column"}>
        <FormControlLabel
            control={<Switch checked={designMode} onChange={
                evt=>props.toggleDesignMode(evt.target.checked)
            }/>}
            label={"Design Mode"}
        />

            <Divider/>
            <PaletteElement {...PaletteElementDictionaryObject["Text"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Date"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Number"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["CheckBox"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["File"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Selection"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Phone"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Header"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Actions for Repeatable"]}/>
            <Divider/>
            <PaletteElement {...PaletteElementDictionaryObject["Group"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Repeatable Group"]}/>
            <PaletteElement {...PaletteElementDictionaryObject["Group in Dialog"]}/>
    </Grid></WithDrop>
}

type ThemedFormBuilderProps = {theme_data:ThemeOptions} & FormBuilderProps

interface ManageOptionSelectorOptionsProps {
    field: FormField;
}

const isStringField = (formField: FormField): boolean => {
    return !isCustomComponentField(formField) && formField && formField.type && formField.type.name === 'string' ? true : false;
};

const isNumberField = (formField: FormField): boolean => {
    return !isCustomComponentField(formField) && formField && formField.type && formField.type.name === 'long' ? true : false;
};

const isCustomComponentField = (formField: FormField): boolean => {
    return formField && typeof formField.properties === 'object' && typeof formField.properties.component === 'string';
};

const fieldsTypesAllowedForChange = ["Text", "Date", "Number", "CheckBox", "File", "Selection", "Phone"];
const PaletteElementDictionaryAllowedForChange = PaletteElementDictionary.filter(e => fieldsTypesAllowedForChange.indexOf(e.name) > -1);
const specialComponentsList = ['Header', 'Actions'];
const selectorComponentsList = ['OptionSelector', 'MultiSelector', 'RadioSelector', 'YesNo', 'CountrySelector', 'CurrencySelector', 'MultiCountrySelector', 'MultiCurrencySelector'];
const notConfigurableComponentsList = ['YesNo', 'CountrySelector', 'CurrencySelector', 'MultiCountrySelector', 'MultiCurrencySelector'];
const componentsDescription = {
    "Header": `This is just a label with underline, can be used as header of section`,
    "Actions": `This is "actions buttons" for "Repeatable Groups", to handle some row with some action`,
    "YesNo": `This is "yes/no" selector, values are "1", "0"`,
    "CountrySelector": `This is Country selector, labels are country names and values are ISO2 strings like "SE"`,
    "CurrencySelector": `This is Currency selector, labels are currency names like "Swedish Krona" and values are ISO 4217 strings like "SEK"`,
    "MultiCountrySelector": `This is Multi Country selector, labels are country names and values are ISO2 strings like "SE". Stored value will be an array of values like: "["SE","GR"]"`,
    "MultiCurrencySelector": `This is Multi Currency selector, labels are currency names like "Swedish Krona" and values are ISO 4217 strings like "SEK". Stored value will be an array of values like: "["SEK", "EUR"]"`,
}

const isOptionSelectorField = (formField: FormField): boolean => {
    return isCustomComponentField(formField) && formField.properties.component === 'OptionSelector';
};

const isSelectorField = (formField: FormField): boolean => {
    return isCustomComponentField(formField) && !!formField.properties.component && selectorComponentsList.indexOf(formField.properties.component)> -1;
};

const isSpecialComponentField = (formField: FormField): boolean => {
    return isCustomComponentField(formField) && !!formField.properties.component && specialComponentsList.indexOf(formField.properties.component)> -1;
};

const isSimpleOptionsType = (field: FormField): boolean => {
    const optionsStr = field && field.properties && typeof field.properties.choices_str === 'string' && field.properties.choices_str || '';
    try {
        const opts = JSON.parse(optionsStr);
        return Array.isArray(opts) && opts.every((opt: SelectorOptionType) => typeof opt === 'string' || typeof opt.label === 'string' && typeof opt.value === 'string')
            ? true
            : false;
    }
    catch (e) {
        return false;
    }
};

const isDynamicOptionsType = (field: FormField): boolean => {
    const optionsStr = field && field.properties && typeof field.properties.choices_str === 'string' && field.properties.choices_str || '';
    try {
        const opts = JSON.parse(optionsStr);
        return typeof opts === 'object' && opts.dynamic_choices && typeof opts.dynamic_choices === 'string'
            ? true
            : false;
    } catch (e) {
        return false;
    }
};

const isConditionalOptionsType = (field: FormField): boolean => {
    const optionsStr = field && field.properties && typeof field.properties.conditional_choices === 'string' && field.properties.conditional_choices || '';
    try {
        const opts = JSON.parse(optionsStr);
        if (Array.isArray(opts) && opts.every((opt: ConditionalOptions) => typeof opt.condition === 'string' && Array.isArray(opt.choices) && opt.choices.every(c => typeof c === 'string' || typeof c.value === 'string'))) {
            return true;
        } else {
            console.warn(`invalid conditional_choices property: ${optionsStr}`);
            return false;
        }
    }
    catch (e) {
        console.warn(`invalid conditional_choices property: ${optionsStr}`);
        return false;
    }
}

const isInvalidJSONOptionsType  = (field: FormField): boolean => {
    const choices_str = field?.properties?.choices_str;
    if (typeof choices_str === 'string'){
        try{
            JSON.parse(choices_str);
            return false;
        }catch (e){
            return true;
        }
    }else{
        return false;
    }
}


const parseSimpleOptions = (field: FormField): SelectorOptionType[] => {
    const optionsStr = field && field.properties && typeof field.properties.choices_str === 'string' && field.properties.choices_str || '';
    try {
        let opts = JSON.parse(optionsStr);
        if (Array.isArray(opts) && opts.every((opt: SelectorOptionType) => typeof opt === 'string' || typeof opt.label === 'string' && typeof opt.value === 'string')) {
            // apply rules:
            // 1. if value empty and label empty -> we store just string ""
            // 2. if value and no label -> we store just string "value"

            opts = opts.map(opt => {
                if (typeof opt === 'string'){
                    return opt;
                }

                if (!opt.value && !opt.label){
                    return "";
                }

                if (opt.value && !opt.label){
                    return opt.value;
                }

                return opt;
            })

            return opts;
        }
        else {
            console.warn(`invalid choices_str property: ${optionsStr}`);
            return [];
        }
    }
    catch (e) {
        console.warn(`invalid choices_str property: ${optionsStr}`);
        return [];
    }
};

const parseDynamicOptions = (field: FormField): string => {
    const optionsStr = field && field.properties && typeof field.properties.choices_str === 'string' && field.properties.choices_str || '';
    try {
        const opts = JSON.parse(optionsStr);

        if(typeof opts === 'object' && typeof opts.dynamic_choices === 'string') {
            return opts.dynamic_choices as string;
        }
        else {
            console.warn(`invalid choices_str property: ${optionsStr}`);
            return '';
        }
    }
    catch (e) {
        console.warn(`invalid choices_str property: ${optionsStr}`);
        return '';
    }
};

interface ManageOptionSelectorOptions {
    field: FormField;
}

const ManageOptionSelectorOptions = (props: ManageOptionSelectorOptions) => {
    const designerActions = useDesignerActions(useDispatch());
    const [ selectedOptionsType, setSelectedOptionsType ] = useState<number>(((): number => {
        if (isInvalidJSONOptionsType(props.field)) {
            return 100;
        }
        if(isDynamicOptionsType(props.field)) {
            return 3;
        }
        else if(isSimpleOptionsType(props.field)) {
            return 1;
        }
        else if(isConditionalOptionsType(props.field)) {
            return 2;
        }
        else {
            return 0;
        }
    })());
    const [selectedComponentType, setSelectedComponentType] = useState<string>(props.field.properties.component || "");
    const prevSelectedOptionsTypeRef = useRef<number>();
    const prevSelectedComponentTypeRef = useRef<string>();


    const handleSelectedTypeChange = (event) => {
        const value = event.target.value;

        if(typeof value === 'number') {
            setSelectedOptionsType(value);
        }
    };

    const handleComponentTypeChange = (event) => {
        const value = event.target.value;
        setSelectedComponentType(value);
    };

    useEffect(() => {
        const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};

        // avoid deleting properties on first render
        if (prevSelectedOptionsTypeRef.current === undefined) {
            prevSelectedOptionsTypeRef.current = selectedOptionsType;
            return;
        }

        if (selectedOptionsType === 1 || selectedOptionsType === 3) {
            delete oldFieldProps['conditional_choices'];
            delete oldFieldProps['choices_str'];

            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                }
            });
        } else if (selectedOptionsType === 2) {
            delete oldFieldProps['choices_str'];

            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                }
            });
        }

        prevSelectedOptionsTypeRef.current = selectedOptionsType;
    }, [selectedOptionsType]);

    useEffect(() => {
        const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};

        // avoid to triger field's change on first render
        if (prevSelectedComponentTypeRef.current === undefined) {
            prevSelectedComponentTypeRef.current = selectedComponentType;
            return;
        }

        if(notConfigurableComponentsList.indexOf(selectedComponentType) >-1 ) {
            delete oldFieldProps['conditional_choices'];
            delete oldFieldProps['choices_str'];

            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                    component: selectedComponentType
                }
            });
        }
        else {
            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                    component: selectedComponentType
                }
            });
        }
    }, [selectedComponentType]);

    useEffect(() => {
        //dynamic_choices
        if(isDynamicOptionsType(props.field)) {
            setSelectedOptionsType(3);
        } else if(isSimpleOptionsType(props.field)) {
            setSelectedOptionsType(1);
        } else if(isConditionalOptionsType(props.field)) {
            setSelectedOptionsType(2);
        }else if (isInvalidJSONOptionsType(props.field)){
            setSelectedOptionsType(100);
        }
    }, [props.field]);

    return (
        <Card>
            <CardHeader title={'Selector options'}/>
            <CardContent>
                <Grid container direction={'column'}>
                    <Grid item alignItems="stretch">
                        <FormControl style={{width: "100%"}}>
                            <InputLabel>Selector Type</InputLabel>
                            <Select value={selectedComponentType} onChange={handleComponentTypeChange}>
                                {selectorComponentsList.map((componentName) =>
                                    < MenuItem value={componentName}>{componentName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    {
                        //description for not configurable elements
                        componentsDescription[selectedComponentType] &&
                        <Grid item>
                            <Typography variant={'h6'}>Component Description</Typography>
                            <Typography variant={'subtitle2'}>{componentsDescription[selectedComponentType]}</Typography>
                        </Grid>
                    }

                    {
                        //option's type can be seletec for
                        notConfigurableComponentsList.indexOf(selectedComponentType) < 0 && selectedOptionsType != 100 &&
                        <Grid item>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel>Options Type</InputLabel>
                                <Select value={selectedOptionsType} onChange={handleSelectedTypeChange}>
                                    <MenuItem value={0}></MenuItem>
                                    <MenuItem value={1}>Simple options</MenuItem>
                                    <MenuItem value={2}>Conditional options</MenuItem>
                                    <MenuItem value={3}>Dynamic options</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    {
                        //simple choices
                        notConfigurableComponentsList.indexOf(selectedComponentType) < 0 && selectedOptionsType === 1 &&
                        <Grid item>
                            <ManageSimpleOptionSelectorOptions field={props.field}/>
                        </Grid>
                    }
                    {
                        //conditional choices
                        notConfigurableComponentsList.indexOf(selectedComponentType) < 0 && selectedOptionsType === 2 &&
                        <Grid item>
                            <ManageConditionalOptionSelectorOptions field={props.field}/>
                        </Grid>
                    }
                    {
                        //dynamic choices
                        notConfigurableComponentsList.indexOf(selectedComponentType) < 0 && selectedOptionsType === 3 &&
                        <Grid item>
                            <ManageDynamicOptionSelectorOptions field={props.field}/>
                        </Grid>
                    }

                    {
                        //dynamic choices
                        notConfigurableComponentsList.indexOf(selectedComponentType) < 0 && selectedOptionsType === 100 &&
                        <Grid item>
                            <ManageInvalidJSONOptionSelectorOptions field={props.field}/>
                        </Grid>
                    }

                </Grid>
            </CardContent>
        </Card>
    );
}

const ManageSpecialComponentsOptions = (props: ManageOptionSelectorOptions) => {
    const designerActions = useDesignerActions(useDispatch());
    const [ selectedComponentType, setSelectedComponentTypeType ] = useState<string>(props.field.properties.component || "");

    const prevSelectedComponentTypeRef = useRef<string>();

    const handleComponentTypeChange = (event) => {
        const value = event.target.value;
        setSelectedComponentTypeType(value);
    };


    useEffect(() => {
        const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};

        // avoid to trigger field's change on first render
        if (prevSelectedComponentTypeRef.current === undefined) {
            prevSelectedComponentTypeRef.current = selectedComponentType;
            return;
        }
        designerActions.changeFieldProp({
            idx: (props.field as any).idx,
            properties: {
                ...oldFieldProps,
                component: selectedComponentType
            }
        });
    }, [selectedComponentType]);

    return (
        <Card>
            {/*<CardHeader title={'Component options'}/>*/}
            <CardContent>
                <Grid container direction={'column'}>
                    {
                        // TODO maybe it will be better to not allow change component type because of properties handling
                        /*<Grid item alignItems="stretch">
                            <FormControl style={{width: "100%"}}>
                            <InputLabel>Component Type</InputLabel>
                            <Select value={selectedComponentType} onChange={handleComponentTypeChange}>
                                {specialComponentsList.map((componentName) =>
                                    < MenuItem value={componentName}>{componentName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>*/
                    }

                    {
                        //description for not configurable elements
                        componentsDescription[selectedComponentType] &&
                        <Grid item>
                            <Typography variant={'h6'}>Component Description</Typography>
                            <Typography variant={'subtitle2'}>{componentsDescription[selectedComponentType]}</Typography>
                        </Grid>
                    }
                    {selectedComponentType === 'Actions' &&
                        <Typography variant="caption" display="block" gutterBottom>
                            <strong>Three properties are required: </strong><br/>
                            - <strong>selectOutputFieldName - </strong> id of field, which exists outside of <strong>"Repeatable
                            group"</strong> and will keep handled <strong>"row id"</strong><br/>
                            - <strong>setSelectValueContextEval - </strong> this is <strong>"row id"</strong>, or what
                            must be stored as identifier of handled row, some field's id like "categoryId", or string
                            like <strong>"`$&#123; categoryId &#125;/$&#123; category &#125;/$&#123; countryIso2 &#125;`" </strong>
                            which will be "Eval"<br/>
                            - <strong>actionsConfig - </strong> action buttons, which will be shown
                            (example: <strong>[ &#123;"id": "renew", "label": "Renew" &#125;,  &#123;"id": "revoke",
                            "label": "Revoke" &#125;]</strong>)<br/>
                            result of "action" will be submitted in special variable <strong>action</strong>. Example of
                            submit:
                            <strong> action: "renew", select_customer: "DEDDED1113/1/GR" </strong>
                        </Typography>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}

interface ManageDynamicOptionSelectorOptionsProps {
    field: FormField;
}
const ManageInvalidJSONOptionSelectorOptions = (props)=>{
    const choices_str = props.field.properties.choices_str;
    return <TextField defaultValue={choices_str} disabled/>
}
const ManageDynamicOptionSelectorOptions = (props: ManageDynamicOptionSelectorOptionsProps) => {
    const designerActions = useDesignerActions(useDispatch());
    const [ value, setValue ] = useState<string>(parseDynamicOptions(props.field));

    const handleUpdateDynamicOption = () => {
        const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};
        designerActions.changeFieldProp({
            idx: (props.field as any).idx,
            properties: {
                ...oldFieldProps,
                choices_str: JSON.stringify({
                    dynamic_choices: value,
                }),
            }
        });
    };

    return (
        <Card>
            <CardContent>
                <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                        <Toolbar disableGutters={true}>
                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <IconButton onClick={handleUpdateDynamicOption}>
                                        <AddIcon fontSize={'small'}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </Grid>
                    <Grid item>
                        <TextField variant={'outlined'}
                                   label={'value'}
                                   value={value}
                                   required
                                   multiline
                                   fullWidth
                                   rows={5}
                                   onChange={(event) => {
                                       setValue(event.target.value);
                                   }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const ManageSimpleOptionSelectorOptions = (props: ManageOptionSelectorOptionsProps) => {
    const designerActions = useDesignerActions(useDispatch());
    const options: SelectorOptionType[] = parseSimpleOptions(props.field);

    const handleChangeOption = (newOptions) => {
        const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};

        designerActions.changeFieldProp({
            idx: (props.field as any).idx,
            properties: {
                ...oldFieldProps,
                choices_str: JSON.stringify(newOptions)
            }
        });
    };

    return (
        <Card>
            <CardContent>
                <SelectOptionsEditor options={options} onChangeOptions={handleChangeOption}/>
            </CardContent>
        </Card>
    );
}

interface ConditionalOptions {
    condition: string;
    choices: SelectorOptionType[];
}

const parseConditionalOptions = (field: FormField): ConditionalOptions[] => {
    const optionsStr = field && field.properties && typeof field.properties.conditional_choices === 'string' && field.properties.conditional_choices || '';
    try {
        const opts = JSON.parse(optionsStr);
        if(Array.isArray(opts) && opts.every((opt: ConditionalOptions) => typeof opt.condition === 'string' && Array.isArray(opt.choices) && opt.choices.every(c => typeof c === 'string' || typeof c.value === 'string'))) {
            return opts;
        }
        else {
            console.warn(`invalid conditional_choices property: ${optionsStr}`);
            return [];
        }
    }
    catch (e) {
        console.warn(`invalid conditional_choices property: ${optionsStr}`);
        return [];
    }
};

interface ManageConditionalOptionSelectorOptions {
    field: FormField;
}

const ManageConditionalOptionSelectorOptions = (props: ManageConditionalOptionSelectorOptions) => {
    const designerActions = useDesignerActions(useDispatch());
    const [ condition, setCondition ] = useState<string>('');
    const conditionalOptions: ConditionalOptions[] = parseConditionalOptions(props.field);

    const handleConditionChange = (event) => {
        if(event && event.target && typeof event.target.value === 'string') {
            setCondition(event.target.value);
        }
    };

    const clearCondition = () => {
        setCondition('');
    };

    const handleAddCondition = () => {
        if(condition.length > 0){
            const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};
            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                    conditional_choices: JSON.stringify([
                        ...conditionalOptions,
                        {condition, choices: []},
                    ])
                }
            });
        }
        clearCondition();
    };

    const handleDeleteConditionalOptionFactory = (cOpts: ConditionalOptions): (event) => any => {
        return (event) => {
            const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};
            designerActions.changeFieldProp({
                idx: (props.field as any).idx,
                properties: {
                    ...oldFieldProps,
                    conditional_choices: JSON.stringify([
                        ...conditionalOptions.filter(elem => elem !== cOpts),
                    ])
                }
            });
        };
    };

    return (
        <Paper>
            <Grid container direction={'column'}>
                <Grid item>
                    <Toolbar disableGutters={true}>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <IconButton onClick={handleAddCondition}>
                                    <AddIcon fontSize={'small'}/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <TextField variant={'outlined'}
                                           label={'condition'}
                                           required
                                           value={condition}
                                           onChange={handleConditionChange}
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
                {
                    conditionalOptions.length === 0
                        ? <Grid item>
                            <Typography>no conditions</Typography>
                        </Grid>
                        : <Grid container direction={'column'}>
                            {
                                conditionalOptions.map((option: ConditionalOptions, index: number) => {
                                    const ConditionItem = ({option, index}) => {
                                        const handleEditConditionalOptionChoice = (choices) => {
                                            const oldFieldProps = props.field && typeof props.field.properties === 'object' && props.field.properties || {};
                                            designerActions.changeFieldProp({
                                                idx: (props.field as any).idx,
                                                properties: {
                                                    ...oldFieldProps,
                                                    conditional_choices: JSON.stringify([
                                                        ...conditionalOptions.map(elem => {
                                                            return elem === option
                                                                ? {
                                                                    ...elem,
                                                                    choices: choices,
                                                                }
                                                                : elem;
                                                        }),
                                                    ])
                                                }
                                            });
                                        };

                                        return (
                                            <Grid item container key={`${index}-${option.choices.length}`} justifyContent={'flex-start'} alignItems={'center'} direction={'column'} wrap={'nowrap'}>
                                                <Grid item>
                                                    <Typography variant={'subtitle1'}>{option.condition}</Typography>
                                                    <IconButton onClick={handleDeleteConditionalOptionFactory(option)}>
                                                        <DeleteIcon fontSize={'small'}/>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Divider/>
                                                </Grid>

                                                <SelectOptionsEditor options={option.choices} onChangeOptions={handleEditConditionalOptionChoice}/>
                                            </Grid>
                                        );
                                    };

                                    return (
                                        <ConditionItem option={option} index={index}/>
                                    );
                                })
                            }
                        </Grid>
                }
            </Grid>
        </Paper>
    );
}


function determinePaletteFieldType(formField) {
    if (!formField) {
        return null;
    }

    if (isCustomComponentField(formField)) {
        const fieldComponent = formField.properties.component;

        if (selectorComponentsList.indexOf(fieldComponent) > -1) {
            return PaletteElementDictionaryObject['Selection'];
        } else{
            return PaletteElementDictionary.find(f => f.component == fieldComponent);
        }
    } else {
        const fieldSimpleType = formField && formField.type && formField.type.name;

        return PaletteElementDictionary.find(f => f.type == fieldSimpleType && !f.component)
    }
}

const PropertiesPanel = (props)=>{
    const designerActions = useDesignerActions(useDispatch())
    const selectedField  = useSelector<RootState>(state=>state.designer?.selectedField);
    const selectedFieldType = determinePaletteFieldType(selectedField);
    const selectedItem = useSelector<RootState,RootState['designer']['selectedItem']>(state=>state.designer?.selectedItem);
    const [errorFields, SetErrorFields] = useState({});
    const [errorItems, SetErrorItems] = useState({});
    const {allProcessVariablesNames} = props;

    const setErrorToField = ({fieldErrors, id, propertyName, error}) => {
        SetErrorFields({
            ...errorFields, ...{
                ["" + id]: {
                    ...fieldErrors,
                    [propertyName]: error
                }
            }
        });
    }

    const setErrorToItem = ({itemErrors, id, propertyName, error}) => {
        SetErrorItems({
            ...errorItems, ...{
                ["" + id]: {
                    ...itemErrors,
                    [propertyName]: error
                }
            }
        });
    }

    const parseBooleanConstraintValueFactory = (constraintName: FormPropertyValidationConstraintName): (formField: FormField) => boolean => {
        return (formField: FormField): boolean => {
            return !!formField?.validationConstraints?.find((constraint: FormPropertyValidationConstraint) => {
                return constraint.name === constraintName;
            });
        };
    };

    const parseRequiredConstraintValue = parseBooleanConstraintValueFactory('required');
    const parseReadonlyConstraintValue = parseBooleanConstraintValueFactory('readonly');

    const parseNumberConstraintValueFactory = (constraintName: string): (formField: FormField) => string => {
        return (formField: FormField): string => {
            const found = formField?.validationConstraints?.find((constraint: FormPropertyValidationConstraint) => {
                return constraint.name === constraintName;
            });
            if(found) {
                const value = found.configuration;
                return value && isFinite(parseFloat(value))
                    ? value as string
                    : '';
            }
            else {
                return '';
            }
        };
    };

    const parseStringConstraintValueFactory = (constraintName: string): (formField: FormField) => string => {
        return (formField: FormField): string => {
            const found = formField?.validationConstraints?.find((constraint: FormPropertyValidationConstraint) => {
                return constraint.name === constraintName;
            });
            if(found) {
                const value = found.configuration;
                return value as string;
            }
            else {
                return '';
            }
        };
    };

    const parseStringPropertyValueFactory = (propertyName: string): (formField: FormField) => string => {
        return (formField: FormField): string => {
            return typeof formField.properties[propertyName] === 'string' ? formField.properties[propertyName] as string : '';
        };
    };

    const handleParseMinValue = parseNumberConstraintValueFactory('min');
    const handleParseMaxValue = parseNumberConstraintValueFactory('max');
    const handleParseMinLengthValue = parseNumberConstraintValueFactory('minlength');
    const handleParseMaxLengthValue = parseNumberConstraintValueFactory('maxlength');
    const handleParsePatternValue = parseStringConstraintValueFactory('pattern');
    const handleParseVisibleValue = parseStringPropertyValueFactory('visible');
    const handleParseVisualSectionValue = parseStringPropertyValueFactory('visual_section');

    const handleNumberValueConstraintChangeFactory = (idx, selectedField : FormField, constraintName: FormPropertyValidationConstraintName, designerActions): (event) => any => {
        return (event) => {
            if(isFinite(parseFloat(event.target.value))) {
                designerActions.changeFieldProp({
                    idx,
                    validationConstraints: [
                        ...((selectedField as FormField).validationConstraints || [])
                            .filter(constraint => constraint.name !== constraintName),
                        { name: constraintName, configuration: event.target.value },
                    ]
                });
            }
            else {
                designerActions.changeFieldProp({
                    idx,
                    validationConstraints: ((selectedField as FormField).validationConstraints || [])
                        .filter(constraint => constraint.name !== constraintName)
                });
            }
        };
    };

    const handleBooleanValueConstraintChangeFactory = (idx, selectedField : FormField, constraintName: FormPropertyValidationConstraintName, designerActions): (event, checked: boolean) => any => {
        return (event, checked: boolean) => {
            console.log(checked);
            if(checked) {
                designerActions.changeFieldProp({
                    idx,
                    validationConstraints: [
                        ...(selectedField as FormField).validationConstraints || [],
                        { name: constraintName },
                    ]
                });
            }
            else {
                designerActions.changeFieldProp({
                    idx,
                    validationConstraints: ((selectedField as FormField).validationConstraints || [])
                        .filter(constraint => constraint.name !== constraintName)
                });
            }
        };
    };

    const handleStringValuePropertyChangeFactory = (idx, selectedField : FormField, propertyName: string, designerActions): (event) => any => {
        return (event) => {
            const value = event.target.value;

            if(value && typeof value === 'string') {
                designerActions.changeFieldProp({
                    idx,
                    properties: {
                        ...typeof (selectedField as FormField).properties === 'object' && (selectedField as FormField).properties || {},
                        [propertyName]: value,
                    }
                });
            }
            else {
                if(typeof (selectedField as FormField).properties === 'object') {
                    const properties = (selectedField as FormField).properties;
                    delete properties[propertyName];
                    designerActions.changeFieldProp({
                        idx,
                        properties,
                    });
                }
            }
        };
    };

    if (selectedField) {
        //@ts-ignore
        const {idx, id, label, value} = selectedField!;
        const fieldErrors = errorFields[id];

        const handleRequiredChange = handleBooleanValueConstraintChangeFactory(idx, selectedField as FormField, 'required', designerActions);
        const handleReadonlyChange = handleBooleanValueConstraintChangeFactory(idx, selectedField as FormField, 'readonly', designerActions);

        const handleMinValueChange = handleNumberValueConstraintChangeFactory(idx, selectedField as FormField, 'min', designerActions);
        const handleMaxValueChange = handleNumberValueConstraintChangeFactory(idx, selectedField as FormField, 'max', designerActions);
        const handleMinLengthValueChange = handleNumberValueConstraintChangeFactory(idx, selectedField as FormField, 'minlength', designerActions);
        const handleMaxLengthValueChange = handleNumberValueConstraintChangeFactory(idx, selectedField as FormField, 'maxlength', designerActions);

        const handlePatternValueChange = handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'pattern', designerActions);
        const handleVisualSectionValueChange = handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'visual_section', designerActions);
        const handleVisibleValueChange = handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'visible', designerActions);

        const validateParsedActionsList = (parsedData: string | IActionJson): { error?: string | null, validatedValue?: string | IActionJson } => {
            if (parsedData && typeof parsedData === 'string') {
                return {error: null, validatedValue: parsedData};
            }

            if (parsedData && typeof parsedData === 'object') {
                //TODO: Add validation back
            /*if (!(parsedData.label && typeof parsedData.label === 'string')) {
                return {error: 'Not supported object format, "label" is not correct '};
            }

            if (!parsedData.dialog) {
                return {error: 'Not supported object format, "dialog" is not correct '};
            }

            if (!(parsedData.dialog && parsedData.dialog.name && typeof parsedData.dialog.name === 'string')) {
                return {error: 'Not supported object format, "dialog.name" is not correct '};
            }

            if (!(parsedData.dialog && parsedData.dialog.label && typeof parsedData.dialog.label === 'string')) {
                return {error: 'Not supported object format, "dialog.label" is not correct '};
            }*/

                // copy from input object only required properties
                return {
                    error: null,
                    validatedValue:parsedData

                }
            }

            return {error: 'Not supported action format'};
        }

        if (id !== 'actionsList') {
            return <Grid container direction={"column"} key={id}>
                <Grid item>
                    <Autocomplete
                        options={allProcessVariablesNames || []}
                        defaultValue={id}
                        //sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} id="prop_id"  label={`Id`} variant="standard"
                                                                        error={fieldErrors && fieldErrors['prop_id']} helperText={fieldErrors && fieldErrors['prop_id']}
                                                                        onBlur={evt => {
                            if (evt.target.value) {

                                designerActions.changeFieldProp({idx, id: evt.target.value});

                                fieldErrors && fieldErrors['prop_id'] && setErrorToField({
                                    fieldErrors: fieldErrors || {},
                                    id,
                                    propertyName: 'prop_id',
                                    error: undefined,
                                });

                            } else {
                                    setErrorToField({
                                        fieldErrors: fieldErrors || {},
                                        id,
                                        propertyName: 'prop_id',
                                        error: `Can't be empty`
                                    });
                                }
                        }}/>}
                    />


                </Grid>

                <Grid item><TextField id="prop_label" label="Label" variant="standard" defaultValue={label}
                                      onBlur={evt => {
                                          designerActions.changeFieldProp({idx, label: evt.target.value})
                                      }}/></Grid>

                { // selection for change field's type
                    selectedFieldType && selectedFieldType.name && fieldsTypesAllowedForChange.indexOf(selectedFieldType.name) > -1 &&
                    <Grid item alignItems="stretch">
                    <FormControl style={{width: "100%"}}>
                        <InputLabel>Field type</InputLabel>
                        <Select value={selectedFieldType.name} onChange={(evt) => {
                            const selectedPaletteFieldType = PaletteElementDictionaryObject[evt.target.value as string];

                            //here we need merge or remove some previous properties
                            const newField = createFieldFromPaletteElement(`field`, selectedPaletteFieldType);

                            delete newField.id;
                            newField.defaultValue = undefined;

                            const _selectedField =  selectedField as FormField;

                            const fieldBelongsTo = _selectedField && _selectedField.properties && (_selectedField.properties['group'] && 'group' || _selectedField.properties['group_repeatable'] && 'group_repeatable');

                            if (fieldBelongsTo) {
                                const groupId = _selectedField.properties[fieldBelongsTo];
                                newField.properties[fieldBelongsTo] = groupId;
                            }

                            if (_selectedField.properties.visual_section) {
                                newField.properties.visual_section = _selectedField.properties.visual_section;
                            }

                            if (_selectedField.validationConstraints && _selectedField.validationConstraints.find(c => c.name === 'required')) {
                                newField.validationConstraints.push({name: 'required'});
                            }

                            designerActions.changeFieldProp({idx, ...newField});
                        }}>
                            {PaletteElementDictionaryAllowedForChange.map((e) =>
                                < MenuItem value={e.name}>{e.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                }

                {
                    isSelectorField(selectedField as FormField) &&
                    <Grid item>
                        {/*<ManageSimpleOptionSelectorOptions field={selectedField as FormField}/>*/}
                        <ManageOptionSelectorOptions field={selectedField as FormField}/>
                    </Grid>
                }
                {
                    isSpecialComponentField(selectedField as FormField) &&
                    <Grid item>
                        <ManageSpecialComponentsOptions field={selectedField as FormField}/>
                    </Grid>
                }

                <Grid item>
                    <Card>
                        <CardHeader title={'Validation'}></CardHeader>
                        <CardContent>
                            <Grid container direction={'column'}>
                                <Grid item container alignItems={'center'}>
                                    <Typography>Required</Typography>
                                    <Checkbox
                                        checked={parseRequiredConstraintValue(selectedField as FormField)}
                                        onChange={handleRequiredChange}
                                        value="required"
                                        name="field_required"
                                    />
                                </Grid>
                                {
                                    isNumberField(selectedField as FormField) &&
                                        <Grid item>
                                            <TextField type={'number'}
                                                       label={'Min value'}
                                                       value={handleParseMinValue(selectedField as FormField)}
                                                       onChange={handleMinValueChange}
                                            />
                                        </Grid>
                                }
                                {
                                    isNumberField(selectedField as FormField) &&
                                    <Grid item>
                                        <TextField type={'number'}
                                                   label={'Max value'}
                                                   value={handleParseMaxValue(selectedField as FormField)}
                                                   onChange={handleMaxValueChange}
                                        />
                                    </Grid>
                                }
                                {
                                    isStringField(selectedField as FormField) &&
                                    <Grid item>
                                        <TextField type={'number'}
                                                   inputProps={{
                                                       min: 1
                                                   }}
                                                   label={'Min length'}
                                                   value={handleParseMinLengthValue(selectedField as FormField)}
                                                   onChange={handleMinLengthValueChange}
                                        />
                                    </Grid>
                                }
                                {
                                    isStringField(selectedField as FormField) &&
                                    <Grid item>
                                        <TextField type={'number'}
                                                   inputProps={{
                                                       min: 1
                                                   }}
                                                   label={'Max length'}
                                                   value={handleParseMaxLengthValue(selectedField as FormField)}
                                                   onChange={handleMaxLengthValueChange}
                                        />
                                    </Grid>
                                }
                                {
                                    isStringField(selectedField as FormField) &&
                                    <Grid item>
                                        <TextField label={'pattern'}
                                                   defaultValue={handleParsePatternValue(selectedField as FormField)}
                                                   onBlur={evt => handlePatternValueChange(evt)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardHeader title={'Properties'}></CardHeader>
                        <CardContent>
                            <Grid container direction={'column'}>
                                {
                                    //    selectOutputFieldName: "select_customer",
                                    //                                 setSelectValueContextEval: "`${categoryId}/${category}/${countryIso2}`",
                                    //                                 actionsConfig: `[{"id": "renew", "label": "Renew" }, {"id": "revoke", "label": "Revoke" }]`,
                                }

                                {"selectOutputFieldName" in (selectedField as FormField).properties && <Grid item>
                                    <TextField label={'selectOutputFieldName'} fullWidth
                                               defaultValue={(selectedField as FormField).properties["selectOutputFieldName"]}
                                               error={fieldErrors && fieldErrors['selectOutputFieldName']} helperText={fieldErrors && fieldErrors['selectOutputFieldName']}
                                               onBlur={event => {
                                                   const value: string = event.target.value;
                                                   let error: string = "";

                                                   if (!value) {
                                                       error = 'This field is required';
                                                   }

                                                   if (error) {
                                                       return setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'selectOutputFieldName',
                                                           error: error
                                                       });
                                                   }

                                                   if (fieldErrors && fieldErrors['selectOutputFieldName']) {
                                                       setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'selectOutputFieldName',
                                                           error: undefined
                                                       });
                                                   }

                                                   handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'selectOutputFieldName', designerActions)(event);
                                               }}/>
                                </Grid>}

                                {"setSelectValueContextEval" in (selectedField as FormField).properties && <Grid item>
                                    <TextField label={'setSelectValueContextEval'} fullWidth multiline
                                               defaultValue={(selectedField as FormField).properties["setSelectValueContextEval"]}
                                               error={fieldErrors && fieldErrors['setSelectValueContextEval']} helperText={fieldErrors && fieldErrors['setSelectValueContextEval']}
                                               onBlur={event => {
                                                   const value: string = event.target.value;
                                                   let error: string = "";

                                                   if (!value) {
                                                       error = 'This field is required';
                                                   }

                                                   if (error) {
                                                       return setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'setSelectValueContextEval',
                                                           error: error
                                                       });
                                                   }

                                                   if (fieldErrors && fieldErrors['setSelectValueContextEval']) {
                                                       return setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'setSelectValueContextEval',
                                                           error: undefined
                                                       });
                                                   }

                                                   handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'setSelectValueContextEval', designerActions)(event);
                                               }}/>
                                </Grid>}

                                {"actionsConfig" in (selectedField as FormField).properties && <Grid item>
                                    <TextField label={'actionsConfig'} fullWidth multiline
                                               defaultValue={(selectedField as FormField).properties["actionsConfig"]}
                                               error={fieldErrors && fieldErrors['actionsConfig']} helperText={fieldErrors && fieldErrors['actionsConfig']}
                                               onBlur={event => {
                                                   const value: string = event.target.value;
                                                   let error: string = "";
                                                   let parsed: { id: string, label: string }[] = [];

                                                   try {
                                                       parsed = JSON.parse(value);
                                                   }catch (e){
                                                       error = `Value doesn't have correct JSON structure `;
                                                   }

                                                   if (!Array.isArray(parsed)){
                                                       error = `Not correct "Actions" structure, check example`;
                                                   } else {
                                                       parsed.forEach((action) => {
                                                            if (!action.id){
                                                                error =`Not correct "Actions" structure, "id" absent`;
                                                             }
                                                             if (!action.label){
                                                                 error = `Not correct "Actions" structure, "label" absent`;
                                                             }
                                                       });
                                                   }

                                                   if (error) {
                                                       return setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'actionsConfig',
                                                           error: error
                                                       });
                                                   }

                                                   if (fieldErrors && fieldErrors['actionsConfig']) {
                                                       return setErrorToField({
                                                           fieldErrors: fieldErrors || {},
                                                           id,
                                                           propertyName: 'actionsConfig',
                                                           error: undefined
                                                       });
                                                   }

                                                   handleStringValuePropertyChangeFactory(idx, selectedField as FormField, 'actionsConfig', designerActions)(event)
                                               }
                                    }
                                    />
                                </Grid>}

                                <Grid item container alignItems={'center'}>
                                    <Typography>Readonly</Typography>
                                    <Checkbox
                                        checked={parseReadonlyConstraintValue(selectedField as FormField)}
                                        onChange={handleReadonlyChange}
                                        value="readonly"
                                        name="field_readonly"
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField label={'Visual section'}
                                               defaultValue={handleParseVisualSectionValue(selectedField as FormField)}
                                               onBlur={evt => handleVisualSectionValueChange(evt)}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField label={'visible'}
                                               defaultValue={handleParseVisibleValue(selectedField as FormField)}
                                               onBlur={evt => handleVisibleValueChange(evt)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        } else {
            return <Grid container direction={"column"} key={id}>
                <Grid item><TextField id="prop_id" label={`Id`} variant="standard" disabled defaultValue={id} /></Grid>

                <Grid item><TextField id="prop_value" multiline label="value" variant="standard"  defaultValue={value.value instanceof Array ?  JSON.stringify(value.value) : value.value}
                                      error={fieldErrors && fieldErrors['prop_value']} helperText={fieldErrors && fieldErrors['prop_value']}
                                      onBlur={evt => {
                                          let inputValue = evt.target.value.trim();
                                          let parsedValue: string | IActionJson | (string | IActionJson)[] | undefined = inputValue;
                                          let validatedResult: { error?: null | string, validatedValue?: string | IActionJson };
                                          let validatedValue: string | IActionJson | undefined;
                                          let error: string | null | undefined;

                                          if (inputValue.indexOf(`[`) === 0 || inputValue.indexOf(`{`) === 0) {
                                              try {
                                                  parsedValue = JSON.parse(evt.target.value)
                                              } catch (e) {
                                                  parsedValue = ''
                                                  error = `Value doesn't have correct JSON structure `;
                                              }

                                              if (!error && parsedValue) {

                                                  if (Array.isArray(parsedValue)) {

                                                      _.each(parsedValue, (parsedEl, index)=>{
                                                          validatedResult = validateParsedActionsList(parsedEl);
                                                          error = validatedResult.error;
                                                          validatedValue = validatedResult.validatedValue;
                                                          parsedValue![index] = validatedValue;

                                                          return !error;
                                                      })

                                                  } else {
                                                      validatedResult = validateParsedActionsList(parsedValue);
                                                      error = validatedResult.error;
                                                      parsedValue = validatedResult.validatedValue;
                                                  }
                                              }

                                          }

                                          if (error) {
                                              return setErrorToField({
                                                  fieldErrors: fieldErrors || {},
                                                  id,
                                                  propertyName: 'prop_value',
                                                  error: error,
                                              });
                                          }

                                          if (inputValue && parsedValue || !inputValue && !parsedValue) {
                                              designerActions.changeFieldProp({
                                                  idx,
                                                  value: {
                                                      value: parsedValue,

                                                  },
                                                  type:{
                                                      name:'json'
                                                  },
                                                  typeName:'json',
                                                  validationConstraints:{name:'readonly'}
                                              })

                                              fieldErrors && fieldErrors['prop_value'] && setErrorToField({
                                                  fieldErrors: fieldErrors || {},
                                                  id,
                                                  propertyName: 'prop_value',
                                                  error: undefined,
                                              });
                                          }

                                      }}/></Grid>
                <Grid item>
                    <br/>
                    <Divider/>
                    <Typography variant="caption" display="block" gutterBottom>
                        <strong>Value can take: </strong><br/>
                        - <strong>comma separated string </strong> (example: Submit, Back, Skip)<br/>
                        - <strong>array of strings </strong> with the name of the buttons to be rendered (example:
                        ["Submit", "Back"])<br/>
                        - <strong>special object</strong> for open dialog on click (example: &#123;"label": "Submit with
                        dialog",
                        "dialog": &#123;"name": "group_in_dialog_1", "label": "Please add some
                        comments"&#125;&#125;)<br/>
                        Here <strong>label</strong> is the label of button, <strong>dialog</strong> - configuration of
                        shown dialog on click,
                        <strong>dialog.name</strong> - name of "group in dialog" which must be
                        shown, <strong>dialog.label</strong> - label of dialog<br/>
                        - <strong>same type or mixed array</strong> - array or mix of strings and special objects
                        (example: ["Submit", &#123;"label": "Submit with dialog", "dialog": ... &#125;])<br/>
                        - <strong>hide</strong>: Means the button bar will be hidden (typically this means that the form
                        components will somehow trigger form submittion<br/>
                        - <strong>back_hide</strong>: will cause the back button (rendered automatically) to be
                        hidden<br/>
                        - <strong>back</strong>: Is a reserved word to render the back button but trigger a named action
                        instead of going to the previous form<br/>
                        <br/>
                        <strong>'actionsList' </strong> is modeled as an input parameter on the camunda user tasks (as
                        the value is constant)<br/>
                        <br/>
                        If <strong>Value</strong> not set, - <strong>Submit</strong> will be shown in Form as default
                    </Typography>
                </Grid>
            </Grid>
        }

    } else if (selectedItem) {
        const {id, type, extraData} = selectedItem;
        const itemErrors = errorItems[id!];


        return <Grid container direction={"column"} key={id}>
            <Grid item>
                <Autocomplete
                    options={allProcessVariablesNames || []}
                    defaultValue={id}

                    renderInput={(params) => <TextField
                        error={itemErrors && itemErrors['prop_id']} helperText={itemErrors && itemErrors['prop_id']}
                        {...params} id="prop_id"  label={`Id`} variant="standard" onBlur={evt => {
                        if (evt.target.value) {
                            if (type === 'group' || type === 'group_repeatable' || type === 'group_in_dialog') {
                                designerActions.changeGroupId({oldId: id, newId: evt.target.value, type})
                            }

                            itemErrors && itemErrors['prop_id'] && setErrorToItem({
                                itemErrors: itemErrors || {},
                                id,
                                propertyName: 'prop_id',
                                error: undefined,
                            });
                        } else {
                             setErrorToItem({
                                itemErrors: itemErrors || {},
                                id,
                                propertyName: 'prop_id',
                                error: `"id" can't be empty!`,
                            });
                        }
                    }}/>}
                />
            </Grid>
        </Grid>
    } else {
        return <div>Properties Panel</div>
    }
}



const DesignFormBuilder = (props:{theme_data:ThemeOptions, onUpdate?:(new_model)=>void} & Partial<FormBuilderProps>)=>{
    const designer = useSelector<RootState,RootState['designer']>(state=>state.designer);

    let name,description,formFields;
    if (designer){
        name = designer.name;
        description = designer.description;
        formFields = designer.formFields;
    }else{
        name = props.name || ""
        description = props.description || "";
        formFields = props.formFields || [];
    }



    useEffect(()=>{
        if (props.onUpdate){
            props.onUpdate({name,description,formFields})
        }
    },[name,description,formFields])

    return <ThemedFormBuilder theme_data={props.theme_data}
                           name={name}
                           description={description}
                           formFields={formFields}
                           submitFN={async (_vars) => {
                           }}
        />

}


export const FormBuilderApp = (props:{theme_data:ThemeOptions, onUpdate?:(new_model)=>void} & Partial<FormBuilderProps>) =>{



    const {name,description,formFields, allProcessVariablesNames } = props
    const [designerMode,setDesignerMode] = useState(true)

    // insert 'actionsList' if not exists
    const actionsListIndex = formFields && formFields.findIndex(f => f.id === 'actionsList');
    if (actionsListIndex && actionsListIndex < 0) {

        formFields.push({
            id: `actionsList`,
            value: {
                value: null,
                type:{
                    name:'json'
                },
                transient:true
            },
            type:{
                name:'json'
            },
            typeName:'json',
            validationConstraints:[{name:'readonly'}],
            properties: {},
        } as FormField);
    }

    // insert 'hidden_fields' into groups to support group handling
    const _formFields = formFields || [];

    for (let i= 0; i<_formFields!.length; i++){
        const field = _formFields![i];

        if (field.properties && ('group' in field.properties || 'group_repeatable' in field.properties) && !field.id.includes('__hidden_field_of_')) {
            const groupType = ('group' in field.properties && 'group') || ('group_repeatable' in field.properties && 'group_repeatable') as string;
            const groupName = field.properties[groupType];

            if (_formFields.findIndex(f => f.properties![groupType] === groupName && f.id.includes('__hidden_field_of_')) >= 0) {
                continue;
            }

            const firstGroupFieldIdx = _formFields.findIndex(f => f.properties![groupType] === groupName);
            const groupFieldsLength = (_formFields.filter(f => f.properties![groupType] === groupName)).length;
            const lastGroupFieldIdx = firstGroupFieldIdx + groupFieldsLength;


            const hidden_field = {
                id: `__hidden_field_of_${groupType}_${groupName}`,
                type: {name: 'string'},
                typeName: 'string',
                value: {
                    value: null,
                    type: {
                        name: 'string'
                    },
                    transient: false
                },
                validationConstraints: [],
                properties: {
                    [groupType]: groupName,
                    'visibility': 'hidden'
                }
            }

            // @ts-ignore
            _formFields.splice(lastGroupFieldIdx, 0, hidden_field);
        }
    }

    const initialState = formFields ? {designer:{
        name:name || "",
        description:description || "",
        formFields}
    } : undefined

    const store = getStore(initialState);


    // @ts-ignore
    const ReduxProvider = _ReduxProvider as any;
    return <DesignModeContext.Provider value={designerMode}><DndProvider backend={HTML5Backend}>
        <ReduxProvider store={store}>
            <Grid container id={"FormBuilderApp"} direction={"column"} spacing={2}
                  style={{ overflowX: "scroll"}}>
                <Grid item container id={"FormBuilderPanel"} direction={"row"} spacing={2} style={{minWidth: 1300}}>
                    <Grid item  style={{ overflowY: "auto", height: "80vh" }}>
                        <Palette toggleDesignMode={(mode) => setDesignerMode(mode)} />
                    </Grid>

                    <Grid item xs={8}>
                        <Box border={"1px black solid"} padding={5}
                                           style={{ overflowY: "auto", height: "100vh" }}>
                        <DesignFormBuilder  {...props} theme_data={props.theme_data ?? {}} />
                    </Box>
                    </Grid>

                    <Grid item xs={2} style={{ overflowY: "auto", height: "100vh" }}>
                        <PropertiesPanel allProcessVariablesNames={allProcessVariablesNames} />
                    </Grid>
                </Grid>
            </Grid>
        </ReduxProvider>
    </DndProvider>
    </DesignModeContext.Provider>
}


export {ThemeOptions} from "@material-ui/core/styles";

export function ThemedFormBuilder(props:ThemedFormBuilderProps){
    let {name,description,theme_data,formFields,submitFN,showSections} = props
    const designMode = useContext(DesignModeContext);

    if (!designMode){
        //in "designer OFF" we filter hidden fields
        formFields = (formFields || []).filter(f => !f.id.includes('__hidden_field_of_'));
    }

    //TODO dispatch lang - if arabic rtl
    theme_data.direction = themeSide.en
    return <ThemeWrapper theme_data={theme_data}>
        <FormBuilder
            name={name}
            designMode={designMode}
            description={description}
            formFields={formFields}
            submitFN={vars => submitFN(vars)}
            showSections={showSections}
        />
    </ThemeWrapper>

}

function _renderFormBuilder(name, description, formFields,theme_data,showSections) {
    const sheets = new ServerStyleSheets();

    const result = renderToStaticMarkup(sheets.collect(
        <ThemedFormBuilder
            name={name}
            description={description}
            formFields={formFields}
            submitFN={function (args) {
                return Promise.resolve();
            }}
            theme_data={theme_data}
            showSections={showSections ?? false}

        />
    ));


    return `<!DOCTYPE html>
              <html>
              <head>
                <meta charset="utf-8">
                <title>Server-side rendering FormBuilder</title>

                <style type="text/css" id="server-side-styles">
                  ${sheets.toString()}
                </style>
              </head>
              <body>
                <div id="app">${result}</div>
              </body>
            </html>`;
}


export function renderFormBuilder(...args:[Omit<ThemedFormBuilderProps,'submitFN'>] | any[]):string {
    if (args.length === 1){
        const {name, description, formFields,theme_data,showSections} = (args as [Omit<ThemedFormBuilderProps,'submitFN'>])[0];
        return _renderFormBuilder(name,description,formFields,theme_data,showSections)
    }else{
        const [name, description, formFields,theme_data,showSections] = args
        return _renderFormBuilder(name, description, formFields,theme_data,showSections);
    }

}
