import * as React from "react";
import {CSSProperties} from "react";

export type ButtonParams = {
    children: string;
    render?: "link" | "button"
    extraStyle?:CSSProperties,
    disabled?:boolean
    validateOnClick?:boolean
    handler:(evt?)=>Promise<any>

}

export const buttonLabelSubstitutions = {
    '__export':'Export',
    '__export_server':'Export'
};

export const ButtonBase = (props:ButtonParams & {doRender:()=>JSX.Element}) =>{
    return props.doRender();
}

