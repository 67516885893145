import {Component, FunctionComponent, MutableRefObject, useContext, useRef, useState} from "react";
import * as React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider, FormHelperText,
    Grid,
    InputAdornment, makeStyles,
    TextField,
    Typography, useTheme,
    withStyles
} from "@material-ui/core";

import GenericFieldComponent from "./GenericFieldComponent";
import {GroupComponent} from "./Group";
import {
    RepeatableBase,
    RepeatableParams,
    RepeatableGroupRowRendererParams,
    RendererType
} from "sancus-client-common/dist/common/RepeatableBase";
import {
    FieldGroupTemplate,
    FileChangeCallback,
    FormValues,
    GroupCheck,
    ValueChangeCallback
} from "sancus-client-common/dist/common/FormContainerBase";
import {v4 as uuidv4} from 'uuid';
import DesignModeContext from "sancus-client-common/dist/common/DesignModeContext";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {FormProvider, useForm, useFormContext, UseFormMethods} from "react-hook-form";
import EditFieldContext from "sancus-client-common/dist/common/EditFieldContext";
import formValidationUtil, {
    IndexDecoratorFormValidationUtilFactory
} from "sancus-client-common/dist/utils/formValidation.util";
import {useIntl} from "react-intl";
import formFieldValidationUtil from "sancus-client-common/dist/utils/formValidation.util";
import Popper from "@material-ui/core/Popper";
import {util} from "prettier";
import skip = util.skip;

const DesignModeComponentWrapper = (props) => {
    const designMode = useContext(DesignModeContext);

    return props.children(designMode);
}

export const EditCreateGroupContent = (props:{
    show:boolean,
    anchorRef:MutableRefObject<HTMLElement | null>,

    idx:number,
    maxIdx:number,
    template: FieldGroupTemplate,
    value:FormValues,
    closeRepeatableDialog:(submit:boolean)=>void,
    repeatableGroupId:string,
    renderGroup:_Repeatable['renderGroup'],
    valueChangeCbk:ValueChangeCallback,
    fileChangeCbk:FileChangeCallback
    readOnly?:boolean,
    checkMessages?:GroupCheck,
    formReference,
    src:RepeatableParams,
    holdValues:boolean
}) => {
    const [content,setContent] = useState(props.value && props.value[props.idx]);

    const {show,anchorRef,idx,maxIdx,template,repeatableGroupId,value,renderGroup,readOnly,checkMessages,formReference,src,closeRepeatableDialog} = props;
    const methods = useFormContext();
    const element = value && renderGroup(idx, template, value, !!readOnly, checkMessages,formReference,src);
    const intl = useIntl();

    const valueChangeCbk = async (key, value) => {
        if (content == null){
            return;
        }


        content.value = value;
        setContent({...content});
    }

    /**
     * key is ubo.ubo_passport which lacks idx and is wrong
     * @param key
     * @param value
     */
    const fileChangeCbk = (key, value) => {
        if (content == null){
            return;
        }

        const internalKey = key.substring(key.lastIndexOf(".")+1);

        content.files = content.files || {};
        //actually we need the prefix.suffix format as the parent fileChangeCbk will inject the indx making it prefix[idx].suffix
        content.files[key] = value;
        setContent({...content});
    }

    const holdValues = props.holdValues;

    const finalize = async (submitted)=>{
        if (!submitted){
            closeRepeatableDialog(false);
            return;
        }
        const triggered = await methods.trigger();
        const errors = methods.formState.errors;
        // const hasErrors = Object.keys(errors).length > 0;
        const hasErrors = formFieldValidationUtil(intl).hasFieldError(repeatableGroupId+'.'+idx,errors)
        if (hasErrors){
            console.debug("hasErrors",hasErrors,errors);
            return;
        }

        if (holdValues){
            //hold values means that the values in the popup are not propagated to the valueChangeCbk
            // as they are updated but are updated when the dialog closes
            const valueChangeCbk = props.valueChangeCbk;
            const currIdx = props.idx == -1? props.maxIdx:props.idx
            const files = content.files;
            const fileChangeCbk = props.fileChangeCbk;

            await valueChangeCbk(props.repeatableGroupId,props.value)

            for (const [key,value] of Object.entries(files)){
                fileChangeCbk(key,value);
            }
        }


        closeRepeatableDialog(true);
    }

    const fullWidth = false;
    const dialogRef = useRef<HTMLElement>(null);
    const paperRef = useRef<HTMLElement>(null);
    const skipHeightAdjustment = false;
    return <Dialog
            ref = {dialogRef}
            container={()=>{
                return anchorRef.current
            }}
            // disablePortal={true} - If enable, make sure to transfer the logic from FormContainer
            // that expands the dialog when the autocopmpletes are open
            onClose={(evt, reason) => {
                if (reason == 'backdropClick') {
                    return;
                }
                closeRepeatableDialog(false);
            }}
            open={show}
            fullWidth={fullWidth}
            hideBackdrop={true}
            maxWidth={false}
            PaperProps={{
                ref: (contentEl:HTMLElement) => {
                    // @ts-ignore
                    paperRef.current = contentEl;
                    if (anchorRef.current) {
                        const contentHeight = contentEl?.getBoundingClientRect().height;
                        const viewPortBottom = window.innerHeight;
                        const anchorRefBR = anchorRef.current.getBoundingClientRect();
                        const _viewPortCenter = anchorRefBR.top + anchorRefBR.height /2;
                        if (_viewPortCenter && contentEl){
                            if (_viewPortCenter < contentHeight /2) {
                                (contentEl as HTMLElement)!.style.top = `${(contentHeight /2)+10}px`
                            }else if (_viewPortCenter > viewPortBottom - contentHeight /2) {
                                (contentEl as HTMLElement)!.style.top = `${(viewPortBottom - contentHeight /2)-10}px`
                            }else {
                                (contentEl as HTMLElement)!.style.top = `${_viewPortCenter}px`
                            }
                        }
                    }
                },
                elevation: 24,
                style: {
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.75)',
                }
            }}
        >
            <FormProvider {...methods}>
                <EditFieldContext.Provider value={true}>
                    <DialogContent>
                        {element}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={
                            async evt => {
                                evt.preventDefault()
                                await finalize(true);
                            }
                        } color="primary" variant={"contained"}>OK</Button>
                        <Button onClick={async evt => {
                            evt.preventDefault();
                            await finalize(false);
                        }} variant={"contained"}>Cancel</Button>
                    </DialogActions>
                </EditFieldContext.Provider>
            </FormProvider>
        </Dialog>



}

class _Repeatable extends RepeatableBase {


    renderGeneric(idx,template,value,src:RepeatableParams){
        return <GenericFieldComponent {...template}
                               valueChangeCbk={this.valueChangeCbk(idx)}
                               fileChangeCbk={this.fileChangeCbk(idx)}
                               _value={value}
                               key={value}
        />
    }



    renderGroupWithDeleteBtn(idx: number, template: FieldGroupTemplate, value: FormValues, readOnly: boolean, checkMessages:GroupCheck | undefined,formReference,src:RepeatableParams): JSX.Element {
        const element = this.renderGroup(idx,template,value,readOnly,checkMessages,formReference,src);

        return <Box style={{
            width:'100%'
        }}>
            <Grid style={{
                paddingLeft:'10px',
                paddingRight:'64px',
                position: 'relative'
            }}>
                {element}
                {/*CSS Witchcraft to align the delete button to the right and center of the group*/}
                <Grid style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    right: '0px',
                    height:'100%',
                    top:'0px'
                }}>
                    <Button>
                        <DeleteIcon onClick={this.deleteInIndex(idx)}/>
                    </Button>
                </Grid>


            </Grid>
        </Box>
    }

    renderGroup(idx: number, template: FieldGroupTemplate, value: FormValues, readOnly: boolean, checkMessages:GroupCheck | undefined,formReference,src:RepeatableParams,
                ctxOverride?:{valueChangeCbk?:ValueChangeCallback,fileChangeCbk?:FileChangeCallback,methods?:UseFormMethods}): JSX.Element {
        const helperText = src?.properties?.entryHelperText;
        const label = src?.properties?.entryLabel;

        return <GroupComponent
            name={this.props.name}
            index={idx}
            path={`${this.props.name}[${idx}]`}
            key={value.uuid}
            template={template}
            value={value}
            contextEvaluator={this.props.contextEvaluator}
            checkMessages={checkMessages}
            fileChangeCbk={ctxOverride?.fileChangeCbk ?? this.fileChangeCbk(idx)}
            valueChangeCbk={ctxOverride?.valueChangeCbk?? this.valueChangeCbk(idx)}
            readOnly={readOnly}
            formReference={formReference}
            designMode={this.props.designMode}
            methods={ctxOverride?.methods ?? this.props.methods}
            visibleElements={this.props.visibleElements}

            label={label}
            helperText={helperText}
        />
    }
    entryRenderer(): FunctionComponent<RepeatableGroupRowRendererParams>{
        return RepeatableGroupRowRenderer;
    }

    renderEntry(idx,readOnly,component,key?){
        key = key || uuidv4()
        return (
            <DesignModeComponentWrapper key={key+"_dmcw"}>
                {(designMode) => (
                    <Grid container  direction="column" key={key} className={"repeatableSubsection repeatable repeatable-item-container"}>
                        <Grid container direction="row"
                              className={`repeatableSubsection repeatable repeatable-item repeatable-item-${idx}`}
                          justifyContent="space-between"
                          alignItems="flex-start" style={readOnly || designMode ? { paddingTop: "16px", paddingBottom: "16px", marginTop: "8px", marginBottom: "8px" } : {}}>
                            {component}
                        </Grid>

                </Grid>)}
            </DesignModeComponentWrapper>)
    }

    doRender(readonly: boolean, entries: JSX.Element[],label:string,properties): JSX.Element{
        const intl = this.props.intl;
        const theme = this.props['theme'];
        const helperText = properties?.helperText;

        const classes = this.props['classes'];


        const isEmptyLabel = label == this.props.id;
        if (isEmptyLabel){
            label = '';
        }

        const methods = this.props.methods;
        const errors = formValidationUtil(intl).hasFieldError(this.props.id,methods?.errors)

        let errorText = undefined;
        if (errors){
            errorText =formValidationUtil(intl).getFieldErrorMessage(this.props.id,methods?.errors)
        }

        return (
            <DesignModeComponentWrapper>
                {(designMode) => (
                    <>
                        <Grid
                            id={this.props.id}
                            key={this.props.id}
                            container
                            data-repeatable={"true"}
                            className={"repeatable-container"}
                            style={{
                                marginLeft:theme.spacing(2)
                            }}
                            direction="column">
                            {label?<>
                                <Grid item className={classes.labelContainer}>
                                    <Typography variant={"subtitle1"}>{label}</Typography>
                                    <Divider/>
                                </Grid>
                            </>:null
                            }
                            {helperText? <FormHelperText>
                                <span dangerouslySetInnerHTML={{__html:helperText}} />
                                </FormHelperText> : null
                            }
                            <Grid container direction="row" className={classes.entryContainer}>
                                {entries}
                            </Grid>
                            {
                                readonly || designMode
                                    ? ''
                                    : <AddNewEntryButton id={this.props.id}
                                                         addNew={this.addNew.bind(this)}
                                                         addNewValue={this.addNewValue.bind(this)}
                                                         maxIdx={this.props.values?.length ?? 0}
                                                         template={this.props.template}/>
                            }
                            {errorText? <FormHelperText error>{errorText}</FormHelperText> : null}
                        </Grid>

                    </>
                )}
            </DesignModeComponentWrapper>
        );
    }
}

export const Repeatable = withStyles(theme => {
    return {
        labelContainer: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        entryContainer: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        }
    }
},{withTheme:true})(_Repeatable);

const RepeatableGroupRowRenderer:FunctionComponent<RepeatableGroupRowRendererParams> = (props:RepeatableGroupRowRendererParams)=>{
    const intl = useIntl();
    const theme = useTheme();

    const classes = makeStyles((theme) => ({

        hoverBtnContainer:{
            backgroundColor:'transparent',

            border:'none',
            '& button':{
                '@media (hover: hover)': {
                    opacity: 0,
                    transition: 'opacity 0.2s',
                },
                '@media (hover: none)': {
                    opacity: 1,
                }

            },
            '&:hover button':{
                opacity:1
            }
        }
    }))(theme);

    const deleteRow = intl.formatMessage({
        id:'deleteRow',
        defaultMessage:'Delete row',
        description: 'Repatable field delete row'
    });

    const isNew = props.isNew || props.value._isNew;

    const [showDialog,setShowDialog] = useState(!!isNew);

    const itemRef = useRef(null);

    return <>
        <TextField
            fullWidth
            innerRef={itemRef}
            variant={"outlined"}
            color={"primary"}
            error={props.hasError}
            helperText={props.errorText}
            className={classes.hoverBtnContainer}
            onChange={evt=>{
                evt.preventDefault(); //Readonly but to show as normal
            }}
            value={props.asText}
            InputProps={{

                className:classes.hoverBtnContainer,
                endAdornment:<InputAdornment
                    position={"end"}>

                    <Button

                        onClick={evt=>{
                            setShowDialog(true);
                        }}
                    ><EditIcon/></Button>
                    <Button
                        onClick={evt=>{
                            props.deleteInIndex(props.idx)(evt);
                        }}><DeleteIcon/>
                    </Button>

                </InputAdornment>
            }}/>

            <EditCreateGroupContent
                show={showDialog}

                anchorRef={itemRef}
                holdValues={false}
                repeatableGroupId={props.id}
                renderGroup={props.renderGroup}
                valueChangeCbk={props.valueChangeCbk.bind(this)}
                fileChangeCbk={props.fileChangeCbk.bind(this)}

                closeRepeatableDialog={submitted=>{
                    setShowDialog(false)
                }}
                formReference={props.formReference}
                idx={props.idx}
                maxIdx={props.maxIdx}
                src={props.src}
                template={props.template}
                value={props.value}/>

        </>
}

const AddNewEntryButton = (props:{id,template,addNew,maxIdx,addNewValue})=>{
    const intl = useIntl();
    const designMode = useContext(DesignModeContext);
    const addNew = intl.formatMessage({
        id:'addNew',
        defaultMessage:'Add new',
        description:'Repeatable Component Add new'
    });

    return <Button variant="outlined" color="primary"
            onClick={
                //this.addNew
                evt=>{
                    if (designMode){
                        return
                    }else {
                        return props.addNew(evt);
                    }
                }}
            key={"add_button"}>{addNew}</Button>
}